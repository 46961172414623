import React, { Component, Fragment, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import Label from '../../component/form/Label';
import GatedComponent, { permissionsQuery } from '../../component/GatedComponent';
import TextField from '../../component/form/TextField';
import TagButton from '../../component/form/TagButton';
import { SaveButton } from '../../component/form/PrimaryButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '../../component/icon/SaveIcon';
import Hidden from '@material-ui/core/Hidden';
import { getAuthClient, getClient, getMyClient } from '../../apollo';
import gql from 'graphql-tag';
import Spinner from '../../component/Spinner';
import ColumnLayout from '../../component/ColumnLayout';
import { FUNERAL_HOMES } from '../../util/funerals';
import MenuItem from '@material-ui/core/MenuItem';

const UpdatePhoneMutation = gql`
    mutation UpdatePhoneMutation($input: MemberUpdateInputType!) {
        updateMember(Input: $input) {
            ID
        }
    }
`;

const updateMemberLastOfficeIDMutation = gql`
    mutation UpdateMemberLastOfficeID($memberid: Int!, $lastofficeid: Int!) {
        updateMemberLastOfficeID(ID: $memberid, LastOfficeID: $lastofficeid) {
            ID
        }
    }
`;

function RenderUser({ user }) {
    const [lastOfficeID, setLastOfficeID] = useState(user.MyLastOfficeID);
    const [Phone, setPhone] = useState(user.Phone);
    const [busy, setBusy] = useState(false);
    const savePhone = async () => {
        const input = { ID: user.ID, Phone };
        const asyncQuery = await getMyClient().mutate({ mutation: UpdatePhoneMutation, variables: { input } });
        return asyncQuery && !!asyncQuery.data;
    };
    const saveOffice = async () => {
        const input = { memberid: Number(user.ID), lastofficeid: lastOfficeID };
        const asyncQuery = await getClient().mutate({
            mutation: updateMemberLastOfficeIDMutation,
            variables: { ...input }
        });
        return asyncQuery && !!asyncQuery.data;
    };
    const isPhoneDirty = Phone !== user.Phone;
    const isOfficeDirty = lastOfficeID !== user.MyLastOfficeID;
    const isPhoneBusy = busy && Phone !== user.Phone;
    const isOfficeBusy = busy && lastOfficeID !== user.MyLastOfficeID;
    return (
        <ColumnLayout>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Label text={'My Details'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField label={'First Name'} value={user.FirstName} readOnly />
                </Grid>
                <Grid item xs={6}>
                    <TextField label={'Surname'} value={user.Surname} readOnly />
                </Grid>
                <Grid item xs={12}>
                    <TextField label={'Email'} value={user.Email} readOnly />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={'Phone'}
                        value={Phone}
                        disabled={isPhoneBusy}
                        onChange={v => setPhone(v.target.value)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="primary">phone</Icon>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <SaveButton
                                    style={{
                                        whiteSpace: 'pre',
                                        marginRight: -6,
                                        borderRadius: '24px 0px 0px 24px'
                                    }}
                                    disabled={isPhoneBusy || !isPhoneDirty}
                                    onClick={() => {
                                        setBusy(true);
                                        savePhone().then(data => {
                                            getAuthClient().writeQuery({
                                                id: user.ID,
                                                query: permissionsQuery,
                                                data: {
                                                    readCurrentUser: { ...user, Phone: Phone }
                                                }
                                            });
                                            setBusy(false);
                                        });
                                    }}
                                >
                                    {isPhoneBusy ? <Spinner /> : <SaveIcon />}
                                    {isPhoneBusy ? (
                                        <Hidden smDown> Updating...</Hidden>
                                    ) : (
                                        <Hidden smDown> Update Phone</Hidden>
                                    )}
                                </SaveButton>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Label text={'My Teams'} />
                </Grid>
                <Grid item xs={12}>
                    {user.Groups && user.Groups.map(e => <TagButton key={e.ID} text={e.Title} />)}
                </Grid>
                <Grid item xs={12}>
                    <Label text={'My Current Office'} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={isOfficeBusy}
                        onChange={e => setLastOfficeID(e.target.value)}
                        label="Office"
                        value={lastOfficeID}
                        select
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="primary">business</Icon>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <SaveButton
                                    style={{
                                        whiteSpace: 'pre',
                                        marginRight: -6,
                                        borderRadius: '24px 0px 0px 24px'
                                    }}
                                    disabled={busy || !isOfficeDirty}
                                    onClick={() => {
                                        setBusy(true);
                                        saveOffice().then(data => {
                                            getAuthClient().writeQuery({
                                                id: user.ID,
                                                query: permissionsQuery,
                                                data: {
                                                    readCurrentUser: { ...user, MyLastOfficeID: lastOfficeID }
                                                }
                                            });
                                            setBusy(false);
                                        });
                                    }}
                                >
                                    {isOfficeBusy ? <Spinner /> : <SaveIcon />}
                                    {isOfficeBusy ? (
                                        <Hidden smDown> Updating...</Hidden>
                                    ) : (
                                        <Hidden smDown> Update Office</Hidden>
                                    )}
                                </SaveButton>
                            )
                        }}
                    >
                        {FUNERAL_HOMES.map(option => (
                            <MenuItem key={option.ID} value={option.ID}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </ColumnLayout>
    );
}

class Account extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span>Account Settings</span>
                    </InlineFieldRightAlignChildren>
                </Typography>
                <br />
                <Paper className={classes.paper} elevation={0}>
                    <GatedComponent showComponentCode={'OBJ_EDIT_ALL'}>
                        {(canEdit, data) => <RenderUser user={data.readCurrentUser} />}
                    </GatedComponent>
                </Paper>
            </Fragment>
        );
    }
}
const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    }
});

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(Account);
