import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from './form/TextField';
import Spinner from './Spinner';
import { withSnackbarMessage } from '../context/SnackbarMessage';
import { compose } from 'react-apollo/index';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from './icon/SearchIcon';
import Table, { TableCell, TableRow } from './form/Table';
import { joinDefined, stringIsNullOrEmpty } from '../util/strings';
import { isNullOrUndefined } from '../util/objects';
import gql from 'graphql-tag';
import {
    PrefillFromEnquiryFragment,
    PrefillFromPrearrangementFragment
} from '../page/quotes/EnquiryAndPrearrangementFragment';
import { quoteTypes } from '../util/quotes';
import Radio from './form/Radio';
import { getEnquiryClient } from '../apollo';

class EnquiryLookup extends Component {
    state = {
        searchTerm: null,
        initialSearchTerm: null,
        searchPending: false
    };

    static getDerivedStateFromProps({ initialSearchTerm }, oldState) {
        if (initialSearchTerm === oldState.initialSearchTerm) return null;

        return { initialSearchTerm, searchTerm: initialSearchTerm };
    }

    render() {
        const variables = this.getQueryVariables();
        const { ignoreEnquiryId } = this.props;

        return (
            <Query
                query={readEnquiries}
                fetchPolicy={'network-only'}
                skip={stringIsNullOrEmpty(variables.contains) && stringIsNullOrEmpty(variables.key)}
                variables={variables}
                client={getEnquiryClient()}
                context={{ debounceKey: 2 }}
            >
                {({ data, loading, error }) => {
                    const resultsE = (data && data.searchEnquiries && data.searchEnquiries.edges) || [];
                    const resultsP = (data && data.searchPrearrangements && data.searchPrearrangements.edges) || [];
                    let results = [].concat(resultsE, resultsP).map(e => e.node);
                    const hasMore =
                        results.length <
                        Number(
                            (data && data.searchEnquiries && data && data.searchEnquiries.pageInfo.totalCount) || 0
                        ) +
                            Number(
                                (data &&
                                    data.searchPrearrangements &&
                                    data &&
                                    data.searchPrearrangements.pageInfo.totalCount) ||
                                    0
                            );
                    if (!stringIsNullOrEmpty(ignoreEnquiryId) && ignoreEnquiryId !== '0') {
                        results = results.filter(x => x.ID !== ignoreEnquiryId);
                    }
                    results = results.sort((a, b) => (a.Created < b.Created ? -1 : 1));
                    return (
                        <Fragment>
                            {hasMore && <p>There are more results, consider refining your query.</p>}
                            <Downshift>{d => this.renderContainer(results || [], loading, d, error)}</Downshift>
                        </Fragment>
                    );
                }}
            </Query>
        );
    }

    renderContainer(results, loading, downshiftData, error) {
        const { classes, className } = this.props;
        return (
            <div className={cx(classes.root, className)}>
                {this.renderInput(downshiftData, loading)}
                {
                    <div className={classes.resultContainer}>
                        {this.renderResults(results, loading, downshiftData, error)}
                    </div>
                }
            </div>
        );
    }

    renderInput(downshiftData, loading) {
        const { classes } = this.props;
        const { searchTerm } = this.state;

        return (
            <TextField
                className={classes.search}
                id="EnquirySearch"
                fullWidth={true}
                label="Search for..."
                InputProps={{
                    disableUnderline: false,
                    ...downshiftData.getInputProps({ placeholder: 'Search for an existing record...' }),
                    startAdornment: (
                        <InputAdornment position="start" className={classes.iconContainer}>
                            {loading && !stringIsNullOrEmpty(searchTerm) ? (
                                <Spinner />
                            ) : (
                                <SearchIcon className={classes.icon} />
                            )}
                        </InputAdornment>
                    ),
                    inputProps: {
                        onChange: e => this.setState({ searchTerm: e.target.value }),
                        value: searchTerm || ''
                    }
                }}
            />
        );
    }

    renderResults(results, loading, downshiftData, error) {
        const { searchTerm } = this.state;
        if (stringIsNullOrEmpty(searchTerm) || loading) return null;
        const { classes } = this.props;

        if (error) {
            return <Typography className={classes.noResult}>{error.message || 'An unknown error occurred'}</Typography>;
        }

        if (results.length === 0) {
            return <span className={classes.noResults}>No records matched your search.</span>;
        }

        return (
            <Table
                className={classes.resultTable}
                columns={['Record Type', 'Funeral', 'Client Name', 'Residential Address', 'DOB', 'Select']}
            >
                {results.map(r => this.renderResult(r))}
            </Table>
        );
    }

    renderResult = result => {
        const { selectedEnquiry, onSelectEnquiry, classes } = this.props;
        let tableID = '(none)';
        let selectionID = 'Enq-' + result.ID;
        let type = 'Enquiry ' + result.LegacyKey;
        if (result.ClassName === 'FuneralManager\\Prearrangement') {
            type = 'Prearrangement ' + result.LegacyKey;
            selectionID = 'Pre-' + result.ID;
        }
        if (result.Funeral) {
            tableID = result.Funeral.LegacyKey;
        } else if (result.Quote && result.Quote.Enquiry.Funeral.ID) {
            tableID = result.Quote.Enquiry.Funeral.LegacyKey;
        }
        return (
            <TableRow key={selectionID}>
                <TableCell>
                    {Object.keys(quoteTypes).find(key => quoteTypes[key] === result.EnquiryType)} {type}
                </TableCell>
                <TableCell>{tableID}</TableCell>
                <TableCell>
                    {joinDefined([result.GivenName || result.FirstName, result.MiddleName, result.Surname], ' ')}
                </TableCell>
                <TableCell>
                    {joinDefined(
                        [
                            result.ResidentialAddress1,
                            result.ResidentialAddress2,
                            result.ResidentialSuburb || result.ResidentialTown,
                            result.ResidentialState,
                            result.ResidentialPostcode || result.ResidentialPostCode,
                            result.ResidentialCountry || result.ResidentialCountry
                        ],
                        ' '
                    )}
                </TableCell>
                <TableCell>{result.UnknownDOB === true ? 'Unknown' : result.DateOfBirth}</TableCell>

                <TableCell className={classes.miniCol}>
                    <Radio
                        value={selectionID}
                        checked={
                            (!isNullOrUndefined(selectedEnquiry) && selectedEnquiry.selectionID === selectionID) ||
                            false
                        }
                        onChange={e => onSelectEnquiry(e.target.checked ? { ...result, selectionID } : null)}
                    />
                </TableCell>
            </TableRow>
        );
    };

    getQueryVariables() {
        const { searchTerm } = this.state;

        let variables = {
            limit: 10
        };

        const term = searchTerm ? searchTerm.trim() : '';

        if (!isNaN(parseInt(term, 10))) {
            // If the term is non-numeric then we do a `contains` search
            // otherwise we search for a `key` match
            variables.key = term;
            variables.contains = null;
        } else {
            variables.key = null;
            variables.contains = term;
        }

        return variables;
    }
}

const styles = ({ palette }) => ({
    root: {
        position: 'relative',
        flex: '1 1 auto'
    },
    search: {},
    miniCol: { width: 1 },
    resultContainer: {
        marginTop: '15px',
        overflow: 'auto',
        maxHeight: 600
    },
    resultTable: {},
    spinner: {
        margin: '8px 16px'
    },
    noResult: {
        padding: '8px 16px'
    },
    iconContainer: {
        alignSelf: 'center'
    },
    icon: {
        fontSize: 16,
        color: palette.action.active
    }
});

const readEnquiries = gql`
    ${PrefillFromEnquiryFragment}
    ${PrefillFromPrearrangementFragment}

    query searchEnquiryAndPrearrangment($contains: String, $key: String, $limit: Int) {
        searchEnquiries(contains: $contains, key: $key, limit: $limit) {
            edges {
                node {
                    ...PrefillFromEnquiryFragment
                }
            }
            pageInfo {
                totalCount
            }
        }
        searchPrearrangements(contains: $contains, key: $key, limit: $limit) {
            edges {
                node {
                    ...PrefillFromPrearrangementFragment
                }
            }
            pageInfo {
                totalCount
            }
        }
    }
`;

// prettier-ignore
export default compose(
    withSnackbarMessage,
    withStyles(styles)
)(EnquiryLookup);
