import gql from 'graphql-tag';
import { joinDefined } from './strings';
import { isNullOrUndefined } from './objects';

/**
 * Extends the base gql function with the ability
 * to pass in arrays of fragments as arguments.
 */
export function gqlArr(literals, ...args) {
    const newLiterals = [literals[0]];
    const newArgs = [];
    args.forEach((arg, i) => {
        if (Array.isArray(arg)) {
            newArgs.push(...arg);
            if (arg.length > 0) {
                const filler = Array(arg.length - 1).fill('');
                newLiterals.push(...filler);
                newLiterals.push(literals[i + 1]);
            }
        } else {
            newLiterals.push(literals[i + 1]);
            newArgs.push(arg);
        }
    });
    return gql(newLiterals, ...newArgs);
}

/**
 * Builds a single fragment combining the given list of fragments.
 * The combined fragment selects all necessary data for a particular funeral form tab.
 */

export function buildTabDataFragment(rootFragmentName, fragments) {
    return gqlArr`
          fragment ${rootFragmentName}Fragment on ${rootFragmentName} {
              ID
              ${fragments.map(f => `...${f.definitions[0].name.value}`).join('\n')}
          }
          ${fragments}
      `;
}

// Builds a lookup of funeral field names to the keys of the tabs that query/mutate those fields
export const buildTabFragmentLookup = (tabs, v2 = false) => {
    return tabs.reduce((lookup, tab) => {
        tab.fragment.definitions.forEach(d =>
            d.selectionSet.selections.forEach(selection => mapTabFragment(lookup, tab.id, selection, null, v2))
        );
        return lookup;
    }, {});
};

//maps fragments and nested fragments
const mapTabFragment = (lookup, lookupKey, selection, prefix = null, v2 = false) => {
    if (isNullOrUndefined(selection.name)) {
        if (v2 && selection.selectionSet) {
            selection.selectionSet.selections.forEach(s => mapTabFragment(lookup, lookupKey, s, prefix, v2));
        }
        return;
    }

    const fieldName = joinDefined([prefix, selection.name.value], '_');
    if (fieldName === 'ID') return;
    if (!lookup[fieldName]) lookup[fieldName] = [];
    if (!lookup[fieldName].includes(lookupKey)) lookup[fieldName].push(lookupKey);
    if (selection.selectionSet) {
        selection.selectionSet.selections.forEach(s => mapTabFragment(lookup, lookupKey, s, fieldName, v2));
    }
};

/**
 * turns tabs into the Fragments object
 * @param {*} tabs
 */
export const createFragments = tabs => {
    let fragments = {};
    tabs.forEach(x => (fragments[x.id] = x.fragment));
    return fragments;
};

/**
 * Flattens edges and nodes in a connection data structure.
 * i.e. data => Children => edges => node becomes data => Children
 */
export function flattenConnection(data, propertyName) {
    if (!data || !data[propertyName] || data[propertyName].edges === undefined) return;
    data[propertyName] = data[propertyName].edges.map(e => e.node);
}

/**
 * turns collection of objects into their respective ids
 * used when there's a relation
 */
export function reduceToIds(array) {
    for (let x = 0; x < array.length; x++) {
        array[x] = {
            ID: array[x].ID
        };
    }
}

/**
 * turns an object to an id if it exists
 */
export function reduceToId(obj, propertyName) {
    if (!obj[propertyName]) return;

    obj[`${propertyName}ID`] = obj[propertyName].ID;
    delete obj[propertyName];
}

/* checks if a gql object is null or undefined
 */
export const isRelatedObjectUndefined = obj => {
    return isNullOrUndefined(obj) || obj.ID === '0' || isNullOrUndefined(obj.ID);
};
