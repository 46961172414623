import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SearchTermConsumer } from '../context/searchTerm';
import SearchQuery from '../component/query/SearchQuery';
import { getFuneralHomeByURLSegment } from '../util/funerals';
import SearchTable from './search/SearchTable';
import LinearProgress from '@material-ui/core/LinearProgress';
import solrLogo from '../_asset/solr_logo_rgb_small.png';

class Search extends Component {
    state = {
        usingSolr: true
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <SearchTermConsumer>{this.renderQuery}</SearchTermConsumer>
            </div>
        );
    }

    renderQuery = ({ searchTerm, sortBy }) => {
        let { brand } = this.props.match.params;
        if (brand && !(brand === 'all' || brand === 'prearrangements' || brand === 'recent')) {
            const funeralBrand = getFuneralHomeByURLSegment(brand);
            brand = funeralBrand ? funeralBrand.letterCode : null;
        }
        return (
            <SearchQuery term={searchTerm} brand={brand} limit={20} sortBy={sortBy}>
                {this.renderResults}
            </SearchQuery>
        );
    };

    renderResults = (data) => {
        const { term, results, brand, onLoadMore, networkStatus, solr, prearrangements } = data;
        const { classes } = this.props;
        const loading = networkStatus === 3 || networkStatus === 2 || networkStatus === 1;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    {solr && <img src={solrLogo} alt={'solr logo'} title={'Using SOLR results'} />}
                    {(networkStatus === 1 && <span>Searching...</span>) ||
                    (brand === 'recent' ? (
                        <span>Recent Funeral Updates</span>
                    ) : (
                        <span>{!!prearrangements ? 'Prearrangements' : 'Funerals'}</span>
                    ))}
                    {!!term ? <span className={classes.searchTerm}>{term}</span> : ''}
                </Typography>
                <div className={classes.results}>
                    {(() => {
                        if (results && results.length && networkStatus !== 2 && networkStatus !== 1) {
                            return (
                                <SearchTable
                                    solr={!!solr || brand === 'recent'}
                                    results={results}
                                    onLoadMore={onLoadMore}
                                    loading={loading}
                                    prearrangements={prearrangements}
                                />
                            );
                        }
                    })()}

                    {this.renderStates(data, loading)}
                </div>
            </Fragment>
        );
    };

    renderStates(data, loading) {
        const { classes } = this.props;
        const { term, results, networkStatus, brand, error, prearrangements, totalCount } = data;
        if (error) return <Typography className={classes.message}>{error.message}</Typography>;
        if (loading) return <LinearProgress />;
        if (!(term || brand || prearrangements))
            return <Typography className={classes.message}>Type in the input to search</Typography>;
        if (networkStatus !== 7) return <LinearProgress />;
        if (!totalCount && results) return <Typography className={classes.message}>No results.</Typography>;
        return false;
    }
}

const styles = ({ typography, palette, funeralHome }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    pageTitle: {
        fontWeight: typography.fontWeightRegular,
        color: palette.action.active
    },
    results: {
        flex: '1 1 auto',
        position: 'relative',
        backgroundColor: palette.common.white
    },
    table: {
        width: '100%',
        maxHeight: '100%',
        borderSpacing: 0,
        '& tr:first-child': {
            backgroundColor: palette.contentBackground[funeralHome]
        },
        '& td,th': {
            padding: '8px 16px',
            textAlign: 'left'
        }
    },
    message: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    searchTerm: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    }
});

export default withStyles(styles)(Search);
