import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../icon/SearchIcon';
import TextField from '@material-ui/core/TextField';
import Spinner from '../Spinner';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';
import SearchQuery from '../query/SearchQuery';
import HeaderSearchResults from './HeaderSearchResults';
import { SearchTermConsumer, withSearchTerm } from '../../context/searchTerm';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import GatedComponent from '../GatedComponent';

class HeaderSearchInput extends Component {
    state = {
        focused: false,
        isOnSearchPage: false,
        isPrearrangements: false
    };

    componentDidMount() {
        this.unlisten = this.props.history.listen(debounce(this.onUpdatePage, 100));
        this.onUpdatePage(this.props.location);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open && this.input) this.input.focus();
    }

    componentWillUnmount() {
        this.unlisten();
    }

    onUpdatePage = location => {
        const isOnSearchPage = location.pathname.substr(0, 7) === '/search';
        if (this.state.isOnSearchPage !== isOnSearchPage) {
            this.setState({ isOnSearchPage });
        }

        const isPrearrangements = location.pathname.includes('prearrangements');
        if (this.state.isPrearrangements !== isPrearrangements) {
            this.setState({ isPrearrangements });
        }
    };

    onInputRef = input => {
        this.input = input;
    };

    onInputFocus = () => {
        this.setState({ focused: true });
    };

    onInputBlur = () => {
        this.setState({ focused: false });
    };

    onItemSelected = (term) => {
        this.input.blur();
        this.props.setSearchTerm(term);
    };

    onSubmit = e => {
        e.preventDefault();
        if (!this.state.isOnSearchPage) {
            this.props.history.push('/search');
        }
    };

    render() {
        return <SearchTermConsumer>{this.renderQuery}</SearchTermConsumer>;
    }

    renderQuery = ({ searchTerm }) => {
        const { focused, isOnSearchPage } = this.state;
        const sortBy = [{ field: 'LastEdited', direction: 'DESC' }];
        return (
            <SearchQuery sortBy={sortBy} term={searchTerm} skip={!focused || isOnSearchPage} oneLoad>
                {({ term, resultsTerm, results, totalCount }) => (
                    <Downshift>
                        {downshiftData => this.renderContainer(term, resultsTerm, results, downshiftData, totalCount)}
                    </Downshift>
                )}
            </SearchQuery>
        );
    };

    renderContainer(term, resultsTerm, results, downshiftData, totalCount) {
        const { classes, className } = this.props;
        const { focused, isOnSearchPage } = this.state;
        const elevated = isOnSearchPage || focused;
        return (
            <div className={cx(classes.root, className)}>
                <Paper className={cx(classes.paper, elevated && classes.paperElevated)} elevation={elevated ? 1 : 0}>
                    {this.renderInput(downshiftData, !isOnSearchPage && term && term !== resultsTerm)}
                </Paper>
                {focused && (
                    <HeaderSearchResults
                        term={term}
                        resultsTerm={resultsTerm}
                        results={results}
                        downshiftData={downshiftData}
                        onItemSelected={this.onItemSelected}
                        totalCount={totalCount}
                    />
                )}
            </div>
        );
    }

    renderInput(downshiftData, loading) {
        const { classes, open, searchTerm, setSearchTerm } = this.props;
        const { isPrearrangements } = this.state;
        // This is to shut up linting errors because it's case insensitive
        const otherProps = {
            inputProps: {
                ref: this.onInputRef,
                tabIndex: open ? 0 : -1,
                onChange: e => setSearchTerm(e.target.value),
                value: searchTerm,
                onFocus: this.onInputFocus,
                onBlur: this.onInputBlur
            }
        };

        return (
            <GatedComponent showComponentCode={'FM_ACCESS_Funeral_View'}>
                {() => {
                    return (
                        <form onSubmit={this.onSubmit}>
                            <TextField
                                style={{ marginBottom: 0 }}
                                fullWidth
                                {...otherProps}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.iconContainer}>
                                            {loading ? <Spinner /> : <SearchIcon className={classes.icon} />}
                                        </InputAdornment>
                                    ),
                                    ...downshiftData.getInputProps({
                                        placeholder:
                                            'Type in key or name to search existing ' +
                                            (!isPrearrangements ? 'funerals' : 'prearrangements')
                                    })
                                }}
                            />
                        </form>
                    );
                }}
            </GatedComponent>
        );
    }
}

const styles = ({ palette }) => ({
    root: {
        position: 'relative',
        maxWidth: 600
    },
    paper: {
        padding: 8
    },
    paperElevated: {
        border: `1px solid ${palette.grey.A100}`
    },
    iconContainer: {
        alignSelf: 'center'
    },
    icon: {
        fontSize: 16,
        color: palette.action.active
    }
});

// prettier-ignore
export default compose(
    withSearchTerm,
    withRouter,
    withStyles(styles)
)(HeaderSearchInput);
