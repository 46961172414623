import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import history from './History';
import { clearToken, getToken, isSignedIn, isTokenExpired, refreshToken } from './util/sessions';
import DebounceLink from 'apollo-link-debounce';
import { setSnackbarMessage } from './context/SnackbarMessage';

let SERVER_URL = 'https://cms-funeralmanager-dev.internetrix.net';
// SERVER_URL = 'http://hparsons.php72.local';
let introspectionQueryResultData = require('./fragmentTypesDev');

if (window.location.hostname === 'funeralmanager.internetrix.net') {
    SERVER_URL = 'https://cms-funeralmanager.internetrix.net';
    introspectionQueryResultData = require('./fragmentTypesProduction');
} else if (window.location.hostname === 'funeralmanager-uat.internetrix.net') {
    SERVER_URL = 'https://cms-funeralmanager-uat.internetrix.net';
    introspectionQueryResultData = require('./fragmentTypesUAT');
} else if (
    window.location.hostname === 'funeralmanager.com.au' ||
    window.location.hostname === 'www.funeralmanager.com.au'
) {
    SERVER_URL = 'https://cms.funeralmanager.com.au';
    introspectionQueryResultData = require('./fragmentTypesProduction');
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

export const GQL_ENDPOINT_PRIMARY = '/fm-graphql';
export const GQL_ENDPOINT_UTILITIES = '/fmutilities-graphql';
export const GQL_ENDPOINT_AUTH_JWT = '/authenticate/graphql';
export const GQL_ENDPOINT_ENQUIRIES = '/em-graphql';
export const GQL_ENDPOINT_TRANSFERS = '/tm-graphql';
export const GQL_ENDPOINT_CALENDAR = '/fm-calendar-graphql';
export const GQL_ENDPOINT_DEFAULT = '/graphql';
export const GQL_ENDPOINT_ADMIN = '/admin/graphql';
export const GQL_POLL_INTERVAL = 60 * 60 * 1000; // 1hr heartbeat, effectively unused

let SERVICE_URL = SERVER_URL + GQL_ENDPOINT_PRIMARY;

let client;

export function getServerLink() {
    return SERVER_URL;
}

/**
 * This link is executed after a request fails.
 * We use this to determine if the request has failed due to authorization.
 * If so we reset the client and redirect to the sign in page.
 */
const handleError = error => {
    const { graphQLErrors, networkError } = error;
    const errorList = [];
    if (!!networkError) errorList.push({ message: networkError });
    if (!!graphQLErrors && graphQLErrors.length) graphQLErrors.forEach(err => errorList.push({ message: err.message }));
    errorList.forEach(err => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'Snackbar Message',
            Message: err.message || null
        });
    });

    // A bit hacky, but whenever you get an error due to being unauthorized the
    // error message from the graphql server always starts with `Cannot view`
    if (
        graphQLErrors &&
        graphQLErrors.some(e => e.message.startsWith('Cannot view') || e.message.endsWith('view access not permitted'))
    ) {
        setSnackbarMessage('Unauthorised user was logged out.');
        clearToken();
        resetClient();
        return;
    }

    if (graphQLErrors) {
        let graphQLErrorsString = '';
        graphQLErrors.map(({ message }) => {
            if (graphQLErrorsString !== '') {
                graphQLErrorsString += '\n \n';
            }
            graphQLErrorsString += `[GraphQL error]: ${message}`;
            return graphQLErrorsString;
        });
        // Omg snackbar from anywhere.
        setSnackbarMessage(graphQLErrorsString);
        console.error(graphQLErrorsString);
    }

    if (networkError) {
        let errorsString = `[Network error]: ${networkError}`;
        setSnackbarMessage(errorsString);
        console.error(errorsString);
    }
};

/**
 * This link will debounce graphql requests which have a `debounceKey`
 * in the context.
 */
function getDebounceLink() {
    return new DebounceLink(250);
}

/**
 * This link actually makes the HTTP request, after the request has been
 * processed by the other link middleware.
 */
function getHttpLink() {
    let URL = SERVICE_URL;
    /* TODO: remove this API profiling config in production ? */
    if (document.cookie.indexOf('XDEBUG_PROFILE') >= 0) {
        URL += '?XDEBUG_PROFILE=1';
    }
    return new HttpLink({
        uri: URL,
        fetch: refreshFetch
    });
}

/**
 * A custom fetch function which adds auth headers to the request and
 * will attempt to refresh the token before a request if it's found
 * to be expired.
 */

function refreshFetch(uri, options) {
    // TODO: It'd be better if we could do this after a failed request with 401
    // instead of doing this preemptively before every outgoing request
    if (isTokenExpired() && JSON.parse(options.body).operationName !== 'RefreshToken') {
        // If the current stored token has expired and this is not the request to refresh
        // the token, then we execute the refresh request before the original request

        return refreshToken(getAuthClient()).then(success => {
            return success === false ? resetClient() : executeFetch(uri, options);
        });
    }

    return executeFetch(uri, options);
}

function executeFetch(uri, options) {
    const token = getToken();
    if (token && token.value) options.headers.authorization = `Bearer ${token.value}`;

    return fetch(uri, options);
}

export function resetClient() {
    // We need to redirect to the /sign-in page first to un-mount all components with active queries
    history.push('/sign-in');
    client.clearStore().then(() => client.resetStore());
    authClient.clearStore().then(() => authClient.resetStore());
}

function dataIdFromObject(obj) {
    const typeName = obj.__typename.toLowerCase();
    let idVal;
    switch (typeName) {
        default:
            idVal = obj.id || obj.Id || obj.ID;
    }
    if (!idVal) {
        if (
            !typeName.endsWith('connection') &&
            !typeName.endsWith('edge') &&
            !typeName.endsWith('pageinfo') &&
            !typeName.endsWith('filterbyinfo') &&
            !typeName.endsWith('filterbyinfooptions') &&
            !typeName.endsWith('filterbyoption') &&
            !typeName.endsWith('__type') &&
            !typeName.endsWith('__inputvalue') &&
            !typeName.endsWith('__enumvalue') &&
            !typeName.endsWith('_multivaluefield') &&
            !typeName.endsWith('_multivaluefieldlist') &&
            !typeName.endsWith('metricsresult') &&
            0 !== Number(idVal)
        )
            console.warn('ID missing on object ' + obj.__typename, obj);
        return undefined;
    }

    // catch joins and cache separately. gotta catch 'em all!
    if (!!obj.Join) {
        idVal += ':' + obj.Join.__typename + ':' + obj.Join.ID;
    }

    return `${obj.__typename}:${idVal}`;
}

export function getClient() {
    if (client) return client;

    client = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(getHttpLink()),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    window.__client = client;

    client.onResetStore(() => {
        //history.push('/');
    });

    return client;
}

/**
 * Client for JWT authentication GQL endpoint `/authenticate/graphql`.
 */
let authClient;

export function getAuthClient() {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (authClient && isSignedIn()) return authClient;

    authClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: SERVER_URL + GQL_ENDPOINT_AUTH_JWT,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache()
    });

    return authClient;
}

/**
 * Client for JWT authentication GQL endpoint `/authenticate/graphql`.
 */
let utilitiesClient;

export function getUtilitiesClient() {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (utilitiesClient && isSignedIn()) return utilitiesClient;

    utilitiesClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: SERVER_URL + GQL_ENDPOINT_UTILITIES,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    return utilitiesClient;
}

/**
 * Client for enquires GQL endpoint `em-graphql`.
 */
let enquiryClient;

export function getEnquiryClient() {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (enquiryClient && isSignedIn()) return enquiryClient;

    enquiryClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: SERVER_URL + GQL_ENDPOINT_ENQUIRIES,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    return enquiryClient;
}

/**
 * Client for transfers GQL endpoint `tm-graphql`.
 */
let transfersClient;

export function getTransfersClient() {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (transfersClient && isSignedIn()) return transfersClient;

    transfersClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: SERVER_URL + GQL_ENDPOINT_TRANSFERS,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    return transfersClient;
}

/**
 * Client for transfers GQL endpoint `tm-graphql`.
 */
let calendarClient;

export function getCalendarClient() {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (calendarClient && isSignedIn()) return calendarClient;

    calendarClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: SERVER_URL + GQL_ENDPOINT_CALENDAR,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    return calendarClient;
}

/**
 * Client for generic things GQL endpoint `/graphql`.
 */
let myClient;

export function getMyClient() {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (myClient && isSignedIn()) return myClient;

    myClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: SERVER_URL + GQL_ENDPOINT_DEFAULT,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    return myClient;
}

/**
 * Client for Assets GQL endpoint `/admin/graphql`.
 */
let assetsClient;

export function getAssetsClient() {
    if (assetsClient) return assetsClient;

    const uploadLink = createUploadLink({
        uri: SERVER_URL + GQL_ENDPOINT_ADMIN,
        fetch: refreshFetch
    });

    assetsClient = new ApolloClient({
        link: ApolloLink.from([onError(handleError), getDebounceLink(), uploadLink]),
        cache: new InMemoryCache()
    });

    return assetsClient;
}

export function getServiceURLHostname() {
    return extractHostname(SERVICE_URL);
}

function extractHostname(url) {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
}

export function isLocalServer() {
    if (SERVICE_URL.indexOf('.local') !== -1) return true;
    if (SERVICE_URL.indexOf('.webdev') !== -1) return true;
    return false;
}

export function isLocalClient() {
    if (window.location.host.indexOf('localhost:300') === 0) return true;
    return false;
}
