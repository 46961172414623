import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TrashIcon from '../icon/TrashIcon';
import { joinDefined } from '../../util/strings';

const styles = ({ transitions }) => ({
    userCircleCell: {
        width: '50px'
    },

    userCircle: {
        height: '35px',
        width: '35px',
        background: '#7BDBFF',
        borderRadius: '20px',
        fontSize: '15px',
        textAlign: 'center',
        color: '#FFFFFF',
        display: 'inline-block',
        position: 'relative',
        '& > *': {
            lineHeight: '34px'
        },
        '&:hover > :nth-child(2)': {
            opacity: '1'
        }
    },

    deleteUser: {
        cursor: 'pointer',
        borderRadius: '100%',
        background: '#EEEEEE',
        display: 'inline-block',
        opacity: '0',
        position: 'absolute',
        height: '35px',
        width: '35px',
        left: '0',
        top: '0',
        color: '#000000',
        transition: transitions.create(['opacity']),
        '& > svg': {
            height: '15px',
            width: '15px',
            margin: '8px 0px 0px 1px'
        }
    },

    abbreviation: {
        transition: transitions.create(['opacity'])
    }
});

const UserCircle = ({ classes, user, person, abbreviation, onDelete }) => {
    const personName = person && joinDefined([person.FirstName, person.Surname], ' ');
    const content = (
        <span className={classes.userCircle}>
            <span className={classes.abbreviation}>{abbreviation || GetInitials(person)}</span>
            {onDelete && (
                <span className={classes.deleteUser} onClick={() => onDelete()}>
                    <TrashIcon />
                </span>
            )}
        </span>
    );

    if (onDelete) {
        return <Tooltip title={`Remove ${personName || user}`}>{content}</Tooltip>;
    }

    return <Tooltip title={`${personName || user}`}>{content}</Tooltip>;
};

export const GetInitials = user => {
    return !!user && firstLetter(user.FirstName) + firstLetter(user.Surname);
};

const firstLetter = str => {
    if (!str) return '';

    str = str.trim();

    if (str.length === 0) return '';

    return str[0].toUpperCase();
};

export default withStyles(styles)(UserCircle);
