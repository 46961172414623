import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo';
import { getUser } from '../../util/sessions';
import { getMyClient } from '../../apollo';
import WorkQueueView from './WorkQueueView';

function WorkQueue(props) {
    return (
        <div className="workqueue-page">
            <WorkQueueView client={getMyClient} user={getUser()} props={props} />
        </div>
    );
}

export default compose(withSnackbarMessage, withStyles({}))(WorkQueue);
