import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import Fragment from '@material-ui/core/Hidden';
import blue from '@material-ui/core/colors/blue';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '../icon/SearchIcon';
import { clearToken, getUser } from '../../util/sessions';
import cx from 'classnames';
import EditContactModal from '../EditContactModal';
import NotificationsPopover from '../../page/workQueue/notifications/NotificationPopover';
import CreateEditTaskModal from '../../page/workQueue/taskModal/CreateEditTaskModal';
import { createOrUpdateTaskFunc } from '../../page/workQueue/components/GetSaveTasks';
import AppointmentPopover from '../../page/calendar/AppointmentPopover';
import CalendarIcon from '../icon/CalendarIcon';
import ContactsIcon from '../icon/ContactsIcon';
import StarIcon from '../icon/StarIcon';
import PayableIcon from '../icon/PayableIcon';
import GatedComponent from '../GatedComponent';
import { resetClient } from '../../apollo';
import { setSnackbarMessage } from '../../context/SnackbarMessage';
import TaskModal from '../../page/workQueue2/Modals/TaskModal';
import Fab from '@material-ui/core/Fab';
import { joinDefined } from '../../util/strings';
import { FUNERAL_HOMES } from '../../util/funerals';
import { theme } from '../../Theme';
import { CapsuleLegend } from '../form/Legend';
// import TransfersIcon from '../icon/TransfersIcon';
// import NewTransferModal from '../../page/transfers/NewTransferModal';

class HeaderActions extends Component {
    state = {
        addressBookModalOpen: false,
        appointmentModalOpen: false,
        newTransferModalOpen: false,
        showCreateEditTaskModal: false,
        showNewTaskModal: false,
        userMenuAnchor: undefined,
        addMenuAnchor: undefined
    };

    onToggleUserMenu = (e = {}) => {
        this.setState({ userMenuAnchor: this.state.userMenuAnchor ? undefined : e.currentTarget });
    };

    onToggleAddMenu = (e = {}) => {
        this.setState({ addMenuAnchor: this.state.addMenuAnchor ? undefined : e.currentTarget });
    };

    onClickProfile = () => {
        this.onToggleUserMenu();
        this.props.history.push('/account');
    };

    onClickLogout = () => {
        this.onToggleUserMenu();
        clearToken();
        resetClient();
        setSnackbarMessage('You have signed out.', true);
    };

    onCloseNewContact = () => {
        this.setState({ addressBookModalOpen: false });
    };

    render() {
        const { addressBookModalOpen } = this.state;
        return (
            <GatedComponent isEnabledCode={'OBJ_EDIT_ALL'}>
                {(canAccess, data) => {
                    return (
                        <div>
                            {!!canAccess && this.renderAddButton()}
                            {!!canAccess && this.renderNotificationsButton()}
                            {this.renderUserButton(data)}
                            {!!canAccess && this.renderSearchButton()}
                            {this.renderUserMenu(data, canAccess)}
                            {!!canAccess && this.renderAddMenu()}
                            {!!canAccess && (
                                <Fragment>
                                    <EditContactModal open={!!addressBookModalOpen} onClose={this.onCloseNewContact} />
                                    <CreateEditTaskModal
                                        onClose={() => this.setState({ showCreateEditTaskModal: false })}
                                        open={this.state.showCreateEditTaskModal}
                                        onSubmit={task => createOrUpdateTaskFunc(task, false)}
                                        user={getUser()}
                                    />
                                    <TaskModal
                                        open={this.state.showNewTaskModal}
                                        onClose={() => this.setState({ showNewTaskModal: false })}
                                    />
                                    {/*<NewTransferModal
                                        open={!!this.state.newTransferModalOpen}
                                        onClose={() => this.setState({ newTransferModalOpen: false })}
                                    />*/}
                                    <AppointmentPopover
                                        open={!!this.state.appointmentModalOpen}
                                        onClose={() =>
                                            this.setState({
                                                appointmentModalOpen: false,
                                                appointmentDefaults: null,
                                                editAppointmentId: null
                                            })
                                        }
                                        allowLocation={true}
                                        allowRecurring={true}
                                    />
                                </Fragment>
                            )}
                        </div>
                    );
                }}
            </GatedComponent>
        );
    }

    renderAddButton() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Hidden xsDown>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.addButtonDesktop}
                        classes={{ label: classes.addButtonLabel }}
                        onClick={this.onToggleAddMenu}
                    >
                        <Icon>add</Icon>&nbsp;Add New...
                    </Button>
                </Hidden>
                <Hidden smUp>
                    <Fab color="primary" className={classes.addButtonMobile} onClick={this.onToggleAddMenu}>
                        <Icon>add</Icon>
                    </Fab>
                </Hidden>
            </Fragment>
        );
    }

    renderNotificationsButton() {
        return <NotificationsPopover />;
    }

    renderUserButton({ readCurrentUser }) {
        const { classes } = this.props;
        const profileName =
            joinDefined(
                [
                    readCurrentUser.FirstName ? readCurrentUser.FirstName.charAt(0) : '',
                    readCurrentUser.Surname ? readCurrentUser.Surname.charAt(0) : ''
                ],
                ''
            ) || '?';

        const office =
            readCurrentUser.MyLastOfficeID && FUNERAL_HOMES.find(e => e.ID === Number(readCurrentUser.MyLastOfficeID));
        if (office)
            return (
                <CapsuleLegend
                    onClick={this.onToggleUserMenu}
                    title={office.label}
                    className={classes.capsuleLegend}
                    text={
                        <span>
                            <span style={{ margin: '0 6px' }}>
                                <Hidden xsDown>{office && office.label}</Hidden>
                                <Hidden smUp>{office && office.letterCode}</Hidden>
                            </span>
                            <Fab
                                title={joinDefined([readCurrentUser.FirstName, readCurrentUser.Surname], ' ')}
                                color="primary"
                                aria-label="user settings"
                                className={cx(classes.userButton, classes.marginLeft)}
                                classes={{ label: classes.userButtonLabel }}
                                onClick={this.onToggleUserMenu}
                            >
                                {profileName}
                                <Icon className={classes.userArrow}>keyboard_arrow_down</Icon>
                            </Fab>
                        </span>
                    }
                    foreground={theme.palette.contentForeground[office.letterCode]}
                    background={theme.palette.contentBackground[office.letterCode]}
                />
            );
        return (
            <Fab
                title={joinDefined([readCurrentUser.FirstName, readCurrentUser.Surname], ' ')}
                color="primary"
                aria-label="user settings"
                className={cx(classes.userButton, classes.marginLeft)}
                classes={{ label: classes.userButtonLabel }}
                onClick={this.onToggleUserMenu}
            >
                {profileName}
                <Icon className={classes.userArrow}>keyboard_arrow_down</Icon>
            </Fab>
        );
    }

    renderSearchButton() {
        const { classes, onToggleSearch } = this.props;
        return (
            <Hidden smUp>
                <IconButton className={classes.iconButton} onClick={onToggleSearch}>
                    <SearchIcon className={classes.searchIcon} />
                </IconButton>
            </Hidden>
        );
    }

    renderUserMenu({ readCurrentUser }, canAccess) {
        const anchorEl = this.state.userMenuAnchor;

        return (
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={this.onToggleUserMenu}
                transformOrigin={{ vertical: -68, horizontal: 'left' }}
            >
                <MenuItem style={{ paddingTop: 24, outline: 0 }} button={false}>
                    <span>
                        {readCurrentUser.FirstName} {readCurrentUser.Surname}
                        <br />
                        <small style={{ opacity: 0.5 }}>{readCurrentUser.Email}</small>
                    </span>
                </MenuItem>
                {!!canAccess && <MenuItem onClick={this.onClickProfile}>Account Settings</MenuItem>}
                <MenuItem onClick={this.onClickLogout}>Sign Out</MenuItem>
            </Menu>
        );
    }

    renderAddMenu() {
        const { classes } = this.props;
        const anchorEl = this.state.addMenuAnchor;
        return (
            <Menu
                id="add-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={this.onToggleAddMenu}
                transformOrigin={{ vertical: -68, horizontal: 'left' }}
            >
                <GatedComponent showComponentCode={'FM_ACCESS_Enquiries_Create'}>
                    {(isEnabled, { readCurrentUser }) => {
                        const { letterCode } = (readCurrentUser && readCurrentUser.MyLastOfficeID && FUNERAL_HOMES.find(e => e.ID === Number(readCurrentUser.MyLastOfficeID))) || {};
                        return (
                            <MenuItem
                                className="targetNewPage"
                                onClick={() => {
                                    this.onToggleAddMenu();
                                    const newPath = `/quotes/enquiry/${letterCode || 'P'}/0`;
                                    let win = window.open(newPath, '_blank');
                                    win.focus();
                                }}
                            >
                                <PayableIcon className={classes.newIcon} />
                                Enquiry
                            </MenuItem>
                        );
                    }}
                </GatedComponent>

                {/* <GatedComponent showComponentCode={'FM_ACCESS_Enquiries_Create'}>
                    {() => {
                        return (
                            <MenuItem onClick={() => this.setState({ newTransferModalOpen: true })}>
                                <TransfersIcon className={classes.newIcon} />
                                Transfer...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>*/}

                <GatedComponent showComponentCode={'FM_ACCESS_Contacts_Create'}>
                    {() => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    this.onToggleAddMenu();
                                    this.setState({ addressBookModalOpen: true });
                                }}
                            >
                                <ContactsIcon className={classes.newIcon} /> Contact...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>
                {1 === 2 && (
                    <GatedComponent showComponentCode={'FM_ACCESS_Tasks_Create'}>
                        {() => {
                            return (
                                <MenuItem
                                    onClick={() =>
                                        this.setState({ showCreateEditTaskModal: true }, () => this.onToggleAddMenu())
                                    }
                                >
                                    <StarIcon className={classes.newIcon} /> Task...
                                </MenuItem>
                            );
                        }}
                    </GatedComponent>
                )}
                <GatedComponent showComponentCode={'FM_ACCESS_Tasks_Create'}>
                    {() => {
                        return (
                            <MenuItem
                                onClick={() => this.setState({ showNewTaskModal: true }, () => this.onToggleAddMenu())}
                            >
                                <StarIcon className={classes.newIcon} /> Task...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>
                <GatedComponent showComponentCode={'FM_ACCESS_Appointments_Create'}>
                    {() => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    this.onToggleAddMenu();
                                    this.setState({ appointmentModalOpen: true });
                                }}
                            >
                                <CalendarIcon className={classes.newIcon} />
                                Appointment...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>
            </Menu>
        );
    }
}

const styles = ({ spacing, palette }) => ({
    addButtonDesktop: {
        boxShadow: 'none',
        borderRadius: 24,
        height: 40,
        padding: '0 16px'
    },
    addButtonMobile: {
        width: 40,
        height: 40,
        boxShadow: 'none',
        flex: '0 0 auto'
    },
    addButtonLabel: {
        textTransform: 'none',
        // fontWeight: 'bold',
        fontSize: 14
    },
    iconButton: {
        marginLeft: spacing.unit,
        flex: '0 0 auto'
    },
    userButton: {
        backgroundColor: blue[200],
        width: 40,
        height: 40,
        boxShadow: 'none',
        // marginLeft: spacing.unit * 2,
        // marginRight: spacing.unit * 2,
        flex: '0 0 auto'
    },
    userButtonLabel: {
        fontSize: '1rem'
    },
    searchIcon: {
        fontSize: 16,
        color: palette.action.active
    },
    userArrow: {
        position: 'absolute',
        color: palette.common.black,
        right: -12,
        bottom: -8,
        fontSize: 16
    },
    newIcon: {
        fontSize: 16,
        marginRight: 16,
        color: palette.action.active
    },
    capsuleLegend: {
        display: 'inline-block',
        position: 'relative',
        marginLeft: spacing.unit * 2,
        marginRight: spacing.unit,
        whiteSpace: 'pre',
        padding: 2,
        width: 'unset',
        cursor: 'pointer'
    }
});

export default withRouter(withStyles(styles)(HeaderActions));
