import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { niceDateFromString } from '../../util/date';
import { isNullOrUndefined } from '../../util/objects';
import { getUser } from '../../util/sessions';
import { joinDefined } from '../../util/strings';
import Typography from '@material-ui/core/Typography';
import UserCircle from '../../component/form/UserCircle';
import CommentsIcon from '../../component/icon/CommentsIcon';
import RepeatIcon from '../../component/icon/RepeatIcon';
import TickIcon from '../../component/icon/TickIcon';
import FlexGrid from './extras/FlexGrid';
import Grid from '../../component/form/Grid';
import Inline, { inlineAlignment } from './extras/Inline';
import Button from './extras/Button';
import RecurrenceModal from './Modals/RecurrenceModal';
import { getTaskStatus, getTaskStatusClass, taskURLObj, updateTaskFunc } from './TaskConstants';
import TaskStar from './TaskStar';
import CheckableStar from './CheckableStar';

class TaskWrapper extends Component {
    constructor(props) {
        super(props);

        this.status = null;
    }

    state = {
        showRecurrenceModal: false,
        item: null
    };

    static getDerivedStateFromProps({ task }, state) {
        if (!state.item || state.item.ID !== task.ID) {
            const newState = {};
            newState.item = task;
            return newState;
        }

        return null;
    }

    render() {
        const { variant, onClickAction, task } = this.props;
        const { showRecurrenceModal } = this.state;

        this.status = getTaskStatus(task);

        if (!task) return null;

        let card = null;

        if (variant === 'list') card = this.renderListItem(onClickAction);

        if (variant === 'card') card = this.renderCard(onClickAction);

        if (variant === 'goal') card = this.renderGoals(onClickAction);

        if (card) {
            return (
                <Fragment>
                    {card}

                    <RecurrenceModal
                        open={showRecurrenceModal}
                        task={task}
                        onClose={() => this.handleCloseRecurrenceModal()}
                    />
                </Fragment>
            );
        }

        return <span>unknown task variant {variant}</span>;
    }

    renderListItem(onClickAction) {
        const { loading } = this.state;
        const { task } = this.props;
        const item = task;
        const convertDateToCheck = date => !isNullOrUndefined(date);
        const related = taskURLObj(task);
        const me = getUser();
        const myTask = item && item.AssignedMembers.find(e => !!e && e.ID === me.ID);
        const myStar = myTask && myTask.Join.Starred;
        const statusClass = getTaskStatusClass(task);
        // const objBrand = taskURLObj(task);

        return (
            <div
                className={`task-card list${!!task.Archived ? ' archived' : ''}`}
                onClick={onClickAction ? () => onClickAction(item) : undefined}
            >
                <Grid container spacing={8}>
                    <Grid item pc>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center className="task-header-desktop">
                            <p className={`task-status ${statusClass}`}>
                                <span className="status-label">{this.status}</span>
                            </p>

                            {!!item.RecurFreq && (
                                <span
                                    title={
                                        'Task recurs ' +
                                        (item.RecurEnds
                                            ? 'until ' + niceDateFromString(moment(item.RecurEnds))
                                            : 'forever')
                                    }
                                >
                                    <IconButton className="task-repeat" disabled>
                                        <RepeatIcon />
                                    </IconButton>
                                </span>
                            )}

                            {item.Due && (
                                <p className={'due-date' + (this.status === 'Overdue' ? ' overdue' : '')}>
                                    <span className="bold">Due: </span>
                                    {moment(item.Due).format('ddd, ll')}
                                </p>
                            )}

                            <div>
                                <CheckableStar
                                    value={convertDateToCheck(myStar)}
                                    disabled={loading}
                                    onChange={checked => {
                                        const AssignedMembers = item.AssignedMembers.map(e => {
                                            e.Join = {
                                                Starred:
                                                    e.ID === me.ID
                                                        ? checked
                                                            ? new Date()
                                                            : null
                                                        : (e.Join && e.Join.Starred) || null
                                            };
                                            return e;
                                        });
                                        this.onStarChange('AssignedMembers', AssignedMembers);
                                    }}
                                />
                            </div>
                        </Inline>

                        <FlexGrid className="task-header-mobile">
                            <Inline center>
                                <p className={`task-status ${statusClass}`}>
                                    <span className="status-label">{this.status}</span>
                                </p>
                                <p className="record-id-mobile">#{item.ID}</p>
                                {item.Due && (
                                    <p className={'due-date' + (this.status === 'Overdue' ? ' overdue' : '')}>
                                        {moment(item.Due).format('ll')}
                                    </p>
                                )}
                            </Inline>
                            <div className="actions">
                                {!!item.RecurFreq && (
                                    <span
                                        title={
                                            'Task recurs ' +
                                            (item.RecurEnds
                                                ? 'until ' + niceDateFromString(moment(item.RecurEnds))
                                                : 'forever')
                                        }
                                    >
                                        <IconButton className="task-repeat" disabled>
                                            <RepeatIcon />
                                        </IconButton>
                                    </span>
                                )}
                                <TaskStar
                                    value={convertDateToCheck(myStar)}
                                    disabled={loading}
                                    onChange={checked => {
                                        const AssignedMembers = item.AssignedMembers.map(e => {
                                            e.Join = {
                                                Starred:
                                                    e.ID === me.ID
                                                        ? checked
                                                            ? new Date()
                                                            : null
                                                        : (e.Join && e.Join.Starred) || null
                                            };
                                            return e;
                                        });
                                        this.onStarChange('AssignedMembers', AssignedMembers);
                                    }}
                                />
                            </div>
                        </FlexGrid>
                    </Grid>
                    <Grid item pc>
                        <div className="left-margin text-section">
                            <h2 className="task-heading">{item.Title}</h2>
                            <p className="task-description">{item.Description}</p>
                        </div>
                    </Grid>

                    <Grid item pc>
                        <div className="left-margin">
                            <Inline alignment={inlineAlignment.rightAlignSiblings} center className="task-details">
                                {/*<a variant="link-orange" className="task-link">*/}
                                {/*    View Task Details*/}
                                {/*</a>*/}

                                <span className="record-id">Task #{item.ID}</span>
                                {(related && (
                                    <span className="record-id restrict">
                                        {related.category} {related.title}
                                    </span>
                                )) || <span className="record-id restrict">{item.Category}</span>}

                                {/*{objBrand && objBrand.title && objBrand.category && (
                                    <div className="task-brand">
                                        <TableKey legacyKey={objBrand.title} />
                                    </div>
                                )}*/}
                                <div className="tag-list">
                                    {item.AssignedLabels &&
                                        item.AssignedLabels.filter(e => e.Member.ID === me.ID && !e.NotActive).map(
                                            label => (
                                                <Button key={'user' + label.ID} variant="tag">
                                                    {label.Title}
                                                </Button>
                                            )
                                        )}
                                </div>
                                <div className="user-container">
                                    {item.AssignedMembers.map(me => (
                                        <UserCircle key={'user' + me.ID} person={me} />
                                    ))}
                                </div>
                            </Inline>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    onStarChange(propertyName, value) {
        const { task } = this.props;

        this.updateTask({
            ...task,
            [propertyName]: value
        });
    }

    updateTask(task) {
        const that = this;
        const { onStarClick } = this.props;

        return updateTaskFunc(task).then(
            result => {
                let updatedTask = cloneDeep(result.data.updateTask);
                this.setState({ item: updatedTask });
                if (onStarClick) {
                    onStarClick(updatedTask);
                }
            },
            e => that.onGqlError('Failed to update quote.', e)
        );
    }

    renderCard(onClickAction) {
        const { item } = this.state;
        const assignedMembers = item.AssignedMembers.map(x => joinDefined([x.FirstName, x.Surname], ' '));
        return (
            <div className="task-card dash-card">
                <Fab size="small" color="primary" aria-label="Check" className="fab check">
                    <TickIcon />
                </Fab>
                <Grid container spacing={16}>
                    <Grid item pc>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <p className="task-status new">
                                <span className="status-label">{this.status}</span>
                            </p>
                            <TaskStar />
                            <p className="due-date">
                                <span className="bold">Due: </span>
                                {niceDateFromString(item.Due)}
                            </p>
                        </Inline>
                    </Grid>
                    <Grid item pc>
                        <h1 className="record-id">Task #{item.ID}</h1>
                        <h2 className="task-heading">{item.Title}</h2>
                        <p className="task-description">{item.Description}</p>
                    </Grid>

                    <Grid item pc>
                        <Button variant="link-orange" onClick={onClickAction ? () => onClickAction(item) : undefined}>
                            {item.Action}
                        </Button>
                    </Grid>

                    <Grid item pc>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <Inline className="comments">
                                    <CommentsIcon />
                                    {item.Audits && item.Audits.edges.filter(e => Number(e.node.Type) === 0).length}
                                </Inline>
                            </div>

                            <Typography className="task-assigned">{joinDefined(assignedMembers, ', ')}</Typography>
                        </Inline>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderGoals(onClickAction) {
        const { item } = this.state;
        const assignedMembers = item.AssignedMembers.map(x => joinDefined([x.FirstName, x.Surname], ' '));
        return (
            <div className="task-card dash-card">
                <Fab
                    disabled
                    size="small"
                    color="primary"
                    aria-label="Check"
                    className={`fab check ${!item.Completed ? ' incomplete' : ''}`}
                >
                    <TickIcon />
                </Fab>
                <Grid container spacing={16}>
                    <Grid item pc>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <TaskStar disabled />
                            </div>
                            <p className="due-date">
                                <span className="bold">Due: </span>
                                {niceDateFromString(item.Due)}
                            </p>
                        </Inline>
                    </Grid>
                    <Grid item pc>
                        <h2 className="record-id">Goal:</h2>
                        <h3 className="task-heading">{item.Title}</h3>
                        <p className="task-description">{item.Description}</p>
                    </Grid>

                    <Grid item pc>
                        <Button variant="link-orange" onClick={onClickAction ? () => onClickAction(item) : undefined}>
                            {item.Action}
                        </Button>
                    </Grid>

                    <Grid item pc>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <Inline className="comments">
                                    <CommentsIcon />
                                    <Typography className="comments">
                                        {item.Audits && item.Audits.edges.filter(e => Number(e.node.Type) === 0).length}
                                    </Typography>
                                </Inline>
                            </div>

                            <Typography className="task-assigned">{joinDefined(assignedMembers, ', ')}</Typography>
                        </Inline>
                    </Grid>
                    <Grid item pc>
                        <Button variant="link-orange" className="ViewTaskLink" href={'/work-queue/task/' + item.ID}>
                            View Task
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    handleShowRecurrenceModal() {
        this.setState({ showRecurrenceModal: true });
    }

    handleCloseRecurrenceModal() {
        this.setState({ showRecurrenceModal: false });
    }
}

export default compose(withRouter, withStyles({}))(TaskWrapper);
