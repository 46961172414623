import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import Label from '../../../component/form/Label';
import Select from '../../../component/form/Select';
import TextField from '../../../component/form/TextField';
import LinkButton from '../../../component/form/LinkButton';
import PrimaryButton from '../../../component/form/PrimaryButton';
import OptionalTextField from '../../../component/form/OptionalTextField';
import AutoComplete from '../../../component/form/AutoComplete';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import CustomAddressModal from '../../../component/form/CustomAddressModal';
import CoronerModal from '../../../component/form/CoronerModal';
import { ADDRESS_BOOK_CATEGORY } from '../funeralConstants';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import { ContactCard, ContactCardContainer } from '../../../component/form/ContactCard';
import ContactCardModal from '../../../component/form/ContactCardModal';
import {
    APPLICANT_RELATIONSHIP_OPTIONS,
    CERTIFICATION_OF_DEATH,
    CORONERS_CAUSES,
    getRequiredDocuments,
    getSignedArrangementFormForDocument,
    POSTAL_ADDRESS_COMPONENTS,
    RESIDENTIAL_ADDRESS_COMPONENTS
} from './CertificationConstants';
import CoronerAutoComplete from '../../../component/form/CoronerAutoComplete';
import ColumnLayout from '../../../component/ColumnLayout';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { applyUrlParams, joinDefined, stringIsNullOrEmpty } from '../../../util/strings';
import PurchaseOrderAddressBookAutocomplete from '../../../component/bookings/PurchaseOrderAddressBookAutocomplete';
import { createCertificationPurchaseOrderMutation } from '../../../component/bookings/PurchaseOrderModal';
import ValidationPlaceholder from '../../../component/form/ValidationPlaceholder';
import Table, { TableCell, TableRow } from '../../../component/form/Table';
import ExclamationTriangleIcon from '../../../component/icon/ExclamationTriangleIcon';
import UploadIcon from '../../../component/icon/UploadIcon';
import cx from 'classnames';
import FileUpload, { getFileName, uploadFilesQuery } from '../../../component/form/FileUpload';
import { compose } from 'react-apollo';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';
import FamilyMemberAutocomplete from '../../../component/form/FamilyMemberAutocomplete';
import { getAssetsClient, getClient, getUtilitiesClient } from '../../../apollo';
import { getProducts } from '../../../util/products';
import { submitToXeroMutation } from '../billing/invoiceModal/GetSaveInvoice';
import { setSnackbarMessage } from '../../../context/SnackbarMessage';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import { Hidden } from '@material-ui/core';

class Certification extends Component {
    state = {
        coronerModalField: undefined,
        authorizedContactModalField: undefined,
        editContact: undefined,
        ApplicantFamilyMember: '',
        isDragOverRow: {}
    };

    static getDerivedStateFromProps({ form }, oldState) {
        const InformantsName = form && form.getField('Informant.InformantsName');
        const RelationshipToDeceased = form && form.getField('Informant.RelationshipToDeceased');
        if (
            !!InformantsName &&
            !!RelationshipToDeceased &&
            ['Husband', 'Wife', 'Daughter', 'Son', 'Father', 'Mother', 'Widow', 'Widower', 'Child', 'Parent'].indexOf(
                RelationshipToDeceased
            ) > -1
        ) {
            const newMember = '' + InformantsName + ' (' + RelationshipToDeceased + ')';
            return { ApplicantFamilyMember: newMember };
        }

        return null;
    }

    onChangePostalAddress = e => {
        const { form } = this.props;
        const { target } = e;
        if (target) {
            if (!target.checked) return;
        } else if (!form.getField('Informant.PostalSameAsResidential')) return;

        const postalAddress = form.getField('Informant.ResidentialAddress1');
        const postalSuburb = form.getField('Informant.SuburbCityAust');
        const postalState = form.getField('Informant.ResidentialState');
        const postalPostcode = form.getField('Informant.ResidentialPostcode');

        form.setField({
            'Informant.PostalAddressLine1': postalAddress,
            'Informant.PostalSuburb': postalSuburb,
            'Informant.PostalState': postalState,
            'Informant.PostalPostcode': postalPostcode
        });
    };

    /* leaving this here in case they want to bring this functionality back */
    onChangeApplicantFamilyMember = item => {
        const { form } = this.props;
        let ApplicantFamilyMember = '';
        if (item && item.metadata) {
            const { source, relationship } = item.metadata;
            form.setField({
                'Informant.RelationshipToDeceased': relationship,
                'Informant.InformantsName': joinDefined([source.FirstName, source.MiddleName, source.Surname], ' '),
                'Informant.FirstName': source.FirstName,
                'Informant.MiddleName': source.MiddleName,
                'Informant.Surname': source.Surname
            });
            ApplicantFamilyMember = item.Label;
        } else {
            form.setField({
                'Informant.RelationshipToDeceased': null,
                'Informant.InformantsName': null,
                'Informant.FirstName': null,
                'Informant.MiddleName': null,
                'Informant.Surname': null
            });
        }
        this.setState({ ApplicantFamilyMember });
    };

    onOpenCoronerModal = name => {
        this.setState({ coronerModalField: name });
    };

    onCreateNewCoroner = coroner => {
        const { form } = this.props;
        form.setField({ 'Certification.CoronersCode': coroner.ID });
    };

    onSubmitContactCard = contact => {
        const { form } = this.props;
        const AuthorisedContacts = (form ? form.getField('AuthorisedContacts') : null) || [];
        const existingContact = contact.ID !== undefined ? AuthorisedContacts.find(x => x.ID === contact.ID) : null;

        if (existingContact) {
            Object.assign(existingContact, contact);
        } else {
            AuthorisedContacts.push(contact);
        }
        form.setField({ AuthorisedContacts });
        this.setState({ editContact: undefined });
    };

    render() {
        const { form, locked } = this.props;
        const changes = form.getField('CertificationChangesFromBDM') || {};
        const changesCount = Object.keys(changes).length;
        const { coronerModalField, editContact, customAddressFields } = this.state;
        return (
            <Fragment>
                <CustomAddressModal
                    onClose={() => this.setState({ customAddressFields: undefined })}
                    open={!!customAddressFields}
                    name={customAddressFields}
                    form={form}
                    customAddressFields={customAddressFields}
                />
                <CoronerModal
                    onClose={() => this.setState({ coronerModalField: undefined })}
                    open={!!coronerModalField}
                    name={coronerModalField}
                    form={form}
                    onSubmit={this.onCreateNewCoroner}
                />
                <ContactCardModal
                    onClose={() => this.setState({ editContact: undefined })}
                    open={!!editContact}
                    value={editContact}
                    onSubmit={this.onSubmitContactCard}
                />
                {changesCount > 0 && !locked && (
                    <BiggerMessageBar>
                        Client made some changes through BDM form.&nbsp;
                        {
                            <PrimaryButton onClick={this.applyBDMChanges}>
                                <TickCircleIcon />
                                <Hidden smDown> Approve changes</Hidden>
                            </PrimaryButton>
                        }
                    </BiggerMessageBar>
                )}
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    applyBDMChanges = () => {
        const { form } = this.props;
        const changes = form.getField('CertificationChangesFromBDM');
        form.setField({ ...changes, CertificationChangesFromBDM: {} });
    };

    renderFirstColumn() {
        const { form, locked } = this.props;
        const funeralId = form.getField('ID');
        const { ApplicantFamilyMember } = this.state;

        return (
            <Grid container spacing={24}>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Informant Identity" />
                    </Grid>

                    {1 === 2 && (
                        <Grid pc={0.5}>
                            <FamilyMemberAutocomplete
                                name="ApplicantFamilyMember"
                                onChange={this.onChangeApplicantFamilyMember}
                                funeralId={funeralId}
                                value={ApplicantFamilyMember}
                            />
                        </Grid>
                    )}
                    {
                        /* !!ApplicantFamilyMember && */ <Fragment>
                            <Grid pc={0.5}>
                                <AutoComplete
                                    disabled={locked}
                                    name="Informant.RelationshipToDeceased"
                                    label="Relationship to the deceased"
                                    form={form}
                                    limit={100}
                                    options={APPLICANT_RELATIONSHIP_OPTIONS}
                                />
                            </Grid>

                            {/* <Grid pc={0.5}>
                        <TextField name="Informant.InformantsName" label="Full name" form={form} />
                    </Grid>*/}
                            <Grid pc={0.5}>
                                <TextField disabled={locked} titleCase name="Informant.FirstName" label="First name" form={form} />
                            </Grid>
                            <Grid pc={0.5}>
                                <TextField disabled={locked} titleCase name="Informant.MiddleName" label="Middle name(s)" form={form} />
                            </Grid>
                            <Grid pc={0.5}>
                                <TextField disabled={locked} titleCase name="Informant.Surname" label="Surname" form={form} />
                            </Grid>
                        </Fragment>
                    }
                    <Grid pc={0.5}>
                        <TextField disabled={locked} name="Informant.Mobile" label="Mobile (optional)" form={form} />
                    </Grid>
                    <Grid pc={0.5}>
                        <TextField
                            disabled={locked}
                            name="Informant.Phone"
                            //className={classes.alignWithLabel}
                            label="Phone (optional)"
                            form={form}
                        />
                    </Grid>
                    <Grid pc={0.5}>
                        <TextField disabled={locked} name="Informant.Email" label="Email (optional)" type="email" form={form} />
                    </Grid>

                    <Grid pc={1}>
                        <InlineHeader header="Residential Address" />
                        <AddressAutocomplete
                            disabled={locked}
                            id="ResidentialAddress"
                            placeholder="Search for residential address"
                            componentFields={RESIDENTIAL_ADDRESS_COMPONENTS}
                            form={form}
                            allowCustomAddress={true}
                            onSelect={this.onChangePostalAddress}
                        />
                    </Grid>

                    <Grid pc={1}>
                        <InlineHeader header="Postal Address">
                            <Checkbox
                                disabled={locked}
                                name="Informant.PostalSameAsResidential"
                                label="Same as residential address"
                                form={form}
                                onChange={this.onChangePostalAddress}
                            />
                        </InlineHeader>
                        <AddressAutocomplete
                            id="PostalAddress"
                            placeholder="Search for postal address1"
                            componentFields={POSTAL_ADDRESS_COMPONENTS}
                            form={form}
                            allowCustomAddress={true}
                            disabled={form.getField('Informant.PostalSameAsResidential') || locked}
                        />
                    </Grid>
                    <Grid pc={1}>
                        <OptionalTextField
                            name="Informant.Comment"
                            buttonlabel="+ Add Informant Notes"
                            textfieldlabel={'Informant Notes'}
                            multiline
                            form={form}
                        />
                    </Grid>
                </Grid>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Informant Declaration" />
                    </Grid>
                    <Grid pc={0.5}>
                        <Checkbox
                            disabled={locked}
                            name="Informant.Signed"
                            label="The informant has signed the declaration form"
                            form={form}
                        />
                    </Grid>
                    <Grid pc={0.5}>
                        <Label text="Date Signed" htmlFor="text-DateSigned" />
                        <TextField disabled={locked} name="Informant.DateOfSignature" type="date" notFuture form={form} />
                    </Grid>
                </Grid>

                {this.renderAuthorizedContacts()}
            </Grid>
        );
    }

    renderSecondColumn() {
        const { form, locked } = this.props;

        let certificationFields;

        const certificateType = form.getField('Certification.TypeOfBdmDeathCertificate');

        if (certificateType === 'Coroner') {
            certificationFields = this.renderCoronerFields(form);
        } else {
            certificationFields = this.renderDoctorFields(form);
        }

        const isMccd = certificateType === 'MCCD';

        const isCremation = form.getField('Disposal.CrematedOrBuried') === 'Cremated';
        const isCoroner = certificateType === 'Coroner';

        return (
            <Grid container spacing={24}>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label disabled={locked} text="Certification of Death" htmlFor="select-CertificationOfDeath" />
                    </Grid>

                    <Grid pc={1}>
                        <Select
                            disabled={locked}
                            allowNone={false}
                            name="Certification.TypeOfBdmDeathCertificate"
                            placeholder="Select a Certification of Death"
                            options={CERTIFICATION_OF_DEATH}
                            form={form}
                        />
                    </Grid>
                    {certificationFields}
                    {isCremation && !isCoroner && this.renderRefereeFields(form, isMccd && isCremation)}
                    <Grid pc={1}>
                        <OptionalTextField
                            name="DoctorCoronerComment"
                            buttonlabel="+ Add Certification Notes"
                            textfieldlabel={'Notes from the Coroner/Doctors...'}
                            form={form}
                        />
                    </Grid>
                </Grid>

                {this.renderRequiredDocumentation()}

                <Grid bucket={true}>
                    {isCremation && <Checkbox disabled={locked} label="Pacemaker to be removed" form={form} name="RemovePacemaker" />}
                    <InlineHeader header="Transfer">
                        <Checkbox name="TransferCertificates" label="Certificates received" form={form} />
                        <Checkbox name="TransferReady" label="Ready" form={form} />
                        <Checkbox name="TransferActioned" label="Actioned" form={form} />
                    </InlineHeader>

                    <Grid pc={1}>
                        <TextField
                            name="TransferComment"
                            form={form}
                            multiline
                            label="Transfer Notes"
                            placeholder="..."
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderDoctorFields(form) {
        const { locked } = this.props;
        const isCremation = form.getField('Disposal.CrematedOrBuried') === 'Cremated';
        return (
            <Fragment>
                <PurchaseOrderAddressBookAutocomplete
                    disabled={locked}
                    form={form}
                    placeholder="Search for doctor"
                    labelField="Name"
                    dataObjectType={'Certification'}
                    contactField="Doctor"
                    purchaseOrderField="DoctorPurchaseOrder"
                    categories={[ADDRESS_BOOK_CATEGORY.doctor]}
                    labelSupplier={'Doctor'}
                    allowPurchaseOrder={form.getField('DoctorAttachedToFlag') !== true && !!isCremation}
                    createPurchaseOrderMutation={createCertificationPurchaseOrderMutation}
                    defaultLineItemCode={'21930'}
                />

                <Grid pc={1}>
                    <Checkbox disabled={locked} name="DoctorAttachedToFlag" label="Hospital attached to doctor" form={form} />
                </Grid>

                {form.getField('DoctorAttachedToFlag') && (
                    <PurchaseOrderAddressBookAutocomplete
                        disabled={locked}
                        form={form}
                        placeholder="Search for a hospital"
                        dataObjectType={'Certification'}
                        contactField="Hospital"
                        purchaseOrderField="HospitalPurchaseOrder"
                        categories={[ADDRESS_BOOK_CATEGORY.hospitalNursingHome]}
                        labelSupplier={'Hospital'}
                        allowPurchaseOrder={form.getField('DoctorAttachedToFlag') === true}
                        createPurchaseOrderMutation={createCertificationPurchaseOrderMutation}
                        defaultLineItemCode={'21910'}
                    />
                )}
            </Fragment>
        );
    }

    renderCoronerFields(form) {
        const { locked } = this.props;
        return (
            <Fragment>
                <Grid pc={1}>
                    <InlineField>
                        <Label text="Select a Coroner" />
                    </InlineField>
                    <InlineField>
                        <CoronerAutoComplete
                            disabled={locked}
                            name="Certification.Coroner"
                            placeholder="Search for coroner"
                            form={form}
                            addNewOnClick={!locked && (() => this.onOpenCoronerModal('Certification.Coroner'))}
                        />
                    </InlineField>
                </Grid>
                <Grid pc={1}>
                    <Select
                        disabled={locked}
                        name="Certification.CoronersCause"
                        label="Coroner's cause"
                        placeholder={"Select a Coroner's Cause"}
                        options={CORONERS_CAUSES}
                        form={form}
                    />
                </Grid>
            </Fragment>
        );
    }

    autobillReferee(form) {
        const client = getClient();
        const clientForProducts = getUtilitiesClient();
        const orderID = form.getField('Certification.RefereePurchaseOrder.ID');
        const certificationID = form.getField('Certification.ID');
        const funeralID = form.getField('ID');
        const supplierID = form.getField('Certification.Referee.ID');
        if (orderID > 0) return false; //We already have order, doing nothing
        if (this.props.locked) return false; //Funeral is locked, no autobills for you :D 
        if (certificationID > 0 && funeralID > 0 && supplierID > 0) {
            //We have everything we need, let's create our bill
            this.autobillingInProgress = true;
            setSnackbarMessage('Creating bill for referee doctor', true);
            form.loading = true;
            this.invoiceCreationInProgress = true;
            clientForProducts
                .query({ query: getProducts, variables: { categoryUrlsegment: null, searchTerm: '2194' } })
                .then(product => {
                    const product_node = product.data.readProducts.edges[0] || {};
                    if (product_node.node.ID > 0) {
                        //Got one, now let's create actual order
                        client
                            .mutate({
                                mutation: createCertificationPurchaseOrderMutation,
                                variables: {
                                    input: {
                                        ID: certificationID,
                                        RefereePurchaseOrder: {
                                            FuneralID: funeralID,
                                            SupplierID: supplierID,
                                            Products: [
                                                {
                                                    ProductID: product_node.node.ID,
                                                    Qty: 1,
                                                    UnitPrice: product_node.node.BasePrice
                                                }
                                            ]
                                        }
                                    }
                                }
                            })
                            .then(order => {
                                const orderID = order.data.updateFuneralCertification.RefereePurchaseOrder.ID;
                                if (orderID > 0) {
                                    //Order created, submiting to Xero
                                    client
                                        .mutate({
                                            mutation: submitToXeroMutation,
                                            variables: {
                                                id: orderID
                                            }
                                        })
                                        .then(invoice => {
                                            this.autobillProcedureStopped(
                                                'Bill for referee doctor created successfully',
                                                true,
                                                form
                                            );
                                        })
                                        .catch(error => {
                                            this.autobillProcedureStopped(error.message, false, form);
                                        });
                                } else {
                                    throw new Error('Failed to submit to Xero');
                                }
                            })
                            .catch(error => {
                                this.autobillProcedureStopped(error.message, false, form);
                            });
                    } else {
                        throw new Error('Failed to found product to use in bill');
                    }
                })
                .catch(error => {
                    this.autobillProcedureStopped(error.message, false, form);
                });
            return true; //Well, at least we started here, will we be successful - we'll see
        }
        return false; //We missing some piece of required data, so just let's relax ;)
    }

    autobillProcedureStopped(message, success, form) {
        this.autobillingInProgress = false;
        setSnackbarMessage(
            !success ? 'Failed to create bill for referee doctor. Reason: ' + message : message,
            success
        );
        form.loading = false;
        form.doRefetch(() => { });
    }

    renderRefereeFields(form, allowPurchaseOrder) {
        const { locked } = this.props;
        let RefereeCremationCheck = form.getField('RefereeCremationCheck');
        if (
            !!form.getField('Certification.FileCremCertificate.AbsoluteLink') &&
            !!form.getField('Certification.FileRefereeingDoctorForm.AbsoluteLink')
        ) {
            if (!RefereeCremationCheck) {
                RefereeCremationCheck = true;
                form.setField({ RefereeCremationCheck });
            }
        } else {
            if (!!RefereeCremationCheck) {
                RefereeCremationCheck = false;
                form.setField({ RefereeCremationCheck });
            }
        }

        if (
            RefereeCremationCheck &&
            allowPurchaseOrder &&
            !this.invoiceCreationInProgress &&
            !form.isDirty &&
            !form.loading
        ) {
            this.autobillReferee(form);
        }

        return (
            <Fragment>
                {this.autobillingInProgress && (
                    <BiggerMessageBar messageType="loading">Referee doctor bill is being created</BiggerMessageBar>
                )}
                <PurchaseOrderAddressBookAutocomplete
                    disabled={locked}
                    form={form}
                    dataObjectType={'Certification'}
                    contactField="Referee"
                    purchaseOrderField="RefereePurchaseOrder"
                    categories={[ADDRESS_BOOK_CATEGORY.doctor]}
                    labelSupplier={'Referee Doctor'}
                    allowPurchaseOrder={false}
                    createPurchaseOrderMutation={createCertificationPurchaseOrderMutation}
                    defaultLineItemCode={'21940'}
                />

                <Grid pc={1}>
                    <Label>
                        {RefereeCremationCheck
                            ? 'Cremation Referee completed'
                            : 'Upload required documents to complete cremation referee'}
                    </Label>
                </Grid>
            </Fragment>
        );
    }

    renderAuthorizedContacts() {
        const { form, locked } = this.props;
        const AuthorisedContacts = form.getField('AuthorisedContacts') || [];
        return (
            <Grid bucket={true}>
                <Grid pc={1}>
                    <Label text={'Authorised Contacts'} />
                </Grid>
                {AuthorisedContacts && (
                    <Grid pc={1}>
                        <ContactCardContainer>
                            {AuthorisedContacts.map((obj, i) => (
                                <ContactCard
                                    contact={obj}
                                    key={i}
                                    onDelete={!locked && (() => this.onDeleteContact(i))}
                                    onEdit={!locked && isRelatedObjectDefined(obj) && (() => this.onEditContact(i, obj))}
                                />
                            ))}
                        </ContactCardContainer>
                    </Grid>
                )}
                <Grid pc={1}>
                    <PrimaryButton disabled={locked} onClick={() => this.onEditContact(undefined, undefined)}>
                        Add authorised contact...
                    </PrimaryButton>
                </Grid>
            </Grid>
        );
    }

    renderRequiredDocumentation() {
        const { form } = this.props;
        const requiredDocuments = getRequiredDocuments(form);

        return (
            <Grid bucket={true}>
                <Grid pc={1}>
                    <Label text="Required Documentation" />
                </Grid>
                <ValidationPlaceholder validationResult={form.getValidation('Certification', true)}>
                    <Grid pc={1}>
                        <Table
                            rowDragDrop={(file, index) => this.handleFileDrop(file, index)}
                            allowDragDrop={row => {
                                return true; //isAwaitingDocumentation(row);
                            }}
                            columns={['ID', 'Required Document', 'Generated', 'Uploaded', 'Upload']}
                        >
                            {requiredDocuments.map((x, i) => this.renderRequiredDocument(x, i))}
                        </Table>
                    </Grid>
                </ValidationPlaceholder>
            </Grid>
        );
    }

    renderRequiredDocument(requiredDocument, index) {
        const { form, classes, match, locked } = this.props;
        const document = form.getField(requiredDocument.fieldName) || {
            ID: null,
            AbsoluteLink: null,
            Name: null,
            Size: 0
        };
        const arrangementForm = getSignedArrangementFormForDocument(
            form,
            requiredDocument.fieldName,
            requiredDocument.formType
        );
        return (
            <TableRow
                key={index}
                onDragOver={event => this.handleRowDragOver(event, index)}
                onDragLeave={event => this.handleRowDragLeave(event, index)}
                onDrop={event => this.handleRowDragDrop(event, requiredDocument)}
            >
                {this.state.isDragOverRow[index] ? (
                    <TableCell colSpan={5}>
                        <div className={cx(classes.iconContainer)}>
                            <UploadIcon /> Please drag your file here to upload
                        </div>
                    </TableCell>
                ) : (
                    <Fragment>
                        {stringIsNullOrEmpty(document.AbsoluteLink) && stringIsNullOrEmpty(arrangementForm.PDF) ? (
                            <TableCell>
                                <div className={cx(classes.iconContainer, classes.warning)} title={'Please upload'}>
                                    <ExclamationTriangleIcon />
                                </div>
                            </TableCell>
                        ) : (
                            <TableCell>
                                <div className={cx(classes.iconContainer, classes.green)}>
                                    <TickCircleIcon />
                                </div>
                            </TableCell>
                        )}
                        <TableCell>
                            {(arrangementForm && (
                                <LinkButton
                                    text={requiredDocument.description}
                                    href={applyUrlParams(arrangementForm.formLink, match.params)}
                                />
                            )) ||
                                requiredDocument.description}
                        </TableCell>
                        <TableCell>
                            {(arrangementForm.PDF && (
                                <LinkButton text={`View PDF`} href={arrangementForm.PDF.AbsoluteLink} target="blank" />
                            )) ||
                                '-'}
                        </TableCell>
                        <TableCell>
                            {(document.Name && (
                                <LinkButton text={document.Name} href={document.AbsoluteLink} target="blank" />
                            )) ||
                                '-'}
                        </TableCell>
                        <TableCell>
                            <div className={classes.uploadButtonContainer}>
                                <FileUpload
                                    disabled={locked}
                                    iconOnly
                                    folderPath={`/documents/funeral/${form.getField('ID')}`}
                                    onComplete={({ uploadFile }) =>
                                        this.onFileUploaded(uploadFile, requiredDocument.fieldName)
                                    }
                                />
                            </div>
                        </TableCell>
                    </Fragment>
                )}
            </TableRow>
        );
    }

    handleRowDragOver(event, index) {
        if (event && event.dataTransfer && event.dataTransfer.types && event.dataTransfer.types.includes('Files')) {
            event.stopPropagation();
            event.preventDefault();
            event.dataTransfer.dropEffect = 'copy';
            this.setState({ isDragOverRow: { [index]: true } });
        }
    }

    handleRowDragLeave(event, index) {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ isDragOverRow: { [index]: false } });
    }

    handleRowDragDrop(event, row) {
        event.stopPropagation();
        event.preventDefault();
        if (event.dataTransfer.files.length > 0) {
            let file = event.dataTransfer.files[0];
            this.handleFileDrop(file, row);
            this.setState({ isDragOverRow: {} });
        }
    }

    handleFileDrop(file, row) {
        const assetsClient = getAssetsClient();
        //uploading file
        setSnackbarMessage('Uploading file', true);
        assetsClient
            .mutate({
                mutation: uploadFilesQuery,
                variables: {
                    file: file,
                    publish: true,
                    folderPath: '/documents/funeral/' + this.props.form.getField('ID')
                }
            })
            .then(result => {
                const file = result.data.uploadFile;
                this.onFileUploaded(file, row.fieldName);
                setSnackbarMessage('Success, document has successfully been uploaded. Save to confirm', true, 1000);
            })
            .catch(error => {
                setSnackbarMessage(error.message, false, null, new Error(error));
            });
    }

    onFileUploaded({ id, url, filename }, fieldName) {
        const { form } = this.props;
        const document = {
            ID: id,
            AbsoluteLink: url,
            Name: getFileName(filename),
            Size: 'Save to confirm'
        };

        form.setField({ [fieldName]: document });
    }

    onDeleteContact = index => {
        const { form } = this.props;
        const AuthorisedContacts = (form ? form.getField('AuthorisedContacts') : null) || [];

        AuthorisedContacts.splice(index, 1);

        form.setField({ AuthorisedContacts });
    };

    onEditContact = (index, contact) => {
        //empty means new
        const editContact = contact
            ? { ...contact }
            : {
                Name: '',
                Phone: '',
                Email: '',
                AddressLine1: '',
                AddressLine2: '',
                Suburb: '',
                Postcode: '',
                Country: ''
            };
        this.setState({ editContact });
    };

    addCustomAddress(customAddressFields) {
        this.setState({ customAddressFields });
    }
}

const styles = ({ palette }) => ({
    alignWithLabel: {
        marginTop: 10
    },

    iconContainer: {
        textAlign: 'center'
        // padding: '10px 0'
    },
    dataContainer: {
        textAlign: 'right'
        // padding: '10px 0'
    },
    uploadButtonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    green: {
        color: palette.validation.good
    },
    warning: {
        color: palette.validation.suggested
    }
});

export default compose(withRouter, withStyles(styles))(Certification);
