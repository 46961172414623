import React, { Component } from 'react';
import { Hidden, Icon, withStyles } from '@material-ui/core';
import ModalStepTitle from '../form/ModalStepTitle';
import PrimaryButton, { OutlineButton } from '../form/PrimaryButton';
import { getCartTotal } from '../../util/products';
import CartItem from './CartItem';
import CloseIcon from '../icon/CloseIcon';
import ModalStepSubHeader from '../form/ModalStepSubHeader';
import { prettyPrice } from '../../util/strings';

class SummaryTab extends Component {
    render() {
        const { classes, stepLabel, stepTitle, stepSubtitle } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <ModalStepTitle header={stepLabel} title={stepTitle} />
                    {!!stepSubtitle && <ModalStepSubHeader header={stepSubtitle} />}
                </div>
                {this.renderItems()}
                {this.renderFooter()}
            </div>
        );
    }

    renderItems() {
        const { classes, cart } = this.props;

        return (
            <div className={classes.content}>
                {cart.length > 0 ? (
                    <div className={classes.items}>{cart.map(this.renderItem)}</div>
                ) : (
                    <div className={classes.items}>No products selected.</div>
                )}
            </div>
        );
    }

    renderItem = item => {
        const { classes, config } = this.props;
        return (
            <CartItem
                key={item.key}
                className={classes.item}
                item={item}
                config={config}
                onRemove={this.onRemoveCartItem}
                onChange={this.onChangeCartItem}
                disabled
            />
        );
    };

    renderFooter() {
        const { classes, cart, config, onBack, onConfirm, onCancel } = this.props;
        const total = getCartTotal(cart, config);
        return (
            <div>
                <div className={classes.total}>Order Total: {prettyPrice(total)}</div>
                <div className={classes.footer}>
                    <span>
                        <OutlineButton onClick={onCancel} color="primary">
                            <CloseIcon />
                            <Hidden xsDown>Cancel</Hidden>
                        </OutlineButton>
                        <PrimaryButton onClick={onBack}>
                            <Icon>chevron_left</Icon>
                            <Hidden xsDown>Previous Step</Hidden>
                        </PrimaryButton>
                    </span>
                    <PrimaryButton className={classes.rightButton} onClick={onConfirm}>
                        <Icon className={classes.buttonIcon}>check_circle_outline</Icon>
                        <Hidden xsDown>&nbsp;Accept</Hidden>
                    </PrimaryButton>
                </div>
            </div>
        );
    }

    onRemoveCartItem = item => {
        const { cart } = this.props;
        cart.splice(cart.indexOf(item), 1);

        if (item.onRemove) item.onRemove();

        this.forceUpdate();
    };

    onChangeCartItem = () => {
        this.forceUpdate();
    };
}

const styles = ({ spacing, palette, breakpoints }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'stretch'
    },
    header: {
        padding: '40px 60px 10px 60px',
        [breakpoints.down('xs')]: {
            padding: spacing.unit,
            '& h1': {
                fontSize: 16,
                display: 'inline'
            }
        }
    },
    footer: {
        padding: '40px 40px',
        [breakpoints.down('xs')]: {
            padding: spacing.unit
        },
        '& button': {
            padding: '0.25em 2em',
            marginLeft: 16,
            '&:first-child': {
                marginLeft: 0
            },
            '& svg': {
                fontSize: '24px',
                margin: 0
            },
            [breakpoints.down('xs')]: {
                margin: '0 3px',
                minWidth: '2em',
                padding: '0.5em'
            }
        }
    },
    rightButton: {
        float: 'right'
    },
    content: {
        flex: '1 1 auto',
        //width: 'calc(100% + 24px)',
        display: 'flex',
        position: 'relative',
        overflow: 'auto',
        flexDirection: 'column',
        padding: '0 40px',
        [breakpoints.down('xs')]: {
            padding: 0
        }
    },
    items: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(420px, 1fr))',
        gridGap: '16px 32px',
        overflowY: 'auto',
        flex: '0 1 auto',
        padding: 12,
        [breakpoints.down(breakpoints.custom.dt)]: {
            gridTemplateColumns: '1fr 1fr'
        },
        [breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr'
        }
    },
    total: {
        margin: '0 40px',
        padding: '24px 0px',
        fontSize: '1.6rem',
        borderTop: `1px solid ${palette.divider}`,
        borderBottom: `1px solid ${palette.divider}`,
        textAlign: 'right',
        [breakpoints.down('xs')]: {
            fontSize: '1.125rem',
            padding: `${spacing.unit}px 0`,
            margin: `0 ${spacing.unit}px`
        }
    },

    buttonIcon: {
        // marginRight: 4
    }
});

export default withStyles(styles)(SummaryTab);
