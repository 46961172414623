import React, { Component, Fragment } from "react";
import { matchPath, withRouter } from "react-router";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Header from "./component/header/Header";
import AppNavDrawer from "./component/drawer/AppNavDrawer";
import Footer from "./component/footer/Footer";
import Main from "./page/Main";
import { isSignedIn } from "./util/sessions";
import { getFuneralHomeByKey, getFuneralHomeByURLSegment } from "./util/funerals";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarMessageConsumer, withSnackbarMessage } from "./context/SnackbarMessage";
import { compose } from "react-apollo";
import TickCircleIcon from "./component/icon/TickCircleIcon";
import ExclamationCircleIcon from "./component/icon/ExclamationCircleIcon";
import { IconButton } from "@material-ui/core";
import CloseIcon from "./component/icon/CloseIcon";

class App extends Component {
    state = {
        signedIn: false,
        drawerOpen: false,
        drawerMinimized: false,
        funeralHome: undefined
    };

    componentDidMount() {
        this.updateState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateState(nextProps);
    }

    updateState({ location }) {
        const newState = {};
        const signedIn = isSignedIn();
        if (signedIn !== this.state.signedIn) {
            newState.signedIn = signedIn;
        }

        let funeralHome;

        let searchmatch = false;
        if ((searchmatch = matchPath(location.pathname, { path: `/search/:brand` }))) {
            const urlSegment = searchmatch.params.brand;
            funeralHome = urlSegment && getFuneralHomeByURLSegment(urlSegment);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/funeral/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getFuneralHomeByKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/documentation/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getFuneralHomeByKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/prearrangement/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getFuneralHomeByKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/mortuary/:junk/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getFuneralHomeByKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/quotes/enquiry/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getFuneralHomeByKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/quotes/` }))) {
            const urlParams = new URLSearchParams(window.location.search);
            const key = urlParams.get('office');
            funeralHome = key && getFuneralHomeByKey(key);
        }

        if (funeralHome !== this.state.funeralHome) {
            newState.funeralHome = funeralHome;
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }
    }

    getFuneralTheme() {
        const { funeralHome } = this.state;
        return theme => {
            theme = { ...theme };
            theme.funeralHome = funeralHome ? funeralHome.letterCode : 'none';
            if (funeralHome) {
                theme.palette = { ...theme.palette };
                theme.palette.action = { ...theme.palette.action };
                theme.palette.action.active = funeralHome.color;
                theme.palette.primary = { ...theme.palette.primary };
                theme.palette.primary.main = funeralHome.color;
                theme.palette.secondary = { ...theme.palette.secondary };
                theme.palette.secondary.main = '#1B9842';
                theme.palette.secondary.dark = '#197138';
            } else {
                theme.palette.primary.main = '#35327c';
            }
            return theme;
        };
    }

    onToggleDrawerOpen = () => {
        this.setState({ drawerOpen: !this.state.drawerOpen });
    };

    onToggleDrawerMinimized = () => {
        this.setState({ drawerMinimized: !this.state.drawerMinimized });
    };

    render() {
        const { classes } = this.props;
        const { signedIn } = this.state;
        return (
            <div className={signedIn ? classes.root : ''}>
                {signedIn && (
                    <Fragment>
                        <Header
                            onToggleDrawerOpen={this.onToggleDrawerOpen}
                            onToggleDrawerMinimized={this.onToggleDrawerMinimized}
                            drawerMinimized={this.state.drawerMinimized}
                        />
                        <AppNavDrawer
                            onToggleOpen={this.onToggleDrawerOpen}
                            onToggleMinimized={this.onToggleDrawerMinimized}
                            open={this.state.drawerOpen}
                            minimized={this.state.drawerMinimized}
                        />
                    </Fragment>
                )}
                <MuiThemeProvider theme={this.getFuneralTheme()}>
                    <Main signedIn={signedIn} />
                </MuiThemeProvider>
                <SnackbarMessageConsumer>{this.renderSnackbar}</SnackbarMessageConsumer>
                {signedIn && <Footer />}
            </div>
        );
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.setSnackbarMessage('');
    };

    renderSnackbar = ({ snackbarMessage, snackbarHappy, snackbarDuration, snackbarException }) => {
        const open = !!snackbarMessage;
        const snackbarExceptionText =
            !!snackbarException &&
            (Array.isArray(snackbarException) ? (
                <ul>
                    {snackbarException.map(e => {
                        return <li>{e.message}</li>;
                    })}{' '}
                </ul>
            ) : (
                snackbarException.message
            ));
        const { classes } = this.props;
        const key = Math.random();
        return (
            <Snackbar
                key={key}
                message={
                    <span id="message-id" className={classes.message}>
                        {snackbarMessage &&
                            (snackbarHappy ? (
                                <TickCircleIcon style={{ color: 'white' }} />
                            ) : (
                                <ExclamationCircleIcon style={{ color: 'white' }} />
                            ))}
                        <span>
                            {snackbarMessage}
                            {!!snackbarException && (
                                <i>
                                    <br />
                                    {snackbarExceptionText}
                                </i>
                            )}
                        </span>
                    </span>
                }
                open={open}
                action={
                    <IconButton onClick={this.handleCloseSnackbar}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                }
                autoHideDuration={snackbarDuration}
                onClose={this.handleCloseSnackbar}
                className={classes.snackbar}
                classes={
                    (!!snackbarMessage && { root: !!snackbarHappy ? classes.snackbarHappy : classes.snackbarSad }) ||
                    null
                }
            />
        );
    };
}

const styles = ({ spacing, sizes }) => ({
    root: {
        paddingBottom: sizes.footerHeight,
        display: 'flex',
        width: '100%',
        height: '100vh'
    },
    snackbar: {
        whiteSpace: 'pre-line',
        '& > div': {
            borderRadius: '6px 6px 0 0', flexWrap: 'nowrap',
            maxHeight: '90vh',
            overflow: 'hidden',
            overflowY: 'auto'
        }
    },
    snackbarHappy: { '& > div': { background: 'green' } },
    snackbarSad: { '& > div': { background: 'red' } },
    message: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            fontSize: '1.25em',
            marginLeft: spacing.unit
        }
    }
});

// prettier-ignore
export default compose(
    withSnackbarMessage,
    withRouter,
    withStyles(styles)
)(App);
