import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { compose, Query } from 'react-apollo';
import Hidden from '@material-ui/core/Hidden';
import { matchPath, withRouter } from 'react-router-dom';
import TaskFragment from './Task';
import Button from './extras/Button';
import ViewHeader from './extras/ViewHeader';
import CloseIcon from '../../component/icon/CloseIcon';
import FlexGrid from './extras/FlexGrid';
import Inline, { inlineAlignment } from './extras/Inline';
import TaskModal from './Modals/TaskModal';
import StarDrawer from './StarDrawer';
import TaskBucket from './TaskBucket';
import TaskDrawer from './TaskDrawer';
import TaskList from './TaskList';
import { TASK_CATEGORY_OPTIONS } from './TaskConstants';
import { withSnackbarMessage } from '../../context/SnackbarMessage';

class WorkQueue extends Component {
    state = {
        firstTime: null,
        showNewTaskModal: false,
        taskIdLoaded: -1,
        myLabels: [],
        myTasks: [],
        filters: { type: 'all', value: null, title: 'All Your Tasks', labels: [] }
    };

    static getDerivedStateFromProps({ match }, state) {
        const { key, queue } = match.params || null;
        const firstTime = `${queue || ''}/${key || ''}`;
        const queueObj =
            queue && TASK_CATEGORY_OPTIONS.find(e => e.value && e.value.toUpperCase() === ('' + queue).toUpperCase());
        if (queueObj && firstTime !== state.firstTime) {
            const { filters } = state;
            filters.type = queueObj.value;
            filters.value = key || null;
            filters.title = !!key ? `Tasks for ${queueObj.labelOne} #${key}` : `Tasks for ${queueObj.label}`;
            return { ...filters, firstTime };
        }
        return null;
    }

    componentDidMount() {
        const { history, match } = this.props;
        const that = this;
        if (!!that && !!history)
            that.watchURL = history.listen(location => {
                if (!location || location.pathname.indexOf('/work-queue') < 0) return;
                const hasPath = matchPath(location.pathname, { path: `/work-queue/:queue/:key?/:id` });
                const { id } = (hasPath && hasPath.params) || { id: null };
                if (!!id) {
                    that.setState({ taskIdLoaded: id });
                }
            });
        const taskID = match.params.id || null;
        if (!!taskID) this.setState({ taskIdLoaded: taskID });
    }

    componentWillUnmount() {
        this.watchURL && this.watchURL();
    }

    render() {
        const { showNewTaskModal } = this.state;
        const { user, client } = this.props;
        const userID = user.ID;
        const variables = {
            labelFilter: [
                { field: 'MemberID', value: '' + userID },
                { field: 'NotActive:not', value: '1' }
            ],
            userId: userID
        };
        return (
            <Fragment>
                <FlexGrid className="full-height wq-page">
                    <Query query={getMyTasks} client={client()} fetchPolicy="network-only" variables={variables}>
                        {result => this.renderBody(result)}
                    </Query>
                </FlexGrid>
                <TaskModal
                    open={showNewTaskModal}
                    onClose={() => this.handleCloseNewTaskModal()}
                    onSaved={() => this.handleCloseNewTaskModal()}
                />
            </Fragment>
        );
    }

    renderBody({ data, loading, error, refetch }) {
        if (error) {
            console.error(error);
            this.props.setSnackbarMessage('Whoops, failed to load tasks.', false, null, new Error(error));
        }
        const myLabels = (data.readTaskLabels && data.readTaskLabels.edges.map(e => e.node)) || [];
        const myTasks = (data.readTasks && data.readTasks.edges.map(e => e.node)) || [];
        const { filters, taskIdLoaded } = this.state;
        const { history } = this.props;
        return (
            <Fragment>
                <TaskBucket
                    className="desktop-bucket"
                    tasks={myTasks}
                    labels={myLabels}
                    setFilters={this.setFilters}
                    filters={filters}
                    reloadBucket={refetch}
                />
                <div className="wq-panels full-height">
                    {this.renderHeading(myTasks, myLabels, refetch)}
                    <TaskList
                        labels={myLabels}
                        filterBy={filters}
                        onStarClick={refetch}
                        history={history}
                        taskIdLoaded={taskIdLoaded}
                    />
                </div>
            </Fragment>
        );
    }

    renderHeading(myTasks, myLabels, refetch) {
        const { filters } = this.state;
        const { user } = this.props;
        const me = user;

        if (!myTasks) return null;

        let starredTasks = myTasks.filter(({ AssignedMembers }) =>
            AssignedMembers.some(({ Join, ID }) => ID === me.ID && !!Join.Starred)
        );

        return (
            <div className="wq-header">
                <Hidden mdDown>
                    <ViewHeader title="Work Queue" className="wq-header-desk">
                        <Inline className="header">
                            <Button
                                variant="primary icon-button white shadow"
                                size="lg"
                                title="Add a new custom task"
                                onClick={() => this.handleShowNewTaskModal()}
                            >
                                <CloseIcon className="plus-icon" />
                            </Button>
                            <StarDrawer tasks={starredTasks} refetch={refetch} />
                        </Inline>
                    </ViewHeader>
                </Hidden>
                <Hidden lgUp>
                    <div className="wq-header-mobile">
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <TaskDrawer
                                tasks={myTasks}
                                labels={myLabels}
                                setFilters={this.setFilters}
                                filters={filters}
                                reloadBucket={refetch}
                            />
                            <Button
                                variant="primary icon-button white shadow"
                                title="Add a new custom task"
                                size="lg"
                                onClick={() => this.handleShowNewTaskModal()}
                            >
                                <CloseIcon className="plus-icon" />
                            </Button>
                            <StarDrawer tasks={starredTasks} refetch={refetch} />
                        </Inline>
                    </div>
                </Hidden>
            </div>
        );
    }

    handleShowNewTaskModal() {
        this.setState({ showNewTaskModal: true });
    }

    handleCloseNewTaskModal() {
        this.setState({ showNewTaskModal: false });
    }

    setFilters = (type, value = null, title = 'Tasks', labels = []) => {
        this.setState({ filters: { type, value, title, labels } });
    };
}

const getMyTasks = gql`
    ${TaskFragment}
    query ReadUserTasks($labelFilter: [TaskLabelFilters]!, $userId: Int!) {
        readTaskLabels(filters: $labelFilter) {
            edges {
                node {
                    ID
                    NotActive
                    Title
                }
            }
        }
        readTasks(userId: $userId, isComplete: false, isArchived: false) {
            edges {
                node {
                    ...TaskFragment
                }
            }
        }
    }
`;
export default compose(withRouter, withSnackbarMessage)(WorkQueue);
