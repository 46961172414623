import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import BigCalendar from 'irx-react-big-calendar';
import 'irx-react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/en-au';
import './calendar.css';
import DayToolbar from './DayToolbar';
import ViewEvent from './ViewEvent';
import { EditAppointmentConsumer, withEditAppointment } from '../../context/EditAppointment';
import { getUser } from '../../util/sessions';
import { appointmentTypesByView, getCalendarCategoryByType } from './CalendarConstants';
import { joinArray } from '../../util/arrays';
import { joinDefined } from '../../util/strings';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import AppointmentPopover from './AppointmentPopover';
import ViewLocations from './ViewLocations';
import EventConflictPartial from '../../page/fragments/EventConflictPartial';
import { getCalendarClient } from '../../apollo';

class Calendar extends React.Component {
    refetch = null;

    constructor(props) {
        super(props);

        const saved = JSON.parse(sessionStorage.getItem('calendar')) || {};
        const data = {
            view: saved.view || 'day',
            date: props.match.params.date || saved.date || moment().format('YYYYMMDD')
        };
        const { view, date } = data;

        this.state = {
            view: view,
            from: moment(date, 'YYYYMMDD').startOf('day'),
            to: moment(date, 'YYYYMMDD')
                .add(view === 'Locations' ? 14 : 1, 'days')
                .endOf('day'),
            offset: 0,
            filterStaff: [],
            filterTypes: [],
            filterGroups: [{ Code: 'funeral-arrangers', ID: '8' }], // default users view, see HP-1531
            staff: []
        };
    }

    appendCurrentUser = staff => {
        const surname = getUser().Surname || '';
        const currentUser = {
            Name: joinDefined([getUser().FirstName, surname.substr(0, 1)], ' '),
            ID: '' + getUser().ID,
            FirstName: getUser().FirstName,
            Surname: surname,
            resourceId: '' + getUser().ID
        };
        if (!!staff && staff.length > 0) {
            return [currentUser].concat(staff.filter(e => Number(e.ID) !== Number(currentUser.ID))); // move me to the front!
        }
        return [currentUser];
    };

    setFilters = ({ staff, types, groups }) => {
        const myID = getUser().ID;
        const excludeMe = staff.filter(e => Number(e) !== Number(myID));
        this.setState(
            {
                filterStaff: !!excludeMe && excludeMe.length > 0 ? [myID].concat(excludeMe) : [],
                filterTypes: types,
                filterGroups: groups,
                offset: 0
            },
            () => this.refetch && this.refetch()
        );
    };

    setFromToView = ({ newdate, newview }) => {
        let view = newview || this.state.view;
        let from = newdate || this.state.from;
        from = moment(from).startOf('day');
        let add = 1;

        if (view === 'Locations') {
            add = 14;
        }
        let to = moment(from)
            .add(add, 'days')
            .endOf('day');

        sessionStorage.setItem('calendar', JSON.stringify({ view: view, date: from.format('YYYYMMDD') }));

        this.setState(
            {
                from: from,
                to: to,
                view: view
            },
            () => this.refetch && this.refetch()
        );
    };

    viewEvent = props => {
        return (
            <ViewEvent
                {...props}
                date={this.state.from}
                category={props.event.ClassName}
                onMutate={() => (this.refetch && this.refetch()) || undefined}
            />
        );
    };
    viewToolbar = (resources, groups) => props => {
        return (
            <DayToolbar
                {...props}
                date={this.state.from}
                setFilters={this.setFilters}
                filters={{
                    staff: this.state.filterStaff,
                    types: this.state.filterTypes,
                    groups: this.state.filterGroups
                }}
                resources={resources}
                groups={groups}
            />
        );
    };

    handleSelectEvent = (event, target) => {
        // let obj = target.currentTarget;
    };

    selectSlot = (slot, resources) => {
        if (slot.action !== 'select') {
            return false;
        }
        const member = resources.filter(e => e.ID === slot.resourceId);
        this.props.createNewAppointment(slot.start, slot.end, member);
    };

    render() {
        const { filterGroups, filterTypes } = this.state;
        let filterStaff = [];

        if (filterGroups.length > 0) {
            filterStaff = [].concat(
                filterStaff,
                joinArray(filterGroups.map(x => !!x && x.Members))
                    .filter(x => !!x && x.ID)
                    .map(x => x.ID)
            );
        }

        if (this.state.filterStaff.length > 0) {
            filterStaff = [].concat(filterStaff, this.state.filterStaff);
        }

        if (filterStaff.length > 0) filterStaff = [getUser().ID].concat(filterStaff); //make sure we include current user

        filterStaff = [...new Set(filterStaff)]; // omit dupes

        const variables = {
            from: this.state.from,
            to: this.state.to,
            memberLimit: null,
            filterStaff,
            filterTypes: [],
            groupCode: 'fm-witness',
            sortBy: [{ field: 'Sort' }]
        };

        if (this.state.view === 'day') {
            variables.filterTypes = filterTypes.filter(e => appointmentTypesByView({ type: e, view: 'day' }));
            return (
                <Fragment>
                    <Query
                        query={staffquery}
                        variables={variables}
                        fetchPolicy="cache-and-network"
                        pollInterval={1000 * 60}
                        client={getCalendarClient()}
                    >
                        {results => this.renderCalendar(results)}
                    </Query>
                </Fragment>
            );
        } else {
            // need to swap location filterTypes into ClassNames
            variables.filterTypes = filterTypes
                .filter(e => appointmentTypesByView({ type: e }))
                .map(e => getCalendarCategoryByType(e).className);
            return (
                <Fragment>
                    <Query
                        query={query}
                        variables={variables}
                        fetchPolicy="cache-and-network"
                        pollInterval={1000 * 60}
                        client={getCalendarClient()}
                    >
                        {results => this.renderCalendar(results)}
                    </Query>
                </Fragment>
            );
        }
    }

    renderCalendar = ({ loading, error, data, fetchMore, refetch, networkStatus }) => {
        const { classes } = this.props;

        if (error) {
            console.error('Failed to load calendar data', error);
            return 'Error loading calendar data';
        }

        if (loading && networkStatus === 1) {
            return (
                <Fragment>
                    <div>Loading data, please wait a moment...</div>
                    <LinearProgress />
                </Fragment>
            );
        }

        if (refetch) this.refetch = refetch;

        let events = [];
        if (this.state.view === 'day') {
            const edges = data && data.readStaffAllocations;
            // const total = edges && data.readCalendarEvents.pageInfo.totalCount;
            events = ((edges && edges.length && edges.filter(e => !!e.ID)) || []).map(e => {
                return {
                    ...e,
                    Start: moment(e.Start).toDate(),
                    End: moment(e.End).toDate(),
                    Title:
                        (!!e.Funeral && e.Funeral.ID !== '0' && e.Funeral.FirstName + ' ' + e.Funeral.Surname) ||
                        e.Title,
                    resourceId: e.Member.ID
                };
            });
        } else {
            const edges = data && data.readCalendarEvents;
            events = ((edges && edges.length && edges.filter(e => !!e.ID)) || []).map(e => ({
                ...e,
                Start: moment(e.Start).toDate(),
                End: moment(e.End).toDate(),
                Title: (!!e.Funeral && e.Funeral.ID !== '0' && e.Funeral.FirstName + ' ' + e.Funeral.Surname) || e.Title
            }));
        }

        const memberEdges = data && data.readMembers && data.readMembers.edges;
        let resources =
            (memberEdges &&
                memberEdges.length &&
                memberEdges.map(e => {
                    return {
                        ...e.node,
                        Name: e.node.FirstName + ' ' + (e.node.Surname ? e.node.Surname : ''),
                        resourceId: e.node.ID
                    };
                })) ||
            [];

        const groups = (data && data.readGroups) || [];

        let filteredStaff = [];
        if (this.state.filterStaff.length > 0 || this.state.filterGroups.length > 0) {
            if (this.state.filterStaff.length > 0) {
                filteredStaff = filteredStaff.concat(
                    resources.filter(e => this.state.filterStaff.find(x => x === e.resourceId))
                );
            }
            if (!!groups && this.state.filterGroups.length > 0) {
                const groupIDs = this.state.filterGroups.map(e => e.ID);
                const staffIDs = [];
                groups.forEach(e => {
                    if (groupIDs.indexOf(e.ID) > -1) {
                        e.Members.forEach(ee => {
                            staffIDs.push(ee.ID);
                        });
                    }
                });
                filteredStaff = filteredStaff.concat(resources.filter(e => staffIDs.indexOf(e.resourceId) > -1));
            }
        } else {
            filteredStaff = resources.slice(0);
        }
        filteredStaff = [...new Set(filteredStaff)]; // omit dupes

        const components = {
            toolbar: this.viewToolbar(
                resources,
                groups.filter(g => g.Code !== 'fm-witness' && g.Code !== 'cm-witness') // omit root group
            ),
            event: this.viewEvent
        };

        resources = this.appendCurrentUser(filteredStaff);

        return (
            <Fragment>
                <Paper className={classes.paper} elevation={0}>
                    <BigCalendar
                        loading={networkStatus}
                        popup
                        selectable //is broken!
                        onSelectSlot={slotInfo => this.selectSlot(slotInfo, resources)}
                        components={components}
                        events={events || []}
                        locationEvents={events || []}
                        defaultView={this.state.view}
                        views={{ day: true, Locations: ViewLocations }}
                        onSelectEvent={this.handleSelectEvent}
                        date={moment(this.state.from).toDate()}
                        to={moment(this.state.to).toDate()}
                        onNavigate={newdate => {
                            this.setFromToView({ newdate });
                        }}
                        onView={newview => {
                            this.setFromToView({ newview });
                        }}
                        showMultiDayTimes // prevent allDay rows
                        //endAccessor={({ end }) => new Date(end.getTime() - 1)} // prevent allDay event s
                        startAccessor="Start"
                        endAccessor="End"
                        resources={this.appendCurrentUser(filteredStaff)}
                        resourceIdAccessor="resourceId" //I cannot get 'memberID' to work :(
                        resourceTitleAccessor="Name"
                        min={moment()
                            .startOf('day')
                            //  .add(7, 'hours')
                            .toDate()}
                        max={moment()
                            // .startOf('day')
                            // .add(21, 'hours')
                            .endOf('day')
                            .toDate()}
                        // deetsAccessor={this.getDeets}
                        formats={{ timeGutterFormat: 'hA' }}
                        length={14} // date range for locations view title
                        localizer={BigCalendar.momentLocalizer(moment)}
                    />
                    {(() => {
                        if (loading) {
                            return <LinearProgress />;
                        }
                    })()}
                </Paper>
                <EditAppointmentConsumer>
                    {props => (
                        <AppointmentPopover
                            {...props}
                            allowRecurring
                            onMutate={() => {
                                if (this.refetch) this.refetch();
                                this.forceUpdate();
                            }}
                            onClose={() => this.props.onAppointmentPopoverClosed()}
                        />
                    )}
                </EditAppointmentConsumer>
            </Fragment>
        );
    };
}

const query = gql`
    query ReadCalendarEvents($from: String!, $to: String!, $filterStaff: [ID], $filterTypes: [String]) {
        readCalendarEvents(from: $from, to: $to, filterStaff: $filterStaff, filterTypes: $filterTypes) {
            ... on CalendarEvent {
                ID
                ClassName
                Title
                Start
                End
                Notes
                Location {
                    ID
                    Name
                    Suburb
                }
                LocationFlattened
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
            }
            ... on FuneralDisposal {
                ID
                ClassName
                Title
                Start
                End
                CemeterySection #Comment
                Location {
                    ID
                    Name
                    Suburb
                }
                LocationFlattened
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
                EventConflicts {
                    ${EventConflictPartial}
                }
            }
            ... on RefreshmentsVenue {
                ID
                ClassName
                Title
                Start
                End
                Type
                Comment
                Location {
                    ID
                    Name
                    Suburb
                }
                LocationFlattened
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
                EventConflicts {
                    ${EventConflictPartial}
                }
            }
            ... on FuneralPlaceOfService {
                ID
                ClassName
                Title
                Start
                End
                Type
                Comment
                Location {
                    ID
                    Name
                    Suburb
                }
                LocationFlattened
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
                EventConflicts {
                    ${EventConflictPartial}
                }
            }
            ... on FuneralPlaceOfViewing {
                ID
                ClassName
                Title
                Start
                End
                Type
                Comment
                Location {
                    ID
                    Name
                    Suburb
                }
                LocationFlattened
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
                EventConflicts {
                    ${EventConflictPartial}
                }
            }
        }
    }
`;

const staffquery = gql`
    query ReadStaffAllocations(
        $from: String!
        $to: String!
        $filterStaff: [ID]
        $filterTypes: [String]
        $groupCode: String
        $memberLimit: Int
        $sortBy: [ReadMembersSortInputType]
    ) {
        readStaffAllocations(from: $from, to: $to, filterStaff: $filterStaff, filterTypes: $filterTypes) {
            ID
            Title
            Start
            End
            Type
            Allocation
            LocationFlattened
            Recurring
            Appointment {
                ID
                Reason
                Office
                Comment
                Phone
                LocationType
                Members {
                    ID
                    FirstName
                    Surname
                }
                Enquiry {
                    ID
                    LegacyKey
                    EnquirerGivenName
                    EnquirerSurname
                    NameOfDeceased
                    Funeral {
                        ID
                        LegacyKey
                        FirstName
                        Surname
                    }
                }
            }
            Member {
                ID
                FirstName
                Surname
            }
            Funeral {
                ID
                LegacyKey
                FirstName
                Surname
            }
            Service {
                ID
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
            }
            Viewing {
                ID
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
            }
            Disposal {
                ID
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
            }
            Refreshments {
                ID
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
            }
            ReflectionRoom {
                ID
                StaffAllocations {
                    ID
                    Allocation
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
            }
        }
        readMembers(groupCode: $groupCode, limit: $memberLimit, sortBy: $sortBy) {
            edges {
                node {
                    ID
                    FirstName
                    Surname
                    Email
                }
            }
        }
        readGroups(parentGroupCode: "fm-user") {
            ID
            Title
            Code
            Members {
                ID
                FirstName
                Surname
                Email
            }
        }
    }
`;

const styles = ({ spacing, typography }) => ({
    pageTitle: {
        fontWeight: typography.fontWeightLight
    },
    content: {
        padding: spacing.unit * 4
    },
    paper: {
        height: '100%',
        backgroundColor: 'transparent'
    },
    rbcHeader: {
        minWidth: '100px'
    }
});

export default compose(withRouter, withEditAppointment, withSnackbarMessage, withStyles(styles))(Calendar);
