import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { theme } from '../../Theme.js';
import { FUNERAL_HOME } from '../../util/funerals';
import { DotLegend } from '../../component/form/Legend';
import { SaveButton } from '../../component/form/PrimaryButton';
import { InlineField, InlineHeader } from '../../component/form/Inline';
import TickIcon from '../../component/icon/TickIcon';
import { getTransfersFunc, saveTransfersFunc } from './GetSaveTransfersFunc';
import { joinDefined, prettyPhone } from '../../util/strings';
import { dateTimeToString, dayDelta, niceDateFromString } from '../../util/date';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo/index';
import cloneDeep from 'lodash.clonedeep';
import BrandedTableKey from '../../component/BrandedTableKey';
import SaveIcon from '../../component/icon/SaveIcon';
import moment from 'moment';
import LinkButton from '../../component/form/LinkButton';
import Grid from '../../component/form/Grid';
import GatedComponent from '../../component/GatedComponent';
import TextField from '../../component/form/TextField';
import Spinner from '../../component/Spinner';
import { isNullOrUndefined } from '../../util/objects';
import { diff } from '../../util/functions';

class Transfers extends Component {
    state = {
        transfers: {
            edges: []
        },
        loaded: false,
        loadingCaption: 'Loading transfers...'
    };

    componentDidMount() {
        const that = this;
        this.unblock = this.props.history.block(this.onHistoryBlock);
        getTransfersFunc().then(original => {
            const transfers = cloneDeep(original);
            that.setState({ transfers, original, loaded: true });
        });
    }
    componentWillUnmount() {
        this.unblock();
    }

    onHistoryBlock = () => {
        return this.isDirty() ? 'You have unsaved changes, are you sure you want to leave this page?' : null;
    };

    isDirty = () => {
        const { transfers, original } = this.state;
        if (!original || !transfers) return null;
        const amDirty = diff(transfers, original, true);
        if (!amDirty) return null;
        return amDirty && amDirty.edges && amDirty.edges.filter(e => !!e.node).length > 0;
    };

    render() {
        const { classes } = this.props;
        const { transfers, loaded } = this.state;
        const isDirty = loaded && this.isDirty();
        return (
            <Fragment>
                {this.renderTitle()}
                <br />
                <Paper className={classes.paper} elevation={0}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="title">
                                Ready List - {transfers.edges.length} item{transfers.edges.length === 1 ? '' : 's'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.wideScroll}>
                        <br />
                        {this.renderKey()}
                        <br />
                        {this.renderTransfers()}
                    </div>
                    {transfers.edges.length !== 0 && (
                        <GatedComponent showComponentCode="FM_ACCESS_Transfers_Process_Deceased">
                            {() => {
                                return (
                                    <div className={classes.footer}>
                                        <SaveButton
                                            disabled={!loaded || !isDirty}
                                            onClick={() => this.commitTransferChanges()}
                                        >
                                            {!!loaded ? <SaveIcon /> : <Spinner />}
                                            <span className={classes.svgLabel}>
                                                {!!loaded ? 'Process Transfers' : 'Processing...'}
                                            </span>
                                        </SaveButton>
                                    </div>
                                );
                            }}
                        </GatedComponent>
                    )}
                </Paper>
            </Fragment>
        );
    }

    renderTitle() {
        const { classes } = this.props;
        return (
            <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                Transfers
                <span className={classes.header}>Ready List</span>
            </Typography>
        );
    }

    renderKey() {
        const { classes } = this.props;

        return (
            <InlineField>
                <Typography variant="body2" className={classes.keyTitle}>
                    Legend:
                </Typography>
                <BrandedTableKey />
                <DotLegend
                    title="Created in the last 7 days"
                    text="New"
                    dotColor={theme.palette.legend.transfers.newColor}
                />
                <DotLegend
                    title="Updated in the last 7 days"
                    text="Updated"
                    dotColor={theme.palette.legend.transfers.updatedColor}
                />
                <DotLegend
                    title="Service in less than 3 days"
                    text="Urgent"
                    dotColor={theme.palette.legend.transfers.urgentColor}
                />
                <DotLegend
                    title="Coroner required"
                    text="Police Transfer"
                    dotColor={theme.palette.legend.transfers.policeTransferColor}
                />
            </InlineField>
        );
    }

    renderTransfers() {
        const { transfers, loaded, loadingCaption } = this.state;

        if (!loaded && transfers.edges.length === 0) return <InlineHeader header={loadingCaption} />;

        const transfersList = () => {
            if (transfers.edges.length === 0) {
                return (
                    <tr>
                        <td colSpan={'10'}>
                            <InlineHeader header="No Transfers" />
                        </td>
                    </tr>
                );
            }
            return transfers.edges.map((transfer, i) => this.renderRow(transfer, i));
        };

        const { classes } = this.props;
        return (
            <div className={!loaded ? classes.loadingTable : ''}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th className={classes.colSm}>Key</th>
                            <th>Deceased Name</th>
                            <th>Transfer from</th>
                            <th>Date of Service</th>
                            <th>Doctor</th>
                            <th className={classes.colXs}>Disposal</th>
                            <th>Transfer Notes</th>
                            <th className={classes.colMd} title={'When call was taken'}>
                                Called
                            </th>
                            <th className={classes.colXs} title={'Certification received?'}>
                                Certs
                            </th>
                            <th className={classes.colXs} title={'Ready for collection?'}>
                                Ready
                            </th>
                            <th className={classes.colXs} title={'Transfer actioned?'}>
                                Actioned
                            </th>
                        </tr>
                    </thead>
                    <tbody>{transfersList()}</tbody>
                </table>
            </div>
        );
    }

    getStateColor = transferState => {
        switch (transferState.toLowerCase()) {
            case 'urgent':
                return theme.palette.legend.transfers.urgentColor;
            case 'new':
                return theme.palette.legend.transfers.newColor;
            case 'updated':
                return theme.palette.legend.transfers.updatedColor;
            case 'police transfer':
                return theme.palette.legend.transfers.policeTransferColor;
            default:
                return '';
        }
    };

    renderRow = (transfer, rowIndex) => {
        const { classes } = this.props;

        const letterCode = transfer.node.LegacyKey.slice(-1);
        const rowStyle = classes['rowColor' + letterCode];

        const transferStates = [];

        //records created in the last 3 days are new
        if (dayDelta(transfer.node.Created, new Date()) < 3) {
            transferStates.push('New');
        }

        //records edited in the last 7 days are updated
        else if (dayDelta(transfer.node.LastEdited, new Date()) < 7) {
            transferStates.push('Updated');
        }

        //records with dateofservice soon are urgent
        if (transfer.node.DateOfService && dayDelta(transfer.node.DateOfService, new Date()) > -3) {
            transferStates.push('Urgent');
        }

        if (transfer.node.Certification.TypeOfBdmDeathCertificate === 'Coroner') {
            transferStates.push('Police Transfer');
        }

        const { TransferComment } = this.state;

        const disposalTitle = () => {
            const title = !!transfer.node.Disposal && transfer.node.Disposal.CrematedOrBuried;
            switch (title) {
                case 'Buried':
                    return 'Burial';
                case 'Cremated':
                    return 'Cremation';
                case 'Repatriated':
                    return 'Repatriation';
                case 'Body Not Recovered':
                    return 'No Body';
                default:
                    return 'Disposal Unknown';
            }
        };

        return (
            <tr key={rowIndex} className={rowStyle}>
                <td>
                    <div className={classes.inline}>
                        <ul className={classes.dotList}>
                            {transferStates.map((transferState, i) => (
                                <li
                                    key={i}
                                    title={transferState}
                                    className={classes.dot}
                                    style={{ background: this.getStateColor(transferState) }}
                                />
                            ))}
                        </ul>
                        <LinkButton
                            onClick={e =>
                                this.props.history.push(
                                    '/funeral/' + transfer.node.LegacyKey + '/' + transfer.node.ID + '/certification'
                                )
                            }
                        >
                            <u>{transfer.node.LegacyKey}</u>
                        </LinkButton>
                    </div>
                </td>
                <td>
                    <div>
                        {joinDefined(
                            [
                                transfer.node.FirstName,
                                transfer.node.MiddleName,
                                transfer.node.Surname ? transfer.node.Surname.toUpperCase() : ''
                            ],
                            ' '
                        )}
                    </div>
                </td>
                <td>
                    <div>
                        {transfer.node.UseResidentialAddress && transfer.node.UsePlaceOfDeathAddress ? (
                            joinDefined(
                                [
                                    transfer.node.ResidentialAddress1,
                                    transfer.node.ResidentialAddress2,
                                    transfer.node.ResidentialTown,
                                    transfer.node.ResidentialState,
                                    transfer.node.ResidentialCountry
                                ],
                                ', '
                            )
                        ) : transfer.node.UsePlaceOfDeathAddress && transfer.node.PlaceOfDeathType === 'AddressBook' ? (
                            <div>
                                <div>
                                    {transfer.node.PlaceOfDeath.Name}
                                    {transfer.node.PlaceOfDeath.Phone ? (
                                        <Fragment>
                                            <br />
                                            {prettyPhone(transfer.node.PlaceOfDeath.Phone, false)}
                                        </Fragment>
                                    ) : null}
                                </div>
                                <div>
                                    {joinDefined(
                                        [
                                            transfer.node.PlaceOfDeath.AddressLine1,
                                            transfer.node.PlaceOfDeath.AddressLine2,
                                            joinDefined(
                                                [transfer.node.PlaceOfDeath.Suburb, transfer.node.PlaceOfDeath.State],
                                                ' '
                                            )
                                        ],
                                        ', '
                                    )}
                                </div>
                            </div>
                        ) : transfer.node.UsePlaceOfDeathAddress && transfer.node.PlaceOfDeathType === 'Residential' ? (
                            joinDefined(
                                [
                                    transfer.node.PlaceOfDeathResidentialAddress.AddressLine1,
                                    transfer.node.PlaceOfDeathResidentialAddress.AddressLine2,
                                    transfer.node.PlaceOfDeathResidentialAddress.Suburb,
                                    transfer.node.PlaceOfDeathResidentialAddress.State
                                ],
                                ', '
                            )
                        ) : transfer.node.TransferFromType === 'Residential' ? (
                            joinDefined(
                                [
                                    transfer.node.TransferFromResidentialAddress.AddressLine1,
                                    transfer.node.TransferFromResidentialAddress.AddressLine2,
                                    transfer.node.TransferFromResidentialAddress.Suburb,
                                    transfer.node.TransferFromResidentialAddress.State
                                ],
                                ', '
                            )
                        ) : (
                            <div>
                                <div>
                                    {transfer.node.TransferFrom.Name}
                                    {transfer.node.TransferFrom.Phone ? (
                                        <Fragment>
                                            <br />
                                            {prettyPhone(transfer.node.TransferFrom.Phone, false)}
                                        </Fragment>
                                    ) : null}
                                </div>
                                <div>
                                    {joinDefined(
                                        [
                                            transfer.node.TransferFrom.AddressLine1,
                                            transfer.node.TransferFrom.AddressLine2,
                                            joinDefined(
                                                [transfer.node.TransferFrom.Suburb, transfer.node.TransferFrom.State],
                                                ' '
                                            )
                                        ],
                                        ', '
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </td>
                <td>
                    <div>{niceDateFromString(transfer.node.DateOfService)}</div>
                </td>
                <td>
                    <div>
                        {transfer.node.Certification.TypeOfBdmDeathCertificate === 'Coroner' ? (
                            <Fragment>
                                {transfer.node.Certification.Coroner.Name}
                                {transfer.node.Certification.Coroner.Phone ||
                                transfer.node.Certification.Coroner.Mobile ? (
                                    <Fragment>
                                        <br />
                                        {prettyPhone(
                                            joinDefined(
                                                [
                                                    transfer.node.Certification.Coroner.Phone,
                                                    transfer.node.Certification.Coroner.Mobile
                                                ],
                                                '/'
                                            ),
                                            false
                                        )}
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {transfer.node.Certification.Doctor.Name}
                                {transfer.node.Certification.Doctor.Phone ||
                                transfer.node.Certification.Doctor.Mobile ? (
                                    <Fragment>
                                        <br />
                                        {prettyPhone(
                                            joinDefined(
                                                [
                                                    transfer.node.Certification.Doctor.Phone,
                                                    transfer.node.Certification.Doctor.Mobile
                                                ],
                                                '/'
                                            ),
                                            false
                                        )}
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        )}
                    </div>
                </td>
                <td>
                    <div>{disposalTitle()}</div>
                </td>
                <td className={classes.clickableCell} onClick={e => this.toggleTextField(rowIndex, 'TransferComment')}>
                    <div title="Click to enter notes...">
                        {!isNullOrUndefined(TransferComment) && TransferComment === rowIndex ? (
                            <TextField
                                InputProps={{ autoFocus: true }}
                                multiline={true}
                                placeholder="Enter transfer notes here..."
                                value={transfer.node.TransferComment}
                                onChange={e => {
                                    const { transfers } = this.state;
                                    transfers.edges[rowIndex].node.TransferComment = e.target.value || null;
                                    this.setState({ transfers });
                                }}
                                onBlur={e => this.setState({ TransferComment: null })}
                            />
                        ) : (
                            transfer.node.TransferComment || (
                                <span className={classes.noCallTime}>click to record a note</span>
                            )
                        )}
                    </div>
                </td>
                <td
                    title={'When call was taken'}
                    className={classes.clickableCell}
                    onClick={e => this.toggleField(rowIndex, 'TransferCalled', this.getTimestamp())}
                >
                    <div title="Click to enter time...">
                        {transfer.node.TransferCalled ? (
                            moment(transfer.node.TransferCalled).isValid() ? (
                                moment(transfer.node.TransferCalled).format('llll')
                            ) : (
                                transfer.node.TransferCalled
                            )
                        ) : (
                            <span className={classes.noCallTime}>click to record a time</span>
                        )}
                    </div>
                </td>
                <GatedComponent isEnabledCode="FM_ACCESS_Transfers_Checkoff">
                    {isEnabled => {
                        return (
                            <Fragment>
                                <td
                                    title={'Certification received?'}
                                    className={classes.clickableCell}
                                    onClick={e =>
                                        this.toggleField(
                                            rowIndex,
                                            'TransferCertificates',
                                            !transfer.node.TransferCertificates,
                                            isEnabled
                                        )
                                    }
                                >
                                    <div className={classes.iconContainer}>
                                        {transfer.node.TransferCertificates ? (
                                            <TickIcon className={classes.icon} />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </td>
                                <td
                                    title={'Ready for collection?'}
                                    className={classes.clickableCell}
                                    onClick={e =>
                                        this.toggleField(
                                            rowIndex,
                                            'TransferReady',
                                            !transfer.node.TransferReady,
                                            isEnabled
                                        )
                                    }
                                >
                                    <div className={classes.iconContainer}>
                                        {transfer.node.TransferReady ? <TickIcon className={classes.icon} /> : ''}
                                    </div>
                                </td>
                                <td
                                    title={'Transfer actioned?'}
                                    className={classes.clickableCell}
                                    onClick={e =>
                                        this.toggleField(
                                            rowIndex,
                                            'TransferActioned',
                                            !transfer.node.TransferActioned,
                                            isEnabled
                                        )
                                    }
                                >
                                    <div className={classes.iconContainer}>
                                        {transfer.node.TransferActioned ? <TickIcon className={classes.icon} /> : ''}
                                    </div>
                                </td>
                            </Fragment>
                        );
                    }}
                </GatedComponent>
            </tr>
        );
    };

    getTimestamp() {
        return dateTimeToString(new Date());
    }

    toggleTextField(rowIndex, fieldName) {
        const { editComment } = this.state;
        if (!editComment) {
            this.setState({ [fieldName]: rowIndex });
        }
    }

    toggleField(rowIndex, fieldName, newValue, canEdit = true) {
        const { transfers } = this.state;
        const row = transfers.edges[rowIndex];
        if (!canEdit) {
            return;
        }

        var mergedRow = {
            node: Object.assign({}, row.node, { [fieldName]: newValue })
        };

        const mergedTransfers = { edges: [...transfers.edges] };
        mergedTransfers.edges[rowIndex] = mergedRow;

        this.setState({ transfers: mergedTransfers });
    }

    commitTransferChanges() {
        const { transfers, original } = this.state;
        this.setState({ loaded: false, loadingCaption: 'Saving transfers...' });
        const me = this;
        saveTransfersFunc(transfers, original).then(
            data => {
                if (data.updateManyFuneral && data.updateManyFuneral.edges.length) {
                    const newResult = cloneDeep(original);
                    const newItems = data.updateManyFuneral.edges;
                    for (let i = 0; i < newItems.length; i++) {
                        const id = newItems[i].node.ID;
                        const oldResult = newResult.edges.find(e => e.node.ID === id);
                        oldResult.node = newItems[i].node;
                    }
                    const newTransfers = newResult.edges.filter(
                        x => !(!!x.node.TransferReady && !!x.node.TransferActioned && !!x.node.TransferCertificates)
                    );
                    me.setState({
                        loaded: true,
                        loadingCaption: 'Loading transfers...',
                        transfers: { edges: newTransfers },
                        original: cloneDeep({ edges: newTransfers })
                    });
                } else {
                    this.setState({ loaded: true, loadingCaption: 'Loading transfers...' });
                }
            },
            e => {
                console.error('error occurred saving transfers', e);
                me.setState({ loaded: true, loadingCaption: '' });
                me.props.setSnackbarMessage(
                    'Oops, there was an error - your changes have NOT saved.',
                    false,
                    null,
                    new Error(e)
                );
            }
        );
    }
}

const styles = ({ typography, spacing, palette, breakpoints, funeralHome }) => ({
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    },

    paper: {
        padding: spacing.unit * 3
    },

    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingLeft: 24,
        marginLeft: -12
    },

    keyTitle: {
        margin: '3px 0 0 0'
    },

    noCallTime: {
        fontWeight: 'light',
        fontStyle: 'italic',
        fontSize: '14px',
        color: '#AAA'
    },

    clickableCell: {
        '&:hover': {
            cursor: 'pointer !important',
            background: '#fff !important'
        },
        '& > :first-child': {
            width: '100%'
        }
    },
    loadingTable: {
        opacity: 0.5,
        pointerEvents: 'none'
    },
    iconContainer: {
        textAlign: 'center',
        border: '3px solid #757575',
        borderRadius: 5,
        width: '50px !important',
        height: 50
    },

    icon: {
        // fontSize: 16,
        color: 'white',
        // height: '30px',
        width: 44,
        height: 44
    },

    table: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        width: '100%',
        '& td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            fontSize: '14px;',
            padding: '8px 8px'
        },
        '& td:last-child': {
            borderWidth: '1px 1px 0px 1px'
        },
        '& tr:last-child td': {
            borderWidth: '1px 0px 1px 1px'
        },
        '& tr:last-child td:last-child': {
            borderWidth: '1px'
        },
        '& tr th:first-child': {
            borderTopLeftRadius: 5
        },
        '& tr th:last-child': {
            borderTopRightRadius: 5
        },
        '& th': {
            background: '#35327C',
            border: '1px solid #413BBE',
            padding: '10px 8px',
            color: '#FFFFFF',
            fontSize: '14px',
            fontWeight: typography.fontWeightMedium,
            textAlign: 'left'
        },
        '& strong': {
            fontWeight: 500
        }
    },

    footer: {
        zIndex: 1202,
        position: 'fixed',
        right: 24,
        bottom: 22,
        [breakpoints.down('xs')]: {
            bottom: 15,
            right: 29
        },
        '& button': {
            [breakpoints.down('xs')]: {
                minWidth: 36,
                padding: '12px 30px',
                borderRadius: 24,
                '& span > span, & span > svg': {
                    margin: 0,
                    height: 25,
                    width: 25,
                    fontSize: 38
                }
            }
        }
    },

    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },

    inline: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },

    dot: {
        height: 16,
        width: 16,
        borderRadius: 16
    },

    dotList: {
        minHeight: 16,
        width: 16,
        display: 'inline-block',
        position: 'absolute',
        left: -18,
        margin: '0 -10px 0 0',
        padding: '0',
        listStyle: 'none',
        '& > :not(:first-child)': {
            marginTop: 2
        }
    },

    colXs: {
        width: '60px'
    },

    colSm: {
        width: '80px'
    },

    colMd: {
        width: '130px'
    },

    ['rowColor' + [FUNERAL_HOME.RANKINS.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.RANKINS.letterCode],
        '& svg': { background: palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] },
        '&:hover': {
            outline: '3px solid ' + palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode],
            backgroundColor: palette.contentBackground[FUNERAL_HOME.RANKINS.letterCode] + '99'
        },
        '& a': { color: palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] }
    },

    ['rowColor' + [FUNERAL_HOME.STAN_CRAP.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.STAN_CRAP.letterCode],
        '& svg': { background: palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] },
        '&:hover': {
            outline: '3px solid ' + palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode],
            backgroundColor: palette.contentBackground[FUNERAL_HOME.STAN_CRAP.letterCode] + '99'
        },
        '& a': { color: palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] }
    },

    ['rowColor' + [FUNERAL_HOME.WOLLONGONG_CITY.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode],
        '& svg': { background: palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] },
        '&:hover': {
            outline: '3px solid ' + palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode],
            backgroundColor: palette.contentBackground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] + '99'
        },
        '& a': { color: palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] }
    },

    ['rowColor' + [FUNERAL_HOME.EASY_FUNERALS.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode],
        '& svg': { background: palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode] },
        '&:hover': {
            outline: '3px solid ' + palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode],
            backgroundColor: palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode] + '99'
        },
        '& a': { color: palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode] }
    },

    ['rowColor' + [FUNERAL_HOME.PARSONS_LADIES.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.PARSONS_LADIES.letterCode],
        '& svg': { background: palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] },
        '&:hover': {
            outline: '3px solid ' + palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode],
            backgroundColor: palette.contentBackground[FUNERAL_HOME.PARSONS_LADIES.letterCode] + '99'
        },
        '& a': { color: palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] }
    },

    ['rowColor' + [FUNERAL_HOME.H_PARSONS.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.H_PARSONS.letterCode],
        '& svg': { background: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] },
        '&:hover': {
            outline: '3px solid ' + palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode],
            backgroundColor: palette.contentBackground[FUNERAL_HOME.H_PARSONS.letterCode] + '99'
        },
        '& a': { color: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] }
    }
});

// prettier-ignore
export default compose(
    withSnackbarMessage,
    withStyles(styles)
)(Transfers);
