import gql from 'graphql-tag';
import { Invoice } from '../../../fragments/Invoices';
import { getClient } from '../../../../apollo';
import { diff } from '../../../../util/functions';
import { deleteTypeName } from '../../../../util/objects';
import { isRelatedObjectDefined, sanitizeObjectWithFK } from '../../../../util/bookable';

const createInvoiceMutation = gql`
    ${Invoice}
    mutation CreateInvoice($input: InvoiceCreateInputType!) {
        createInvoice(Input: $input) {
            ...InvoiceObject
        }
    }
`;

const updateInvoiceMutation = gql`
    ${Invoice}
    mutation UpdateInvoice($input: InvoiceUpdateInputType!) {
        updateInvoice(Input: $input) {
            ...InvoiceObject
        }
    }
`;

export const submitToXeroMutation = gql`
    ${Invoice}
    mutation SubmitToXero($id: ID!) {
        submitInvoiceToXero(ID: $id) {
            ...InvoiceObject
        }
    }
`;

const authoriseToXeroMutation = gql`
    ${Invoice}
    mutation AuthoriseToXero($id: ID!) {
        authoriseInvoiceToXero(ID: $id) {
            ...InvoiceObject
        }
    }
`;

const sendInvoiceXeroMutation = gql`
    ${Invoice}
    mutation SendInvoiceXero($id: ID!) {
        sendInvoiceXero(ID: $id) {
            ...InvoiceObject
        }
    }
`;

export const createInvoiceFunc = async invoice => {
    return await getClient().mutate({
        mutation: createInvoiceMutation,
        variables: {
            input: createInvoice(invoice)
        }
    });
};

export const updateInvoiceFunc = async (invoice, original) => {
    return await getClient().mutate({
        mutation: updateInvoiceMutation,
        variables: {
            input: trimInvoice(invoice, original)
        }
    });
};

export const submitToXeroFunc = async invoice => {
    return await getClient().mutate({
        mutation: submitToXeroMutation,
        variables: {
            id: invoice.ID
        }
    });
};

export const authoriseToXeroFunc = async invoice => {
    return await getClient().mutate({
        mutation: authoriseToXeroMutation,
        variables: {
            id: invoice.ID
        }
    });
};

export const sendInvoiceXeroFunc = async invoice => {
    return await getClient().mutate({
        mutation: sendInvoiceXeroMutation,
        variables: {
            id: invoice.ID
        }
    });
};

const trimInvoice = (update, original) => {
    // create a clone with any changes, so data is not disturbed during save or lost on failure.
    let invoice = JSON.parse(JSON.stringify(original ? diff({ ...update }, original) : { ...update }));

    //sanitizeObjectWithFK(invoice, 'Funeral', false);
    if (!!invoice.Funeral && Number(invoice.Funeral.ID) > 0) {
        invoice.FuneralID = invoice.Funeral.ID;
        delete invoice.Funeral;
    }

    deleteTypeName(invoice);

    delete invoice.useDefaultBillingSource;

    if (invoice.InvoiceItems) {
        for (let x = 0; x < invoice.InvoiceItems.length; x++) {
            let invoiceItem = invoice.InvoiceItems[x];
            sanitizeObjectWithFK(invoiceItem, 'Product', false);
            sanitizeObjectWithFK(invoiceItem, 'Variation', false);
            delete invoiceItem.QuoteID;
            delete invoiceItem.CostIncGST;
            deleteTypeName(invoiceItem);
        }
    }

    if (isRelatedObjectDefined(original)) {
        invoice.ID = original.ID;
        if (invoice.Customer && isRelatedObjectDefined(original.Customer)) {
            invoice.Customer.ID = original.Customer.ID;
        }
    }
    delete invoice.SalesRegisterTransactions;

    return { ...invoice };
};

const createInvoice = newInvoice => {
    // create a clone, so data is not disturbed during save or lost on failure.
    let invoice = JSON.parse(JSON.stringify({ ...newInvoice }));

    delete invoice.useDefaultBillingSource;
    //    delete invoice.Customer.BillingSource;
    if (invoice.InvoiceItems) {
        for (let x = 0; x < invoice.InvoiceItems.length; x++) {
            let invoiceItem = invoice.InvoiceItems[x];
            sanitizeObjectWithFK(invoiceItem, 'Product', false);
            sanitizeObjectWithFK(invoiceItem, 'Variation', false);
            delete invoiceItem.ID;
            delete invoiceItem.QuoteID;
            delete invoiceItem.CostIncGST;
            deleteTypeName(invoiceItem);
        }
    }
    return { ...invoice };
};
