import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TickableCircle from '../../workQueue2/TickableCircle';
import CheckableStar from '../../workQueue2/CheckableStar';
import CommentsIcon from '../../../component/icon/CommentsIcon';
import cx from 'classnames';
import { InlineFieldRightAlignChildren } from '../../../component/form/Inline';
import LinkButton from '../../../component/form/LinkButton';
import { isNullOrUndefined } from '../../../util/objects';
import { isRelatedObjectDefined } from '../../../util/bookable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuditEventTypes } from './TaskConstants';
import moment from 'moment';
import GatedComponent from '../../../component/GatedComponent';
import { joinDefined } from '../../../util/strings';
import { getUser } from '../../../util/sessions';

class TaskCard extends Component {
    render() {
        const { classes, className, onClickShowModal, task, loading, loadingTaskId } = this.props;
        const convertDateToCheck = date => !isNullOrUndefined(date);
        const convertCheckToDate = check => (check ? new Date() : null);
        const commentCount =
            task.node.Audits.edges.filter(audit => audit.node.Type === AuditEventTypes.Comment && !!audit.node.Comment)
                .length || 0;
        const onClickAction = isRelatedObjectDefined(task.node.Funeral) ? this.props.onClickAction : null;
        const overdue = moment() > moment(task.node.Due) && !task.node.Completed;

        const { Funeral } = task.node;

        const me = getUser();
        const myTask = task && task.node.AssignedMembers.find(e => !!e && e.ID === me.ID);
        const myStar = myTask && myTask.Join && myTask.Join.Starred;

        return (
            <Fragment>
                <div className={cx(classes.root, className)}>
                    <GatedComponent showComponentCode={'FM_ACCESS_Tasks_Complete'}>
                        {() => {
                            return (
                                <TickableCircle
                                    className={classes.tickButton}
                                    value={convertDateToCheck(task.node.Completed)}
                                    disabled={loading}
                                    loading={loadingTaskId === task.node.ID}
                                    onChange={checked => this.onChange('Completed', convertCheckToDate(checked))}
                                />
                            );
                        }}
                    </GatedComponent>
                    <div className={classes.content}>
                        <InlineFieldRightAlignChildren lineHeight={'2em'}>
                            <span className={classes.starButton}>
                                <CheckableStar
                                    value={convertDateToCheck(myStar)}
                                    disabled={loading}
                                    loading={loadingTaskId === task.node.ID}
                                    onChange={checked => {
                                        const AssignedMembers = task.node.AssignedMembers.map(e => {
                                            e.Join = {
                                                Starred:
                                                    e.ID === me.ID
                                                        ? checked
                                                            ? new Date()
                                                            : null
                                                        : e.Join && e.Join.Starred
                                            };
                                            return e;
                                        });
                                        this.onChange('AssignedMembers', AssignedMembers);
                                    }}
                                />
                            </span>
                            {!!task.node.Due && (
                                <span className={overdue ? classes.redtext : classes.text}>
                                    {overdue ? 'Overdue' : 'Due'}: {moment(task.node.Due).format('ll')}
                                </span>
                            )}
                        </InlineFieldRightAlignChildren>
                        <div className={classes.text}>
                            {isRelatedObjectDefined(Funeral) ? (
                                <Fragment>
                                    <LinkButton text={Funeral.LegacyKey} onClick={() => onClickAction()} />
                                    {' | '}
                                    {Funeral.NameOfDeceased.trim() ||
                                        joinDefined([Funeral.FirstName, Funeral.MiddleName, Funeral.Surname], ' ')}
                                </Fragment>
                            ) : (
                                <Fragment>&nbsp;</Fragment>
                            )}
                        </div>
                        <div className={classes.title}>{task.node.Title}</div>
                        <div className={classes.description}>{task.node.Description}</div>
                        <div className={classes.description}>
                            <strong>{task.node.RequiredAction}</strong>
                        </div>
                        {onClickAction && (
                            <div className={cx(classes.description, classes.tasklink)}>
                                <strong>
                                    {onClickAction && (
                                        <LinkButton
                                            classes={{ root: classes.letMeRap }}
                                            text={'Perform task now'}
                                            onClick={() => onClickAction()}
                                        />
                                    )}
                                </strong>
                            </div>
                        )}
                        <div className={classes.commentContainer}>
                            <CommentsIcon className={classes.commentIcon} />
                            <span className={classes.commentCount}>{commentCount} notes</span>
                            {loading && loadingTaskId === task.node.ID && (
                                <span className={classes.loading}>
                                    <CircularProgress />
                                </span>
                            )}
                        </div>
                    </div>

                    <div className={classes.viewContainer}>
                        <button className={classes.viewButton} onClick={() => onClickShowModal()}>
                            View Task Activity
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }

    onChange(propertyName, value) {
        const { onChange, task } = this.props;
        if (onChange) {
            onChange({
                node: {
                    ...task.node,
                    [propertyName]: value
                }
            });
        }
    }
}

const styles = ({ palette, breakpoints }) => ({
    root: {
        background: '#FFFFFF',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        borderStyle: 'solid',
        borderWidth: '0 0 3px 0',
        position: 'relative',
        borderColor: palette.contentForeground.none
    },
    content: {
        padding: '1em',
        paddingBottom: '4.25rem'
    },
    text: {
        fontSize: '0.8em',
        margin: '6px 0'
    },
    redtext: {
        fontSize: '0.8em',
        lineHeight: '2.2em',
        color: 'red'
    },
    description: {
        fontSize: '1em',
        fontWeight: 'lighter',
        padding: '6px 0'
        // lineHeight: '2.3em',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis'
    },
    tasklink: {
        '& span > span': {
            color: 'black',
            textDecoration: 'underline',
            '&:hover': {
                color: palette.contentForeground.none
            }
        }
    },

    title: {
        color: palette.contentForeground.none,
        fontSize: '1.25rem'
    },
    tickButton: {
        padding: 0,
        float: 'right',
        top: '-10px',
        right: '-10px'
    },
    starButton: {
        width: '15px'
    },
    commentIcon: {
        height: '12px',
        width: '12px'
    },
    commentCount: {
        fontSize: '10px',
        margin: '0 0 0 5px',
        verticalAlign: 'middle'
    },
    commentContainer: {
        position: 'absolute',
        bottom: '3rem'
    },
    viewContainer: {
        bottom: 0,
        width: '100%',
        position: 'absolute'
    },
    view: {
        padding: '5px 0',
        float: 'right',
        marginRight: '5px'
    },
    viewButton: {
        height: '100%',
        width: '100%',
        padding: '14px 0',
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: '1px 0 0 0',
        borderColor: palette.contentForeground.none,
        background: '#FFFFFF',
        color: palette.contentForeground.none,
        fontSize: '1rem',
        textTransform: 'capitalize',
        '&:hover': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        },
        '&:active': {
            background: '#D9DCDD',
            color: '#000000'
        }
    },
    // loading: {
    //     position: 'absolute',
    //     right: '1rem',
    //     transform: 'scale(0.5)'
    // },
    letMeRap: {
        //yo nigga!
        '& > span': {
            whiteSpace: 'normal',
            lineHeight: 'normal'
        }
    }
});

export default withStyles(styles)(TaskCard);
