import React from 'react';
import { Redirect, Route as StandardRoute } from 'react-router-dom';
import { isSignedIn } from '../util/sessions';

export default function Route({ auth, anon, redirectPath, ...rest }) {
    setPageTitle(
        rest.title,
        (rest.computedMatch && (rest.computedMatch.params.key || rest.computedMatch.params.id)) || null
    );

    if (auth) return <GatedRoute condition={!isSignedIn()} redirectPath={redirectPath || '/sign-in'} {...rest} />;
    if (anon) return <GatedRoute condition={isSignedIn()} redirectPath={redirectPath || '/'} {...rest} />;
    return <StandardRoute {...rest} />;
}

function GatedRoute({ condition, redirectPath, ...rest }) {
    if (!condition) return <StandardRoute {...rest} />;
    const { component, ...other } = rest;
    return (
        <StandardRoute
            {...other}
            render={props => <Redirect to={{ pathname: redirectPath, state: { referrer: props.location } }} />}
        />
    );
}

function setPageTitle(title, key) {
    if (!!title) document.title = 'Funeral Manager: ' + title + (key ? (Number(key) ? ' #' : ' ') + key : '');
    else document.title = 'Funeral Manager';
}
