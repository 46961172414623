import Deceased from './Deceased';
import DeceasedFragment from './DeceasedFragment';
import { ABORIGINAL_OPTIONS, DATE_OF_DEATH_OPTION, TORRES_OPTIONS } from './DeceasedConstants';
import { isContactDefined } from '../../../util/bookable';
import { titleCase } from '../../../util/strings';
import { validationHelper } from '../../../util/validation';
import { isNullOrUndefined } from '../../../util/objects';

export default {
    label: 'Deceased',
    component: Deceased,
    fragment: DeceasedFragment,
    onLoad: data => {
        if (!!data.DateEnteredAustralia) {
            data.DateEnteredAustralia = data.DateEnteredAustralia.substring(0, 4);
        }
        data.DeceasedChangesFromBDM = JSON.parse(data.DeceasedChangesFromBDM || "{}");
        if (!!data.DeceasedChangesFromBDM.DateEnteredAustralia) {
            data.DeceasedChangesFromBDM.DateEnteredAustralia = data.DeceasedChangesFromBDM.DateEnteredAustralia.substring(
                0,
                4
            );
        }

        if (!data.ReferencePhoto || !data.ReferencePhoto.ID) {
            data.ReferencePhoto = { ID: 0 };
        }

    },
    formatSaveData: (saveData, state) => {
        if (!state.Aboriginal) saveData.Aboriginal = 'N';
        if (!state.TorresStrait) saveData.TorresStrait = 'N';

        if (state.PlaceOfBirthCountry === 'Australia') {
            saveData.DateEnteredAustralia = null;
        } else if (
            !state.PlaceOfBirth1 ||
            (state.PlaceOfBirth1.toLowerCase() === 'unknown' && !state.PlaceOfBirthCountry)
        ) {
            saveData.PlaceOfBirth1 = 'Unknown';
            saveData.PlaceOfBirthState = null;
            saveData.PlaceOfBirthCountry = null;
        }
        if (!isNullOrUndefined(saveData.DateEnteredAustralia)) {
            saveData.DateEnteredAustralia = `${saveData.DateEnteredAustralia}-01-01`;
        }

        if (state.DateOfDeath1 && state.DateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value) {
            saveData.DateOfDeath1 = null;
        }

        if (state.Pensioner !== 'Y' && !!state.PensionType) {
            saveData.PensionType = null;
        }

        if (isContactDefined(saveData.PlaceOfDeath)) {
            saveData.PlaceOfDeathID = saveData.PlaceOfDeath.ID;
            delete saveData.PlaceOfDeath;
        }

        if (isContactDefined(saveData.TransferFrom)) {
            saveData.TransferFromID = saveData.TransferFrom.ID;
            delete saveData.TransferFrom;
        }

        if (saveData.Occupation) saveData.Occupation = titleCase(saveData.Occupation);

        /*
         * removed for HP-1625, as the textfields already enforce capitalisation...
        if (saveData.FirstName) saveData.FirstName = titleCase(saveData.FirstName);

        if (saveData.MiddleName) saveData.MiddleName = titleCase(saveData.MiddleName);

        if (saveData.Surname) saveData.Surname = titleCase(saveData.Surname);
        */

        // recalculate age if no longer using manual age
        if (saveData.ManualAge !== undefined && saveData.ManualAge === false) {
            saveData.Age = null;
            saveData.AgeCode = null;
        }

        if (saveData.DeceasedPhotos) {
            saveData.DeceasedPhotos = saveData.DeceasedPhotos.map(({ ID }) => ({ ID }));
        }
        if (saveData.ReferencePhoto) {
            saveData.ReferencePhotoID = saveData.ReferencePhoto.ID;
            delete saveData.ReferencePhoto;
        }
        saveData.DeceasedChangesFromBDM =
            Object.keys(saveData.DeceasedChangesFromBDM || {}).length === 0
                ? null
                : JSON.stringify(saveData.DeceasedChangesFromBDM);
    },
    validation: {
        required: ['Gender'],
        suggested: ['DateOfDeathType'],
        onValidate: {
            FirstName: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'FirstName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            MiddleName: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'MiddleName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            Surname: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'Surname';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            ReferencePhoto: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                if (changes['ReferencePhoto'] !== undefined) {
                    return validationHelper.optional('Client updated reference photo');
                } else if (changes['ReferencePhoto.ID'] !== undefined) {
                    return validationHelper.optional('Client removed reference photo');
                }
                return validationHelper.ok();
            },
            SurnameAtBirth: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'FamilyNameAtBirth';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            PlaceOfDeathType: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'PlaceOfDeathType';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            UseResidentialAddress: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'UseResidentialAddress';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' + (!changes[fieldName] ? 'No' : 'Yes')
                    );
                }
                return validationHelper.ok();
            },
            'PlaceOfDeathResidentialAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const street = 'PlaceOfDeathResidentialAddress.AddressLine1';
                const city = 'PlaceOfDeathResidentialAddress.Suburb';
                const state = 'PlaceOfDeathResidentialAddress.State';
                const postcode = 'PlaceOfDeathResidentialAddress.Postcode';
                if (
                    changes[street] !== undefined ||
                    changes[city] !== undefined ||
                    changes[state] !== undefined ||
                    changes[postcode] !== undefined
                ) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' +
                        (changes[street] || getField(street)) +
                        ', ' +
                        (changes[city] || getField(city)) +
                        ' ' +
                        (changes[state] || getField(state)) +
                        ' ' +
                        (changes[postcode] || getField(postcode))
                    );
                }
                return validationHelper.ok();
            },
            'PlaceOfDeath.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                if (getField('PlaceOfDeath.ID') !== changes['PlaceOfDeathID']) {
                    return validationHelper.optional('Selected hospital was changed');
                }
                return validationHelper.ok();
            },
            ResidentialAddress1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const street = 'ResidentialAddress1';
                const city = 'ResidentialTown';
                const state = 'ResidentialState';
                const country = 'ResidentialCountry';
                const postcode = 'PostCode';
                if (
                    changes[street] !== undefined ||
                    changes[city] !== undefined ||
                    changes[state] !== undefined ||
                    changes[country] !== undefined ||
                    changes[postcode] !== undefined
                ) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' +
                        (changes[street] || getField(street)) +
                        ', ' +
                        (changes[city] || getField(city)) +
                        ' ' +
                        (changes[state] || getField(state)) +
                        ' ' +
                        (changes[country] || getField(country)) +
                        ' ' +
                        (changes[postcode] || getField(postcode))
                    );
                }
                return validationHelper.ok();
            },
            DateOfDeath1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'DateOfDeath1';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                const dateOfDeathType = getField('DateOfDeathType');
                if (hasValue || dateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value) return validationHelper.ok();

                return validationHelper.suggested();
            },
            DateOfDeath2: (newValue, persistedValue, hasValue, getField) => {
                const dateOfDeathType = getField('DateOfDeathType');
                if (hasValue || dateOfDeathType !== DATE_OF_DEATH_OPTION.BETWEEN.value) return validationHelper.ok();

                return validationHelper.required();
            },
            DateOfBirth: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'DateOfBirth';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' + (changes[fieldName] ? changes[fieldName] : 'Unknown')
                    );
                }
                if (getField('DateOfDeath1') < persistedValue)
                    return validationHelper.required('Must not be after date of death.');
                if (getField('UnknownDOB') === true || !isNullOrUndefined(persistedValue)) return validationHelper.ok();

                return validationHelper.required();
            },
            UnknownDOB: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'UnknownDOB';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' + (changes[fieldName] === 1 ? 'Yes' : 'No')
                    );
                }
                return validationHelper.ok();
            },
            Age: (newValue, persistedValue, hasValue, getField) => {
                if (isNullOrUndefined(persistedValue) || Number(persistedValue) < 1) {
                    if (
                        getField('DateOfDeathType') === 'Unknown' ||
                        getField('UnknownDOB') === true ||
                        getField('ManualAge')
                    ) {
                        if (['Stillborn', 'Not Stated', 'Unknown'].indexOf(getField('AgeCode')) < 0)
                            return validationHelper.required();
                    }
                }
                return validationHelper.ok();
            },
            AgeCode: (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('DateOfDeathType') === 'Unknown' ||
                    getField('UnknownDOB') === true ||
                    getField('ManualAge')
                ) {
                    if (isNullOrUndefined(persistedValue)) return validationHelper.required();
                }
                return validationHelper.ok();
            },
            PlaceOfBirth1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'PlaceOfBirth1';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            PlaceOfBirthState: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'PlaceOfBirthState';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            PlaceOfBirthCountry: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'PlaceOfBirthCountry';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            DateEnteredAustralia: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'DateEnteredAustralia';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                const placeOfBirthCountry = getField('PlaceOfBirthCountry');
                const placeOfBirth1 = getField('PlaceOfBirth1');
                const dateEnteredUnknown = getField('DateEnteredAustraliaUnknown');

                if (placeOfBirthCountry === 'Australia' || placeOfBirth1 === 'Unknown' || dateEnteredUnknown) {
                    return validationHelper.ok();
                }

                if (!isNullOrUndefined(persistedValue)) {
                    const value = parseInt(persistedValue, 10);
                    const max = new Date().getFullYear();
                    const min = max - 120;
                    return value < min || value > max
                        ? validationHelper.required(`Must be between ${min} and ${max}`)
                        : validationHelper.ok();
                }

                return validationHelper.suggested();
            },
            Occupation: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'Occupation';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            Pensioner: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'Pensioner';
                const typeFieldName = 'PensionType';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' +
                        (changes[fieldName] === 'Y' ? 'Yes' : 'No') +
                        (changes[typeFieldName] !== undefined ? ' (' + changes[typeFieldName] + ')' : '')
                    );
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            PensionType: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'PensionType';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            Retired: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'Retired';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' + (changes[fieldName] === 'Y' ? 'Yes' : 'No')
                    );
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            Aboriginal: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'Aboriginal';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' + ((ABORIGINAL_OPTIONS.find(opt => opt.value === changes[fieldName]) || { label: 'Unknown' }).label)
                    );
                }
                return validationHelper.ok();
            },
            TorresStrait: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM');
                const fieldName = 'TorresStrait';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Value has changed, new value: ' + ((TORRES_OPTIONS.find(opt => opt.value === changes[fieldName]) || { label: 'Unknown' }).label)
                    );
                }
                return validationHelper.ok();
            }
        }
    }
};
