import React, { Component, Fragment } from 'react';
import ModalStepTitle from '../../../component/form/ModalStepTitle';
import { Typography, withStyles } from '@material-ui/core';
import Label from '../../../component/form/Label';
import TextField from '../../../component/form/TextField';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import RadioGroup from '../../../component/form/RadioGroup';
import { diff } from '../../../util/functions';
import { getEnquiryClient } from '../../../apollo';
import gql from 'graphql-tag';
import Select from '../../../component/form/Select';
import FileUploadCard from '../../workQueue2/extras/FileUploadCard';

class QuotesModalConfirmTab extends Component {
    state = {
        templates: null
    };

    setEmail = e => {
        this.props.setEmail(e);
        this.forceUpdate();
    };

    componentDidMount() {
        const { OfficeID } = this.props;
        getEmailTemplates().then(data => {
            this.setState({
                templates:
                    (
                        data.edges &&
                        data.edges.map(e => {
                            return {
                                label: `#${e.node.ID}: ` + (e.node.Title || e.node.LegacyKey),
                                value: e.node.ID,
                                office: e.node.Office.ID,
                                template: e.node.EmailText,
                                attached: e.node.EmailAttachments
                            };
                        })
                    ).filter(e => e.office === OfficeID) || []
            });
        });
    }

    render() {
        const { classes, readQuote } = this.props;
        const { Accepted, Released } = readQuote;
        const notDraft = Accepted || Released;

        return (
            <div className={classes.root}>
                <div>
                    <ModalStepTitle
                        number={notDraft ? false : 'Three'}
                        title={
                            notDraft
                                ? 'Send the quote again or change its status'
                                : 'Please release quote via an email to customer.'
                        }
                        header={notDraft ? `Review Quote #${readQuote.ID}:` : false}
                    />
                    {!readQuote && <Typography variant="title">Please select a quote first.</Typography>}
                </div>
                {!readQuote ? null : this.renderPreview()}
            </div>
        );
    }

    changeAccepted = e => {
        const { originalStatus } = this.props;
        const { name, checked } = e.target;
        this.props.setAdminAction(!!checked ? (name === 'isAccepted' ? 'Accepted' : 'Declined') : originalStatus);
    };

    renderPreview = () => {
        const { classes, readQuote, editQuote, emailForm, adminAltered } = this.props;
        const { templates } = this.state;

        const { Accepted, Released } = readQuote;
        const notDraft = !!(Accepted || Released);

        const { emailTo, emailTemplate, emailSubject, emailBody, emailCC } = emailForm.fields;

        if (!(readQuote && readQuote.ID && Number(readQuote.ID) > 0)) return <div>Please save the quote first.</div>;

        if (!notDraft) {
            // can't use isDirty here...
            const input = diff([readQuote.QuoteItems, readQuote.Title], [editQuote.QuoteItems, editQuote.Title], true);
            if (input.length > 0) return <div>Please save your changes to the quote first.</div>;
        }
        const template = !!templates && templates.find(x => Number(x.value) === Number(emailTemplate));
        const hasAttachments = !!template && template.attached;

        return (
            <div>
                <div className={classes.previewColumn}>
                    <Typography variant="title" style={{ marginBottom: 20 }}>
                        {notDraft ? 'The quote appears as below:' : 'The quote will appear as below:'}
                    </Typography>
                    <div style={{ background: 'grey' }}>
                        <iframe
                            src={readQuote.AbsolutePDFLink}
                            style={{ width: '100%', height: 660 }}
                            title="quote-pdf"
                        />
                    </div>
                </div>

                <div className={classes.emailColumn}>
                    <Grid container spacing={24}>
                        <Grid bucket>
                            {(!!Accepted && (
                                <Grid pc={1}>
                                    <Checkbox
                                        label="Accepted Quote has been cancelled?"
                                        checked={adminAltered === 'Declined' ? true : null}
                                        name="isNotAccepted"
                                        onChange={this.changeAccepted}
                                    />
                                </Grid>
                            )) ||
                                (!Released && (
                                    <Grid pc={1}>
                                        <Checkbox
                                            label="Quote is already accepted?"
                                            checked={adminAltered === 'Accepted' ? true : null}
                                            name="isAccepted"
                                            onChange={this.changeAccepted}
                                        />
                                    </Grid>
                                )) || (
                                    <Grid pc={1}>
                                        <RadioGroup
                                            label="Quote Status"
                                            options={[
                                                { label: 'Released', value: 'Released' },
                                                { label: 'Accepted', value: 'Accepted' },
                                                { label: 'Declined', value: 'Declined' }
                                            ]}
                                            value={adminAltered}
                                            name="isAccepted"
                                            onChange={e => this.props.setAdminAction(e.target.value)}
                                        />
                                    </Grid>
                                )}
                        </Grid>
                        <Grid bucket>
                            <Fragment>
                                <Grid pc={1}>
                                    <Label text={'Email to'} htmlFor={'emailTo'} />
                                    <TextField
                                        required={true}
                                        name={'emailTo'}
                                        onChange={this.setEmail}
                                        value={emailTo}
                                        type={'email'}
                                        inputProps={{ multiple: true }}
                                        helperText="Separate multiple email addresses with a comma."
                                    />
                                </Grid>
                                <Grid pc={1}>
                                    <Label text={'Subject'} htmlFor={'emailSubject'} className={classes.labelGap} />
                                    <TextField
                                        name={'emailSubject'}
                                        onChange={this.setEmail}
                                        value={emailSubject}
                                        required={true}
                                    />
                                </Grid>
                                <Grid pc={1}>
                                    <Label text={'Template'} htmlFor={'emailBody'} className={classes.labelGap} />
                                    <Select
                                        disabled={!!templates && templates.length === 0}
                                        options={templates || []}
                                        placeholder={
                                            !!templates && templates.length > 0
                                                ? '(Choose a template to prefill message...)'
                                                : !!templates
                                                ? '(No templates for this office.)'
                                                : '(Loading templates...)'
                                        }
                                        value={emailTemplate || ''}
                                        onChange={this.setTemplate}
                                    />
                                </Grid>
                                <Grid pc={1}>
                                    <Label text={'Message'} htmlFor={'emailBody'} className={classes.labelGap} />

                                    <TextField
                                        name={'emailBody'}
                                        onChange={this.setEmail}
                                        value={emailBody}
                                        multiline
                                        rows={10}
                                        rowsMax={10}
                                        required={true}
                                        placeholder={'You must enter a message to send with the quote.'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Checkbox
                                        label={'Send me a copy'}
                                        name={'emailCC'}
                                        onChange={this.setEmail}
                                        checked={emailCC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    {!!hasAttachments && hasAttachments.length > 0 && (
                                        <div style={{ marginRight: -8 }}>
                                            <Label>Template Attachments:</Label>
                                            {hasAttachments.map(e => (
                                                <FileUploadCard variant="mini" key={e.ID} file={e} />
                                            ))}
                                        </div>
                                    )}
                                </Grid>
                            </Fragment>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };

    setTemplate = e => {
        const { templates } = this.state;
        const newText = (!!templates && templates.find(x => x.value === e.target.value)) || {
            template: null
        };
        this.setEmail({
            target: {
                name: 'emailTemplate',
                value: e.target.value
            }
        });
        if (!!newText.template) {
            this.setEmail({
                target: {
                    name: 'emailBody',
                    value: this.makeSubstitutions(newText.template)
                }
            });
        }
    };

    makeSubstitutions(description) {
        const { substitutionFields } = this.props;

        if (substitutionFields) {
            for (let key in substitutionFields) {
                if (substitutionFields[key]) {
                    description = description.split(key).join(substitutionFields[key]);
                }
            }
        }
        return description;
    }
}

const getEmailTemplates = async () => {
    const asyncQuery = await getEnquiryClient().query({ query: readEmailTemplates() });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readQuoteEmailTemplates : {};
};

const readEmailTemplates = () => gql`
    query {
        readQuoteEmailTemplates {
            edges {
                node {
                    ID
                    LegacyKey
                    Title
                    EmailText
                    Office {
                        ID
                    }
                    EmailAttachments {
                        ... on SilverStripeFile {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on SilverStripeImage {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
            }
        }
    }
`;

const styles = ({ palette, typography, breakpoints }) => ({
    root: {
        maxHeight: 'calc(100vh - 375px)',
        minHeight: '33vh',
        overflow: 'auto',
        padding: 42,
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 175px)',
            padding: 12
        }
    },
    dataList: {
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 30
    },
    emailColumn: {
        marginLeft: 700,
        [breakpoints.down('md')]: { float: 'none', marginLeft: 0, width: '100%' }
    },
    previewColumn: {
        float: 'left',
        width: 660,
        maxWidth: '100%',
        [breakpoints.down('md')]: { float: 'none', marginBottom: 20, width: '100%' }
    },
    labelGap: {
        // marginTop: '1rem',
        // display: 'block'
    }
});

export default withStyles(styles)(QuotesModalConfirmTab);
