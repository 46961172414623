import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import TextField from '../form/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '../form/Grid';
import CloseIcon from '../icon/CloseIcon';
import { OutlineButton, SaveButton } from '../form/PrimaryButton';
import cloneDeep from 'lodash.clonedeep';
import EnvelopeIcon from '../icon/EnvelopeIcon';
import { getClient } from '../../apollo';
import gql from 'graphql-tag';
import Label from '../../component/form/Label';
import AddressAutocomplete from '../../component/form/AddressAutocomplete';
import { GST, ProductConfig } from '../../util/products';
import { isNullOrUndefined } from '../../util/objects';
import { joinDefined, prettyPrice } from '../../util/strings';
import { niceTimeFromString } from '../../util/date';
import moment from 'moment';
import { isRelatedObjectDefined } from '../../util/bookable';
import { createForm } from '../../util/form';

class RequestBookingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sendingEmail: false,
            date: moment(props.booking.Required).format('YYYY-MM-DD')
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.open === false && nextProps.open === true) {
            const booking = nextProps.booking ? cloneDeep(nextProps.booking) : {};
            const address = {};

            const { hasVenue } = nextProps;
            if (hasVenue && isRelatedObjectDefined(hasVenue)) {
                booking.DeliveryDate = hasVenue.Date;
                booking.DeliveryTime = hasVenue.Time;
                if (isRelatedObjectDefined(hasVenue, 'Location')) {
                    booking.DeliveryAddress = joinDefined(
                        [
                            hasVenue.Location.AddressLine1,
                            hasVenue.Location.AddressLine2,
                            hasVenue.Location.Suburb,
                            hasVenue.Location.State !== 'NSW' && hasVenue.Location.State
                        ],
                        ', '
                    );
                    address.AddressLine1 = hasVenue.Location.AddressLine1;
                    address.AddressLine2 = hasVenue.Location.AddressLine2;
                    address.Suburb = hasVenue.Location.Suburb;
                    address.State = hasVenue.Location.State !== 'NSW' && hasVenue.Location.State;
                }
            }

            this.setState({ booking, address });
        }
    }

    render() {
        const { classes, open, className } = this.props;
        const { booking } = this.state;
        const form = createForm(this, this.state);
        return (
            <Dialog
                classes={{ paper: cx(className, classes.root) }}
                open={open}
                onClose={this.onClose}
                aria-labelledby={'RequestBookingModal'}
            >
                <form onSubmit={e => this.onSubmit(e)}>{booking && this.renderForm(form)}</form>
            </Dialog>
        );
    }

    renderForm(form) {
        const { sendingEmail, address, booking } = this.state;
        const { classes, isOrder, label, contact, funeralInfo } = this.props;
        const actionType = isOrder ? 'Order' : 'Booking';

        return (
            <Fragment>
                <DialogTitle className={classes.diagHeader}>
                    <EnvelopeIcon className={classes.starButton} />
                    <div className={classes.diagHeaderTitle}>
                        <strong>{label || 'Supplier'}: </strong>
                        {contact.Name}
                    </div>
                    <div className={classes.diagHeaderSubtitle}>
                        {actionType} Request for {funeralInfo.LegacyKey}
                    </div>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    <Grid container spacing={8} pc={1} style={{ margin: 0 }}>
                        <Grid item xs={12}>
                            <Label text={'To: ' + contact.Email} />
                            <hr />
                            <Label
                                text={isOrder ? 'Delivery Address' : 'Event Address'}
                                htmlFor="booking.DeliveryAddress"
                            />

                            <AddressAutocomplete
                                id="DeliveryAddress"
                                placeholder="Search for a street address..."
                                form={form}
                                componentFields={{
                                    line1: 'address.AddressLine1',
                                    line2: 'address.AddressLine2',
                                    city: 'address.Suburb',
                                    state: 'address.State'
                                }}
                                name={address}
                                value={booking.DeliveryAddress}
                                allowCustomAddress={true}
                                onSelect={e => this.setField('DeliveryAddress', e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={'Date Required'}
                                type="date"
                                placeholder="Enter delivery or event date..."
                                value={booking.DeliveryDate}
                                onChange={e => this.setField('DeliveryDate', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={'Time Required'}
                                type="time"
                                placeholder="Enter delivery or event time..."
                                value={booking.DeliveryTime}
                                onChange={e => this.setField('DeliveryTime', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={'Additional Instructions'}
                                multiline={true}
                                rows={8}
                                placeholder="Enter additional instructions here..."
                                value={booking.Notes}
                                onChange={e => this.setField('Notes', e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={classes.diagActions}>
                    <OutlineButton onClick={this.onCancel}>
                        <CloseIcon />
                        <span className={classes.svgLabel}>Cancel</span>
                    </OutlineButton>

                    <SaveButton type="submit" disabled={!!sendingEmail}>
                        <EnvelopeIcon />
                        <span className={classes.svgLabel}>{!!sendingEmail ? 'Sending...' : 'Send Email'}</span>
                    </SaveButton>
                </DialogActions>
            </Fragment>
        );
    }

    setField(propertyName, value) {
        const { booking } = this.state;
        booking[propertyName] = value;
        this.setState({ booking });
    }

    onCancel = reload => {
        this.props.onClose(reload);
        this.clearState();
    };

    clearState() {
        this.setState({ booking: null, sendingEmail: false, address: null });
    }

    onSubmit(e) {
        e.preventDefault();

        const { contact } = this.props;
        const { booking, sendingEmail } = this.state;

        if (!!sendingEmail) return null;
        else this.setState({ sendingEmail: true });

        const that = this;

        //let flowerItems = renderProductsList(booking.BookingItems);

        this.sendBookingFunc(this.props.funeralInfo, booking, contact).then(
            mutation => {
                if (mutation.data) {
                    if (mutation.data.createFuneralManagerFuneralBookingReply) {
                        that.onCancel(true);
                    } else console.error('unexpected result: ', mutation.data);
                } else {
                    that.onGqlError('GQL Query returned no data');
                    that.setState({ sendingEmail: false });
                    return null;
                }
            },
            error => {
                that.setState({ sendingEmail: false });
                that.onGqlError('Failed to send email', error);
            }
        );
    }

    onGqlError(action, error) {
        console.error('onGqlError: ' + action, error);
    }

    sendBookingFunc = async (funeralInfo, booking, contact) => {
        let asyncQuery;
        const { label } = this.props;
        const { Notes } = booking;

        asyncQuery = await getClient().mutate({
            mutation: createFuneralManagerFuneralBookingReply,
            variables: {
                input: {
                    BookingID: booking.ID,
                    DeliveryAddress: booking.DeliveryAddress,
                    DeliveryDate: booking.DeliveryDate,
                    DeliveryTime: booking.DeliveryTime,
                    EmailTo: contact.Email,
                    EmailBody:
                        (isNullOrUndefined(booking.Notes) ? '' : '<p>' + Notes.replace(/\n/g, '<br/>') + '</p>') +
                        '<p>' +
                        (isNullOrUndefined(booking.BookingItems) || booking.BookingItems.length <= 0
                            ? 'Request for: <strong>' + label + '</strong> ' + contact.Name + '<br/>Venue '
                            : renderProductsList(booking.BookingItems) + 'Delivery ') +
                        'Address: ' +
                        (isNullOrUndefined(booking.DeliveryAddress) ? 'Not required.' : booking.DeliveryAddress) +
                        '<br/>' +
                        'Required Date: ' +
                        (isNullOrUndefined(booking.DeliveryDate)
                            ? moment()
                                  .add(2, 'days')
                                  .format('D/MM/YYYY')
                            : moment(booking.DeliveryDate).format('D/MM/YYYY')) +
                        '<br/>' +
                        'Required Time: ' +
                        (isNullOrUndefined(booking.DeliveryTime)
                            ? '08:00 AM'
                            : niceTimeFromString(booking.DeliveryTime)) +
                        '</p>'
                }
            }
        });

        return asyncQuery;
    };
}

const gstIncPrice = (item, product) => {
    if (!product) return '';
    let needsGST = (!!product.original && !!product.original.gst) || false;
    if (!!item.VariationID && item.VariationID !== '0') {
        const variant = product.variations.find(e => e.id === item.VariationID);
        if (!!variant) needsGST = !!variant.gst;
    }
    return item.UnitPrice * (!!needsGST ? GST + 1 : 1); //incGST
};

const renderProductItem = item => {
    const product = ProductConfig.productMap[item.ProductID];
    return `<td><img src=${product.image} width="100%" /><br/>
${item.Quantity}x ${product.internalId}:
<strong>${item.Title || product.title}</strong> for $${prettyPrice(gstIncPrice(item, product), true)} each.
${!!item.Comment ? '<br/>Comment: <i>' + item.Comment + '</i>' : ''}
</td>`;
};

const renderProductsList = items => {
    let orderTotal = 0;
    items.forEach(item => {
        const product = ProductConfig.productMap[item.ProductID];
        if (!!product) orderTotal += item.Quantity * gstIncPrice(item, product);
    });
    return `Products required:</p><table width="320" style="margin:auto">
${items.map((item, i) => `<tr>${renderProductItem(item)}</tr>`)}
</table><p>Order Total: $${prettyPrice(orderTotal, true)} (includes GST)</p><p>`;
};

const createFuneralManagerFuneralBookingReply = gql`
    mutation createFuneralManagerFuneralBookingReply($input: FuneralManagerFuneralBookingReplyCreateInputType!) {
        createFuneralManagerFuneralBookingReply(Input: $input) {
            ID
            Booking {
                ID
            }
            ActionedBy {
                ID
                Email
            }
            Supplier {
                ID
                Name
            }
        }
    }
`;

const styles = ({ palette, typography, transitions, funeralHome, breakpoints }) => ({
    root: {
        maxWidth: '800px',
        width: '100%',
        maxHeight: 'calc(100vh - 2rem)'
    },
    diagHeader: {
        background: palette.contentForeground[funeralHome],
        padding: 32
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    content: {
        width: '100%',
        padding: 32,
        maxHeight: 'calc(100vh - 18rem)',
        overflow: 'auto'
    },
    diagActions: {
        padding: 32,
        paddingTop: 0,
        margin: 0,
        justifyContent: 'space-between',
        '& button': {
            [breakpoints.down('xs')]: {
                padding: 8,
                minWidth: 16,
                '& span > span, & span > svg': {
                    width: 24,
                    height: 24,
                    fontSize: 26,
                    margin: 6
                }
            }
        }
    },

    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },

    regular: {
        fontSize: '16px',
        fontWeight: 'lighter'
    },

    greenButt: {
        border: '1px solid' + palette.action.save,
        backgroundColor: palette.action.save,
        '&:hover': {
            backgroundColor: palette.action.hover,
            color: palette.action.save
        }
    },
    starButton: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    }
});

export default withStyles(styles)(RequestBookingModal);
