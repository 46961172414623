import React, { Component } from 'react';
import { Icon, withStyles } from '@material-ui/core';
import ModalStepTitle from '../../../component/form/ModalStepTitle';
import TableData from '../../../component/form/TableData';
import Radio from '../../../component/form/Radio';
import TextField from '../../../component/form/TextField';
import { prettyPrice } from '../../../util/strings';
import { calcQuoteTotal } from '../../../util/quotes';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import { flattenConnection } from '../../../util/functions';
import { cloneDeep } from 'apollo-utilities';
import { lookupItemSource } from './QuotesModal';
import { getEnquiryClient } from '../../../apollo';
import { WhiteLoader } from '../../../component/WhiteLoader';

class QuotesModalChooseTab extends Component {
    state = {
        searchKeyword: ''
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.modalHeader}>
                    <ModalStepTitle number="One" title="Please select a quote template">
                        {this.renderFilterForm()}
                    </ModalStepTitle>
                </div>
                <Query
                    client={getEnquiryClient()}
                    query={queryQuoteTemplates}
                    variables={{
                        NotCurrent: false,
                        sortBy: [{ field: 'Created', direction: 'DESC' }]
                    }}
                >
                    {results => this.renderTemplateList(results)}
                </Query>
            </div>
        );
    }

    renderFilterForm() {
        return (
            <div style={{ position: 'relative' }}>
                <button type='submit' disabled style={{ zIndex: -999, position: 'absolute' }}>
                    just a hack to prevent page submit when enter is pressed...
                </button>
                <Icon style={{ position: 'absolute', margin: '8px 0 0 8px', zIndex: 1 }}>search</Icon>
                <TextField
                    InputProps={{
                        style: { paddingLeft: 34, width: 300, maxWidth: 'calc(100vw - 88px)' }
                    }}
                    placeholder="Start typing to filter templates"
                    fullWidth={false}
                    value={this.state.searchKeyword}
                    onChange={e => this.setState({ searchKeyword: e.target.value })}
                />
            </div>
        );
    }

    renderTemplateList = ({ error, loading, data }) => {
        if (error) return 'Error!';
        if (loading) return <WhiteLoader actionWord="Loading quote templates" />;
        const { classes, form } = this.props;

        const templates = data && cloneDeep(data.readQuoteTemplates.edges);
        flattenTemplates(templates);

        const words = (this.state.searchKeyword + '').toLowerCase().split(' ');
        const types = (this.props.isImminent) ? ['ATNEED'] : [this.props.quotesType];

        const tableData =
            templates &&
            templates.length &&
            templates.map(e => {
                return {
                    ID: e.ID,
                    Description: e.Description,
                    QuoteType: e.QuoteType,
                    LegacyKey: e.LegacyKey,
                    Amount: calcQuoteTotal(e.QuoteTemplateItems)
                };
            });

        const tableDataFiltered =
            tableData &&
            tableData
                .filter(e => {
                    return words.every(word => {
                        if (e.ID === word) return true;
                        if ((e.QuoteType + '').toLowerCase().includes(word)) return true;
                        if ((e.Description + '').toLowerCase().includes(word)) return true;
                        if ((e.LegacyKey + '').toLowerCase().includes(word)) return true;
                        return false;
                    });
                })
                .filter(e => {
                    return !(types && types.indexOf(e.QuoteType) < 0);
                });

        if (tableDataFiltered && tableDataFiltered.length)
            return (
                <div className={classes.dataList}>
                    <TableData
                        columns={[
                            { id: 'LegacyKey', label: 'Quote template key' },
                            { id: 'Description', label: 'Template description' },
                            {
                                id: 'Amount',
                                label: 'Total',
                                render: val => prettyPrice(val),
                                styles: { textAlign: 'right' }
                            },
                            {
                                id: 'ID',
                                label: 'Selected',
                                render: (val, row) => {
                                    return (
                                        <Radio
                                            checked={form.getField('template') === val}
                                            name={'template'}
                                            value={val}
                                            form={form}
                                            onChange={e => {
                                                this.setTemplate(e, templates);
                                            }}
                                        />
                                    );
                                },
                                styles: { textAlign: 'center' }
                            }
                        ]}
                        data={tableDataFiltered}
                    />
                </div>
            );
        if (tableData && tableData.length)
            return 'No templates match your filter. Consider typing other words or spelling variants.';
        return 'No templates are currently available.';
    };

    setTemplate(event, templates) {
        const { form } = this.props;
        const quote = templates.find((obj, i) => {
            return obj.ID === event.target.value;
        });
        const newState = {};
        newState.template = event.target.value;
        newState.quote = null;

        if (quote) {
            // clone the object, don't alter the original
            newState.quote = cloneDeep(quote);
            newState.quote.Title = quote.Description;
            newState.quote.QuoteTemplateID = quote.ID;
            delete newState.quote.ID;
            delete newState.quote.QuoteTemplateItems;
            delete newState.quote.Description;
            delete newState.quote.Created;

            newState.quote.QuoteItems = quote.QuoteTemplateItems.map(e => {
                const { Product, Variation } = e;
                const quoteItem = {
                    ID: undefined,
                    Qty: e.Optional ? 0 : 1,
                    Complimentary: e.Complimentary,
                    Optional: e.Optional,
                    Prepaid: false,
                    Product,
                    Variation
                };
                const source = lookupItemSource({ Product, Variation });
                quoteItem.Price = !!e.Complimentary ? 0 : 1 * source.Cost;
                return quoteItem;
            }).filter(e => (!!e.Product && Number(e.Product.ID) > 0) || (!!e.Variation && Number(e.Variation.ID) > 0));
        }

        form.setField(newState);
    }
}

const queryQuoteTemplates = gql`
    query($limit: Int, $NotCurrent: Boolean) {
        readQuoteTemplates(limit: $limit, NotCurrent: $NotCurrent) {
            edges {
                node {
                    ID
                    LegacyKey
                    Created
                    Description
                    QuoteType
                    QuoteTemplateItems {
                        edges {
                            node {
                                ID
                                Qty
                                Complimentary
                                Optional
                                Product {
                                    ID
                                    GST
                                    BasePrice
                                    Title
                                    LegacyKey
                                    AllowPurchase
                                    InternalItemID
                                    PriceOverrides {
                                        edges {
                                            node {
                                                ID
                                                Join {
                                                    ID
                                                    BasePrice
                                                }
                                            }
                                        }
                                    }
                                }
                                Variation {
                                    ID
                                    ShortDescription
                                    GST
                                    InternalItemID
                                    Price
                                    PriceOverrides {
                                        edges {
                                            node {
                                                ID
                                                Join {
                                                    ID
                                                    BasePrice
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const flattenTemplates = templates => {
    for (let x = 0; x < templates.length; x++) {
        templates[x] = templates[x].node;
        flattenConnection(templates[x], 'QuoteTemplateItems');
    }
};

const styles = ({ palette, typography, breakpoints }) => ({
    root: {
        maxHeight: 'calc(100vh - 375px)',
        minHeight: '33vh',
        overflow: 'auto',
        padding: 42,
        position: 'relative',
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 175px)',
            height: '100vh',
            padding: 12
        }
    },
    dataList: {
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 30
    }
});

export default withStyles(styles)(QuotesModalChooseTab);
