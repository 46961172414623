import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BiggerMessageBar from '../BiggerMessageBar';
import LinkButton from '../form/LinkButton';
import Grid from '../form/Grid';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { niceTimeFromString } from '../../util/date';
import { isNullOrUndefined } from '../../util/objects';
import { isRelatedObjectUndefined } from '../../util/graphql';
import { OutlineButton } from '../form/PrimaryButton';
import moment from 'moment';

class EventConflictMessage extends Component {
    render() {
        const { conflicts: allConflicts, conflictName, note, classes, funeralKey } = this.props;

        if (allConflicts.length === 0) return null;
        const conflicts = allConflicts.filter(e => !funeralKey || !(e.Funeral && e.Funeral.LegacyKey === funeralKey));
        if (conflicts.length === 0) return null;
        let calLink = null;
        return (
            <Grid item xs={12}>
                <BiggerMessageBar messageType="warning">
                    <div className={classes.root}>
                        <b>
                            {conflictName} has{' '}
                            {conflicts.length === 1 ? 'a scheduling conflict:' : 'multiple scheduling conflicts'}
                        </b>
                        <ul style={{ paddingLeft: 16 }}>
                            {conflicts.map((conflict, index) => {
                                calLink = conflict.Start;
                                const start = niceTimeFromString(conflict.Start);
                                const end = niceTimeFromString(conflict.End);
                                const type = classLookup[conflict.ClassName];
                                const label = isNullOrUndefined(type)
                                    ? conflict.Title || 'Unknown class type'
                                    : type.label;
                                const pathToConflict = !isRelatedObjectUndefined(conflict.Funeral)
                                    ? `/funeral/${conflict.Funeral.LegacyKey}/${conflict.Funeral.ID}/${type.tab}`
                                    : '#';

                                const action = !isRelatedObjectUndefined(conflict.Funeral) ? (
                                    <LinkButton
                                        href={pathToConflict}
                                        disabled={pathToConflict === '#'}
                                        target={'_blank'}
                                        onClick={() => {
                                            window.open(pathToConflict, '_blank');
                                        }}
                                        className={classes.view}
                                    >
                                        <small>(Funeral {conflict.Funeral.LegacyKey})</small>
                                    </LinkButton>
                                ) : (
                                    <small>(Event #{conflict.ID})</small>
                                );

                                return (
                                    <li key={index} className={classes.conflictListItem}>
                                        {`${start} to ${end} - ${label} `}
                                        {action}
                                    </li>
                                );
                            })}
                        </ul>

                        <div className={classes.note}>
                            {note}
                            <div style={{ float: 'right' }}>
                                <OutlineButton
                                    target="_blank"
                                    onClick={() => {
                                        const win = window.open('/calendar/' + moment(calLink).format('YYYYMMDD'));
                                        win.focus();
                                    }}
                                >
                                    Open Calendar
                                </OutlineButton>
                            </div>
                        </div>
                    </div>
                </BiggerMessageBar>
            </Grid>
        );
    }
}

const classLookup = {
    'FuneralManager\\FuneralPlaceOfService': {
        label: 'Funeral Service',
        tab: 'funeral-details'
    },
    'FuneralManager\\FuneralDisposal': {
        label: 'Committal',
        tab: 'funeral-details'
    },
    'FuneralManager\\FuneralPlaceOfViewing': {
        label: 'Viewing',
        tab: 'funeral-details'
    },
    'FuneralManager\\RefreshmentsVenue': {
        label: 'Refreshments Venue',
        tab: 'optionals'
    }
};

const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > :not(:first-child)': {
            margin: '15px 0 0 0'
        }
    },
    conflictListItem: {
        width: '100%',
        margin: '6px 0'
    },
    note: {
        fontStyle: 'italic',
        fontSize: '14px',
        color: '#333333'
    }
});

// prettier-ignore
export default compose(
    withRouter,
    withStyles(styles)
)(EventConflictMessage);
