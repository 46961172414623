import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { BILL_TO_OPTIONS } from './BillingConstants';
import Grid from '../../../component/form/Grid';
import TextField from '../../../component/form/TextField';
import { InlineField, InlineHeaderRightAlignChildren } from '../../../component/form/Inline';
import RadioGroup from '../../../component/form/RadioGroup';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import AddressBookAutocomplete, { getAddressBookById } from '../../../component/form/AddressBookAutocomplete';
import FamilyMemberAutocomplete from '../../../component/form/FamilyMemberAutocomplete';
import { ADDRESS_BOOK_CATEGORY } from '../funeralConstants';
import DisplayContact from '../../../component/bookings/DisplayContact';
import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import Label from '../../../component/form/Label';
import { joinDefined, stringIsNullOrEmpty } from '../../../util/strings';
import { isNullOrUndefined } from '../../../util/objects';
import ValidationPlaceholder from '../../../component/form/ValidationPlaceholder';

export const BILL_TO_MAPPING = {
    billingSource: 'billingSource',
    relationToDeceased: 'relationToDeceased',
    fullName: 'fullName',
    givenName: 'givenName',
    middleName: 'middleName',
    surname: 'surname',
    phone: 'phone',
    mobile: 'mobile',
    email: 'email',
    fax: 'fax',
    line1: 'line1',
    line2: 'line2',
    city: 'city',
    state: 'state',
    country: 'country',
    code: 'code',
    billingId: 'billingId'
};

class BillTo extends Component {
    state = {
        hasBillingTarget: false,
        billingContact: null,
        relationToDeceased: null,
        addressMapping: null
    };

    static getDerivedStateFromProps({ mapping }, { addressMapping }) {
        if (!isNullOrUndefined(addressMapping)) return null;
        return {
            addressMapping: {
                line1: mapping.line1,
                line2: mapping.line2,
                city: mapping.city,
                state: mapping.state,
                country: mapping.country,
                code: mapping.code
            }
        };
    }

    componentDidMount() {
        const { billingContact } = this.state;
        const { mapping, billingTargetForm } = this.props;
        const billingSource = billingTargetForm.getField(mapping.billingSource);
        const billingId = billingTargetForm.getField(mapping.billingId);

        if (billingSource === 'AddressBook' && isNullOrUndefined(billingContact) && !isNullOrUndefined(billingId)) {
            getAddressBookById(billingId).then(x => {
                this.setState({ billingContact: x });
                billingTargetForm.setField({ [mapping.billingId]: x.ID });
            });
        }
    }

    render() {
        const { disabled, billingTargetForm, mapping } = this.props;
        const billingSource = billingTargetForm.getField(mapping.billingSource);

        return (
            <Fragment>
                <InlineField>
                    <RadioGroup
                        disabled={disabled}
                        options={BILL_TO_OPTIONS}
                        label="Bill To"
                        value={billingSource}
                        onChange={e => this.onBillingSourceChanged(e.target.value)}
                    />
                </InlineField>
                {stringIsNullOrEmpty(billingSource) && this.renderEmpty()}
                {billingSource === 'Applicant' && this.renderApplicantDetails()}
                {billingSource === 'AddressBook' && this.renderAddressBook()}
                {billingSource === 'FamilyMember' && this.renderFamilyMember()}
                {billingSource === 'Other' && this.renderOtherBillingTarget()}
            </Fragment>
        );
    }

    renderEmpty() {
        return (
            <Grid item xs={12}>
                <Typography variant="caption">Please select a billing source...</Typography>
            </Grid>
        );
    }

    renderApplicantDetails() {
        return this.renderBillingTarget(false, false);
    }

    renderAddressBook() {
        const { billingContact } = this.state;
        const { billingTargetForm, classes } = this.props;

        return (
            <Fragment>
                <ValidationPlaceholder name={'BillTo.BillingId'} form={billingTargetForm}>
                    <InlineField>
                        <AddressBookAutocomplete
                            clearOnSelect={true}
                            placeholder="Search for a Billing Entity"
                            categories={[ADDRESS_BOOK_CATEGORY.billing]}
                            onSelect={(_, result) => this.onSelectBillerFromAddressBook(result)}
                            value={billingContact}
                        />
                    </InlineField>
                </ValidationPlaceholder>

                {billingContact && (
                    <div className={classes.listItem}>
                        <span style={{ float: 'right' }}>
                            <IconButton
                                title={'Remove'}
                                onClick={() => {
                                    this.onBillingSourceChanged('AddressBook');
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                        <p>
                            <Label text={'Selected Biller'} />
                        </p>
                        <DisplayContact contactID={billingContact.ID} />
                    </div>
                )}
            </Fragment>
        );
    }

    renderFamilyMember() {
        const { billingSourceForm, billingTargetForm, mapping } = this.props;
        const funeralId = billingSourceForm.getField('ID');
        const familyMember =
            joinDefined(
                [
                    billingTargetForm.getField(mapping.givenName),
                    billingTargetForm.getField(mapping.middleName),
                    billingTargetForm.getField(mapping.surname)
                ],
                ' '
            ) +
            ' (' +
            billingTargetForm.getField(mapping.relationToDeceased) +
            ')';

        return (
            <Fragment>
                <InlineField>
                    <Grid item xs={12}>
                        <FamilyMemberAutocomplete
                            name="ApplicantFamilyMember_Billing"
                            onChange={this.onChangeBillingTargetFamilyMember}
                            funeralId={funeralId}
                            value={familyMember}
                        />
                    </Grid>
                </InlineField>
                {this.renderBillingTarget(true, true)}
            </Fragment>
        );
    }

    renderOtherBillingTarget() {
        return this.renderBillingTarget(true, true);
    }

    renderBillingTarget(canEditNames, canEditContactDetails) {
        const { billingTargetForm, classes, mapping, disabled } = this.props;
        const { addressMapping } = this.state;
        const fullName = billingTargetForm.getField(mapping.fullName);

        return (
            <Fragment>
                {!stringIsNullOrEmpty(fullName) ? (
                    <Grid item xs={12} md={12}>
                        <TextField
                            titleCase
                            name={mapping.fullName}
                            label="Name"
                            form={billingTargetForm}
                            disabled={!canEditNames || disabled}
                        />
                    </Grid>
                ) : (
                    <Fragment>
                        <Grid item xs={12} md={4}>
                            <TextField
                                titleCase
                                name={mapping.givenName}
                                label="First name"
                                form={billingTargetForm}
                                disabled={!canEditNames || disabled}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                titleCase
                                name={mapping.middleName}
                                label="Middle Name(s) (optional)"
                                form={billingTargetForm}
                                disabled={!canEditNames || disabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                titleCase
                                name={mapping.surname}
                                label="Surname"
                                form={billingTargetForm}
                                disabled={!canEditNames || disabled}
                            />
                        </Grid>
                    </Fragment>
                )}

                <Grid item xs={12} md={6}>
                    <TextField
                        name={mapping.phone}
                        className={classes.alignWithLabel}
                        label="Phone (optional)"
                        form={billingTargetForm}
                        disabled={!canEditContactDetails || disabled}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        className={classes.alignWithLabel}
                        name={mapping.mobile}
                        label="Mobile (optional)"
                        form={billingTargetForm}
                        disabled={!canEditContactDetails || disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.alignWithLabel}
                        name={mapping.email}
                        label="Email (optional)"
                        type="email"
                        form={billingTargetForm}
                        disabled={!canEditContactDetails || disabled}
                    />
                </Grid>

                <InlineHeaderRightAlignChildren
                    header="Billing Address"
                    className={classes.col2}
                    disabled={!canEditContactDetails || disabled}
                >
                    <div>&nbsp;</div>
                </InlineHeaderRightAlignChildren>
                <Grid item xs={12} className={classes.col2}>
                    <AddressAutocomplete
                        id="BillingAddress"
                        placeholder="Enter an address..."
                        componentFields={addressMapping}
                        form={billingTargetForm}
                        disabled={!canEditContactDetails || disabled}
                        allowCustomAddress={!!canEditContactDetails}
                        required
                    />
                </Grid>
            </Fragment>
        );
    }

    onBillingSourceChanged(billingSource) {
        const { mapping, billingSourceForm, billingTargetForm } = this.props;
        let hasBillingTarget = false;

        if (billingSource === 'Applicant') {
            const infFullNamePiece = billingSourceForm.getField('Informant.InformantsName') || '';
            let infSurname = billingSourceForm.getField('Informant.Surname');
            let infFirstName = billingSourceForm.getField('Informant.FirstName');
            let infMiddleName = billingSourceForm.getField('Informant.MiddleName');
            // need to reconstruct informant name from legacy data...?
            if (!!infFullNamePiece && !infSurname && !infFirstName && !infMiddleName) {
                const bits = ('' + infFullNamePiece).split(' ');
                if (!infSurname) infSurname = bits.pop();
                else bits.pop();
                if (!infFirstName) infFirstName = bits.shift();
                else bits.shift();
                if (!infMiddleName) infMiddleName = bits.join(' ');
            }

            const infFullName = infFullNamePiece || joinDefined([infFirstName, infMiddleName, infSurname], ' ');

            let state = {
                [mapping.givenName]: infFirstName,
                [mapping.middleName]: infMiddleName,
                [mapping.surname]: infSurname,
                [mapping.fullName]: infFullName,
                [mapping.phone]: billingSourceForm.getField('Informant.Phone'),
                [mapping.mobile]: billingSourceForm.getField('Informant.Mobile'),
                [mapping.email]: billingSourceForm.getField('Informant.Email'),
                [mapping.relationToDeceased]: billingSourceForm.getField('Informant.RelationshipToDeceased'),
                [mapping.billingSource]: billingSource
            };

            const useResidential = billingSourceForm.getField('Informant.PostalSameAsResidential');
            if (useResidential) {
                state[mapping.line1] = billingSourceForm.getField('Informant.ResidentialAddress1');
                state[mapping.line2] = billingSourceForm.getField('Informant.ResidentialAddress2');
                state[mapping.city] = billingSourceForm.getField('Informant.SuburbCityAust');
                state[mapping.state] = billingSourceForm.getField('Informant.ResidentialState');
                state[mapping.code] = billingSourceForm.getField('Informant.ResidentialPostcode');
                state[mapping.country] = null;
            } else {
                state[mapping.line1] = billingSourceForm.getField('Informant.PostalAddressLine1');
                state[mapping.line2] = billingSourceForm.getField('Informant.PostalAddressLine2');
                state[mapping.city] = billingSourceForm.getField('Informant.PostalSuburb');
                state[mapping.state] = billingSourceForm.getField('Informant.PostalState');
                state[mapping.code] = billingSourceForm.getField('Informant.PostalPostcode');
                state[mapping.country] = billingSourceForm.getField('Informant.PostalCountry');
            }

            billingTargetForm.setField(state);
            hasBillingTarget = true;
        } else if (billingSource === 'AddressBook') {
            this.clearBillingTarget(billingSource);
        } else if (billingSource === 'FamilyMember') {
            this.clearBillingTarget(billingSource);
        } else if (billingSource === 'Other') {
            this.clearBillingTarget(billingSource);
            hasBillingTarget = true;
        } else {
            this.clearBillingTarget();
            hasBillingTarget = false;
        }

        this.setState({ billingContact: null, hasBillingTarget });
    }

    clearBillingTarget(billingSource = '') {
        const { mapping, billingTargetForm } = this.props;
        let state = {
            [mapping.relationToDeceased]: null,
            [mapping.givenName]: null,
            [mapping.middleName]: null,
            [mapping.surname]: null,
            [mapping.fullName]: null,
            [mapping.phone]: null,
            [mapping.mobile]: null,
            [mapping.email]: null,
            [mapping.line1]: null,
            [mapping.line2]: null,
            [mapping.city]: null,
            [mapping.state]: null,
            [mapping.code]: null,
            [mapping.country]: null,
            [mapping.billingSource]: billingSource
        };

        if (!isNullOrUndefined(mapping.billingId)) state[mapping.billingId] = null;

        billingTargetForm.setField(state);
    }

    onSelectBillerFromAddressBook(contact) {
        const { billingTargetForm, mapping } = this.props;
        let state = {
            [mapping.relationToDeceased]: null,
            [mapping.fullName]: contact.Name,
            [mapping.givenName]: '',
            [mapping.middleName]: '',
            [mapping.surname]: '',
            [mapping.phone]: contact.Phone,
            [mapping.mobile]: contact.Mobile,
            [mapping.email]: contact.Email,
            [mapping.line1]: contact.AddressLine1,
            [mapping.line2]: contact.AddressLine2,
            [mapping.city]: contact.Suburb,
            [mapping.state]: contact.State,
            [mapping.code]: contact.Postcode,
            [mapping.country]: null,
            [mapping.billingSource]: 'AddressBook'
        };

        if (!isNullOrUndefined(mapping.billingId)) state[mapping.billingId] = contact.ID;
        billingTargetForm.setField(state);
        this.setState({ billingContact: contact, hasBillingTarget: true });
    }

    onChangeBillingTargetFamilyMember = item => {
        if (item && item.metadata) {
            const { source, relationship } = item.metadata;
            const { billingTargetForm, mapping } = this.props;
            let state = {
                [mapping.relationToDeceased]: relationship,
                [mapping.givenName]: source.FirstName,
                [mapping.middleName]: source.MiddleName,
                [mapping.surname]: source.Surname,
                [mapping.phone]: null,
                [mapping.mobile]: null,
                [mapping.email]: null,
                [mapping.line1]: null,
                [mapping.line2]: null,
                [mapping.city]: null,
                [mapping.state]: null,
                [mapping.code]: null,
                [mapping.country]: null,
                [mapping.billingSource]: 'FamilyMember'
            };

            if (!isNullOrUndefined(mapping.billingId)) state[mapping.billingId] = null;
            billingTargetForm.setField(state);
            this.setState({ hasBillingTarget: true });
        } else {
            this.clearBillingTarget('FamilyMember');
            this.setState({ hasBillingTarget: false });
        }
    };
}

const styles = {
    alignWithLabel: {
        // marginTop: 10
    },
    listItem: {
        width: '100%',
        padding: '0 8px 8px 8px',
        margin: '6px 0 0 !important',
        borderRadius: 5,
        // backgroundColor: 'rgba(0,0,0,0.035)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        },
        '& > p': {
            margin: '0.65rem 0'
        }
    }
};

export default withStyles(styles)(BillTo);
