import gql from 'graphql-tag';

//for some reason, it doesn't like nesting this inside the Invoices collection. nasty
export const Invoice = gql`
    fragment InvoiceObject on Invoice {
        ID
        Created
        LegacyID
        LegacyKey
        XeroID
        XeroStatus
        XeroReference
        Printed
        InvoiceDate
        InvoicePaid
        GstAmount
        Total
        InvoiceDueDate
        InvoiceTypeCode
        OriginatingOffice
        InvoiceStatus
        InvoiceSent
        InvoiceSentDatetime
        IsPurchase # true for bills
        Funeral {
            ID
        }
        InvoiceItems {
            ID
            Quantity
            Price
            Prepaid
            Title
            Product {
                ID
                InternalItemID
                Title
                GST
                BasePrice
                PriceOverrides {
                    edges {
                        node {
                            ID
                            Join {
                                ID
                                BasePrice
                            }
                        }
                    }
                }
            }
            Variation {
                ID
                InternalItemID
                ShortDescription
                GST
                Price
                Product {
                    ID
                    Title
                }
                PriceOverrides {
                    edges {
                        node {
                            ID
                            Join {
                                ID
                                BasePrice
                            }
                        }
                    }
                }
            }
        }
        SalesRegisterTransactions {
            ID
            LegacyKey
            TransType
            Nett
            Gst
            TransDate
            BankTransNo
            XeroReference
        }
        Customer {
            ID
            CustomerName
            FirstName #only new records
            MiddleName #only new records
            Surname #only new records
            Contact #this is used for companies.. a contact at a company
            AddressLine1
            AddressLine2
            Suburb
            Postcode
            State
            Country
            Phone
            Mobile
            BusPhone #only old records...only show if Phone is empty
            RelationToDeceased
            Email
            BillingId
            BillingSource
        }
        CreatedBy {
            ID
            FirstName
            Surname
            Email
        }
    }
`;

export default gql`
    fragment Invoice on Funeral {
        Invoices {
            ID
            Created
            LegacyID
            LegacyKey
            XeroID
            XeroStatus
            XeroReference
            Printed
            InvoiceDate
            InvoicePaid
            GstAmount
            Total
            InvoiceDueDate
            InvoiceTypeCode
            OriginatingOffice
            InvoiceStatus
            InvoiceSent
            InvoiceSentDatetime
            IsPurchase
            Funeral {
                ID
            }
            InvoiceItems {
                ID
                Quantity
                Price
                Prepaid
                Title
                Product {
                    ID
                    InternalItemID
                    Title
                    GST
                    BasePrice
                }
                Variation {
                    ID
                    InternalItemID
                    ShortDescription
                    GST
                    Price
                    Product {
                        ID
                        Title
                    }
                }
            }
            SalesRegisterTransactions {
                ID
                LegacyKey
                TransType
                Nett
                Gst
                TransDate
                BankTransNo
            }
            Customer {
                ID
                CustomerName
                FirstName #only new records
                MiddleName #only new records
                Surname #only new records
                Contact #this is used for companies.. a contact at a company
                AddressLine1
                AddressLine2
                Suburb
                Postcode
                State
                Country
                Phone
                Mobile
                BusPhone #only old records...only show if Phone is empty
                RelationToDeceased
                Email
                BillingId
                BillingSource
            }
            CreatedBy {
                ID
                FirstName
                Surname
                Email
            }
        }
    }
`;
