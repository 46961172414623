import React from 'react';
import { withStyles } from '@material-ui/core';
import { FUNERAL_HOME } from '../util/funerals';
import { CapsuleLegend } from './form/Legend';
import { theme } from '../Theme';

const BrandedTableKey = () => {
    return Object.keys(FUNERAL_HOME).map(
        (x, i) =>
            x !== 'PREARRANGEMENTS' && (
                <CapsuleLegend
                    key={i}
                    text={FUNERAL_HOME[x].label}
                    foreground={theme.palette.contentForeground[FUNERAL_HOME[x].letterCode]}
                    background={theme.palette.contentBackground[FUNERAL_HOME[x].letterCode]}
                />
            )
    );
};

const styles = theme => ({});
export default withStyles(styles)(BrandedTableKey);
