import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from './TextField';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';
import cx from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '../icon/CloseIcon';
import ValidationPlaceholder from './ValidationPlaceholder';

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        focused: false,
        searchTerm: null,
        searchField: undefined,
        options: null,
        limit: undefined,
        value: null
    };

    componentWillMount() {
        const { limit, searchField, options } = this.props;
        this.setState({
            searchField: searchField || 'label',
            searchTerm: '',
            limit: limit || 10,
            options: options && options.then ? null : options
        });
        this.updateSearchTermAndOptions(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateSearchTermAndOptions(nextProps);
    }

    updateSearchTermAndOptions(props) {
        const { name, form, value } = props;
        const { searchField } = this.state;
        let options = props.options;

        if (!options) options = [];

        //set the search term
        const newValue = value || (form ? form.getField(name) : null) || '';
        const newSearchTerm = (newValue ? newValue[searchField] || newValue : null) || '';
        const oldSearchTerm = this.state.searchTerm;

        if (oldSearchTerm !== newSearchTerm) this.setState({ searchTerm: newSearchTerm });

        //set the options
        if (options.then) {
            const that = this;
            options.then(result => that.setState({ options: result }));
        } else {
            this.setState({ options });
        }
    }

    onSearchFieldFocus = e => {
        if (!!this.inputRef) this.inputRef.focus();
        if (this.state.focused) return;

        this.setState({ focused: true });
    };

    onSearchFieldBlur = e => {
        const { searchTerm, focused } = this.state;
        if (!focused) return;

        const { name, form } = this.props;
        if (form && form.getField(name) !== searchTerm) {
            this.selectItem(this.getMatchingItem());
        } else {
            this.setState({ focused: false });
        }
    };

    onSearchFieldRef = input => {
        this.inputRef = input;
    };

    onSearchFieldKeyPress = (e, highlightedIndex) => {
        if (e.key === 'Enter') {
            if (highlightedIndex === null) {
                const match = this.getMatchingItem();
                if (match) this.selectItem(match);
            } else {
                this.selectItem(this.getNearestItem(highlightedIndex));
            }
        } else if (e.key === 'Tab') {
            const nearest = this.getNearestItem(highlightedIndex);
            if (nearest) {
                const { searchField } = this.state;
                this.selectItem(nearest);
                this.setState({ searchTerm: nearest[searchField] });
            } else {
                //block navigation
                e.preventDefault();
            }
        }
    };

    onSearchFieldChange = e => {
        this.onSearchFieldFocus();
        this.setState({ searchTerm: e.target.value });
    };

    selectItem = item => {
        const { searchField } = this.state;
        const key = item ? item['key'] || item['value'] || item : null;
        const label = item ? item[searchField] : '';
        const { onChange, name, form } = this.props;

        this.setState({ searchTerm: label, focused: false });

        if (form) form.setField({ [name]: key });

        if (onChange) onChange(item || {});
    };

    getMatchingItem = () => {
        const { options, searchTerm, searchField } = this.state;
        const upperSearchTerm = searchTerm.toUpperCase();
        return options.find(x => x[searchField].toUpperCase() === upperSearchTerm);
    };

    getNearestItem = highlightedIndex => {
        const { options, searchTerm } = this.state;

        if (!searchTerm || searchTerm === '') return this.getHighlightedItem(highlightedIndex);

        const upperSearchTerm = searchTerm.toUpperCase();
        return options.find(x => x.label.toUpperCase().includes(upperSearchTerm));
    };

    getHighlightedItem = index => {
        const { options } = this.state;
        return options[index];
    };

    render() {
        const props = {
            ...this.props,
            onItemSelected: this.onItemSelected,
            onSearchFieldChange: this.onSearchFieldChange,
            onSearchFieldKeyPress: this.onSearchFieldKeyPress,
            onSearchFieldFocus: this.onSearchFieldFocus,
            onSearchFieldBlur: this.onSearchFieldBlur,
            onSearchFieldRef: this.onSearchFieldRef
        };

        return <Downshift>{downshiftData => this.renderContainer(props, downshiftData)}</Downshift>;
    }

    renderContainer = (props, data) => {
        const { searchTerm, focused, options, searchField, limit } = this.state;
        const { classes, className } = props;
        const { highlightedIndex } = data;
        const selectItem = this.selectItem;

        return (
            <div className={cx(classes.root, className)}>
                <Fragment>
                    <SearchField {...props} data={data} searchTerm={searchTerm} highlightedIndex={highlightedIndex} />
                    {focused && (
                        <Paper className={classes.itemPaper} square>
                            {options
                                ? options
                                      .filter(
                                          item =>
                                              !searchTerm ||
                                              item[searchField].toLowerCase().includes(searchTerm.toLowerCase())
                                      )
                                      .filter((item, index) => index < limit)
                                      .map((item, index) => (
                                          <SearchResult
                                              key={index}
                                              {...props}
                                              data={data}
                                              selectItem={selectItem}
                                              item={item}
                                              index={index}
                                              searchField={searchField}
                                          />
                                      ))
                                : null}
                        </Paper>
                    )}
                </Fragment>
            </div>
        );
    };
}

const SearchField = ({
    open,
    label,
    placeholder,
    onSearchFieldChange,
    onSearchFieldKeyPress,
    onSearchFieldFocus,
    onSearchFieldBlur,
    onSearchFieldRef,
    data,
    searchTerm,
    highlightedIndex,
    disabled,
    form,
    name
}) => {
    // This is to shut up linting errors because it's case insensitive
    const otherProps = {
        InputProps: {
            tabIndex: open ? 0 : -1,
            endAdornment: searchTerm && !disabled && (
                <InputAdornment position="end">
                    <span title="Remove">
                        <CloseIcon
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'grey',
                                padding: 1,
                                fontSize: 12,
                                borderRadius: 36,
                                color: 'white'
                            }}
                            onClick={() => onSearchFieldChange({ target: { value: '' } })}
                        />
                    </span>
                </InputAdornment>
            ),
            disableUnderline: true,
            ...data.getInputProps({
                placeholder,
                onFocus: onSearchFieldFocus,
                onBlur: onSearchFieldBlur,
                onChange: onSearchFieldChange,
                onKeyDown: e => onSearchFieldKeyPress(e, highlightedIndex),
                onClick: e => onSearchFieldFocus(),
                value: searchTerm
            })
        },
        inputRef: onSearchFieldRef,
        disabled: disabled
    };

    return (
        <ValidationPlaceholder form={form} name={name}>
            <TextField name="searchTerm" label={label} fullWidth {...otherProps} />
        </ValidationPlaceholder>
    );
};

const SearchResult = ({ classes, data, selectItem, item, index, searchField }) => {
    const key = item.key || item.value;
    const label = item[searchField];
    const itemProps = data.getItemProps({ item: key });
    return (
        <MenuItem
            {...itemProps}
            className={cx(classes.item)}
            selected={data.highlightedIndex === index}
            onClick={e => selectItem(item)}
        >
            <span className={classes.itemLabel}>{label}</span>
        </MenuItem>
    );
};

const styles = ({ spacing, palette, transitions, typography }) => ({
    root: {
        position: 'relative',
        flex: '1 1 auto'
    },

    searchField: {
        backgroundColor: palette.common.white,
        borderRadius: 3,
        border: `1px solid ${palette.grey.A100}`,
        padding: '2px 8px',
        transition: transitions.create(['border-color', 'box-shadow']),
        'label + &': {
            marginTop: spacing.unit * 2
            // backgroundColor: 'transparent'
        }
    },

    searchFieldFocused: {
        borderColor: palette.primary.light,
        boxShadow: `0 0 0 0.1rem rgba(65, 59, 190,.25)` // active color in RGB
    },

    paper: {
        padding: 8
    },
    paperElevated: {
        border: `1px solid ${palette.grey.A100}`
    },
    iconContainer: {
        alignSelf: 'center'
    },
    icon: {
        fontSize: 16,
        color: palette.action.active
    },
    itemPaper: {
        position: 'absolute',
        zIndex: 100,
        left: 0,
        right: 0,
        top: 'calc(100% - 2px)',
        border: `1px solid ${palette.grey.A100}`,
        padding: '8px 0',
        maxHeight: '50vh',
        overflow: 'auto'
    },
    item: {
        padding: '6px 16px',
        display: 'flex'
    },
    itemLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: typography.body1.fontSize
    }
});

export default withStyles(styles)(AutoComplete);
