import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import Typography from '@material-ui/core/Typography/Typography';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';

class Music extends Component {
    render() {
        const loading = false;
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span>Music</span>
                    </InlineFieldRightAlignChildren>
                </Typography>

                <br />

                {!!loading ? (
                    <span className={classes.loading}>
                        <CircularProgress />
                    </span>
                ) : (
                    this.renderMusic()
                )}
            </Fragment>
        );
    }

    renderMusic() {
        const { classes } = this.props;
        return (
            <Paper className={classes.paper} elevation={0}>
                <div>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            Music source is not configured.
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        );
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    }
});

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(Music);
