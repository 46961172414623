import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import MetricsCustomerService from './MetricsCustomerService';
import '../../component/metrics/css/metrics.scss';
import NotFound from '../NotFound';
import MetricsArranger from './MetricsArranger';
import Inline, { inlineAlignment } from '../workQueue2/extras/Inline';
import Select from '../../component/form/Select';
import moment from 'moment';
import { getUser } from '../../util/sessions';
import GatedComponent from '../../component/GatedComponent';
import StaffAutoComplete from '../../component/form/StaffAutoComplete';
import { dateTimeToString, dateToString } from '../../util/date';
import TextField from '../../component/form/TextField';
import { joinDefined } from '../../util/strings';

class Metrics extends Component {
    state = {};

    render() {
        const { classes, history } = this.props;
        const currentUser = getUser();
        const requestedMetrics = new URLSearchParams(window.location.search);

        const period = (requestedMetrics && requestedMetrics.get('period')) || 'month';
        const urlStart = requestedMetrics && requestedMetrics.get('start');
        const myStart = (urlStart && moment(urlStart).isValid() ? moment(urlStart) : moment()).startOf('month');

        let periodStart = moment(myStart).startOf(period);
        if (period === 'year') {
            if (moment(myStart).quarter() > 2) {
                periodStart = moment(periodStart).add(6, 'months');
            } else {
                periodStart = moment(periodStart).subtract(6, 'months');
            }
        }
        let periodUntil = moment(periodStart)
            .add(1, period)
            .subtract(1, 'second');

        const periodValues = () => {
            const dateArray = [];
            const curr = moment('2019-07-01'); // history begins here!
            const now = moment();
            let format = 'MMM Y';
            if (period === 'quarter') {
                format = 'Qo [Quarter] Y';
            }
            while (curr < now) {
                dateArray.push({
                    label:
                        period === 'year'
                            ? moment(curr).format('Y') +
                              ' - ' +
                              moment(curr)
                                  .add(1, period)
                                  .format('Y')
                            : moment(curr).format(format),
                    value: moment(curr).format('Y-MM-DD')
                });
                curr.add(1, period);
            }
            return dateArray;
        };

        const periodValue = () => {
            const expectedValues = periodValues();
            const selected = dateToString(myStart);
            const good = expectedValues.find(e => e.value === selected);
            if (good) return good; // user picked a proper date!
            const goodEnough = expectedValues.find(e => e.value > selected);
            if (goodEnough) return goodEnough; // user's date near a proper date, show nearest before.
            return expectedValues[expectedValues.length - 1]; // user picked some future date, just show latest.
        };

        const periodData = {
            team: null,
            type: period,
            label: periodValue().label,
            start: dateTimeToString(periodStart),
            until: dateTimeToString(periodUntil),
            userID: currentUser.ID
        };

        return (
            <GatedComponent isEnabledCode={'FM_ACCESS_Metrics'}>
                {(isEnabled, data) => {
                    if (!isEnabled) return <NotFound />;
                    const viewOptions = [];
                    const { readCurrentUser } = data;
                    const { AllPermissions } = readCurrentUser || null;
                    const { Groups } = readCurrentUser || [];
                    if (AllPermissions.indexOf('ADMIN') < 0) {
                        // not an admin, check which group can see what metric
                        if (Groups.find(g => MetricsAccessGroup.FuneralArranger.indexOf(g.Code) > -1))
                            viewOptions.push({ label: 'Funeral Arranger Metrics', value: 'arrangers' });
                        if (Groups.find(g => MetricsAccessGroup.CustomerService.indexOf(g.Code) > -1))
                            viewOptions.push({ label: 'Customer Service Metrics', value: 'service' });
                        if (Groups.find(g => MetricsAccessGroup.StaffManager.indexOf(g.Code) > -1))
                            periodData.userID = (requestedMetrics && requestedMetrics.get('user')) || currentUser.ID;
                    } else {
                        // is an admin, see all metrics
                        periodData.userID = (requestedMetrics && requestedMetrics.get('user')) || currentUser.ID;
                        viewOptions.push({ label: 'Funeral Arranger Metrics', value: 'arrangers' });
                        viewOptions.push({ label: 'Customer Service Metrics', value: 'service' });
                    }

                    const viewSelected = (requestedMetrics && requestedMetrics.get('view')) || 'service';
                    const view =
                        (viewOptions.length && (viewOptions.length === 1 ? viewOptions[0].value : viewSelected)) ||
                        null;
                    periodData.team = view;

                    if (!(view === 'service' || view === 'arrangers')) return <NotFound />;

                    return (
                        <div className="metrics-page">
                            <Inline className="metrics" alignment={inlineAlignment.rightAlignSiblings} center>
                                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                                    <span>Performance Metrics</span>
                                    <span className={classes.header}>
                                        {(view === 'service' && 'Customer Service') ||
                                            (view === 'arrangers' && 'Funeral Arrangers') ||
                                            ''}
                                    </span>
                                </Typography>
                                {!!view && (
                                    <Inline>
                                        {((AllPermissions.indexOf('ADMIN') > -1 ||
                                            Groups.find(g => MetricsAccessGroup.StaffManager.indexOf(g.Code) > -1)) && (
                                            <span style={{ minWidth: 200, width: '100%' }}>
                                                <StaffAutoComplete
                                                    category={
                                                        view === 'service'
                                                            ? MetricsAccessGroup.CustomerService
                                                            : MetricsAccessGroup.FuneralArranger
                                                    }
                                                    preloadAll={true}
                                                    value={periodData.userID}
                                                    label="Person"
                                                    selectProps={{ multiple: false }}
                                                    clearOnSelect={false}
                                                    onSelect={(_, result) => {
                                                        requestedMetrics.set('user', result.ID);
                                                        history.push('?' + requestedMetrics.toString());
                                                    }}
                                                />
                                            </span>
                                        )) || (
                                            <span style={{ minWidth: 200, width: '100%' }}>
                                                <TextField
                                                    label="Person"
                                                    readOnly
                                                    value={
                                                        joinDefined(
                                                            [currentUser.FirstName, currentUser.Surname],
                                                            ' '
                                                        ) || currentUser.Email
                                                    }
                                                />
                                            </span>
                                        )}
                                        {viewOptions.length > 1 && (
                                            <Select
                                                label="View"
                                                allowNone={false}
                                                options={viewOptions}
                                                value={view}
                                                onChange={e => {
                                                    requestedMetrics.set('view', e.target.value);
                                                    history.push('?' + requestedMetrics.toString());
                                                }}
                                            />
                                        )}
                                        <Select
                                            label="Period"
                                            allowNone={false}
                                            options={[
                                                { label: 'Financial Year', value: 'year' },
                                                { label: 'Quarterly', value: 'quarter' },
                                                { label: 'Monthly', value: 'month' }
                                            ]}
                                            value={period}
                                            onChange={e => {
                                                requestedMetrics.set('period', e.target.value);
                                                requestedMetrics.set('start', moment(myStart).format('Y-MM-DD'));
                                                history.push('?' + requestedMetrics.toString());
                                            }}
                                        />

                                        <Select
                                            label={period}
                                            allowNone={false}
                                            options={periodValues()}
                                            value={periodValue().value}
                                            onChange={e => {
                                                requestedMetrics.set('start', e.target.value);
                                                history.push('?' + requestedMetrics.toString());
                                            }}
                                        />
                                    </Inline>
                                )}
                            </Inline>

                            {(view === 'service' && <MetricsCustomerService period={periodData} />) ||
                                (view === 'arrangers' && <MetricsArranger period={periodData} />) || <NotFound />}
                        </div>
                    );
                }}
            </GatedComponent>
        );
    }
}

const MetricsAccessGroup = {
    CustomerService: [
        'funeral-directors',
        'operation-managers',
        'admin-staff-managers',
        'customer-service-manager',
        'admin-staff'
    ],
    FuneralArranger: ['funeral-directors', 'operation-managers', 'funeral-arrangers'],
    StaffManager: ['operation-managers', 'admin-staff-managers', 'customer-service-manager']
};

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    },
    tableData: {
        marginTop: '1rem'
    }
});

export const CONVERSION_TYPE_COLOURS = {
    colourPreneed: '#d3c293',
    colourPhone: '#93ac9c',
    colourAtneed: '#93a3b7'
};

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(Metrics);
