import gql from 'graphql-tag';
import EventConflictPartial from './EventConflictPartial';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment PlaceOfService on Funeral {
        PlaceOfService {
            ID
            Comment
            Requested
            Responded
            Confirmed
            Date
            Time
            Type
            Private
            Duration
            Location {
                ID
                Name
                State
                Suburb
                Postcode
                AddressLine1
                AddressLine2
                Mobile
                Phone
                Fax
                Email
                Contact
                Notes
                AddressBookCategory
                PlaceOfServiceCategory
            }
            StaffAllocations {
                ID
                Title
                Allocation
                Start
                End
                Type
                Location
                Comments
                Recurring
                Member {
                    ID
                    FirstName
                    Surname
                    Email
                }
            }
            Bookings {
                ${BookingsFragmentInclude}
            }
            EventConflicts {
                ${EventConflictPartial}
            }
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementPlaceOfService on Prearrangement {
        PlaceOfService {
            ID
            Comment
            Requested
            Responded
            Confirmed
            Date
            Time
            Type
            Private
            Duration
            Location {
                ID
                Name
                State
                Suburb
                Postcode
                AddressLine1
                AddressLine2
                Mobile
                Phone
                Fax
                Email
                Contact
                Notes
                AddressBookCategory
                PlaceOfServiceCategory
            }
        }
    }
`;
