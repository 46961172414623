import React, { Component, Fragment } from 'react';
import Select from './form/Select';
import gql from 'graphql-tag';
import {
    CONTACT_AUS_STATES,
    CONTACT_CATEGORY_OPTIONS,
    CONTACT_CLERGY_OPTIONS
} from '../page/contacts/contactConstants';
import { withStyles } from '@material-ui/core/styles/index';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import TabbedModal from './form/TabbedModal';
import ModalStepTitle from './form/ModalStepTitle';
import PrimaryButton, { OutlineButton } from './form/PrimaryButton';
import Icon from '@material-ui/core/Icon/Icon';
import Label from './form/Label';
import CloseIcon from './icon/CloseIcon';
import Grid from './form/Grid';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import ColumnLayout from './ColumnLayout';
import Typography from '@material-ui/core/Typography/Typography';
import AddressAutocomplete from './form/AddressAutocomplete';
import Query from 'react-apollo/Query';
import DataForm from './form/DataForm';
import TextField from './form/TextField';
import Checkbox from './form/Checkbox';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import { validationHelper } from '../util/validation';

const TABS = ['Identity', 'Correspondence'];

class EditContactModal extends Component {
    static defaultProps = {
        // title: 'Enter contact details',
        onClose: () => {}
    };

    state = {
        tabIndex: 0
    };

    validation = {
        identity: {
            onValidate: {
                Name: (newValue, persistedValue, hasValue, getField) => {
                    const isEmpty = !newValue && !persistedValue;
                    const category = getField('AddressBookCategory');
                    if (category === 'DOCTOR' && !!isEmpty) return validationHelper.suggested();
                    if (!isEmpty) return validationHelper.ok();
                    const { tabIndex } = this.state;
                    if (tabIndex !== 0) this.setState({ tabIndex: 0 });
                    return validationHelper.required();
                },
                AddressBookCategory: (newValue, persistedValue, hasValue, getField) => {
                    const isEmpty = !newValue && !persistedValue;
                    if (!isEmpty) return validationHelper.ok();
                    const { tabIndex } = this.state;
                    if (tabIndex !== 0) this.setState({ tabIndex: 0 });
                    return validationHelper.required();
                }
            }
        },
        correspondence: {
            suggested: ['AddressLine1', 'State', 'Postcode', 'Contact'],
            onValidate: {
                Phone: (newValue, persistedValue, hasValue, getField) => {
                    const isEmpty = !newValue && !persistedValue;
                    const category = getField('AddressBookCategory');
                    if (category === 'DOCTOR' && !!isEmpty) return validationHelper.suggested();
                    if (!isEmpty) return validationHelper.ok();
                    return validationHelper.required();
                },
                Suburb: (newValue, persistedValue, hasValue, getField) => {
                    const isEmpty = !newValue && !persistedValue;
                    const category = getField('AddressBookCategory');
                    if (category === 'DOCTOR' && !!isEmpty) return validationHelper.suggested();
                    if (!isEmpty) return validationHelper.ok();
                    return validationHelper.required();
                },
                Email: (newValue, persistedValue, hasValue, getField) => {
                    const isEmpty = !newValue && !persistedValue;
                    const noEmail = getField('NoEmail');
                    if (noEmail || !!isEmpty) return validationHelper.ok();

                    return validationHelper.suggested();
                },
                ClergyCategory: (newValue, persistedValue, hasValue, getField) => {
                    const isEmpty = !newValue && !persistedValue;
                    const category = getField('AddressBookCategory');
                    if (category === 'CLERGY' || category === 'PLACE OF COMMITTAL' || category === 'PLACE OF SERVICE') {
                        if (!!isEmpty) return validationHelper.required();
                    }
                    return validationHelper.ok();
                }
            }
        }
    };

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    nextTab = form => {
        this.setState({ tabIndex: this.state.tabIndex + 1 });
    };

    prevTab = form => {
        this.setState({ tabIndex: this.state.tabIndex - 1 });
    };

    onCancel = () => {
        this.props.onClose();
    };

    onCompleted = (result, ...other) => {
        const { onClose } = this.props;
        const address = result.createAddressBook ? result.createAddressBook : result.updateAddressBook;
        onClose(address);
    };

    render() {
        const { classes, contactId, category, ...other } = this.props;
        const open = this.props.open;
        const { tabIndex } = this.state;

        if (open)
            return (
                <TabbedModal
                    tabs={TABS}
                    tabIndex={tabIndex}
                    className={classes.root}
                    onChange={this.onChangeTab}
                    {...other}
                >
                    <div className={classes.form}>
                        {(contactId && (
                            <Query query={this.getQuery()} variables={{ id: contactId }} fetchPolicy={'network-only'}>
                                {results => this.renderContent(results)}
                            </Query>
                        )) ||
                            this.renderContent({ error: false, loading: false, data: { readOneAddressBook: {} } })}
                    </div>
                </TabbedModal>
            );
        return null;
    }

    renderContent({ error, loading, data }) {
        if (error) {
            console.error(error);
            return <div>Error: Can't read data!</div>;
        } else if (loading) {
            return <div>Loading data, please wait...</div>;
        }
        const { classes } = this.props;
        const { tabIndex } = this.state;

        return (
            <DataForm
                name="Contacts"
                loading={loading}
                error={error}
                data={data && data.readOneAddressBook}
                mutation={this.props.contactId ? this.getUpdate() : this.getCreate()}
                variables={this.getVariables}
                onSaved={this.onCompleted}
                buttonStyles={classes.saveButton}
                buttonLabels={{ isSaved: 'Save & close', isUnsaved: 'Save & close' }}
                validation={this.validation}
            >
                {(form, save) => {
                    this.form = form;
                    return (
                        <Fragment>
                            <DialogContent style={{ maxHeight: 'calc(100vh - 270px)' }}>
                                {tabIndex === 0 && (
                                    <ColumnLayout>
                                        <Grid container={true} spacing={16}>
                                            <Grid pc={1}>
                                                <ModalStepTitle
                                                    number="One"
                                                    title="Enter the business name and categories"
                                                />
                                                <Typography>
                                                    Please ensure that the categories are filled in appropriately. Extra
                                                    fields may appear for different categories.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} spacing={16}>
                                            <Grid pc={1}>
                                                <Label text="Business Name" htmlFor={'text-Name'} />
                                                {this.renderTextField(form, 'Name', null, true, true)}
                                            </Grid>
                                            <Grid pc={1}>
                                                <div className={classes.subtitle}>
                                                    If supplier or partner is an individual please use their name.
                                                </div>
                                            </Grid>
                                            <Grid pc={1}>
                                                <Label text="Categories" />
                                            </Grid>
                                            <Grid pc={1}>
                                                {this.renderSelectField(
                                                    form,
                                                    'AddressBookCategory',
                                                    'Main Category',
                                                    CONTACT_CATEGORY_OPTIONS,
                                                    null,
                                                    false
                                                )}
                                            </Grid>
                                            <Grid pc={1}>
                                                {this.renderTextField(form, 'Religion', 'Extra categorisation details')}
                                            </Grid>
                                            <Grid pc={1}>
                                                {' '}
                                                {//form.getField('AddressBookCategory') === 'CLERGY' &&
                                                this.renderSelectField(
                                                    form,
                                                    'ClergyCategory',
                                                    'Religious affiliation',
                                                    CONTACT_CLERGY_OPTIONS
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} spacing={16}>
                                            {form.getField('AddressBookCategory') === 'DOCTOR' && (
                                                <Grid pc={1}>
                                                    <Label text={'Medical Registration number'} />
                                                    {this.renderTextField(form, 'RegistrationNoMisc')}
                                                </Grid>
                                            )}
                                            {form.getField('AddressBookCategory') === 'CLERGY' && (
                                                <Fragment>
                                                    <Grid pc={1}>
                                                        <Label
                                                            text={
                                                                'Non-standard ' +
                                                                ((Number(form.getField('ClergyCategory')) === 1 ||
                                                                Number(form.getField('ClergyCategory')) === 2
                                                                    ? 'Celebrant'
                                                                    : 'Clergy') +
                                                                    ' Fees')
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid pc={0.5}>
                                                        {this.renderTextField(
                                                            form,
                                                            'ClergyBaseRate',
                                                            'Base Fees',
                                                            null,
                                                            null,
                                                            null,
                                                            'number'
                                                        )}
                                                    </Grid>
                                                    <Grid pc={0.5}>
                                                        {this.renderTextField(
                                                            form,
                                                            'ClergyFollowToCommittalRate',
                                                            'Committal Fees',
                                                            null,
                                                            null,
                                                            null,
                                                            'number'
                                                        )}
                                                    </Grid>
                                                    <Grid pc={0.5}>
                                                        {this.renderTextField(
                                                            form,
                                                            'ClergyFollowAfterRefreshmentsRate',
                                                            'Refreshments Fees',
                                                            null,
                                                            null,
                                                            null,
                                                            'number'
                                                        )}
                                                    </Grid>
                                                    <Grid pc={0.5}>
                                                        {this.renderSelectField(
                                                            form,
                                                            'FeesInclGst',
                                                            'Fees include GST',
                                                            [
                                                                { label: 'No', value: false },
                                                                { label: 'Yes', value: true }
                                                            ]
                                                        )}
                                                    </Grid>
                                                </Fragment>
                                            )}
                                            <Grid pc={1}>
                                                <Label text={'Notes'} />
                                                <TextField
                                                    name={'Notes'}
                                                    multiline
                                                    form={form}
                                                    value={form.getField('Notes') || ''}
                                                    onChange={this.onChange}
                                                />
                                            </Grid>
                                            {form.getField('ID') > 0 && (
                                                <Grid pc={1}>
                                                    <Checkbox
                                                        form={form}
                                                        name={'NotCurrent'}
                                                        label={'This contact is no longer available.'}
                                                        falseValue={false}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </ColumnLayout>
                                )}
                                {tabIndex === 1 && (
                                    <ColumnLayout>
                                        <Grid container={true} spacing={16}>
                                            <Grid pc={1}>
                                                <ModalStepTitle
                                                    number="Two"
                                                    title="Enter the address and contact details"
                                                />
                                                <Typography>
                                                    Please enter an email address and the name of a contact person, for
                                                    automated correspondence.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} spacing={16}>
                                            <Grid pc={1}>
                                                <Label text={'Contact address'} />
                                            </Grid>

                                            <Grid pc={1}>
                                                <AddressAutocomplete
                                                    label={'Search for a residential address'}
                                                    placeholder="Search for a residential address"
                                                    componentFields={{
                                                        line1: 'AddressLine1',
                                                        line2: 'AddressLine2',
                                                        city: 'Suburb',
                                                        state: 'State',
                                                        //country: 'country',
                                                        code: 'Postcode'
                                                    }}
                                                    form={form}
                                                />
                                            </Grid>
                                            <Grid pc={0.5}>
                                                {this.renderTextField(
                                                    form,
                                                    'AddressLine1',
                                                    'Address line 1',
                                                    null,
                                                    true
                                                )}
                                            </Grid>
                                            <Grid pc={0.5}>
                                                {this.renderTextField(form, 'AddressLine2', 'Address line 2')}
                                            </Grid>
                                            <Grid pc={1}>
                                                {this.renderTextField(form, 'Suburb', 'Suburb', null, true)}
                                            </Grid>
                                            <Grid pc={0.5}>
                                                {this.renderSelectField(
                                                    form,
                                                    'State',
                                                    'State',
                                                    CONTACT_AUS_STATES,
                                                    null,
                                                    false,
                                                    true
                                                )}
                                            </Grid>
                                            <Grid pc={0.5}>
                                                {this.renderTextField(
                                                    form,
                                                    'Postcode',
                                                    'Postcode',
                                                    null,
                                                    true,
                                                    false,
                                                    'text',
                                                    this.postcodeMaskFunc,
                                                    '____'
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} spacing={16}>
                                            <Grid pc={1}>
                                                <Label text={'Contact details'} />
                                            </Grid>
                                            <Grid pc={0.5}>
                                                {this.renderTextField(
                                                    form,
                                                    'Phone',
                                                    'Primary Number',
                                                    null,
                                                    true,
                                                    null,
                                                    'text',
                                                    this.phoneMaskFunc,
                                                    '(__) ____-____'
                                                )}
                                            </Grid>
                                            <Grid pc={0.5}>
                                                {this.renderTextField(
                                                    form,
                                                    'Mobile',
                                                    'Secondary Number',
                                                    null,
                                                    false,
                                                    null,
                                                    'text',
                                                    this.phoneMaskFunc,
                                                    '(__) ____-____'
                                                )}
                                            </Grid>
                                            <Grid pc={0.5}>
                                                {this.renderTextField(
                                                    form,
                                                    'Fax',
                                                    'Fax',
                                                    null,
                                                    false,
                                                    null,
                                                    'text',
                                                    this.phoneMaskFunc,
                                                    '(__) ____-____'
                                                )}
                                            </Grid>

                                            <Grid pc={1}>
                                                {this.renderTextField(
                                                    form,
                                                    'Email',
                                                    'Email',
                                                    false,
                                                    !form.getField('NoEmail'),
                                                    form.getField('NoEmail'),
                                                    'text',
                                                    this.emailMaskFunc,
                                                    '_____@_____.___'
                                                )}
                                            </Grid>
                                            <Grid pc={1}>
                                                <Checkbox
                                                    label="No email available"
                                                    form={form}
                                                    name="NoEmail"
                                                    // onChange={this.onNoEmailChange}
                                                />
                                            </Grid>
                                            <Grid pc={1}>
                                                {this.renderTextField(
                                                    form,
                                                    'Contact',
                                                    'Name of Primary Contact Person',
                                                    null,
                                                    true
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ColumnLayout>
                                )}
                            </DialogContent>
                            <DialogActions style={{ padding: 24, margin: '12px -12px -12px -12px' }}>
                                <div className={classes.actions}>{this.getActions(form, save)}</div>
                            </DialogActions>
                        </Fragment>
                    );
                }}
            </DataForm>
        );
    }

    renderTextField(form, name, label, autoFocus, required, disabled, type, maskFunc, placeholder) {
        return (
            <TextField
                label={label}
                placeholder={placeholder}
                name={name}
                autoFocus={autoFocus}
                required={required}
                form={form}
                disabled={disabled}
                type={type}
                // InputProps={{
                //     inputComponent: maskFunc
                // }}
            />
        );
    }

    phoneMaskFunc(props) {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                mask={['(', /\d/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            />
        );
    }

    postcodeMaskFunc(props) {
        const { inputRef, ...other } = props;

        return <MaskedInput {...other} mask={[/\d/, /\d/, /\d/, /\d/]} />;
    }

    emailMaskFunc(props) {
        const { inputRef, ...other } = props;

        return <MaskedInput {...other} mask={emailMask} showMask />;
    }

    renderSelectField(form, name, label, options, defaultValue, allowNone = true, required) {
        return (
            <Select
                label={label}
                name={name}
                options={options}
                form={form}
                allowNone={allowNone}
                defaultValue={defaultValue}
            />
        );
    }

    getVariables = input => {
        const { category } = this.props;
        const presetCategory = category && category.key;
        if (presetCategory) input.AddressBookCategory = presetCategory;
        return input;
    };

    getActions(form, save) {
        const { loading } = form;
        const { classes } = this.props;
        const { tabIndex } = this.state;
        return (
            <Fragment>
                <OutlineButton
                    onClick={loading ? undefined : this.onCancel}
                    color="primary"
                    disabled={loading}
                    style={{ float: 'left' }}
                >
                    <CloseIcon />
                    <span className={classes.svgLabel}>Cancel</span>
                </OutlineButton>

                {tabIndex !== 0 && (
                    <PrimaryButton
                        onClick={loading ? undefined : () => this.prevTab(form)}
                        disabled={loading}
                        style={{ float: 'left' }}
                    >
                        <Icon>navigate_before</Icon>
                        <span className={classes.svgLabel}>Previous step</span>
                    </PrimaryButton>
                )}

                {(tabIndex !== TABS.length - 1 && (
                    <PrimaryButton onClick={loading ? undefined : () => this.nextTab(form)} disabled={loading}>
                        <span className={classes.svgLabel}>Next step</span>
                        <Icon>navigate_next</Icon>
                    </PrimaryButton>
                )) ||
                    save}
            </Fragment>
        );
    }

    addressBookFields() {
        return [
            'ID',
            'Name',
            'AddressBookCategory',
            'ClergyCategory',
            'ClergyType',
            'Religion',
            'Email',
            'Phone',
            'Fax',
            'Mobile',
            'Contact',
            'AddressLine1',
            'AddressLine2',
            'Suburb',
            'State',
            'Postcode',
            'Notes',
            'FeesInclGst',
            'ClergyBaseRate',
            'ClergyFollowToCommittalRate',
            'ClergyFollowAfterRefreshmentsRate',
            'NotCurrent',
            'NoEmail',
            'RegistrationNoMisc'
        ];
    }

    getCreate() {
        const addressBookFields = this.addressBookFields();
        return gql`
            mutation CreateAddressBook($input: AddressBookCreateInputType!) {
                createAddressBook(Input: $input) {
                    ${addressBookFields.join('\n')}
                }
            }
        `;
    }

    getUpdate() {
        const addressBookFields = this.addressBookFields();
        return gql`
            mutation UpdateAddressBook($input: AddressBookUpdateInputType!) {
                updateAddressBook(Input: $input) {
                    ${addressBookFields.join('\n')}
                }
            }
        `;
    }

    getQuery() {
        const addressBookFields = this.addressBookFields();
        return gql`
            query QueryAddressBook($id: ID!) {
                readOneAddressBook(ID: $id) {
                    ${addressBookFields.join('\n')}
                }
            }
        `;
    }
}

const styles = ({ spacing, palette, typography, breakpoints }) => ({
    form: {
        width: '100%',
        maxWidth: 1280,
        padding: 24,
        [breakpoints.down('xs')]: {
            padding: 0
        }
    },

    actions: {
        margin: 0,
        width: '100%',
        textAlign: 'right',
        '& button': {
            marginRight: 6,
            '&:last-child': {
                marginRight: 0
            },
            [breakpoints.down('xs')]: {
                padding: 8,
                minWidth: 16,
                '& span > span, & span > svg': {
                    width: 24,
                    height: 24,
                    fontSize: 26,
                    margin: 6
                }
            }
        }
    },

    saveButton: {
        position: 'static'
    },

    svgLabel: {
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },

    subtitle: {
        fontSize: '14px',
        fontStyle: 'italic'
    }
});

export default compose(withRouter, withStyles(styles))(EditContactModal);
