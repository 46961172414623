import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import TextField from '../../../component/form/TextField';
import DataFormView from '../../../component/form/v2/DataFormView';
import Spinner from '../../../component/form/v2/Spinner';
import {
    CoffinListFragment,
    getCoffin,
    getCoffinCode,
    getCoffinDescription,
    getCoffinIndex,
    isCategory
} from '../Coffin/CoffinListConstants';
import { joinDefined } from '../../../util/strings';
import { getFuneralHomeByKey } from '../../../util/funerals';
import { LoadProducts, QueryKeys } from '../../../util/products';
import { flattenConnection, isRelatedObjectUndefined } from '../../../util/graphql';
import { isRelatedObjectDefined } from '../../../util/bookable';

class CoffinModal extends Component {
    state = {
        coffinIndex: null,
        actualDescription: null,
        originalCode: null,
        actualCode: null,
        coffinProducts: null
    };

    componentDidMount() {
        const { form } = this.props;

        const actualCoffin = getCoffin(form.state, false);
        let coffinIndex = getCoffinIndex(form.state, false);
        const originalCode = getCoffinCode(actualCoffin);

        if (coffinIndex < 0) {
            const items = form.getState('Disposal.DisposalBookingItems');
            flattenConnection(items, 'DisposalBookingItems');

            const selectedCoffinIndex = getCoffinIndex(form.state, true);
            if (selectedCoffinIndex < 0) throw new Error('Cannot find existing selected coffin');

            items[selectedCoffinIndex].Quantity = 0; //this sets it to selected
            const { ProductCategories } = items[selectedCoffinIndex].Product;

            coffinIndex = items.length;
            items.push({
                Comment: null,
                Product: {
                    ID: '0',
                    ProductCategories
                },
                Variation: {
                    ID: '0'
                },
                Quantity: 1
            });
            form.setState({ 'Disposal.DisposalBookingItems': items });
        }

        this.setState({
            coffinIndex,
            actualDescription: getCoffinDescription(actualCoffin),
            originalCode: originalCode,
            actualCode: getCoffinCode(actualCoffin)
        });

        LoadProducts('coffins').then(coffinProducts => this.setState({ coffinProducts }));
    }

    render() {
        const { classes } = this.props;
        const { originalCode } = this.state;
        return (
            <Fragment>
                <Typography variant="headline" id="modal-title" className={classes.popoverTitle}>
                    {originalCode ? 'Review ' : 'Add '} Actual Coffin
                </Typography>
                <br />
                <Grid item xs={12}>
                    {this.state.coffinIndex >= 0 ? this.renderCoffinDetails() : this.renderNoCoffin()}
                </Grid>
            </Fragment>
        );
    }

    renderNoCoffin() {
        const { form } = this.props;
        const record = form.getState('LegacyKey');

        return (
            <Fragment>
                <p>{`The funeral record '${record}' has no coffin selected. A coffin needs to be selected to continue`}</p>
                <p>{getArrangersString(form)}</p>
                <br />
                <br />
            </Fragment>
        );
    }

    renderCoffinDetails() {
        const { coffinIndex, actualDescription, actualCode, coffinProducts } = this.state;
        const { form } = this.props;

        if (coffinProducts === null) return this.renderLoading();

        return (
            <Fragment>
                <TextField
                    label="Coffin Code"
                    value={actualCode}
                    onChange={e => this.onScannedItemCodeChanged(e.target.value)}
                />

                <TextField InputProps={{ disabled: true }} label="Description" value={actualDescription} />

                <TextField
                    label="Additional notes"
                    placeholder="Enter your notes here..."
                    form={form}
                    name={`Disposal.DisposalBookingItems[${coffinIndex}].Comment`}
                    multiline
                />
            </Fragment>
        );
    }

    renderLoading() {
        const { classes } = this.props;
        return (
            <Fragment>
                <div className={classes.loading}>
                    <Spinner />
                    &nbsp;<span>Loading...</span>
                </div>
            </Fragment>
        );
    }

    onScannedItemCodeChanged(actualCode) {
        const { coffinProducts, coffinIndex } = this.state;
        let actualDescription = null;
        let coffin = null;
        let variation = null;
        for (let x = 0; x < coffinProducts.length && !coffin; x++) {
            if (coffinProducts[x].legacyKey === actualCode) {
                coffin = coffinProducts[x];
            } else if (actualCode.startsWith(coffinProducts[x].legacyKey) && coffinProducts[x].variations.length > 0) {
                variation = coffinProducts[x].variations.find(variation => variation.legacyKey === actualCode);
                if (variation) {
                    coffin = coffinProducts[x];
                }
            }
        }

        const { form } = this.props;

        if (coffin) {
            form.setState({
                [`Disposal.DisposalBookingItems[${coffinIndex}].Product.ID`]: coffin.id,
                [`Disposal.DisposalBookingItems[${coffinIndex}].Variation.ID`]: variation ? variation.id : '0'
            });
            actualDescription = variation ? variation.title : coffin.title;
        } else {
            form.setState({
                [`Disposal.DisposalBookingItems[${coffinIndex}].Product.ID`]: '0',
                [`Disposal.DisposalBookingItems[${coffinIndex}].Variation.ID`]: '0'
            });
            actualDescription = null;
        }

        this.setState({ actualCode, actualDescription });
    }
}

const getArrangersString = form => {
    const arrangers = joinDefined(
        (form.getState('Arrangers') || []).map(({ Member }) => `${Member.FirstName} ${Member.Surname}`),
        ', '
    );

    const office = getFuneralHomeByKey(form.getState('LegacyKey')).label;

    if (arrangers.length > 0) {
        return `Please contact ${arrangers} from ${office} for more information`;
    }

    return `No arrangers were assigned to this funeral. Please contact ${office} directly for more information.`;
};

const styles = ({ palette, breakpoints }) => ({
    formControl: {
        width: '100%'
    },

    select: {
        width: '100%'
    },

    icon: {
        color: 'white'
    },

    buttonCancel: {
        outline: palette.action.active,
        marginRight: 10
    },
    buttonCancelCopy: {
        lineHeight: '2em'
    },
    buttonOk: { color: '#26CC6F' },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    },
    actionButtons: {
        marginTop: 20,
        textAlign: 'center'
    },
    saveButtonContainer: {
        marginLeft: 10,
        position: 'static',
        minWidth: 'unset'
    },
    saveButton: {
        [breakpoints.down('sm')]: {
            minWidth: 'unset',
            bottom: 0,
            right: 0,
            padding: '10px',
            borderRadius: 64,
            height: 'fit-content',
            '& span > span': {
                display: 'block'
            },

            '& span > svg': {
                display: 'block',
                margin: 0,
                height: 20,
                width: 20,
                marginRight: 12
            }
        }
    },
    classes: {},
    viewRoot: {
        padding: '40px'
    }
});

const Tabs = [
    {
        id: 'CoffinModal',
        label: 'CoffinModal',
        component: withStyles(styles)(CoffinModal),
        fragment: CoffinListFragment,
        onLoad: data => {
            data.Disposal.DisposalBookingItems = data.Disposal.DisposalBookingItems.filter(
                x => !isRelatedObjectUndefined(x.Product)
            );
        },
        formatSaveData: (saveData, state) => {
            const bookingItems = state.Disposal.DisposalBookingItems.filter(e => !!e);
            saveData.Disposal.DisposalBookingItems = [];
            for (let x = 0; x < bookingItems.length; x++) {
                let bookingItem = bookingItems[x];

                if (isCategory(bookingItem, QueryKeys.Coffins)) {
                    if (isRelatedObjectDefined(bookingItem.Product)) {
                        saveData.Disposal.DisposalBookingItems.push({
                            ID: bookingItem.ID || null,
                            VariationID: isRelatedObjectDefined(bookingItem.Variation) ? bookingItem.Variation.ID : '0',
                            ProductID: bookingItem.Product.ID,
                            Comment: bookingItem.Comment,
                            Quantity: bookingItem.Quantity
                        });
                    }
                } else {
                    saveData.Disposal.DisposalBookingItems.push({ ID: bookingItem.ID });
                }
            }
        }
    }
];

class CoffinModalView extends Component {
    render() {
        const { context, itemId, onClose, onSaved, open, classes } = this.props;
        return (
            <Dialog disableBackdropClick={true} disableEscapeKeyDown={true} open={open}>
                <DataFormView
                    tabs={Tabs}
                    createNew={false}
                    createNewFunc={null}
                    objectType="Funeral"
                    itemId={itemId}
                    name="CoffinModal"
                    context={context}
                    onClose={onClose}
                    onCreateNew={null}
                    onSaved={onSaved}
                    className={classes.viewRoot}
                    classes={{ footerButtonsContainer: classes.saveButtonContainer, saveButton: classes.saveButton }}
                />
            </Dialog>
        );
    }
}

export default withStyles(styles)(CoffinModalView);
