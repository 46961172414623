import Presentation from './Presentation';
import PresentationFragment from './PresentationFragment';
import { flattenConnection } from '../../../util/functions';
import { prepareBookingsForSave } from '../../../util/bookable';
import { validationHelper } from '../../../util/validation';
import { stringIsNullOrEmpty } from '../../../util/strings';

export default {
    label: 'Presentation',
    component: Presentation,
    fragment: PresentationFragment,
    onLoad: data => {
        const items = [
            'DefaultMusicians',
            'EnterMusicians',
            'ExitMusicians',
            'GravesideMusicians',
            'ReflectionMusicians',
            'RslMasonic',
            'EnterMusicSong',
            'ExitMusicSongs',
            'GravesideMusicSongs',
            'ReflectionMusicSongs'
        ];
        items.forEach(item => flattenConnection(data, item));
    },
    formatSaveData: (saveData, state) => {
        [
            'DefaultMusicians',
            'EnterMusicians',
            'ExitMusicians',
            'GravesideMusicians',
            'ReflectionMusicians',
            'RslMasonic'
        ].forEach(item => {
            if (saveData[item]) {
                saveData[item] = prepareBookingsForSave(state[item]);
            }
        });

        ['EnterMusicSong', 'ExitMusicSongs', 'GravesideMusicSongs', 'ReflectionMusicSongs'].forEach(item => {
            if (saveData[item]) {
                saveData[item] = state[item].map(e => {
                    if (e.OriginalList && e.OriginalList !== item) delete e.ID;
                    delete e.OriginalList;
                    if (item !== 'ReflectionMusicSongs') {
                        delete e.IncludeInLifeStory;
                        delete e.ForFlowerPlacement;
                        delete e.OtherText;
                    }
                    return e;
                });
            }
        });

        if (!!saveData.FlagType && saveData.FlagType !== 'Other') {
            saveData.FlagDetails = null;
        }

        if (saveData.NamesheetsRequired === null || !!saveData.NamesheetsNotRequired) {
            saveData.NamesheetsDetails = null;
        }

        if (saveData.MassbookRequired === null) {
            saveData.MassbookDetails = null;
        }

        if (!!state.Lifestory && !!state.Lifestory.Presentation && !!state.Lifestory.FoyerPhotoRequired) {
            const lifeStory = saveData.Lifestory || state.Lifestory;
            lifeStory.FoyerPhotoRequired = null;
            saveData.Lifestory = lifeStory;
        }
    },
    validation: {
        required: [],
        suggested: [],
        onValidate: {
            //if presentation selected, validate these fields...
            'Lifestory.PresentationFamilyToPreparePowerpointHandoverTime': (
                newValue,
                persistedValue,
                hasValue,
                getField
            ) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (getField('Lifestory.PresentationFamilyToPreparePowerpointReceived') === true)
                    return validationHelper.ok();
                if (whatWillFamilySend(getField) === 'none') return validationHelper.ok();
                return !stringIsNullOrEmpty(persistedValue) ? validationHelper.ok() : validationHelper.suggested();
            },
            'Lifestory.PresentationFamilyToPreparePowerpointHandoverDate': (
                newValue,
                persistedValue,
                hasValue,
                getField
            ) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (getField('Lifestory.PresentationFamilyToPreparePowerpointReceived') === true)
                    return validationHelper.ok();
                if (whatWillFamilySend(getField) === 'none') return validationHelper.ok();
                return !stringIsNullOrEmpty(persistedValue) ? validationHelper.ok() : validationHelper.suggested();
            },
            'Lifestory.PhotoScanningRequiredTime': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (getField('Lifestory.PresentationFamilyToPreparePowerpointReceived') === true)
                    return validationHelper.ok();
                if (whatWillFamilySend(getField) !== 'none') return validationHelper.ok();
                return !stringIsNullOrEmpty(persistedValue) ? validationHelper.ok() : validationHelper.suggested();
            },
            'Lifestory.PhotoScanningRequiredDate': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (getField('Lifestory.PresentationFamilyToPreparePowerpointReceived') === true)
                    return validationHelper.ok();
                if (whatWillFamilySend(getField) !== 'none') return validationHelper.ok();
                return !stringIsNullOrEmpty(persistedValue) ? validationHelper.ok() : validationHelper.suggested();
            },
            'Lifestory.PresentationFamilyToPreparePowerpointHandoverQty': (
                newValue,
                persistedValue,
                hasValue,
                getField
            ) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (whatWillFamilySend(getField) === 'video') return validationHelper.ok();
                if (Number(persistedValue) > 0) return validationHelper.ok();
                const received = getField('Lifestory.PresentationFamilyToPreparePowerpointReceived');
                if (!!received && Number(persistedValue) !== 0) return validationHelper.required();
                return validationHelper.suggested();
            },
            'Lifestory.PresentationAdditionalPhotosQty': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (Number(persistedValue) > 0) return validationHelper.ok();
                const received = getField('Lifestory.PresentationFamilyToPreparePowerpointReceived');
                if (!!received && Number(persistedValue) !== 0) return validationHelper.required();
                return validationHelper.suggested();
            },
            'Lifestory.PresentationFamilyToPreparePowerpointHowSend': (
                newValue,
                persistedValue,
                hasValue,
                getField
            ) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (getField('Lifestory.PresentationFamilyToPreparePowerpointReceived') === true)
                    return validationHelper.ok();
                if (
                    whatWillFamilySend(getField) !== 'video' &&
                    Number(getField('Lifestory.PresentationFamilyToPreparePowerpointHandoverQty')) === 0
                )
                    return validationHelper.ok();
                if (!stringIsNullOrEmpty(persistedValue)) return validationHelper.ok();
                return validationHelper.suggested();
            },
            'Lifestory.PresentationAdditionalPhotosHowSend': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Lifestory.Presentation') !== true) return validationHelper.ok();
                if (getField('Lifestory.PresentationFamilyToPreparePowerpointReceived') === true)
                    return validationHelper.ok();
                if (whatWillFamilySend(getField) !== 'none') return validationHelper.ok();
                if (Number(getField('Lifestory.PresentationAdditionalPhotosQty')) === 0) return validationHelper.ok();
                if (!stringIsNullOrEmpty(persistedValue)) return validationHelper.ok();
                return validationHelper.suggested();
            },

            NamesheetsDetails: (newValue, persistedValue, hasValue, getField) => {
                return !getField('NamesheetsRequired') ||
                    !!getField('NamesheetsNotRequired') ||
                    !stringIsNullOrEmpty(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.suggested();
            },

            MassbookDetails: (newValue, persistedValue, hasValue, getField) => {
                return !getField('MassbookRequired') || !stringIsNullOrEmpty(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.suggested();
            },

            FlagType: (newValue, persistedValue, hasValue, getField) => {
                return !getField('FlagRequired') || !stringIsNullOrEmpty(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.required();
            },
            FlagDetails: (newValue, persistedValue, hasValue, getField) => {
                return getField('FlagType') !== 'Other' || !stringIsNullOrEmpty(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.required();
            }
        }
    }
};

const whatWillFamilySend = getField => {
    const familyToPrepare = getField('Lifestory.PresentationFamilyToPrepareWhat');
    return familyToPrepare === 'Scanned Photos' ? 'photos' : familyToPrepare === null ? 'none' : 'video';
};
