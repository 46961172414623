import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../../component/form/Grid';
import Spinner from '../../../component/form/v2/Spinner';
import { isNullOrUndefined } from '../../../util/objects';
import Paper from '@material-ui/core/Paper/Paper';
import CoffinModal from '../Common/CoffinModal';
import CoffinInvalidModal from '../Common/CoffinInvalidModal';
import { getCoffinList } from './CoffinListConstants';
import Table, { TableCell, TableRow } from '../../../component/form/Table';
import { FUNERAL_HOME } from '../../../util/funerals';
import { theme } from '../../../Theme';
import { InlineField } from '../../../component/form/Inline';
import BrandedTableKey from '../../../component/BrandedTableKey';
import QuestionMarkCircleIcon from '../../../component/icon/QuestionMarkCircleIcon';

class CoffinList extends Component {
    state = {
        coffins: null,
        modalItemId: null,
        invalidModalItem: null
    };

    componentDidMount() {
        getCoffinList().then(coffins => this.setState({ coffins }));
    }

    render() {
        const { coffins, modalItemId, invalidModalItem } = this.state;
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <span>Mortuary</span>
                    <span className={classes.header}>Coffin Distribution</span>
                </Typography>
                <br />
                {isNullOrUndefined(coffins) ? this.renderLoading() : this.renderBody()}
                <CoffinModal
                    context={this}
                    itemId={modalItemId}
                    open={!isNullOrUndefined(modalItemId)}
                    onClose={() => this.setState({ modalItemId: null })}
                    onSaved={() => this.onSaved()}
                />
                <CoffinInvalidModal
                    item={invalidModalItem}
                    open={!isNullOrUndefined(invalidModalItem)}
                    onClose={() => this.setState({ invalidModalItem: null })}
                />
            </Fragment>
        );
    }

    renderKey() {
        const { classes } = this.props;

        return (
            <InlineField>
                <Typography variant="body2" className={classes.keyTitle}>
                    Legend:
                </Typography>
                <BrandedTableKey />
            </InlineField>
        );
    }

    renderBody() {
        const { classes } = this.props;
        const { coffins } = this.state;

        if (coffins.error) return <div>failed to get coffin items: {coffins.error}</div>;
        if (coffins.items === null || coffins.items.length === 0) return <div>No items to display.</div>;

        return (
            <Paper className={classes.paper} elevation={0}>
                <div className={classes.wideScroll}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="title">
                                Coffin distribution list - {coffins.items.length} items
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    {this.renderKey()}
                    <br />
                    <div>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Table
                                    className={classes.table}
                                    columns={[
                                        'Key',
                                        'Deceased',
                                        'Selected Coffin',
                                        'Selected Coffin Description',
                                        'Actual Coffin',
                                        'Actual Coffin Description'
                                    ]}
                                >
                                    {coffins.items.map((coffinItem, index) => {
                                        const letterCode = coffinItem.LegacyKey.slice(-1);
                                        const rowStyle = classes['rowColor' + letterCode];
                                        return (
                                            <TableRow
                                                className={rowStyle}
                                                key={index}
                                                onClick={() => this.onSelectRow(index)}
                                                pad={true}
                                            >
                                                <TableCell>{coffinItem.LegacyKey}</TableCell>
                                                <TableCell>{coffinItem.Deceased}</TableCell>
                                                <TableCell>
                                                    {coffinItem.SelectedCoffinCode || (
                                                        <QuestionMarkCircleIcon color="disabled" />
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {coffinItem.SelectedCoffinDescription}
                                                    {!!coffinItem.SelectedCoffinComment ? (
                                                        <div>{coffinItem.SelectedCoffinComment}</div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </TableCell>
                                                <TableCell>{coffinItem.ActualCoffinCode}</TableCell>
                                                <TableCell>
                                                    {coffinItem.ActualCoffinDescription}
                                                    {!!coffinItem.ActualCoffinComment ? (
                                                        <div>{coffinItem.ActualCoffinComment}</div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Paper>
        );
    }

    renderLoading() {
        const { classes } = this.props;

        return (
            <Paper className={classes.paper} elevation={0}>
                <Spinner />
                &nbsp;<span>Loading...</span>
            </Paper>
        );
    }

    onSaved() {
        this.setState({ coffins: null });
        getCoffinList().then(coffins => this.setState({ coffins, modalItemId: null }));
    }

    onSelectRow(index) {
        const { coffins } = this.state;
        const item = coffins.items[index];

        if (!item.SelectedCoffinCode) {
            this.setState({ invalidModalItem: item });
        } else {
            this.setState({ modalItemId: item.ID });
        }
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },

    paperModal: {
        position: 'absolute',
        backgroundColor: 'white',
        minWidth: '30%',
        minHeight: '30%',
        width: '30%',
        height: 'auto',
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },

    paperMainPage: {
        padding: spacing.unit * 3,
        position: 'absolute',
        backgroundColor: 'white',
        outline: 'none'
    },

    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },

    addButtonDesktop: {
        float: 'right',
        top: -45,
        boxShadow: 'none',
        backgroundColor: palette.button.alt.backgroundColor,
        borderRadius: 18
    },

    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingLeft: 24,
        marginLeft: -12
    },
    tableData: {
        marginTop: '1rem'
    },
    icon: {
        fontSize: 16,
        color: '#26CC6F',
        height: '30px',
        width: '30px'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        '& tbody tr:hover': {
            cursor: 'pointer',
            outline: '3px solid black'
        },
        '& tbody td': {
            padding: 8
        }
    },
    ['rowColor' + [FUNERAL_HOME.RANKINS.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.RANKINS.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] },
        '&:hover': { outlineColor: theme.palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] + '!important' }
    },

    ['rowColor' + [FUNERAL_HOME.STAN_CRAP.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.STAN_CRAP.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] },
        '&:hover': { outlineColor: theme.palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] + '!important' }
    },

    ['rowColor' + [FUNERAL_HOME.WOLLONGONG_CITY.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] },
        '&:hover': {
            outlineColor: theme.palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.EASY_FUNERALS.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode] + '!important',
        '& svg': { background: theme.palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode] },
        '&:hover': {
            outlineColor: theme.palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.PARSONS_LADIES.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.PARSONS_LADIES.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] },
        '&:hover': {
            outlineColor: theme.palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.H_PARSONS.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.H_PARSONS.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] },
        '&:hover': { outlineColor: theme.palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] + '!important' }
    }
});

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(CoffinList);
