import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ palette, funeralHome }) => ({
    table: {
        borderSpacing: 0,
        borderCollapse: 'collapse',
        width: 'calc(100% - 1px)' // slight rendering error
    },

    tableBody: {
        borderBottom: '2px solid ' + palette.contentForeground[funeralHome]
    },
    tableFooter: {
        // borderBottom: '2px solid ' + palette.contentForeground[funeralHome]
    },
    tableHeaderRow: {
        '& th': {
            background: palette.contentForeground[funeralHome],
            borderLeft: '1px solid ' + palette.contentForeground[funeralHome],
            padding: '14px 8px',
            color: palette.table.header.foregroundColor,
            fontSize: '0.875rem',
            fontWeight: '500',
            textAlign: 'left'
        },
        '& th:first-child': {
            borderLeft: 0,
            borderRadius: '6px 0 0 0'
        },
        '& th:last-child': {
            borderRadius: '0 6px 0 0'
        }
    },

    tableFooterRow: {
        '& th': {
            background: palette.contentForeground[funeralHome],
            border: '1px solid ' + palette.contentForeground[funeralHome],
            padding: '12px 8px',
            color: palette.table.header.foregroundColor,
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'left',
            borderLeft: 1
        },
        '& td': {
            borderStyle: 'solid',
            borderWidth: 1,
            background: palette.table.row.backgroundColor,
            fontSize: 14,
            fontWeight: 'bolder',
            padding: '8px 8px',
            borderColor: palette.table.border
        },
        '&:hover, & td:hover': {
            backgroundColor: palette.table.row.hoverColor
        }
    },

    tableRow: {
        background: palette.table.row.backgroundColor,
        '&:nth-child(2n)': { background: palette.table.row.backgroundAltColor },
        '& td': {
            borderStyle: 'solid',
            borderWidth: 1,
            fontSize: 14,
            padding: '12px 8px',
            borderColor: palette.table.border,
            '&:last-child': {}
        },
        '&:hover': {
            backgroundColor: palette.table.row.hoverColor
        },
        '&:first-child': {
            borderTop: '1px solid'
        },
        '&:last-child': {
            borderBottom: '1px solid'
        }
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        paddingLeft: 1 // weird Firefox bug
    }
});

/**
 * TableData
 * @param {columns} the id and label of each column. Also has render((value,row)) and styles.
 * @param {data} an array of structured objects, eg. [ {id1: value, id2: value}, { id1: value, id2: value } ]
 * @exports TableData
 */
const TableData = withStyles(styles)(({ ...props }) => {
    const { classes, columns, data, footer } = props;
    if (data && data.length) {
        return (
            <div className={classes.wideScroll}>
                <table className={classes.table}>
                    <thead>
                        <tr className={classes.tableHeaderRow}>
                            {columns.map((column, columnIndex) => (
                                <th
                                    key={columnIndex}
                                    // style={column.styles && column.styles.width ? { width: column.styles.width } : {}}
                                >
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={classes.tableBody}>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex} className={classes.tableRow}>
                                {columns.map((column, columnIndex) => (
                                    <td key={columnIndex} style={column.styles}>
                                        {row[column.id]
                                            ? column.render
                                                ? column.render(row[column.id], row, rowIndex)
                                                : row[column.id]
                                            : column.render
                                            ? column.render('', row, rowIndex)
                                            : ''}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    {footer && footer.length && (
                        <tfoot className={classes.tableFooter}>
                            <tr className={classes.tableFooterRow}>
                                {columns.map((column, columnIndex) => {
                                    const me = footer.find(foot => {
                                        return column.id === foot.id;
                                    });
                                    return me ? (
                                        me.label ? (
                                            <th key={columnIndex}>{me.label}</th>
                                        ) : (
                                            <td key={columnIndex} style={column.styles}>
                                                {me.render ? me.render(column.id, data) : me.value}
                                            </td>
                                        )
                                    ) : (
                                        <td
                                            key={columnIndex}
                                            style={{
                                                visibility: 'hidden',
                                                borderColor: 'transparent'
                                            }}
                                        />
                                    );
                                })}
                            </tr>
                        </tfoot>
                    )}
                </table>
            </div>
        );
    }
    return null;
});

export default TableData;
