import {
    BillTo,
    Certification,
    EnterMusicians,
    ExitMusicians,
    Informant,
    Invoices,
    LegacyDocuments,
    PurchaseOrders,
    RefreshmentsVenue
} from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';
import Disposal from '../../fragments/Disposal';
import RslMasonic from '../../fragments/RslMasonic';
import ReflectionMusicians from '../../fragments/ReflectionMusicians';
import Clergy from '../../fragments/Clergy';
import GravesideMusicians from '../../fragments/GravesideMusicians';
import DefaultMusicians from '../../fragments/DefaultMusicians';
import PersonalisedTouches from '../../fragments/PersonalisedTouches';
import NewspaperNotice from '../../fragments/NewspaperNotice';
import MemorialStationary from '../../fragments/MemorialStationary';
import Florist from '../../fragments/Florist';
import Caterers from '../../fragments/Caterers';

export default buildTabDataFragment(
    [
        gql`
            fragment BillingFields on Funeral {
                ApplicantGivenName
                ApplicantMiddleName
                ApplicantSurname

                FollowUpRating
                FollowUpComment
                FollowUpCallMade
                FollowUpSatisfaction
                FollowUpCallMadeDatetime

                Cancelled
                CancelledDate
                CancelledReason
                BillingNotes
                DateOfService

                Enquiry {
                    ID
                }
                ...PurchaseOrders
            }
        `,
        BillTo,
        Caterers,
        Certification,
        Clergy,
        DefaultMusicians,
        Disposal,
        EnterMusicians,
        ExitMusicians,
        Florist,
        GravesideMusicians,
        Informant,
        Invoices,
        LegacyDocuments,
        MemorialStationary,
        NewspaperNotice,
        PersonalisedTouches,
        PurchaseOrders,
        ReflectionMusicians,
        RefreshmentsVenue,
        RslMasonic
    ],
    'Funeral'
);
