import gql from 'graphql-tag';

export const ArrangementChecksQuery = gql`
    query checksQuery($formID: Int, $formType: String) {
        readFormArrangementChecks(ArrangementForm: $formID, FormType: $formType) {
            ID
            Checked
            ArrangementCheck {
                ID
                Title
                Page
                Section
                FormType
            }
        }
    }
`;

export const BuildArrangementChecksMutation = function(modified, formObject) {
    const args = modified
        .map((x, i) => {
            return `$input${i}: FormArrangementCheckUpdateInputType!`;
        })
        .join(',');
    const mutations = modified
        .map((x, i) => {
            return `check${i}:updateFormArrangementCheck(Input: $input${i}) {
        ID
        Checked
    }`;
        })
        .join('\n');

    let mutation = gql`
        mutation updateFormArrangementCheck(${args},$inputProgress:${formObject}UpdateInputType!){
            ${mutations}
            progress: update${formObject}(Input: $inputProgress){
                ID
                Progress
            }
        }
    `;
    return mutation;
};
