import { isNullOrUndefined } from './objects';
import { sanitizeAndAppendFuneralData, sanitizeCateringStaffAllocation } from '../page/calendar/AppointmentConstants';

/**
 * determines if the contact is defined
 * @param {Object} contact: Any database object
 */
export function isContactDefined(contact) {
    return contact && contact.ID !== '0';
}

/**
 * determines if a foreign key object is defined
 * @param {Object} obj: Any database object
 * @param {String} nestedProperty: Name of field or array key
 */
export function isRelatedObjectDefined(obj, nestedProperty = false) {
    if (!nestedProperty) return obj && obj.ID !== '0' && !isNullOrUndefined(obj.ID);

    return isRelatedObjectDefined(obj) && isRelatedObjectDefined(obj[nestedProperty]);
}

/**
 * sanitizes the foreign key from a object to make it save worthy
 * @param {Object} originalObject
 * @param {String} propertyPath
 * @param {Boolean} clone: to create a clone or modify original
 */
export function sanitizeObjectWithFK(originalObject, propertyPath, clone = true) {
    if (!originalObject || !propertyPath) return originalObject;

    const objectWithFK = clone ? { ...originalObject } : originalObject;

    if (isContactDefined(objectWithFK[propertyPath])) {
        // if we have an ID, save the relationship
        objectWithFK[propertyPath + 'ID'] =
            objectWithFK[propertyPath].ID || (!!objectWithFK[propertyPath].node && objectWithFK[propertyPath].node.ID);
    }
    delete objectWithFK[propertyPath]; // prune the related object

    return objectWithFK;
}

/**
 * sanitizes multiple foreign keys from a object to make it save worthy
 * @param {*} objectWithFK
 */
export function sanitizeObjectWithFKs(objectWithFK, propertyPaths) {
    if (!objectWithFK) return objectWithFK;

    for (let x = 0; x < propertyPaths.length; x++) {
        objectWithFK = sanitizeObjectWithFK(objectWithFK, propertyPaths[x], false);
    }

    return { ...objectWithFK };
}

/**
 * sanitizes the object to make it save worthy
 * @param {*} objectWithFK
 */
export function sanitizeObject(obj, fields) {
    if (!obj) return obj;

    let clone = { ...obj };

    for (let x = 0; x < fields.length; x++) {
        if (obj[fields[x]] !== undefined) delete obj[fields[x]];
    }

    return clone;
}

/**
 * sanitizes an array (removes nodes)
 */
export function sanitizeArray(array, onSanitizeItem) {
    const sanitized = [];
    for (let x = 0; x < array.edges.length; x++) {
        const item = onSanitizeItem ? onSanitizeItem({ ...array.edges[x].node }) : { ...array.edges[x].node };

        sanitized.push(item);
    }
    return sanitized;
}

/**
 * sanitizes an array (removes field from a node)
 */
export function sanitizeFieldFromArray(array, field) {
    return array.map(item => {
        delete item[field];
        return item;
    });
}

/**
 * flattens a booking object
 */
export const flattenBookableObjects = (fieldNames, data) => {
    fieldNames
        .map(fieldName => data[fieldName])
        .filter(bookingDataObject => !isNullOrUndefined(bookingDataObject))
        .forEach(bookingDataObject => {
            //get the array
            let bookableArray = [];
            if (bookingDataObject.length > 0) bookableArray = bookingDataObject;
            else if (bookingDataObject.edges && bookingDataObject.edges.length > 0)
                bookableArray = bookingDataObject.edges.map(({ node }) => node);
            else bookableArray = [bookingDataObject];

            //iterate over
            bookableArray.forEach(bookable => {
                if (bookable.BookingItems && bookable.BookingItems.edges) {
                    bookable.BookingItems = bookable.BookingItems.edges.map(
                        ({ node: { Product, Variation, ...item } }) => {
                            return {
                                ...item,
                                Product,
                                Variation,
                                ProductID: !!Product ? Product.ID : null,
                                VariationID: !!Variation ? Variation.ID : null
                            };
                        }
                    );
                } else if (isNullOrUndefined(bookable.BookingItems)) {
                    bookable.BookingItems = [];
                }
            });
        });
};

export const prepareBookingsForSave = (rawBookings, eventType = null, funeralID = null) => {
    return rawBookings.map(b => {
        const item = { ...b };
        item.ContactID = item.Contact ? item.Contact.ID : null;
        if (eventType && funeralID) item[eventType + 'VenueID'] = funeralID; // attach booking to funeral
        delete item['Responded'];
        delete item['Responses'];
        delete item['Contact'];
        if (item.BookingItems)
            item.BookingItems = (!!item.BookingItems.edges ? item.BookingItems.edges.map(e => e.node) : item.BookingItems).map(x => {
                return {
                    ID: x.ID || null,
                    ProductID: x.ProductID,
                    Quantity: x.Quantity,
                    VariationID: x.VariationID,
                    Returns: x.Returns,
                    Comment: x.Comment
                };
            });
        return item;
    });
};

const prepareVenueForSave = (saveObj, stateObj, eventType, funeralID) => {
    saveObj.LocationID = !!stateObj.Location ? stateObj.Location.ID : null;
    delete saveObj.Location;
    delete saveObj.EventConflicts;
    if (saveObj.Bookings) {
        saveObj.Bookings = prepareBookingsForSave(stateObj.Bookings, eventType, funeralID);
    }
    if (stateObj.StaffAllocations) {
        const eventID = stateObj.ID || null;
        saveObj.StaffAllocations = stateObj.StaffAllocations.map(obj =>
            sanitizeAndAppendFuneralData(eventType, funeralID, eventID, obj)
        );
    }
    return saveObj;
};

export const prepareServiceForSave = (saveData, stateData) => {
    if (isNullOrUndefined(saveData.PlaceOfService.ID))
        saveData.PlaceOfService.ID = stateData.PlaceOfService.ID || stateData.PlaceOfServiceID || null;
    prepareVenueForSave(saveData.PlaceOfService, stateData.PlaceOfService, 'Service', stateData.ID);
    if (['No Service No Attendance', 'Graveside'].indexOf(stateData.PlaceOfService.Type) > -1) {
        saveData.PlaceOfService.LocationID = null;
        if (stateData.PlaceOfService.Bookings)
            saveData.PlaceOfService.Bookings = stateData.PlaceOfService.Bookings.filter(b => !!b.ID).map(b => {
                return { ID: b.ID, Cancelled: true };
            });
    }
};

export const prepareDisposalForSave = (saveData, stateData) => {
    saveData.Disposal.FuneralID = stateData.ID;
    prepareVenueForSave(saveData.Disposal, stateData.Disposal, 'Committal', stateData.ID);
    if (saveData.Disposal.DisposalBookingItems) {
        if (saveData.Disposal.DisposalBookingItems.length > 0) {
            saveData.Disposal.DisposalBookingItems = saveData.Disposal.DisposalBookingItems.map(x => {
                return {
                    ID: x.ID || null,
                    ProductID: x.ProductID,
                    Quantity: x.Quantity,
                    VariationID: x.VariationID,
                    Returns: x.Returns,
                    Comment: x.Comment
                };
            });
        }
    }
};

export const prepareViewingsForSave = (saveData, stateData) => {
    saveData.PlaceOfViewingItems = stateData.PlaceOfViewingItems.map(stateViewing => {
        const saveViewing = { ...stateViewing };
        return prepareVenueForSave(saveViewing, stateViewing, 'Viewing', stateData.ID);
    });
};

export const prepareRefreshmentsForSave = (saveData, stateData) => {
    if (stateData.RefreshmentsVenue.Type === 'Not Required') {
        saveData.RefreshmentsVenue.OffsiteAddressLine1 = null;
        saveData.RefreshmentsVenue.OffsiteSuburb = null;
        saveData.RefreshmentsVenue.OffsiteState = null;
        saveData.RefreshmentsVenue.OffsiteCountry = null;
        saveData.RefreshmentsVenue.OffsitePostCode = null;
        saveData.RefreshmentsVenue.LocationID = null;
        saveData.RefreshmentsVenue.Location = null;
        if (stateData.RefreshmentsVenue.Bookings)
            saveData.RefreshmentsVenue.Bookings = stateData.RefreshmentsVenue.Bookings.filter(b => !!b.ID).map(b => {
                return { ID: b.ID, Cancelled: true };
            });
        saveData.RefreshmentsVenue.StaffAllocations = [];
        saveData.RefreshmentsVenue.CateringStaffAllocations = [];
    } else {
        prepareVenueForSave(saveData.RefreshmentsVenue, stateData.RefreshmentsVenue, 'Refreshments', stateData.ID);
        if (stateData.RefreshmentsVenue.Type === 'Venue') {
            saveData.RefreshmentsVenue.OffsiteAddressLine1 = null;
            saveData.RefreshmentsVenue.OffsiteSuburb = null;
            saveData.RefreshmentsVenue.OffsiteState = null;
            saveData.RefreshmentsVenue.OffsiteCountry = null;
            saveData.RefreshmentsVenue.OffsitePostCode = null;
        } else {
            saveData.RefreshmentsVenue.LocationID = null;
        }
        if (saveData.RefreshmentsVenue.CateringStaffAllocations) {
            saveData.RefreshmentsVenue.CateringStaffAllocations = stateData.RefreshmentsVenue.CateringStaffAllocations.map(
                e => sanitizeCateringStaffAllocation(stateData.RefreshmentsVenue.ID, e)
            );
        }
    }
};

export const cancelAllBookings = (rawBookings = []) => {
    // Remove bookings: cancel if supplier already confirmed, cancelled or contacted, otherwise delete.
    return rawBookings
        .map(obj => {
            if (obj.Confirmed || obj.Cancelled) return { ...obj, Cancelled: true };
            const responses = (obj.Responses && (obj.Responses.edges || obj.Responses)) || [];
            if (responses.length > 0) return { ...obj, Cancelled: true };
            return null;
        })
        .filter(e => !!e);
};

/* Use this hardcoded value to link to addressbook for Mountain View Crematorium
 * TODO: replace with graphql values from either SiteConfig or CurrentUser
 * */
export const MVC_ID = '6463';
export const Default_Catering_ID = '8265';
