import React, { Fragment } from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles/index';
import { compose } from 'react-apollo/index';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import {
    getAppointmentOptionType,
    getCalendarCategoryByClassName,
    getCalendarCategoryByType
} from './CalendarConstants';
import { withEditAppointment } from '../../context/EditAppointment';
import LinkButton from '../../component/form/LinkButton';
import GatedComponent from '../../component/GatedComponent';
import DeleteIcon from '../../component/icon/DeleteIcon';
import { FUNERAL_HOMES, getFuneralHomeByKey } from '../../util/funerals';
import { isRelatedObjectDefined } from '../../util/bookable';
import { joinDefined } from '../../util/strings';
import { getCalendarClient } from '../../apollo';

const deleteAppointment = gql`
    mutation deleteAppointment($IDs: [ID]!) {
        deleteAppointment(IDs: $IDs) {
            ID
        }
    }
`;

const deleteCalendarEvent = gql`
    mutation deleteCalendarEvent($IDs: [ID]!) {
        deleteCalendarEvent(IDs: $IDs) {
            ID
        }
    }
`;

const deleteStaffAllocation = gql`
    mutation deleteStaffAllocation($IDs: [ID]!) {
        deleteStaffAllocation(IDs: $IDs) {
            ID
        }
    }
`;

class ViewEvent extends React.Component {
    state = {
        anchorEl: null,
        anchorLeft: 0,
        anchorTop: 0
    };

    render() {
        const { date, event, classes, view } = this.props;
        const { anchorEl } = this.state;
        const open = !!anchorEl;
        const category =
            view === 'Locations'
                ? getCalendarCategoryByClassName(event.ClassName)
                : getCalendarCategoryByType(event.Type);
        let times = { slot: '', popup: <span>Until {moment(event.end).format('LL')} </span> };
        let isAllDay = this.isAllDay(event);

        if (isAllDay === null) {
            // single day event, with times
            times.slot = times.popup = (
                <span>
                    {moment(event.start).format('H:mm')} - {moment(event.end).format('H:mm')}{' '}
                </span>
            );
        } else if (isAllDay === false) {
            // multi-day event, with times
            if (moment(event.start).isSame(date, 'day'))
                times.slot = <span>From {moment(event.start).format('H:mm')} </span>;
            else if (moment(event.end).isSame(date, 'day'))
                times.slot = <span>Until {moment(event.end).format('H:mm')} </span>;
            times.popup = (
                <span>
                    {moment(event.start).format('LT')} - {moment(event.end).format('LT L')}{' '}
                </span>
            );
        } else {
            // all-day event, could be multi-day
            if (
                moment(event.start).isSame(event.end, 'day') ||
                moment(event.start).isSame(
                    moment(event.end)
                        .subtract(1, 'day')
                        .startOf('day')
                )
            ) {
                times.popup = <span>All Day </span>;
            }
        }

        const theOffice =
            ['PreFuneral', 'Appointment', 'Arrangement'].indexOf(category.type) > -1
                ? FUNERAL_HOMES.find(obj => obj.LegacyKey === event.Appointment.Office)
                : (isRelatedObjectDefined(event.Funeral) && getFuneralHomeByKey(event.Funeral.LegacyKey)) || '';

        const theTitle =
            view === 'Locations'
                ? (event.Funeral && joinDefined([event.Funeral.FirstName, event.Funeral.Surname], ' ')) || event.Title
                : (event.Appointment && event.Appointment.Reason) || event.Title;

        return (
            <Fragment>
                {view === 'Locations' ? (
                    <Fragment>
                        <div
                            onClick={this.handlePopoverOpen}
                            style={{
                                borderLeft: '3px solid ' + category.color,
                                backgroundColor: category.backgroundColor + 'AA'
                            }}
                        >
                            <div className={'category'} style={{ color: category.color }}>
                                {category.label}
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div
                            onClick={this.handlePopoverOpen}
                            style={{
                                borderLeft: '3px solid ' + category.color,
                                background: `linear-gradient(${category.backgroundColor},${category.backgroundColor +
                                    'AA'})`
                            }}
                        >
                            <div className={'category'} style={{ color: category.color }}>
                                {category.label}
                            </div>
                            <div className={'title'}>
                                {event.Funeral && event.Funeral.LegacyKey ? `${event.Funeral.LegacyKey} | ` : ''}
                                {theTitle}
                            </div>
                            <div className={'details'}>
                                {moment(event.Start).format('h:mma')} - {moment(event.End).format('h:mma')}
                                <br />
                                {event.LocationFlattened}
                            </div>
                        </div>
                    </Fragment>
                )}
                <Popover
                    elevation={0} // applied to 'cal-card' instead
                    marginThreshold={24}
                    className={classes.popover}
                    open={open}
                    onClose={this.handlePopoverClose}
                    anchorEl={anchorEl}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: this.state.anchorTop, left: this.state.anchorLeft }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <div className={'cal-card'}>
                        <div className={'cal-card-head'} style={{ backgroundColor: category.color }}>
                            <GatedComponent showComponentCode={'FM_ACCESS_Appointments_Delete'}>
                                {() => {
                                    // canCancel if just an appointment
                                    const canCancel =
                                        !category.type ||
                                        ['PreFuneral', 'Unavailable', 'Appointment', 'VenueUnavailable'].indexOf(
                                            category.type
                                        ) > -1;

                                    // canDestroy the staff allocation if we lost the calendar event
                                    const canDestroyLostAllocation =
                                        !canCancel &&
                                        view !== 'Locations' &&
                                        !(
                                            getAppointmentOptionType(event.Type) === 'StaffAllocation' &&
                                            [
                                                'Service',
                                                'Viewing',
                                                'Disposal',
                                                'Refreshments',
                                                'ReflectionRoom'
                                            ].find(e => Number(event[e] && Number(event[e].ID)))
                                        );

                                    return (
                                        (canCancel || canDestroyLostAllocation) && (
                                            <span
                                                className={'cal-card-del'}
                                                title={'Delete'}
                                                onClick={e => {
                                                    if (event.Appointment && Number(event.Appointment.ID))
                                                        // cancel appointment
                                                        this.handleDeleteAppointment(event.Appointment.ID);
                                                    else if (!canDestroyLostAllocation)
                                                        // cancel calendarevent
                                                        this.handleDeleteCalendarEvent(event.ID);
                                                    // delete staff allocation because lost calendar event
                                                    else this.handleDeleteStaffAllocation(event.ID);
                                                }}
                                            >
                                                <DeleteIcon style={{ width: 18, height: 18 }} />
                                            </span>
                                        )
                                    );
                                }}
                            </GatedComponent>
                            <span className={'cal-card-close'} title={'Close'} onClick={this.handlePopoverClose}>
                                <Icon>cancel</Icon>
                            </span>

                            <Typography>
                                <small style={{ color: '#ffffffAA' }}>
                                    {category.label}
                                    {theOffice && ` - ${theOffice.label}`}
                                </small>
                            </Typography>
                            <Typography style={{ fontSize: '1.125rem' }}>
                                {(!!event.Funeral && !!event.Funeral.LegacyKey && (
                                    <span>
                                        <LinkButton
                                            title={'View Record'}
                                            variant="link"
                                            classes={{
                                                //root: classes.moveLink,
                                                enabled: classes.niceLink
                                            }}
                                            text={event.Funeral.LegacyKey}
                                            href={this.viewRecordURL(event, category)}
                                            onClick={e => this.props.history.push(this.viewRecordURL(event, category))}
                                        />
                                        {' | '}
                                    </span>
                                )) ||
                                    (!!event.Appointment &&
                                        !!event.Appointment.Enquiry &&
                                        !!event.Appointment.Enquiry.Funeral &&
                                        !!event.Appointment.Enquiry.Funeral.LegacyKey && (
                                            <span>
                                                <LinkButton
                                                    title={'View Record'}
                                                    variant="link"
                                                    classes={{
                                                        //root: classes.moveLink,
                                                        enabled: classes.niceLink
                                                    }}
                                                    text={event.Appointment.Enquiry.Funeral.LegacyKey}
                                                    href={this.viewRecordURL(event.Appointment.Enquiry, category)}
                                                    onClick={e =>
                                                        this.props.history.push(
                                                            this.viewRecordURL(event.Appointment.Enquiry, category)
                                                        )
                                                    }
                                                />
                                                {' | '}
                                            </span>
                                        ))}
                                {theTitle}
                            </Typography>
                        </div>
                        <GatedComponent isEnabledCode={'FM_ACCESS_Appointments_Edit'}>
                            {isEnabled => {
                                if (event.Type === 'ReflectionRoom' || event.Type === 'AshCollection')
                                    isEnabled = false; // don't edit CM appointments
                                return (
                                    <Fab
                                        size="small"
                                        title={!!isEnabled ? 'Edit' : 'Cannot edit'}
                                        className={classes.edit}
                                        disabled={!isEnabled}
                                        style={{ backgroundColor: category.color }}
                                        onClick={e => (!!isEnabled ? this.handleEdit(e) : null)}
                                    >
                                        <Icon>{!isEnabled ? 'lock' : 'edit'}</Icon>
                                    </Fab>
                                );
                            }}
                        </GatedComponent>
                        <div className={'cal-card-body'}>
                            <Typography className={classes.cardtext}>
                                <Icon className={classes.cardicon} style={{ color: category.color }}>
                                    access_time
                                </Icon>
                                {moment(event.Start).format('dddd, D MMMM Y')}
                                <br />
                                {moment(event.Start).format('h:mma')}
                                {' - '}
                                {moment(event.End).format('h:mma')}
                                {!moment(event.Start).isSame(moment(event.End), 'day') && (
                                    <small>{moment(event.End).format(' dddd, D MMMM Y')}</small>
                                )}
                            </Typography>
                            {event.LocationFlattened &&
                                (!event.Appointment || event.Appointment.LocationType !== 'Phone') && (
                                    <Typography className={classes.cardtext}>
                                        <Icon className={classes.cardicon} style={{ color: category.color }}>
                                            map
                                        </Icon>
                                        {event.LocationFlattened}
                                    </Typography>
                                )}
                            {event.Appointment && event.Appointment.Phone && (
                                <Typography className={classes.cardtext}>
                                    <Icon className={classes.cardicon} style={{ color: category.color }}>
                                        phone
                                    </Icon>
                                    {event.Appointment.Phone}
                                </Typography>
                            )}
                            {this.renderPeople(event, category, classes)}
                            <Typography className={classes.cardtext} style={{ marginLeft: -32 }}>
                                <small>
                                    {isRelatedObjectDefined(event.Appointment)
                                        ? event.Appointment.Comment
                                        : event.Allocation || event.CemeterySection || event.Comment || event.Notes}
                                </small>
                            </Typography>
                        </div>
                    </div>
                </Popover>
            </Fragment>
        );
    }

    renderPeople = (event, category, classes) => {
        if (this.props.view && this.props.view === 'Locations') {
            return (
                event.StaffAllocations &&
                event.StaffAllocations.map((allocation, imy) => {
                    const desc = allocation.Allocation;
                    let name = '';
                    let icon = 'check_circle_outline';
                    if ((allocation.Member && allocation.Member.FirstName) || allocation.Member.Surname) {
                        name = allocation.Member.FirstName + ' ' + allocation.Member.Surname + ' ';
                        icon = 'person';
                    }

                    let start = null;
                    let end = null;
                    if (allocation.Start !== allocation.End) {
                        start = moment(allocation.Start).format('h:mma');
                        end = moment(allocation.End).format('h:mma');
                    }

                    return (
                        <Typography className={classes.cardtext} key={'allocation_' + imy}>
                            <Icon className={classes.cardicon} style={{ color: category.color }}>
                                {icon}
                            </Icon>
                            {name}
                            <small>
                                {start && end && `${start} - ${end} `}
                                {desc && ' | ' + desc}
                            </small>
                        </Typography>
                    );
                })
            );
        } else {
            const others =
                (!!event.Appointment &&
                    Number(event.Appointment.ID) &&
                    (event.Appointment.Members.length > 0
                        ? event.Appointment.Members.filter(e => e.ID !== event.Member.ID)
                        : [])) ||
                (event[category.type] &&
                    event[category.type].StaffAllocations &&
                    event[category.type].StaffAllocations.filter(e => e.Member.ID !== event.Member.ID).map(
                        e => e.Member
                    )) ||
                [];

            return (
                <Fragment>
                    <Typography className={classes.cardtext}>
                        <Icon className={classes.cardicon} style={{ color: category.color }}>
                            person
                        </Icon>
                        {event.Member.FirstName} {event.Member.Surname}
                    </Typography>
                    {others.length > 0 && (
                        <Typography className={classes.cardtext}>
                            <Icon className={classes.cardicon} style={{ color: category.color }}>
                                people
                            </Icon>
                            <small>
                                with
                                {others.map(
                                    (eventMember, i) =>
                                        (i === 0 ? ' ' : ', ') + `${eventMember.FirstName} ${eventMember.Surname}`
                                )}
                            </small>
                        </Typography>
                    )}
                </Fragment>
            );
        }
    };

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.target, anchorTop: event.clientY, anchorLeft: event.clientX });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    handleDeleteAppointment = eventID => {
        if (!window.confirm('Are you sure you want to delete this appointment?')) return null;
        this.doDelete(eventID, deleteAppointment);
    };

    handleDeleteCalendarEvent = eventID => {
        if (!window.confirm('Are you sure you want to delete this event?')) return null;
        this.doDelete(eventID, deleteCalendarEvent);
    };

    handleDeleteStaffAllocation = eventID => {
        if (!window.confirm('Are you sure you want to delete this lost allocation?')) return null;
        this.doDelete(eventID, deleteStaffAllocation);
    };

    doDelete = async (eventID, mutation) => {
        const me = this;
        const deletion = await getCalendarClient()
            .mutate({ mutation: mutation, variables: { IDs: [eventID] } })
            .then(data => {
                const { onMutate } = me.props;
                if (onMutate) onMutate();
                me.handlePopoverClose();
            });
        return !!deletion;
    };

    isAllDay = event => {
        if (
            moment(event.start).valueOf() ===
                moment(event.start)
                    .startOf('day')
                    .valueOf() &&
            moment(event.end).valueOf() ===
                moment(event.end)
                    .startOf('day')
                    .valueOf()
        ) {
            // all-day event. could be multiday.
            return true;
        } else if (!moment(event.start).isSame(event.end, 'day')) {
            // multi-day event, but with start & end times.
            return false;
        } else {
            // single day event with start & end times
            return null;
        }
    };

    handleEdit(e) {
        const { event, editCalendarAllocationsById, editAppointmentById, editCalendarEventById } = this.props;

        if (this.props.view === 'Locations') {
            if (event.__typename === 'CalendarEvent') editCalendarEventById(event.ID);
            else editCalendarAllocationsById(event.ID);
        } else {
            if (getAppointmentOptionType(event.Type) === 'StaffAllocation') {
                const eventType = ['Service', 'Viewing', 'Disposal', 'Refreshments', 'ReflectionRoom'].find(e =>
                    Number(event[e].ID)
                );
                if (eventType) editCalendarAllocationsById(event[eventType].ID);
            } else {
                editAppointmentById(event.ID);
            }
        }

        this.handlePopoverClose(e);
    }

    viewRecordURL = (event, category) => {
        const url = '/funeral/' + event.Funeral.LegacyKey + '/' + event.Funeral.ID;
        const tab = category.type === 'Refreshments' ? 'optionals' : 'funeral-details';
        return url + '/' + tab;
    };
}

const styles = () => ({
    popover: {
        '& > div': {
            margin: '-1rem',
            backgroundColor: 'transparent'
        }
    },
    edit: {
        position: 'absolute',
        margin: '-1.25rem 0.75rem',
        color: 'white'
    },
    cardtext: {
        margin: '0.5rem 0'
    },
    cardicon: {
        position: 'absolute',
        margin: '1px 0 0 -1.88rem',
        fontSize: '20px'
    },
    moveLink: {
        fontSize: '0.875rem',
        marginTop: -20,
        position: 'absolute',
        right: '1.75rem'
    },
    niceLink: {
        '& > span': {
            color: '#ffffff'
        },
        '& > span:hover': {
            color: '#ffffffAA'
        }
    }
});

export default compose(withRouter, withEditAppointment, withStyles(styles))(ViewEvent);
