import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import AdminPerformanceIndividual from './components/AdminPerformanceIndividual';
import YearlyIncentives from './components/YearlyIncentives';
import AverageRating from './components/AverageRating';
import NetPromoterScore from './components/NetPromoterScore';
import PerformanceGoals from './components/PerformanceGoals';
import withRouter from 'react-router/withRouter';

class MetricDashboard extends Component {
    state = {};

    render() {
        return (
            <Fragment>
                {this.renderYearlyIncentives()}
                {this.renderAdminPerformanceIndividual()}
                {this.renderAdminPerformanceTeam()}
                {this.renderAverageRating()}
                {this.renderNetPromoterScore()}
                {this.renderPerformanceGoals()}
            </Fragment>
        );
    }

    renderYearlyIncentives() {
        return <YearlyIncentives period={this.props.period} />;
    }

    renderAdminPerformanceIndividual() {
        return <AdminPerformanceIndividual variant="individual" period={this.props.period} />;
    }

    renderAdminPerformanceTeam() {
        return <AdminPerformanceIndividual variant="company" period={this.props.period} />;
    }
    renderAverageRating() {
        return <AverageRating period={this.props.period} />;
    }
    renderNetPromoterScore() {
        return <NetPromoterScore period={this.props.period} />;
    }
    renderPerformanceGoals() {
        return <PerformanceGoals period={this.props.period} />;
    }
}

const styles = ({ palette, typography, funeralHome }) => ({
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    }
});
export default compose(withRouter, withStyles(styles))(MetricDashboard);
