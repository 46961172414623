import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from './TextField';
import Grid from './Grid';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core';
import Select from './Select';

class CoronerModal extends Component {
    static defaultProps = {
        title: 'Enter Coroner details',
        onClose: undefined
    };

    state = {
        CoronerID: undefined
    };

    getId() {
        return `CoronerModal-${this.props.title}`;
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onCancel = () => {
        const { onClose } = this.props;
        if (onClose) onClose();

        this.clear();
    };

    clear = () => {
        const clearedState = Object.keys(this.state).reduce((acc, key) => {
            acc[key] = undefined;
            return acc;
        }, {});
        this.setState(clearedState);
    };

    onSubmit = (mutate, loading) => e => {
        e.preventDefault();
        if (loading) return;
        mutate({
            variables: {
                input: {
                    ...this.state
                }
            }
        });

        const coroner = { ...this.state };
        const { onSubmit } = this.props;
        if (onSubmit) onSubmit(coroner);
    };

    onCompleted = ({ createCoroner: coroner }) => {
        const { form, name, onClose } = this.props;
        if (onClose) onClose(coroner);
        if (form) form.setField({ [`${name}`]: { ID: coroner.ID, Name: coroner.Name } });
        this.clear();
    };

    onSelectCoroner = (e, item) => {
        const coroner = { ...item };
        this.setState(coroner);
        e.preventDefault();
    };

    onSelectCoronerType = item => {
        this.setState({ Type: item.target.value });
    };

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.onClose} aria-labelledby={this.getId()}>
                <Mutation mutation={createCoroner} onCompleted={this.onCompleted}>
                    {this.renderForm}
                </Mutation>
            </Dialog>
        );
    }

    renderForm = (mutate, { loading }) => {
        // const { title, classes } = this.props;
        const { title } = this.props;
        const coroner = { ...this.state };
        return (
            <form onSubmit={this.onSubmit(mutate, loading)}>
                <DialogTitle id={this.getId()}>{title}</DialogTitle>

                <DialogContent>
                    {this.props.category && <span>New {this.props.category.label}</span>}
                    <Grid container spacing={24}>
                        {/*<Grid item xs={12} sm={3}>*/}
                        {/*<CoronerAutoComplete*/}
                        {/*className={classes.alignWithFloatingLabel}*/}
                        {/*name="CoronerID"*/}
                        {/*onSelect={this.onSelectCoroner}*/}
                        {/*placeholder="Coroner ID"*/}
                        {/*limit={5}*/}
                        {/*labelField={'ID'}*/}
                        {/*valueField={'ID'}*/}
                        {/*value={coroner}*/}
                        {/*/>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={6}>
                            <Select
                                // className={classes.alignWithFloatingLabel}
                                options={CORONER_TYPE_OPTIONS}
                                name="Type"
                                value={coroner.Type}
                                onChange={this.onSelectCoronerType}
                                label="Coroner Type"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.renderField('District', 'District')}
                        </Grid>

                        <Grid item xs={12}>
                            {this.renderField('Name', 'Full Name', true, true)}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.renderField('Mobile', 'Mobile')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.renderField('Phone', 'Phone')}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={loading ? undefined : this.onCancel} color="primary" disabled={loading}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={loading}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        );
    };

    renderField(name, label, autoFocus, required) {
        return (
            <TextField
                label={label}
                name={name}
                autoFocus={autoFocus}
                // margin="dense"
                required={required}
                value={this.state[name] || ''}
                onChange={this.onChange}
            />
        );
    }
}

const createCoroner = gql`
    mutation CreateCoroner($input: CoronerCreateInputType!) {
        createCoroner(Input: $input) {
            ID
            Created
            LegacyID
            LegacyKey
            Name
            District
            Type
            Phone
            Mobile
        }
    }
`;

const CORONER_TYPE_OPTIONS = [
    { label: 'Assistant Coroner', value: 'Assistant Coroner' },
    { label: 'Coroner', value: 'Coroner' },
    { label: 'Deputy State', value: 'Deputy State' },
    { label: 'State Coroner', value: 'State Coroner' }
];

const styles = () => ({
    alignWithFloatingLabel: {
        marginTop: '22px'
    }
});

export default withStyles(styles)(CoronerModal);
