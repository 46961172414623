import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const styles = ({ palette, funeralHome }) => ({
    table: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        fontSize: '0.875rem',
        width: '100%',
        '& th, & td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px'
        },
        '& th:last-child, & td:last-child': {
            borderWidth: '1px 1px 0px 1px'
        },
        '& tr:last-child th, & tr:last-child td': {
            borderWidth: '1px 0px 1px 1px'
        },
        '& tr:last-child th:last-child, & tr:last-child td:last-child': {
            borderWidth: '1px'
        }
    },

    tableHeaderRow: {
        '& th': {
            background: palette.basePrimary[funeralHome],
            border: '1px solid ' + palette.basePrimary[funeralHome],
            padding: '0.625rem 0.5rem',
            color: palette.table.header.foregroundColor,
            fontSize: '0.875rem',
            fontWeight: '500',
            textAlign: 'left'
        },
        '& th:first-child': {
            borderTopLeftRadius: '6px'
        },
        '& th:last-child': {
            borderTopRightRadius: '6px'
        },
        '& td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            fontSize: '0.875rem;',
            padding: '0px 0.5rem',
            borderColor: palette.table.border
        }
    },

    tableSecondaryHeaderRow: {
        fontVariant: 'all-small-caps',
        '& th': {
            background: palette.table.headerSecondary.backgroundColor,
            borderColor: palette.table.border,
            padding: '0.625rem 0.5rem',
            color: palette.table.headerSecondary.foregroundColor,
            fontSize: '0.875rem',
            fontWeight: '500',
            textAlign: 'left'
        },
        '& th:first-child': {
            borderTopLeftRadius: '0px'
        },
        '& th:last-child': {
            borderTopRightRadius: '0px'
        }
    },

    tableRow: {
        '&:nth-child(2n+1)': { background: palette.table.row.backgroundColor },
        '&:nth-child(2n)': { background: palette.table.row.backgroundAltColor },
        '& td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            fontSize: '0.875rem;',
            padding: '0px 0.5rem',
            borderColor: palette.table.border
        },
        '&:nth-child(n):hover': {
            backgroundColor: palette.table.row.hoverColor
            // cursor: 'pointer'
        }
    },

    clickRow: {
        '&:hover': {
            cursor: 'pointer'
        }
    },

    tableNestedRow: {
        '& td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            borderColor: palette.table.border,
            background: palette.table.nestedRow.backgroundColor
        },
        '& td:last-child:first-child': {
            borderWidth: '1px 1px 0px 1px'
        },
        '& td:last-child': {
            borderWidth: '1px 1px 0px 0px'
        },
        '& tr:last-child td': {
            borderWidth: '1px 0px 1px 1px'
        },
        '& tr:last-child td:last-child': {
            borderWidth: '0px 1px 1px 0px '
        },
        '& td:first-child': {
            padding: '0.5rem 0.5rem 0.5rem 1.5rem'
        },
        '&:hover td': {
            backgroundColor: palette.table.nestedRow.hoverColor
        }
    },

    paddedRow: {
        '& td': {
            padding: '0.5rem'
        }
    },

    colXs: {
        width: '60px'
    },

    colSm: {
        width: '80px'
    },

    colMd: {
        width: '130px'
    }
});

/**
 * Table
 * see the sampleTableData object to see what data needs to look like
 * @param {*} param0
 */

const Table = withStyles(styles)(({ children, ...props }) => {
    const { classes, containerClassName, className, columns, columnspans } = props;

    return (
        <div className={cx(classes.containerClassName, containerClassName)}>
            <table className={cx(classes.table, className)}>
                {!!columns && (
                    <thead>
                        <TableHeaderRow>
                            {columns.map((column, columnIndex) => (
                                <TableHeaderCell
                                    colSpan={(!!columnspans && columnspans[columnIndex]) || null}
                                    key={columnIndex}
                                >
                                    {column}
                                </TableHeaderCell>
                            ))}
                        </TableHeaderRow>
                    </thead>
                )}
                <tbody>{children}</tbody>
            </table>
        </div>
    );
});

const TableHeaderRow = withStyles(styles)(({ pad, children, classes, className }) => {
    return (
        <tr
            className={
                pad ? cx(classes.tableHeaderRow, classes.paddedRow, className) : cx(classes.tableHeaderRow, className)
            }
        >
            {children}
        </tr>
    );
});

const TableSecondaryHeaderRow = withStyles(styles)(({ pad, children, classes }) => {
    return (
        <tr className={pad ? cx(classes.tableSecondaryHeaderRow, classes.paddedRow) : classes.tableSecondaryHeaderRow}>
            {children}
        </tr>
    );
});

const TableRow = withStyles(styles)(({ pad, children, classes, className, onClick, onDragOver = null, onDragLeave = null, onDrop = null }) => {
    return (
        <tr
            className={cx(classes.tableRow, pad && classes.paddedRow, onClick && classes.clickRow, className)}
            
            onClick={onClick}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            {children}
        </tr>
    );
});

const TableNestedRow = withStyles(styles)(({ pad, children, classes }) => {
    return <tr className={pad ? cx(classes.tableNestedRow, classes.paddedRow) : classes.tableNestedRow}>{children}</tr>;
});

const TableCell = withStyles(styles)(({ className, pad, tiny = false, children, colSpan, centred = false }) => {
    const style = {};
    if (pad) style.padding = '8px';
    if (tiny) style.width = '1px';
    if (centred) style.textAlign = 'center';
    return (
        <td className={className} style={style} colSpan={colSpan}>
            {children}
        </td>
    );
});

const TableHeaderCell = withStyles(styles)(({ className, pad, children, colSpan }) => {
    return (
        <th className={className} style={pad ? { padding: '8px' } : {}} colSpan={colSpan}>
            {children}
        </th>
    );
});

export default Table;
export { TableHeaderRow, TableSecondaryHeaderRow, TableRow, TableNestedRow, TableCell, TableHeaderCell };
