import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FUNERAL_HOME } from '../../util/funerals';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import TaskCard from './components/TaskCard';
import TaskCardContainer from './components/TaskCardContainer';
import CreateEditTaskModal from './taskModal/CreateEditTaskModal';
import ViewTaskModal from '../workQueue2/Modals/ViewTaskModal';
import FilterTasksPopover from './taskModal/FilterTasksPopover';
import { applyUrlParams } from '../../util/strings';
import { withRouter } from 'react-router';
import { TAB_URL_SEGMENTS } from './components/TaskConstants';
import { createOrUpdateTaskFunc, getUserTasksFunc } from './components/GetSaveTasks';
import { getUser } from '../../util/sessions';
import cloneDeep from 'lodash.clonedeep';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import { withSnackbarMessage } from '../../context/SnackbarMessage';

class WorkQueue extends Component {
    state = {
        tasks: { edges: [] },
        filteredTasks: [],
        filters: {},
        showCreateEditTaskModal: false,
        loading: false,
        loadingTaskId: null
    };

    componentDidMount() {
        const user = getUser();
        this.setState({ user });
        this.loadTasks(
            user,
            {
                offset: 0,
                limit: 1000,
                isComplete: null,
                isStarred: null,
                isOverdue: null,
                isNew: null
            },
            true
        );
    }

    render() {
        const { classes } = this.props;
        const { modalTask, showCreateEditTaskModal, editTask, user, loading, loadingTaskId } = this.state;
        const { id, key } = this.props.match.params;

        return (
            <Fragment>
                <ViewTaskModal
                    onClose={() => this.setState({ modalTask: undefined })}
                    open={!!modalTask}
                    task={modalTask}
                    onSubmit={(task, mutate) => this.viewTaskOnSubmit(task, mutate)}
                    onEdit={task => this.viewTaskOnEdit(task)}
                    onClickAction={() => this.goToTask(modalTask)}
                />
                <CreateEditTaskModal
                    onClose={() => this.setState({ showCreateEditTaskModal: false })}
                    open={showCreateEditTaskModal}
                    task={editTask}
                    onSubmit={task => this.createOrUpdateTask(task)}
                    user={user}
                />
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <div>
                            <span>Work Queue</span>
                            <span className={classes.breadCrumb}>
                                {id && key ? `Viewing Tasks for Funeral ${key}` : 'Viewing Your Tasks'}
                            </span>
                        </div>
                        <Hidden mdUp>
                            {/*<PrimaryButton*/}
                            {/*onClick={() => this.setState({ showCreateEditTaskModal: true })}*/}
                            {/*className={classes.btnSm}*/}
                            {/*>*/}
                            {/*<AddIcon />*/}
                            {/*</PrimaryButton>*/}
                            <FilterTasksPopover onApply={filters => this.filterTasksPopoverOnApply(filters)} />
                        </Hidden>
                        <Hidden smDown>
                            {/*<PrimaryButton onClick={() => this.setState({ showCreateEditTaskModal: true })}>
                                Create New Task...
                            </PrimaryButton>*/}
                            <FilterTasksPopover onApply={filters => this.filterTasksPopoverOnApply(filters)} />
                        </Hidden>
                    </InlineFieldRightAlignChildren>
                </Typography>

                {loading && loadingTaskId === null ? (
                    <div>
                        <span>
                            <CircularProgress />
                        </span>
                    </div>
                ) : (
                    this.renderTasks()
                )}
            </Fragment>
        );
    }

    renderTasks() {
        const { classes } = this.props;
        const { id, key } = this.props.match.params;
        const { filteredTasks, tasks, loading, loadingTaskId } = this.state;
        const totalOutstanding = tasks.edges.filter(x => !x.node.Completed).length;
        const totalFiltered = (!!filteredTasks.edges && filteredTasks.edges.length) || 0;
        return (
            <div className={classes.section}>
                <Typography variant="title" align="left" className={classes.paperTitle} gutterBottom>
                    You have {totalOutstanding} {totalOutstanding === 1 ? 'task' : 'tasks'} outstanding
                    {id && key ? ' for this funeral.' : '.'}
                </Typography>
                <Typography align="left" gutterBottom>
                    {totalFiltered !== totalOutstanding && (
                        <span>
                            Filtered tasks:{' '}
                            <strong>
                                {totalFiltered} task{totalFiltered === 1 ? ' ' : 's '}
                            </strong>
                            matched the filters.
                        </span>
                    )}
                </Typography>
                <div className={classes.section}>
                    {!!filteredTasks.edges && (
                        <TaskCardContainer>
                            {filteredTasks.edges.map((task, i) => (
                                <TaskCard
                                    key={i}
                                    task={task}
                                    onClickAction={() => this.goToTask(task)}
                                    onClickShowModal={() => this.showTaskModal(task)}
                                    loading={loading}
                                    loadingTaskId={loadingTaskId}
                                    onChange={t => this.createOrUpdateTask(t)}
                                />
                            ))}
                        </TaskCardContainer>
                    )}
                </div>
            </div>
        );
    }

    loadTasks(user, filters = null, refresh = false) {
        this.setState({ loading: true });
        const that = this;
        const { id } = this.props.match.params;
        const applyFilters = this.state.filters;
        const tabUrlSegments = TAB_URL_SEGMENTS.filter(x => filters[x.value.replace('-', '')]).map(x => x.value);
        getUserTasksFunc(
            user.ID,
            filters.isComplete,
            filters.isStarred,
            filters.offset,
            filters.limit,
            tabUrlSegments,
            id,
            refresh
        ).then(
            originalTasks => {
                const tasks = cloneDeep(originalTasks);
                const filteredTasks = tasks.edges.filter(x => includeTask(x, applyFilters));
                that.setState({ tasks, filteredTasks: { edges: filteredTasks }, loading: false });
            },
            error => {
                that.onGqlError('Failed to load tasks.', error);
                that.setState({ loading: false });
            }
        );
    }

    onGqlError(action, error) {
        if (error) console.error(error);
        this.props.setSnackbarMessage(action, false, null, new Error(error));
    }

    goToTask(task, tabURLSegment) {
        const funeralPath =
            (tabURLSegment || task.node.TabURLSegment) === 'mortuary'
                ? '/mortuary/checkout/:key/:id'
                : '/funeral/:key/:id/' + (tabURLSegment || task.node.TabURLSegment);
        const path = applyUrlParams(funeralPath, {
            key: task.node.Funeral.LegacyKey,
            id: task.node.Funeral.ID
        });
        this.props.history.push(path);
    }

    showTaskModal(modalTask) {
        this.setState({ modalTask });
    }

    viewTaskOnSubmit(task, mutate) {
        this.createOrUpdateTask({
            node: {
                ...task.node,
                ...mutate
            }
        });
    }

    viewTaskOnEdit(task) {
        this.setState({
            modalTask: undefined,
            showCreateEditTaskModal: true,
            editTask: task
        });
    }

    filterTasksPopoverOnApply(filters) {
        const { tasks, filteredTasks } = this.state;

        filteredTasks.edges = tasks.edges.filter(x => includeTask(x, filters));
        this.setState({ filteredTasks, filters });
    }

    createOrUpdateTask(task) {
        const { tasks } = this.state;
        const existing = task.node.ID !== null ? tasks.edges.find(x => x.node.ID === task.node.ID) : null;
        const that = this;
        this.setState({
            loading: true,
            loadingTaskId: existing ? existing.node.ID : null
        });
        createOrUpdateTaskFunc(task, existing).then(
            mutation => {
                let result = null;
                if (mutation.data) {
                    if (mutation.data.updateTask) result = mutation.data.updateTask;
                    else if (mutation.data.createTask) result = mutation.data.createTask;
                    else throw new Error('unexpected task query: ', mutation.data);
                } else {
                    that.onGqlError('Um, server returned no data?');
                    return;
                }
                if (!!result) {
                    that.loadTasks(
                        that.state.user,
                        {
                            offset: 0,
                            limit: 1000,
                            isComplete: null,
                            isStarred: null,
                            isOverdue: null,
                            isNew: null
                        },
                        false
                    );
                }
                that.setState({
                    tasks,
                    editTask: null,
                    showCreateEditTaskModal: false,
                    loading: false,
                    loadingTaskId: null
                });
            },
            error => {
                that.setState({ loading: false, loadingTaskId: null });
                that.onGqlError('Failed to ' + (existing ? 'save' : 'create new') + ' task.', error);
            }
        );
    }
}

const includeTask = (task, filters) => {
    const now = new Date();
    const filterSections = [];
    const taskSection = !!task.node.TabURLSegment && task.node.TabURLSegment.replace('-', '');
    for (let x = 0; x < TAB_URL_SEGMENTS.length; x++) {
        const tab = TAB_URL_SEGMENTS[x].value.replace('-', '');
        if (!!filters[tab]) filterSections.push(tab);
    }

    const include =
        ((!!filters.isComplete && !!task.node.Completed) || (!filters.isComplete && !task.node.Completed)) && // always hide completed, unless specifically included
        ((!!filters.isStarred && !!task.node.Starred) || !filters.isStarred) &&
        ((!!filters.isNew && !moment(task.node.Created).isBefore(now, 'week')) || !filters.isNew) &&
        ((!!filters.isOverdue && !!task.node.Due && moment(task.node.Due).isBefore(now)) || !filters.isOverdue) &&
        (filterSections.length === 0 || (!!taskSection && -1 < filterSections.indexOf(taskSection)));

    return include;
};

const styles = ({ spacing, typography, palette }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode]
    },
    paperTitle: {},
    summaryText: {
        fontWeight: 'lighter',
        fontSize: typography.fontSizes.regular,
        '& > div': {
            lineHeight: '34px'
        }
    },
    section: {
        margin: '25px 0 0 0'
    },
    filterIcon: {
        width: '14px',
        height: '14px'
    },
    breadCrumb: {
        color: '#333',
        fontWeight: 300,
        marginLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        paddingLeft: 16
    },
    loading: {
        position: 'relative',
        float: 'right',
        transform: 'scale(0.5)',
        marginTop: '-20px'
    },

    btnSm: {
        padding: '13px',
        minWidth: '40px',
        '& > span': {
            margin: '0',
            '& > svg': {
                margin: '0'
            }
        }
    }
});

// prettier-ignore
export default compose(
    withRouter,
    withSnackbarMessage,
    withStyles(styles)
)(WorkQueue);
