import { getServiceURLHostname } from '../../apollo';
import DashboardIcon from '../icon/DashboardIcon';
import WorkQueueIcon from '../icon/WorkQueueIcon';
import ContactsIcon from '../icon/ContactsIcon';
import QuotesIcon from '../icon/QuotesIcon';
import FinancialIcon from '../icon/FinancialIcon';
import StockIcon from '../icon/StockIcon';
import UsersIcon from '../icon/UsersIcon';
import MortuaryIcon from '../icon/MortuaryIcon';
import PayableIcon from '../icon/PayableIcon';
import MetricDashboardIcon from '../metrics/icons/MetricDashboardIcon';
import NurseIcon from '../icon/NurseIcon';

/**
 * Nested list of items for the Drawer
 */
export const navigationItems = [
    {
        path: '/',
        label: 'Dashboard',
        icon: DashboardIcon,
        permission: 'FM_ACCESS_Dashboard',
        items: [
            {
                path: '/metrics',
                label: 'Performance Metrics',
                icon: MetricDashboardIcon,
                permission: 'FM_ACCESS_Metrics'
            }
        ]
    },
    {
        path: '/work-queue',
        label: 'Work Queue',
        icon: WorkQueueIcon,
        permission:
            'FM_ACCESS_Tasks_View' /*,
        items: [
            {
                path: '/work-queue-cards',
                label: 'Work Queue Cards',
                icon: WorkQueueIcon,
                permission: 'FM_ACCESS_Tasks_View'
            }
        ]*/
    },
    {
        path: '/contacts',
        label: 'Contacts',
        icon: ContactsIcon,
        permission: 'FM_ACCESS_Contacts_View'
    },
    {
        path: '/quotes',
        label: 'Enquiries',
        icon: PayableIcon,
        permission: 'FM_ACCESS_Enquiries'
    },
    {
        path: '/refereedoctor',
        label: 'Referee Doctor',
        icon: NurseIcon,
        permission: 'FM_ACCESS_RefereeDoctors_View'
    },
    {
        path: '/mortuary',
        label: 'Mortuary',
        icon: MortuaryIcon,
        permission: 'FM_ACCESS_Mortuary_View',
        items: [
            {
                path: '/mortuary/checkout',
                permission: 'FM_ACCESS_Mortuary_Deceased_Checkout_View',
                label: 'Checkout',
                icon: MortuaryIcon
            },
            {
                path: '/mortuary/coffins',
                permission: 'FM_ACCESS_Mortuary_Coffin_View',
                label: 'Coffin Distribution',
                icon: StockIcon
            }
        ]
    },
    // {
    //     path: '/music',
    //     label: 'Music',
    //     icon: MusicIcon,
    //     permission: 'FM_ACCESS_Music_View'
    // },
    {
        path: 'https://login.xero.com/',
        label: 'Invoicing (Xero)',
        icon: QuotesIcon,
        newTab: true,
        permission: 'FM_ACCESS_Invoicing'
    },
    {
        path: '//' + getServiceURLHostname() + '/admin/reports',
        label: 'Reports (CMS)',
        icon: FinancialIcon,
        newTab: true,
        permission: 'CMS_ACCESS_ReportAdmin'
    },
    {
        path: '//' + getServiceURLHostname() + '/admin/catalog',
        label: 'Stock (CMS)',
        icon: StockIcon,
        newTab: true,
        permission: 'CMS_ACCESS_SilverShop\\Admin\\ProductCatalogAdmin'
    },
    {
        path: '//' + getServiceURLHostname() + '/admin/security',
        label: 'Users (CMS)',
        icon: UsersIcon,
        newTab: true,
        permission: 'CMS_ACCESS_SecurityAdmin'
    },
    {
        path: '/orders',
        label: 'Online Orders',
        icon: PayableIcon,
        permission: 'FM_ACCESS_Enquiries'
    }
];

export const buildFlatList = items => {
    const itemsList = [];

    items.forEach(item => {
        itemsList.push(item);
        if (item.items) {
            buildFlatList(item.items);
        }
    });

    return itemsList;
};
