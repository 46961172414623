import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import RouteTabs from '../../component/RouteTabs';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import { applyUrlParams, joinDefined } from '../../util/strings';
import cx from 'classnames';
import { TABS } from './funeralConstants';
import DataForm from '../../component/form/DataForm';
import { SnappableLayout } from '../../component/ColumnLayout';
import PrimaryButton from '../../component/form/PrimaryButton';
import DocumentsIcon from '../../component/icon/DocumentsIcon';
import AdminSummary from './adminSummary/AdminSummary';
import { loadAdminSummary, saveAdminSummaryFuncAsync } from './adminSummary/GetSaveAdminSummary';
import Spinner from '../../component/Spinner';
import GatedComponent from '../../component/GatedComponent';
import DocumentsPopover from '../funeral/documents/DocumentsPopover';
import OnlineOrderPopover from '../arrangermanager/OnlineOrderPopover';
import ArrangementIcon from '../../component/icon/ArrangementIcon';
import InfoCircleIcon from '../../component/icon/InfoCircleIcon';
import Button from '../workQueue2/extras/Button';
import ClipboardIcon from '../../component/icon/ClipboardIcon';
import Hidden from '@material-ui/core/Hidden';
import RecordDrawer from '../workQueue2/RecordDrawer';
import Grid from '../../component/form/Grid';
import BiggerMessageBar from '../../component/BiggerMessageBar';
import Inline from '../workQueue2/extras/Inline';
import { readOnlineOrdersBtnQuery } from '../arrangermanager/OnlineOrdersConstants';
import { getMyClient } from '../../apollo';
import { getProperty } from '../../util/objects';

class Funeral extends React.Component {
    state = {
        showAdminSummary: false,
        documentsModalOpen: false,
        onlineorderModalOpen: false,
        adminData: { adminSummary: null, groupedIndices: null, original: null }
    };

    componentDidMount() {
        loadAdminSummary(this.props.match.params.id).then(adminData => this.setState({ adminData }));
    }

    getCurrentTab() {
        const { match, location } = this.props;
        return location.pathname.replace(match.url + '/', '');
    }

    render() {
        const { classes } = this.props;
        const { adminData, showAdminSummary } = this.state;

        return (
            <Grid container>
                {this.renderTitle()}

                <Paper className={classes.paper} elevation={0}>
                    <SnappableLayout
                        snappableComponent={
                            showAdminSummary ? (
                                <AdminSummary
                                    currentTab={this.getCurrentTab()}
                                    {...adminData}
                                    onClose={() => this.toggleAdminSummary()}
                                    onSave={e => this.saveAdminSummary()}
                                />
                            ) : null
                        }
                    >
                        <RouteTabs tabs={TABS}>{this.renderTabs}</RouteTabs>
                    </SnappableLayout>
                </Paper>
                <DocumentsPopover
                    open={!!this.state.documentsModalOpen}
                    onClose={() =>
                        this.setState({
                            documentsModalOpen: false,
                            appointmentDefaults: null,
                            editAppointmentId: null
                        })
                    }
                />
            </Grid>
        );
    }

    renderTitle() {
        const { classes, match } = this.props;
        const { key, id } = match.params;
        const { adminData } = this.state;
        return (
            <Grid pc={1}>
                <Inline>
                    {((id || key) && (
                        <Query query={nameQuery} variables={{ id, key }}>
                            {this.renderName}
                        </Query>
                    )) || (
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            Funeral
                            <div className={classes.keyline} />
                            Unknown?
                        </Typography>
                    )}

                    <PrimaryButton
                        href={applyUrlParams('/documentation/:key/:id/summary', this.props.match.params)}
                        target={'_blank'}
                        className={classes.primaryButton}
                    >
                        <ArrangementIcon />
                        <Hidden smDown> View Arrangement</Hidden>
                    </PrimaryButton>
                    <Query query={readOnlineOrdersBtnQuery} variables={{ key }} client={getMyClient()}>
                        {results => this.renderOnlineOrderButton(results)}
                    </Query>
                    <Button
                        variant={`primary icon-button white shadow ${classes.funeralIcon}`}
                        size="lg"
                        title="Generate Merge Documents"
                        onClick={() => this.toggleDocumentModal()}
                    >
                        <DocumentsIcon />
                    </Button>
                    <GatedComponent showComponentCode={'FM_ACCESS_Funeral_Summary_View'}>
                        {() => {
                            return (
                                <span className={classes.adminButton}>
                                    <Button
                                        variant={`primary icon-button white shadow ${classes.funeralIcon}`}
                                        size="lg"
                                        title="Funeral Checklist"
                                        onClick={() => this.toggleAdminSummary()}
                                        className={classes.funeralIcon}
                                    >
                                        <ClipboardIcon />
                                        <span>
                                            {!!adminData &&
                                                !!adminData.adminSummary &&
                                                ' ' +
                                                    Math.round(adminData.adminSummary.AdminChecksComplete * 100) +
                                                    '%'}
                                        </span>
                                    </Button>
                                </span>
                            );
                        }}
                    </GatedComponent>
                    <RecordDrawer funeralId={id} legacyKey={key} />
                </Inline>
                <br />
            </Grid>
        );
    }

    renderName = ({ loading, error, data }) => {
        const { classes } = this.props;
        const { FirstName, MiddleName, Surname, LegacyKey, Cancelled } = (data && data.readOneFuneral) || {};
        return (
            <Fragment>
                {Cancelled && <BiggerMessageBar messageType="error">This record is cancelled.</BiggerMessageBar>}
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    Funeral
                    <div className={classes.keyline} />
                    {LegacyKey}&nbsp;
                    <span className={cx(classes.nameTitle, !loading && classes.nameTitleActive)}>
                        {joinDefined([FirstName, MiddleName, Surname], ' ')}
                    </span>
                </Typography>
            </Fragment>
        );
    };

    renderTabs = selectedIndex => {
        const { fragment } = TABS[selectedIndex];
        const { id } = this.props.match.params;

        const query = gql`
            ${fragment}
            query LoadTabData($id: ID!) {
                readOneFuneral(ID: $id) {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                    MiddleName
                    LockFuneral
                    ...${fragment.definitions[0].name.value}
                }
            }
        `;

        return (
            <Query query={query} variables={{ id }}>
                {results => this.renderForm(selectedIndex, results)}
            </Query>
        );
    };

    renderForm = (selectedIndex, { loading, error, data, networkStatus, refetch }) => {
        const { classes } = this.props;
        const { id } = this.props.match.params;
        const { adminData } = this.state;
        const tab = TABS[selectedIndex];

        return (
            <GatedComponent isEnabledCode={'FM_ACCESS_Funeral_Edit'}>
                {(isEnabled, user) => {
                    return (
                        <DataForm
                            id={id}
                            tab={tab}
                            loading={networkStatus !== 7}
                            error={error}
                            data={data && data.readOneFuneral}
                            allowSave={isEnabled}
                            onSaved={() => this.onFuneralSaved()}
                            refetch={refetch}
                            requireValid={false}
                            continuousValidation={true}
                            whitelistFields={
                                !!getProperty(data, 'readOneFuneral.LockFuneral')
                                    ? [
                                          'TransferCertificates',
                                          'TransferReady',
                                          'TransferActioned',
                                          'TransferComment',
                                          'DoctorCoronerComment',
                                          'Informant.Comment',
                                          'PlaceOfService.Comment',
                                          'Clergy[].Comment',
                                          'Disposal.CemeterySection',
                                          'AshesDetail.Comment',
                                          'PlaceOfViewingItems[].Comment',
                                          'FuneralDressing.Comment',
                                          'CoffinComment',
                                          'HearseComment',
                                          'LimoBookings[].Comment',
                                          'Lifestory.PresentationFamilyToPreparePowerpointNotes',
                                          'RslMasonicComment',
                                          'DefaultMusicians[].Comment',
                                          'EnterMusicNotes',
                                          'ReflectionMusicSongs[].OtherText',
                                          'ReflectionMusicNotes',
                                          'ExitMusicNotes',
                                          'GravesideMusicNotes',
                                          'RefreshmentsVenue.Comment',
                                          'Caterers[].Comment',
                                          'Florists[].Comment',
                                          'NewspaperNotices[].Comment',
                                          'NewspaperNotices[].Notes',
                                          'MemorialStationary[].Comment',
                                          'PersonalisedTouches[].Comment',
                                          'SpecialInstructions[].Comment',
                                          'BillingNotes',
                                          'FollowUpComment',
                                          'PlaceOfService.StaffAllocations[].Allocation'
                                      ]
                                    : null
                            }
                        >
                            {(form, button) => {
                                return (
                                    <Fragment>
                                        <div className={classes.content}>
                                            {!!loading && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 99,
                                                        margin: -20,
                                                        padding: 20,
                                                        height: '100%',
                                                        width: '100%',
                                                        background: 'rgba(255,255,255,0.5)'
                                                    }}
                                                >
                                                    <span>Loading data, please wait a moment...</span>
                                                    <div style={{ float: 'left', marginRight: 8 }}>
                                                        <Spinner />
                                                    </div>
                                                </div>
                                            )}

                                            <tab.component
                                                form={form}
                                                adminChecklist={tab.key === 'summary' ? adminData.adminSummary : false}
                                                user={user}
                                                locked={!!getProperty(data, 'readOneFuneral.LockFuneral')}
                                            />
                                        </div>
                                        {button}
                                    </Fragment>
                                );
                            }}
                        </DataForm>
                    );
                }}
            </GatedComponent>
        );
    };

    renderOnlineOrderButton = ({ loading, error, data, networkStatus, refetch }) => {
        const { classes } = this.props;
        const results = (data && data.readOnlineOrders && data.readOnlineOrders.edges) || [];
        if (results && results.length) {
            return results.map((onlineorder, i) => {
                return (
                    <Fragment key={i}>
                        <PrimaryButton onClick={() => this.toggleOnlineOrderModal()} className={classes.primaryButton}>
                            <InfoCircleIcon />
                            <Hidden smDown> View Online Order Details</Hidden>
                        </PrimaryButton>
                        <OnlineOrderPopover
                            orderData={onlineorder.node.OrderData}
                            open={!!this.state.onlineorderModalOpen}
                            pdfLink={onlineorder.node.SummaryPdf.AbsoluteLink}
                            onClose={() =>
                                this.setState({
                                    onlineorderModalOpen: false
                                })
                            }
                        />
                    </Fragment>
                );
            });
        }
        return null;
    };

    onFuneralSaved() {
        this.saveAdminSummary();
    }

    saveAdminSummary() {
        const { id } = this.props.match.params;
        const { adminData } = this.state;
        saveAdminSummaryFuncAsync(id, adminData.adminSummary, adminData.original).then(
            result => {
                loadAdminSummary(id).then(adminData => this.setState({ adminData }));
            },
            error => console.error('Error during save admin summary. e=' + error.message, error)
        );
    }

    toggleAdminSummary() {
        const { showAdminSummary } = this.state;
        this.setState({ showAdminSummary: !showAdminSummary });
    }

    toggleDocumentModal() {
        this.setState({ documentsModalOpen: true });
    }
    toggleOnlineOrderModal() {
        this.setState({ onlineorderModalOpen: true });
    }
}

const nameQuery = gql`
    query NameQuery($id: ID!) {
        readOneFuneral(ID: $id) {
            ID
            LegacyKey
            FirstName
            Surname
            MiddleName
            Cancelled
        }
    }
`;

const styles = ({ spacing, typography, palette, transitions, breakpoints }) => ({
    pageTitleContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        [breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
        }
    },
    primaryButton: {
        [breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    adminButton: {
        margin: '0 0 0 10px',
        flexGrow: 0,
        '& button': {
            minWidth: 'unset',
            color: palette.action.active + '!important',
            '& svg + span': {
                marginLeft: spacing.unit,
                fontSize: '1rem'
            }
        }
    },
    adminIcon: {
        width: '16px',
        margin: '0 0.2rem 0 0'
    },
    pageTitle: {
        color: palette.action.active,
        display: 'flex',
        alignItems: 'center',
        flexGrow: '1',
        flexWrap: 'wrap'
    },
    content: {
        padding: spacing.unit * 4,
        marginRight: -12,
        [breakpoints.down('md')]: {
            marginRight: 0
        },
        [breakpoints.down('xs')]: {
            padding: 12
        }
    },
    keyline: {
        width: 1,
        height: 30,
        display: 'inline-block',
        margin: '0 16px',
        opacity: 0.5,
        backgroundColor: palette.action.active
    },
    nameTitle: {
        display: 'inline-block',
        fontWeight: typography.fontWeightLight,
        // marginLeft: 16,
        opacity: 0,
        color: palette.text.primary,
        [breakpoints.down('sm')]: {
            // marginLeft: 0
        },
        transition: transitions.create(['opacity'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    nameTitleActive: {
        opacity: 1
    },
    paper: {
        width: '100%',
        '& > div': {
            marginBottom: 0,
            '& > div:nth-child(2)': {
                zIndex: 1
            }
        }
    },
    funeralIcon: {
        '& span > svg': {
            color: palette.primary.main + '!important'
        }
    }
});

// prettier-ignore
export default compose(
    withRouter,
    withStyles(styles)
)(Funeral);
