import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import withTheme from '@material-ui/core/styles/withTheme';
import { withStyles } from '@material-ui/core';
import Button from './extras/Button';
import CloseIcon from '../../component/icon/CloseIcon';
import { getMyClient } from '../../apollo';
import RecordWorkspace from './RecordWorkspace';
import WorkQueueIcon from '../../component/icon/WorkQueueIcon';
import TaskFragment1 from '../workQueue/components/TaskFragment';

const styles = ({ palette, sizes }) => ({
    drawerBox: {
        marginTop: sizes.headerHeight,
        height: 'calc(100% - ' + (sizes.footerHeight + sizes.headerHeight) + 'px)',
        overflowX: 'hidden',
        boxShadow: '-2px 0px 6px rgba(0, 0, 0, 0.1)'
    },
    drawerBig: {
        overflowX: 'hidden'
    },
    funeralIcon: {
        '& span > svg': {
            color: palette.primary.main + '!important'
        }
    },
    notificationsBadge: {
        top: 3,
        right: 3,
        width: 24,
        height: 24,
        borderRadius: 32
    }
});

export default compose(
    withTheme(),
    withStyles(styles)
)(
    ({
        funeralId = null,
        plaqueId = null,
        cremationId = null,
        enquiryFMId = null,
        enquiryCMId = null,
        legacyKey,
        theme,
        classes
    }) => {
        if (
            !Number(funeralId) &&
            !Number(cremationId) &&
            !Number(plaqueId) &&
            !Number(enquiryFMId) &&
            !Number(enquiryCMId)
        )
            return null;
        const recordType = !!funeralId
            ? 'Funeral'
            : !!plaqueId
            ? 'Plaque'
            : !!enquiryCMId
            ? 'Enquiry'
            : !!enquiryFMId
            ? 'Enquiry'
            : 'Cremation';
        const [open, setOpen] = React.useState(false);
        const handleDrawerOpen = () => {
            setOpen(true);
        };
        const handleDrawerClose = () => {
            setOpen(false);
        };
        const taskQuery = gql`
            ${TaskFragment1}
            query ReadRecordTasks(
                $funeralId: Int
                $cremationId: Int
                $plaqueId: Int
                $enquiryFMId: Int
                $enquiryCMId: Int
            ) {
                OutstandingTasks: readTasks(
                    isComplete: false
                    enquiryFMId: $enquiryFMId
                    enquiryCMId: $enquiryCMId
                    funeralId: $funeralId
                    cremationId: $cremationId
                    plaqueId: $plaqueId
                ) {
                    edges {
                        node {
                            ...TaskFragment1
                        }
                    }
                }
                CompletedTasks: readTasks(
                    isComplete: true
                    enquiryFMId: $enquiryFMId
                    enquiryCMId: $enquiryCMId
                    funeralId: $funeralId
                    cremationId: $cremationId
                    plaqueId: $plaqueId
                ) {
                    edges {
                        node {
                            ...TaskFragment1
                        }
                    }
                }
            }
        `;

        const renderContent = ({ error, loading, data, refetch }) => {
            if (error) {
                console.error(error);
                return <div>Error: Can't read {recordType} tasks!</div>;
            } else if (loading && 1 === 2) {
                return <div>Loading data, please wait...</div>;
            }
            const tasksDone = []
                .concat(data && data.CompletedTasks && data.CompletedTasks.edges)
                .filter(e => !!e)
                .map(e => !!e && e.node);
            const tasksToDo = []
                .concat(data && data.OutstandingTasks && data.OutstandingTasks.edges)
                .filter(e => !!e)
                .map(e => !!e && e.node);

            const variant = window.innerWidth < theme.breakpoints.values['md'] ? 'temporary' : 'persistent';

            return (
                <Fragment>
                    <Badge
                        classes={{ badge: classes.notificationsBadge }}
                        showZero={false}
                        color="primary"
                        badgeContent={tasksToDo.length}
                    >
                        <Button
                            variant={`primary icon-button white shadow ${classes.funeralIcon}`}
                            size="lg"
                            aria-label="open drawer"
                            edge="end"
                            title={
                                `Show ${recordType} Tasks` +
                                (tasksToDo.length ? ` (${tasksToDo.length} outstanding)` : '')
                            }
                            onClick={handleDrawerOpen}
                        >
                            <WorkQueueIcon title={`${recordType} Work Queue`} />
                        </Button>
                    </Badge>
                    {open && (
                        <div style={{ margin: 0 }}>
                            <Drawer
                                classes={{ paper: variant !== 'temporary' ? classes.drawerBox : classes.drawerBig }}
                                variant={variant}
                                anchor="right"
                                open
                            >
                                <div className="workqueue-page">
                                    <div className="wq-page" style={{ padding: 0 }}>
                                        <div className="workspace-header">
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                                <div style={{ lineHeight: 1 }}>
                                                    <Button
                                                        onClick={handleDrawerClose}
                                                        variant="primary icon-button white"
                                                        size="lg"
                                                        color="inherit"
                                                        aria-label="close drawer"
                                                    >
                                                        <CloseIcon style={{ color: theme.palette.primary.main }} />
                                                    </Button>
                                                </div>
                                                <h2 style={{ color: theme.palette.primary.main }}>
                                                    <span style={{ fontWeight: 200, fontSize: '75%' }}>
                                                        Work Queue:
                                                    </span>
                                                    <br />
                                                    {recordType} {legacyKey}
                                                </h2>
                                            </div>
                                        </div>
                                        <RecordWorkspace
                                            tasksDone={tasksDone}
                                            tasksToDo={tasksToDo}
                                            refetch={refetch}
                                            recordType={recordType}
                                        />
                                    </div>
                                </div>
                            </Drawer>
                        </div>
                    )}
                </Fragment>
            );
        };

        return (
            <Query
                query={taskQuery}
                variables={{ funeralId, cremationId, plaqueId, enquiryFMId, enquiryCMId }}
                client={getMyClient()}
                fetchPolicy="network-only"
            >
                {results => renderContent(results)}
            </Query>
        );
    }
);
