import gql from 'graphql-tag';

export default gql`
    fragment ArrangementForms on Funeral {
        Arrangement {
            ID
            ArrangementForms {
                ... on ArrangementFormStatutoryForms {
                    ID
                    Signed
                    Type: __typename
                    PDF {
                        ... on SilverStripeFile {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
                ... on ArrangementFormBurialBooking {
                    ID
                    Signed
                    Type: __typename
                    PDF {
                        ... on SilverStripeFile {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
                ... on ArrangementFormCremationBooking {
                    ID
                    Signed
                    Type: __typename
                    PDF {
                        ... on SilverStripeFile {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
                ... on ArrangementFormLetterOfAuthority {
                    ID
                }
            }
        }
    }
`;
