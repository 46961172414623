import { getServiceURLHostname } from '../apollo';

export const FUNERAL_HOME = {
    // need to replace with real db values
    H_PARSONS: {
        color: '#35327C',
        label: 'H. Parsons Funerals',
        path: '/search/h-parsons-funerals',
        letterCode: 'P',
        LegacyKey: 'PARSONS',
        logo: '//' + getServiceURLHostname() + '/themes/hparsons-fm/images/logos/h-parsons.png',
        ID: 4,
        style: 'tablestyle5'
    },
    PARSONS_LADIES: {
        color: '#8B3983',
        label: 'Parsons Ladies Funerals',
        path: '/search/parsons-ladies-funerals',
        letterCode: 'L',
        LegacyKey: 'PARSONS_LADIES',
        logo: '//' + getServiceURLHostname() + '/themes/hparsons-fm/images/logos/parsons-ladies.png',
        ID: 5,
        style: 'tablestyle2'
    },
    RANKINS: {
        color: '#14A554',
        label: 'Rankins Funerals',
        path: '/search/rankins-funerals',
        letterCode: 'R',
        LegacyKey: 'RANKINS',
        logo: '//' + getServiceURLHostname() + '/themes/hparsons-fm/images/logos/rankins.png',
        ID: 3,
        style: 'tablestyle3'
    },
    STAN_CRAP: {
        color: '#FFBD26',
        label: 'Stan Crapp Funerals',
        path: '/search/stan-crapp-funerals',
        letterCode: 'K',
        LegacyKey: 'STANCRAPP',
        logo: '//' + getServiceURLHostname() + '/themes/hparsons-fm/images/logos/stan-crapp.png',
        ID: 2,
        style: 'tablestyle1'
    },
    EASY_FUNERALS: {
        color: '#DE1076',
        label: 'Easy Funerals',
        path: '/search/easy-funerals',
        letterCode: 'E',
        LegacyKey: 'EASY_FUNERALS',
        logo: '//' + getServiceURLHostname() + '/themes/hparsons-fm/images/logos/easy-funerals2.png',
        ID: 6,
        style: 'tablestyle7'
    },
    WOLLONGONG_CITY: {
        color: '#E67D40',
        label: 'Wollongong City Funerals',
        path: '/search/wollongong-city-funerals',
        letterCode: 'W',
        LegacyKey: 'WOLLONGONGCITY',
        logo: '//' + getServiceURLHostname() + '/themes/hparsons-fm/images/logos/city-funerals.png',
        ID: 1,
        style: 'tablestyle4'
    }
};

export const FUNERAL_HOMES = Object.values(FUNERAL_HOME);

export function getFuneralHomeByKey(key = null, strict = false) {
    if (!key) {
        if (!strict) {
            key = '0P';
        } else {
            return null;
        }
    }
    const letter = key.charAt(key.length - 1).toUpperCase();
    return FUNERAL_HOMES.find(h => h.letterCode === letter);
}

export function getFuneralHomeByURLSegment(urlSegment) {
    const path = '/search/' + urlSegment;
    return FUNERAL_HOMES.find(h => h.path === path);
}

export const BOND_PERCENT = 0.02;
export const ADMIN_FEE = 300;
