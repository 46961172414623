import React, { Component, Fragment } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { getClient } from '../../apollo';
import gql from 'graphql-tag';
import { PurchaseOrderObject } from '../../page/fragments/PurchaseOrders';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo';
import { isRelatedObjectDefined } from '../../util/bookable';
import { AltButton, OutlineButton, SaveButton } from '../form/PrimaryButton';
import QuotesIcon from '../icon/QuotesIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import UsdCircleIcon from '../icon/UsdCircleIcon';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '../form/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '../icon/CloseIcon';
import { joinDefined, prettyPrice } from '../../util/strings';
import { round } from '../../util/objects';
import { GST } from '../../util/products';
import TableData from '../form/TableData';
import { isRelatedObjectUndefined } from '../../util/graphql';
import Hidden from '@material-ui/core/Hidden';
import Spinner from '../Spinner';

class PurchaseInvoiceModal extends Component {
    state = {
        open: false,
        loaded: false,
        Invoice: null
    };

    componentDidMount() {
        const { hasPurchaseOrder } = this.props;
        const { PO } = this.state;
        if (PO !== hasPurchaseOrder.ID) {
            getClient()
                .query({ query: readMyPurchaseOrder, variables: { id: hasPurchaseOrder.ID } })
                .then(result => {
                    const foo = { loaded: true };
                    if (!!result.data.readOneFuneralManagerPurchaseOrder) {
                        foo.Invoice = result.data.readOneFuneralManagerPurchaseOrder.Invoice;
                    }
                    this.setState(foo);
                });
        }
    }

    render() {
        const { classes, hasPurchaseOrder = false } = this.props;
        const { Invoice, loaded, open } = this.state;
        const hasInvoice = !!Invoice && isRelatedObjectDefined(Invoice);
        const hasXero = !!hasInvoice && Invoice.XeroID;

        if (!hasPurchaseOrder) return 'Error: No PO or Bill found.';

        if (!!hasInvoice && !!open)
            return (
                <Dialog
                    classes={{ paper: classes.root }}
                    open={open}
                    onClose={this.onClose}
                    aria-labelledby={'PurchaseOrderModal'}
                >
                    <form noValidate onSubmit={e => this.onSubmit(e)}>
                        {this.renderInvoice()}
                    </form>
                </Dialog>
            );

        if (!loaded) return 'Loading...';
        return (
            (!!hasInvoice && (
                <OutlineButton onClick={this.showInvoiceModal}>
                    <QuotesIcon />
                    &nbsp;{hasXero ? 'View ' : 'Submit '}Bill #{Invoice.ID}...
                </OutlineButton>
            )) || (
                <AltButton onClick={this.generateBill}>
                    <QuotesIcon />
                    &nbsp;Generate Bill
                </AltButton>
            )
        );
    }

    onCancel = reload => {
        const { form } = this.props;
        if (!!form && !!reload && form.doRefetch) {
            const me = this;
            form.doRefetch(() => me.clearState());
        } else {
            this.clearState();
        }
    };

    clearState() {
        this.setState({ open: false, loaded: true });
    }

    renderInvoiceItems() {
        const { classes } = this.props;
        const { Invoice } = this.state;
        let orderTotal = 0;

        const data = Invoice.InvoiceItems.filter(e => !!e && !isRelatedObjectUndefined(e.Product)).map(e => {
            const Item =
                (isRelatedObjectDefined(e.Variation) && e.Variation) ||
                (isRelatedObjectDefined(e.Product) && e.Product);
            if (!Item) return null;
            const needGST = Item.GST;
            const cost = isRelatedObjectDefined(e.Variation) ? Item.Price : Item.BasePrice;
            const title =
                e.Product.Title + (!!isRelatedObjectDefined(e.Variation) ? ' | ' + Item.ShortDescription : '');
            const displayData = {
                data: {
                    Code: Item.InternalItemID,
                    Description: title,
                    Cost: round(cost * (!!needGST ? GST + 1 : 1), 2), //incGST
                    Price: round(e.Price * (!!needGST ? GST + 1 : 1), 2), //incGST
                    Qty: Number(e.Quantity).toFixed(0),
                    needGST: !!needGST
                }
            };
            displayData.data = PurchaseInvoiceModal.reCalcTable(displayData.data);
            orderTotal += Number(displayData.data.Price) * Number(displayData.data.Qty);
            return displayData.data;
        });

        const columns = [
            { id: 'Code', label: 'Product Code' },
            { id: 'Description', label: 'Description of product or service' },
            /*{
                id: 'Cost',
                label: 'Cost',
                render: val => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right' }
            },*/
            {
                id: 'Price',
                label: 'Price',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { width: 1 }
            },
            { id: 'Qty', label: 'QTY', styles: { textAlign: 'right', width: 1 } },
            {
                id: 'SubTotal',
                label: 'Subtotal (exGST)',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5.5rem' }
            },
            {
                id: 'GST',
                label: 'GST',
                render: (val, row, rowIndex) => {
                    return !!row.needGST ? prettyPrice(val) : 'N/A';
                },
                styles: { textAlign: 'right', width: '5rem' }
            },
            {
                id: 'Total',
                label: 'Total',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5.5rem' }
            }
        ];

        return (
            <div>
                <Typography variant={'display1'}>Bill Total: {prettyPrice(orderTotal)}</Typography>
                <div className={classes.dataList}>
                    <TableData
                        columns={columns}
                        data={data}
                        footer={[
                            { id: 'SubTotal', label: 'TOTAL' },
                            {
                                id: 'GST',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['GST'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                }
                            },
                            {
                                id: 'Total',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['Total'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                },
                                styles: { fontWeight: 'bolder' }
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }

    static reCalcTable(data) {
        data.SubTotal = (data.Qty * 1 * (!!data.needGST ? data.Price / (GST + 1) : data.Price)).toFixed(2);
        data.GST = !!data.needGST ? (data.SubTotal * 0.1).toFixed(2) : 0;
        data.Total = Number(data.Qty * data.Price).toFixed(2);
        return data;
    }

    renderInvoice() {
        const { classes, labelSupplier = 'Supplier' } = this.props;
        const { Invoice, loaded } = this.state;
        const contactName =
            joinDefined([Invoice.Customer.FirstName, Invoice.Customer.MiddleName, Invoice.Customer.Surname], ' ') ||
            Invoice.Customer.CustomerName;
        return (
            <Fragment>
                <DialogTitle className={classes.diagHeader}>
                    <UsdCircleIcon className={classes.starButton} />
                    <div className={classes.diagHeaderTitle}>
                        {labelSupplier}:<strong> {contactName}</strong>
                    </div>
                    <div className={classes.diagHeaderSubtitle}>View Bill #{Invoice.ID}</div>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container spacing={24} pc={1} style={{ margin: 0 }}>
                        <Grid item xs={12}>
                            {this.renderInvoiceItems()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.diagActions}>
                    <OutlineButton onClick={() => this.setState({ open: false })}>
                        <CloseIcon />
                        <Hidden xsDown>&nbsp;Close</Hidden>
                    </OutlineButton>

                    <div style={{ textAlign: 'right' }}>
                        {(!Invoice.XeroID && (
                            <SaveButton onClick={e => this.generateBill(e)} disabled={!loaded}>
                                {loaded ? (
                                    <Fragment>
                                        <UsdCircleIcon />
                                        <Hidden xsDown>&nbsp;Submit To Xero</Hidden>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Spinner />
                                        <Hidden xsDown>&nbsp;Submitting...</Hidden>
                                    </Fragment>
                                )}
                            </SaveButton>
                        )) || (
                            <div>
                                <strong>{Invoice.XeroReference}</strong>
                                <br />
                                Status: <strong>{Invoice.XeroStatus}</strong>
                            </div>
                        )}
                    </div>
                </DialogActions>
            </Fragment>
        );
    }

    showInvoiceModal = e => {
        e.preventDefault();
        const { hasPurchaseOrder } = this.props;
        const { Invoice } = this.state;
        if (isRelatedObjectDefined(hasPurchaseOrder) && isRelatedObjectDefined(Invoice)) {
            this.setState({ open: true });
        }
    };

    generateBill = e => {
        e.preventDefault();
        const { hasPurchaseOrder } = this.props;
        const that = this;
        if (isRelatedObjectDefined(hasPurchaseOrder)) {
            this.setState({ loaded: false });
            return submitToXero(hasPurchaseOrder.ID).then(
                moreData => {
                    if (moreData && moreData.data.submitFuneralManagerPurchaseOrderToXero) {
                        this.props.setSnackbarMessage('Success, your bill was submitted.', true);
                        this.onCancel(true);
                    }
                },
                subError => that.onGqlError('Failed to send bill to xero.', subError)
            );
        }
    };

    onGqlError(action, error) {
        this.setState({ loaded: true });
        if (error) console.error(error);
        this.props.setSnackbarMessage(action, false, null, new Error(error));
    }
}

export const readMyPurchaseOrder = gql`
    ${PurchaseOrderObject}
    query ReadOnePurchaseOrder($id: ID!) {
        readOneFuneralManagerPurchaseOrder(ID: $id) {
            ...PurchaseOrderObject
        }
    }
`;

const submitToXeroMutation = gql`
    ${PurchaseOrderObject}
    mutation SubmitToXero($id: ID!) {
        submitFuneralManagerPurchaseOrderToXero(ID: $id) {
            ...PurchaseOrderObject
        }
    }
`;

const submitToXero = async ID => {
    let asyncQuery;

    asyncQuery = await getClient().mutate({
        mutation: submitToXeroMutation,
        variables: {
            id: ID
        }
    });

    return asyncQuery;
};

const styles = ({ palette, funeralHome }) => ({
    root: {
        maxWidth: '800px',
        width: '100%',
        maxHeight: 'unset'
    },
    diagHeader: {
        background: palette.contentForeground[funeralHome],
        padding: '30px'
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    content: {
        width: '100%',
        padding: 18,
        maxHeight: 'calc(100vh - 15rem)',
        overflow: 'auto',
        minHeight: 340
    },
    diagActions: {
        padding: 30,
        paddingTop: 10,
        margin: 0,
        justifyContent: 'space-between'
    },

    regular: {
        fontSize: '16px',
        fontWeight: 'lighter'
    },

    greenButt: {
        border: '1px solid' + palette.action.save,
        backgroundColor: palette.action.save,
        '&:hover': {
            backgroundColor: palette.action.hover,
            color: palette.action.save
        }
    },
    dataList: {
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 30
    },
    starButton: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    }
});

export default compose(withSnackbarMessage, withStyles(styles))(PurchaseInvoiceModal);
