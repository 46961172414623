import gql from 'graphql-tag';

export default gql`
    fragment DocumentEmail on DocumentEmail {
        ID
        Created
        Subject
        Message
        EmailSent
        ScheduledToSendEmail
        EmailApplicant
        EmailOther
        OtherEmailAddress
        Approved
        SentToRecipientEmailAddresses

        Attachments {
            ... on SilverStripeFile {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;
