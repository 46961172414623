import { ChildrenDetails, Marriages, Parent1, Parent2 } from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment FamilyFields on Funeral {
                DeFacto
                DefactoGivenName
                DefactoSurname
                DefactoAge
                DefactoAgeType

                Surname
                Gender
                FamilyChangesFromBDM
            }
        `,
        ChildrenDetails,
        Marriages,
        Parent1,
        Parent2
    ],
    'Funeral'
);
