import gql from 'graphql-tag';
import { OrdersFragmentInclude } from '../fragments/Bookings';

export const CateringReportFragment = gql`
    query($from: String!, $to: String!) {
        readFuneralManagerCateringStaffs {
            edges {
                node {
                    ID
                    ClassName
                    LastEdited
                    Created
                    #LegacyID
                    #LegacyKey
                    Name
                    Phone
                    Mobile
                    Email
                    NotCurrent
                    CateringStaffDaysoff {
                        ID
                        Date
                    }
                    CateringStaffAnnualLeave {
                        ID
                        StartDate
                        EndDate
                    }
                    CateringStaffPoints {
                        ID
                        LastEdited
                        Created
                        Year
                        Points
                    }
                    CateringStaffAllocation {
                        ID
                        Availability
                        Start
                        End
                    }
                }
            }
        }

        readCalendarEvents(from: $from, to: $to, filterTypes: ["FuneralManager\\\\RefreshmentsVenue"]) {
            ... on RefreshmentsVenue {
                ID
                ClassName
                Start
                End
                NumberOfPeople
                LocationFlattened
                Comment
                Confirmed
                CateringStaffAllocations {
                    ID
                    Availability
                    Confirmed
                    Start
                    End
                    CateringStaff {
                        ID
                        Name
                    }
                }
                Funeral {
                    ID
                    FirstName
                    MiddleName
                    Surname
                    LegacyKey
                    Informant {
                        ID
                        InformantsName
                        RelationshipToDeceased
                    }
                    Caterers {
                        ${OrdersFragmentInclude}
                    }
                    Arrangers {
                        ID
                        Member {
                            ID
                            Surname
                            FirstName
                        }
                    }
                    Coordinators {
                        ID
                        Member {
                            ID
                            Surname
                            FirstName
                        }
                    }
                    Conductors {
                        ID
                        Member {
                            ID
                            Surname
                            FirstName
                        }
                    }
                }
            }
        }
    }
`;
