import gql from 'graphql-tag';
import DocumentEmail from './DocumentEmail';

const FileFields = `
    ID
    Name
    Size
    AbsoluteLink
    Created
`;

const DocumentFields = `
    ... on SilverStripeFile {
        ${FileFields}    
    }
    ... on SilverStripeImage {
        ${FileFields}
    }
`;

const ResidentalAddressFields = `
    ID
    AddressLine1
    AddressLine2
    Suburb
    State
    Postcode
    Country
    Phone
    Fax
    Email
    Contact
    Mobile
`;

const AddressBookFields = `
    ID
    Name
    State
    Suburb
    Postcode
    AddressLine1
    AddressLine2
    ClergyType
    Notes
    Phone
    Fax
    Mobile
    Email
    AddressBookCategory
`;

const FormFieldsShort = `
    ID
    Approved
    Void
    Sent
    ReadyForSignature
    Signed
    SignedOn
    Version
    Created
    SignatureDate
    Progress
    PDF {
        ... on SilverStripeFile {
            ID
            AbsoluteLink
        }
    }
    FormName
    Notes
    Type: __typename
`;

const FormFieldsCommon = `
    ID
    Approved
    Void
    Sent
    Signed
    SignatureDate
    Signature
    Version
    ReadyForSignature

    ApplicantFirstName
    ApplicantMiddleName
    ApplicantLastName
    ApplicantAge
    ApplicantRelationship
    ApplicantPhone
    ApplicantMobile
    ApplicantTitle
    ApplicantBusinessName
    ApplicantEmail

    DeceasedFirstName
    DeceasedLastName
    DeceasedMiddleName
    DeceasedMartialStatus
    DeceasedAge
    DeceasedGender
    DeceasedDateOfDeath
    DeceasedTimeOfDeath
    DeceasedDateOfBirth
    DeceasedOccupation
    DeceasedReligion
    DeceasedPlaceOfDeathType

    FormName

    DeclarationDate

    BookingDate
    DeliveryDate
    DeliveryTime
    ServiceType
    HeavenAddress
    Record
    AVPresentation

    Progress
    Notes

    ApplicantAddress {
        ${ResidentalAddressFields}
    }

    DeceasedAddress {
        ${ResidentalAddressFields}
    }

    DeceasedPlaceOfDeath {
        ${ResidentalAddressFields}
    }
    
    DeceasedPlaceOfDeathAddressBook {
        ${AddressBookFields}
    }

    DeclarationLocation {
        ${ResidentalAddressFields}
    }

    ApplicantSignature {
        ${FileFields}
    }

    WitnessSignature {
        ${FileFields}
    }
    PDF {
        ... on SilverStripeFile {
            ID
            AbsoluteLink
        }
    }
`;

export const SummaryFragment = gql`
    fragment Summary on Funeral {
        ID
        LegacyKey
        ApprovedByClient
        ApprovedOn
        RejectionNote
        RejectedOn
        LatestBDMForm {
            ID
        }
        AdminChecks {
            ID
            Title
            TabURLSegment
            Active
            Join {
                ID
                Checked
            }
        }
        Disposal {
            ID
            CrematedOrBuried
            Location {
                ID
                Name
            }
        }
        Grave {
            ID
            GroundDescription
            DepthOption
        }
        Arrangement {
            ID
            AgreeTC
            OptToSendEmail
            ArrangementForms {
                 ...on ArrangementFormBurialBooking {
                    ${FormFieldsShort}
                 }
                ...on ArrangementFormCremationBooking {
                     ${FormFieldsShort}
                }
                ...on ArrangementFormStatutoryForms {
                    ${FormFieldsShort}
                }
                ...on ArrangementFormLetterOfAuthority {
                    ${FormFieldsShort}
                }
            }
            DocumentEmails {
                ...DocumentEmail
            }
        }
        Certification {
            ID
            TypeOfBdmDeathCertificate
            FileCorDisposalOrder {
                ${DocumentFields}
            }
            FileMedCertCause {
                ${DocumentFields}
            }
            FileStatDecForm { #AForm
                ${DocumentFields}
            }
            FileCorCremPermit {
                ${DocumentFields}
            }
            FileCremCertificate {
                ${DocumentFields}
            }
            FileCremBookingForm { #Crematorium Booking Form
                ${DocumentFields}
            }
            FileRefereeingDoctorForm {
                ${DocumentFields}
            }
            FileBurialBookingForm { #Burial Booking Form
                ${DocumentFields}
            }
            FileEmbalmingCert {
                ${DocumentFields}
            }
            FileDisclaimer {
                ${DocumentFields}
            }
        }
        Office {
            ID
            LegacyKey
            BusinessName
            QuoteTermsPDF {
                ... on SilverStripeFile {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
        }
    }
    ${DocumentEmail}
`;

export const CremationFormFragment = gql`
    fragment CremationBookingForm on Funeral {
        ID
        Arrangement {
            ID
            ArrangementForms {
                ... on ArrangementFormCremationBooking {
                    ${FormFieldsShort}
                }
            }
        }
    }
`;

export const CremationFormDataFragment = gql`
    fragment CremationBookingFormData on ArrangementFormCremationBooking {
        ... on ArrangementFormCremationBooking {
            ${FormFieldsCommon}
            FuneralCompany
            FuneralPhone
            Email

            OfficeAddress {
                ${ResidentalAddressFields}
            }
            
            ArrangerName
            HasSpecialInstructions
            SpecialInstructions
            CemeteryName
            SiteReference

            ApplicantTitle
            ApplicantEmail

            DeceasedMiddleName

            ApplicantBusinessName
            Template
        }
    }
`;

export const LetterOfAuthorityFragment = gql`
fragment LetterOfAuthority on Funeral {
    ID
    Arrangement {
        ID
        ArrangementForms {
            ... on ArrangementFormLetterOfAuthority {
                ${FormFieldsShort}
            }
        }
    }
}`;

export const LetterOfAuthorityDataFragment = gql`
    fragment LetterOfAuthorityData on ArrangementFormLetterOfAuthority {
        ... on ArrangementFormLetterOfAuthority {
            ${FormFieldsCommon}
            PersonOfContact

            ContactFirstName
            ContactLastName
            ContactEmail
            ContactPhone
            ContactAddress {
                ${ResidentalAddressFields}
            }

            CorrespondenceBefore
            CorrespondenceAfter

            DecideDisposition

            ContactSignature {
                ... on SilverStripeImage{
                    ${FileFields}
                }
            }
        }
    }
`;

export const BurialFormFragment = gql`
    fragment BurialBookingForm on Funeral {
        ID
        Arrangement {
            ID
            ArrangementForms {
                ... on ArrangementFormBurialBooking {
                    ${FormFieldsShort}
                }
            }
        }
    }
`;

export const BurialFormDataFragment = gql`
    fragment BurialBookingFormData on ArrangementFormBurialBooking {
        ... on ArrangementFormBurialBooking {
            ${FormFieldsCommon}

            CemeteryLocation {
                ${AddressBookFields}
            }

            Celebrant {
                ${AddressBookFields}
            }

            IntermentSignature {
                ${FileFields}
            }

            ReservationAddress {
                ${ResidentalAddressFields}
            }

            IntermentRightsAddress {
                ${ResidentalAddressFields}
            }

            OfficeAddress {
                ${ResidentalAddressFields}
            }


            #Deceased info
            NextOfKinFullName
            InfectiousDisease
            InfectiousDiseaseDetails
            DiseaseListA
            DiseaseListB

            #IntermentRights
            IntermentRightsHolder
            IntermentRightsCertificateNo
            IntermentRightsCertificateName
            IntermentRightsCertificateDate
            IntermentRightsCertificateReceipt
            IntermentRightsHolderTitle
            IntermentRightsHolderName
            IntermentRightsHolderMiddleName
            IntermentRightsHolderLastName
            IntermentRightsSigneeName
            IntermentSignatureDate
            IntermentConsent
            IntermentReservationPhone
            IntermentReservationMail
            IntermentRightsMoreThanOne
            IntermentRightsMoreThanOneInformed
            IntermentRightsMoreThanOneWhyNotInformed

            #Reopen info
            NumberOfCurrentlyBurried
            CurrentlyBurriedFirstName
            CurrentlyBurriedLastName
            SecondCurrentlyBurriedFirstName
            SecondCurrentlyBurriedLastName
            ThirdCurrentlyBurriedFirstName
            ThirdCurrentlyBurriedLastName
            ApplicantForReopen
            ReopenApplicantFirstName
            ReopenApplicantLastName
            AuthorityForReopen

            #FuneralDirector
            FuneralCompany
            FuneralPhone
            Email
            ArrangerName
            CemeteryConsent
            
            # Burial info
            HasCelebrant
            HasSpecialRequirements
            SpecialRequirements
            CoffinSelection
            CoffinWeight

            # grave bits
            GraveReservationRequired
            GravePortionGardenName
            GraveDenomination
            GraveBeamRowLot
            GraveGroundType
            GraveSection
            GraveNumber
            GraveDepth
            GraveConsent
            GraveTypeChild
            GraveTypeBaby
            IntermentOrder
            FamilyWillSelectGrave

            #Coffin
            CoffinShape
            CoffinLidRounded

            #Adjoining reservation
            ReservationFirstName
            ReservationLastName
            ReservationNote

            #Booking
            BookingDate
            BookingTime

            #Declaration
            DeclarationDate

            Template
        }
    }
`;

export const AFormFragment = gql`
    fragment AForm on Funeral {
        ID
        Arrangement {
            ID
            ArrangementForms {
                ... on ArrangementFormStatutoryForms {
                    ${FormFieldsShort}
                }
            }
        }
    }
`;

export const AFormDataFragment = gql`
    fragment AFormData on ArrangementFormStatutoryForms {
        ${FormFieldsCommon}
        
        SurvivingRelative
        ExecutorOfEstate
        ExecutorRelationship
        ApplicationReasons
        WrittenAuthority
        RequestedBy
        RelativesInformed

        IsRelative

        RelativeObjections
        ReasonForObjection
        WrittenInstruction

        Instructions

        SoundStateOfMind
        SuspectedViolence
        SuspectedPoison
        SuspectedAbuseOrNeglect
        SuspectedDrowning
        SuspectedSuffocation
        SuspectedBurns
        SuspectedCustodialCare
        SuspectedIllegalOperations
        ExaminationRequired

        MedicalPractitionerFullName
        MedicalPractitionerCopyFrom
        LatestPractitionerFullName
        LatestPractitionerCopyFrom

        RegistryOffice
        BatteryPoweredDevice
        DeviceType
        DeviceRemoved
        PermissionForDeviceRemoval

        MedicalPractitionerAddress {
            ${ResidentalAddressFields}
        }

        LatestPractitionerAddress {
            ${ResidentalAddressFields}
        }

        CrematoriumLocation {
            ${AddressBookFields}
        }

        WitnessFirstName
        WitnessLastName
        WitnessQualification
        WitnessSeenFace
        WitnessFamiliar
        WitnessSeenDocument
    }
`;
