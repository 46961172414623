import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose, Query } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '../../component/form/Grid';
import TextField from '../../component/form/TextField';
import gql from 'graphql-tag';
import NoteIcon from '../../component/icon/NoteIcon';
import Typography from '@material-ui/core/Typography/Typography';
import { CONTACT_CATEGORY_OPTIONS, CONTACT_CLERGY_OPTIONS } from './contactConstants';
import Mutation from 'react-apollo/Mutation';
import IconButton from '@material-ui/core/IconButton';

/*
 * Renders the notes for a contact, includes editing.
 *
 * @param contactId is used to query for the details
 * @param contactNotes is used to compare the original to the edit
 * @param onClose can update the caller with the mutation result
 */
class ContactAddNote extends React.Component {
    state = {
        open: false,
        notes: null,
        busy: false
    };
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = data => {
        this.setState({ open: false, notes: null, busy: false });
        if (data && data.updateAddressBook && this.props.onClose) this.props.onClose(data.updateAddressBook.Notes);
    };

    render() {
        const { contactId, contactNotes, classes } = this.props;
        return (
            <Fragment>
                <span onClick={this.handleClickOpen}>
                    <IconButton
                        className={
                            (contactNotes !== null && contactNotes !== undefined) || this.state.notes !== null
                                ? classes.buttonNote
                                : classes.buttonNoNote
                        }
                        aria-label="Add Note"
                        title="Add Note"
                    >
                        <NoteIcon />
                    </IconButton>
                </span>
                {this.state.open && (
                    <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                        {(this.props.contact &&
                            this.renderContent({
                                loading: false,
                                error: false,
                                data: { readAddressBooks: { edges: [{ node: this.props.contact }] } }
                            })) || (
                            <Query query={contactQuery} variables={{ id: contactId }} fetchPolicy={'network-only'}>
                                {results => this.renderContent(results)}
                            </Query>
                        )}
                    </Dialog>
                )}
            </Fragment>
        );
    }

    renderContent({ loading, error, data }) {
        if (error) {
            console.error(error);
            return <div>Error: Can't read data!</div>;
        }

        if (loading) {
            return <div>Loading notes...</div>;
        }

        const contactEdges = data.readAddressBooks && data.readAddressBooks.edges;

        //start to run Query, return loading message.
        if (!contactEdges) {
            return <div>Loading...</div>;
        }

        //loaded, but no edges, return not found message.
        if (contactEdges.length <= 0) {
            return <div>Contact Not Found!</div>;
        }

        const contactData = contactEdges[0].node;

        const { classes } = this.props;

        const cat = CONTACT_CATEGORY_OPTIONS.find(e => {
            return e.value === contactData.AddressBookCategory;
        });

        const clg = CONTACT_CLERGY_OPTIONS.find(e => {
            return e.value === contactData.ClergyCategory;
        });

        const variables = {
            input: {
                ID: contactData.ID,
                Notes: this.state.notes
            }
        };

        return (
            <Fragment>
                {/* <div style={{ width: 600, maxWidth: 'calc(100vw - 64px)', overflow: 'hidden' }}> */}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        overflow: 'hidden',
                        pointerEvents: 'none'
                    }}
                >
                    <NoteIcon
                        style={{
                            opacity: 0.025,
                            width: 300,
                            height: 300,
                            position: 'absolute',
                            right: -24,
                            top: -24,
                            transform: 'rotate(20deg)',
                            zIndex: 0
                        }}
                    />
                </div>
                <DialogTitle className={classes.title}>CONTACT NOTES</DialogTitle>
                <DialogContent style={{ width: 600, maxWidth: '100%' }}>
                    <Typography variant={'headline'}>
                        {contactData.Name}
                        <span style={{ fontSize: '0.875rem', marginLeft: 12 }}>{contactData.Contact}</span>
                    </Typography>
                    <Typography variant={'title'}>
                        {cat && cat.label}
                        <span style={{ fontSize: '0.75rem', marginLeft: 12, fontWeight: 'normal' }}>
                            {clg && clg.label}{' '}
                            {contactData.Religion && clg && (Number(clg.value) === 9 || Number(clg.value) === 11)
                                ? ' - ' + contactData.Religion
                                : ''}
                        </span>
                    </Typography>
                    <div style={{ paddingBottom: 24, marginTop: 12, borderTop: '1px dotted #999' }} />
                    <Grid container spacing={24}>
                        <Grid pc={1}>
                            <TextField
                                name="notes"
                                label="Enter contact notes here..."
                                value={this.state.notes !== null ? this.state.notes : contactData.Notes}
                                multiline={true}
                                disabled={this.state.busy}
                                rows={12}
                                onChange={e => {
                                    const newState = { notes: this.state.notes };
                                    newState.notes = e.target.value;
                                    this.setState(newState);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Mutation mutation={contactMutation} variables={variables} onCompleted={this.handleClose}>
                    {(mutate, { loading }) => {
                        const disabled = loading || this.state.notes === contactData.Notes || this.state.notes === null;
                        return (
                            <DialogActions>
                                {(disabled && (
                                    <Button color="primary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                )) || (
                                    <Button className={classes.buttonCancel} onClick={this.handleClose} color="primary">
                                        Cancel
                                    </Button>
                                )}
                                <Button
                                    onClick={e => this.onSave(mutate, !disabled && variables, e)}
                                    className={classes.buttonSave}
                                    color="primary"
                                    disabled={disabled}
                                >
                                    {this.state.busy ? 'Saving...' : 'Save'}
                                </Button>
                            </DialogActions>
                        );
                    }}
                </Mutation>
                {/*</div>*/}
            </Fragment>
        );
    }
    onSave = (mutate, variables, e) => {
        e.preventDefault();
        this.setState({ busy: true });
        if (variables) mutate({ variables });
    };
}

const contactMutation = gql`
    mutation($input: AddressBookUpdateInputType!) {
        updateAddressBook(Input: $input) {
            ID
            Notes
        }
    }
`;

const contactQuery = gql`
    query($id: String) {
        readAddressBooks(id: $id) {
            edges {
                node {
                    ID
                    Name
                    Contact
                    Notes
                    AddressBookCategory
                    ClergyCategory
                    Religion
                }
            }
        }
    }
`;

const styles = ({ palette }) => ({
    buttonCancel: { color: '#E64040' },
    buttonSave: { color: '#26CC6F' },
    buttonNote: {
        margin: '0 0.5em',
        color: palette.primary
    },
    buttonNoNote: {
        opacity: 0.5,
        margin: '0 0.5em',
        color: palette.primary
    }
});

export default compose(withRouter, withStyles(styles))(ContactAddNote);
