import { withStyles } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import Grid from '../form/Grid';
import EventConflictMessage from './EventConflictMessage';
import OptionalTextField from '../form/OptionalTextField';
import StaffAllocationTable from '../form/StaffAllocationTable';
import SupplierBooking from './SupplierBooking';
import ValidationPlaceholder from '../form/ValidationPlaceholder';
import Label from '../form/Label';

class VenueRequest extends PureComponent {
    render() {
        const {
            categories = [],
            label = 'Products',
            labelSupplier = 'Venue',
            name,
            form,
            children,
            hasOrder,
            commentField = 'Comment',
            offsite,
            allowPurchaseOrder,
            disabled
        } = this.props;
        const location = form.getField(`${name}.Location`);
        const rawBookings = form.getField(`${name}.Bookings`) || [];
        const bookings = (rawBookings && rawBookings.edges && rawBookings.edges.map(e => e.node)) || rawBookings;
        const currentBooking = bookings.find(b => !b.Cancelled);
        const hasMoreThanOne = bookings.length > 1;
        if (!currentBooking) {
            bookings.unshift({
                Contact: form.getField(`${name}.Location`),
                Confirmed: form.getField(`${name}.Confirmed`) || false
            });
        }

        return (
            <Fragment>
                {children}
                <EventConflictMessage
                    funeralKey={form.getField('LegacyKey')}
                    conflictName={location ? location.Name : labelSupplier}
                    conflicts={form.getField(`${name}.EventConflicts`) || []}
                //note="Please allow a 30 minute buffer between bookings."
                />
                {offsite
                    ? offsite()
                    : bookings.map((b, i) => (
                        <Fragment key={'key' + i}>
                            {(!b.Cancelled && (
                                <ValidationPlaceholder
                                    validationResult={form.getValidation(`${name}.Location`, true)}
                                >
                                    <Grid pc={1}>
                                        {hasOrder && (
                                            <Fragment>
                                                <p style={{ marginBottom: 0 }}>
                                                    <Label
                                                        text={
                                                            'Selected ' +
                                                            label +
                                                            (hasMoreThanOne ? ' #' + (1 + i) : '')
                                                        }
                                                    />
                                                </p>
                                                {hasOrder(`${name}.Bookings`, i, label)}
                                                <br />
                                            </Fragment>
                                        )}
                                        <SupplierBooking
                                            disabled={disabled}
                                            index={i}
                                            isOrder={!!hasOrder}
                                            labelSupplier={labelSupplier}
                                            form={form}
                                            name={`${name}.Bookings`}
                                            categories={categories}
                                            forceVenue={name}
                                            allowPurchaseOrder={allowPurchaseOrder}
                                        />
                                    </Grid>
                                </ValidationPlaceholder>
                            )) || (
                                    <Grid pc={1}>
                                        <SupplierBooking
                                            disabled={disabled}
                                            index={i}
                                            isOrder={false}
                                            labelSupplier={labelSupplier}
                                            form={form}
                                            name={`${name}.Bookings`}
                                            categories={categories}
                                            forceVenue={name}
                                            allowPurchaseOrder={allowPurchaseOrder}
                                        />
                                    </Grid>
                                )}
                        </Fragment>
                    ))}
                <Grid pc={1}>
                    <OptionalTextField
                        buttonlabel={`+ Add Comment`}
                        textfieldlabel={`${labelSupplier} Comments`}
                        form={form}
                        name={`${name}.${commentField}`}
                    />
                </Grid>
                <StaffAllocationTable
                    disabled={disabled}
                    staffAllocations={form.getField(`${name}.StaffAllocations`) || []}
                    onChange={(i, obj) => this.onChangeStaffAllocation(`${name}.StaffAllocations`, i, obj, location)}
                    onDelete={i => this.onDeleteStaffAllocation(`${name}.StaffAllocations`, i)}
                    onAdd={obj => this.onAddStaffAllocation(`${name}.StaffAllocations`, obj, location)}
                    startTime={form.getField(`${name}.Time`)}
                    duration={form.getField(`${name}.Duration`)}
                    showCalendarLink
                    date={form.getField(`${name}.Date`)}
                    funeralId={form.getField('ID')}
                />
            </Fragment>
        );
    }

    onChangeStaffAllocation(property, index, update, location) {
        const { form } = this.props;
        const StaffAllocations = form.getField(property) || [];
        update.Location = location;
        Object.assign(StaffAllocations[index], update);
        form.setField({ [property]: StaffAllocations });
    }

    onDeleteStaffAllocation(property, index) {
        const { form } = this.props;
        const StaffAllocations = form.getField(property) || [];
        StaffAllocations.splice(index, 1);
        form.setField({ [property]: StaffAllocations });
    }

    onAddStaffAllocation(property, staffAllocation, location) {
        const { form } = this.props;
        const StaffAllocations = form.getField(property) || [];
        staffAllocation.Location = location || null;
        StaffAllocations.push(staffAllocation);
        form.setField({ [property]: StaffAllocations });
    }
}

const styles = ({ typography }) => ({
    listItem: {
        width: '100%',
        padding: '0 8px 8px 8px',
        margin: '6px 0 0 !important',
        borderRadius: 5,
        position: 'relative',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        },
        '& > p': {
            margin: '1.125rem 0 0 0',
            lineHeight: typography.body2.lineHeight
        }
    }
});

export default withStyles(styles)(VenueRequest);
