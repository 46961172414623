import {
    DeceasedPhotos,
    PlaceOfDeath,
    PlaceOfDeathResidentialAddress,
    TransferFrom,
    TransferFromResidentialAddress
} from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment DeceasedFields on Funeral {
                FirstName
                MiddleName
                Surname
                KnownAs
                FamilyNameAtBirth
                Gender

                DateOfBirth
                UnknownDOB
                Age
                AgeCode
                ManualAge

                DateOfDeath1
                DateOfDeath2
                DateOfDeathType

                ResidentialAddress1
                ResidentialAddress2
                ResidentialTown
                ResidentialState
                ResidentialCountry
                PostCode

                UseResidentialAddress
                UsePlaceOfDeathAddress
                PlaceOfDeathType
                DeathOccuredInHospital

                PlaceOfBirth1
                PlaceOfBirthCity
                PlaceOfBirthState
                PlaceOfBirthPostcode
                PlaceOfBirthCountry
                PlaceOfBirthLocation

                Occupation
                Retired
                Pensioner
                PensionType

                Aboriginal
                TorresStrait
                OriginatingOffice

                ReferencePhoto {
                    ... on SilverStripeFile {
                        ID
                        AbsoluteLink
                        Name
                    }
                    ... on SilverStripeImage {
                        ID
                        AbsoluteLink
                        Name
                    }
                }


                DeceasedPhotos {
                    ... on SilverStripeFile {
                        ID
                        AbsoluteLink
                        Name
                    }
                    ... on SilverStripeImage {
                        ID
                        AbsoluteLink
                        Name
                    }
                }

                TransferFromType
                EstimatedDeceasedWeight

                DateEnteredAustralia
                DateEnteredAustraliaUnknown

                DateTransferredToMortuary
                ReadyForMortuary
                DatetimeReadyForMortuaryCheckout
                MortuaryActionReturnedReason
                DatetimeOfMortuaryCheckout
                DeceasedChangesFromBDM
            }
        `,
        DeceasedPhotos,
        PlaceOfDeath,
        PlaceOfDeathResidentialAddress,
        TransferFromResidentialAddress,
        TransferFrom
    ],

    'Funeral'
);
