import React, { Fragment } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FilterIcon from '../../component/icon/FilterIcon';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PrimaryButton, { OutlineButton } from '../../component/form/PrimaryButton';
import Popover from '@material-ui/core/Popover';
import { appointmentTypesByView, CALENDAR_TYPES } from './CalendarConstants';
import Select from '../../component/form/Select';
import Grid from '../../component/form/Grid';
import TagButton from '../../component/form/TagButton';
import StaffAutoComplete from '../../component/form/StaffAutoComplete';
import { getUser } from '../../util/sessions';
import { joinDefined } from '../../util/strings';

class CalendarFilters extends React.Component {
    state = {
        open: false,
        anchorEl: null,
        activeOnly: false,
        filter: {
            staff: [],
            types: [],
            groups: []
        }
    };

    render() {
        const { classes, view, filters } = this.props;
        const { anchorEl, open } = this.state;

        const hasNoFilters =
            (view !== 'day' || (filters.staff.every(e => !e) && Object.keys(filters.groups).length === 0)) &&
            filters.types.every(e => !e || !appointmentTypesByView({ type: e, view }));

        return (
            <div className="rbc-btn-group" style={{ position: 'relative', display: 'inline-block' }}>
                <PrimaryButton
                    onClick={this.handleOpen}
                    className={'filterBtn' + (!!hasNoFilters ? ' filterBtnOff' : '')}
                    classes={{ addButtonDesktop: classes.filterBtn }}
                    color="primary"
                    style={{ whiteSpace: 'pre' }}
                >
                    <FilterIcon />
                    <Hidden smDown>Filters</Hidden>
                    {!hasNoFilters ? ' ON' : ''}
                    <Hidden smDown>...</Hidden>
                </PrimaryButton>
                <Popover
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    anchorReference={'anchorEl'}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <div className={classes.paper}>
                        <Typography variant="headline" id="modal-title">
                            Filter
                        </Typography>
                        <div className={classes.paperScroll}>
                            {view === 'day' && this.renderStaffFilters()}
                            {this.renderTypesFilters()}
                        </div>
                        <div className={cx(classes.buttonGroup, classes.actionButtons)}>
                            <Button onClick={this.handleClose} size="small" className={cx(classes.buttonCancel)}>
                                CANCEL
                            </Button>{' '}
                            <Button onClick={this.handleApplyFilters} size="small" className={cx(classes.buttonOk)}>
                                OK
                            </Button>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </div>
                </Popover>
            </div>
        );
    }

    handleBulkSelectChange = name => event => {
        const filter = { ...this.state.filter };
        const { resources } = this.props;

        if (name === 'staff') {
            resources.forEach(opt => (filter.staff[opt.resourceId] = true));
        } else {
            CALENDAR_TYPES.forEach(opt => (filter.types[opt.type] = true));
        }

        this.setState({
            filter
        });
    };

    handleBulkUnselectChange = name => event => {
        const filter = { ...this.state.filter };
        filter[name] = {};
        this.setState({ filter });
    };

    handleStaffChange = event => {
        const filter = { ...this.state.filter };
        const { value, checked } = event.target;
        if (checked) {
            filter.staff[value] = true;
        } else {
            delete filter.staff[value];
        }
        this.setState({ filter });
    };

    handleStaffPick = event => {
        const filter = { ...this.state.filter };
        const { value } = event.target;
        filter.staff[value] = true;
        this.setState({ filter });
    };

    renderSelectedGroups() {
        const { filter } = this.state;
        const { groups } = this.props;
        const allGroups = Object.keys(filter.groups).map(property => {
            return groups.find(e => e.ID === property);
        });

        if (allGroups.length === 0) return null;

        const { classes } = this.props;
        return (
            <Grid pc={1}>
                <div className={classes.selectedGroups}>
                    {allGroups.map((x, i) => {
                        return (
                            !!x && <TagButton key={i} text={x.Title} onClickRemove={() => this.onRemoveStaffGroup(x)} />
                        );
                    })}
                </div>
            </Grid>
        );
    }

    onSelectStaffGroup = groupID => {
        const { groups } = this.props;
        const { filter } = this.state;
        const group = groups.find(e => e.ID === groupID);
        filter.groups[groupID] = group;
        this.setState({ filter });
    };

    onRemoveStaffGroup = group => {
        const { filter } = this.state;
        delete filter.groups[group.ID];
        this.setState({ filter });
    };

    handleTypesChange = event => {
        const filter = { ...this.state.filter };
        const { value, checked } = event.target;
        if (checked) {
            filter.types[value] = true;
        } else {
            delete filter.types[value];
        }
        this.setState({ filter });
    };

    handleApplyFilters = event => {
        const thisComp = this;
        //push selected categories to parent state..
        const selectedStaff = [];
        Object.keys(this.state.filter.staff).forEach(function(staffName, index) {
            if (thisComp.state.filter.staff[staffName] === true) {
                selectedStaff.push(staffName);
            }
        });
        // this.props.setFilterCategories(selectedCategories);

        //push selected clergies to parent state.
        const selectedTypes = [];
        Object.keys(this.state.filter.types).forEach(function(type, index) {
            if (thisComp.state.filter.types[type] === true) {
                selectedTypes.push(type);
            }
        });

        const selectedGroups = Object.keys(this.state.filter.groups).map(
            property => this.state.filter.groups[property]
        );

        this.props.setFilters({
            staff: selectedStaff,
            types: selectedTypes,
            groups: selectedGroups
        });

        this.setState({ open: false });
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleOpen = e => {
        let staff = {};
        let types = {};
        let groups = {};
        this.props.filters.staff.forEach(val => {
            staff[val] = true;
        });
        this.props.filters.groups.forEach(val => {
            groups[val.ID] = val;
        });
        this.props.filters.types.forEach(val => {
            types[val] = true;
        });
        const filter = { staff, types, groups };
        this.setState({ open: true, anchorEl: e.currentTarget, filter });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    renderStaffFilters() {
        const { classes, resources, groups } = this.props;
        const { filter } = this.state;
        const me = getUser();
        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid pc={1}>
                        <Typography variant="title" className={classes.titleGap}>
                            Show Team Staff
                        </Typography>

                        <Select
                            onChange={(_, group) => this.onSelectStaffGroup(group)}
                            multiple
                            options={
                                groups &&
                                groups.map(group => {
                                    return {
                                        value: group.ID,
                                        label: group.Title
                                    };
                                })
                            }
                        />
                    </Grid>

                    {this.renderSelectedGroups()}
                </Grid>
                <Grid container spacing={24}>
                    <Grid pc={1}>
                        <Typography variant="title" id="contact-filter-staff" className={classes.titleGap}>
                            Show Individual Staff
                        </Typography>

                        <StaffAutoComplete
                            onSelect={(picked, pepper) => {
                                this.handleStaffPick({ target: { value: pepper.ID } });
                            }}
                        />

                        <div className={classes.buttonGroup}>
                            <OutlineButton onClick={this.handleBulkSelectChange('staff')}>Select All</OutlineButton>
                            <OutlineButton onClick={this.handleBulkUnselectChange('staff')}>Unselect All</OutlineButton>
                        </div>

                        <Grid container spacing={0}>
                            {Object.keys(filter.staff).length > 0 &&
                                Object.keys(filter.staff).map(checkbox => {
                                    const mappedOption = resources.find(
                                        e => checkbox === e.resourceId && e.resourceId !== me.ID
                                    );
                                    if (!!mappedOption) {
                                        const optVal = mappedOption.resourceId;
                                        return (
                                            <Grid key={optVal} item xs={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={filter.staff[optVal] || false}
                                                            onChange={this.handleStaffChange}
                                                            value={optVal}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={joinDefined(
                                                        [mappedOption.FirstName, mappedOption.Surname],
                                                        ' '
                                                    )}
                                                />
                                            </Grid>
                                        );
                                    }
                                    return null;
                                })}
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderTypesFilters() {
        const { classes, view } = this.props;
        const { filter } = this.state;
        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid pc={1}>
                        <Typography variant="title" id="contact-filter-clergy" className={classes.titleGap}>
                            Show Appointment Types
                        </Typography>

                        <div className={classes.buttonGroup}>
                            <OutlineButton onClick={this.handleBulkSelectChange('types')}>Select All</OutlineButton>
                            <OutlineButton onClick={this.handleBulkUnselectChange('types')}>Unselect All</OutlineButton>
                        </div>

                        <Grid container spacing={0}>
                            {CALENDAR_TYPES.filter(e => appointmentTypesByView({ type: e.type, view })).map(
                                mappedOption => {
                                    const optVal = mappedOption.type;
                                    return (
                                        <Grid key={optVal} item xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={filter.types[optVal] || false}
                                                        onChange={this.handleTypesChange}
                                                        value={optVal}
                                                        color="primary"
                                                    />
                                                }
                                                label={mappedOption.label}
                                            />
                                        </Grid>
                                    );
                                }
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const styles = ({ breakpoints }) => ({
    buttonGroup: {
        marginTop: 12,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
        // marginRight: '10px'
        // boxShadow: 'none',
        // borderRadius: '18px'
    },
    filterBtn: {
        [breakpoints.down('sm')]: {
            minWidth: '56px'
        },
        [breakpoints.down('xs')]: {
            borderRadius: '0 30px 30px 0 !important',
            borderLeft: 'none!important'
        }
    },
    paper: {
        width: '100%',
        maxWidth: 440,
        maxHeight: 'calc(100vh - 32px)',
        backgroundColor: '#fafafa',
        padding: 25
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)',
        padding: '12px 0'
    },
    selectButton: {
        marginRight: 10,
        marginTop: 15
    },
    titleGap: { paddingTop: 16 },
    actionButtons: {
        textAlign: 'right'
    },
    buttonCancel: { color: '#E64040' },
    buttonOk: { color: '#26CC6F' },
    selectedGroups: {
        margin: '-20px -6px 0',
        '& > *': {
            margin: '6px'
        }
    }
});

export default compose(withRouter, withStyles(styles))(CalendarFilters);
