import React, { Component, Fragment } from 'react';
import { matchPath, withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import Select from '../../component/form/Select';
import Grid from '../../component/form/Grid';
import TextField from '../../component/form/TextField';
import Checkbox from '../../component/form/Checkbox';
import AddressAutoComplete from '../../component/form/AddressAutocomplete';
import UserCircle, { GetInitials } from '../../component/form/UserCircle';
import Label from '../../component/form/Label';
import FuneralAutoComplete from '../../component/form/FuneralAutoComplete';
import StaffAutoComplete from '../../component/form/StaffAutoComplete';
import { InlineFlex, InlineHeader } from '../../component/form/Inline';
import { adjustTimeString, dateTimeToString, dateToString, dateToTimeString, stringToDate } from '../../util/date';
import { isRelatedObjectDefined } from '../../util/bookable';
import { IndexOf } from '../../util/arrays';
import { joinDefined, pad } from '../../util/strings';
import { getUser, isSignedIn } from '../../util/sessions';
import {
    APPOINTMENT_OPTIONS,
    getAppointmentOptionType,
    getCalendarCategoryByClassName,
    getCalendarCategoryByType
} from './CalendarConstants';
import {
    APPOINTMENT_RESIDENTIAL_COMPONENTS,
    createAppointmentMutation,
    createCalendarEventMutation,
    getOfficeNames,
    readAllocatableFuneralLocations,
    readOneAppointmentQuery,
    readOneCalendarEventQuery,
    readOneStaffAllocationQuery,
    sanitizeAppointment,
    sanitizeCalendarAllocations,
    sanitizeCalendarEvent,
    searchCollidingStaffAllocations,
    updateAppointmentMutation,
    updateCalendarEventMutation
} from './AppointmentConstants';
import { getCalendarClient, getClient, getUtilitiesClient } from '../../apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo';
import Spinner from '../../component/Spinner';
import StaffAllocationTable from '../../component/form/StaffAllocationTable';
import { deleteTypeName, getProperty, setPropertyWrapper } from '../../util/objects';
import AddressBookAutocomplete, { getLabelWithSuburb } from '../../component/form/AddressBookAutocomplete';
import cloneDeep from 'lodash.clonedeep';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { OutlineButton } from '../../component/form/PrimaryButton';
import moment from 'moment';
import gql from 'graphql-tag';
import GatedComponent from '../../component/GatedComponent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import CalendarIcon from '../../component/icon/CalendarIcon';
import RadioGroup from '../../component/form/RadioGroup';
import EnquiryAutoComplete from '../workQueue2/extras/EnquiryAutoComplete';
import { APPLICANT_RELATIONSHIP_OPTIONS } from '../funeral/certification/CertificationConstants';
import { Hidden } from '@material-ui/core';
import ExclamationTriangleIcon from '../../component/icon/ExclamationTriangleIcon';
import ValidationPlaceholder from '../../component/form/ValidationPlaceholder';

class AppointmentPopover extends Component {
    constructor(props) {
        super(props);
        // Set state function is passed down to children as a callback
        // so we need to bind the context of `this`
        this.setState = this.setState.bind(this);
    }

    state = {
        open: false,
        type: 'Appointment',
        view: 'Appointment',
        appointment: null,
        staffAllocation: null,
        popoverTitle: null,
        mutation: createAppointmentMutation,
        sanitize: null,
        offices: null,
        placeOfViewingOptions: [],
        funeral: null,
        loadingPrefill: null,
        edit: false,
        checkingCollisions: false,
        loadingOnSave: false,
        showCollideAllocationsPopup: false,
        errorVal: [] // for those can't be validated with HTML5
    };

    static getDerivedStateFromProps(
        {
            appointment,
            staffAllocation,
            appointmentId,
            queryViaStaffAllocation,
            staffAllocationId,
            calendarEvent,
            calendarEventId,
            open,
            appointmentType,
            eventBegin,
            eventUntil,
            memberList,
            defaults
        },
        oldState
    ) {
        //only continue if this is a new open
        if (!(open && !oldState.open) || !isSignedIn()) return null;

        let adjustedState;

        if (staffAllocation || staffAllocationId || appointmentType === 'StaffAllocation') {
            adjustedState = prepareStateForStaffAllocation(staffAllocation, staffAllocationId, defaults);
        } else if (appointment || appointmentId || appointmentType === 'Appointment') {
            adjustedState = prepareStateForAppointment(appointment, appointmentId, queryViaStaffAllocation, defaults);
        } else if (calendarEvent || calendarEventId || appointmentType === 'CalendarEvent') {
            adjustedState = prepareStateForCalendarEvent(calendarEvent, calendarEventId, defaults);
        } else {
            //default is appointment
            adjustedState = prepareStateForAppointment(null, null, null, defaults, eventBegin, eventUntil, memberList);
        }

        return {
            open,
            user: getUser(),
            ...adjustedState
        };
    }

    componentDidUpdate() {
        const { offices, open, loadingModel, loadingFuneral, loadingPrefill } = this.state;
        if (!open) return;
        if (offices === null) {
            this.loadOffices(); //only attempt to load the offices if the model is open
        }
        if (loadingModel) return;

        const { queryId, query, staffAllocation, appointment, calendarEvent, view } = this.state;
        if (queryId && query && !staffAllocation && !appointment && !calendarEvent) {
            this.queryObjectById(queryId, query, view);
            return;
        }

        if (
            view === 'StaffAllocation' &&
            this.state.funeral === null &&
            !loadingFuneral &&
            staffAllocation &&
            isRelatedObjectDefined(staffAllocation.Funeral)
        ) {
            this.loadAllocationLocations(staffAllocation.Funeral.ID);
        }

        // if appointment unsaved and created from a funeral record, attempt to prefill prefuneral
        if (!!appointment && !appointment.ID && loadingPrefill === null) {
            const { location } = this.props;
            const funLookup = async theID => {
                const { data } = await getClient().query({
                    query: gql`
                        query PrefuneralApp($id: ID!) {
                            readOneFuneral(ID: $id) {
                                ID
                                LegacyKey
                                FirstName
                                MiddleName
                                Surname
                                Informant {
                                    ID
                                    InformantsName
                                    FirstName
                                    Surname
                                    Phone
                                    Mobile
                                }
                            }
                        }
                    `,
                    variables: {
                        id: theID
                    }
                });
                return data;
            };
            const searchmatch = matchPath(location.pathname, { path: `/funeral/:key/:id` });
            if (!!searchmatch && searchmatch.params.id) {
                funLookup(searchmatch.params.id).then(({ readOneFuneral }) => {
                    const newApp = cloneDeep(appointment);
                    newApp.Funeral = !!readOneFuneral ? { ...readOneFuneral } : false;
                    this.setState({ appointment: newApp, loadingPrefill: false });
                });
            }
            this.setState({ loadingPrefill: true });
        }
    }

    render() {
        const { classes } = this.props;
        const {
            open,
            popoverTitle,
            // mutation,
            view,
            edit,
            loadingModel,
            loadingFuneral,
            checkingCollisions,
            loadingOnSave,
            showCollideAllocationsPopup
        } = this.state;

        let content, input;
        if (view === 'Appointment') {
            content = this.renderAppointment();
            input = this.state.appointment;
        } else if (view === 'StaffAllocation') {
            // content = this.renderStaffAllocation();
            // input = this.state.staffAllocation;
            content = this.renderCalendarEvent();
            input = this.state.calendarEvent;
        } else if (view === 'CalendarEvent') {
            content = this.renderCalendarAllocations();
            input = this.state.calendarEvent;
        } else {
            throw new Error('unknown view ' + view);
        }

        const objThing =
            (input &&
                (input.ClassName
                    ? getCalendarCategoryByClassName(input.ClassName)
                    : getCalendarCategoryByType(input.Type))) ||
            null;

        return (
            <Fragment>
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    aria-labelledby="Appointments"
                    aria-describedby="Appointments"
                    open={open}
                    onClose={() => this.onClose()}
                    // anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                    // transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                    className={classes.root}
                >
                    <form>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                overflow: 'hidden',
                                pointerEvents: 'none'
                            }}
                        >
                            <CalendarIcon
                                style={{
                                    opacity: 0.025,
                                    width: 300,
                                    height: 300,
                                    position: 'absolute',
                                    right: -24,
                                    top: 24,
                                    transform: 'rotate(20deg)',
                                    zIndex: 0
                                }}
                            />
                        </div>
                        <div className={classes.paper}>
                            <Typography variant="headline" id="modal-title" className={classes.popoverTitle}>
                                {popoverTitle}
                            </Typography>
                            <div className={classes.paperScroll}>
                                {loadingModel || loadingFuneral ? <Spinner /> : content}
                            </div>
                            <div className={cx(classes.buttonGroup, classes.actionButtons)}>
                                {!!edit &&
                                    !!objThing &&
                                    [
                                        'Appointment',
                                        'PreFuneral',
                                        'Arrangement',
                                        'Unavailable',
                                        'VenueUnavailable'
                                    ].indexOf(objThing.type) > -1 && (
                                        <GatedComponent showComponentCode={'FM_ACCESS_Appointments_Delete'}>
                                            {() => {
                                                return (
                                                    <Button
                                                        onClick={e =>
                                                            ([
                                                                'Appointment',
                                                                'PreFuneral',
                                                                'Arrangement',
                                                                'Unavailable'
                                                            ].indexOf(objThing.type) > -1 &&
                                                                this.handleDeleteAppointment(input.ID)) ||
                                                            (['VenueUnavailable'].indexOf(objThing.type) > -1 &&
                                                                this.handleDeleteCalendarEvent(input.ID)) ||
                                                            undefined
                                                        }
                                                        size="small"
                                                        className={cx(classes.buttonCancel)}
                                                    >
                                                        Delete
                                                    </Button>
                                                );
                                            }}
                                        </GatedComponent>
                                    )}
                                &nbsp;
                                <Button onClick={() => this.onClose()} size="small" color={'primary'}>
                                    {edit ? 'Close' : 'Cancel'}
                                </Button>
                                &nbsp;
                                <Button
                                    onClick={e => this.checkForCollisions(e, { input }, view)}
                                    size="small"
                                    className={cx(classes.buttonOk)}
                                    disabled={this.state.appointment && !this.state.appointment.Type}
                                >
                                    {checkingCollisions
                                        ? 'Checking...'
                                        : loadingOnSave
                                        ? edit
                                            ? 'Saving...'
                                            : 'Adding...'
                                        : edit
                                        ? 'Save'
                                        : 'Add'}
                                </Button>
                            </div>
                            <div style={{ clear: 'both' }} />
                        </div>
                    </form>
                </Dialog>

                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    aria-labelledby="Colliding Alert"
                    aria-describedby="Colliding Alert"
                    open={!!showCollideAllocationsPopup}
                    onClose={() => this.onClose()}
                    className={classes.root}
                >
                    <div className={classes.paper}>
                        <div className={classes.paperScroll}>
                            <ExclamationTriangleIcon
                                style={{ margin: 'auto', display: 'block', fontSize: '3em', color: '#eab35d' }}
                            />
                            <h4>Your appointment may have scheduling conflicts.</h4>
                            <p>{showCollideAllocationsPopup}</p>
                        </div>
                        <div className={cx(classes.buttonGroup, classes.actionButtons)}>
                            <Button
                                onClick={() =>
                                    this.setState({
                                        showCollideAllocationsPopup: false,
                                        checkingCollisions: false
                                    })
                                }
                                size="small"
                                color={'primary'}
                            >
                                make changes
                            </Button>
                            <Button
                                size="small"
                                onClick={e => this.onSaveAppointment(e, { input })}
                                className={cx(classes.buttonOk)}
                                disabled={this.state.appointment && !this.state.appointment.Type}
                            >
                                Save Anyway
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </Fragment>
        );
    }

    renderCalendarEvent() {
        const { calendarEvent, edit /*, type*/ } = this.state;
        if (!calendarEvent) return null;

        const startDate = moment(calendarEvent.Start || null).format('YYYY-MM-DD');
        const startTime = (calendarEvent.Start
            ? moment(calendarEvent.Start)
            : moment()
                  .startOf('day')
                  .hours(9)
        ).format('HH:mm:ss');
        const endDate = moment(calendarEvent.End || null).format('YYYY-MM-DD');
        const endTime = (calendarEvent.End
            ? moment(calendarEvent.End)
            : moment()
                  .startOf('day')
                  .hours(17)
        ).format('HH:mm:ss');

        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <Select
                            id="AppointmentType"
                            allowNone={!edit}
                            disabled={!!edit}
                            options={APPOINTMENT_OPTIONS.filter(
                                e => e.type === 'Appointment' || e.type === 'VenueUnavailable'
                            )}
                            label="Appointment Type"
                            placeholder="Select an Appointment Type..."
                            onChange={e => this.setType(e.target.value)}
                            value={'VenueUnavailable'}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ValidationPlaceholder
                            validationResult={{
                                valid: !this.state.errorVal['Location'],
                                message: this.state.errorVal['Location']
                            }}
                        >
                            <AddressBookAutocomplete
                                form={this}
                                name="calendarEvent.Location"
                                label={'Location'}
                                placeholder="Search for an address book entry..."
                                labelFieldFunc={getLabelWithSuburb}
                                required
                            />
                        </ValidationPlaceholder>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            placeholder="Enter the title of this event..."
                            value={calendarEvent.Title}
                            onChange={e => this.setObjectProperty('calendarEvent', 'Title', e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text={'Date & Time'} />
                        <InlineFlex>
                            <TextField
                                placeholder="From Date"
                                type="date"
                                value={startDate}
                                onChange={e =>
                                    this.setObjectProperty(
                                        'calendarEvent',
                                        'Start',
                                        dateTimeToString(e.target.value + ' ' + startTime)
                                    )
                                }
                                required={true}
                            />
                            <TextField
                                placeholder="From Time"
                                type="time"
                                value={startTime}
                                onChange={e =>
                                    this.setObjectProperty(
                                        'calendarEvent',
                                        'Start',
                                        dateTimeToString(startDate + ' ' + e.target.value)
                                    )
                                }
                                required={true}
                            />
                            <Label>To</Label>
                            <TextField
                                placeholder="To Date"
                                type="date"
                                value={endDate}
                                onChange={e =>
                                    this.setObjectProperty(
                                        'calendarEvent',
                                        'End',
                                        dateTimeToString(e.target.value + ' ' + endTime)
                                    )
                                }
                                required={true}
                            />
                            <TextField
                                placeholder="To Time"
                                type="time"
                                value={endTime}
                                onChange={e =>
                                    this.setObjectProperty(
                                        'calendarEvent',
                                        'End',
                                        dateTimeToString(endDate + ' ' + e.target.value)
                                    )
                                }
                                required={true}
                            />
                        </InlineFlex>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            rows={6}
                            label={'Comments'}
                            placeholder="Enter comments here..."
                            multiline={true}
                            value={calendarEvent.Notes}
                            onChange={e => this.setObjectProperty('calendarEvent', 'Notes', e.target.value)}
                        />
                    </Grid>
                    <div style={{ height: 26, width: '100%' }}>
                        {/* added padding so location autocomplete doesn't cause scrolling */}
                    </div>
                </Grid>
            </Fragment>
        );
    }

    /*
    renderStaffAllocation() {
        const { appointmentType, classes } = this.props;
        const { staffAllocation, user, loadingFuneral, edit } = this.state;
        if (!staffAllocation) return null;

        const assignToMe = user && staffAllocation.Member && staffAllocation.Member.ID === user.ID;
        let eventType = ['Service', 'Viewing', 'Committal', 'Refreshments'].find(e => e === staffAllocation.Type);
        if (eventType === 'Committal') eventType = 'Disposal';
        const eventObj = staffAllocation[eventType];

        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <Select
                            disabled={loadingFuneral === true || !!edit || appointmentType}
                            id="AppointmentType"
                            allowNone={false}
                            options={APPOINTMENT_OPTIONS}
                            label="Appointment Type"
                            placeholder="Select an Appointment Type..."
                            onChange={e => this.setType(e.target.value)}
                            value={staffAllocation.Type}
                        />
                    </Grid>
                    <Grid pc={0.5}>
                        {eventObj && (
                            <small style={{ marginTop: 12, display: 'block' }}>
                                {staffAllocation.Type} starts on {niceDateTimeFromString(eventObj.Start)} until{' '}
                                {niceTimeFromString(eventObj.End)}
                            </small>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <FuneralAutoComplete
                            label="Funeral"
                            placeholder="Type ID or name to find a Funeral..."
                            value={isRelatedObjectDefined(staffAllocation.Funeral) ? staffAllocation.Funeral : null}
                            onSelect={(_, funeral) => this.onSelectFuneral(funeral)}
                            required={true}
                            disabled={loadingFuneral || !!edit}
                        />
                    </Grid>
                    {staffAllocation.Error ? (
                        <Grid item xs={12}>
                            <Label>{staffAllocation.Error}</Label>
                        </Grid>
                    ) : (
                        <Fragment>
                            <Grid item xs={12}>
                                <TextField
                                    label="Title"
                                    placeholder="Add a title..."
                                    value={staffAllocation.Title}
                                    onChange={e => this.setObjectProperty('staffAllocation', 'Title', e.target.value)}
                                    required={true}
                                    disabled={loadingFuneral}
                                />
                            </Grid>

                            {this.renderAllocatedLocation()}

                            <Grid item xs={12}>
                                <Label text={'Staff Allocation Date & Time'} />
                                <InlineFlex>
                                    <TextField
                                        placeholder="From Date"
                                        type="date"
                                        value={staffAllocation.DateFrom}
                                        onChange={e =>
                                            this.setObjectProperty('staffAllocation', 'DateFrom', e.target.value)
                                        }
                                        required={true}
                                        disabled={loadingFuneral}
                                    />
                                    <TextField
                                        placeholder="From Time"
                                        type="time"
                                        value={staffAllocation.TimeFrom}
                                        onChange={e =>
                                            this.setObjectProperty('staffAllocation', 'TimeFrom', e.target.value)
                                        }
                                        required={true}
                                        disabled={loadingFuneral}
                                    />
                                    <Label>To</Label>
                                    <TextField
                                        placeholder="To Date"
                                        type="date"
                                        value={staffAllocation.DateTo}
                                        onChange={e =>
                                            this.setObjectProperty('staffAllocation', 'DateTo', e.target.value)
                                        }
                                        required={true}
                                        disabled={loadingFuneral}
                                    />
                                    <TextField
                                        placeholder="To Time"
                                        type="time"
                                        value={staffAllocation.TimeTo}
                                        onChange={e =>
                                            this.setObjectProperty('staffAllocation', 'TimeTo', e.target.value)
                                        }
                                        required={true}
                                        disabled={loadingFuneral}
                                    />
                                </InlineFlex>
                            </Grid>

                            <Grid item xs={6}>
                                <Checkbox
                                    label="This allocation is for me"
                                    checked={assignToMe}
                                    onChange={e => this.toggleAssignToMe(e.target.checked)}
                                    disabled={loadingFuneral}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <StaffAutoComplete
                                    label={'Assign Staff (one only)'}
                                    selectProps={{ multiple: false }}
                                    onSelect={(_, user) => this.onAssignStaff(user)}
                                    disabled={loadingFuneral}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div className={classes.memberIcons}>
                                    {staffAllocation.Member && (
                                        <UserCircle
                                            user={joinDefined(
                                                [staffAllocation.Member.FirstName, staffAllocation.Member.Surname],
                                                ' '
                                            )}
                                            abbreviation={GetInitials(staffAllocation.Member)}
                                            onDelete={() => this.onUnassignStaff()}
                                        />
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    rows={6}
                                    label={'Comments'}
                                    placeholder="Enter comments here..."
                                    value={staffAllocation.Allocation}
                                    onChange={e =>
                                        this.setObjectProperty('staffAllocation', 'Allocation', e.target.value)
                                    }
                                    multiline={true}
                                    disabled={loadingFuneral}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }
*/
    renderAppointment() {
        const { appointmentType, allowRecurring, allowLocation, allowOfficeChange, classes } = this.props;
        const { appointment, user, offices, edit } = this.state;
        if (!appointment) return null;

        const assignToMe = user && IndexOf(appointment.Members, x => x.ID === user.ID) >= 0;
        const requireLocation = appointment.Type !== 'Unavailable';
        const { Funeral } = appointment;

        return (
            <Fragment>
                <Grid container spacing={16}>
                    {(appointment.Type && (
                        <Fragment>
                            <Grid item xs sm={6}>
                                <Select
                                    //disabled={edit}
                                    id="AppointmentType"
                                    options={
                                        appointmentType
                                            ? APPOINTMENT_OPTIONS.filter(e => e.type === appointmentType)
                                            : APPOINTMENT_OPTIONS.filter(
                                                  e =>
                                                      e.type === 'Appointment' ||
                                                      (e.type === 'VenueUnavailable' && !edit)
                                              )
                                    }
                                    label="Appointment Type"
                                    placeholder="Select an Appointment Type..."
                                    onChange={e => this.setType(e.target.value)}
                                    value={appointment.Type}
                                />
                            </Grid>

                            {(appointment.Type === 'Appointment' && (
                                <Grid item xs={12} sm={6}>
                                    <Select
                                        id="Office"
                                        disabled={allowOfficeChange === false}
                                        options={offices || []}
                                        label="Office"
                                        placeholder="Select an Office..."
                                        onChange={e => this.setObjectProperty('appointment', 'Office', e.target.value)}
                                        value={appointment.Office}
                                    />
                                </Grid>
                            )) ||
                                (appointment.Type === 'Arrangement' && (
                                    <Grid item xs={12} sm={6}>
                                        <EnquiryAutoComplete
                                            label="Enquiry"
                                            value={
                                                isRelatedObjectDefined(appointment.Enquiry) ? appointment.Enquiry : null
                                            }
                                            onSelect={(_, enquiry) => {
                                                const Relation = APPLICANT_RELATIONSHIP_OPTIONS.find(
                                                    e => e.value.toLowerCase() === enquiry.RelationshipToDeceased
                                                );
                                                const Reason = `${
                                                    enquiry.EnquiryType === 'PRENEED' ? 'Pre-Need' : 'At-Need'
                                                } appointment for ${joinDefined(
                                                    [enquiry.GivenName, enquiry.Surname],
                                                    ' '
                                                )} ${(enquiry.RelationshipToDeceased !== 'self' &&
                                                    joinDefined(
                                                        [
                                                            ' - with ',
                                                            enquiry.EnquirerGivenName,
                                                            enquiry.EnquirerSurname,
                                                            Relation && '(' + Relation.label + ')'
                                                        ],
                                                        ' '
                                                    )) ||
                                                    ''}`;
                                                const Phone = enquiry.EnquirerPhone || null;
                                                const LocationResidentialAddress = {
                                                    ...enquiry.EnquirerResidentialAddress
                                                };

                                                this.setObjectProperty('appointment', 'Enquiry', enquiry);
                                                this.setObjectProperty('appointment', 'Reason', Reason);
                                                this.setObjectProperty('appointment', 'Phone', Phone);
                                                this.setObjectProperty(
                                                    'appointment',
                                                    'LocationResidentialAddress',
                                                    LocationResidentialAddress
                                                );
                                            }}
                                            required={true}
                                            disabled={!allowOfficeChange || !!isRelatedObjectDefined(Funeral)}
                                        />
                                    </Grid>
                                )) ||
                                (appointment.Type === 'PreFuneral' && (
                                    <Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <FuneralAutoComplete
                                                label="Funeral"
                                                value={isRelatedObjectDefined(Funeral) ? Funeral : null}
                                                findById={!!Funeral && Funeral.ID}
                                                onSelect={(_, newFuneral) => this.setAsPrefuneral(newFuneral)}
                                                required={true}
                                                disabled={
                                                    !allowOfficeChange || !!isRelatedObjectDefined(appointment.Enquiry)
                                                }
                                            />
                                        </Grid>
                                        {isRelatedObjectDefined(Funeral) && (
                                            <Grid item xs={12} sm={6}>
                                                <OutlineButton
                                                    target="_blank"
                                                    onClick={() => {
                                                        const win = window.open(
                                                            `/funeral/${Funeral.LegacyKey}/${Funeral.ID}`
                                                        );
                                                        win.focus();
                                                    }}
                                                >
                                                    View Funeral {Funeral.LegacyKey}
                                                </OutlineButton>
                                            </Grid>
                                        )}
                                    </Fragment>
                                ))}

                            <Grid item xs={12}>
                                <TextField
                                    validationResult={{
                                        valid: !this.state.errorVal['Reason'],
                                        message: this.state.errorVal['Reason']
                                    }}
                                    label="Reason"
                                    placeholder="Enter the reason for this appointment..."
                                    value={appointment.Reason}
                                    onChange={e => this.setObjectProperty('appointment', 'Reason', e.target.value)}
                                    required={true}
                                />
                            </Grid>

                            {!!allowLocation && (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <InlineHeader header={`Location${requireLocation ? ' *' : ''}`}>
                                            <Checkbox
                                                label="Phone call only"
                                                checked={appointment.LocationType === 'Phone'}
                                                onChange={e => {
                                                    this.setObjectProperty(
                                                        'appointment',
                                                        'LocationType',
                                                        !!e.target.checked ? 'Phone' : 'Residential'
                                                    );
                                                    this.setObjectProperty(
                                                        'appointment',
                                                        'TimeTo',
                                                        adjustTimeString(
                                                            appointment.TimeFrom,
                                                            !!e.target.checked ? 15 : 120
                                                        )
                                                    );
                                                    const { errorVal } = this.state;
                                                    delete errorVal.Phone;
                                                    delete errorVal.Location;
                                                    this.setState({ errorVal });
                                                }}
                                            />
                                            <Checkbox
                                                label="Use address book"
                                                checked={appointment.LocationType === 'AddressBook'}
                                                onChange={e =>
                                                    this.setObjectProperty(
                                                        'appointment',
                                                        'LocationType',
                                                        !!e.target.checked ? 'AddressBook' : 'Residential'
                                                    )
                                                }
                                            />
                                        </InlineHeader>

                                        <Grid item xs={12}>
                                            <ValidationPlaceholder
                                                validationResult={{
                                                    valid: !this.state.errorVal['Location'],
                                                    message: this.state.errorVal['Location']
                                                }}
                                            >
                                                {(appointment.LocationType === 'AddressBook' && (
                                                    <AddressBookAutocomplete
                                                        form={this}
                                                        name="appointment.Location"
                                                        //label={'Address'}
                                                        placeholder="Search for an address book entry..."
                                                        labelFieldFunc={getLabelWithSuburb}
                                                        required={requireLocation}
                                                    />
                                                )) ||
                                                    (appointment.LocationType === 'Residential' && (
                                                        <AddressAutoComplete
                                                            style={{ width: '100%' }}
                                                            //label={'Address'}
                                                            placeholder={'Search for an address...'}
                                                            componentFields={APPOINTMENT_RESIDENTIAL_COMPONENTS}
                                                            form={this}
                                                            required={requireLocation}
                                                            allowCustomAddress={true}
                                                        />
                                                    ))}
                                            </ValidationPlaceholder>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    validationResult={{
                                        valid: !this.state.errorVal['Phone'],
                                        message: this.state.errorVal['Phone']
                                    }}
                                    label="Phone number"
                                    placeholder={'Enter a phone number...'}
                                    value={appointment.Phone}
                                    onChange={e => this.setObjectProperty('appointment', 'Phone', e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon color="primary">phone</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    required={appointment.LocationType === 'Phone'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Label>Date & Time</Label>
                                <InlineFlex>
                                    <TextField
                                        placeholder="From Date"
                                        type="date"
                                        value={appointment.Date}
                                        onChange={e => {
                                            this.setObjectProperty('appointment', 'Date', e.target.value);
                                            this.setObjectProperty('appointment', 'DateTo', e.target.value);
                                        }}
                                        required={true}
                                    />
                                    <TextField
                                        placeholder="From Time"
                                        disabled={appointment.AllDay}
                                        type="time"
                                        value={appointment.TimeFrom}
                                        onChange={e => {
                                            const diff = moment(appointment.Date + ' ' + appointment.TimeFrom).diff(
                                                moment(appointment.DateTo + ' ' + appointment.TimeTo),
                                                'minutes'
                                            );
                                            this.setObjectProperty('appointment', 'TimeFrom', e.target.value);
                                            const timeTo = adjustTimeString(e.target.value, -diff);
                                            this.setObjectProperty('appointment', 'TimeTo', timeTo);
                                        }}
                                        required={true}
                                    />
                                    <Label>To</Label>
                                    <TextField
                                        placeholder="To Time"
                                        disabled={appointment.AllDay}
                                        type="time"
                                        value={appointment.TimeTo}
                                        onChange={e => this.setObjectProperty('appointment', 'TimeTo', e.target.value)}
                                        required={true}
                                    />
                                </InlineFlex>
                            </Grid>

                            <Grid item xs={6}>
                                <OutlineButton
                                    target="_blank"
                                    onClick={() => {
                                        const win = window.open('/calendar/' + appointment.Date);
                                        win.focus();
                                    }}
                                >
                                    Open Calendar
                                </OutlineButton>
                            </Grid>

                            {!!allowRecurring && (
                                <Grid item xs={6}>
                                    <InlineFlex>
                                        <Checkbox
                                            label={appointment.Recuring ? 'Until' : 'Recurring?'}
                                            checked={appointment.Recuring}
                                            onChange={e =>
                                                this.setObjectProperty('appointment', 'Recuring', e.target.checked)
                                            }
                                        />
                                        {appointment.Recuring && (
                                            <TextField
                                                placeholder="To Date"
                                                type="date"
                                                value={appointment.DateTo}
                                                onChange={e => {
                                                    this.setObjectProperty('appointment', 'DateTo', e.target.value);
                                                }}
                                                required={true}
                                            />
                                        )}
                                    </InlineFlex>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Checkbox
                                    label="This appointment is for me"
                                    checked={assignToMe}
                                    onChange={e => this.toggleAssignToMe(e.target.checked)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <ValidationPlaceholder
                                    validationResult={{
                                        valid: !this.state.errorVal['Members'],
                                        message: this.state.errorVal['Members']
                                    }}
                                >
                                    <StaffAutoComplete
                                        label={'Assign Staff (multiple)'}
                                        selectProps={{ multiple: true }}
                                        onSelect={(_, user) => this.onAssignStaff(user)}
                                    />
                                </ValidationPlaceholder>
                            </Grid>

                            {appointment.Members.length > 0 && (
                                <Grid item xs={12}>
                                    <div className={classes.memberIcons}>
                                        {appointment.Members.map((obj, i) => (
                                            <UserCircle
                                                key={i}
                                                user={joinDefined([obj.FirstName, obj.Surname], ' ')}
                                                abbreviation={GetInitials(obj)}
                                                onDelete={() => this.onUnassignStaff(i)}
                                            />
                                        ))}
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    rows={6}
                                    label={'Comments'}
                                    placeholder="Enter comments here..."
                                    multiline={true}
                                    value={appointment.Comment}
                                    onChange={e => this.setObjectProperty('appointment', 'Comment', e.target.value)}
                                />
                            </Grid>
                        </Fragment>
                    )) || (
                        <Fragment>
                            <Grid item xs={12}>
                                <RadioGroup
                                    row={false}
                                    required
                                    id="AppointmentType"
                                    options={APPOINTMENT_OPTIONS.filter(
                                        e => e.type === 'Appointment' || (e.type === 'VenueUnavailable' && !edit)
                                    )}
                                    onChange={e => this.setType(e.target.value)}
                                    value={appointment.Type}
                                />
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={12}>
                                    <p>Choose a type of Appointment to continue...</p>
                                </Grid>
                            </Hidden>
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderCalendarAllocations() {
        const { calendarEvent } = this.state;
        if (!calendarEvent) return null;

        const startTime =
            calendarEvent.Start ||
            moment()
                .startOf('day')
                .hours(7)
                .format('YYYY-MM-DD HH:mm:ss');
        const endTime =
            calendarEvent.End ||
            moment()
                .endOf('day')
                .hours(17)
                .format('YYYY-MM-DD HH:mm:ss');

        const eventType = getCalendarCategoryByClassName(calendarEvent.ClassName);

        return (
            <Fragment>
                <Grid container spacing={24}>
                    <StaffAllocationTable
                        funeralId={calendarEvent.Funeral && calendarEvent.Funeral.ID}
                        staffAllocations={calendarEvent.StaffAllocations}
                        onChange={(i, obj) => this.onChangeStaffAllocation(i, obj)}
                        onDelete={i => this.onDeleteStaffAllocation(i)}
                        onAdd={obj => this.onAddStaffAllocation(obj)}
                        startTime={startTime.replace(/\d\d\d\d-\d\d-\d\d\s/, '')}
                        endTime={endTime.replace(/\d\d\d\d-\d\d-\d\d\s/, '')}
                        date={startTime.replace(/\s.*/, '')}
                    />

                    <Grid item xs={12}>
                        <TextField label="Event Type" value={eventType.label} disabled={true} />
                    </Grid>

                    <Grid item xs={12}>
                        <InlineFlex>
                            <TextField
                                label="Start"
                                value={moment(startTime).format('D/MM/YYYY h:mma')}
                                disabled={true}
                            />
                            <Label>To</Label>
                            <TextField label="End" value={moment(endTime).format('D/MM/YYYY h:mma')} disabled={true} />
                        </InlineFlex>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Funeral"
                            value={
                                (!!calendarEvent.Funeral &&
                                    joinDefined(
                                        [
                                            calendarEvent.Funeral.LegacyKey,
                                            calendarEvent.Funeral.FirstName,
                                            calendarEvent.Funeral.Surname
                                        ],
                                        ' '
                                    )) ||
                                ''
                            }
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Location"
                            value={
                                !!calendarEvent.Location && calendarEvent.Location.ID > 0
                                    ? calendarEvent.Location.Name + ', ' + calendarEvent.Location.Suburb
                                    : ''
                            }
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    onChangeStaffAllocation(index, update) {
        const { calendarEvent } = this.state;
        // reapply values, 'cause old ones might not have it
        update.Type = getCalendarCategoryByClassName(calendarEvent.ClassName).type;
        update.Funeral = calendarEvent.Funeral;
        update.DateFrom = moment(calendarEvent.Start).format('YYYY-MM-DD');
        update.DateTo = moment(calendarEvent.End).format('YYYY-MM-DD');
        Object.assign(calendarEvent.StaffAllocations[index], update);
        this.setState({ calendarEvent });
    }

    onDeleteStaffAllocation(index) {
        const { calendarEvent } = this.state;
        calendarEvent.StaffAllocations.splice(index, 1);
        this.setState({ calendarEvent });
    }

    onAddStaffAllocation(newStaffAllocation) {
        const { calendarEvent, staffAllocation } = this.state;

        //create based on an existing one
        const { Member } = newStaffAllocation;
        newStaffAllocation = createStaffAllocation(staffAllocation);

        // reapply values, 'cause new ones don't have it
        newStaffAllocation.Type = getCalendarCategoryByClassName(calendarEvent.ClassName).type;
        newStaffAllocation.Funeral = calendarEvent.Funeral;
        newStaffAllocation.DateFrom = moment(calendarEvent.Start).format('YYYY-MM-DD');
        newStaffAllocation.DateTo = moment(calendarEvent.End).format('YYYY-MM-DD');

        delete newStaffAllocation.ID;
        newStaffAllocation.Member = Member;

        calendarEvent.StaffAllocations.push(newStaffAllocation);
        this.setState({ calendarEvent });
    }

    renderAllocatedLocation() {
        const { staffAllocation, placeOfViewingOptions } = this.state;

        if (!isRelatedObjectDefined(staffAllocation.Funeral)) {
            return null;
        }

        if (staffAllocation.Type === 'Viewing' && placeOfViewingOptions.length > 0) {
            return (
                <Grid item xs={12}>
                    <Select
                        label={'Place of Viewing'}
                        placeholder="Select a Place of Viewing..."
                        options={placeOfViewingOptions}
                        allowNone={false}
                        value={
                            isRelatedObjectDefined(staffAllocation.Viewing, 'Location')
                                ? staffAllocation.Viewing.ID
                                : null
                        }
                        onSelect={e => {
                            const { staffAllocation } = this.state;
                            staffAllocation.Viewing.ID = e.target.value;
                            this.setState({ staffAllocation });
                        }}
                    />
                </Grid>
            );
        }

        if (staffAllocation.Type === 'Service' && isRelatedObjectDefined(staffAllocation.Service, 'Location')) {
            return (
                <Grid item xs={12}>
                    <TextField label={'Location'} disabled={true} value={staffAllocation.Service.Location.Name} />
                </Grid>
            );
        }

        if (staffAllocation.Type === 'Committal' && isRelatedObjectDefined(staffAllocation.Disposal, 'Location')) {
            return (
                <Grid item xs={12}>
                    <TextField label={'Location'} disabled value={staffAllocation.Disposal.Location.Name} />
                </Grid>
            );
        }

        if (
            staffAllocation.Type === 'Refreshments' &&
            isRelatedObjectDefined(staffAllocation.Refreshments, 'Location')
        ) {
            return (
                <Grid item xs={12}>
                    <TextField label={'Location'} disabled value={staffAllocation.Refreshments.Location.Name} />
                </Grid>
            );
        }

        return null;
    }

    onSelectFuneral(funeral) {
        const { staffAllocation } = this.state;
        staffAllocation.Funeral = funeral;
        staffAllocation.Service = null;
        staffAllocation.Viewing = null;
        staffAllocation.Disposal = null;
        staffAllocation.Refreshments = null;
        staffAllocation.Title = [
            staffAllocation.Funeral.LegacyKey,
            staffAllocation.Funeral.FirstName,
            staffAllocation.Funeral.Surname
        ].join(' ');
        this.setState({ staffAllocation, placeOfViewingOptions: [], funeral: null });
    }

    loadAllocationLocations(funeralId) {
        let placeOfViewingOptions = [];
        const that = this;
        getClient()
            .query({
                query: readAllocatableFuneralLocations,
                variables: { id: funeralId }
            })
            .then(
                ({ data }) => {
                    if (!data || !data.readOneFuneral) {
                        that.onError('failed to get funeral', data, { loadingFuneral: false, funeral: {} });
                        return;
                    }

                    const funeral = data.readOneFuneral;
                    placeOfViewingOptions = funeral.PlaceOfViewingItems.filter(
                        node => !!(node.Location.ID && node.Location.ID > 0)
                    ).map(node => {
                        return {
                            label: node.Location.Name,
                            value: node.ID
                        };
                    });
                    that.selectLocation(funeral);
                    that.setState({ placeOfViewingOptions, loadingFuneral: false, funeral });
                },
                error => that.onError('failed to get funeral', error, { loadingFuneral: false, funeral: {} })
            );

        this.setState({ loadingFuneral: true });
    }

    loadOffices() {
        const that = this;
        getUtilitiesClient()
            .query({ query: getOfficeNames })
            .then(
                ({ data }) => {
                    if (!data || !data.readOfficesUsernames) {
                        that.onError('failed to get offices. data empty', data);
                        return;
                    }

                    const offices = data.readOfficesUsernames.edges.map(({ node }) => {
                        return { label: node.BusinessName, value: node.LegacyKey, metadata: node };
                    });

                    that.setState({ offices });
                },
                error => that.onError('failed to get offices', error)
            );
        //do this so that it doesn't try to infinitely load the offices
        this.setState({ offices: [] });
    }

    queryObjectById(id, query, view) {
        const that = this;
        getCalendarClient()
            .query({ query, variables: { id }, fetchPolicy: 'network-only' })
            .then(
                ({ data }) => {
                    if (!data) {
                        that.onError(`failed to get ${view}`, data, {
                            loadingModel: false
                        });
                        return;
                    }

                    if (view === 'Appointment') {
                        let appointment;
                        if (data.readOneStaffAllocation)
                            appointment = createAppointment(data.readOneStaffAllocation.Appointment);
                        else if (data.readOneAppointment) appointment = createAppointment(data.readOneAppointment);

                        that.setState({ appointment, loadingModel: false });
                    } else if (view === 'StaffAllocation') {
                        const calendarEvent = createCalendarEvent(data.readOneCalendarEvent);
                        that.setState({
                            calendarEvent,
                            loadingModel: false
                        });
                    } else if (view === 'CalendarEvent') {
                        const calendarEvent = createCalendarEvent(data.readOneCalendarEvent);
                        that.setState({
                            calendarEvent,
                            //use this staff alloc as the default to clone from when adding new
                            staffAllocation:
                                calendarEvent.StaffAllocations && calendarEvent.StaffAllocations.length > 0
                                    ? createStaffAllocation(calendarEvent.StaffAllocations[0])
                                    : null,
                            loadingModel: false
                        });
                    } else {
                        that.onError(view + ' events not supported for query by id', null, { loadingModel: false });
                    }
                },
                error =>
                    that.onError(`failed to get ${view}`, error, {
                        loadingModel: false
                    })
            );
        //do this so that it doesn't try to infinitely load the offices
        this.setState({ query: null, queryId: null, loadingModel: true });
    }

    selectLocation(funeral) {
        const { staffAllocation, useTimesFromFuneral } = this.state;
        let times, validationError, Viewing, Service, Disposal, Refreshments;

        if (staffAllocation.Type === 'Viewing') {
            if (
                funeral.PlaceOfViewingItems.length === 0 ||
                funeral.PlaceOfViewingItems.filter(e => e.Location && e.Location.ID > 0).length === 0
            ) {
                validationError = 'Funeral does not have any Places of Viewing';
            } else {
                Viewing = funeral.PlaceOfViewingItems[0];
                times = Viewing;
            }
        } else if (staffAllocation.Type === 'Service') {
            if (!isRelatedObjectDefined(funeral.PlaceOfService, 'Location')) {
                validationError = 'Funeral does not have a Place of Service';
            } else {
                Service = funeral.PlaceOfService;
                times = Service;
            }
        } else if (staffAllocation.Type === 'Committal') {
            if (!isRelatedObjectDefined(funeral.Disposal, 'Location')) {
                validationError = 'Funeral does not have a Place of Committal';
            } else {
                Disposal = funeral.Disposal;
                times = Disposal;
            }
        } else if (staffAllocation.Type === 'Refreshments') {
            if (!isRelatedObjectDefined(funeral.RefreshmentsVenue, 'Location')) {
                validationError = 'Funeral does not have a Refreshments Venue';
            } else {
                Refreshments = funeral.RefreshmentsVenue;
                times = Refreshments;
            }
        }

        if (!useTimesFromFuneral && times) {
            extractAndAdjustTime(times.Start, -30, staffAllocation, 'DateFrom', 'TimeFrom');
            extractAndAdjustTime(times.End, 30, staffAllocation, 'DateTo', 'TimeTo');
        }

        this.setState({
            staffAllocation: {
                ...staffAllocation,
                Error: validationError,
                Viewing,
                Service,
                Disposal,
                Refreshments
            }
        });
    }

    validateForm(e) {
        let valid = true; // use this flag to determine if a form has error or not
        this.setState({ errorVal: [] }); // reset all the errors

        if (!!e.target && e.target.form && !e.target.form.checkValidity()) {
            valid = false;
            // check error from each form field for displaying
            const elem = e.target.form.elements;
            for (var i = 0; i < elem.length; i++) {
                elem[i].reportValidity();
            }
        }

        // Additional error checking
        const { appointment, calendarEvent, type } = this.state;
        if (type !== 'VenueUnavailable') {
            if (!!appointment && appointment.Members.length <= 0) {
                this.setObjectProperty('errorVal', 'Members', 'At least one staff member must be selected.');
                valid = false;
            }
            if (!!appointment && !appointment.Reason) {
                this.setObjectProperty('errorVal', 'Reason', 'Please enter a reason for this appointment.');
                valid = false;
            }
        }
        if (type !== 'Unavailable' && !!this.props.allowLocation) {
            if (type === 'VenueUnavailable') {
                if (!!calendarEvent && !calendarEvent.Location) {
                    this.setObjectProperty('errorVal', 'Location', 'Please enter a location.');
                    valid = false;
                }
            } else if (!!appointment) {
                const { LocationType } = appointment;
                if (LocationType === 'Phone' && !appointment.Phone) {
                    this.setObjectProperty('errorVal', 'Phone', 'Please enter a phone number.');
                    valid = false;
                } else if (LocationType === 'AddressBook' && !appointment.Location) {
                    this.setObjectProperty('errorVal', 'Location', 'Please enter a location.');
                    valid = false;
                } else if (LocationType === 'Residential' && !appointment.LocationResidentialAddress) {
                    this.setObjectProperty('errorVal', 'Location', 'Please enter a location.');
                    valid = false;
                }
            }
        }
        return valid;
    }

    checkForCollisions(e, variables, view) {
        if (this.validateForm(e)) {
            if (view === 'Appointment') {
                const that = this;
                this.setState({ checkingCollisions: true });
                const { input } = variables;
                getCalendarClient()
                    .query({
                        fetchPolicy: 'network-only',
                        query: searchCollidingStaffAllocations,
                        variables: {
                            from: moment(input.Date + ' ' + input.TimeFrom).subtract(30, 'minutes'),
                            to: moment(input.DateTo + ' ' + input.TimeTo).add(30, 'minutes')
                        }
                    })
                    .then(
                        ({ data }) => {
                            if (!data || !data.readStaffAllocations) {
                                that.onError('failed to get colliding staff allocations. data empty', data);
                                return;
                            }

                            const hasCollisions = data.readStaffAllocations.filter(
                                c => c.Appointment.ID !== input.ID && input.Members.find(m => m.ID === c.Member.ID)
                            );

                            if (hasCollisions.length) {
                                const uniques = [
                                    ...new Set(
                                        hasCollisions.map(obj => `${obj.Member.FirstName} ${obj.Member.Surname || ''}`)
                                    )
                                ];
                                that.setState({
                                    showCollideAllocationsPopup: `${joinDefined(uniques, ', ')} ${
                                        uniques.length !== 1 ? 'are' : 'is'
                                    } already busy.`
                                });
                            } else {
                                that.onSaveAppointment(null, variables);
                            }
                        },
                        error => that.onError('failed to get colliding staff allocations.', error)
                    );
            } else {
                this.onSaveAppointment(e, variables);
            }
        }
    }

    onSaveAppointment(e, variables) {
        if (!!e) e.preventDefault();
        const { mutation } = this.state;

        this.setState({
            showCollideAllocationsPopup: false,
            checkingCollisions: false,
            loadingOnSave: true
        });

        if (!e || this.validateForm(e)) {
            const { sanitize, type } = this.state;
            const that = this;
            const sanitizedVariables = sanitize(deleteTypeName(variables));

            if (type !== 'PreFuneral') delete sanitizedVariables.input.FuneralID;

            getCalendarClient()
                .mutate({
                    mutation: mutation,
                    variables: sanitizedVariables
                })
                .then(
                    result => {
                        const { onMutate } = that.props;
                        if (onMutate) onMutate();
                        that.onClose();
                    },
                    error => {
                        that.onError(
                            `failed to ${that.state.edit ? 'update existing' : 'create new '} ${that.state.view}`,
                            error
                        );
                        that.onClose();
                    }
                );
        }
    }

    onApply = (e, mutate, variables) => {
        e.preventDefault();
        let errorFlag = false; // use this flag to determine if a form has error or not
        this.setState({ errorVal: [] }); // reset all the errors

        if (!e.currentTarget.form.checkValidity()) {
            errorFlag = true;
            // check error from each form field for displaying
            const elem = e.currentTarget.form.elements;
            for (var i = 0; i < elem.length; i++) {
                elem[i].reportValidity();
            }
        }

        // Additional error checking
        if (this.state.type === 'Appointment' && this.state.appointment.Members.length <= 0) {
            this.setObjectProperty('errorVal', 'Members', 'At least one staff member must be selected.');
            errorFlag = true;
        }

        if (!errorFlag) {
            const { sanitize } = this.state;
            const that = this;
            const sanitizedVariables = sanitize(deleteTypeName(variables));

            mutate({ variables: sanitizedVariables }).then(
                result => {
                    const { onMutate } = that.props;
                    if (onMutate) onMutate();
                    that.onClose();
                },
                error => {
                    that.onError(
                        `failed to ${this.state.edit ? 'update existing' : 'create new '} ${this.state.view}`,
                        error
                    );
                    that.onClose();
                }
            );
        }
    };

    openPopover = e => {
        if (!isSignedIn()) return;

        this.setState({
            open: true,
            anchorEl: e.currentTarget,
            user: getUser(),
            type: 'Appointment',
            appointment: createAppointment(),
            staffAllocation: createStaffAllocation(),
            popoverTitle: 'New Appointment',
            mutation: createAppointmentMutation,
            sanitize: sanitizeAppointment,
            edit: false
        });
    };

    setType(type) {
        const { edit } = this.state;
        let { /*staffAllocation,*/ calendarEvent, appointment, view } = this.state;

        if (view === 'CalendarEvent') throw new Error('Cannot change type for ' + view);

        const nextViewType = getAppointmentOptionType(type);
        const nextViewTitle = APPOINTMENT_OPTIONS.find(e => type === e.value);
        if (nextViewType === 'Appointment') {
            if (!appointment) appointment = createAppointment();
            appointment.Type = type;
            this.setState({
                type,
                mutation: edit ? updateAppointmentMutation : createAppointmentMutation,
                sanitize: sanitizeAppointment,
                popoverTitle: `${edit ? 'Edit' : 'New'} ${(nextViewTitle && nextViewTitle.label) || 'Appointment'}`,
                view: 'Appointment',
                appointment
            });
            if (type === 'PreFuneral') this.setAsPrefuneral();
            // } else if (nextViewType === 'StaffAllocation') {
            //     if (!staffAllocation) {
            //         const defaults = {};
            //         defaults.DateFrom = appointment.Date;
            //         defaults.DateTo = appointment.DateTo;
            //         defaults.TimeFrom = appointment.TimeFrom;
            //         defaults.TimeTo = appointment.TimeTo;
            //         defaults.Member = appointment.Members[0] || null;
            //         staffAllocation = createStaffAllocation(null, defaults);
            //     }
            //     staffAllocation.Type = type;
            //     this.setState({
            //         type,
            //         staffAllocation,
            //         mutation: edit ? updateStaffAllocationMutation : createStaffAllocationMutation,
            //         sanitize: sanitizeStaffAllocation,
            //         view: 'StaffAllocation',
            //         popoverTitle: `${edit ? 'Edit' : 'New'} Staff Allocation`
            //     });
            //     if (isRelatedObjectDefined(staffAllocation.Funeral)) this.selectLocation(this.state.funeral);
        } else if (nextViewType === 'VenueUnavailable') {
            if (!calendarEvent) {
                const defaults = {};
                defaults.Start = joinDefined([appointment.Date, appointment.TimeFrom], ' ');
                defaults.End = joinDefined([appointment.DateTo, appointment.TimeTo], ' ');
                defaults.Member = appointment.Members[0] || null;
                defaults.ClassName = 'FuneralManager\\CalendarEvent';
                calendarEvent = createCalendarEvent({}, defaults);
            }
            appointment.Type = type;
            this.setState({
                type,
                mutation: edit ? updateCalendarEventMutation : createCalendarEventMutation,
                sanitize: sanitizeCalendarEvent,
                popoverTitle: `${edit ? 'Edit' : 'New'} ${(nextViewTitle && nextViewTitle.label) || 'Calendar Event'}`,
                view: 'StaffAllocation',
                calendarEvent
            });
        } else {
            throw new Error('Cannot change type for ' + view);
        }
    }

    setAsPrefuneral = funeral => {
        const { appointment } = this.state;
        if (!funeral) funeral = appointment.Funeral;
        if (!funeral) return;
        this.setObjectProperty('appointment', 'Funeral', funeral);
        if (!!funeral.Informant)
            this.setObjectProperty('appointment', 'Phone', funeral.Informant.Mobile || funeral.Informant.Phone);
        this.setObjectProperty(
            'appointment',
            'Reason',
            `Pre-Funeral appointment for ${joinDefined([funeral.FirstName, funeral.Surname], ' ')}` +
                (!!funeral.Informant
                    ? ` - with ${joinDefined([funeral.Informant.FirstName, funeral.Informant.Surname], ' ') ||
                          funeral.Informant.InformantsName ||
                          ''}`
                    : '')
        );
    };

    onAssignStaff(user) {
        const { view } = this.state;
        if (view === 'Appointment') {
            const { appointment } = this.state;
            appointment.Members.push(user);
            this.setState({ appointment });
        } else if (view === 'StaffAllocation') {
            const { staffAllocation } = this.state;
            staffAllocation.Member = user;
            this.setState({ staffAllocation });
        } else {
            throw new Error('Cannot Assign staff for ' + view);
        }
    }

    onUnassignStaff(index) {
        const { view } = this.state;
        if (view === 'Appointment') {
            const { appointment } = this.state;
            appointment.Members.splice(index, 1);
            this.setState({ appointment });
        } else if (view === 'StaffAllocation') {
            const { staffAllocation } = this.state;
            staffAllocation.Member = null;
            this.setState({ staffAllocation });
        } else {
            throw new Error('Cannot Unassign staff for ' + view);
        }
    }

    toggleAssignToMe(assignToMe) {
        const { view } = this.state;
        if (view === 'Appointment') {
            const { appointment, user } = this.state;
            const myIndex = IndexOf(appointment.Members, x => x.ID === user.ID);

            if (assignToMe && myIndex < 0) {
                appointment.Members.push(user);
            } else if (!assignToMe && myIndex >= 0) {
                appointment.Members.splice(myIndex, 1);
            }

            this.setState({ appointment });
        } else if (view === 'StaffAllocation') {
            const { staffAllocation, user } = this.state;
            if (assignToMe) {
                staffAllocation.Member = user;
            } else if (staffAllocation.Member && staffAllocation.Member.ID === user.ID) {
                staffAllocation.Member = null;
            }
            this.setState({ staffAllocation });
        } else {
            throw new Error('Cannot toggle assigned for ' + view);
        }
    }

    toggleAllDay(allDay) {
        const { appointment } = this.state;
        appointment.AllDay = allDay;
        appointment.TimeFrom = '08:00';
        appointment.TimeTo = '17:00';
        this.setState({ appointment });
    }

    setObjectProperty = (objectName, propertyName, value) => {
        const target = this.state[objectName];
        target[propertyName] = value;
        this.setState({ [objectName]: target });
    };

    onClose() {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.clear();
    }

    clear() {
        this.setState({
            appointment: null,
            staffAllocation: null,
            calendarEvent: null,
            query: null,
            queryId: null,
            placeOfViewingOptions: [],
            funeral: null,
            loadingFuneral: false,
            loadingModel: false,
            edit: false,
            open: false,
            useTimesFromFuneral: null,
            view: 'Appointment',
            loadingOnSave: false,
            loadingPrefill: null,
            checkingCollisions: false,
            errorVal: []
        });
    }

    onError(msg, obj, state) {
        const errorMessage = msg + (obj && obj.message ? '. Reason: ' + obj.message : '');
        this.props.setSnackbarMessage(errorMessage);
        console.error(errorMessage, obj);
        if (state) this.setState(state);
    }

    getState = property => {
        return getProperty(this.state, property);
    };

    setState = newState => {
        setPropertyWrapper(this.state, newState);
        this.forceUpdate();
    };

    handleDeleteAppointment = eventID => {
        if (!window.confirm('Are you sure you want to delete this appointment?')) return null;
        this.doDelete(eventID, deleteAppointment);
    };

    handleDeleteCalendarEvent = eventID => {
        if (!window.confirm('Are you sure you want to delete this event?')) return null;
        this.doDelete(eventID, deleteCalendarEvent);
    };

    doDelete = async (eventID, mutation) => {
        const me = this;
        const deletion = await getCalendarClient()
            .mutate({ mutation: mutation, variables: { IDs: [eventID] } })
            .then(data => {
                const { onMutate } = me.props;
                if (onMutate) onMutate();
                me.onClose();
            });
        return !!deletion;
    };

    getValidation = () =>
        // just a stubb, to prevent warnings!
        ({ message: null, requirement: null, valid: true, shouldUpdate: false, dependentFields: [] });
}

const deleteAppointment = gql`
    mutation deleteAppointment($IDs: [ID]!) {
        deleteAppointment(IDs: $IDs) {
            ID
        }
    }
`;

const deleteCalendarEvent = gql`
    mutation deleteCalendarEvent($IDs: [ID]!) {
        deleteCalendarEvent(IDs: $IDs) {
            ID
        }
    }
`;

const extractAndAdjustTime = (dateTimeString, minutes, obj, dateProperty, timeProperty) => {
    if (!dateTimeString) return;

    const parts = dateTimeString.split(' ');
    obj[dateProperty] = parts[0];

    var adjustedDate = new Date(stringToDate(dateTimeString).getTime() + minutes * 60000);
    obj[timeProperty] = `${pad(adjustedDate.getHours(), 2)}:${pad(adjustedDate.getMinutes(), 2)}`;
};

const createAppointment = (existing, defaults, eventBegin, eventUntil, memberList) => {
    if (existing) return deleteTypeName(cloneDeep(existing));

    return {
        Reason: '',
        Type: null, //'Appointment',
        Date: dateToString(eventBegin || new Date()),
        DateTo: dateToString(eventUntil || new Date()),
        TimeFrom: dateToTimeString(eventBegin) || '09:00',
        TimeTo: dateToTimeString(eventUntil) || '11:00',
        AllDay: false,
        Recuring: false,
        Office: null,
        Members: memberList || [],
        LocationType: 'Residential',
        ...(defaults || {})
    };
};

const createCalendarEvent = (existing, defaults) => {
    return {
        ID: existing.ID,
        ClassName: existing.ClassName,
        Start: existing.Start,
        End: existing.End,
        Location: existing.Location || null,
        Title: existing.Title || null,
        Funeral: existing.Funeral || null,
        Notes: existing.Notes || null,
        StaffAllocations:
            (!!existing.StaffAllocations && existing.StaffAllocations.map(x => createStaffAllocation(x))) || [],
        ...(defaults || {})
    };
};

const createStaffAllocation = (existing, defaults, eventBegin, eventUntil) => {
    if (existing) {
        const dateTimeFrom = existing.Start ? existing.Start.split(' ') : ['', ''];
        const dateTimeTo = existing.End ? existing.End.split(' ') : ['', ''];
        return {
            ...deleteTypeName(cloneDeep(existing)),
            DateFrom: dateTimeFrom[0],
            DateTo: dateTimeTo[0],
            TimeFrom: dateTimeFrom[1],
            TimeTo: dateTimeTo[1]
        };
    }

    return {
        Title: '',
        DateFrom: dateToString(eventBegin || new Date()),
        DateTo: dateToString(eventUntil || new Date()),
        TimeFrom: dateToTimeString(eventBegin) || '08:00',
        TimeTo: dateToTimeString(eventUntil) || '17:00',
        Type: '',
        Allocation: '',
        Recurring: false,
        Member: null,
        Funeral: null,
        Service: null,
        Viewing: null,
        Disposal: null,
        Refreshments: null,
        ...(defaults || {})
    };
};

const prepareStateForStaffAllocation = (staffAllocation, staffAllocationId, defaults, eventBegin, eventUntil) => {
    /*    let state = {
        type: 'StaffAllocation',
        view: 'StaffAllocation',
        sanitize: sanitizeStaffAllocation,
        edit: staffAllocation || staffAllocationId
    };

    if (state.edit) {
        state.popoverTitle = 'Edit Staff REPLACE Allocation';
        state.mutation = updateCalendarEventMutation;
    } else {
        state.popoverTitle = 'New Staff REPLACE Allocation';
        state.mutation = createStaffAllocationMutation;
    }

    if (staffAllocation) {
        state.staffAllocation = createStaffAllocation(staffAllocation);
    } else if (staffAllocationId) {
        state.queryId = staffAllocationId;
        state.query = readOneStaffAllocationQuery;
    } else {
        state.staffAllocation = createStaffAllocation(null, defaults);
    }
    return state;

 */

    let state = {
        type: 'StaffAllocation',
        view: 'StaffAllocation',
        sanitize: sanitizeCalendarEvent,
        edit: !!staffAllocation || !!staffAllocationId
    };

    if (state.edit) {
        state.popoverTitle = 'Edit Calendar Event';
        state.mutation = updateCalendarEventMutation;
    } else {
        state.popoverTitle = 'New REPLACE Calendar Event';
        state.mutation = createCalendarEventMutation;
    }

    if (staffAllocation) {
        state.calendarEvent = createCalendarEvent(staffAllocation);
    } else if (staffAllocationId) {
        state.queryId = staffAllocationId;
        state.query = readOneCalendarEventQuery;
    } else {
        state.calendarEvent = createCalendarEvent(null, defaults);
    }
    return state;
};

const prepareStateForAppointment = (
    appointment,
    appointmentId,
    queryViaStaffAllocation,
    defaults,
    eventBegin,
    eventUntil,
    memberList
) => {
    let state = {
        type: null, //'Appointment',
        view: 'Appointment',
        sanitize: sanitizeAppointment,
        edit: !!appointment || !!appointmentId
    };

    if (state.edit) {
        state.popoverTitle = 'Edit Appointment';
        state.mutation = updateAppointmentMutation;
    } else {
        state.popoverTitle = 'New Appointment';
        state.mutation = createAppointmentMutation;
    }

    if (appointment) {
        state.appointment = createAppointment(appointment);
    } else if (appointmentId) {
        state.queryId = appointmentId;
        state.query = queryViaStaffAllocation
            ? readOneStaffAllocationQuery //seomtimes we use the staff query to get to the appointment
            : readOneAppointmentQuery;
    } else {
        state.appointment = createAppointment(null, defaults, eventBegin, eventUntil, memberList);
    }
    return state;
};

const prepareStateForCalendarEvent = (calendarEvent, calendarEventId, defaults) => {
    let state = {
        type: 'CalendarEvent',
        view: 'CalendarEvent',
        sanitize: sanitizeCalendarAllocations,
        edit: !!calendarEvent || !!calendarEventId
    };

    if (state.edit) {
        state.popoverTitle = 'Edit Staff Allocation';
        state.mutation = updateCalendarEventMutation;
    } else {
        state.popoverTitle = 'New Calendar Event';
        state.mutation = createCalendarEventMutation;
    }

    if (calendarEvent) {
        state.calendarEvent = createCalendarEvent(calendarEvent);
    } else if (calendarEventId) {
        state.queryId = calendarEventId;
        state.query = readOneCalendarEventQuery;
    } else {
        state.calendarEvent = createCalendarEvent(null, defaults);
    }
    return state;
};

const styles = ({ palette, breakpoints }) => ({
    root: {
        '& > div:nth-child(2) > div': {
            maxWidth: 'unset',
            maxHeight: '94vh',
            [breakpoints.down('xs')]: {
                margin: 16
            }
        },
        //need this hack so that the 'tick' button can appear in the top right corner
        '& > :nth-child(2)': {
            overflowY: 'visible',
            overflowX: 'visible',
            borderRadius: '10px',
            maxWidth: 'unset'
        }
    },
    buttonGroup: {
        marginTop: 12,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
    },
    popoverTitle: {
        margin: '0 6px'
    },
    paper: {
        maxWidth: '700px',
        width: '100%',
        maxHeight: 'calc(100vh - 32px)',
        backgroundColor: '#fafafa',
        padding: 25,
        borderRadius: 10,
        [breakpoints.down('xs')]: {
            padding: '16px 0'
        }
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 248px)',
        padding: '0px 6px 12px 6px',
        //height: '650px',
        marginTop: 24
    },
    memberIcons: {
        margin: -3,
        '& > span': {
            margin: 3
        }
    },
    actionButtons: {
        textAlign: 'right'
    },
    buttonCancel: { color: '#E64040' },
    buttonOk: { color: '#26CC6F' },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    }
});

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(AppointmentPopover);
