import gql from 'graphql-tag';

export default gql`
    fragment DeceasedPhotos on Funeral {
        DeceasedPhotos {
            ... on SilverStripeFile {
                ID
                AbsoluteLink
                Name
            }
            ... on SilverStripeImage {
                ID
                AbsoluteLink
                Name
            }
        }
    }
`;
