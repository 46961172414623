export default `
    ... on FuneralPlaceOfService {
        ID
        ClassName
        Confirmed
        Start
        End
        Funeral {
            ID
            Surname
            FirstName
            LegacyKey
        }
    }

    ... on FuneralPlaceOfViewing {
        ID
        ClassName
        Confirmed
        Start
        End
        Funeral {
            ID
            Surname
            FirstName
            LegacyKey
        }
    }

    ... on FuneralDisposal {
        ID
        ClassName
        Confirmed
        Start
        End
        Funeral {
            ID
            Surname
            FirstName
            LegacyKey
        }
    }

    ... on RefreshmentsVenue {
        ID
        ClassName
        Confirmed
        Start
        End
        Funeral {
            ID
            Surname
            FirstName
            LegacyKey
        }
    }
    
    ... on CalendarEvent {
        ID
        ClassName
        Start
        End
        Title
    }
`;
