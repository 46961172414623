import React, { Component, Fragment } from 'react';
import { compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '../form/Grid';
import TextField from '../form/TextField';
import Checkbox from '../form/Checkbox';
import Inline from '../../page/workQueue2/extras/Inline';
import Button from '../../page/workQueue2/extras/Button';
import { createForm } from '../../util/form';
import Modal from '../../page/workQueue2/extras/Modal';
import SaveIcon from '../icon/SaveIcon';
import Spinner from '../Spinner';
import FileUpload, { getFileName } from '../form/FileUpload';
import Select from '../form/Select';
import { getMyClient } from '../../apollo';
import FlexGrid from '../../page/workQueue2/extras/FlexGrid';
import FileUploadCard from '../../page/workQueue2/extras/FileUploadCard';
import { InlineFieldRightAlignChildren } from '../form/Inline';
import PrimaryButton from '../form/PrimaryButton';
import TableData from '../form/TableData';
import { Hidden, IconButton, LinearProgress } from '@material-ui/core';
import EditIcon from '../icon/EditIcon';
import CommunicationNote from '../../page/fragments/CommunicationNote';
import { cloneDeep } from 'apollo-utilities';
import CloseIcon from '../icon/CloseIcon';
import EnvelopeIcon from '../icon/EnvelopeIcon';
import moment from 'moment';
import TickIcon from '../icon/TickIcon';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { niceDateFromString, niceDateTimeFromString, niceTimeFromString } from '../../util/date';
import { joinDefined } from '../../util/strings';
import { WhiteLoader } from '../WhiteLoader';
import Label from '../form/Label';
import BiggerMessageBar from '../BiggerMessageBar';

class CommunicationNotes extends Component {
    state = {
        communicationNoteTemplates: [],
        savingCommunicationNote: false,
        sendingCommunicationNote: false,
        open: false
    };

    onClose = () => {
        this.form = createForm(this, {});
        this.refetchModal = () => null;
        this.setState({ open: false });
    };

    onOpen = noteID => {
        this.setState({ open: noteID || 0, LockoutWarning: false });
    };

    render() {
        const { recordID, recordType } = this.props;
        const variables = { recordID: recordID, recordField: recordType ? recordType + 'ID' : 'ID' };
        return (
            <Grid bucket>
                <Grid item pc>
                    <Label text={'Communication Notes'} />
                    {!recordID && (
                        <p>
                            Communication notes can be added after the{' '}
                            {recordType ? recordType.toLowerCase() : 'record'} is saved.
                        </p>
                    )}

                    {!!recordID && (
                        <Fragment>
                            <Query query={queryMyNotes} variables={variables} client={getMyClient()}>
                                {({ data, loading, error, refetch }) => {
                                    if (error) return 'error';
                                    this.refetchList = refetch;
                                    const result =
                                        !!data &&
                                        !!data.readCommunicationNotes &&
                                        data.readCommunicationNotes.edges.map(e => e.node);
                                    return this.renderNotesTable(loading, result);
                                }}
                            </Query>
                        </Fragment>
                    )}
                </Grid>
                {!!recordID && (
                    <Grid item xs={12}>
                        <Inline>
                            <PrimaryButton onClick={() => this.onOpen(0)}>Add Communication Note...</PrimaryButton>
                        </Inline>
                    </Grid>
                )}
                {this.renderNoteModal()}
            </Grid>
        );
    }

    renderNotesTable = (loading, data) => {
        if (!data || !data.length) return <p>There are currently no communication notes.</p>;
        return (
            <div style={{ marginTop: 16 }}>
                {(!!loading && <LinearProgress />) || (
                    <TableData
                        columns={[
                            { label: 'Created', id: 'Created', render: val => niceDateTimeFromString(val) },
                            { label: 'Subject', id: 'Title' },
                            {
                                label: 'Sent',
                                render: (val, row, i) => {
                                    return this.getIsSent(row) ? <TickIcon /> : null;
                                }
                            },
                            {
                                render: (val, row, i) => (
                                    <IconButton onClick={() => this.onOpen(row.ID)}>
                                        <EditIcon />
                                    </IconButton>
                                ),
                                styles: { padding: 0 }
                            }
                        ]}
                        data={data}
                    />
                )}
            </div>
        );
    };

    renderNoteModal() {
        const { open } = this.state;
        if (!open) {
            return this.renderModal();
        }
        const variables = { noteId: open };
        return (
            <Query query={queryCommunicationNote} variables={variables} client={getMyClient()}>
                {({ data, error, loading, refetch }) => {
                    const oldID = this.form.getField('ID');
                    const newID = !!data && !!data.readOneCommunicationNote && data.readOneCommunicationNote.ID;
                    if (newID) {
                        if (newID !== oldID) {
                            this.form.original = cloneDeep(data.readOneCommunicationNote);
                            this.form.state = cloneDeep(data.readOneCommunicationNote);
                        } else {
                            const oldStamp = this.form.getField('LastEdited');
                            const newStamp = data.readOneCommunicationNote.LastEdited;
                            if (newStamp !== oldStamp) {
                                this.form.original = cloneDeep(data.readOneCommunicationNote);
                                this.form.state = cloneDeep(data.readOneCommunicationNote);
                            }
                        }
                    }
                    this.refetchModal = refetch;
                    return this.renderModal(loading);
                }}
            </Query>
        );
    }

    renderModal(loading) {
        const { open } = this.state;
        if (open === false) return null;
        return (
            <Modal open={open !== false} onClose={this.onClose} canClickOut={false} subtitle="Communication Note">
                {!!loading && <WhiteLoader />}
                {this.renderNoteDetails()}
            </Modal>
        );
    }

    renderNoteDetails() {
        const form = this.form;
        const attachments = form.getField('Attachments') || [];

        const row = {
            MailDate: form.getOriginal('MailDate'),
            MailTime: form.getOriginal('MailTime'),
            EmailSent: form.getOriginal('EmailSent')
        };

        const readonlyCommunicationNote = this.getIsSent(row);

        const displayAttachmentsHeading = !readonlyCommunicationNote || (!!attachments && !!attachments.length);

        const disabledSendMailButton =
            !!this.state.savingCommunicationNote ||
            !!this.state.sendingCommunicationNote ||
            !!form.getField('EmailSent') ||
            !form.getField('Title') ||
            !form.getField('Description') ||
            !form.getField('SendEmail') ||
            !(
                form.getField('EmailApplicant') ||
                form.getField('EmailAuthorised') ||
                (form.getField('EmailOther') && form.getField('OtherEmailAddress'))
            );

        const disabledSaveButton =
            !!this.state.savingCommunicationNote ||
            !!this.state.sendingCommunicationNote ||
            !form.getField('Title') ||
            !form.getField('Description') ||
            !form.getDirty().length;

        let saveButtonIcon = <SaveIcon />;
        let saveButtonText = !this.form.getDirty().length && this.form.getField('ID') ? 'Saved' : 'Save Changes';
        let sendButtonIcon = <EnvelopeIcon />;
        let sendButtonText = 'Send Email';

        if (!!this.state.sendingCommunicationNote) {
            sendButtonIcon = <Spinner size="xs" />;
            sendButtonText = 'Sending';
        } else if (!!this.state.savingCommunicationNote) {
            saveButtonIcon = <Spinner size="xs" />;
            saveButtonText = 'Saving';
        }

        return (
            <Grid container spacing={8}>
                {(!readonlyCommunicationNote && (
                    <Grid item xs={12}>
                        <Query query={queryCommunicationNoteTemplates} client={getMyClient()}>
                            {results => this.renderCommunicationNoteTemplateList(results)}
                        </Query>
                    </Grid>
                )) ||
                    (!!form.getField('Template') && !!form.getField('Template.ID') && (
                        <TextField
                            label="Communication Template"
                            readOnly
                            value={`#${form.getField('Template.ID')}: ${form.getField('Template.Title')}`}
                        />
                    ))}
                {this.state.LockoutWarning && <BiggerMessageBar messageType="info">This Funeral record will be locked once the note has been sent to the client, please ensure you complete bookings and data entry beforehand</BiggerMessageBar>}
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Subject"
                        placeholder="Enter a reason for this note..."
                        form={form}
                        name="Title"
                        readOnly={readonlyCommunicationNote}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        label="Message"
                        placeholder="Enter the message you need to send..."
                        multiline
                        rows={8}
                        form={form}
                        name="Description"
                        readOnly={readonlyCommunicationNote}
                    />
                </Grid>

                {!readonlyCommunicationNote && (
                    <Grid item xs={12}>
                        {!!displayAttachmentsHeading && <h2>Attach a File</h2>}
                        <FileUpload
                            buttonOnly
                            folderPath={this.getDocumentPath()}
                            onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, attachments)}
                        />
                    </Grid>
                )}

                {!!attachments &&
                    !!attachments.length &&
                    this.renderAttachmentLinks(attachments, readonlyCommunicationNote)}

                {(!readonlyCommunicationNote || !!form.getField('SendEmail')) && (
                    <Grid item xs={12}>
                        <Inline center>
                            <h2>Send note via email</h2>

                            {!readonlyCommunicationNote && (
                                <Checkbox
                                    label="Yes, email this note"
                                    form={form}
                                    name="SendEmail"
                                    disabled={readonlyCommunicationNote}
                                />
                            )}
                        </Inline>

                        {readonlyCommunicationNote && (
                            <Checkbox
                                label="Send via email"
                                form={form}
                                name="SendEmail"
                                disabled={readonlyCommunicationNote}
                            />
                        )}

                        {!!form.getField('SendEmail') && (
                            <div>
                                {(!readonlyCommunicationNote || !!form.getField('EmailApplicant')) && (
                                    <Checkbox
                                        label="Send to Applicant's email address"
                                        form={form}
                                        name="EmailApplicant"
                                        disabled={readonlyCommunicationNote}
                                    />
                                )}

                                {(!readonlyCommunicationNote || !!form.getField('EmailAuthorised')) && (
                                    <Checkbox
                                        label="Send to Authorised Contact email addresses"
                                        form={form}
                                        name="EmailAuthorised"
                                        disabled={readonlyCommunicationNote}
                                    />
                                )}

                                {(!readonlyCommunicationNote || !!form.getField('EmailOther')) && (
                                    <Checkbox
                                        label="Send to other email address"
                                        form={form}
                                        name="EmailOther"
                                        disabled={readonlyCommunicationNote}
                                    />
                                )}

                                {!!form.getField('EmailOther') &&
                                    (!readonlyCommunicationNote || !!form.getField('OtherEmailAddress')) && (
                                        <TextField
                                            label="Other email address"
                                            form={form}
                                            name="OtherEmailAddress"
                                            readOnly={readonlyCommunicationNote}
                                        />
                                    )}
                            </div>
                        )}
                    </Grid>
                )}

                {(!readonlyCommunicationNote || !!form.getField('SendMail')) && (
                    <Grid item xs={12}>
                        <Inline center>
                            <h2>Mail note as letter</h2>

                            {!readonlyCommunicationNote && (
                                <Checkbox
                                    label="Yes, mail to a postal address"
                                    form={form}
                                    name="SendMail"
                                    disabled={readonlyCommunicationNote}
                                />
                            )}
                        </Inline>

                        {readonlyCommunicationNote && (
                            <Checkbox
                                label="Mail to postal address"
                                form={form}
                                name="SendMail"
                                disabled={readonlyCommunicationNote}
                            />
                        )}

                        {!!form.getField('SendMail') && (
                            <div>
                                {(!readonlyCommunicationNote || !!form.getField('MailApplicant')) && (
                                    <Checkbox
                                        label="Send to Applicant's postal address"
                                        form={form}
                                        name="MailApplicant"
                                        disabled={readonlyCommunicationNote}
                                    />
                                )}

                                {(!readonlyCommunicationNote || !!form.getField('MailAuthorised')) && (
                                    <Checkbox
                                        label="Send to Authorised Contact postal addresses"
                                        form={form}
                                        name="MailAuthorised"
                                        disabled={readonlyCommunicationNote}
                                    />
                                )}

                                {(!readonlyCommunicationNote || !!form.getField('MailOther')) && (
                                    <Checkbox
                                        label="Send to other postal address"
                                        form={form}
                                        name="MailOther"
                                        readOnly={readonlyCommunicationNote}
                                    />
                                )}

                                {!!form.getField('MailOther') &&
                                    (!readonlyCommunicationNote || !!form.getField('OtherMailAddress')) && (
                                        <TextField
                                            label="Other postal address"
                                            form={form}
                                            name="OtherMailAddress"
                                            readOnly={readonlyCommunicationNote}
                                        />
                                    )}
                            </div>
                        )}
                    </Grid>
                )}

                {!!form.getField('SendMail') && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Mail Date"
                                type="date"
                                form={form}
                                name="MailDate"
                                readOnly={readonlyCommunicationNote}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Mail Time"
                                type="time"
                                form={form}
                                name="MailTime"
                                readOnly={readonlyCommunicationNote}
                            />
                        </Grid>
                    </Fragment>
                )}

                {!!form.getField('EmailSent') && (
                    <Fragment>
                        <Grid item xs={12}>
                            <h2>Note History</h2>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email Date"
                                value={niceDateFromString(form.getField('EmailSent'))}
                                name="EmailSent"
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email Time"
                                value={niceTimeFromString(form.getField('EmailSent'))}
                                name="EmailSent"
                                readOnly
                            />
                        </Grid>
                    </Fragment>
                )}

                {form.getField('CreatedBy.ID') > 0 && (
                    <Grid item xs={12}>
                        <TextField
                            readOnly
                            label={'Created'}
                            value={this.showAuthor(form.getField('CreatedBy'), form.getField('Created'))}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <InlineFieldRightAlignChildren>
                        <div>
                            <Button variant="secondary" onClick={this.onClose}>
                                <CloseIcon />
                                <Hidden smDown>&nbsp;Close</Hidden>
                            </Button>
                        </div>

                        {!readonlyCommunicationNote && (
                            <Fragment>
                                {!!form.getField('SendEmail') && (
                                    <Button
                                        variant="primary"
                                        disabled={disabledSendMailButton}
                                        onClick={() => this.onSendEmail()}
                                    >
                                        {sendButtonIcon}
                                        <Hidden smDown>&nbsp;{sendButtonText}</Hidden>
                                    </Button>
                                )}
                                <Button
                                    variant="confirmation"
                                    disabled={disabledSaveButton}
                                    onClick={() => this.onSave()}
                                >
                                    {saveButtonIcon}
                                    <Hidden smDown>&nbsp;{saveButtonText}</Hidden>
                                </Button>
                            </Fragment>
                        )}
                    </InlineFieldRightAlignChildren>
                </Grid>
            </Grid>
        );
    }

    renderCommunicationNoteTemplateList = ({ error, loading, data }) => {
        const { communicationNoteTemplates } = this.state;
        const form = this.form;
        const { recordType } = this.props;

        if (error) {
            return 'Error!';
        }

        if (loading) {
            return <span>Loading...</span>;
        }

        if (!data) {
            return '';
        }

        const templates = data.readCommunicationNoteTemplates.edges;

        let selectTemplateOptions = [];

        for (let x = 0; x < templates.length; x++) {
            if (templates[x].node.AddLinkToFuneralArranger && recordType != "Funeral") {
                continue;
            }
            selectTemplateOptions.push({
                label: '#' + templates[x].node.ID + ': ' + templates[x].node.Title,
                value: templates[x].node.ID
            });
            communicationNoteTemplates[templates[x].node.ID] = templates[x].node;
        }

        return (
            <Select
                label="Communication Template"
                placeholder={'Choose a template...'}
                name="Template.ID"
                options={selectTemplateOptions}
                onChange={this.handleCommunicationNoteTemplateChange}
                form={form}
            />
        );
    };

    getDocumentPath() {
        const { recordType, recordID } = this.props;
        if (recordID) {
            if (recordType === 'Funeral') return `documents/funeral/${recordID}`;
            if (recordType === 'Enquiry') return `documents/enquiries/${recordID}`;
        }
        return `Uploads/CommunicationNotes/FM2/${this.form.getField('ID')}`;
    }

    getRelationName() {
        const { recordType } = this.props;
        if (recordType) return recordType;
        return 'CremationOrder';
    }

    getRelationFieldValue() {
        const { recordID } = this.props;
        if (recordID) return recordID;
        return this.form.state.CremationOrderID;
    }

    getIsSent(row) {
        const mailStamp = moment(row.MailDate + ' ' + row.MailTime);
        const isMailed = mailStamp && mailStamp.isValid() && mailStamp.isBefore();
        return !!(row.EmailSent || isMailed);
    }

    showAuthor(author, date) {
        const { FirstName, Surname, Email } = author;
        const Name = joinDefined([FirstName, Surname], ' ');
        return joinDefined(['By', Name || Email, 'on', niceDateTimeFromString(date)], ' ');
    }

    onSave() {
        const { onSave } = this.props;

        if (onSave) {
            onSave(this.state);
        }

        this.setState({
            savingCommunicationNote: true
        });

        this.sendCommunicationNoteFunction(this.form, this.getRelationName(), this.getRelationFieldValue()).then(
            mutation => {
                this.setState({
                    savingCommunicationNote: false,
                    sendingCommunicationNote: false
                });

                if (!!mutation && !!mutation.data) {
                    if (
                        (!!this.form.state.ID && !!mutation.data.updateCommunicationNote) ||
                        !!mutation.data.createCommunicationNote
                    ) {
                        const result = mutation.data.createCommunicationNote || mutation.data.updateCommunicationNote;
                        if (this.form.state.ScheduledToSendEmail) {
                            if (result.EmailSent) {
                                this.props.setSnackbarMessage('Success, your email has been sent.', true);
                            } else {
                                this.props.setSnackbarMessage(
                                    'Oops, your email was not sent. Do you have a recipient email address?'
                                );
                            }
                        } else {
                            this.props.setSnackbarMessage('Success, your changes have been saved.', true);
                        }
                        if (!!mutation.data.createCommunicationNote)
                            this.setState({ open: mutation.data.createCommunicationNote.ID });
                        else this.refetchModal();
                    } else {
                        console.error('unexpected result: ', mutation.data);
                    }
                } else {
                    this.onGqlError('Failed to save communication note');
                    return null;
                }
                this.refetchList();
            },
            error => {
                this.setState({
                    savingCommunicationNote: false,
                    sendingCommunicationNote: false
                });
                this.onGqlError('Failed to save communication note', error, null, new Error(error));
            }
        );
    }

    onSendEmail() {
        const { onSendEmail } = this.props;

        if (onSendEmail) {
            onSendEmail(this.state);
        }

        this.setState({
            sendingCommunicationNote: true
        });

        this.form.state.ScheduledToSendEmail = true;

        this.onSave();
    }

    onGqlError(action, error) {
        console.error('onGqlError: ' + action, error);
    }

    sendCommunicationNoteFunction = async (form, relationName, relationFieldValue) => {
        let asyncQuery;

        let data = {
            [relationName + 'ID']: relationFieldValue,
            Title: form.state.Title,
            Description: form.state.Description,
            ScheduledToSendEmail: form.state.ScheduledToSendEmail,
            SendEmail: form.state.SendEmail,
            EmailApplicant: form.state.EmailApplicant,
            EmailAuthorised: form.state.EmailAuthorised,
            EmailOther: form.state.EmailOther,
            OtherEmailAddress: form.state.OtherEmailAddress,
            SendMail: form.state.SendMail,
            MailApplicant: form.state.MailApplicant,
            MailAuthorised: form.state.MailAuthorised,
            MailOther: form.state.MailOther,
            OtherMailAddress: form.state.OtherMailAddress,
            MailDate: form.state.MailDate,
            MailTime: form.state.MailTime
        };

        if (!!form.state.Template && !!form.state.Template.ID) {
            data.TemplateID = form.state.Template.ID;
        }

        data.Attachments = [];
        if (!!form.state.Attachments && form.state.Attachments.length > 0) {
            form.state.Attachments.forEach(function (attachment, index, object) {
                if (!!attachment.ID) {
                    data.Attachments.push({
                        ID: attachment.ID
                    });
                }
            });
        }

        if (!!form.state.ID && form.state.ID !== '0') {
            data.ID = form.state.ID;
        }

        asyncQuery = await getMyClient().mutate({
            mutation: data.ID ? updateCommunicationNote : createCommunicationNote,
            variables: {
                input: data
            }
        });

        return asyncQuery;
    };

    handleCommunicationNoteTemplateChange = event => {
        const { communicationNoteTemplates } = this.state;
        const form = this.form;

        let communicationNoteTemplate = communicationNoteTemplates[event.target.value];

        if (!communicationNoteTemplate) {
            this.state.LockoutWarning = false;
            return;
        }

        form.state.Title = this.makeSubstitutions(communicationNoteTemplate.Title);
        form.state.Description = this.makeSubstitutions(communicationNoteTemplate.Description);
        form.state.Attachments = communicationNoteTemplate.Attachments;
        this.state.LockoutWarning = !!communicationNoteTemplate.AddLinkToFuneralArranger;
    };

    makeSubstitutions(description) {
        const { substitutionFields } = this.props;

        if (substitutionFields) {
            for (let key in substitutionFields) {
                if (substitutionFields[key]) {
                    description = description.split(key).join(substitutionFields[key]);
                }
            }
        }
        return description;
    }

    renderAttachmentLinks(attachments, readOnly) {
        return (
            <Grid item>
                <h2> Attached Files </h2>
                <FlexGrid>
                    {attachments.map(attachment => (
                        <FileUploadCard
                            variant={'mini'}
                            key={'file-' + attachment.ID}
                            fileName={attachment.Name}
                            fileLink={attachment.AbsoluteLink}
                            onClickRemoveAction={!readOnly ? () => this.onAttachmentRemove(attachment.ID) : undefined}
                        />
                    ))}
                </FlexGrid>
            </Grid>
        );
    }

    handleUploadAttachment(uploadFile, attachments) {
        const form = this.form;
        attachments = attachments || [];

        attachments.push({
            ID: uploadFile.id,
            AbsoluteLink: uploadFile.url,
            Name: getFileName(uploadFile.filename)
        });

        form.setField({
            Attachments: attachments
        });
    }

    onAttachmentRemove(fileId) {
        const form = this.form;

        let Attachments = form.getField('Attachments');
        Attachments = Attachments.filter(obj => fileId !== obj.ID);

        form.setField({ Attachments });
    }

    form = createForm(this, {});
    refetchModal = () => null;
    refetchList = () => null;
}

const queryCommunicationNoteTemplates = gql`
    query($limit: Int) {
        readCommunicationNoteTemplates(limit: $limit) {
            edges {
                node {
                    ID
                    Title
                    Description
                    AddLinkToFuneralArranger

                    Attachments {
                        ... on SilverStripeFile {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on SilverStripeImage {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
            }
        }
    }
`;

const createCommunicationNote = gql`
    ${CommunicationNote}
    mutation createCommunicationNote($input: CommunicationNoteCreateInputType!) {
        createCommunicationNote(Input: $input) {
            ...CommunicationNote
        }
    }
`;

const updateCommunicationNote = gql`
    ${CommunicationNote}
    mutation updateCommunicationNote($input: CommunicationNoteUpdateInputType!) {
        updateCommunicationNote(Input: $input) {
            ...CommunicationNote
        }
    }
`;

const queryCommunicationNote = gql`
    ${CommunicationNote}
    query readOneCommunicationNote($noteId: ID!) {
        readOneCommunicationNote(ID: $noteId) {
            ...CommunicationNote
        }
    }
`;

const queryMyNotes = gql`
    query readCommunicationNotes($recordID: String!, $recordField: String!) {
        readCommunicationNotes(filters: [{ field: $recordField, value: $recordID }]) {
            edges {
                node {
                    ID
                    Created
                    Title
                    EmailSent
                    MailDate
                    MailTime
                }
            }
        }
    }
`;

export default compose(withSnackbarMessage)(CommunicationNotes);
