import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import { getUser } from '../../../util/sessions';
import { dateTimeToString } from '../../../util/date';
import Grid from '../../../component/form/Grid';
import BellIcon from '../../../component/icon/BellIcon';
import {
    clearMemberNotificationsFunc,
    getUserNotificationsQuery,
    updateNotificationMembersFunc
} from './GetSaveNotification';
import { getMyClient } from '../../../apollo';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import CloseIcon from '../../../component/icon/CloseIcon';
import { FUNERAL_TAB_SEGMENTS } from '../../funeral/funeralConstants';
import { applyUrlParams } from '../../../util/strings';
import { FUNERAL_HOME } from '../../../util/funerals';
import { theme } from '../../../Theme.js';

class NotificationsPopover extends Component {
    state = {
        loading: false,
        loadingNotificationId: null,
        open: false,
        loadedDate: dateTimeToString(moment('2012-12-12')),
        seenDate: false
    };
    audio = new Audio('/service-bell_daniel_simion.mp3');
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
    refTarget = React.createRef();

    static getDerivedStateFromProps(props, state) {
        let seenDate = window.localStorage.getItem('notificationSeenDate');
        if (!seenDate) seenDate = dateTimeToString(moment('2012-12-21')); // the end of the world?!
        if (seenDate !== state.seenDate) return { seenDate };
        return null;
    }

    componentDidMount() {
        window.addEventListener('touchstart', this.listenBell);
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.listenBell);
    }

    render() {
        const user = getUser();
        return (
            <Query
                client={getMyClient()}
                query={getUserNotificationsQuery}
                variables={{
                    userId: user.ID,
                    limit: 99,
                    offset: 0
                }}
                pollInterval={30000}
            >
                {({ data, loading, error }) => {
                    const { readNotifications } = data || false;
                    const notificationCount = readNotifications
                        ? readNotifications.edges.length + (!!readNotifications.pageInfo.hasNextPage && '+')
                        : 0;
                    const latestDate = (notificationCount && readNotifications.edges[0].node.Created) || null;

                    return (
                        <Fragment>
                            {this.renderNotificationsButton(notificationCount, latestDate)}
                            {this.renderPopOver(notificationCount, readNotifications, loading || error, latestDate)}
                        </Fragment>
                    );
                }}
            </Query>
        );
    }

    listenBell = () => {
        this.audio.load();
        // window.removeEventListener('touchstart', this.listenBell);
    };

    doRingBell = () => {
        // Turn this annoying sound off!
        /*
        if (this.audioContext && this.audioContext.state === 'running') {
            this.audio.currentTime = 0;
            const promise = this.audio.play(); OMG
            if (promise !== undefined) {
                promise
                    .then(_ => {
                        // Autoplay started!
                    })
                    .catch(_ => {
                        // Autoplay was prevented.
                    });
            }
        } else if (this.audioContext) {
            // browser not ready, try again?
            setTimeout(this.doRingBell, 250);
        }
        */
    };

    renderNotificationsButton(notificationCount, latestDate) {
        const { classes } = this.props;
        const { seenDate } = this.state;
        const ss = Number(notificationCount) === 1 ? '' : 's';

        if (latestDate > seenDate) {
            this.doRingBell();
        }

        return (
            <Badge
                title={'You have ' + notificationCount + ' notification' + ss + '.'}
                color={latestDate > seenDate ? 'secondary' : 'primary'}
                badgeContent={notificationCount}
                classes={{ badge: classes.notificationsBadge }}
                onClick={() => {
                    this.openPopup(latestDate);
                }}
            >
                <IconButton className={classes.notificationsButton} buttonRef={this.refTarget}>
                    <BellIcon
                        color="disabled"
                        className={latestDate > seenDate ? classes.notificationsIconRing : classes.notificationsIcon}
                    />
                </IconButton>
            </Badge>
        );
    }

    renderPopOver(notificationCount, readNotifications, loadingAll, latestDate) {
        const { classes } = this.props;
        const { open, loadingNotificationId, loading, seenDate } = this.state;
        let newNotifications = false;
        if (latestDate > seenDate && !open) {
            this.openPopup(latestDate); // TODO: should not update state during render
            newNotifications = true;
        }
        // if (!this.refTarget.current) return null;
        const ss = Number(notificationCount) === 1 ? '' : 's';
        return (
            <Popover
                anchorEl={this.refTarget.current}
                open={open || newNotifications}
                onClose={() => this.onClose()}
                anchorReference="anchorEl"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <div className={classes.paper}>
                    <div className={classes.headline}>
                        <Typography variant="headline" id="modal-title">
                            {notificationCount || ''} Notification{ss}
                        </Typography>
                    </div>
                    <div
                        className={classes.paperScroll}
                        style={loading && !loadingNotificationId ? { pointerEvents: 'none', opacity: 0.5 } : null}
                    >
                        {loadingAll && !readNotifications
                            ? this.renderLoading()
                            : this.renderNotifications(readNotifications)}
                    </div>
                    <div style={{ clear: 'both', textAlign: 'right' }}>
                        {!!notificationCount && (
                            <Button
                                className={classes.clearButton}
                                onClick={this.onClearAll}
                                size="small"
                                disabled={loading}
                            >
                                <DeleteIcon />
                                &nbsp;Dismiss All
                            </Button>
                        )}
                        <Button className={classes.closeButton} onClick={this.onClose} size="small">
                            <CloseIcon />
                            &nbsp;Close
                        </Button>
                    </div>
                </div>
            </Popover>
        );
    }

    renderLoading() {
        const { classes } = this.props;
        return (
            <div className={classes.paddedContent}>
                <span>Loading...</span>
                <span className={classes.loading}>
                    <CircularProgress />
                </span>
            </div>
        );
    }

    renderNotifications(Notifications) {
        const { classes } = this.props;
        const { loading, loadingNotificationId } = this.state;
        if (!Notifications) return null;
        return (
            <Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {Notifications.edges.length === 0 ? (
                            <div className={classes.paddedContent}>
                                <p>You currently have no unread notifications.</p>
                                <div className={classes.chillax}>
                                    <Icon>emoji_food_beverage</Icon>
                                    <div>Come back later... ?</div>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <ul className={classes.notificationsList}>
                                    {Notifications.edges.map((notification, i) => {
                                        const letterCode =
                                            notification.node.Funeral && notification.node.Funeral.LegacyKey
                                                ? notification.node.Funeral.LegacyKey.slice(-1)
                                                : 'X';
                                        return (
                                            <li
                                                key={notification.node.ID}
                                                className={classes['officeTheme' + letterCode]}
                                                style={{
                                                    opacity:
                                                        loadingNotificationId === notification.node.ID ? '0.25' : null
                                                }}
                                            >
                                                <div
                                                    role="button"
                                                    tabIndex="0"
                                                    className={classes.notification}
                                                    onKeyDown={() =>
                                                        !loading && this.onClickNotification(i, notification)
                                                    }
                                                    onClick={() =>
                                                        !loading && this.onClickNotification(i, notification)
                                                    }
                                                >
                                                    <span className={classes.notificationTitle}>
                                                        {notification.node.Title}
                                                    </span>
                                                    <span className={classes.notificationDescription}>
                                                        <br />
                                                        {moment(notification.node.Created).fromNow()}
                                                        {': '}
                                                        {notification.node.Description}
                                                    </span>
                                                </div>
                                                {loadingNotificationId === notification.node.ID ? (
                                                    <span className={classes.clearLoading}>
                                                        <span className={classes.loading}>
                                                            <CircularProgress />
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={classes.clearIcon}
                                                        title="Dismiss this notification"
                                                    >
                                                        <IconButton
                                                            onClick={() =>
                                                                !loading && this.setNotificationToRead(i, notification)
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Fragment>
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    setNotificationToRead(index, original) {
        const user = getUser();
        const notification = cloneDeep(original);
        const notifiedMember = notification.node.AssignedMembers.find(x => x.ID === user.ID);

        notifiedMember.Join.Read = notifiedMember.Join.Read ? notifiedMember.Join.Read : dateTimeToString(new Date());

        this.updateNotification(notification, original, () => {});
    }

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.setState({ open: false });
        this.updateLocalStorage();
    };

    onClickNotification(index, notification) {
        this.setNotificationToRead(index, notification);
        this.goToNotification(notification);
        this.onClose();
    }

    goToNotification(notification) {
        const { TabURLSegment } = notification.node;
        let path = TabURLSegment;
        if (Object.values(FUNERAL_TAB_SEGMENTS).indexOf(TabURLSegment) === 0)
            path = applyUrlParams('/funeral/:key/:id/' + TabURLSegment, {
                key: notification.node.Funeral.LegacyKey,
                id: notification.node.Funeral.ID
            });

        if (!!path && path !== '') {
            const { history } = this.props;
            history.push(path);
        }
    }

    updateNotification(notification, original, onSaved) {
        this.setState({ loading: true, loadingNotificationId: notification.node.ID });
        const that = this;
        updateNotificationMembersFunc(notification, original).then(
            () => {
                that.setState({ loading: false });
                that.updateLocalStorage();
                onSaved();
            },
            error => that.onGqlError('Failed to update/reload notifications', error)
        );
    }

    onClearAll = () => {
        this.setState({ loading: true });
        const that = this;
        clearMemberNotificationsFunc().then(
            () => {
                that.setState({ loading: false });
                that.updateLocalStorage();
            },
            error => that.onGqlError('Failed to clear/reload notifications', error)
        );
    };

    onGqlError(action, error) {
        console.error(action, error);
        this.setState({ loading: false, loadingNotificationId: null });
    }

    openPopup = latestDate => {
        this.setState({
            open: true,
            loadedDate: latestDate
        });
    };

    updateLocalStorage = () => {
        const { loadedDate } = this.state;
        window.localStorage.setItem('notificationSeenDate', loadedDate);
    };
}

const styles = ({ spacing, transitions, palette }) => ({
    root: {},
    buttonGroup: {
        marginTop: 12,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
    },
    headline: {
        padding: spacing.unit * 2,
        whiteSpace: 'pre'
    },
    paper: {
        width: 400,
        maxWidth: '100%',
        maxHeight: 'calc(100vh - 32px)',
        backgroundColor: '#fafafa'
    },
    paperScroll: {
        overflow: 'hidden',
        maxHeight: 'calc(100vh - 160px)',
        padding: 0,
        width: '100%',
        clear: 'both',
        boxShadow: '0 0 3px 0px #ccc',
        background: '#fff'
    },
    selectButton: {
        marginRight: 10,
        marginTop: 15
    },
    notificationsBadge: {
        top: 12,
        right: 12,
        width: 24,
        height: 24,
        borderRadius: 32
    },
    notificationsButton: {
        marginLeft: spacing.unit * 2
    },
    notificationsIcon: {
        fontSize: '1.75rem'
    },
    loading: {
        float: 'right',
        transform: 'scale(0.5)',
        width: 28
    },
    paddedContent: {
        margin: spacing.unit * 3
    },
    notificationsList: {
        listStyle: 'none',
        maxHeight: 'calc(100vh - 220px)',
        margin: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
        padding: '0',
        '& > li': {
            borderStyle: 'solid',
            borderTopColor: '#CCC',
            borderBottomColor: '#CCC',
            borderRightColor: 'transparent',
            borderWidth: '1px 15px 0px 5px',
            cursor: 'pointer',
            transition: transitions.create(['border-width', 'box-shadow', 'background', 'transform'])
        },
        '& > li:last-child': {
            borderWidth: '1px 15px 1px 5px'
        },
        '& > li:hover': {
            borderLeftWidth: 15,
            borderRightWidth: 5,
            boxShadow: '-2px 2px 2px 2px #888888',
            zIndex: '1',
            '& button': {
                opacity: 1
            }
        },
        '& > li:active': {
            //transform:'translateX(205%)'// todo:make this transition away nicely
        }
    },

    officeThemeX: {
        '&:hover': { backgroundColor: theme.palette.contentBackground.none },
        borderColor: theme.palette.contentForeground.none
    },

    ['officeTheme' + [FUNERAL_HOME.RANKINS.letterCode]]: {
        '&:hover': { backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.RANKINS.letterCode] },
        borderColor: theme.palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode]
    },

    ['officeTheme' + [FUNERAL_HOME.STAN_CRAP.letterCode]]: {
        '&:hover': { backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.STAN_CRAP.letterCode] },
        borderColor: theme.palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode]
    },

    ['officeTheme' + [FUNERAL_HOME.WOLLONGONG_CITY.letterCode]]: {
        '&:hover': { backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] },
        borderColor: theme.palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode]
    },

    ['officeTheme' + [FUNERAL_HOME.PARSONS_LADIES.letterCode]]: {
        '&:hover': { backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.PARSONS_LADIES.letterCode] },
        borderColor: theme.palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode]
    },

    ['officeTheme' + [FUNERAL_HOME.H_PARSONS.letterCode]]: {
        '&:hover': { backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.H_PARSONS.letterCode] },
        borderColor: theme.palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode]
    },

    ['officeTheme' + [FUNERAL_HOME.EASY_FUNERALS.letterCode]]: {
        '&:hover': { backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode] },
        borderColor: theme.palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode]
    },

    notification: {
        width: 'calc(100% - 43px)',
        display: 'inline-block',
        padding: '15px 0 15px 25px',
        overflow: 'hidden'
    },

    notificationTitle: {
        fontSize: '14px'
    },

    notificationDescription: {
        marginLeft: '15px',
        fontSize: '12px',
        fontWeight: 'light',
        color: '#888888'
    },

    clearIcon: {
        margin: 15,
        display: 'inline-block',
        position: 'absolute',
        right: 0,

        '& > button': {
            height: '28px',
            width: '28px',
            padding: 0,
            color: '#666',
            opacity: 0.125,
            '&:hover': {
                color: palette.action.destroy
            }
        },
        '& > button:first-child > span:first-child > svg': {
            height: '18px',
            width: '18px'
        }
    },

    clearLoading: {
        margin: '10px 15px',
        display: 'inline-block',
        position: 'absolute',
        right: 0
    },

    clearButton: {
        '& svg': { fontSize: '18px' },
        float: 'left',
        color: palette.action.destroy,
        margin: spacing.unit
    },
    closeButton: {
        '& svg': { fontSize: '18px' },
        margin: spacing.unit
    },
    chillax: {
        position: 'relative',
        height: 96,
        overflow: 'hidden',
        margin: '0 10% 64px',
        borderBottom: '8px solid #e9e9e9',
        '& > span': {
            fontSize: 96,
            transition: '2s ease-in-out',
            left: 0,
            bottom: -26,
            position: 'absolute',
            color: palette.primary.main
        },
        '& > div': {
            opacity: 0,
            left: 'calc(100% - 26px)',
            transition: 'opacity 0',
            position: 'absolute',
            bottom: 70,
            fontSize: '0.8em',
            whiteSpace: 'pre',
            color: palette.secondary.main
        },
        '&:hover > span': { color: palette.secondary.main, left: 'calc(100% - 96px)', cursor: 'grab' },
        '&:hover > span:active': { cursor: 'grabbing' },
        '&:hover > div': { opacity: 1, left: 0, transition: '1s ease-in-out 1.125s' }
    },

    notificationsIconRing: {
        fontSize: '1.75rem',
        animation: 'swing 0.6s ease infinite'
    },

    '@keyframes swing': {
        '0%': { transform: 'rotateZ(0)' },
        '25%': { transform: 'rotateZ(25deg)' },
        '50%': { transform: 'rotateZ(-15deg)' },
        '75%': { transform: 'rotateZ(10deg)' },
        '100%': { transform: 'rotateZ(0)' }
    }
});

// prettier-ignore
export default compose(
    withRouter,
    withStyles(styles)
)(NotificationsPopover);
