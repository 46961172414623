import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../../util/strings';
import { getFuneralHomeByKey } from '../../../util/funerals';

export default withStyles({})(({ label, variant, legacyKey }) => {
    let cssVariant = null;
    const brand = getFuneralHomeByKey(legacyKey, true);
    if (brand) {
        variant = brand.style;
        label = brand.label;
    }
    if (!stringIsNullOrEmpty(variant)) cssVariant = `table-key--${variant}`;

    return <div className={`table-key ${cssVariant || ''}`}>{label}</div>;
});
