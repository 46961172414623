import { Application, BdmResponse, Disposal, WebServiceLogs, LegacyDocuments } from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment BdmsFields on Funeral {
                FuneralDataStatus
                ExportDate
                ExportStatus
                ExportId
                DeathCertificateStatus
                DeathCertificateNumber
                FormattedBDMResponse
                LifeLinkValidationField {
                    AllItems {
                        Key
                        Value
                    }
                }
                DeathCertificateNotRequired
            }
        `,
        BdmResponse,
        Disposal,
        Application,
        WebServiceLogs,
        LegacyDocuments
    ],
    'Funeral'
);

export const UpdateFileMutation = gql`
    mutation($input: SilverStripeFileUpdateInputType!) {
        updateSilverStripeFile(Input: $input) {
            ID
            Name
            Size
            AbsoluteLink
        }
    }
`;
