import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import { TableCell, TableRow } from '../../../component/form/Table';
import { joinDefined } from '../../../util/strings';
import { ProductConfig, QueryKeys } from '../../../util/products';
import { DATE_OF_DEATH_OPTION } from '../../funeral/deceased/DeceasedConstants';
import { getAddressBookById } from '../../../component/form/AddressBookAutocomplete';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { indexOf } from '../../../util/arrays';
import { getFuneralHomeByKey } from '../../../util/funerals';
import { isNullOrUndefined } from '../../../util/objects';
import { niceDateFromString, niceDateTimeFromString, niceTimeFromString } from '../../../util/date';
import { CONTACT_CLERGY_OPTIONS, CONTACT_CLERGY_RELIGIOUS } from '../../contacts/contactConstants';

const getFlorists = form => {
    const florists = form.getState('Florists') || [];
    if (florists.length === 0) return 'None';

    return florists.map((florist, index) => (
        <div key={index}>{(!!florist.Contact && florist.Contact.Name) || '(no florist)'}</div>
    ));
};

export const getContact = (form, name, missingLabel) => {
    const id = form.getState(name);
    if (isNullOrUndefined(id)) return Promise.resolve(missingLabel || 'None');

    return getAddressBookById(id);
};

const getDateOfDeath = form => {
    const dateOfDeathType = form.getState('DateOfDeathType');
    const dateOfDeath1 = form.getState('DateOfDeath1');
    const dateOfDeath2 = form.getState('DateOfDeath2');

    if (isNullOrUndefined(dateOfDeathType)) return '"Date of Death Type" not specified';

    if (dateOfDeathType === DATE_OF_DEATH_OPTION.ABOUT.value) {
        return `about ${dateOfDeath1}`;
    } else if (dateOfDeathType === DATE_OF_DEATH_OPTION.AFTER.value) {
        return `after ${dateOfDeath1}`;
    } else if (dateOfDeathType === DATE_OF_DEATH_OPTION.APPROX.value) {
        return `approximately ${dateOfDeath1}`;
    } else if (dateOfDeathType === DATE_OF_DEATH_OPTION.BETWEEN.value) {
        return `between ${dateOfDeath1} - ${dateOfDeath2}`;
    } else if (dateOfDeathType === DATE_OF_DEATH_OPTION.ON.value) {
        return dateOfDeath1;
    } else if (dateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value) {
        return 'unknown';
    }

    return 'Unknown date of death type ' + dateOfDeathType;
};

export const qualityWorkCheckList = [
    'Cross-check Identity',
    'Check Coffin Type',
    'Confirm if Viewing and Clothes',
    'Check Valuables Requirements',
    'Confirm Pacemaker Removed'
];

export const formStyles = () => ({
    rootContainer: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 800,
        margin: 'auto'
    },
    root: {
        width: '100%',
        padding: 10,
        fontSize: 8
    },
    hidePrint: {
        display: 'none'
    },
    print: {
        padding: 0,
        margin: '0 auto',
        overflow: 'hidden',
        width: '100%',
        //maxWidth: 800,
        display: 'block',
        fontSize: '1rem'
    },
    center: {
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        display: 'block',
        marginBottom: '1rem',
        fontSize: 12
    },
    smallerButton: {
        marginRight: 15,
        '& > span': {
            fontSize: 11
        }
    },
    titleBoxRight: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    titleBoxLeft: {
        //height: '40px',
        height: '20px',
        width: '30px',
        display: 'block'
    },
    title: {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    tableTitle: {
        fontWeight: 'bold'
    },
    spacer: {
        margin: '1px 0 0 0',
        height: '1px',
        width: '100%'
    },
    inset: { margin: '0px 10px' },
    leftCol: { width: '25%' },
    notesPre: { whiteSpace: 'pre-wrap' }
});

export const DeceasedDetailsTable = withStyles(formStyles)(({ form, classes }) => {
    const deceased = joinDefined(
        [form.getState('FirstName'), form.getState('MiddleName'), form.getState('Surname')],
        ' '
    );

    const showAge =
        form.getState('Age') && form.getState('AgeCode')
            ? joinDefined([form.getState('Age'), form.getState('AgeCode')], ' ')
            : 'NOT SPECIFIED';

    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <span className={classes.tableTitle}>Deceased Details</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.leftCol}>Funeral Key</TableCell>
                <TableCell colSpan={5}>{form.getState('LegacyKey')}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell colSpan={5}>
                    <strong>{deceased}</strong>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Date Of Birth</TableCell>
                <TableCell colSpan={5}>{form.getState('DateOfBirth') || 'NOT SPECIFIED'}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Date Of Death</TableCell>
                <TableCell colSpan={5}>{getDateOfDeath(form)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Age</TableCell>
                <TableCell colSpan={5}>{showAge}</TableCell>
            </TableRow>
        </Fragment>
    );
});

export const CoffinDetailsTable = withStyles(formStyles)(({ form, classes }) => {
    let coffinDescription = '',
        trim = null;
    const coffinProducts = ProductConfig.products.filter(x => x.urlSegment === QueryKeys.Coffins);
    const bookingItems = form.getState('Disposal.DisposalBookingItems') || [];
    if (bookingItems.length) {
        for (let x = 0; x < bookingItems.length; x++) {
            const coffinIndex = indexOf(coffinProducts, c => c.id === bookingItems[x].ProductID);
            if (coffinIndex < 0) continue;

            //do the coffin stuff
            if (!coffinDescription || bookingItems[x].Quantity === 1) {
                if (!!bookingItems[x].VariationID && bookingItems[x].VariationID !== '0')
                    coffinDescription = coffinProducts[coffinIndex].variationMap[bookingItems[x].VariationID].title;
                else coffinDescription = coffinProducts[coffinIndex].title;
                //only use a trim if there's a place of viewing.
                trim =
                    form.getState('PlaceOfViewingItems').filter(x => isRelatedObjectDefined(x.Location)).length > 0
                        ? coffinProducts[coffinIndex].coffinTrim
                        : 'No View Trim';
            }
        }
    }

    const coffinComponents = [].concat(
        bookingItems
            .filter(x => x.URLSegment !== QueryKeys.Coffins)
            .map(x => {
                const product = ProductConfig.productMap[x.ProductID];
                if (product)
                    return x.Quantity + 'x ' + product.title + ((1 === 2 && !!x.Returns && ' (to be returned)') || '');

                const variation = ProductConfig.productMap[x.VariationID];
                if (variation)
                    return (
                        x.Quantity + 'x ' + variation.title + ((1 === 2 && !!x.Returns && ' (to be returned)') || '')
                    );

                console.error('Please load the products before rendering the coffin details table');
                return null;
            })
    );

    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <span className={classes.tableTitle}>Coffin Details</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Coffin</TableCell>
                <TableCell colSpan={5}>{coffinDescription}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Adornments</TableCell>
                <TableCell colSpan={5}>{joinDefined(coffinComponents, ', ')}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Trim</TableCell>
                <TableCell colSpan={5}>{trim || 'No Trim'}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Religious</TableCell>
                <TableCell colSpan={5}>
                    {joinDefined(
                        (form.getState('Clergy') || []).map(
                            x =>
                                (x.Contact &&
                                    CONTACT_CLERGY_RELIGIOUS(x.Contact.ClergyCategory) &&
                                    CONTACT_CLERGY_OPTIONS.find(e => e.value === x.Contact.ClergyCategory).label) ||
                                ''
                        ),
                        ', '
                    ) || 'N/A'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Flowers</TableCell>
                <TableCell colSpan={5}>{getFlorists(form)}</TableCell>
            </TableRow>
        </Fragment>
    );
});

export const FuneralDetailsTable = withStyles(formStyles)(({ form, classes }) => {
    const crematedOrBuried = form.getState('Disposal.CrematedOrBuried');
    const eventType = crematedOrBuried === 'Cremated' ? 'Cremation' : 'Burial';

    const placeOfService = form.getState('PlaceOfService');
    const placeOfRefreshments =
        form.getState('RefreshmentsVenue.Type') !== 'Not Required' && form.getState('RefreshmentsVenue');
    const placeOfDisposal = form.getState('Disposal');
    const placeOfViewingItems = !!form.getState('PlaceOfViewingRequired') ? form.getState('PlaceOfViewingItems') : [];

    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <span className={classes.tableTitle}>Funeral Events</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <span className={classes.tableTitle}>Date</span>
                </TableCell>

                <TableCell>
                    <span className={classes.tableTitle}>Time</span>
                </TableCell>

                <TableCell colSpan={2}>
                    <span className={classes.tableTitle}>Event</span>
                </TableCell>

                <TableCell colSpan={2}>
                    <span className={classes.tableTitle}>Location</span>
                </TableCell>
            </TableRow>

            {placeOfViewingItems
                .filter(x => isRelatedObjectDefined(x.Location))
                .map((placeOfViewingItem, index) => (
                    <TableRow key={index}>
                        <TableCell>{niceDateFromString(placeOfViewingItem.Start) || 'Unspecified'}</TableCell>
                        <TableCell>{niceTimeFromString(placeOfViewingItem.Start) || 'Unspecified'}</TableCell>
                        <TableCell colSpan={2}>{placeOfViewingItem.Type}</TableCell>
                        <TableCell colSpan={2}>{placeOfViewingItem.LocationFlattened}</TableCell>
                    </TableRow>
                ))}

            {isRelatedObjectDefined(placeOfService) && (
                <TableRow>
                    <TableCell>{niceDateFromString(placeOfService.Start)}</TableCell>
                    <TableCell>{niceTimeFromString(placeOfService.Start)}</TableCell>
                    <TableCell colSpan={2}>Service</TableCell>
                    <TableCell colSpan={2}>{placeOfService.LocationFlattened || '(no location)'}</TableCell>
                </TableRow>
            )}

            {isRelatedObjectDefined(placeOfDisposal) && (
                <TableRow>
                    <TableCell>{niceDateFromString(form.getState('Disposal.Start')) || 'Unspecified'}</TableCell>
                    <TableCell>{niceTimeFromString(form.getState('Disposal.Start')) || 'Unspecified'}</TableCell>
                    <TableCell colSpan={2}>{eventType}</TableCell>
                    <TableCell colSpan={2}>{placeOfDisposal.LocationFlattened || '(no location)'}</TableCell>
                </TableRow>
            )}

            {isRelatedObjectDefined(placeOfRefreshments) && (
                <TableRow>
                    <TableCell>{niceDateFromString(placeOfRefreshments.Start) || 'Unspecified'}</TableCell>
                    <TableCell>{niceTimeFromString(placeOfRefreshments.Start) || 'Unspecified'}</TableCell>
                    <TableCell colSpan={2}>Refreshments</TableCell>
                    <TableCell colSpan={2}>{placeOfRefreshments.LocationFlattened || '(no location)'}</TableCell>
                </TableRow>
            )}
        </Fragment>
    );
});

export const CheckListTable = withStyles(formStyles)(({ classes, form }) => {
    if (!form) return null;
    const office = getFuneralHomeByKey(form.getState('LegacyKey')) || null;
    const releaseFrom = office && office.label;
    const releaseTo = form.getState('Disposal.Location.Name');
    const valuables = form.getState('ValuableItems').map(x => ({
        name: x.Description,
        comment: x.Note,
        quantity: x.Quantity,
        position: x.CurrentLocationDetail,
        releaseFromComment: x.ToBePlaced || 'Put On',
        releaseToComment: x.ToBeReturned ? x.WhereToBeReturned : 'Leave On'
    }));

    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <span className={classes.tableTitle}>Release Instruction</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3}>
                    <span className={classes.tableTitle}>Release From</span>
                </TableCell>
                <TableCell colSpan={3}>
                    <span className={classes.tableTitle}>Release To</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3}>{releaseFrom}</TableCell>
                <TableCell colSpan={3}>{releaseTo}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={6}>
                    <span className={classes.tableTitle}>Valuables</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <span className={classes.tableTitle}>Item</span>
                </TableCell>
                <TableCell>
                    <span className={classes.tableTitle}>Comment</span>
                </TableCell>
                <TableCell>
                    <span className={classes.tableTitle}>Qty</span>
                </TableCell>
                <TableCell>
                    <span className={classes.tableTitle}>Position</span>
                </TableCell>
                <TableCell>
                    <span className={classes.tableTitle}>Placement</span>
                </TableCell>
                <TableCell>
                    <span className={classes.tableTitle}>Return To</span>
                </TableCell>
            </TableRow>
            {(valuables.length > 0 &&
                valuables.map((x, i) => (
                    <TableRow key={i}>
                        <TableCell>{x.name}</TableCell>
                        <TableCell>{x.comment}</TableCell>
                        <TableCell>{x.quantity}</TableCell>
                        <TableCell>{x.position}</TableCell>
                        <TableCell>{x.releaseFromComment}</TableCell>
                        <TableCell>{x.releaseToComment}</TableCell>
                    </TableRow>
                ))) || (
                <TableRow>
                    <TableCell colSpan={6}>No valuables.</TableCell>
                </TableRow>
            )}
        </Fragment>
    );
});

export const MortuaryActionTable = withStyles(formStyles)(({ classes, data }) => {
    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={12}>
                    <span className={classes.tableTitle}>Mortuary Checkout - Checklist</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3}>
                    <span className={classes.tableTitle}>Check Item</span>
                </TableCell>
                <TableCell colSpan={3}>
                    <span className={classes.tableTitle}>Details</span>
                </TableCell>
                <TableCell colSpan={3}>
                    <span className={classes.tableTitle}>Checked?</span>
                </TableCell>
                <TableCell colSpan={3}>
                    <span className={classes.tableTitle}>Checked by</span>
                </TableCell>
            </TableRow>

            {!!data &&
            data.map((item, i) => (
                <TableRow key={i}>
                    <TableCell colSpan={3}>{item.Title}</TableCell>
                    <TableCell colSpan={3}>{item.Join.Description}</TableCell>
                    <TableCell colSpan={3}>{niceDateTimeFromString(item.Join.Checked)}</TableCell>

                    <TableCell colSpan={3}>
                        {joinDefined(
                            [
                                isRelatedObjectDefined(item.Join.Member) &&
                                `${item.Join.Member.FirstName} ${item.Join.Member.Surname}`,
                                isRelatedObjectDefined(item.Join.MortuaryMember) &&
                                `${item.Join.MortuaryMember.FirstName} ${item.Join.MortuaryMember.Surname}`
                            ],
                            ' & '
                        )}
                    </TableCell>
                </TableRow>
            ))}
        </Fragment>
    );
});

export const GraveTable = withStyles(formStyles)(({ classes, form }) => {
    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={2}>
                    <span className={classes.tableTitle}>Grave Details </span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.leftCol}>Ground Type / Depth</TableCell>
                <TableCell>
                    {form.getField('Grave.GroundDescription')} / {form.getField('Grave.DepthOption')}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Allocation</TableCell>
                <TableCell>
                    {!!form.getField('Grave.PortionGardenName') &&
                    'Portion/Garden Name: ' + form.getField('Grave.PortionGardenName') + '. '}
                    {!!form.getField('Grave.BeamRowLot') && 'Beam/Lot/Row: ' + form.getField('Grave.BeamRowLot') + '. '}
                    {!!form.getField('Grave.SideSection') &&
                    'Side/Section: ' + form.getField('Grave.SideSection') + '. '}
                    {!!form.getField('Grave.Number') && 'Grave Number: ' + form.getField('Grave.Number') + '. '}
                </TableCell>
            </TableRow>
        </Fragment>
    );
});
