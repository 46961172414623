import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import cx from 'classnames';
import { isNullOrUndefined } from 'util';
import GatedComponent from '../GatedComponent';

class DrawerListItem extends PureComponent {
    state = {
        expanded: false
    };

    componentDidMount() {
        const { activeItem, item } = this.props;
        if (isActive(item, activeItem)) this.setState({ expanded: true });
        else if (!!item.expanded) this.setState({ expanded: true });
    }

    onClick = e => {
        const { history, item } = this.props;

        if (item.path) {
            if (item.newTab) {
                let win = window.open(item.path, '_blank');
                win.focus();
            } else {
                history.push(item.path);
            }
        } else if (item.items) {
            const expanded = !this.state.expanded;
            this.setState({ expanded });
        }
    };

    onExpand = e => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ expanded: !this.state.expanded });
    };

    render() {
        const { item, activeItem, nested, hidden, minimized, classes, renderItem, renderGroup } = this.props;

        let renderFunc;
        if (item.items && renderGroup) renderFunc = renderGroup;
        else if (!item.items && renderItem) renderFunc = renderItem;

        return (
            <Fragment>
                <ListItem
                    button
                    onClick={this.onClick}
                    className={cx(classes.root, {
                        [classes.active]: isActive(item, activeItem),
                        [classes.nested]: nested,
                        [classes.minimized]: minimized
                    })}
                    aria-hidden={hidden}
                    tabIndex={hidden ? -1 : 0}
                >
                    {item.icon && this.renderIcon()}
                    {renderFunc ? renderFunc(item) : this.renderText()}
                    {this.renderAction()}
                </ListItem>
                {this.renderChildItems()}
            </Fragment>
        );
    }

    renderIcon() {
        const { item, activeItem, classes } = this.props;
        return (
            <ListItemIcon>
                <item.icon className={cx(classes.icon, { [classes.iconActive]: item === activeItem })} />
            </ListItemIcon>
        );
    }

    renderText() {
        const { item, activeItem, classes } = this.props;
        return (
            <ListItemText
                primary={item.label}
                classes={{
                    primary: cx(classes.text, item.newTab && 'targetNewPage', {
                        [classes.textActive]: item === activeItem
                    })
                }}
            />
        );
    }

    renderAction() {
        const { item, minimized } = this.props;
        if (minimized || !item.items) return;
        return (
            <IconButton onClick={this.onExpand}>
                <Icon>{this.state.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
            </IconButton>
        );
    }

    renderChildItems() {
        const { item, minimized } = this.props;
        if (minimized || !item.items) return;
        return (
            <Collapse in={this.state.expanded}>
                <List component="div" disablePadding>
                    {item.items.map(this.renderChildItem)}
                </List>
            </Collapse>
        );
    }

    renderChildItem = item => {
        const { minimized, activeItem, hidden, renderItem, renderGroup } = this.props;
        return (
            <GatedComponent key={item.id || item.label} showComponentCode={item.permission}>
                {() => {
                    return (
                        <DrawerListItemConnected
                            key={item.id || item.label}
                            item={item}
                            nested
                            minimized={minimized}
                            activeItem={activeItem}
                            hidden={hidden || !this.state.expanded}
                            renderItem={renderItem}
                            renderGroup={renderGroup}
                        />
                    );
                }}
            </GatedComponent>
        );
    };
}

const isActive = (self, activeItem) => {
    if (self === activeItem) return true;

    if (isNullOrUndefined(self.items)) return false;

    return !isNullOrUndefined(self.items.find(x => x === activeItem));
};

const styles = ({ spacing, typography, transitions, palette }) => ({
    root: {
        height: 48,
        paddingRight: 0,
        transition: transitions.create('padding-left', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    active: {
        backgroundColor: palette.action.selected,
        borderLeft: `3px solid ${palette.action.active}`
    },
    nested: {
        paddingLeft: spacing.unit * 4
    },
    minimized: {
        paddingLeft: spacing.unit * 2
    },
    icon: {
        width: 16,
        fontSize: typography.subheading.fontSize,
        margin: 0
    },
    iconActive: {
        marginLeft: -3 // to offset the border left on the active list item
    },
    text: {
        fontSize: typography.body1.fontSize,
        color: palette.custom.darkishGrey,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    textActive: {
        color: palette.action.active,
        fontWeight: typography.fontWeightMedium
    }
});

const DrawerListItemConnected = withRouter(withStyles(styles)(DrawerListItem));
export default DrawerListItemConnected;
