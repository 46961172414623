import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '../icon/DeleteIcon';
import cx from 'classnames';

const styles = ({ breakpoints }) => ({
    imageList: {
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: 'repeat(3, 1fr)',
        border: '1px solid transparent',
        width: '100%',
        [breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(4, 1fr)'
        },
        [breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(1, 1fr)'
        }
    },

    imageListItem: {
        display: 'inline-block',
        backgroundColor: '#ffffff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        border: '1px solid #AAA',
        height: '200px',
        position: 'relative',
        float: 'left',
        overflow: 'hidden',
        [breakpoints.down('xs')]: {
            gridColumn: 'span 1 !important'
        }
    },
    itemLabel: {
        background: 'rgba(255,255,255,0.75)',
        color: '#000',
        padding: '10px 5px',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        borderWidth: '0 0 0 1px',
        fontSize: '90%'
    },

    itemImage: {
        position: 'relative',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },

    deleteWrapper: {
        backgroundColor: '#FFFFFFB3',
        borderRadius: '50%'
    },
    deleteTopRight: {
        top: '0',
        right: '0',
        margin: '5px',
        position: 'absolute'
    },
    deleteButtonSmall: {
        height: '25px',
        width: '25px',
        '& > span > svg': {
            fontSize: '16px'
        }
    },
    flowerItemQty: {
        position: 'absolute',
        top: 0,
        right: 0,
        background: `#35327C`,
        color: 'white',
        fontSize: '1rem',
        borderRadius: '0px 0px 0px 5px',
        padding: '1px 5px'
    },
    noQuantity: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: `#35327C66`,
        color: 'white',
        textAlign: 'center'
    }
});

const ImageList = withStyles(styles)(({ value, classes, children, ...props }) => {
    return (
        <div className={classes.imageList} {...props}>
            {children}
        </div>
    );
});

const ImageListItem = withStyles(styles)(
    ({ key, value, classes, onClick, onDelete, customAction, title, quantity = 1, image, ...props }) => {
        const style = {
            gridColumn: 'span ' + (isNaN(value.tileWidth) ? '1' : value.tileWidth),
            gridRow: 'span ' + (value.tileHeight || '1'),
            cursor: onClick ? 'pointer' : 'default',
            backgroundImage: ' url(' + (!!image ? image : value.image) + ')'
        };

        if (quantity < 1) return null;

        return (
            <span key={key} className={classes.imageListItem} alt={value.label} style={style} onClick={onClick}>
                {quantity > 1 && <span className={classes.flowerItemQty}>x{quantity}</span>}
                {(title || value.title) && <span className={classes.itemLabel}>{title || value.title}</span>}
                {onDelete && <DeleteButton topRight onClick={onDelete} />}
                {customAction && <IconButton topLeft title={customAction.title} onClick={(e) => { e.stopPropagation(); if (customAction.handler) customAction.handler() }}>{customAction.icon}</IconButton>}
            </span>
        );
    }
);

const DeleteButton = withStyles(styles)(({ onClick, classes, small, topRight }) => (
    <span className={cx(classes.deleteWrapper, topRight ? [classes.deleteTopRight] : null)}>
        <IconButton
            title={'Remove'}
            className={small ? classes.deleteButtonSmall : null}
            onClick={e => {
                e.stopPropagation();
                onClick();
            }}
        >
            <DeleteIcon />
        </IconButton>
    </span>
));

export { ImageList, ImageListItem, DeleteButton };
