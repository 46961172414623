import gql from "graphql-tag";

export const DailyRunSheetFragment = gql`
    fragment BookingsFragmentInclude on FuneralBooking {
        ID
        Confirmed
        Cancelled
        Comment
        Notes
        Contact {
            ID
            Name
        }
    }

    fragment OrdersFragmentInclude on FuneralBooking {
        ...BookingsFragmentInclude
        BookingItems {
            edges {
                node {
                    ID
                    Title
                    Quantity
                    Comment
                }
            }
        }
    }

    fragment FuneralRunsheet on Funeral {
        ID
        FirstName
        MiddleName
        Surname
        LegacyKey

        DonationsName
        Donations2Name

        RefereeCremationCheck

        PlaceOfViewingRequired
        ReflectionRoomRequired

        FollowToDisposal

        CarRequired
        LimoRequired

        HearseRequired
        HearseComment
        HearseDriveAway
        HearseDriveAwayDate
        HearseDriveAwayTime
        HearseToPassHouse
        HearseToPassHouseDate
        HearseToPassHouseTime
        HearseToPassHouseAfterService
        HearseToPassHouseAfterServiceDate
        HearseToPassHouseAfterServiceTime

        WebcastRequired
        WebcastComment
        WebcastConfirmed
        WebcastEmailedLinkToFamily

        FlagRequired
        FlagType
        FlagDetails

        RslMasonicComment
        LastpostRequired
        ReveilleRequired
        PoppiesRequired

        NamesheetsRequired
        NamesheetsNotRequired
        NamesheetsDetails

        MassbookRequired
        MassbookDetails

        EnterMusicByWho
        ExitMusicByWho
        GravesideMusicByWho
        ReflectionMusicByWho
        EnterMusicNotes
        ExitMusicNotes
        GravesideMusicNotes
        ReflectionMusicNotes

        MemorialStationaryBy

        Lifestory {
            ID
            Presentation
            PresentationByWho
            PresentationFamilyToPrepareWhat
            PresentationFamilyToPreparePowerpointReceived
            PresentationFamilyToPreparePowerpointNotes
            FoyerPhotoRequired
        }

        Certification {
            ID
            TypeOfBdmDeathCertificate
            Referee {
                ID
            }
        }

        ValuableItems {
            ID
            Description
            Quantity
            ToBePlaced
            CurrentLocation
            CurrentLocationDetail
            ToBeReturned
            WhereToBeReturned
            Signed
            Note
        }

        CoffinBearings {
            ID
            Type
            Bearers
            Address
            Date
            Time
            Duration
            NumberOfStaffRequired
            NumberOfFamilyRequired
            Comment
        }

        MemorialStationary {
            ...OrdersFragmentInclude
        }

        PersonalisedTouches {
            ...OrdersFragmentInclude
        }

        Caterers {
            ...OrdersFragmentInclude
        }

        Florists {
            ...OrdersFragmentInclude
        }

        DefaultMusicians {
            ...BookingsFragmentInclude
        }

        EnterMusicians {
            ...BookingsFragmentInclude
        }

        ExitMusicians {
            ...BookingsFragmentInclude
        }

        GravesideMusicians {
            ...BookingsFragmentInclude
        }

        ReflectionMusicians {
            ...BookingsFragmentInclude
        }

        Informant {
            ID
            InformantsName
            RelationshipToDeceased
        }

        Cars {
            ID
            Date
            Time
            Duration
            Address
        }

        Limos { # obsolete, legacy
            ID
            Date
            Time
            Duration
            Address
        }

        LimoBookings {
            ...BookingsFragmentInclude
            DeliveryDate
            DeliveryTime
            DeliveryAddress
            Duration
        }


        Arrangers {
            ID
            Member {
                ID
                Surname
                FirstName
            }
        }
        Coordinators {
            ID
            Member {
                ID
                Surname
                FirstName
            }
        }
        Conductors {
            ID
            Member {
                ID
                Surname
                FirstName
            }
        }

        Clergy {
            ID
            Contact {
                ID
                Name
                ClergyCategory
            }
            Comment
        }

        RslMasonic {
            ID
            Contact {
                ID
                Name
            }
            Comment
        }

        FuneralDressing {
            ID
            Status
            Date
            Time
            Received
            GreekShroud
            Shroud
            Comment
        }

        SpecialInstructions {
            ID
            Comment
            Action
            Check
        }

        GravesideMusicSongs {
            ID
            Name
            Artist
        }
        EnterMusicSong {
            ID
            Name
            Artist
        }
        ReflectionMusicSongs {
            ID
            Name
            Artist
            IncludeInLifeStory
            ForFlowerPlacement
            #Other
            OtherText
        }
        ExitMusicSongs {
            ID
            Name
            Artist
        }

        Disposal {
            ID
            ClassName
            Private
            NoAttendance
            CemeterySection
            Start
            End
            LocationFlattened
            DisposalBookingItems {
                ID
                Quantity
                Title
                Returns
                Product {
                    ID
                    Title
                    Quantity
                    CoffinTrim
                    InternalItemID
                    Parent {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                    ProductCategories {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                }
                Variation {
                    ID
                    InternalItemID
                    AttributeValues {
                        edges {
                            node {
                                ID
                                Value
                                Type {
                                    ID
                                    Label
                                }
                            }
                        }
                    }
                }
            }
            CrematedOrBuried
            StaffAllocations {
                ...EventStaffRunsheet
            }
        }

        PlaceOfService {
            ID
            ClassName
            Start
            End
            Type
            Private
            LocationFlattened
            Comment
            StaffAllocations {
                ...EventStaffRunsheet
            }
        }

        RefreshmentsVenue {
            ID
            ClassName
            Confirmed
            Start
            End
            NumberOfPeople
            Type
            LocationFlattened
            Comment
            CateringStaffAllocations {
                ID
                Confirmed
                CateringStaff {
                    ID
                    Name
                }
            }
            StaffAllocations {
                ...EventStaffRunsheet
            }
        }

        PlaceOfViewingItems {
            ID
            ClassName
            Type
            Start
            End
            LocationFlattened
            Comment
            ViewingPrivate
            TypeOfViewingAdv
            StaffAllocations {
                ...EventStaffRunsheet
            }
        }
        
        ReflectionRoom {
            ID
            ClassName
            Start
            End
            LocationFlattened
            NumberOfPeople
            AuthorisedContact
            Applicant
            AttendeeNames
            StaffAllocations {
                ...EventStaffRunsheet
            }
        }
    }

    fragment EventStaffRunsheet on StaffAllocation {
        ID
        Title
        Start
        End
        Allocation
        Member {
            ID
            FirstName
            Surname
        }
    }

    query($from: String!, $to: String!) {
        readStaffAllocations(from: $from, to: $to, filterTypes: ["Appointment", "PreFuneral", "Arrangement"]) {
            ID
            Start
            End
            LocationFlattened
            Appointment {
                ID
                Reason
                Office
                Phone
                Members {
                    ID
                    Surname
                    FirstName
                }
            }
        }

        readFunerals(from: $from, to: $to) {
            edges {
                node {
                    ...FuneralRunsheet
                }
            }
        }
    }
`;
