import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose, Query } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '../../component/form/Grid';
import TextField from '../../component/form/TextField';
import gql from 'graphql-tag';
import DataForm from '../../component/form/DataForm';
import Select from '../../component/form/Select';
import { CONTACT_AUS_STATES, CONTACT_CATEGORY_OPTIONS, CONTACT_CLERGY_OPTIONS } from './contactConstants';
import GatedComponent from '../../component/GatedComponent';

class ContactView extends React.Component {
    state = {
        open: false,
        readOnly: true,
        loadedData: {}
    };

    constructor(props) {
        super(props);
        if (props.editable === true) {
            this.state.readOnly = false;
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, readOnly: true });
    };

    render() {
        const { contactId, children } = this.props;
        return (
            <Fragment>
                <span onClick={this.handleClickOpen}>{children}</span>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    disableBackdropClick={!this.state.readOnly}
                    disableEscapeKeyDown={!this.state.readOnly}
                >
                    <Query query={contactQuery} variables={{ id: contactId }}>
                        {results => this.renderContent(results)}
                    </Query>
                </Dialog>
            </Fragment>
        );
    }

    renderContent = ({ loading, error, data }) => {
        if (error) {
            console.error(error);
            return <div>Error: Can't read data!</div>;
        }

        const contactEdges = data.readAddressBooks && data.readAddressBooks.edges;

        //start to run Query, return loading message.
        if (!contactEdges) {
            return <div>Loading...</div>;
        }

        //loaded, but no edges, return not found message.
        if (contactEdges.length <= 0) {
            return <div>Contact Not Found!</div>;
        }

        const contactData = contactEdges[0].node;
        const { classes } = this.props;

        return (
            <DataForm
                id={contactData.id}
                loading={loading}
                error={error}
                data={data && contactData}
                buttonStyles={classes.saveButt}
                mutation={this.getUpdate()}
            >
                {(form, saveButton) => {
                    return (
                        <Fragment>
                            <DialogTitle className={classes.title}>
                                {!this.state.readOnly && 'EDIT '}CONTACT DETAILS
                            </DialogTitle>
                            <DialogContent className={classes.contentRoot}>
                                <Grid container spacing={24}>
                                    <Grid pc={1}>
                                        <TextField
                                            name="Name"
                                            label="Business Name"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>

                                    <Grid pc={0.5}>
                                        <TextField
                                            name="AddressLine1"
                                            label="Address line 1"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="AddressLine2"
                                            label="Address line 2"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={1}>
                                        <TextField
                                            name="Suburb"
                                            label="Suburb"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <Select
                                            name="State"
                                            label="State"
                                            form={form}
                                            selectProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                            options={CONTACT_AUS_STATES}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Postcode"
                                            label="Postcode"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>

                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Phone"
                                            label="Primary Number"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Mobile"
                                            label="Secondary Number"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Email"
                                            label="Email"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Fax"
                                            label="Fax"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={1}>
                                        <TextField
                                            name="Contact"
                                            label="Primary Contact Person"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <Select
                                            name="AddressBookCategory"
                                            label="Main Category"
                                            form={form}
                                            selectProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                            options={CONTACT_CATEGORY_OPTIONS}
                                        />
                                    </Grid>

                                    {form.getField('AddressBookCategory') === 'DOCTOR' && (
                                        <Grid pc={0.5}>
                                            <TextField
                                                name="RegistrationNoMisc"
                                                label="Registration Number"
                                                form={form}
                                                InputProps={{
                                                    readOnly: this.state.readOnly
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    {form.getField('AddressBookCategory') === 'CLERGY' && (
                                        <Fragment>
                                            <Grid pc={0.5}>
                                                <Select
                                                    name="ClergyCategory"
                                                    label="Religious affiliation"
                                                    form={form}
                                                    selectProps={{
                                                        readOnly: this.state.readOnly
                                                    }}
                                                    options={CONTACT_CLERGY_OPTIONS}
                                                    onChange={e => {
                                                        const newState = { ClergyType: null };
                                                        const val = Number(e.target.value);
                                                        if (val === 1 || val === 2) newState.ClergyType = 'CELEBRANT';
                                                        form.setField(newState);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid pc={1}>
                                                <TextField
                                                    name="Religion"
                                                    label="Extra categorisation details"
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: this.state.readOnly
                                                    }}
                                                />
                                            </Grid>
                                        </Fragment>
                                    )}
                                    <Grid pc={1}>
                                        <TextField
                                            name="Notes"
                                            label="Notes"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                            multiline={true}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>{this.renderActionButtons(form, saveButton)}</DialogActions>
                        </Fragment>
                    );
                }}
            </DataForm>
        );
    };

    renderActionButtons(form, saveButton) {
        const { classes, disabled } = this.props;
        if (this.state.readOnly) {
            return (
                <Fragment>
                    <Button onClick={this.handleClose} color="primary">
                        Close
                    </Button>
                    {!disabled && <GatedComponent showComponentCode={'FM_ACCESS_Contacts_Create'}>
                        {() => {
                            return <Button onClick={e => this.setState({ readOnly: false })}>Edit</Button>;
                        }}
                    </GatedComponent>}
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    {form.isDirty ? (
                        <Button className={classes.buttonCancel} onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                    ) : (
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                    )}
                    {saveButton}
                </Fragment>
            );
        }
    }

    getUpdate = () => {
        return gql`
            mutation UpdateAddressBook($input: AddressBookUpdateInputType!) {
                updateAddressBook(Input: $input) {
                    ID
                    LastEdited
                    Created
                    Name
                    AddressLine1
                    AddressLine2
                    State
                    Suburb
                    Postcode
                    Phone
                    Fax
                    Email
                    Contact
                    AddressBookCategory
                    Mobile
                    Religion
                    Salutation
                    RegistrationNoMisc
                    NotCurrent
                    FeesInclGst
                    ClergyBaseRate
                    ClergyFollowToCommittalRate
                    ClergyFollowAfterRefreshmentsRate
                    Notes
                    Biography
                    ClergyType
                    ClergyCategory
                    PlaceOfServiceCategory
                    ExcludeFromTabletDisplay
                }
            }
        `;
    };
}

const contactQuery = gql`
    query($id: String) {
        readAddressBooks(id: $id) {
            edges {
                node {
                    ID
                    ClassName
                    LastEdited
                    Created
                    LegacyID
                    LegacyKey
                    Name
                    AddressLine1
                    AddressLine2
                    State
                    Suburb
                    Postcode
                    Phone
                    Fax
                    Email
                    Contact
                    AddressBookCategory
                    AsPlaceOfDeath
                    Mobile
                    Religion
                    Salutation
                    RegistrationNoMisc
                    NotCurrent
                    FeesInclGst
                    ClergyBaseRate
                    ClergyFollowToCommittalRate
                    ClergyFollowAfterRefreshmentsRate
                    Office
                    Notes
                    Biography
                    ClergyType
                    ClergyCategory
                    PlaceOfServiceCategory
                    ExcludeFromTabletDisplay
                    Address {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    ClergyAllocationsCodes {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    NotusedAssociatedChargesDescription {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    ClergyPlaceOfServiceCodes {
                        AllItems {
                            Key
                            Value
                        }
                    }
                }
            }
        }
    }
`;

const styles = () => ({
    //title: { minWidth: 400 },
    contentRoot: {
        maxHeight: 'calc(100vh - 250px)'
    },
    buttonCancel: { color: '#E64040' },
    buttonSave: { color: '#26CC6F' },
    saveButt: {
        position: 'static!important'
    }
});

export default compose(withRouter, withStyles(styles))(ContactView);
