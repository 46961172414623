import gql from 'graphql-tag';
import { getClient } from '../../../apollo';

const CheckoutFragment = gql`
    fragment Checkout on Funeral {
        ID
        LegacyID
        LegacyKey
        FirstName
        MiddleName
        Surname
        PlaceOfViewingRequired
        ReadyForMortuary
        MortuaryActionReturnedReason
        DatetimeOfMortuaryCheckout
        DateTransferredToMortuary
        DatetimeReadyForMortuaryCheckout

        Disposal {
            ID
            Date
            Time
            CrematedOrBuried
            Location {
                ID
                Name
            }
        }

        Certification {
            ID
            TypeOfBdmDeathCertificate
            Referee {
                ID
            }
        }

        RefereeCremationCheck

        PlaceOfService {
            ID
            Date
            Time
            Type
            Location {
                ID
                Name
            }
        }

        PlaceOfViewingItems {
            ID
            Date
            Time
            Location {
                ID
                Name
            }
        }
    }
`;

const CheckoutListQuery = gql`
    ${CheckoutFragment}
    query {
        readFunerals(mortuary: true, checkout: true) {
            edges {
                node {
                    ...Checkout
                }
            }
        }
    }
`;

export const getCheckoutList = () => {
    return getClient()
        .query({ query: CheckoutListQuery, fetchPolicy: 'network-only' })
        .then(
            ({ data }) => {
                const { readFunerals } = data;
                if (readFunerals) {
                    return {
                        items: readFunerals.edges.map(({ node }) => {
                            return {
                                ...node
                            };
                        })
                    };
                }
            },
            e => {
                console.error('failed to get checkout list', e);
                return { error: 'failed to get checkout list' };
            }
        );
};
