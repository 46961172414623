import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from '../../../component/form/GqlAutocomplete';
import { joinDefined } from '../../../util/strings';
import { getEnquiryClient } from '../../../apollo';

const EnquiryAutoComplete = ({ category, className, name, clearOnSelect = true, ...props }) => {
    return (
        <GqlAutocomplete
            queryClient={getEnquiryClient()}
            className={className}
            readAllQuery={readAllEnquiries}
            placeholder="Enquiry..."
            labelFieldFunc={e =>
                joinDefined(
                    [e.LegacyKey, ' - ', e.NameOfDeceased, ' - with ', e.EnquirerGivenName, e.EnquirerSurname],
                    ' '
                )
            }
            multiple={true}
            name={name}
            clearOnSelect={clearOnSelect}
            {...props}
        />
    );
};

const readAllEnquiries = gql`
    fragment EnquiryFragment on Enquiry {
        ID
        LegacyKey
        EnquirerResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        EnquirerGivenName
        EnquirerSurname
        EnquirerPhone
        EnquiryType
        RelationshipToDeceased
        GivenName
        Surname
        NameOfDeceased
    }

    query ReadEnquiries($contains: String) {
        searchEnquiries(contains: $contains, limit: 15, offset: 0) {
            edges {
                node {
                    ...EnquiryFragment
                }
            }
        }
    }
`;

export default EnquiryAutoComplete;
