import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';
import cx from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from './TextField';
import Spinner from '../Spinner';
import { getClient } from '../../apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo/index';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../icon/SearchIcon';
import { getValidationDecorations } from '../../util/validation';

/**
 * Autocomplete that supports searching for and selecting results from
 * the gql database.
 *
 * Can use this by providing the `form` state and the `name` of the field on
 * the Funeral type which has the address book entry to be selected.
 *
 * For custom handling behavior you can provide the `value` and `onSelect`
 * callback handler passed through props.
 *
 * To set the fields that will be displayed, you can specify labelField
 * To set the field that will be used as the ID query, specify valueField
 */

class GqlAutocomplete extends Component {
    static defaultProps = {
        value: null, // Object like { ID: 1, Name: "Hospital" }
        onSelect: undefined,
        placeholder: 'Search...',
        categories: undefined, //Object like [ { key:'', label:'' } ],
        readAllQuery: undefined, //query to read ALL items from database
        readOneQuery: undefined //query to read single item by ID from the database
    };

    state = {
        value: null,
        searchTerm: '',
        focused: false,
        labelField: 'Name',
        valueField: 'ID',
        labelFieldFunc: null,
        valueIsListed: false
    };

    componentWillMount() {
        const valueField = this.getValueOrDefault('valueField', this.state, this.props);
        const labelField = this.getValueOrDefault('labelField', this.state, this.props);
        const labelFieldFunc = this.getValueOrDefault('labelFieldFunc', this.state, this.props);
        this.setState({ valueField, labelField, labelFieldFunc });
        this.resetInitialState(this.props, true);
    }

    componentWillReceiveProps(nextProps) {
        this.resetInitialState(nextProps, false);
    }

    componentDidUpdate(_, { searchTerm }) {
        if (searchTerm !== this.state.searchTerm) this.updateForm();
    }

    resetInitialState(nextProps, isMount) {
        const { name, form, findById, readOneQuery, categories, queryClient } = nextProps;

        if (form && form.getField) {
            form.getState = form.getField;
        }

        const nextValue = (form && form.getState(name)) || nextProps.value;

        //on first call, we don't need to compare props. just set the initial state
        //on subsequent calls, we're comparing old props to new props
        if (!isMount) {
            //prime the first value
            const lastValue = (this.props.form && this.props.form.getState(name)) || this.props.value || null;
            const stateValue = this.state.value;
            if (nextValue === lastValue && nextValue === stateValue) return;
        }

        if (findById && readOneQuery) {
            if (nextValue) {
                var that = this;
                getValueById(nextValue, readOneQuery, categories, queryClient).then(result => {
                    if (!result) return;
                    const clonedResult = { ...result };
                    that.setState({ value: clonedResult });
                    that.setSearchTerm(clonedResult, nextProps.labelField, nextProps.labelFieldFunc);
                });
            }
        } else if (nextValue) {
            this.setState({ value: nextValue });
            this.setSearchTerm(nextValue, nextProps.labelField, nextProps.labelFieldFunc);
        }
    }

    setSearchTerm = (value, labelField, labelFieldFunc) => {
        if (!labelField) labelField = this.state.labelField;
        if (!labelFieldFunc) labelFieldFunc = this.state.labelFieldFunc;

        const lastSearchTerm = this.state.searchTerm;

        let searchTerm;
        if (typeof value === 'object') {
            if (labelFieldFunc) {
                searchTerm = labelFieldFunc(value);
            } else {
                searchTerm = value.hasOwnProperty(labelField) ? value[labelField] : null;
            }
        } else {
            searchTerm = value;
        }

        if (lastSearchTerm === searchTerm) return;

        this.setState({ searchTerm });
    };

    getValueOrDefault(name, state, props) {
        const defaultValue = state ? state[name] : null;
        const propValue = props ? props[name] : null;
        return propValue || defaultValue;
    }

    onInputRef = input => {
        this.input = input;
    };

    onInputFocus = e => {
        this.setState({ focused: true });
    };

    onInputBlur = e => {
        this.setState({ focused: false }, () => !!this.state.value && this.setSearchTerm(this.state.value));
    };

    updateForm() {
        const { name, form, allowUnlisted } = this.props;
        const { value, valueIsListed } = this.state;

        if (!form || (!allowUnlisted && !valueIsListed)) return;

        if (form.setField) form.setState = form.setField;

        form.setState({ [name]: value });
    }

    onItemSelected = (e, result) => {
        const { onSelect, findById, clearOnSelect, form, name } = this.props;

        if (onSelect) onSelect(e, result);

        if (e.defaultPrevented) return;

        if (clearOnSelect) {
            this.setState({ value: null, searchTerm: '', valueIsListed: true });
        } else {
            const valueToUse = findById && result ? result[this.state.valueField] : result;
            this.setState({ value: valueToUse, valueIsListed: true });
            this.setSearchTerm(valueToUse);
        }

        if (this.input) this.input.blur();

        if (form && !form.getValidation) {
            console.log(name + ' form does not contain validation function!');
            return;
        }

        if (form && name && form.getValidation(name, true).shouldUpdate) this.forceUpdate();
    };

    getQueryVariables() {
        const { categories, canSearchByKey } = this.props;
        const { searchTerm } = this.state;

        let variables = {
            limit: 10,
            filter: categories
        };

        const term = !!searchTerm ? ('' + searchTerm).trim() : '';
        const terms = term.split(' ');

        if (canSearchByKey && !isNaN(parseInt(terms[0], 10))) {
            // If the term is non-numeric then we do a `contains` search
            // otherwise we search for a `key` match
            variables.key = terms[0];
            variables.contains = null;
        } else {
            variables.key = null;
            variables.contains = term;
        }

        return variables;
    }

    render() {
        const { readAllQuery, convertResults, queryClient } = this.props;
        const { focused } = this.state;
        const variables = this.getQueryVariables();

        return (
            <Query
                query={readAllQuery}
                skip={!focused || (!variables.contains && !variables.key)}
                variables={variables}
                client={queryClient || getClient()}
                context={{ debounceKey: 2 }}
            >
                {({ data, loading, error, networkStatus }) => {
                    let results = null;
                    if (data && !loading) {
                        const propertyName = Object.keys(data)[0];
                        results = convertResults
                            ? convertResults(data)
                            : data[propertyName]
                                ? (data[propertyName].edges || data[propertyName]).map(e => e.node || e)
                                : null;
                    }

                    const isReallyLoading = results != null && loading && focused; //it seems to loading=true before a query is run
                    return (
                        <Downshift>
                            {d => this.renderContainer(results, isReallyLoading, d, error, networkStatus)}
                        </Downshift>
                    );
                }}
            </Query>
        );
    }

    renderContainer(results, loading, downshiftData, error, networkStatus) {
        const { classes, className } = this.props;
        return (
            <div className={cx(classes.root, className)}>
                {this.renderInput(downshiftData, loading, networkStatus)}
                {this.renderResults(results, loading, downshiftData, error, networkStatus)}
            </div>
        );
    }

    renderInput(downshiftData, loading, networkStatus) {
        const { placeholder, id, name, label, classes, multiple, required, allowUnlisted, addNewButton, disabled } = this.props;
        const { searchTerm } = this.state;

        const onChange = allowUnlisted
            ? e => this.setState({ searchTerm: e.target.value, valueIsListed: false, value: e.target.value })
            : e => this.setState({ searchTerm: e.target.value, valueIsListed: false });

        const decorations = getValidationDecorations(this.props);

        const busy = networkStatus && networkStatus !== 7;

        return (
            <TextField
                id={id || name}
                fullWidth
                label={label}
                multiple={multiple}
                required={required}
                validationResult={decorations.validationResult}
                disabled={disabled}
                InputProps={{
                    disableUnderline: true,
                    ...downshiftData.getInputProps({ placeholder }),
                    startAdornment: (
                        <InputAdornment position="start" className={classes.iconContainer}>
                            {!!busy && !!searchTerm ? <Spinner /> : <SearchIcon className={classes.icon} />}
                        </InputAdornment>
                    ),
                    endAdornment: !!addNewButton && <InputAdornment position="end">{addNewButton}</InputAdornment>,
                    inputProps: {
                        ref: this.onInputRef,
                        onChange,
                        value: searchTerm || '',
                        onFocus: this.onInputFocus,
                        onBlur: this.onInputBlur
                    }
                }}
            />
        );
    }

    renderResults(results, loading, downshiftData, error, networkStatus) {
        const { focused, searchTerm } = this.state;
        if (!focused || !searchTerm) return false;
        const { classes } = this.props;
        const noResults = error || !results || !results.length;

        const busy = networkStatus && networkStatus !== 7;

        return (
            <Paper className={`${classes.results} hover-search-results square`}>
                {noResults ? (
                    busy ? (
                        <Typography className={classes.noResult}>Searching...</Typography>
                    ) : (
                        <Typography className={classes.noResult}>
                            {(error ? error.message : null) || 'No Results'}
                        </Typography>
                    )
                ) : (
                    results.map((r, i) => this.renderResult(r, i, downshiftData))
                )}
            </Paper>
        );
    }

    renderResult = (result, index, downshiftData) => {
        const { classes, extraLabelField, extraLabelFunc } = this.props;
        const { valueField, labelField, labelFieldFunc } = this.state;

        const itemProps = downshiftData.getItemProps({ item: result[valueField] });

        return (
            <MenuItem
                {...itemProps}
                className={classes.item}
                selected={downshiftData.highlightedIndex === index}
                key={result[valueField]}
                onClick={() => this.onItemSelected(this.createEvent('itemSelected'), { ...result })}
            >
                {extraLabelFunc && extraLabelFunc(result)}
                {labelFieldFunc ? labelFieldFunc(result) : result[labelField]}
                {extraLabelField && (
                    <small style={{ marginLeft: 'auto', paddingLeft: 12 }}>{result[extraLabelField]}</small>
                )}
            </MenuItem>
        );
    };

    createEvent = name => {
        var e = document.createEvent('Event');
        e.initEvent(name, true, true);
        return e;
    };
}

export const getValueById = async (id, readOneQuery, categories = [], queryClient) => {
    const client = queryClient || getClient();
    const ID = id.ID === undefined ? id : id.ID;
    if (!ID) return null;
    const asyncQuery = await client.query({
        query: readOneQuery,
        variables: { id: ID, filter: categories }
    });

    const data = asyncQuery.data;
    const propertyName = Object.keys(data)[0];

    if (data[propertyName] && data[propertyName].edges) {
        const { convertResults } = this.props;
        const { valueField } = this.state;
        const results = convertResults ? convertResults(data) : data[propertyName].edges.map(e => e.node);
        const result = results.find(x => x[valueField] === ID);
        return result;
    }

    return data[propertyName];
};

const styles = ({ palette, typography }) => ({
    root: {
        position: 'relative',
        flex: '1 1 auto'
    },
    results: {
        position: 'absolute',
        zIndex: 2,
        left: 0,
        right: 0,
        top: 'calc(100% - 2px)',
        border: `1px solid ${palette.grey.A100}`,
        padding: `8px 0`,
        maxHeight: '50vh',
        overflow: 'auto'
    },
    item: {
        padding: '6px 16px',
        display: 'flex',
        //overflow: 'hidden',
        height: 'auto',
        minHeight: 24,
        textOverflow: 'ellipsis',
        fontSize: typography.body1.fontSize
    },
    spinner: {
        margin: '8px 16px'
    },
    noResult: {
        padding: '8px 16px'
    },
    iconContainer: {
        alignSelf: 'center'
    },
    icon: {
        fontSize: 16,
        color: palette.action.active
    }
});

// prettier-ignore
export default compose(
    withSnackbarMessage,
    withStyles(styles)
)(GqlAutocomplete);
