import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import FooterItem from './FooterItem';
import { items } from './footerConstants';
import Hidden from '@material-ui/core/Hidden';
import GatedComponent from '../GatedComponent';
import SimpleMenu from '../form/SimpleMenu';
import MenuItem from '@material-ui/core/MenuItem';

class Footer extends Component {
    state = {
        activeItem: undefined
    };

    componentDidMount() {
        this.updateActiveItem(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateActiveItem(nextProps);
    }

    updateActiveItem(props) {
        const path = props.location.pathname;
        const activeItem = items.find(item => path === item.path);
        if (activeItem !== this.state.activeItem) {
            this.setState({ activeItem });
        }
    }

    onClickItem = (e, item) => {
        e.preventDefault();
        this.props.history.push(item.path);
    };

    render() {
        const { classes } = this.props;
        const path = window.location.pathname;
        const needMenu =
            path.startsWith('/funeral/') ||
            path.startsWith('/quotes/enquiry/') ||
            path.startsWith('/documentation/') ||
            path.startsWith('/prearrangement/') ||
            path.startsWith('/transfers') ||
            path.startsWith('/account');
        if (!needMenu)
            return <BottomNavigation className={classes.root}>{items.map(this.renderItem)}</BottomNavigation>;
        return (
            <Fragment>
                <Hidden smDown>
                    <BottomNavigation className={classes.root}>{items.map(this.renderItem)}</BottomNavigation>
                </Hidden>
                <Hidden mdUp>
                    <BottomNavigation className={classes.rootMobile}>
                        <SimpleMenu>{items.map(this.renderItem)}</SimpleMenu>
                    </BottomNavigation>
                </Hidden>
            </Fragment>
        );
    }

    renderItem = item => {
        const { classes } = this.props;
        return (
            <GatedComponent key={item.id || item.label} showComponentCode={item.permission}>
                {() => {
                    return (
                        <Fragment>
                            <Hidden smDown>
                                <FooterItem
                                    key={item.label}
                                    item={item}
                                    active={item === this.state.activeItem}
                                    onClick={e => this.onClickItem(e, item)}
                                />
                            </Hidden>
                            <Hidden mdUp>
                                <MenuItem className={classes.mobMenuItem}>
                                    <FooterItem
                                        key={item.label}
                                        item={item}
                                        active={item === this.state.activeItem}
                                        onClick={e => this.onClickItem(e, item)}
                                    />
                                </MenuItem>
                            </Hidden>
                        </Fragment>
                    );
                }}
            </GatedComponent>
        );
    };
}

const styles = ({ sizes, zIndex, breakpoints, palette, funeralHome }) => ({
    root: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: sizes.footerHeight,
        boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
        borderTop: `1px solid ${palette.grey.A100}`,
        zIndex: zIndex.drawer + 1
    },
    rootMobile: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: sizes.footerHeight,
        boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
        borderTop: `1px solid ${palette.grey.A100}`,
        zIndex: zIndex.drawer + 1,
        justifyContent: 'left',
        [breakpoints.down('xs')]: {
            justifyContent: 'space-between'
        },
        '& > button': {
            borderRight: '1px solid #d5d5d5',
            borderRadius: '0',
            color: palette.basePrimary[funeralHome],
            alignItems: 'baseline',
            '& > span': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > label': {
                    marginRight: 0,
                    color: palette.basePrimary[funeralHome],
                    lineHeight: '1.5px'
                }
            },
            '& > span > svg': {
                [breakpoints.down('md')]: {
                    width: '1.75em',
                    height: '1.75em',
                    transform: 'rotate(90deg)'
                }
            }
        }
    },
    mobMenuItem: {
        height: 'auto',
        padding: 0,
        '& > a > span': {
            lineHeight: '1em'
        }
    }
});

export default withStyles(styles)(withRouter(Footer));
