import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const Separator = ({ classes, pad, padtop, padbot, ...props }) => {
    let margin;
    if (padtop && padbot) margin = `${padtop}px 0 ${padbot}px 0`;
    else if (padtop) margin = `${padtop}px 0 0 0`;
    else if (padbot) margin = `0 0 ${padbot}px 0`;
    else margin = '';

    return (
        <div className={classes.root} style={{ margin }} {...props}>
            &nbsp;
        </div>
    );
};

const styles = () => ({
    root: {
        width: '100%',
        height: '1px',
        display: 'block',
        backgroundColor: '#B5B5B5'
    }
});

export default withStyles(styles)(Separator);
