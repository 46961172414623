import Bdms from './Bdms';
import BdmsFragment from './BdmsFragment';
import { validationHelper } from '../../../util/validation';

export default {
    label: 'BDMs',
    component: Bdms,
    fragment: BdmsFragment,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {
        // don't write any of this data, it is generated in php
        [
            'FuneralDataStatus',
            'ExportDate',
            'ExportStatus',
            'ExportId',
            'DeathCertificateStatus',
            'DeathCertificateNumber',
            'FormattedBDMResponse',
            'LifeLinkValidationField',
            'WebServiceLogs',
            'BdmResponse'
        ].forEach(e => {
            delete saveData[e];
        });

        if (saveData.LegacyDocuments) {
            saveData.LegacyDocuments = saveData.LegacyDocuments.map(({ ID }) => ({ ID }));
        }
    },

    validation: {
        onValidate: {
            'Application.Quantity': (newValue, persistedValue, hasValue, getField) => {
                if (!getField('Application.CertificateRequired')) return validationHelper.ok();
                const quantity = parseInt(persistedValue, 10);
                if (quantity > 0 && !isNaN(quantity)) return validationHelper.ok();
                return validationHelper.required('Must be at least 1');
            },
            'Application.CertificateRequired': (newValue, persistedValue, hasValue, getField) => {
                return persistedValue !== undefined ? validationHelper.ok() : validationHelper.suggested();
            }
        }
    }
};
