import React, { Component, Fragment } from 'react';
import { cloneDeep } from 'apollo-utilities';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../workQueue2/extras/Modal';
import FileUploadCard from '../../workQueue2/extras/FileUploadCard';
import FlexGrid from '../../workQueue2/extras/FlexGrid';
import Grid from '../../../component/form/Grid';
import FileUpload, { getFileName } from '../../../component/form/FileUpload';
import TextField from '../../../component/form/TextField';
import Checkbox from '../../../component/form/Checkbox';
import SaveIcon from '../../../component/icon/SaveIcon';
import Spinner from '../../../component/Spinner';
import { createForm } from '../../../util/form';
import { deleteTypeName, isNullOrUndefined } from '../../../util/objects';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { getClient } from '../../../apollo';
import DocumentEmail from '../DocumentEmail';
import Inline, { inlineAlignment } from '../../workQueue2/extras/Inline';
import Button from '../../workQueue2/extras/Button';
import { diff } from '../../../util/functions';
import { getPopupDocumentName } from '../Tab_Summary';

class DocumentEmailModal extends Component {
    state = {
        loading: false,
        form: null,
        emailId: null,
        open: false
    };

    static getDerivedStateFromProps({ emailId, open, parentForm }, state) {
        const newState = { open };
        const documentEmails = parentForm.getField('Arrangement.DocumentEmails');
        const documentEmail = documentEmails && documentEmails.find(e => e.ID === emailId);
        if (!state.form || !state.form.state || (!!documentEmail && state.form.state.ID !== documentEmail.ID)) {
            if (!isNullOrUndefined(documentEmail)) {
                let clonedEmail = cloneDeep(documentEmail);
                const oldContext = state.form !== null ? state.form.context : null;
                newState.form = createForm(oldContext, clonedEmail);
                newState.emailId = emailId;
            } else {
                newState.form = null;
            }
            newState.loading = false;
        }

        return newState;
    }

    componentDidUpdate(_, oldState) {
        const { loading, form } = this.state;
        const { open } = this.props;
        if (open && form === null && !loading) {
            this.createDocumentEmail();
            return;
        }

        if (oldState.form === null && form !== null) form.context = this;
    }

    render() {
        const { open, onClose, parentForm } = this.props;
        const { form } = this.state;

        if (!form) return null;

        const attachments = form.getField('Attachments') || [];

        return (
            <Modal open={open} onClose={onClose} title="Add Email">
                <Grid container spacing={16}>
                    <Grid item>
                        <h3>Enter Email Details</h3>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Document Name"
                            name="DocumentName"
                            value={getPopupDocumentName(parentForm)}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField label="Subject" name="Subject" form={form} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField label="Message" multiline name="Message" form={form} />
                    </Grid>

                    <Grid item xs={12}>
                        <h4>Attach Files</h4>
                    </Grid>
                    <div>
                        <FileUpload
                            folderPath={`Uploads/DocumentEmail/${form.getField('ID')}`}
                            buttonOnly={true}
                            onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, attachments)}
                        />
                    </div>

                    {!!attachments && !!attachments.length && (
                        <Grid item xs={12}>
                            <h4> Attached Files </h4>
                            <FlexGrid>
                                {attachments.map(attachment => (
                                    <FileUploadCard
                                        key={'attachment' + attachment.ID}
                                        fileName={attachment.Name}
                                        fileLink={attachment.AbsoluteLink}
                                    />
                                ))}
                            </FlexGrid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <h4> Email Document </h4>
                        <Checkbox label="Yes, Email Document" name="ScheduledToSendEmail" form={form} />
                    </Grid>

                    {form.getField('ScheduledToSendEmail') && (
                        <Fragment>
                            <Grid item xs={12}>
                                <h4> Email to </h4>
                                <Checkbox label="Email Applicant" name="EmailApplicant" form={form} />
                                <Checkbox label="Email Other Recipient" name="EmailOther" form={form} />
                            </Grid>
                            {form.getField('EmailOther') && (
                                <Grid item xs={12}>
                                    <TextField label="Email Recipient" name="OtherEmailAddress" form={form} />
                                </Grid>
                            )}
                        </Fragment>
                    )}

                    <Grid item>
                        <Inline className="button-alignment" alignment={inlineAlignment.rightAlignSiblings} center>
                            <Button variant="secondary" onClick={() => onClose()}>
                                Cancel
                            </Button>

                            {!form.getField('ScheduledToSendEmail') && (
                                <Button
                                    variant="confirmation"
                                    onClick={() => this.onSave()}
                                    disabled={this.state.loading}
                                >
                                    {this.state.loading ? <Spinner /> : <SaveIcon />}
                                    Save Changes
                                </Button>
                            )}

                            {form.getField('ScheduledToSendEmail') && (
                                <Button
                                    variant="primary"
                                    onClick={() => this.onSendEmail()}
                                    disabled={this.state.loading}
                                >
                                    {this.state.loading && <Spinner />}
                                    {!!form.getField('EmailSent') && 'Re-'}Send Email
                                </Button>
                            )}
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    createDocumentEmail() {
        const email = {
            Message: null,
            ScheduledToSendEmail: false,
            EmailApplicant: false,
            EmailOther: false,
            OtherEmailAddress: null,
            Approved: false,
            SentToRecipientEmailAddresses: null
        };

        //add form details
        const form = createForm(this, email);
        this.setState({ loading: false, form });
        return null;
    }

    onSendEmail() {
        const { form } = this.state;

        this.setState({
            loading: true
        });

        form.state.ScheduledToSendEmail = true;

        this.onSave();
    }

    onSave() {
        const { form } = this.state;
        const { parentForm, arrangementID, emailId } = this.props;

        let email = form.state;

        this.setState({ loading: true });
        const that = this;

        const documentEmails = parentForm.getField('Arrangement.DocumentEmails');
        const documentEmail = documentEmails.find(e => e.ID === emailId);

        if (documentEmail) {
            return this.executeUpdate(documentEmail);
        } else {
            return createDocumentEmailFunc(email, arrangementID).then(
                ({ data }) => {
                    const createdEmail = data.createDocumentEmail;
                    const clonedEmail = cloneDeep(createdEmail);

                    that.executeUpdate(clonedEmail);
                },
                e => that.onGqlError('Failed to create document email.', e)
            );
        }
    }

    executeUpdate(documentEmail) {
        const { form, emailId } = this.state;
        let email = form.state;
        const { parentForm, onClose, arrangementID } = this.props;
        const that = this;
        // return;
        return updateDocumentEmailFunc(email, documentEmail, arrangementID).then(
            ({ data }) => {
                const DocumentEmails = parentForm.getField('Arrangement.DocumentEmails');
                const updatedEmail = data.updateDocumentEmail;
                const clonedEmail = cloneDeep(updatedEmail);
                const foundIndex = DocumentEmails.findIndex(e => e.ID === emailId);
                if (foundIndex >= 0) {
                    Object.assign(DocumentEmails[foundIndex], clonedEmail);
                } else {
                    DocumentEmails.push(clonedEmail);
                }
                parentForm.setField(
                    {
                        'Arrangement.DocumentEmails': DocumentEmails
                    },
                    true
                );

                that.setState({
                    loading: false,
                    open: false
                });
                onClose();
                parentForm.save();
            },
            e => that.onGqlError('Failed to update document email.', e)
        );
    }

    onGqlError(action, error) {
        console.error(action, error);
        // this.props.setSnackbarMessage(action + (error ? ' - ' + error.message : ''));
    }

    onAttachmentRemove(fileId, property) {
        const { form } = this.state;

        let attachments = form.getField('Attachments');
        attachments = attachments.filter(obj => fileId !== obj.ID);

        form.setField({ [property]: attachments });
    }

    handleUploadAttachment(uploadFile, attachments) {
        const { form } = this.state;

        attachments.push({
            ID: uploadFile.id,
            AbsoluteLink: uploadFile.url,
            Name: getFileName(uploadFile.filename)
        });

        form.setState({
            Attachments: attachments
        });

        this.setState({
            loading: false
        });
    }
}

const createDocumentEmailMutation = gql`
    ${DocumentEmail}
    mutation CreateDocumentEmail($input: DocumentEmailCreateInputType!) {
        createDocumentEmail(Input: $input) {
            ...DocumentEmail
        }
    }
`;

export const createDocumentEmailFunc = async (documentEmail, arrangementID) => {
    return await getClient().mutate({
        mutation: createDocumentEmailMutation,
        variables: {
            input: trimDocumentEmail(arrangementID, documentEmail)
        }
    });
};

const updateDocumentEmailMutation = gql`
    ${DocumentEmail}
    mutation UpdateDocumentEmail($input: DocumentEmailUpdateInputType!) {
        updateDocumentEmail(Input: $input) {
            ...DocumentEmail
        }
    }
`;

const updateDocumentEmailFunc = async (documentEmail, original, arrangementID) => {
    return await getClient().mutate({
        mutation: updateDocumentEmailMutation,
        variables: {
            input: trimDocumentEmail(arrangementID, documentEmail, original)
        }
    });
};

const trimDocumentEmail = (arrangementID, newEmail, original) => {
    let email = original
        ? JSON.parse(JSON.stringify(original ? diff({ ...newEmail }, original) : { ...newEmail }))
        : JSON.parse(JSON.stringify({ ...newEmail }));

    email.FuneralArrangementID = arrangementID;

    if (isRelatedObjectDefined(original)) {
        email.ID = original.ID;
    }

    const attachments = [];
    if (original && !!email.Attachments && email.Attachments.length > 0) {
        email.Attachments.forEach(function(attachment, index, object) {
            if (!!attachment.ID) {
                attachments.push({
                    ID: attachment.ID
                });
            }
        });
        email.Attachments = attachments;
    } else if (!original && email.Attachments) {
        delete email.Attachments;
    }

    deleteTypeName(email);

    return { ...email };
};

export default withStyles({})(DocumentEmailModal);
