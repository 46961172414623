import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { Query } from 'react-apollo';
import TaskFragment from './Task';
import { getMyClient } from '../../apollo';
import Select from '../../component/form/Select';
import Spinner from '../../component/Spinner';

export const getTaskStatus = task => {
    let status = 'To Do';
    if (!!task.Completed) {
        status = 'Completed';
    } else if (moment() > moment(task.Due)) {
        status = 'Overdue';
    } else if (moment().add(1, 'days') > moment(task.Due)) {
        status = 'Urgent';
    } else if (task.InProgress) {
        status = 'In Progress';
    } else if (moment(task.Created).add(7, 'days') > moment()) {
        status = 'New';
    }
    return status;
};

export const getTaskStatusClass = task => {
    const status = getTaskStatus(task) || '';
    return status === 'Completed'
        ? 'done'
        : status === 'New'
        ? 'new'
        : status === 'Urgent'
        ? 'urgent'
        : status === 'Overdue'
        ? 'overdue'
        : !!task.Due
        ? ''
        : 'due';
};

export const taskURLObj = task => {
    if (!task) return null;
    const category = task.Category;
    const link = task.LinkURL;
    const hasCremation = !!task.Cremation && !!Number(task.Cremation.ID);
    const hasPlaque = !!task.Plaque && !!Number(task.Plaque.ID);
    const hasEnquiryCM = !!task.EnquiryCM && !!Number(task.EnquiryCM.ID);
    const hasEnquiryFM = !!task.EnquiryFM && !!Number(task.EnquiryFM.ID);
    const hasFuneral = !!task.Funeral && !!Number(task.Funeral.ID);
    const selfPath = window.location.host;
    const fmAdmin = selfPath.replace(/cremation/, 'funeral');
    const cmAdmin = selfPath.replace(/funeral/, 'cremation');

    let ret = null;
    if (hasCremation) {
        ret = {
            link: '//' + cmAdmin + '/cremations/' + task.Cremation.ID,
            title: task.Cremation.LegacyKey,
            category: 'Cremation'
        };
    } else if (hasPlaque) {
        ret = {
            link: '//' + cmAdmin + '/plaques/' + task.Plaque.ID,
            title: task.Plaque.LegacyKey,
            category: 'Plaque'
        };
    } else if (hasEnquiryCM) {
        ret = {
            link: '//' + cmAdmin + '/enquiries/' + task.EnquiryCM.ID,
            title: task.EnquiryCM.LegacyKey,
            category: 'Enquiry'
        };
    } else if (hasEnquiryFM) {
        ret = {
            link: '//' + fmAdmin + '/enquiries/' + task.EnquiryFM.ID,
            title: task.EnquiryFM.LegacyKey,
            category: 'Enquiry'
        };
    } else if (hasFuneral) {
        ret = {
            link: '//' + fmAdmin + '/funeral/' + task.Funeral.LegacyKey + '/' + task.Funeral.ID,
            title: task.Funeral.LegacyKey,
            category: 'Funeral'
        };
    }
    if (!ret && link) {
        ret = {
            link: link.search(/^(\/\/|https?:\/\/)/) === -1 ? 'https://' + link : link,
            title: link
        };
    }
    if (!!ret) {
        const cat = !!category && TASK_CATEGORY_OPTIONS.find(e => !!e && e.value === category);
        if (!!cat) ret.category = cat.labelOne || '';
    }
    return ret;
};

export const TeamDropdownField = (form, onSelectTeam) => {
    return (
        <Query
            query={gql`
                query ReadGroups {
                    readGroups(parentGroupCode: "fm-user") {
                        ID
                        Title
                    }
                }
            `}
        >
            {({ data, loading }) => {
                if (loading) return <Spinner />;
                const allGroups = data && data.readGroups;
                const groupOptions = allGroups.map(group => ({
                    label: group.Title,
                    value: group.ID
                }));
                const selectedGroup = form.getField('AssignedTeams[0]');
                if (!onSelectTeam) return (!!selectedGroup && <p>{selectedGroup.Title}</p>) || <p>(none)</p>;
                return (
                    <Select
                        value={!!selectedGroup ? selectedGroup.ID : ''}
                        onChange={e => onSelectTeam(e, form, allGroups)}
                        placeholder="Select a team..."
                        options={groupOptions}
                    />
                );
            }}
        </Query>
    );
};

export const TaskProgressField = form => {
    const task = form.state;
    if (!task) return null;
    const onTaskProgressChange = e => {
        const value = e.target.value;
        form.setField({
            Archived: value === 'Archived' ? new Date() : null,
            Completed: value === 'Completed' ? new Date() : null,
            InProgress: value === 'InProgress'
        });
    };

    return (
        <Select
            required
            value={
                !!task.Archived ? 'Archived' : !!task.Completed ? 'Completed' : !!task.InProgress ? 'InProgress' : ''
            }
            placeholder="To Do"
            options={[
                { label: 'In Progress', value: 'InProgress' },
                { label: 'Completed', value: 'Completed' },
                { label: 'Archived', value: 'Archived' }
            ]}
            onChange={e => onTaskProgressChange(e)}
        />
    );
};

export const TASK_CATEGORY_OPTIONS = [
    { labelOne: 'Custom', label: 'Custom Tasks', value: null },
    { labelOne: 'Funeral', label: 'Funerals', value: 'Funeral' },
    { labelOne: 'Cremation', label: 'Cremation Records', value: 'Cremation' },
    { labelOne: 'Plaque', label: 'Plaque Orders', value: 'Plaque' },
    { labelOne: 'Enquiry', label: 'Quotes & Enquiries', value: 'EnquiryCM' },
    { labelOne: 'Enquiry', label: 'Quotes & Enquiries', value: 'EnquiryFM' },
    { labelOne: 'Goal', label: 'Goals', value: 'Goal' }
];

export const AuditEventTypes = {
    Comment: 0,
    Assign: 1,
    Unassign: 2,
    Archive: 3,
    Restore: 4,
    Due: 5,
    InProgress: 6,
    Completed: 7
};

export const assembleFilters = filterBy => {
    const stamp = moment().startOf('day');
    const filters = [];
    let isComplete = false;
    let isArchived = false;
    let isStarred = null;
    if (filterBy.type === 'inprogress') {
        filters.push({ field: 'InProgress', value: filterBy.value ? '1' : '0' });
    } else if (filterBy.type === 'overdue') {
        filters.push({ field: 'Due:LessThan', value: stamp });
    } else if (filterBy.type === 'starred') {
        isStarred = true;
    } else if (filterBy.type === 'new') {
        filters.push({ field: 'Created:GreaterThan', value: stamp.subtract(7, 'days') });
    } else if (filterBy.type === 'all' && !!filterBy.value) {
        if (filterBy.value === 'done') {
            isComplete = true;
        } else {
            isArchived = filterBy.value === 'gone' ? true : null;
            isComplete = null;
        }
    } else if (
        filterBy.type === 'Cremation' ||
        filterBy.type === 'Funeral' ||
        filterBy.type === 'EnquiryFM' ||
        filterBy.type === 'EnquiryCM' ||
        filterBy.type === 'Plaque'
    ) {
        if (!filterBy.value) filters.push({ field: filterBy.type + 'ID:not', value: '0' });
        else filters.push({ field: filterBy.type + 'ID', value: filterBy.value });
    } else if (filterBy.type === 'team') {
        filters.push({ field: 'AssignedTeams.ID', value: filterBy.value });
    } else if (filterBy.type === 'member') {
        filters.push({ field: 'AssignedMembers.ID', value: filterBy.value });
    } else if (filterBy.type === 'goal') {
        filters.push({ field: 'Category', value: 'Goal' });
    } else if (filterBy.type === 'custom') {
        filters.push({ field: 'FuneralID', value: '0' });
        filters.push({ field: 'Category:not', value: 'Goal' });
        filters.push({ field: 'EnquiryFMID', value: '0' });
    }
    if (filterBy.labels && filterBy.labels.length) {
        filterBy.labels.forEach(labelID => filters.push({ field: 'AssignedLabels.ID', value: labelID }));
    }
    return { filters, isComplete, isStarred, isArchived };
};

//gets the users tasks from the database
export const getUserTasksFunc = async (userId, offset = 0, limit = 1000, contains = '', sortBy, filterBy) => {
    const variables = {
        userId,
        offset,
        limit,
        contains,
        sortBy,
        ...assembleFilters(filterBy)
    };
    if (
        filterBy.type === 'member' ||
        filterBy.type === 'team' ||
        filterBy.type === 'Funeral' ||
        filterBy.type === 'EnquiryFM'
    ) {
        delete variables.userId;
    }
    const asyncQuery = await getMyClient().query({
        fetchPolicy: 'network-only',
        query: readTasksQuery,
        variables: variables
    });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readTasks : { edges: [] };
};

export const readTasksQuery = gql`
    ${TaskFragment}

    query readTasks(
        $userId: Int
        $contains: String
        $limit: Int!
        $offset: Int!
        $sortBy: [ReadTasksSortInputType]
        $filters: [TaskFilters]
        $isComplete: Boolean
        $isArchived: Boolean
        $isStarred: Boolean
    ) {
        readTasks(
            userId: $userId
            contains: $contains
            limit: $limit
            offset: $offset
            sortBy: $sortBy
            filters: $filters
            isComplete: $isComplete
            isArchived: $isArchived
            isStarred: $isStarred
        ) {
            edges {
                node {
                    ...TaskFragment
                }
            }
            pageInfo {
                totalCount
                hasNextPage
            }
        }
    }
`;

export const updateTaskFunc = async task => {
    let attachments = [];
    let members = [];
    let teams = [];
    const assignedLabels = [];
    const checklist = [];

    if (!!task.Attachments && task.Attachments.length > 0) {
        task.Attachments.forEach(function(attachment) {
            if (!!attachment.ID) {
                attachments.push({
                    ID: attachment.ID
                });
            }
        });
    }

    if (!!task.AssignedTeams && task.AssignedTeams.length > 0) {
        task.AssignedTeams.forEach(function(team) {
            if (!!team.ID) {
                teams.push({
                    ID: team.ID
                });
            }
        });
    }

    if (!!task.AssignedTeams && task.AssignedTeams.length > 0) {
        task.AssignedTeams.forEach(function(team) {
            if (!!team.ID) {
                teams.push({
                    ID: team.ID
                });
            }
        });
    }

    if (!!task.AssignedMembers && task.AssignedMembers.length > 0) {
        task.AssignedMembers.forEach(function(member) {
            if (!!member.ID) {
                members.push({
                    ID: member.ID,
                    Starred: (member.Join && member.Join.Starred) || null
                });
            }
        });
    }

    if (!!task.AssignedLabels && task.AssignedLabels.length > 0) {
        task.AssignedLabels.forEach(function(label) {
            if (!!label.ID) {
                assignedLabels.push({
                    ID: label.ID
                });
            }
        });
    }

    if (!!task.ChecklistItems && task.ChecklistItems.length > 0) {
        task.ChecklistItems.forEach(function(label) {
            if (!!label.ID) {
                checklist.push({
                    ID: label.ID,
                    Completed: label.Completed
                });
            } else {
                checklist.push({
                    Title: label.Title,
                    Completed: label.Completed
                });
            }
        });
    }

    return await getMyClient().mutate({
        mutation: updateTaskMutation,
        variables: {
            input: {
                ID: task.ID,
                InProgress: task.InProgress,
                Completed: task.Completed,
                Archived: task.Archived,
                Attachments: attachments,
                AssignedTeams: teams,
                AssignedMembers: members,
                AssignedLabels: assignedLabels,
                ChecklistItems: checklist,
                Comment: task.NewComment
            }
        }
    });
};

const updateTaskMutation = gql`
    ${TaskFragment}
    mutation UpdateTask($input: TaskUpdateInputType!) {
        updateTask(Input: $input) {
            ...TaskFragment
        }
    }
`;
