import gql from 'graphql-tag';

export default gql`
    fragment LegacyDocuments on Funeral {
        LegacyDocuments {
            ... on SilverStripeFile {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementLegacyDocuments on Prearrangement {
        LegacyDocuments {
            ... on SilverStripeFile {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;
