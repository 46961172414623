import { withStyles } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import Grid from '../form/Grid';
import BiggerMessageBar from '../BiggerMessageBar';
import { InlineHeader } from '../form/Inline';
import Checkbox from '../form/Checkbox';
import { AltButton } from '../form/PrimaryButton';
import PayableIcon from '../icon/PayableIcon';
import QuotesIcon from '../icon/QuotesIcon';
import { getClient } from '../../apollo';
import gql from 'graphql-tag';
import Spinner from '../Spinner';
import { isRelatedObjectDefined, MVC_ID } from '../../util/bookable';
import PurchaseOrderModal, {
    createBookingPurchaseOrderMutation,
    createNewBookingPurchaseOrderMutation
} from './PurchaseOrderModal';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo';
import PurchaseInvoiceModal from './PurchaseInvoiceModal';
import DisplayContact from './DisplayContact';
import { isNullOrUndefined } from '../../util/objects';
import { GST, LoadProducts } from '../../util/products';

const sendRequestCM = gql`
    mutation CreateCremationRequest($input: FuneralUpdateInputType!) {
        updateFuneral(Input: $input) {
            ID
            CremationOrder {
                ID
                CremationPending
            }
        }
    }
`;

export const SameDayCremationProductID = '30210';

class CremationRequest extends PureComponent {
    state = {
        loading: false,
        orderModalOpen: false,
        lastDefaultItemCode: null
    };

    fetchDefaultItemInfo() {
        const { defaultLineItemCode } = this.props;
        this.state.lastDefaultItemCode = defaultLineItemCode;

        LoadProducts(null, defaultLineItemCode).then(products => {
            const product = products.find(e => '' + e.internalId === '' + defaultLineItemCode);
            if (!product) {
                console.error('Failed to get default line item with code ', defaultLineItemCode);
                this.setState({
                    defaultLineItem: { ID: 0 },
                    invalidLineItem: true,
                    loading: false
                });
                return;
            }
            const cost = product.original.price;
            const lineItem = {
                UnitPrice: cost, //exGST
                Price: cost * (!!product.original.gst ? GST + 1 : 1), //incGST
                Quantity: 1,
                ProductID: product.id,
                InternalItemID: product.internalId,
                Title: product.title,
                GST: product.original.gst
            };

            this.setState({ defaultLineItem: lineItem, invalidLineItem: false, loading: false });
        });
    }

    componentDidMount() {
        const { defaultLineItemCode } = this.props;
        if (isNullOrUndefined(defaultLineItemCode)) return;

        this.setState({ loading: true });
        this.fetchDefaultItemInfo();
    }

    componentDidUpdate() {
        const { defaultLineItem, invalidLineItem } = this.state;
        const { defaultLineItemCode } = this.props;

        if (isNullOrUndefined(defaultLineItemCode)) {
            if (!isNullOrUndefined(defaultLineItem)) {
                this.state.defaultLineItem = null;
            }
            return;
        }
        if (
            !invalidLineItem &&
            (isNullOrUndefined(defaultLineItem) || defaultLineItem.InternalItemID !== defaultLineItemCode)
        ) {
            this.setState({ loading: true }, () => this.fetchDefaultItemInfo());
        }
    }

    sendMVCRequestMutation = async input => {
        const asyncQuery = await getClient().mutate({ mutation: sendRequestCM, variables: { input } });
        return asyncQuery.data;
    };

    sendMVC = () => {
        const { form } = this.props;
        const input = {
            ID: form.getField('ID'),
            CremationOrder: {
                CremationPending: true
            }
        };
        const me = this;
        me.setState({ loading: true });
        this.sendMVCRequestMutation(input).then(
            data => {
                if (data && data.updateFuneral) {
                    form.doRefetch(() => me.setState({ loading: false }));
                }
            },
            e => {
                console.error('error occurred saving CM request', e);
                me.props.setSnackbarMessage(
                    'Oops, there was an error - your changes have NOT saved.',
                    false,
                    null,
                    new Error(e)
                );
            }
        );
    };

    render() {
        const { form } = this.props;
        const { defaultLineItem, lastDefaultItemCode } = this.state;
        const sent = isRelatedObjectDefined(form.getField('CremationOrder'));
        const confirmed = form.getField('Disposal.Confirmed');
        const deliveryOnly = form.getField('PlaceOfService.Type') === 'No Service No Attendance';
        const disabled = form.isDirty || this.state.loading;
        const labelSupplier = 'Cremation';
        const items = form.getField('Disposal.Bookings') || [];
        const item = items[0] || { ID: null };
        if (!isNullOrUndefined(defaultLineItem)) {
            if (Array.isArray(item.BookingItems)) {
                const isInListAlready = item.BookingItems.some(bookedItem => {
                    return bookedItem.InternalItemID === defaultLineItem.InternalItemID;
                });
                if (!isInListAlready) {
                    item.BookingItems.push(defaultLineItem);
                }
            } else {
                item.BookingItems = [defaultLineItem];
            }
        } else if (!isNullOrUndefined(lastDefaultItemCode)) {
            if (Array.isArray(item.BookingItems)) {
                item.BookingItems = item.BookingItems.filter(element => {
                    return element.InternalItemID !== lastDefaultItemCode;
                });
            }
            this.state.lastDefaultItemCode = null;
        }
        return (
            <Grid item pc={1}>
                {deliveryOnly && <BiggerMessageBar>Cremation is Delivery only</BiggerMessageBar>}
                <DisplayContact contactID={MVC_ID} />
                {(!sent && (
                    <BiggerMessageBar messageType={'warning'}>
                        MVC will need to accept this booking. Please {form.isDirty && 'save and then'} press 'Send
                        Cremation Request' when you're ready.
                    </BiggerMessageBar>
                )) ||
                (!confirmed && (
                    <BiggerMessageBar>
                        Cremation request #{form.getField('CremationOrder.ID')} was sent to MVC. They should accept
                        this request before you create a bill.
                    </BiggerMessageBar>
                )) || (
                    <BiggerMessageBar messageType={'good'}>
                        The booking with MVC has been confirmed.
                    </BiggerMessageBar>
                )}
                <InlineHeader header={'Confirmation status:'}>
                    <Checkbox form={form} name={'Disposal.Confirmed'} label={'Booking Confirmed'} disabled />
                </InlineHeader>
                <div style={{ float: 'right' }}>
                    {(!sent && (
                        <AltButton
                            disabled={disabled}
                            onClick={e =>
                                window.confirm('Are you sure you want to request a cremation?') && this.sendMVC()
                            }
                        >
                            {this.state.loading ? <Spinner /> : <PayableIcon />}
                            <span>Send Cremation Request</span>
                        </AltButton>
                    )) ||
                    (!(item && isRelatedObjectDefined(item, 'PurchaseOrder')) && (
                        <Fragment>
                            <AltButton disabled={disabled} onClick={e => this.setState({ orderModalOpen: true })}>
                                <QuotesIcon />
                                <span>Create Bill...</span>
                            </AltButton>
                            <PurchaseOrderModal
                                canEdit={true}
                                canAdd={true}
                                open={this.state.orderModalOpen}
                                booking={item}
                                contact={form.getField('Disposal.Location')}
                                funeralID={form.getField('ID')}
                                eventObj={form.getField('Disposal')}
                                label={labelSupplier}
                                isOrder={false}
                                purchaseOrderField="PurchaseOrder"
                                mutation={item.ID ? createBookingPurchaseOrderMutation : createNewBookingPurchaseOrderMutation}
                                onClose={reload => {
                                    const me = this;
                                    if (reload && form.doRefetch)
                                        form.doRefetch(() => me.setState({ orderModalOpen: false }));
                                    else this.setState({ orderModalOpen: false });
                                }}
                            />
                        </Fragment>
                    )) || (
                        <PurchaseInvoiceModal
                            hasPurchaseOrder={item.PurchaseOrder}
                            labelSupplier={labelSupplier}
                            form={form}
                        />
                    )}
                </div>
            </Grid>
        );
    }
}

const styles = () => ({});

export default compose(withSnackbarMessage, withStyles(styles))(CremationRequest);
