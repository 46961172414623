import React, { Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/en-au';
import Agenda from 'irx-react-big-calendar/lib/Agenda';
import message from 'irx-react-big-calendar/lib/utils/messages';
import dates from 'irx-react-big-calendar/lib/utils/dates';
import { withStyles } from '@material-ui/core/styles/index';
import { isRelatedObjectDefined } from '../../util/bookable';
import Spinner from '../../component/Spinner';

class ViewLocations extends Agenda {
    state = {};

    inRange = (e, start, end) => {
        const eStart = moment(e.Start).startOf('day');
        const eEnd = moment(e.End).endOf('day');

        const startsBeforeEnd = eStart.isSameOrBefore(end, 'day');
        // when the event is zero duration we need to handle a bit differently
        const endsAfterStart = !moment(eStart).isSame(eEnd, 'minutes')
            ? eEnd.isAfter(start, 'minutes')
            : eEnd.isSameOrAfter(start, 'minutes');

        return startsBeforeEnd && endsAfterStart;
    };

    render() {
        const { date, length, min, max, locationEvents, localizer } = this.props;

        const messages = message(this.props.messages);
        const start = date;
        const end = dates.add(start, length, 'day');
        const range = dates.range(start, end, 'day');
        const slots = dates.range(min, max, 'hours');

        const events =
            (locationEvents &&
                locationEvents.length &&
                locationEvents.filter(event => !!event && this.inRange(event, start, end))) ||
            [];

        return (
            <div className="rbc-agenda-view">
                <table ref="header" className="rbc-agenda-table">
                    <thead>
                        <tr>
                            <th className="rbc-header" ref="dateCol">
                                <span style={{ fontWeight: 'bold' }}> {messages.date} / Location </span>
                            </th>
                            <th className="rbc-header" ref="timeCol" style={{ display: 'none' }} />
                            <th className="rbc-header">
                                <div style={{ position: 'relative' }}>
                                    {slots.map((s, i) => {
                                        return (
                                            <div
                                                key={i}
                                                style={{
                                                    left: 'calc(' + (100 / (slots.length - 0)) * i + '% - 4px)',
                                                    position: 'absolute'
                                                }}
                                            >
                                                {i !== slots.length - 0 ? moment(s).format('hA') : ''}
                                            </div>
                                        );
                                    })}
                                    &nbsp;
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>

                <div className="rbc-agenda-content" ref="content">
                    <table className="rbc-agenda-table">
                        <tbody ref="tbody">
                            {range.map((day, idx) => this.renderDay(day, events, idx, slots, localizer))}
                        </tbody>
                    </table>
                </div>

                {events.length > 0 ? (
                    ''
                ) : (
                    <div
                        className="message"
                        style={{ color: 'black', background: 'white', borderLeft: '1px solid #DDD', paddingLeft: 16 }}
                    >
                        {this.props.loading !== 7 ? (
                            <Fragment>
                                <p>
                                    <Spinner /> Loading data, please wait a moment...
                                </p>
                            </Fragment>
                        ) : (
                            <p>There are no events during this period.</p>
                        )}
                    </div>
                )}
            </div>
        );
    }

    renderDay = (day, events, dayKey, slots, localizer) => {
        if (!events.length) {
            return null;
        }
        const { components } = this.props;
        const EventComponent = components.event;
        const DateComponent = components.date;

        const groupedEvents = this.groupByArray(
            events.filter(e => this.inRange(e, day, day)),
            'LocationFlattened'
        );

        return groupedEvents.map((groupedEvents, idx) => {
            const dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat');

            const dateRow = () => {
                if (idx === 0)
                    return (
                        <tr style={{ backgroundColor: '#f8f9fb' }}>
                            <td className="rbc-agenda-date-cell">
                                {DateComponent ? <DateComponent day={day} label={dateLabel} /> : dateLabel}
                            </td>
                            <td />
                        </tr>
                    );
                return false;
            };
            const location = groupedEvents.values.length ? groupedEvents.values[0] : null;

            return (
                <Fragment key={dayKey + '_' + idx + 'dates'}>
                    {dateRow()}
                    <tr>
                        <td className="rbc-agenda-time-cell">
                            {location && isRelatedObjectDefined(location.Location) ? (
                                <Fragment>
                                    {location.Location.Name}
                                    <small>
                                        <br />
                                        {location.Location.Suburb}
                                    </small>
                                </Fragment>
                            ) : (
                                <Fragment>{groupedEvents.key && groupedEvents.key.replace(/,/, '\n')}</Fragment>
                            )}
                        </td>
                        {this.renderRowCells(day, groupedEvents.values, dayKey, slots, EventComponent)}
                    </tr>
                </Fragment>
            );
        }, []);
    };

    renderRowCells = (day, events, dayKey, slots, EventComponent) => {
        return (
            <td className="rbc-agenda-event-cell">
                <div style={{ position: 'relative' }}>
                    {slots.map((s, i) => {
                        if (i + 1 < slots.length)
                            return (
                                <span
                                    className="rbc-agenda-slot"
                                    key={i}
                                    style={{ width: 'calc(' + 100 / (slots.length - 1) + '%)' }}
                                />
                            );
                        return null;
                    })}
                    {events
                        .sort((a, b) => +a.Start - +b.Start)
                        .map((event, idx) => {
                            const title = event.ClassName;
                            const rMin = moment(slots[0]).format('H');
                            const rMax = moment(slots[slots.length - 1]).format('H');
                            const eStart = moment(event.Start).isSame(day, 'day')
                                ? 1 * moment(event.Start).format('H') + moment(event.Start).format('m') / 60
                                : 0;
                            const eEnd = moment(event.End).isSame(day, 'day')
                                ? 1 * moment(event.End).format('H') + moment(event.End).format('m') / 60
                                : 23;

                            const leftPos = ((Math.max(eStart, rMin) - rMin) / (rMax - rMin)) * 100;
                            const rightPos = ((Math.min(eEnd, rMax) - rMin) / (rMax - rMin)) * 100 - leftPos;

                            return (
                                <div
                                    key={'eventKey_' + idx}
                                    className="rbc-agenda-event"
                                    style={{ width: rightPos + '%', left: leftPos + '%' }}
                                >
                                    {EventComponent ? (
                                        <EventComponent event={event} title={title} view="Locations" />
                                    ) : (
                                        title
                                    )}
                                </div>
                            );
                        })}
                </div>
            </td>
        );
    };

    groupByArray(xs, key) {
        return xs.reduce(function(rv, x) {
            const v = key instanceof Function ? key(x) : x[key];
            const el = rv.find(r => r && r.key === v);
            if (el) {
                el.values.push(x);
            } else {
                rv.push({ key: v, values: [x] });
            }
            return rv;
        }, []);
    }
}

const styles = () => ({});

export default withStyles(styles)(ViewLocations);
