import React, { Fragment } from 'react';
import { CircularProgress } from '@material-ui/core';
import QuestionMarkCircleIcon from './QuestionMarkCircleIcon';

export function ProgressPieIcon({ value }) {
    return (
        <Fragment>
            <QuestionMarkCircleIcon style={{ position: 'absolute' }} />
            <CircularProgress
                value={value}
                variant="static"
                thickness={22}
                style={{ marginLeft: 5, opacity: Math.max(0.33, value / 100) }}
            />
        </Fragment>
    );
}
