import gql from 'graphql-tag';
import { getMyClient } from '../../../apollo';
import TaskFragment1 from './TaskFragment';
import { deleteTypeName } from '../../../util/objects';
import { sanitizeObjectWithFKs } from '../../../util/bookable';
import { diff } from '../../../util/functions';

/**
 * dynamically creates tasks query. because you cant optionally specify the bools complete/starred
 */
const createUserTasksQuery = (isComplete = null, isStarred = null, tabUrlSegments = null, funeralId = null) => {
    let optionalDefinitions1 = '';
    let optionalDefinitions2 = '';

    if (isComplete !== null) {
        optionalDefinitions1 += '$isComplete: Boolean!,';
        optionalDefinitions2 += 'isComplete: $isComplete,';
    }

    if (isStarred) {
        optionalDefinitions1 += '$isStarred: Boolean!,';
        optionalDefinitions2 += 'isStarred: $isStarred,';
    }

    if (tabUrlSegments && tabUrlSegments.length > 0) {
        optionalDefinitions1 += '$tabSegmentUrl: [String]!,';
        optionalDefinitions2 += 'tabSegmentUrl: $tabSegmentUrl,';
    }

    if (funeralId !== null) {
        optionalDefinitions1 += '$funeralId: Int!,';
        optionalDefinitions2 += 'funeralId: $funeralId,';
    }

    return gql`
        ${TaskFragment1}
        query readTasks($userId: Int!, ${optionalDefinitions1} $limit: Int!, $offset:Int!){
        readTasks(userId: $userId, ${optionalDefinitions2} limit: $limit, offset: $offset) {
        edges {
        node {
        ...TaskFragment1
        }
        }
        }
        }`;
};

const updateTaskMutation = gql`
    ${TaskFragment1}
    mutation UpdateTaskMutation($input: TaskUpdateInputType!) {
        updateTask(Input: $input) {
            ...TaskFragment1
        }
    }
`;

const createTaskMutation = gql`
    ${TaskFragment1}
    mutation CreateTaskMutation($input: TaskCreateInputType!) {
        createTask(Input: $input) {
            ...TaskFragment1
        }
    }
`;

//gets the users tasks from the database
const getUserTasksFunc = async (
    userId,
    isComplete = null,
    isStarred = null,
    offset = 0,
    limit = 1000,
    tabSegmentUrl = null,
    funeralId = null,
    refresh = false
) => {
    //todo: use refresh to force the query
    const asyncQuery = await getMyClient().query({
        fetchPolicy: 'network-only',
        query: createUserTasksQuery(isComplete, isStarred, tabSegmentUrl, funeralId),
        variables: {
            userId,
            isComplete,
            isStarred,
            offset,
            limit,
            tabSegmentUrl,
            funeralId
        }
    });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readTasks : { edges: [] };
};

//saves the task to the database
const createOrUpdateTaskFunc = async (task, original) => {
    let input = trimTask(task, original);
    let asyncQuery;

    if (original) {
        asyncQuery = await getMyClient().mutate({
            mutation: updateTaskMutation,
            variables: {
                input
            }
        });
    } else {
        asyncQuery = await getMyClient().mutate({
            mutation: createTaskMutation,
            variables: {
                input
            }
        });
    }

    return asyncQuery;
};

const trimTask = (update, original) => {
    let task = { ...update.node };

    task = sanitizeObjectWithFKs(task, ['Funeral', 'CremationOrder', 'Plaque', 'Master', 'EnquiryFM', 'EnquiryCM']);

    deleteTypeName(task);

    if (original) {
        task = diff(task, original);
    }

    //we cant send audits
    delete task.Audits;

    task.AssignedMembers = task.AssignedMembers.map(x => {
        return { ID: x.ID, Starred: x.Join.Starred || null };
    });
    if (task.ChecklistItems)
        task.ChecklistItems = task.ChecklistItems.map(x => {
            return { ID: x.ID };
        });

    return task;
};

export { createOrUpdateTaskFunc, getUserTasksFunc };
