import Billing from "./Billing";
import BillingFragment from "./BillingFragment";
import { isRelatedObjectUndefined } from "../../../util/graphql";
import { isEmailValid, messages, validationHelper } from "../../../util/validation";
import { isNullOrUndefined } from "../../../util/objects";
import { flattenBookableObjects, isRelatedObjectDefined } from "../../../util/bookable";
import { flattenBookingItems } from "../../../util/products";
import { flattenCateringStaffAllocations, flattenStaffAllocations } from "../../../util/functions";

export default {
    label: 'Billing',
    component: Billing,
    fragment: BillingFragment,
    onLoad: data => {
        if (data.Invoices) {
            data.Invoices.filter(invoice => isRelatedObjectUndefined(invoice.Customer)).forEach(invoice => {
                //adding missing customer objects to the invoices
                invoice.Customer = {};
            });
        }
        flattenBookableObjects(['Florists', 'PersonalisedTouches', 'MemorialStationary', 'Caterers'], data);

        if (data.Disposal) {
            flattenBookingItems(data.Disposal, 'DisposalBookingItems');
            data.Disposal.DisposalBookingItems = (data.Disposal.DisposalBookingItems || []).filter(x =>
                isRelatedObjectDefined(x)
            );
            flattenStaffAllocations(data.Disposal);
        }

        if (data.RefreshmentsVenue) {
            flattenStaffAllocations(data.RefreshmentsVenue);
            flattenCateringStaffAllocations(data.RefreshmentsVenue);
        }

        // create a stub to detect new billing notes on save!
        if (!!data.BillingNotes) {
            data.OldBillingNotes = '' + (data.BillingNotes || '');
        }
        data.BillingNotes = null;
    },
    formatSaveData: (saveData, state) => {
        if (typeof state.FollowUpCallMade !== 'undefined' && !state.FollowUpCallMade && state.FollowUpCallMadeDatetime) {
            saveData.FollowUpCallMadeDatetime = '';
        }
        delete saveData.Enquiry; // don't alter anything
        delete saveData.Invoices; // don't muck with these
    },
    validation: {
        required: ['LegacyKey'],
        suggested: [],
        onValidate: {
            'BillTo.Email': (newValue, persistedValue, hasValue, getField) => {
                if (!getField('BillTo.BillingSource')) return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'AddressBook') return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'Applicant') return validationHelper.ok();
                if (isNullOrUndefined(persistedValue)) return validationHelper.suggested();
                return isEmailValid(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.required(messages.invalidEmail);
            },
            'BillTo.FirstName': (newValue, persistedValue, hasValue, getField) => {
                if (!getField('BillTo.BillingSource')) return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'AddressBook') return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'Applicant') return validationHelper.ok();
                if (isNullOrUndefined(persistedValue)) return validationHelper.required();
                return validationHelper.ok();
            },
            'BillTo.Surname': (newValue, persistedValue, hasValue, getField) => {
                if (!getField('BillTo.BillingSource')) return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'AddressBook') return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'Applicant') return validationHelper.ok();
                if (isNullOrUndefined(persistedValue)) return validationHelper.required();
                return validationHelper.ok();
            },
            'BillTo.Suburb': (newValue, persistedValue, hasValue, getField) => {
                if (!getField('BillTo.BillingSource')) return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'AddressBook') return validationHelper.ok();
                if (getField('BillTo.BillingSource') === 'Applicant') return validationHelper.ok();
                if (isNullOrUndefined(persistedValue)) return validationHelper.required();
                if (isNullOrUndefined(getField('BillTo.AddressLine1'))) return validationHelper.required();
                return validationHelper.ok();
            },
            'BillTo.BillingId': (newValue, persistedValue, hasValue, getField) => {
                if (!getField('BillTo.BillingSource')) return validationHelper.ok();
                if (getField('BillTo.BillingSource') !== 'AddressBook') return validationHelper.ok();
                if (isNullOrUndefined(persistedValue)) return validationHelper.suggested();
                return validationHelper.ok();
            }
        }
    }
};
