import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import Table, { TableCell, TableRow } from '../../../component/form/Table';
import { getCheckoutList } from './CheckoutListConstants';
import { formatLocationDateTime, formatLocationName } from '../MortuaryListConstants';
import TickIcon from '../../../component/icon/TickIcon';
import Spinner from '../../../component/form/v2/Spinner';
import { joinDefined } from '../../../util/strings';
import { isNullOrUndefined } from '../../../util/objects';
import { FUNERAL_HOME } from '../../../util/funerals';
import { InlineField } from '../../../component/form/Inline';
import BrandedTableKey from '../../../component/BrandedTableKey';
import { isContactDefined } from '../../../util/bookable';
import { niceDateTimeFromString } from '../../../util/date';
import moment from 'moment';
import GatedComponent from '../../../component/GatedComponent';

class CheckoutList extends Component {
    state = {
        mortuary: null
    };

    componentDidMount() {
        getCheckoutList().then(mortuary => this.setState({ mortuary }));
    }

    render() {
        const { mortuary } = this.state;
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <span>Mortuary</span>
                    <span className={classes.header}>Checkout List</span>
                </Typography>
                <br />
                {isNullOrUndefined(mortuary) ? this.renderLoading() : this.renderBody()}
            </Fragment>
        );
    }

    renderLoading() {
        const { classes } = this.props;

        return (
            <Paper className={classes.paper} elevation={0}>
                <Spinner />
                &nbsp;<span>Loading...</span>
            </Paper>
        );
    }

    renderKey() {
        const { classes } = this.props;
        return (
            <InlineField>
                <Typography variant="body2" className={classes.keyTitle}>
                    Legend:
                </Typography>
                <BrandedTableKey />
            </InlineField>
        );
    }

    renderBody() {
        const { classes } = this.props;
        const { mortuary } = this.state;

        if (mortuary.error) return <div>failed to get checkout items: {mortuary.error}</div>;
        if (mortuary.items === null || mortuary.items.length === 0)
            return (
                <Paper className={classes.paper} elevation={0}>
                    <div>There are currently no checkout items.</div>
                </Paper>
            );

        return (
            <Paper className={classes.paper} elevation={0}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="title">
                            Checkout List - {mortuary.items.length} item{mortuary.items.length === 1 ? '' : 's'}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.wideScroll}>
                    <br />
                    {this.renderKey()}
                    <br />
                    <div>
                        <Grid container spacing={24}>
                            <GatedComponent
                                showComponentCode={'FM_ACCESS_Mortuary_View'}
                                isEnabledCode={'FM_ACCESS_Mortuary_Deceased_Checkout_View'}
                            >
                                {isEnabled => {
                                    return (
                                        <Grid item xs={12}>
                                            <Table
                                                className={classes.table}
                                                columns={[
                                                    'Key',
                                                    'Deceased',
                                                    'Type',
                                                    'Refereed',
                                                    'Date & Time of Viewing',
                                                    'Date & Time of Service',
                                                    'To be released to',
                                                    'Released for checkout on'
                                                ]}
                                            >
                                                {mortuary.items
                                                    .sort((a, b) => this.sortNearestEvent(a, b))
                                                    .map((mortuaryItem, index) =>
                                                        this.renderRow(mortuaryItem, index, isEnabled)
                                                    )}
                                            </Table>
                                        </Grid>
                                    );
                                }}
                            </GatedComponent>
                        </Grid>
                    </div>
                </div>
            </Paper>
        );
    }

    firstViewing = item => {
        let latestDate = moment().add(100, 'years');
        let viewing = false;
        if (!!item.PlaceOfViewingRequired) {
            item.PlaceOfViewingItems.forEach(e => {
                const theDate = moment(e.Date + ' ' + e.Time);
                if (moment(theDate).isValid() && theDate < latestDate) {
                    latestDate = theDate;
                    viewing = e;
                }
            });
        }
        return viewing;
    };

    firstEvent = item => {
        const serviceObj = item.PlaceOfService.Type === 'Graveside' ? item.Disposal : item.PlaceOfService;
        const service = moment(serviceObj.Date + ' ' + serviceObj.Time);
        let latestDate = service.isValid() ? service : moment().add(100, 'years');
        let viewing = service.isValid() ? serviceObj : false;
        const theViewing = this.firstViewing(item);
        if (!!theViewing) {
            const theDate = moment(theViewing.Date + ' ' + theViewing.Time);
            if (moment(theDate).isValid() && theDate < latestDate) {
                viewing = theViewing;
            }
        }
        return viewing;
    };

    sortNearestEvent = (itemA, itemB) => {
        const eventA = this.firstEvent(itemA);
        const eventB = this.firstEvent(itemB);
        const timeA = (!!eventA && moment(eventA.Date + ' ' + eventA.Time)) || moment().add(100, 'years');
        const timeB = (!!eventB && moment(eventB.Date + ' ' + eventB.Time)) || moment().add(100, 'years');
        return timeA > timeB ? 1 : -1;
    };

    renderRow(mortuaryItem, index, canEdit) {
        const { classes } = this.props;

        const letterCode = mortuaryItem.LegacyKey.slice(-1);
        const rowStyle = classes['rowColor' + letterCode];

        const isCremation = !!mortuaryItem.Disposal && mortuaryItem.Disposal.CrematedOrBuried === 'Cremated';
        const isGraveside =
            !!mortuaryItem.Disposal &&
            !!mortuaryItem.PlaceOfService &&
            mortuaryItem.PlaceOfService.Type === 'Graveside';
        const serviceObj = !!isGraveside ? mortuaryItem.Disposal : mortuaryItem.PlaceOfService;
        const needsReferee =
            !!isCremation &&
            !(!!mortuaryItem.Certification && mortuaryItem.Certification.TypeOfBdmDeathCertificate === 'Coroner');

        const disposalTitle = () => {
            const title = !!mortuaryItem.Disposal && mortuaryItem.Disposal.CrematedOrBuried;
            switch (title) {
                case 'Buried':
                    return 'Burial';
                case 'Cremated':
                    return 'Cremation';
                case 'Repatriated':
                    return 'Repatriation';
                case 'Body Not Recovered':
                    return 'No Body';
                default:
                    return 'Disposal Unknown';
            }
        };

        const firstViewing = this.firstViewing(mortuaryItem);
        const releaseTo = serviceObj => {
            const service = moment(serviceObj.Date + ' ' + serviceObj.Time);
            let latestDate = service.isValid() ? service : moment('2100-01-01');
            let viewing = serviceObj;
            const theViewing = firstViewing;
            if (!!theViewing) {
                const theDate = moment(theViewing.Date + ' ' + theViewing.Time);
                if (moment(theDate).isValid() && theDate < latestDate) {
                    viewing = theViewing;
                }
            }
            return viewing;
        };

        return (
            <TableRow key={index} className={rowStyle} onClick={() => this.viewMortuaryItem(mortuaryItem, canEdit)}>
                <TableCell>{mortuaryItem.LegacyKey}</TableCell>
                <TableCell>
                    {joinDefined([mortuaryItem.Surname, mortuaryItem.FirstName, mortuaryItem.MiddleName], ', ')}
                </TableCell>
                <TableCell>{disposalTitle()}</TableCell>
                <TableCell>
                    <span className={classes.iconContainer}>
                        {isCremation ? (
                            !!needsReferee ? (
                                !!isContactDefined(mortuaryItem.Certification.Referee) ? (
                                    !!mortuaryItem.RefereeCremationCheck ? (
                                        <TickIcon className={classes.icon} title={'Completed'} />
                                    ) : (
                                        'Assigned'
                                    )
                                ) : (
                                    'NOT ASSIGNED'
                                )
                            ) : (
                                'Not Required'
                            )
                        ) : (
                            '-'
                        )}
                    </span>
                </TableCell>
                <TableCell>{niceDateTimeFromString(formatLocationDateTime(firstViewing))}</TableCell>
                <TableCell>{niceDateTimeFromString(formatLocationDateTime(serviceObj))}</TableCell>
                <TableCell>{formatLocationName(releaseTo(serviceObj))}</TableCell>
                <TableCell>{niceDateTimeFromString(mortuaryItem.DatetimeReadyForMortuaryCheckout)}</TableCell>
            </TableRow>
        );
    }

    viewMortuaryItem({ ID, LegacyKey }, canEdit) {
        const { history } = this.props;

        if (canEdit) {
            history.push({
                pathname: `/mortuary/checkout/${LegacyKey}/${ID}`
            });
        }
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingLeft: 24,
        marginLeft: -12
    },
    tableData: {
        marginTop: '1rem'
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    dotList: {
        minHeight: 16,
        width: 16,
        display: 'inline-block',
        position: 'absolute',
        left: -18,
        margin: '0 -10px 0 0',
        padding: '0',
        listStyle: 'none',
        '& > :not(:first-child)': {
            marginTop: 2
        }
    },
    dot: {
        height: 16,
        width: 16,
        borderRadius: 16
    },
    icon: {
        fontSize: 16,
        // color: '#26CC6F',
        height: '30px',
        width: '30px'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        '& tbody tr:hover': {
            cursor: 'pointer',
            outline: '3px solid black'
        },
        '& tbody td': {
            padding: 8
        }
    },

    ['rowColor' + [FUNERAL_HOME.RANKINS.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.RANKINS.letterCode] + '!important',
        '& svg': { color: palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] },
        '&:hover': { outlineColor: palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] + '!important' }
    },

    ['rowColor' + [FUNERAL_HOME.STAN_CRAP.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.STAN_CRAP.letterCode] + '!important',
        '& svg': { color: palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] },
        '&:hover': { outlineColor: palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] + '!important' }
    },

    ['rowColor' + [FUNERAL_HOME.WOLLONGONG_CITY.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] + '!important',
        '& svg': { color: palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] },
        '&:hover': {
            outlineColor: palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.EASY_FUNERALS.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode] + '!important',
        '& svg': { color: palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode] },
        '&:hover': {
            outlineColor: palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.PARSONS_LADIES.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.PARSONS_LADIES.letterCode] + '!important',
        '& svg': { color: palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] },
        '&:hover': {
            outlineColor: palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.H_PARSONS.letterCode]]: {
        color: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode],
        backgroundColor: palette.contentBackground[FUNERAL_HOME.H_PARSONS.letterCode] + '!important',
        '& svg': { color: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] },
        '&:hover': { outlineColor: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] + '!important' }
    }
});

export default compose(withSnackbarMessage, withStyles(styles))(CheckoutList);
