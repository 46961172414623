import gql from 'graphql-tag';

export default gql`
    fragment EnquiryOrPrearrangment on EnquiryOrPrearrangment {
        ID
        Created
        ClassName
        LastEdited
        LegacyKey
        EnquiryType
        Notes
        EnquirerTitle
        EnquirerGivenName
        EnquirerMiddleName
        EnquirerSurname
        EnquirerEmail
        EnquirerPhone
        EnquirerMobile
        RelationshipToDeceased
        EnquirerPostalAddress1
        EnquirerPostalAddress2
        EnquirerPostalSuburb
        EnquirerPostalState
        EnquirerPostalPostcode
        GivenName
        MiddleName
        Surname
        Gender
        DateOfBirth
        Situation
        DateOfDeathType
        DateOfDeath1
        DateOfDeath2
        ResidentialAddress1
        ResidentialAddress2
        ResidentialSuburb
        ResidentialState
        ResidentialCountry
        ResidentialPostcode
        PostalUseResidentialAddress
        DeathUseResidentialAddress
        TransferUsePlaceOfDeathAddress
        PlaceOfDeathType
        TransferFromType
        UnknownDOB
        PlaceOfDeath {
            ID
            Name
        }
        TransferFrom {
            ID
            Name
        }
        EnquirerResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        PlaceOfDeathResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        TransferFromResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        Office {
            ID
            RegistrantSuffix
        }
        Funeral {
            ID
            FirstName
            Surname
            LegacyKey
        }
        Prearrangement {
            ID
            LegacyKey
            LegacyDocuments {
                ... on SilverStripeFile {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on SilverStripeImage {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
        }
    }
`;

export const PrefillFromEnquiryFragment = gql`
    fragment PrefillFromEnquiryFragment on Enquiry {
        ID
        Created
        ClassName
        LastEdited
        LegacyKey
        EnquiryType
        Notes
        EnquirerTitle
        EnquirerGivenName
        EnquirerMiddleName
        EnquirerSurname
        EnquirerEmail
        EnquirerPhone
        EnquirerMobile
        RelationshipToDeceased
        EnquirerPostalAddress1
        EnquirerPostalAddress2
        EnquirerPostalSuburb
        EnquirerPostalState
        EnquirerPostalPostcode
        GivenName
        MiddleName
        Surname
        Gender
        DateOfBirth
        Situation
        DateOfDeathType
        DateOfDeath1
        DateOfDeath2
        ResidentialAddress1
        ResidentialAddress2
        ResidentialSuburb
        ResidentialState
        ResidentialCountry
        ResidentialPostcode
        UnknownDOB
        Office {
            ID
            RegistrantSuffix
        }
        Funeral {
            ID
            FirstName
            Surname
            LegacyKey
        }
        Prearrangement {
            ID
            LegacyKey
            LegacyDocuments {
                ... on SilverStripeFile {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on SilverStripeImage {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
        }
        LegacyDocuments {
            ... on SilverStripeFile {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;

export const PrefillFromPrearrangementFragment = gql`
    fragment PrefillFromPrearrangementFragment on Prearrangement {
        ID
        Created
        ClassName
        LastEdited
        LegacyKey
        BillingNotes
        FirstName
        MiddleName
        Surname
        Gender
        DateOfBirth
        ResidentialAddress1
        ResidentialAddress2
        ResidentialTown
        ResidentialState
        ResidentialCountry
        PostCode
        UnknownDOB
        Office {
            ID
            RegistrantSuffix
        }
        Quote {
            ID
            Enquiry {
                ID
                LegacyKey
                Funeral {
                    ID
                    LegacyKey
                }
                LegacyDocuments {
                    ... on SilverStripeFile {
                        ID
                        Name
                        Size
                        AbsoluteLink
                    }
                    ... on SilverStripeImage {
                        ID
                        Name
                        Size
                        AbsoluteLink
                    }
                }
            }
        }
        LegacyDocuments {
            ... on SilverStripeFile {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;
