import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MapMarkerIcon from '../icon/MapMarkerIcon';
import PhoneSquareIcon from '../icon/PhoneSquareIcon';
import MobileIcon from '../icon/MobileIcon';
import EnvelopeSquareIcon from '../icon/EnvelopeSquareIcon';
import FaxIcon from '../icon/FaxIcon';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Spinner from '../Spinner';
import { CONTACT_CATEGORY_OPTIONS, CONTACT_CLERGY_OPTIONS } from '../../page/contacts/contactConstants';
import { prettyEmail, prettyPhone } from '../../util/strings';
import Grid from '../form/Grid';
import ContactCharges from '../../page/contacts/ContactCharges';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ContactAddNote from '../../page/contacts/ContactAddNote';
import ContactView from '../../page/contacts/ContactView';
import { OutlineButton } from '../form/PrimaryButton';
import UsdCircleIcon from '../icon/UsdCircleIcon';
import { getUtilitiesClient } from '../../apollo';

class DisplayContact extends Component {
    state = {};
    displayContact = (contact, refetch) => {
        const { classes, className } = this.props;
        return (
            <div className={cx(classes.cardItem, className)}>
                <Typography component={'h5'} style={{ fontWeight: 'bold' }}>
                    {contact.Name || `Contact #${contact.ID}`}
                    {!!contact.NotCurrent && <span className={classes.notCurrent}>NO LONGER AVAILABLE</span>}
                </Typography>
                <Typography component={'h6'} style={{ marginBottom: '1rem' }}>
                    {this.renderSubtitle(contact)}
                </Typography>

                <div className={cx(classes.contactDetails)} style={!!contact.NotCurrent ? { opacity: 0.5 } : null}>
                    {this.renderAddress(contact)}
                    {this.renderDetails(contact)}
                </div>

                <div className={classes.footerButtons}>{this.renderActionButtonsRightBottom(contact, refetch)}</div>
            </div>
        );
    };

    renderName = ({ loading, error, data, refetch }) => {
        const contact =
            (data &&
                data.readAddressBooks &&
                data.readAddressBooks.edges &&
                data.readAddressBooks.edges.length > 0 &&
                data.readAddressBooks.edges[0].node) ||
            {};
        if (!!loading || !!error) {
            return (
                <Fragment>
                    <div style={{ float: 'left' }}>
                        <Spinner />
                    </div>
                    <div style={{ paddingLeft: '1.25rem', fontSize: '75%', opacity: 0.5 }}>
                        {error ? error.toString() : 'loading...'}
                    </div>
                </Fragment>
            );
        }
        if (!(!!contact && !!contact.ID && contact.ID > 0)) {
            return null;
        }
        return this.displayContact(contact, refetch);
    };

    renderSubtitle(contact) {
        const { classes } = this.props;
        const hasContact = contact.Contact && contact.Contact.length > 0;
        const cat = CONTACT_CATEGORY_OPTIONS.find(e => {
            return e.value === contact.AddressBookCategory;
        });
        const clg = CONTACT_CLERGY_OPTIONS.find(e => {
            return e.value === contact.ClergyCategory;
        });
        return (
            <div>
                <Typography className={classes.name} component="span">
                    {hasContact && <span className={classes.name}>{contact.Contact}</span>}
                    {contact.AddressBookCategory && (
                        <Typography className={classes.category} component="span">
                            {cat ? cat.label : contact.AddressBookCategory}
                        </Typography>
                    )}
                    {contact.ClergyCategory && (
                        <Typography className={classes.category} component="span">
                            {clg ? clg.label : contact.ClergyCategory}
                            {clg && (Number(clg.value) === 9 || Number(clg.value) === 11) && contact.Religion && (
                                <span> - {contact.Religion}</span>
                            )}
                        </Typography>
                    )}
                </Typography>
            </div>
        );
    }

    renderAddress(contact) {
        if (!(contact.State || contact.Suburb || contact.Postcode)) return;

        const { classes } = this.props;

        const addressData = [];
        if (contact.Address) addressData.push(contact.Address);
        else {
            if (contact.AddressLine1) addressData.push(contact.AddressLine1);
            if (contact.AddressLine2) addressData.push(contact.AddressLine2);
        }
        if (contact.State) addressData.push(contact.State);
        if (contact.Suburb) addressData.push(contact.Suburb);
        if (contact.Postcode) addressData.push(contact.Postcode);

        return (
            <Typography className={classes.textWithIcon} component="p">
                <MapMarkerIcon className={classes.iconLight} title={'Address'} />
                {addressData.join(', ')}
            </Typography>
        );
    }

    renderDetails(contact) {
        const { classes } = this.props;

        return (
            <Fragment>
                <Grid container>
                    {contact.Phone && (
                        <Grid item xs={12} sm={4} md={6} lg={4}>
                            <Typography className={classes.textWithIcon} component="p">
                                <PhoneSquareIcon className={classes.iconLight} title={'Phone'} />
                                <small>{prettyPhone(contact.Phone)}</small>
                            </Typography>
                        </Grid>
                    )}
                    {contact.Email && (
                        <Grid item xs={12} sm={8} md={12} lg={8}>
                            <Typography className={classes.textWithIcon} component="p">
                                <EnvelopeSquareIcon className={classes.iconLight} title={'Email'} />
                                <small>{prettyEmail(contact.Email)}</small>
                            </Typography>
                        </Grid>
                    )}
                    {contact.Mobile && (
                        <Grid item xs={12} sm={4} md={6} lg={4}>
                            <Typography className={classes.textWithIcon} component="p">
                                <MobileIcon className={classes.iconLight} title={'Mobile'} style={{ marginLeft: 6 }} />
                                <small>{prettyPhone(contact.Mobile)}</small>
                            </Typography>
                        </Grid>
                    )}
                    {contact.Fax && (
                        <Grid item xs={12} sm={4} md={6} lg={4}>
                            <Typography className={classes.textWithIcon} component="p">
                                <FaxIcon className={classes.iconLight} title={'Fax'} />
                                <small>{prettyPhone(contact.Fax)}</small>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderActionButtonsRightBottom(contact, refetch) {
        const { classes, disabled } = this.props;

        return (
            <Typography className={classes.actionRightBottom} component="p">
                {contact.ClergyBaseRate > 0 && (
                    <ContactCharges contactId={contact.ID}>
                        <IconButton
                            className={contact.ClergyBaseRate > 0 ? classes.buttonInvoice : classes.buttonNoInvoice}
                            aria-label="Charges"
                            title="Charges"
                        >
                            <UsdCircleIcon />
                        </IconButton>
                    </ContactCharges>
                )}
                <ContactAddNote contactId={contact.ID} contactNotes={contact.Notes} onClose={refetch} />
                <ContactView disabled={disabled} contactId={contact.ID}>
                    <OutlineButton title={'View All'}>Details...</OutlineButton>
                </ContactView>
            </Typography>
        );
    }

    render() {
        const { contactItem, contactKey, contactID } = this.props;
        const { contact } = this.state;

        if (contactItem && contactItem.ID && contactItem.ID > 0) return this.displayContact(contactItem);

        if (!contact || contact.ID !== contactID)
            return (
                <Query query={nameQuery} client={getUtilitiesClient()} variables={{ contactKey, contactID }}>
                    {this.renderName}
                </Query>
            );
        return this.displayContact(contact);
    }
}

const nameQuery = gql`
    query ContactLookup($contactKey: String, $contactID: String) {
        readAddressBooks(key: $contactKey, id: $contactID, limit: 1) {
            edges {
                node {
                    ID
                    LegacyKey
                    Name
                    Contact
                    Email
                    Phone
                    Fax
                    Mobile
                    State
                    Suburb
                    Postcode
                    AddressBookCategory
                    AddressLine1
                    AddressLine2
                    Notes
                    ClergyBaseRate
                    ClergyCategory
                    Religion
                    NotCurrent
                }
            }
        }
    }
`;

const styles = ({ spacing, palette }) => ({
    cardItem: {
        width: '100%',
        padding: '0.75rem',
        margin: 0,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: 5,
        position: 'relative'
    },
    buttonInvoice: {},
    buttonNoInvoice: {
        opacity: 0.5
    },
    buttonNote: {},
    buttonNoNote: {
        opacity: 0.5
    },
    buttonViewAll: {
        marginRight: '10px',
        boxShadow: 'none',
        borderRadius: '18px'
    },
    iconLight: {
        color: palette.custom.lightishGrey,
        marginRight: '10px'
    },
    textWithIcon: {
        position: 'relative',
        minHeight: 24,
        lineHeight: '24px',
        margin: [5, 0, 10, 30].join('px ') + 'px',
        '& svg': {
            position: 'absolute',
            left: -30
        }
    },
    name: {
        margin: '5px 0',
        fontSize: '0.875rem',
        color: palette.highlight.blue,
        // textTransform: 'capitalize',
        '& > span': {
            marginRight: 24
        }
    },
    companyName: {
        lineHeight: '1.35'
        // maxWidth: 'calc(100% - 68px)'
    },
    category: {
        color: palette.tag.foreground,
        backgroundColor: palette.tag.background,
        borderRadius: 5,
        display: 'inline-block',
        // textTransform: 'capitalize',
        padding: '0.125rem 0.5rem',
        margin: '0.05rem 5px 0 0 !important',
        fontSize: '0.725rem'
    },
    contactDetails: {
        // minHeight: 110,
        overflowX: 'hidden',
        marginBottom: 32
    },
    notCurrent: {
        background: 'red',
        color: 'white',
        padding: '2px 5px',
        position: 'absolute',
        right: spacing.unit
    },
    footerButtons: {
        position: 'absolute',
        bottom: 4,
        right: 12 // has a border
    }
});

export default withStyles(styles)(DisplayContact);
