import gql from 'graphql-tag';

export default gql`
    fragment AdminSummary on Funeral {
        ID
        AdminChecks {
            ID
            Title
            TabURLSegment
            OptionsFlag
            Active
            Join {
                ID
                Task {
                    ID
                    Title
                    Description
                    Comment
                    Completed
                    AssignedMembers {
                        ID
                        FirstName
                        Surname
                    }
                }
                Checked
                MortuaryMember {
                    ID
                    FirstName
                    Surname
                }
                Member {
                    ID
                    FirstName
                    Surname
                }
            }
        }
        AdminChecksComplete
        Coordinators {
            ID
            Allocation
            Member {
                ID
                Surname
                FirstName
                Email
            }
        }
    }
`;
