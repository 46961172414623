import gql from 'graphql-tag';

export default gql`
    fragment CommunicationNote on CommunicationNote {
        ID
        Created
        LastEdited
        Title
        Description
        EmailSent
        ScheduledToSendEmail
        SendEmail
        EmailApplicant
        EmailAuthorised
        EmailOther
        OtherEmailAddress
        SendMail
        MailApplicant
        MailAuthorised
        MailOther
        OtherMailAddress
        MailDate
        MailTime

        CreatedBy {
            ID
            Email
            FirstName
            Surname
        }

        Funeral {
            ID
        }

        Enquiry {
            ID
        }

        Attachments {
            ... on SilverStripeFile {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
            }
        }

        Template {
            ID
            Title
        }
    }
`;
