import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import LeftLabel from '../extras/LeftLabel';
import TextField from '../../../component/form/TextField';
import Checkbox from '../../../component/form/Checkbox';
import Select from '../../../component/form/Select';
import StaffAutoComplete from '../../../component/form/StaffAutoComplete';
import RadioGroup from '../../../component/form/RadioGroup';
import Grid from '../../../component/form/Grid';
import UserCircle from '../../../component/form/UserCircle';
import { TASK_CATEGORY_OPTIONS, taskURLObj, TeamDropdownField } from '../TaskConstants';
import { deleteTypeName } from '../../../util/objects';
import FuneralAutoComplete from '../../../component/form/FuneralAutoComplete';
import EnquiryAutoComplete from '../extras/EnquiryAutoComplete';
import ValidationPlaceholder from '../../../component/form/ValidationPlaceholder';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { InlineField } from '../../../component/form/Inline';

class TaskModalDetail extends Component {
    constructor(props) {
        super(props);
        if (props.createNew === true) {
            this.state.readOnly = false;
        }
    }

    state = {
        readOnly: true,
        recurWas: 1,
        recurUntil: 'never',
        recurCount: 0,
        isRecurring: false,
        addingMembers: false
    };

    componentDidMount() {
        const { form } = this.props;
        const task = form.state;
        if (!!form && !!task && task.RecurFreq) {
            this.setState({
                recurWas: task.RecurFreq,
                isRecurring: true
            });
        }
        const due = form.getField('Due');
        if (due)
            this.setState({
                dueDate: moment(due).format('Y-MM-DD'),
                dueTime: moment(due).format('HH:mm:ss')
            });
        this.setLinkURL();
        this.presetCategory();
        this.recalculateRecur(form);
    }

    render() {
        const { form } = this.props;
        const { recurWas, recurUntil, recurCount, isRecurring, dueDate, dueTime } = this.state;
        const assignedMembers = form.getField('AssignedMembers');
        const freq = form.getField('RecurFreq');
        const type = form.getField('RecurType');
        const category = form.getField('Category');
        const linkObj = taskURLObj(form.state);

        if (category !== 'Cremation' && !form.getField('Cremation.ID')) {
            delete TASK_CATEGORY_OPTIONS[2];
        }
        if (category !== 'Plaque' && !form.getField('Plaque.ID')) {
            delete TASK_CATEGORY_OPTIONS[3];
        }
        if (category !== 'EnquiryCM' && !form.getField('EnquiryCM.ID')) {
            delete TASK_CATEGORY_OPTIONS[4];
        }

        return (
            <Grid item pc>
                <LeftLabel label="Title:">
                    <TextField placeholder="Type task title" name="Title" form={form} />
                </LeftLabel>

                <LeftLabel label="Due Date:">
                    <ValidationPlaceholder name="Due" form={form}>
                        <InlineField>
                            <TextField
                                type="date"
                                name="dueDate"
                                value={dueDate}
                                onChange={this.handleDateTimeChange}
                            />
                            <TextField
                                type="time"
                                name="dueTime"
                                value={dueTime}
                                onChange={this.handleDateTimeChange}
                            />
                        </InlineField>
                    </ValidationPlaceholder>
                </LeftLabel>

                <LeftLabel label="">
                    <Checkbox
                        label="This task is recurring"
                        name="IsRecurring"
                        checked={isRecurring}
                        value="1"
                        onClick={e => {
                            this.setState({
                                recurWas: freq ? freq : recurWas,
                                isRecurring: e.target.checked
                            });

                            form.setField({
                                RecurFreq: e.target.checked ? recurWas : 0
                            });
                        }}
                    />
                </LeftLabel>

                {!!isRecurring && (
                    <Fragment>
                        <LeftLabel label="Repeats every:">
                            <div className="multi-fields">
                                <div className="small">
                                    <TextField
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        name="RecurFreq"
                                        form={form}
                                        onChange={this.recalculateRecur}
                                    />
                                </div>
                                <div className="large">
                                    <Select
                                        placeholder="Select when.."
                                        form={form}
                                        name="RecurType"
                                        onChange={this.recalculateRecur}
                                        options={[
                                            { value: 'year', label: 'Years' },
                                            { value: 'month', label: 'Months' },
                                            {
                                                value: 'fortnight',
                                                label: 'Fortnights'
                                            },
                                            { value: 'week', label: 'Weeks' },
                                            { value: 'day', label: 'Days' }
                                        ]}
                                    />
                                </div>
                            </div>
                        </LeftLabel>
                        <LeftLabel>
                            <RadioGroup
                                label="Ends:"
                                name="TaskFrequency"
                                options={[
                                    { label: 'Never', value: 'never' },
                                    { label: 'After Date', value: 'date' },
                                    {
                                        label: 'Number of occurrences',
                                        value: 'number'
                                    }
                                ]}
                                value={recurUntil}
                                onClick={e =>
                                    this.setState({
                                        recurUntil: e.target.value
                                    })
                                }
                            />

                            {recurUntil !== 'never' && (
                                <div className="multi-fields">
                                    <div className="small">
                                        {recurUntil === 'date' && (
                                            <TextField
                                                placeholder="Enter Date"
                                                type="date"
                                                form={form}
                                                inputProps={{
                                                    min: moment(form.getField('Created')).format('YYYY-MM-DD')
                                                }}
                                                name="RecurEnds"
                                                onChange={this.recalculateRecur}
                                            />
                                        )}

                                        {recurUntil === 'number' && (
                                            <TextField
                                                placeholder="Enter number of occurrence"
                                                type="number"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                value={recurCount}
                                                onChange={e => {
                                                    this.setState({
                                                        recurCount: e.target.value
                                                    });
                                                    const newDate = moment().add(freq, type);
                                                    form.setField({
                                                        RecurEnds: newDate.format('YYYY-MM-DD')
                                                    });
                                                }}
                                                onBlur={e => {
                                                    this.recalculateRecur();
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </LeftLabel>
                    </Fragment>
                )}

                <LeftLabel label="Category:">
                    <RadioGroup
                        name="Category"
                        value={category || ''}
                        placeholder="Select a category..."
                        options={TASK_CATEGORY_OPTIONS.map(e => {
                            return { label: e.labelOne, value: e.value || '' };
                        })}
                        form={form}
                        className="spacing"
                    />
                    <div style={{ display: form.getField('Category') === 'Funeral' ? null : 'none' }}>
                        <ValidationPlaceholder validationResult={form.getValidation('Funeral', true)}>
                            <FuneralAutoComplete
                                value={
                                    !!form.state && isRelatedObjectDefined(form.state.Funeral)
                                        ? form.state.Funeral
                                        : null
                                }
                                selectProps={{ multiple: false }}
                                placeholder="Search for a funeral..."
                                onSelect={(_, funeral) => this.handleSelectRelatedObject('Funeral', funeral)}
                                name="Funeral"
                            />
                        </ValidationPlaceholder>
                    </div>
                    <div style={{ display: form.getField('Category') === 'EnquiryFM' ? null : 'none' }}>
                        <ValidationPlaceholder validationResult={form.getValidation('EnquiryFM', true)}>
                            <EnquiryAutoComplete
                                selectProps={{ multiple: false }}
                                placeholder="Search for a enquiry..."
                                onSelect={(_, enquiry) => this.handleSelectRelatedObject('EnquiryFM', enquiry)}
                                form={form}
                                name="EnquiryFM"
                            />
                        </ValidationPlaceholder>
                    </div>
                </LeftLabel>

                <LeftLabel label="Assign Team:">{TeamDropdownField(form, this.onSelectTeam)}</LeftLabel>

                <LeftLabel label="Assigned To:">
                    {
                        /*addingMembers &&*/
                        <StaffAutoComplete
                            onSelect={(_, result) => this.onSelectStaffMember(result)}
                            placeholder="Search for a staff member"
                        />
                    }
                    {assignedMembers && !!assignedMembers.length && (
                        <div className="user-container">
                            {assignedMembers.map(member => (
                                <UserCircle
                                    key={'user' + member.ID}
                                    person={member}
                                    onDelete={() => this.onRemoveStaffMember(member)}
                                />
                            ))}
                        </div>
                    )}
                    {/*<span>
						<IconButton
							onClick={() => this.setState({addingMembers: true})}
						>
							<AddIcon />
						</IconButton>
					</span>*/}
                </LeftLabel>

                <LeftLabel label="Description:">
                    <TextField placeholder="Type description here" multiline name="Description" form={form} />
                </LeftLabel>
                <LeftLabel label="Refers to Link:">
                    {this.getRelatedRecord() && !!linkObj ? (
                        <a href={linkObj.link} title="Do Task">
                            {linkObj.title}
                        </a>
                    ) : (
                        <TextField
                            placeholder="Paste link here"
                            name="LinkURL"
                            form={form}
                            readOnly={!!this.getRelatedRecord()}
                        />
                    )}
                </LeftLabel>
            </Grid>
        );
    }

    handleDateTimeChange = event => {
        const { form } = this.props;

        if (!event.target.value && event.target.name === 'dueDate') {
            // reset if date empty
            this.setState({ dueDate: null, dueTime: null }, () => form.setField({ Due: null }));
        } else {
            this.setState({ [event.target.name]: event.target.value }, () => {
                form.setField({ Due: this.state.dueDate + ' ' + this.state.dueTime });
            });
        }
    };

    handleSelectRelatedObject(propertyName, object) {
        const { form } = this.props;

        if (!!object) {
            form.setState({ [propertyName]: object });
            this.setLinkURL();
        }
    }

    setLinkURL() {
        const { form } = this.props;
        const linkObj = taskURLObj(form.state);
        if (!!linkObj) {
            form.setField({ LinkURL: linkObj.link });
        }
    }

    presetCategory() {
        const { form } = this.props;
        const category = form.getField('Category');
        const newCategory = category
            ? category
            : form.getField('Funeral.ID')
            ? 'Funeral'
            : form.getField('Cremation.ID')
            ? 'Cremation'
            : form.getField('Plaque.ID')
            ? 'Plaque'
            : form.getField('EnquiryCM.ID')
            ? 'EnquiryCM'
            : form.getField('EnquiryFM.ID')
            ? 'EnquiryFM'
            : null;
        form.setField({ Category: newCategory }, true);
    }

    getRelatedRecord() {
        const { form } = this.props;
        const category = form.getField('Category');
        return category === 'Funeral'
            ? form.getField('Funeral')
            : category === 'Cremation'
            ? form.getField('Cremation')
            : category === 'Plaque'
            ? form.getField('Plaque')
            : category === 'EnquiryCM'
            ? form.getField('EnquiryCM')
            : category === 'EnquiryFM'
            ? form.getField('EnquiryFM')
            : null;
    }

    recalculateRecur = () => {
        const { form } = this.props;
        const created = form.getField('Created');
        const freq = form.getField('RecurFreq');
        const type = form.getField('RecurType');
        const ends = form.getField('RecurEnds');
        const isRecurring = form.getField('IsRecurring');

        if (!!isRecurring && (!freq || freq < 0)) {
            form.setField({
                RecurFreq: 0
            });
        }

        if (freq)
            this.setState({
                recurUntil: ends ? 'date' : 'never',
                recurCount: ends
                    ? Math.floor(
                          -moment(created)
                              .startOf('day')
                              .diff(ends, type) / freq
                      ) + 1
                    : 0
            });
    };

    onSelectTeam(e, form, groups) {
        form.setField({
            AssignedTeams: groups.filter(group => e.target.value === group.ID)
        });
    }

    onSelectStaffMember(member) {
        const { form } = this.props;
        const AssignedMembers = form.getField('AssignedMembers');

        const cloned = deleteTypeName(cloneDeep(member));
        AssignedMembers.push(cloned);

        this.setState({ addingMembers: false });
        form.setField({ AssignedMembers });
    }

    onRemoveStaffMember(member) {
        const { form } = this.props;
        let AssignedMembers = form.getField('AssignedMembers');

        AssignedMembers = AssignedMembers.filter(obj => member.ID !== obj.ID);

        form.setField({ AssignedMembers });
    }
}

export default withStyles({})(TaskModalDetail);
