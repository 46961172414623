import React, { Component, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { FUNERAL_HOME, FUNERAL_HOMES, getFuneralHomeByKey } from "../../util/funerals";
import { AltButton } from "../../component/form/PrimaryButton";
import Grid from "../../component/form/Grid";
import TextField from "../../component/form/TextField";
import Paper from "@material-ui/core/Paper/Paper";
import Table, {
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    TableSecondaryHeaderRow
} from "../../component/form/Table";
import Query from "react-apollo/Query";
import Spinner from "../../component/Spinner";
import ReactToPrint from "react-to-print";
import { flattenConnection } from "../../util/functions";
import { isNullOrUndefined } from "../../util/objects";
import cloneDeep from "lodash.clonedeep";
import { formStyles } from "../mortuary/Common/FormFields";
import moment from "moment";
import { renderRunSheetDetails } from "./DailyRunSheetConstants";
import { joinDefined, prettyPhone, stringIsNullOrEmpty } from "../../util/strings";
import { LinearProgress } from "@material-ui/core";
import PrintIcon from "../../component/icon/PrintIcon";
import { InlineField } from "../../component/form/Inline";
import LinkButton from "../../component/form/LinkButton";
import { withSnackbarMessage } from "../../context/SnackbarMessage";
import { compose } from "react-apollo";
import { DailyRunSheetFragment } from "./DailyRunSheetFragment2";
import { isRelatedObjectUndefined } from "../../util/graphql";
import { isContactDefined } from "../../util/bookable";
import Hidden from "@material-ui/core/Hidden";

class DailyRunSheet extends Component {
    constructor(props) {
        super(props);

        const saved = JSON.parse(sessionStorage.getItem('runsheet')) || {};
        const rawDate = props.match.params.date || saved.date || moment().format('YYYYMMDD');

        const date = this.validDate(rawDate) ? moment(rawDate) : moment();

        this.state = {
            dateFrom: date.format('YYYY-MM-DD'),
            dateTo: date.format('YYYY-MM-DD'),
            renderDateFrom: date.format('YYYY-MM-DD'),
            renderDateTo: date.format('YYYY-MM-DD')
        };
    }

    // only allow run sheet to use dates from 21st century and up to two years in the future.
    validDate = rawDate => {
        const date = moment(rawDate);
        return !(!date.isValid() || date.isBefore('2001-01-01') || date.isAfter(moment().add(2, 'year')));
    };

    dateChange = e => {
        const { dateFrom, dateTo } = this.state;
        const diff = moment(dateTo).diff(dateFrom, 'days');
        // enforce a maximum of 7 days in the runsheet.
        const newDates = { dateFrom, dateTo };

        if (e.target.name === 'dateFrom') {
            if (moment(e.target.value).isAfter(dateTo)) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isBefore(moment(dateTo).subtract(7, 'days'))) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateFrom = e.target.value;
            }
        } else {
            if (moment(e.target.value).isBefore(dateFrom)) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isAfter(moment(dateFrom).add(7, 'days'))) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateTo = e.target.value;
            }
        }

        // only update run sheet if sensible date chosen, eg. user could still be typing
        if (this.validDate(e.target.value)) {
            sessionStorage.setItem('runsheet', JSON.stringify({ date: moment(newDates.dateFrom).format('YYYYMMDD') }));
            newDates.renderDateFrom = moment(newDates.dateFrom).format('YYYY-MM-DD');
            newDates.renderDateTo = moment(newDates.dateTo).format('YYYY-MM-DD');
        }
        this.setState(newDates);
    };

    render() {
        const { classes } = this.props;
        const { dateFrom, dateTo } = this.state;

        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Run Sheet</span>
                            <Hidden smUp>
                                <ReactToPrint
                                    pageStyle={
                                        '@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'
                                    }
                                    trigger={() => (
                                        <AltButton
                                            className={classes.headerButton}
                                            style={{ padding: 12, minWidth: 'unset' }}
                                        >
                                            <PrintIcon />
                                        </AltButton>
                                    )}
                                    content={() => this.formRef}
                                />
                            </Hidden>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.calendarFieldsWrapper}>
                        <InlineField>
                            <TextField
                                label={'From'}
                                type="date"
                                value={dateFrom}
                                name="dateFrom"
                                onChange={this.dateChange}
                                onBlur={this.dateChange}
                            />

                            <TextField
                                label={'Until'}
                                type="date"
                                value={dateTo}
                                name="dateTo"
                                onChange={this.dateChange}
                                onBlur={this.dateChange}
                            />
                        </InlineField>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} sm={4}>
                            <ReactToPrint
                                pageStyle={
                                    '@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'
                                }
                                trigger={() => (
                                    <AltButton className={classes.headerButton}>
                                        <PrintIcon /> Print
                                    </AltButton>
                                )}
                                content={() => this.formRef}
                            />
                        </Grid>
                    </Hidden>
                </Grid>
                {this.renderReport()}
            </Fragment>
        );
    }

    renderReport() {
        const { renderDateFrom, renderDateTo } = this.state;

        return (
            <Fragment>
                <Query
                    notifyOnNetworkStatusChange
                    pollInterval={60 * 1000}
                    fetchPolicy={'network-only'}
                    query={DailyRunSheetFragment}
                    variables={{ from: renderDateFrom + ' 00:00:00', to: renderDateTo + ' 23:59:59' }}
                >
                    {({ loading, error, data, networkStatus }) => {
                        if (loading && networkStatus === 1) {
                            return (
                                <Fragment>
                                    <div>Loading data, please wait a moment...</div>
                                    <LinearProgress />
                                </Fragment>
                            );
                        }

                        if (error) {
                            return (
                                <Fragment>
                                    <div style={{ float: 'left' }}>
                                        <Spinner />
                                    </div>
                                    <div style={{ paddingLeft: '1.25rem', fontSize: '75%', opacity: 0.5 }}>
                                        {error ? error.toString() : 'loading...'}
                                    </div>
                                </Fragment>
                            );
                        }

                        const getDays = () => {
                            var arr = {};
                            var dt = renderDateFrom;
                            while (moment(dt) <= moment(renderDateTo)) {
                                arr[moment(dt).format('YYYY-MM-DD')] = [];
                                dt = moment(dt).add(1, 'd');
                            }
                            return arr;
                        };
                        let daylist = getDays();
                        daylist = this.addUniqueDaysToDays(data, daylist);
                        return this.renderDays(daylist, networkStatus);
                    }}
                </Query>
            </Fragment>
        );
    }

    addUniqueDaysToDays(data, daylist) {
        let filteredAppointments = this.FilterByDate(data, 'readStaffAllocations', 'Start');

        const funEvents = { readFunerals: [] };
        !!data.readFunerals &&
            data.readFunerals.edges.forEach(e => {
                if (['No Service No Attendance', 'Graveside'].indexOf(e.node.PlaceOfService.Type) === -1)
                    funEvents.readFunerals.push({ ...e.node.PlaceOfService, Funeral: e.node });
                if (e.node.Disposal.CrematedOrBuried !== 'Body Not Recovered')
                    funEvents.readFunerals.push({ ...e.node.Disposal, Funeral: e.node });
                if (e.node.RefreshmentsVenue.Type !== 'Not Required')
                    funEvents.readFunerals.push({ ...e.node.RefreshmentsVenue, Funeral: e.node });
                if (e.node.PlaceOfViewingRequired)
                    e.node.PlaceOfViewingItems.forEach(v => funEvents.readFunerals.push({ ...v, Funeral: e.node }));
                if (e.node.ReflectionRoom.ID && e.node.ReflectionRoomRequired === 'Required')
                    funEvents.readFunerals.push({ ...e.node.ReflectionRoom, Funeral: e.node });
            });

        let filteredFuneralEvents = this.FilterByDate(funEvents, 'readFunerals', 'Start');

        //cycle through appointments and create unique days
        return this.getUniqueDates(
            daylist,
            filteredAppointments.sort((a, b) => moment(a.Start) - moment(b.Start)),
            filteredFuneralEvents.sort((a, b) => moment(a.Start) - moment(b.Start)),
            'Date'
        );
    }

    getUniqueDates(days, appointments, events, key) {
        let result = [];

        appointments.forEach(function(item) {
            if (!(item[key] in days)) {
                days[item[key]] = [];
                result.push(days[item[key]]);
            }
            let tempDays = days[item[key]];
            if (!tempDays.Appointments) {
                tempDays.Appointments = [];
                tempDays.Appointments.push(item);
            } else {
                tempDays.Appointments.push(item);
            }
        });

        events.forEach(function(item) {
            if (isRelatedObjectUndefined(item.Funeral)) return;
            if (!(item[key] in days)) {
                days[item[key]] = [];
                result.push(days[item[key]]);
            }
            let tempDays = days[item[key]];
            if (!tempDays.FuneralEvents) {
                tempDays.FuneralEvents = [];
                tempDays.FuneralEvents.push(item);
            } else {
                tempDays.FuneralEvents.push(item);
            }
        });

        return days;
    }

    getGroupedBy(data, key) {
        let groups = {},
            result = [];

        data.forEach(function(item) {
            if (!(item[key] in groups)) {
                groups[item[key]] = [];
                result.push(groups[item[key]]);
            }
            groups[item[key]].push(item);
        });

        return result;
    }

    getGroupedByObj(data, key) {
        let groups = {},
            result = [];

        data.forEach(function(item) {
            if (!(item[key] in groups)) {
                groups[item[key]] = [];
                result.push(groups[item[key]]);
            }
            groups[item[key]].push(item);
        });

        return groups;
    }

    FilterByDate(data, node, dateObj) {
        const { renderDateFrom, renderDateTo } = this.state;
        const original = cloneDeep(data);
        flattenConnection(original, [node]);

        let datesTimesAdded = [];

        !!original[node] &&
            original[node].forEach(function(item, i) {
                let date = moment(item[dateObj]);

                item['Date'] = date.format('YYYY-MM-DD');

                item['Time'] = date.format('HH:mm:ss');

                if (
                    moment(renderDateFrom).isSameOrBefore(date, 'day') &&
                    moment(renderDateTo).isSameOrAfter(date, 'day')
                )
                    datesTimesAdded.push(item);
            });

        return datesTimesAdded;
    }

    renderDays(data, networkStatus) {
        const { classes } = this.props;
        const loading = !!networkStatus && networkStatus !== 7;

        let days = [];

        for (let item in data) {
            days.push(item);
        }

        return (
            <Paper className={classes.paper} elevation={0}>
                {loading && (
                    <Fragment>
                        <div>Loading data, please wait a moment...</div>
                        <LinearProgress />
                    </Fragment>
                )}
                <div className={classes.rootContainer}>
                    <div ref={e => (this.formRef = e)} className={classes.root}>
                        <Grid container spacing={24} className={classes.print}>
                            {days.map((item, i, a) => {
                                const hasApps = !!data[days[i]] && !!data[days[i]].Appointments;
                                const hasEvts = !!data[days[i]] && !!data[days[i]].FuneralEvents;
                                return (
                                    <Fragment key={i}>
                                        <Grid item xs={12} className={classes.header}>
                                            <div className={i === 0 ? '' : 'page-break'} />
                                            <Typography variant="headline">
                                                Daily Run Sheet for {moment(item).format('dddd[,] LL')}
                                            </Typography>
                                        </Grid>

                                        {/* display appointments if they exist */}
                                        {hasApps && (
                                            <Grid item xs={12}>
                                                <Table
                                                    columns={['APPOINTMENTS: ' + moment(item).format('D/MM/YYYY')]}
                                                    columnspans={[3]}
                                                >
                                                    {this.renderAppointments(data[days[i]])}
                                                </Table>
                                            </Grid>
                                        )}

                                        {/* display funeral events if they exist */}
                                        {hasEvts && this.renderFuneralEvents(data[days[i]])}

                                        {!loading && (!hasApps || !hasEvts) && (
                                            <Grid item xs={12}>
                                                {!hasApps && (
                                                    <Typography align={'center'}>
                                                        There are no appointments on this day.
                                                    </Typography>
                                                )}

                                                {!hasEvts && (
                                                    <Typography align={'center'}>
                                                        There are no events on this day.
                                                    </Typography>
                                                )}
                                            </Grid>
                                        )}
                                        {1 + i !== a.length && (
                                            <div
                                                className={'print-hide'}
                                                style={{
                                                    margin: 32,
                                                    height: 1,
                                                    background: '#e6e6e6'
                                                }}
                                            />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </Grid>
                    </div>
                </div>
            </Paper>
        );
    }

    renderAppointments(item) {
        const { classes } = this.props;
        const uniqueAppointments = [];

        if (!item.Appointments) return null;

        return (
            <Fragment>
                <TableSecondaryHeaderRow>
                    <TableHeaderCell className={classes.sideColumn}>
                        <span>Time</span>
                    </TableHeaderCell>

                    <TableHeaderCell className={classes.extraColumn}>
                        <span>Staff / Office</span>
                    </TableHeaderCell>

                    <TableHeaderCell>
                        <span>Reason / Location</span>
                    </TableHeaderCell>
                </TableSecondaryHeaderRow>

                {item.Appointments.map((item, j) => {
                    // skip duplicate appointments
                    if (!(uniqueAppointments.indexOf(item.Appointment.ID) < 0)) return null;
                    uniqueAppointments.push(item.Appointment.ID);

                    const officeName =
                        FUNERAL_HOMES.find(e => e.LegacyKey === item.Appointment.Office) ||
                        (item.Appointment.Office === '<All Offices>' ? { label: 'All Offices' } : null);

                    const locationString = item.LocationFlattened;
                    const location = !!locationString && locationString.replace(/Phone\scall:\s/, '');
                    const phone =
                        !stringIsNullOrEmpty(item.Appointment.Phone) &&
                        item.Appointment.Phone !== location &&
                        item.Appointment.Phone;

                    return (
                        <TableRow key={j} pad className={'page-break-avoid'}>
                            <TableCell className={classes.sideColumn}>
                                <span>
                                    {moment(item.Start).format('h:mma')} - {moment(item.End).format('h:mma')}
                                </span>
                            </TableCell>
                            <TableCell className={classes.extraColumn}>
                                {item.Appointment.Members.length > 0 ? (
                                    item.Appointment.Members.sort((a, b) => (a.FirstName < b.FirstName ? -1 : 1)).map(
                                        (member, j, a) => (
                                            <span key={j}>
                                                <span>
                                                    {member.FirstName} {member.Surname}
                                                </span>
                                                {a.length !== 1 + j && ', '}
                                            </span>
                                        )
                                    )
                                ) : (
                                    <div className={classes.notSpecified}>No Assigned Staff</div>
                                )}
                                {!!officeName && <div className={classes.notes}>{officeName.label}</div>}
                            </TableCell>
                            <TableCell>
                                {!stringIsNullOrEmpty(item.Appointment.Reason) ? (
                                    <div>{item.Appointment.Reason}</div>
                                ) : (
                                    <div className={classes.notSpecified}>Not specified</div>
                                )}
                                {(!!location || !!phone) && (
                                    <div className={classes.notes}>
                                        {!!location ? `At ${location}` : ''}
                                        {!!location && !!phone ? ' - ' : ''}
                                        {!!phone ? <Fragment>Call {prettyPhone(phone, false)}</Fragment> : ''}
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Fragment>
        );
    }

    renderFuneralEvents(list) {
        if (isNullOrUndefined(list.FuneralEvents)) return null;
        const { classes } = this.props;

        const funeralEvents = [];
        const funServices = list.FuneralEvents.filter(item => {
            // need to find all the services, include committals for graveside services
            return (
                item.__typename === 'FuneralPlaceOfService' ||
                (item.__typename === 'FuneralDisposal' &&
                    !!item.Funeral.PlaceOfService &&
                    item.Funeral.PlaceOfService.Type === 'Graveside')
            );
        });

        const pushViewing = [];
        list.FuneralEvents.forEach((item, i, a) => {
            let keepFun = true;

            if (!!funServices.find(fun => item.ID === fun.ID)) {
                // need to inject certain viewings immediately before their service
                const funViews = pushViewing.filter(e => e.ID === item.Funeral.ID);
                if (!!funViews && funViews.length > 0) {
                    funViews.forEach(viewing => {
                        funeralEvents.push(viewing.view);
                    });
                }
            } else {
                const funService = funServices.find(e => e.Funeral.ID === item.Funeral.ID);

                if (funService && item.__typename === 'RefreshmentsVenue') {
                    if (moment(item.Start).diff(funService.Start, 'days') === 0) {
                        // If date of service = date of refreshment, discard refreshment
                        keepFun = false;
                    }
                } else if (funService && item.__typename === 'FuneralDisposal') {
                    if (
                        moment(item.Start).diff(funService.Start, 'hours') < 3 &&
                        moment(item.Start).diff(funService.Start, 'hours') >= 0
                    ) {
                        // If time of committal < 3hrs after time of service then combine into 1 event on the runsheet.
                        // If time of committal ≥ 3hrs after time of service (or before or different date) show separately.
                        keepFun = false;
                    }
                } else if (funService && item.__typename === 'FuneralPlaceOfViewing') {
                    // If time of viewing ≤ 1hrs before time of service then we'll move it to appear above service.
                    if (
                        moment(item.Start).diff(funService.Start, 'hours') >= -1 &&
                        moment(item.Start).diff(funService.Start, 'hours') <= 0
                    ) {
                        keepFun = false;
                        pushViewing.push({ ID: item.Funeral.ID, view: item });
                    }
                }
            }
            if (!!keepFun) funeralEvents.push(item);
        });

        return (
            <Fragment>
                {funeralEvents.map((item, i) => (
                    <Grid item xs={12} key={i}>
                        <Table className={'page-break-avoid'}>
                            <TableHeaderRow>
                                <TableHeaderCell colSpan="2">
                                    {this.renderEventTitle(item)}
                                    {this.renderBrand(item)}
                                </TableHeaderCell>
                            </TableHeaderRow>
                            <TableSecondaryHeaderRow>
                                <TableHeaderCell className={classes.sideColumn}>
                                    <span>Assigned Staff</span>
                                </TableHeaderCell>

                                <TableHeaderCell>{this.renderFuneralTitle(item)}</TableHeaderCell>
                            </TableSecondaryHeaderRow>
                            {this.renderEvents(item)}
                        </Table>
                    </Grid>
                ))}
            </Fragment>
        );
    }

    renderBrand(item) {
        if (!isNullOrUndefined(item.Funeral.LegacyKey)) {
            let brand = getFuneralHomeByKey(item.Funeral.LegacyKey);
            return (
                <span className={this.props.classes.brandField}>
                    <LinkButton
                        title={'View Record'}
                        classes={{
                            enabled: this.props.classes.niceLink
                        }}
                        href={this.viewRecord(item, { type: this.renderEventType(item) }, false)}
                        onClick={e => this.viewRecord(item, { type: this.renderEventType(item) })}
                        text={item.Funeral.LegacyKey}
                    />{' '}
                    | {brand.label}{' '}
                </span>
            );
        } else {
            return null;
        }
    }

    renderEvents(item) {
        if (item.ClassName === 'FuneralManager\\FuneralDisposal') return this.renderFuneralEventDisposal(item);

        if (item.ClassName === 'FuneralManager\\FuneralPlaceOfViewing') return this.renderFuneralEventDisposal(item);

        if (item.ClassName === 'FuneralManager\\FuneralPlaceOfService') return this.renderFuneralEventDisposal(item);

        if (item.ClassName === 'FuneralManager\\RefreshmentsVenue') return this.renderFuneralEventDisposal(item);

        if (item.ClassName === 'FuneralManager\\ReflectionRoom') return this.renderFuneralEventDisposal(item);

        console.error('unknown funeral event type', item, item.ClassName);

        return null;
    }

    renderFuneralEventDisposal(item) {
        const { classes } = this.props;

        return (
            <TableRow pad>
                <TableCell className={classes.sideColumn}>{this.renderStaffList(item.StaffAllocations)}</TableCell>
                <TableCell className={classes.packedIn}>{renderRunSheetDetails(item, classes)}</TableCell>
            </TableRow>
        );
    }

    renderStaffList(staffAllocations) {
        const { classes } = this.props;

        const trimmed = staffAllocations.filter(e => !!e); //.sort((a, b) => (a.Member.FirstName < b.Member.FirstName ? -1 : 1));
        if (trimmed.length === 0) return <div className={classes.notSpecified}>No Staff Allocated</div>;

        return (
            <Fragment>
                {trimmed.map((item, index) => (
                    <p key={index}>
                        {(!!item.Member && isContactDefined(item.Member) && (
                            <Fragment>
                                {item.Member.FirstName} {item.Member.Surname}
                            </Fragment>
                        )) || <span className={classes.notSpecified}>Staff Not Allocated</span>}
                        <small>
                            <span className={classes.forcedLine}>
                                {item.Start !== item.End &&
                                    `${moment(item.Start).format('h:mma')}-${moment(item.End).format('h:mma')}`}
                            </span>
                            <span className={classes.forcedLine}>{item.Allocation}</span>
                        </small>
                    </p>
                ))}
            </Fragment>
        );
    }

    renderEventTitle(item) {
        let name = '';
        name = joinDefined(
            [item.Funeral.FirstName, item.Funeral.MiddleName, ('' + item.Funeral.Surname).toUpperCase()],
            ' '
        );
        return (
            this.renderEventType(item).toUpperCase() +
            ': ' +
            moment(item.Start).format('h:mma') +
            ` - ${name || '(no name)'}`
        );
    }

    renderEventType(item) {
        let type = '';

        if (item.ClassName === 'FuneralManager\\FuneralDisposal') {
            type = item.Funeral.Disposal.CrematedOrBuried === 'Cremated' ? 'Cremation' : 'Burial';
        } else if (item.ClassName === 'FuneralManager\\FuneralPlaceOfViewing') {
            type = 'Viewing';
        } else if (item.ClassName === 'FuneralManager\\FuneralPlaceOfService') {
            type = 'Service';
        } else if (item.ClassName === 'FuneralManager\\RefreshmentsVenue') {
            type = 'Refreshments';
        } else if (item.ClassName === 'FuneralManager\\ReflectionRoom') {
            type = 'Viewing';
        } else {
            type = 'Other';
        }

        return type;
    }

    renderFuneralTitle(item) {
        let arrangers = [];
        let coordinators = [];
        let conductors = [];

        if (!isNullOrUndefined(item.Funeral.Arrangers)) {
            arrangers = item.Funeral.Arrangers.filter(item => !isNullOrUndefined(item.Member)).map(
                item => `${item.Member.FirstName} ${item.Member.Surname}`
            );
        }

        if (!isNullOrUndefined(item.Funeral.Coordinators)) {
            coordinators = item.Funeral.Coordinators.filter(item => !isNullOrUndefined(item.Member)).map(
                item => `${item.Member.FirstName} ${item.Member.Surname}`
            );
        }

        if (!isNullOrUndefined(item.Funeral.Conductors)) {
            conductors = item.Funeral.Conductors.filter(item => !isNullOrUndefined(item.Member)).map(
                item => `${item.Member.FirstName} ${item.Member.Surname}`
            );
        }

        return (
            <span>
                {(conductors.length ? ` Conductors: ${conductors}.` : ``) +
                    `
                Arrangers: ${arrangers.length > 0 ? arrangers : 'None'}.
                Coordinators: ${coordinators.length > 0 ? coordinators : 'None'}.
                `}
            </span>
        );
    }

    viewRecord = (event, category, pushURL = true) => {
        const url = '/funeral/' + event.Funeral.LegacyKey + '/' + event.Funeral.ID;
        const tab = category.type === 'Refreshments' ? 'optionals' : 'funeral-details';
        if (pushURL) this.props.history.push(url + '/' + tab);
        return url + '/' + tab;
    };
}

const styles = ({ spacing, palette }) => ({
    paper: {
        /*padding: spacing.unit * 3,*/
        marginTop: 32
    },
    packedIn: {
        lineHeight: 1.5
    },
    forcedLine: {
        display: 'block'
    },
    pageTitle: {
        color: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode]
    },
    fullWidth: {
        marginRight: 6
    },
    fullWidthDiv: {
        margin: '6px 0'
    },
    border: {
        border: '1px solid #aa55aa'
    },
    sideColumn: {
        width: '8.7rem',
        '& > p:first-of-type': {
            marginTop: 0
        },
        '& > p:last-of-type': {
            marginBottom: 0
        }
    },
    extraColumn: {
        width: '13.3rem'
    },
    header: {
        textAlign: 'center'
    },
    headerButton: {
        float: 'right'
    },
    brandField: {
        float: 'right'
    },
    calendarFieldsWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    notSpecified: {
        fontStyle: 'italic',
        color: '#777'
    },
    niceLink: {
        '& > span': {
            color: '#ffffff'
        },
        '& > span:hover': {
            color: '#ffffffAA'
        }
    },
    notes: {
        fontStyle: 'italic',
        color: '#777',
        '& a': {
            color: 'inherit'
        }
    }
});

export default compose(
    withSnackbarMessage,
    withStyles(theme => ({ ...styles(theme), ...formStyles(theme) }))
)(DailyRunSheet);
