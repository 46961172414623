import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DrawerListItem from './DrawerListItem';
import fmLogoSvg from '../../_asset/fm-logo.svg';
import burgerSvg from '../../_asset/burger.svg';
import cx from 'classnames';
import GatedComponent from '../GatedComponent';
import { isNullOrUndefined } from '../../util/objects';

class DrawerList extends Component {
    state = {
        activeItem: undefined
    };

    componentDidMount() {
        this.updateActiveItem(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateActiveItem(nextProps);
    }

    updateActiveItem(props) {
        const activeItem = getMatchingItem(props.location.pathname, props.itemsList);
        if (activeItem !== this.state.activeItem) {
            this.setState({ activeItem });
        }
    }

    render() {
        const { items, hideOnCollapse, minimized, footer, classes } = this.props;
        const renderContent = !minimized || !hideOnCollapse;
        const listClass = footer ? classes.listWithFooter : classes.list;
        return (
            <Fragment>
                <List component="nav" className={listClass}>
                    {this.renderTitle()}
                    {renderContent && items.map(this.renderItem)}
                </List>
                {renderContent && footer && <div className={classes.footer}>{footer()}</div>}
            </Fragment>
        );
    }

    renderTitle() {
        const {
            minimized,
            onClickHamburger,
            classes,
            showTitleIcon,
            hamburgerAnchor,
            hamburgerIcon,
            drawerTitle
        } = this.props;
        const { title, titleIcon, titleText, burger } = classes;

        const menuIcon = (
            <IconButton onClick={onClickHamburger} className={classes.burgerButton} title={drawerTitle}>
                {!!hamburgerIcon ? (
                    hamburgerIcon
                ) : (
                    <img
                        src={burgerSvg}
                        alt="toggle menu"
                        className={cx(burger, { [classes.burgerMinimized]: minimized })}
                    />
                )}
            </IconButton>
        );

        let before;
        let after;

        if (hamburgerAnchor === 'left') before = menuIcon;
        else after = menuIcon;

        return (
            <div className={title}>
                {before}
                {!minimized && (
                    <Fragment>
                        {showTitleIcon && <img src={fmLogoSvg} alt="logo" className={titleIcon} />}
                        <Typography variant="subheading" className={titleText}>
                            {drawerTitle}
                        </Typography>
                    </Fragment>
                )}
                {after}
            </div>
        );
    }

    renderItem = item => {
        const { minimized, renderItem, renderGroup } = this.props;
        const { activeItem } = this.state;

        return (
            <GatedComponent key={item.id || item.label} showComponentCode={item.permission}>
                {() => {
                    return (
                        <DrawerListItem
                            key={item.id || item.label}
                            item={item}
                            minimized={minimized}
                            activeItem={activeItem}
                            renderItem={renderItem}
                            renderGroup={renderGroup}
                        />
                    );
                }}
            </GatedComponent>
        );
    };
}

const getMatchingItem = (path, itemsList) => {
    for (let x = 0; x < itemsList.length; x++) {
        const item = itemsList[x];
        if (item.path === path) return item;
        if (isNullOrUndefined(item.items)) {
            if (path.startsWith(item.path)) return item;
        } else if (path.startsWith(item.path)) {
            const activeSubItem = item.items.find(subItem => path.startsWith(subItem.path));
            //we return the sub item. OR we return the parent item, if this is a breadcrumb
            if (activeSubItem) return activeSubItem;
            if (path.startsWith(item.path) && item.path !== '/') return item;
        }
    }

    return null;
};

const styles = ({ sizes, spacing }) => ({
    list: {
        paddingTop: 0,
        overflow: 'hidden',
        overflowY: 'auto'
    },
    listWithFooter: {
        paddingTop: 0,
        margin: '0px 0px 72px 0px',
        overflow: 'auto'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: sizes.headerHeight
    },
    titleText: {
        flex: '1 0 auto'
    },
    titleIcon: {
        width: 38,
        marginRight: spacing.unit,
        marginLeft: spacing.unit * 2
    },
    burger: {
        width: 16
        // marginRight: spacing.unit
    },
    burgerMinimized: {
        marginRight: 0
    },
    burgerButton: {
        marginLeft: spacing.unit / 2,
        marginRight: spacing.unit / 2,
        width: 40,
        height: 40
    },
    footer: {
        display: 'relative',
        position: 'absolute',
        bottom: '70px',
        padding: '15px 15px'
    },
    drawerPaperMinimized: {},
    drawerPaper: {},
    docked: {}
});

export default withRouter(withStyles(styles)(DrawerList));
