import { pad } from "./strings";
import { isNullOrUndefined } from "./objects";
import moment from "moment";

moment.suppressDeprecationWarnings = true;

/**
 * converts a date to a string, formatted as yyyy-MM-dd
 *
 * @param date date to format
 */
export function dateToString(date) {
    /*
    let str = date.getFullYear() + '-' + pad(date.getMonth() + 1 + '', 2) + '-' + pad(date.getDate() + '', 2);
    */
    if (!!date && moment(date).isValid()) return moment(date).format('YYYY-MM-DD');
    return null;
}

export function dateToTimeString(date) {
    if (!!date && moment(date).isValid()) return moment(date).format('HH:mm:ss');
    return null;
}

/**
 * converts a datetime to a string, formatted as yyyy-MM-dd HH:mm:ss
 *
 * @param datetime datetime to format
 */
export function dateTimeToString(datetime) {
    if (!!datetime && moment(datetime).isValid()) return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
    return null;
    /*
    let str =
        datetime.getFullYear() +
        '-' +
        pad(datetime.getMonth() + 1 + '', 2) +
        '-' +
        pad(datetime.getDate() + '', 2) +
        ' ' +
        pad(datetime.getHours() + '', 2) +
        ':' +
        pad(datetime.getMinutes() + '', 2) +
        ':' +
        pad(datetime.getSeconds() + '', 2);

    return str;
     */
}

/**
 * converts a string to a date, expected format is yyyy-MM-dd HH:mm:ss
 *
 * @param str string to turn into a date
 */
export function stringToDate(str) {
    if (!!str && moment(str).isValid()) return moment(str).toDate();
    return null;
    /*
    const dateParts = getDateParts(str);
    return new Date(
        dateParts.year,
        dateParts.month,
        dateParts.day,
        dateParts.hour,
        dateParts.minute,
        dateParts.second,
        0
    );
     */
}

/*
function getDateParts(str) {
    let dateParts = {
        year: 2000,
        month: 0,
        day: 1,
        hour: 0,
        minute: 0,
        second: 0
    };

    const parts = str.split(' ').map(x => parseDatePart(x));
    for (var x = 0; x < parts.length; x++) {
        dateParts = { ...dateParts, ...parts[x] };
    }

    return dateParts;
}

function parseDatePart(dateString) {
    if (dateString.length === 10) {
        const yearMonthDay = dateString.split('-');
        if (yearMonthDay.length !== 3) throw new Error('unexpected date format "' + dateString + '"');

        return {
            year: parseInt(yearMonthDay[0], 10),
            month: parseInt(yearMonthDay[1], 10) - 1,
            day: parseInt(yearMonthDay[2], 10)
        };
    } else if (dateString.length === 8) {
        const timeOfDay = dateString.split(':');
        if (timeOfDay.length !== 3) throw new Error('unexpected date format "' + dateString + '"');

        return {
            hour: parseInt(timeOfDay[0], 10),
            minute: parseInt(timeOfDay[1], 10),
            second: parseInt(timeOfDay[2], 10)
        };
    }

    throw new Error('unexpected date time format "' + dateString + '"');
}
*/

/**
 * gets the year delta between two dates
 * NOTE: the first date must be greater than the second, otherwise zero is returned
 *
 * @param date1 first date
 * @param date2 second date
 */
export function yearDelta(date1, date2) {
    return date1 > date2 ? 0 : Math.floor((date2 - date1) / (1000 * 60 * 60 * 24 * 365.25));
}

/**
 * gets the total days from the given date until today
 * used primarily to work out how old a record is
 * @param date old date
 */
export function dayDelta(date1, date2) {
    const fixedDate1 = typeof date1 === 'string' ? stringToDate(date1) : date1;

    const fixedDate2 = typeof date2 === 'string' ? stringToDate(date2) : date2;

    const dayDelta = (fixedDate2 - fixedDate1) / 1000 / 3600 / 24;
    return Math.round(dayDelta);
}

/**
 * day of week. each day's int value corresponds to javascript's internal (new Date()).getDay() value
 */
const Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * day of week. each day's int value corresponds to javascript's internal (new Date()).getDay() value
 */
const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

/**
 * finds the date of a given day of week. used to find the first day of the current week
 * @param {*} dayOfWeek DayOfWeek.[Sunday to Saturday] or int[0 to 6]
 * @param {*} date Javascript Date()
 */
function getFirstOccurrenceAtOrBefore(dayOfWeek = 0, date = new Date()) {
    if (dayOfWeek < 0) throw new Error('DayOfWeek cannot be less than zero (Sunday = 0)');

    if (dayOfWeek > 6) throw new Error('DayOfWeek cannot be greater than 6 (Saturday = 6)');

    let currentDate = date;
    while (currentDate.getDay() !== dayOfWeek) currentDate.setDate(currentDate.getDate() - 1);

    return currentDate;
}

/**
 * gets the sunday-commencing start of week date
 */
export function getStartOfWeek() {
    return getFirstOccurrenceAtOrBefore(0, new Date());
}

/**
 * if a date is DATE TIME, this just trims out the time bit
 *
 export function dateFromString(dateString) {
    if (!dateString) return '';

    return dateString.split(' ')[0];
}
 */
/**
 * adjusts a time string
 * @param {*} timeString
 * @param {*} minutes
 */
export const adjustTimeString = (timeString, minutes) => {
    if (!timeString || typeof timeString !== 'string') return '00:00';

    const parts = timeString.split(':');
    const date = new Date(2000, 1, 1, parseInt(parts[0], 10), parseInt(parts[1], 10), 0, 0);
    const adjustedDate = new Date(date.getTime() + minutes * 60000);

    return `${pad(adjustedDate.getHours(), 2)}:${pad(adjustedDate.getMinutes(), 2)}`;
};

/***
 * takes a date like 2005-09-23 and turns it into Thursday 23 September, 2005
 */
export const niceDateFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    const date = stringToDate(dateString);
    if (!moment(date).isValid()) return null;

    return `${Days[date.getDay()]} ${date.getDate()} ${Months[date.getMonth()]} ${date.getFullYear()}`;
};

/***
 * takes a date like 2005-09-23 13:20:00 and turns it into Thursday 23 September, 2005 at 1.20PM
 */
export const niceDateTimeFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    const date = stringToDate(dateString);
    if (!moment(date).isValid()) return null;

    const hour = date.getHours() - (date.getHours() >= 12 ? 12 : 0);
    const period = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${niceDateFromString(dateString)} at ${hour === 0 ? 12 : hour}:${pad(date.getMinutes(), 2)}${period}`;
};

/***
 * takes a date like 2005-09-23 13:20:00 and turns it into 1.20PM
 */
export const niceTimeFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    let date = stringToDate(dateString);
    if (!moment(date).isValid()) {
        date = stringToDate('2001-01-01 ' + dateString);
        if (!moment(date).isValid()) {
            return null;
        }
    }

    const hour = date.getHours() - (date.getHours() >= 12 ? 12 : 0);
    const period = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${hour === 0 ? 12 : hour}:${pad(date.getMinutes(), 2)}${period}`;
};

/***
 * compares two dates and returns difference in years or days.
 */
export const nicePeriod = (birthday, deathday) => {
    let result = '';
    ['years', 'days', 'hours', 'minutes'].forEach(unitX => {
        const diffX = Math.abs(moment(deathday).diff(birthday, unitX));
        if (!result && diffX > 0) {
            result = diffX + ` ${unitX}`;
        }
    });
    return result;
};
