import gql from 'graphql-tag';
import EventConflictPartial from './EventConflictPartial';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment PlaceOfViewingItems on Funeral {
        PlaceOfViewingRequired
        PlaceOfViewingItems {
            ID
            Code
            Date
            Time
            Duration
            Type
            Comment
            Confirmed
            NameAddress
            ViewingPrivate
            TypeOfViewingAdv

            Location {
                ID
                Name
                AddressLine1
                State
                Suburb
                Postcode
                Phone
                Mobile
                Fax
            }
            StaffAllocations {
                ID
                Title
                Allocation
                Start
                End
                Type
                Location
                Comments
                Recurring
                Member {
                    ID
                    FirstName
                    Surname
                    Email
                }
            }
            Bookings {
                ${BookingsFragmentInclude}
            }
            EventConflicts {
                ${EventConflictPartial}
            }
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementPlaceOfViewingItems on Prearrangement {
        PlaceOfViewingItems {
            ID
            Code
            Date
            Time
            Duration
            #                    Type
            Comment

            NameAddress
            #                    ViewingPrivate
            #                    TypeOfViewingAdv

            Location {
                ID
                Name
                AddressLine1
                State
                Suburb
                Postcode
                Phone
                Mobile
                Fax
            }
        }
    }
`;
