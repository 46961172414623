import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import fmLogoSvg from '../../_asset/fm-logo.svg';
import cx from 'classnames';

const AnonPage = ({ classes, children }) => (
    <div className={classes.root}>
        <div className={cx(classes.side, classes.leftSide)}>
            <img src={fmLogoSvg} alt="logo" className={classes.fmLogo} />
            <Typography variant="display1" align="center" className={classes.fmTitle}>
                Funeral
                <br />
                Manager
            </Typography>
        </div>
        <div className={cx(classes.side, classes.rightSide)}>{children}</div>
    </div>
);

const styles = ({ palette, typography, spacing, breakpoints }) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        height: 0, // flex hack!
        overflow: 'auto',
        margin: -spacing.unit * 3,
        [breakpoints.down('xs')]: {
            margin: '-8px -12px',
            flexDirection: 'column'
        }
    },
    side: {
        flex: '1 1 50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '6px 0'
    },
    leftSide: {
        backgroundColor: palette.action.active,
        [breakpoints.down('sm')]: {
            flex: '1 1 33%'
        },
        [breakpoints.down('xs')]: {
            flex: '1 1 33%'
        }
    },
    rightSide: {
        backgroundColor: palette.contentBackground.none,
        [breakpoints.down('sm')]: {
            flex: '1 1 67%'
        },
        [breakpoints.down('xs')]: {
            display: 'block',
            overflow: 'unset',
            paddingBottom: spacing.unit * 4 // safari
        }
    },
    fmLogo: {
        width: 64,
        marginBottom: spacing.unit
    },
    fmTitle: {
        color: palette.common.white,
        fontWeight: typography.fontWeightLight
    },
    dividerLine: {
        width: 50,
        height: 4,
        backgroundColor: palette.action.active,
        margin: '16px 0'
    }
});

export default withStyles(styles)(AnonPage);
