import gql from 'graphql-tag';

export default gql`
    fragment Notification on Notification {
        ID
        Created
        Title
        Description
        TabURLSegment
        Funeral {
            ID
            LegacyKey
        }
        Task {
            ID
        }
        Quote {
            ID
            QuoteType
            Accepted
        }
        Appointment {
            ID
            Start
        }
        AssignedMembers {
            ID
            Join {
                ID
                Read
            }
        }
    }
`;
