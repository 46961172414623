import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import cx from 'classnames';
import { joinDefined } from '../../util/strings';

const styles = ({ funeralHome, palette, breakpoints }) => ({
    addButtonDesktop: {
        boxShadow: 'none',
        borderRadius: 30,
        padding: '0.65em 1.5em',
        fontSize: '0.9em',
        fontWeight: '300',
        minWidth: '8em',
        backgroundColor: palette.basePrimary[funeralHome],
        border: '1.5px solid ' + palette.basePrimary[funeralHome],
        '&:hover': {
            backgroundColor: palette.baseSecondary[funeralHome] + 'AA',
            border: '1.5px solid ' + palette.baseSecondary[funeralHome] + 'AA'
        },
        '& svg, & .material-icons': {
            [breakpoints.up('md')]: {
                fontSize: '1rem',
                width: '1rem',
                height: '1rem',
                marginRight: '0.35rem'
            }
        },
        [breakpoints.down('sm')]: {
            minWidth: '4em'
        }
    },

    addButtonLabel: {
        textTransform: 'capitalize',
        fontWeight: 'normal',
        fontSize: '0.9rem',
        letterSpacing: '0.5px',
        lineHeight: 'normal'
    },

    altButton: {
        backgroundColor: palette.baseSecondary[funeralHome],
        borderColor: palette.baseSecondary[funeralHome],
        color: palette.button.alt.foregroundColor,
        '&:hover': {
            backgroundColor: 'white',
            borderColor: palette.baseSecondary[funeralHome],
            color: palette.baseSecondary[funeralHome]
        }
    },

    outlineButton: {
        background: 'white',
        color: palette.contentForeground[funeralHome],
        borderColor: palette.basePrimary[funeralHome],
        '&:hover': {
            backgroundColor: palette.basePrimary[funeralHome],
            color: 'white',
            borderColor: palette.basePrimary[funeralHome]
        }
    },

    modalPrimaryButton: {
        backgroundColor: palette.baseSecondary[funeralHome],
        borderColor: palette.baseSecondary[funeralHome],
        color: palette.button.alt.foregroundColor,
        '&:hover': {
            backgroundColor: palette.baseTertiary[funeralHome],
            borderColor: palette.baseSecondary[funeralHome],
            color: palette.baseSecondary[funeralHome]
        }
    },

    saveButton: {
        backgroundColor: palette.action.save,
        borderColor: palette.action.save,
        '&:hover': {
            borderColor: palette.action.save,
            backgroundColor: palette.action.hover,
            color: palette.action.save
        }
    },
    destroyButton: {
        backgroundColor: palette.action.destroy,
        borderColor: palette.action.destroy,
        '&:hover': {
            borderColour: palette.action.destroy,
            backgroundColor: '#ffffff',
            color: palette.action.destroy
        }
    }
});

/**
 * Primary coloured button, suitable for generic actions. Prepend "+" for adding inline items, append "..." when opening modals.
 */
const PrimaryButton = ({ classes, onClick, text, children, className, color, title, target, ...props }) => {
    const customColorClass = classes[color];
    if (target) {
        title = joinDefined([title, '(Opens in a new window)'], ' ');
    }
    return (
        <Button
            title={title}
            target={target}
            variant="contained"
            color={'primary'}
            onClick={onClick}
            className={cx(classes.addButtonDesktop, className, customColorClass, target && 'targetNewPage')}
            classes={{ label: classes.addButtonLabel }}
            {...props}
        >
            {children || text}
        </Button>
    );
};

/**
 * Alternatively-styled button for unusual actions eg. printing, sending emails.
 */
export const AltButton = withStyles(styles)(({ classes, className, ...props }) => (
    <PrimaryButton className={cx(classes.altButton, className)} classes={classes} {...props} />
));

/**
 * Button that uses an outline for the style. Typically used when moving away from current screen eg. closing a modal or leaving the page.
 */
export const OutlineButton = withStyles(styles)(({ classes, className, ...props }) => (
    <PrimaryButton className={cx(classes.outlineButton, className)} classes={classes} {...props} />
));

/**
 * Used on a popup modal to indicate confirmation & closing - but not writing to database!
 */
export const ModalPrimaryButton = withStyles(styles)(({ classes, ...props }) => (
    <PrimaryButton className={classes.modalPrimaryButton} classes={classes} {...props} />
));

/*
 * Only use this button if you are writing to the database!
 */
export const SaveButton = withStyles(styles)(({ classes, className, ...props }) => (
    <PrimaryButton className={cx(classes.saveButton, className)} classes={classes} {...props} />
));

/*
 * Only use this button if you are writing to the database!
 */
export const DestroyButton = withStyles(styles)(({ classes, className, ...props }) => (
    <PrimaryButton className={cx(classes.destroyButton, className)} classes={classes} {...props} />
));

export default withStyles(styles)(PrimaryButton);
