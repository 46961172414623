import React, { Component, Fragment } from 'react';
import { Icon, Typography, withStyles } from '@material-ui/core';
import PrimaryButton, { OutlineButton } from '../form/PrimaryButton';
import CartItem from './CartItem';
import { getCartTotal } from '../../util/products.js';
import { prettyPrice } from '../../util/strings';

class Cart extends Component {
    render() {
        const { classes } = this.props;
        const cart = this.props.cart || [];
        return (
            <Fragment>
                <div className={classes.cartHeader}>
                    <div className={classes.cartHeaderSummary}>
                        <Icon>shopping_cart</Icon>
                        <Typography>
                            Cart: {cart.length} product{cart.length === 1 ? '' : 's'} selected
                        </Typography>
                    </div>
                    <Typography variant="display1">Preview order</Typography>
                </div>
                {this.renderCartList()}
                {this.renderCartFooter()}
            </Fragment>
        );
    }

    renderCartList() {
        const { classes } = this.props;
        const cart = this.props.cart || [];
        return (
            <div className={classes.cartList}>
                <div className={classes.cartListInner}>
                    {(cart && cart.length > 0 && cart.map(this.renderCartItem)) || 'Cart is empty.'}
                </div>
            </div>
        );
    }

    renderCartItem = (item, index) => {
        const { config } = this.props;
        return (
            <CartItem
                key={index}
                item={item}
                config={config}
                onRemove={this.onRemoveCartItem}
                onChange={this.onChangeCartItem}
            />
        );
    };

    renderCartFooter() {
        const { classes, config, onConfirm } = this.props;
        const cart = this.props.cart || [];
        // Checking if we have all products available in the config, if not then they are still loading
        const loading = cart.some(({ productId }) => !config.productMap[productId]);

        const total = !loading && getCartTotal(cart, config);
        const disabled = loading;
        return (
            <div className={classes.cartFooter}>
                {!!total && (
                    <div className={classes.cartTotal}>
                        <span>Order Total</span>
                        <span>{prettyPrice(total)}</span>
                    </div>
                )}
                <div className={classes.cartActions}>
                    <OutlineButton
                        className={classes.clearButton}
                        onClick={this.onClearCart}
                        disabled={disabled || !cart.length}
                    >
                        Clear Cart
                    </OutlineButton>
                    <PrimaryButton onClick={onConfirm} disabled={disabled}>
                        Confirm Selection<Icon>chevron_right</Icon>
                    </PrimaryButton>
                </div>
            </div>
        );
    }

    onClearCart = () => {
        const { cart } = this.props;
        cart.splice(0, cart.length);
        this.forceUpdate();
    };

    onRemoveCartItem = item => {
        const { cart } = this.props;
        cart.splice(cart.indexOf(item), 1);

        if (item.onRemove) item.onRemove();

        this.forceUpdate();
    };

    onChangeCartItem = () => {
        this.forceUpdate();
    };
}

const styles = ({ palette, typography, breakpoints }) => ({
    root: {},
    cartHeader: {
        padding: 20,
        backgroundColor: palette.primary.main,
        borderTopRightRadius: 8,
        '& span, & h1, & p': {
            color: palette.common.white
        },
        '& p': {
            marginLeft: 4
        }
    },
    cartHeaderSummary: {
        display: 'flex',
        alignItems: 'center'
    },
    cartList: {
        padding: 20,
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflowY: 'auto'
    },
    cartListInner: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column-reverse',
        justifyContent: 'flex-end'
    },
    cartFooter: {
        marginTop: 4,
        padding: '0 20px 20px',
        flex: '0 0 auto'
    },
    cartTotal: {
        padding: 20,
        margin: '0 -20px',
        display: 'flex',
        fontWeight: typography.fontWeightMedium,
        justifyContent: 'space-between',
        borderTop: `1px solid ${palette.divider}`,
        borderBottom: `1px solid ${palette.divider}`
    },
    cartActions: {
        padding: '20px 20px 20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            padding: '0.25em 2em',
            marginLeft: 16,
            '&:first-child': {
                marginLeft: 0
            },
            '& svg': {
                fontSize: '24px',
                margin: 0
            },
            [breakpoints.down('xs')]: {
                margin: '0 3px',
                minWidth: '2em',
                padding: '0.5em'
            }
        }
    },
    clearButton: {
        marginRight: 24
    }
});

export default withStyles(styles)(Cart);
