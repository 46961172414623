import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { setSnackbarMessage, withSnackbarMessage } from '../../context/SnackbarMessage';
import { Hidden, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import { FUNERAL_HOME } from '../../util/funerals';
import { theme } from '../../Theme.js';
import DataTable from '../../component/dataTable/DataTable';
import {
    assignDoctorsFunc,
    isAwaitingDocumentation,
    refereeDoctorListColumns,
    refereeDoctorListFilterByFragment,
    refereeDoctorListFragment,
    uploadRefereeMutation
} from './RefereeDoctorListConstants';
import { AltButton, OutlineButton, SaveButton } from '../../component/form/PrimaryButton';
import Modal from '../workQueue2/extras/Modal';
import Inline, { inlineAlignment } from '../workQueue2/extras/Inline';
import { ADDRESS_BOOK_CATEGORY } from '../funeral/funeralConstants';
import AddressBookAutocomplete from '../../component/form/AddressBookAutocomplete';
import { dateToString } from '../../util/date';
import TextField from '../../component/form/TextField';
import Spinner from '../../component/Spinner';
import moment from 'moment';
import DisplayContact from '../../component/bookings/DisplayContact';
import SaveIcon from '../../component/icon/SaveIcon';
import CloseIcon from '../../component/icon/CloseIcon';
import Label from '../../component/form/Label';
import { joinDefined } from '../../util/strings';
import GatedComponent from '../../component/GatedComponent';
import { getAssetsClient, getClient, getServiceURLHostname } from '../../apollo';
import { withTheme } from '@material-ui/core/styles';
import TickCircleIcon from '../../component/icon/TickCircleIcon';
import DownloadIcon from '../../component/icon/DownloadIcon';
import { uploadFilesQuery } from '../../component/form/FileUpload';

class RefereeDoctorList extends Component {
    state = {
        funerals: [],
        downloads: [],
        doctor: 0,
        signingDate: dateToString(new Date()),
        assignDoctorModalOpen: false,
        assignDoctorLoading: false,
        reloadData: false,
        dateFrom: moment()
            .subtract(3, 'months')
            .format('YYYY-MM-DD'),
        dateTo: moment()
            .add(3, 'months')
            .format('YYYY-MM-DD')
    };

    dateChange = e => {
        const { dateFrom, dateTo } = this.state;
        const diff = moment(dateTo).diff(dateFrom, 'days');
        // enforce a maximum of 7 days in the runsheet.
        const newDates = { dateFrom, dateTo };

        if (e.target.name === 'dateFrom') {
            if (moment(e.target.value).isAfter(dateTo)) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isBefore(moment(dateTo).subtract(7, 'days'))) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateFrom = e.target.value;
            }
        } else {
            if (moment(e.target.value).isBefore(dateFrom)) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isAfter(moment(dateFrom).add(7, 'days'))) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateTo = e.target.value;
            }
        }
        // sessionStorage.setItem('runsheet', JSON.stringify({ date: moment(newDates.dateFrom).format('YYYYMMDD') }));

        this.setState(newDates);
    };

    render() {
        const { classes } = this.props;
        return (
            <div className="referee-doctor-list">
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Referee Doctor</span>
                        </Typography>
                        <br />
                    </Grid>
                </Grid>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.wideScroll}>
                        <GatedComponent showComponentCode={'FM_ACCESS_RefereeDoctors_View'}>
                            {() => this.renderRefereeDoctorTable()}
                        </GatedComponent>
                    </div>
                </Paper>
            </div>
        );
    }

    renderRefereeDoctorTable() {
        let { funerals, downloads, assignDoctorModalOpen, reloadData, dateFrom, dateTo } = this.state;

        const funeralsLength = Object.keys(funerals || {}).length;
        const downloadsLength = Object.keys(downloads || {}).length;

        const headerMessage = {
            show: true,
            content: null,
            markedColums: null
        };

        if (funeralsLength > 0) {
            headerMessage.content = (
                <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                    <p>
                        You have selected {funeralsLength} record
                        {funeralsLength > 1 && 's'} for referee assignment
                    </p>
                    <AltButton
                        onClick={s =>
                            this.setState({
                                assignDoctorModalOpen: true,
                                assignDoctorDone: false
                            })
                        }
                    >
                        Assign Referee...
                    </AltButton>
                </Inline>
            );
            headerMessage.markedColumns = [5];
        } else if (downloadsLength) {
            headerMessage.content = (
                <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                    <p>
                        You have selected {downloadsLength} record
                        {downloadsLength > 1 && 's'} for download
                    </p>
                    <AltButton onClick={this.downloadDocuments}>
                        <DownloadIcon />
                        Download
                    </AltButton>
                </Inline>
            );
            headerMessage.markedColumns = [0];
        } else {
            headerMessage.show = false;
        }

        return (
            <Fragment>
                <Grid item>
                    <Grid container spacing={24}>
                        <Grid item xs={12} style={{ position: 'relative' }}>
                            <DataTable
                                queryName="readFunerals"
                                fragment={refereeDoctorListFragment}
                                filterByFragment={refereeDoctorListFilterByFragment}
                                columns={refereeDoctorListColumns}
                                headerMessage={headerMessage}
                                variables={{
                                    limit: 10,
                                    filterBy: [
                                        {
                                            field: 'RefereeCompleted',
                                            value: 'Awaiting Documentation'
                                        }
                                    ],
                                    filters: [
                                        {
                                            field: 'Disposal.CrematedOrBuried',
                                            value: 'Cremated'
                                        },
                                        {
                                            field: 'Disposal.Date:GreaterThan',
                                            value: dateFrom
                                        },
                                        {
                                            field: 'Disposal.Date:LessThan',
                                            value: dateTo + ' 23:59:59'
                                        },
                                        {
                                            field: 'Certification.TypeOfBdmDeathCertificate',
                                            value: 'MCCD'
                                        },
                                        {
                                            field: 'DeathCertificateNotRequired',
                                            value: 0
                                        },
                                        {
                                            field: 'Cancelled',
                                            value: 0
                                        }
                                    ],
                                    sortBy: [
                                        {
                                            // field: 'Disposal.Date',
                                            field: 'DateOfService',
                                            direction: 'DESC'
                                        }
                                    ]
                                }}
                                brandProperty="LegacyKey"
                                searchVariable="contains"
                                tableTitle="Funeral Records"
                                onCellClick={(action, LegacyKey, data) => this.updateViewState(action, LegacyKey, data)}
                                onHeaderClick={downloads => this.updateDownloadsList(downloads)}
                                extraData={{ funerals, downloads }}
                                reloadData={reloadData}
                                rowDragDrop={(file, index) => this.handleFileDrop(file, index)}
                                allowDragDrop={row => {
                                    return isAwaitingDocumentation(row);
                                }}
                                aboveTable={
                                    <Fragment>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Inline
                                                    className="referee"
                                                    alignment={inlineAlignment.rightAlignSiblings}
                                                    center
                                                >
                                                    {/*<Inline center>
                                                        <Label text="Select Date Range:" />
                                                        <TextField
                                                            fullWidth={false}
                                                            label={'From'}
                                                            type="date"
                                                            value={dateFrom}
                                                            name="dateFrom"
                                                            onChange={this.dateChange}
                                                        />

                                                        <TextField
                                                            fullWidth={false}
                                                            label={'Until'}
                                                            type="date"
                                                            value={dateTo}
                                                            name="dateTo"
                                                            onChange={this.dateChange}
                                                        />
                                                    </Inline>*/}
                                                </Inline>
                                            </Grid>
                                        </Grid>
                                        {funeralsLength === 0 && downloadsLength === 0 ? (
                                            <div className="instruction">
                                                <p>Select records to begin assigning or downloading documents</p>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </Fragment>
                                }
                                isSortable
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {assignDoctorModalOpen && this.renderModal()}
            </Fragment>
        );
    }

    renderModal() {
        const {
            assignDoctorModalOpen,
            signingDate,
            assignDoctorLoading,
            assignDoctorDone,
            doctor,
            funerals,
            downloads
        } = this.state;
        const funeralsLength = Object.keys(funerals).length;
        if (assignDoctorModalOpen)
            return (
                <Modal
                    open={assignDoctorModalOpen}
                    variant="primary"
                    onClose={() => this.setState({ assignDoctorModalOpen: false })}
                    subtitle="Assign Referee"
                    // title="Choose a Refereeing Doctor"
                >
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <AddressBookAutocomplete
                                label="Refereeing Doctor"
                                placeholder="Search for a Doctor..."
                                categories={[ADDRESS_BOOK_CATEGORY.doctor]}
                                onSelect={(_, result) => this.setState({ doctor: result.ID })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {(doctor && <DisplayContact contactID={doctor} />) || '(No doctor selected)'}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Signing Date"
                                placeholder="Signing Date"
                                type="date"
                                value={signingDate}
                                onChange={e => {
                                    this.setState({ signingDate: e.target.value });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label text="Selected Records" />
                            <div>{joinDefined(Object.keys(funeralsLength > 0 ? funerals : downloads), ', ')}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                                <div>
                                    {!assignDoctorLoading && (
                                        <OutlineButton
                                            onClick={() =>
                                                this.setState({
                                                    assignDoctorModalOpen: false
                                                })
                                            }
                                            disabled={assignDoctorLoading}
                                        >
                                            <CloseIcon />
                                            <Hidden smDown>&nbsp;{assignDoctorDone ? 'Close' : 'Cancel'}</Hidden>
                                        </OutlineButton>
                                    )}
                                </div>
                                {assignDoctorDone ? (
                                    <Inline>
                                        <AltButton onClick={this.downloadDocuments}>
                                            <DownloadIcon /> Download Documents
                                        </AltButton>
                                        <TickCircleIcon />
                                        <Hidden smDown>&nbsp;Referee Assigned</Hidden>
                                    </Inline>
                                ) : (
                                    <SaveButton
                                        onClick={() => this.assignDoctors()}
                                        disabled={assignDoctorLoading || !doctor}
                                    >
                                        {assignDoctorLoading ? (
                                            <Fragment>
                                                <Spinner />
                                                <Hidden smDown>&nbsp;Saving...</Hidden>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <SaveIcon />
                                                <Hidden smDown>&nbsp;Assign to selected</Hidden>
                                            </Fragment>
                                        )}
                                    </SaveButton>
                                )}
                            </Inline>
                        </Grid>
                    </Grid>
                </Modal>
            );
    }

    handleFileDrop(file, row) {
        const client = getClient();
        const assetsClient = getAssetsClient();
        //uploading file
        setSnackbarMessage('Uploading file', true);
        console.dir(row);
        assetsClient
            .mutate({
                mutation: uploadFilesQuery,
                variables: {
                    file: file,
                    publish: true,
                    folderPath: '/documents/funeral/' + row.ID
                }
            })
            .then(result => {
                const file = result.data.uploadFile;
                client
                    .mutate({
                        mutation: uploadRefereeMutation,
                        variables: {
                            input: {
                                ID: row.ID,
                                Certification: {
                                    ID: row.Certification.ID,
                                    FileRefereeingDoctorFormID: file.id || 0
                                }
                            }
                        }
                    })
                    .then(() => {
                        setSnackbarMessage('Success, referee document has successfully been uploaded.', true, 1000);
                    })
                    .catch(error => {
                        setSnackbarMessage(error.message);
                    });
            })
            .catch(error => {
                setSnackbarMessage(error.message);
            });
    }

    assignDoctors = () => {
        const { funerals, doctor, signingDate } = this.state;

        let input = [];
        for (let legacyKey in funerals) {
            funerals[legacyKey].RefereeDoctorDateSigned = signingDate;
            funerals[legacyKey].Certification.Referee.ID = doctor;

            input.push(funerals[legacyKey]);
        }

        const me = this;
        me.setState({
            assignDoctorLoading: true
        });

        const downloads = {};
        Object.keys(funerals).forEach(key => (downloads[key] = funerals[key].ID));

        assignDoctorsFunc(input).then(
            data => {
                if (data.updateManyFuneral && data.updateManyFuneral.edges.length) {
                    me.setState({
                        downloads: downloads,
                        funerals: [],
                        reloadData: true,
                        assignDoctorLoading: false,
                        assignDoctorDone: true
                    });

                    me.props.setSnackbarMessage('Successfully assigned', true);
                } else {
                    me.props.setSnackbarMessage('Oops, there was an error - your changes have NOT saved.');
                }
            },
            e => {
                console.error('error occurred assigning doctors', e);
                // me.setState({ loaded: true, loadingCaption: '' });
                me.props.setSnackbarMessage(
                    'Oops, there was an error - your changes have NOT saved.',
                    false,
                    null,
                    new Error(e)
                );
            }
        );
    };

    updateViewState = (action, LegacyKey, data) => event => {
        let { funerals, downloads } = this.state;
        const { checked } = event.target;

        switch (action) {
            case 'addReferee':
                if (checked) {
                    if (!(LegacyKey in funerals)) {
                        funerals[LegacyKey] = data;
                    } else {
                        for (let property in data) {
                            funerals[LegacyKey][property] = data[property];
                        }
                    }
                } else {
                    delete funerals[LegacyKey];
                }
                break;
            default:
            case 'download':
                if (checked) {
                    downloads[LegacyKey] = data;
                } else {
                    delete downloads[LegacyKey];
                }
                break;
        }

        this.setState({ funerals, downloads });
    };

    updateDownloadsList = downloads => event => {
        this.setState({ downloads });
    };

    downloadDocuments = () => {
        const { downloads } = this.state;
        this.setState({ downloads: {} });

        window.location.href =
            '//' +
            getServiceURLHostname() +
            '/merge-doc-funeral/download_multiple/medical/' +
            Object.values(downloads).join(',');
    };
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingTop: 0,
        paddingLeft: 24,
        marginLeft: -12
    },
    tableData: {
        marginTop: '1rem'
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    dotList: {
        minHeight: 16,
        width: 16,
        display: 'inline-block',
        position: 'absolute',
        left: -18,
        margin: '0 -10px 0 0',
        padding: '0',
        listStyle: 'none',
        '& > :not(:first-child)': {
            marginTop: 2
        }
    },
    dot: {
        height: 16,
        width: 16,
        borderRadius: 16
    },
    icon: {
        fontSize: 16,
        // color: '#26CC6F',
        height: '30px',
        width: '30px'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        '& tbody tr:hover': {
            cursor: 'pointer',
            outline: '3px solid black'
        },
        '& tbody td': {
            padding: 8
        }
    },

    ['rowColor' + [FUNERAL_HOME.RANKINS.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.RANKINS.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] },
        '&:hover': { outlineColor: theme.palette.contentForeground[FUNERAL_HOME.RANKINS.letterCode] + '!important' }
    },

    ['rowColor' + [FUNERAL_HOME.STAN_CRAP.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.STAN_CRAP.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] },
        '&:hover': { outlineColor: theme.palette.contentForeground[FUNERAL_HOME.STAN_CRAP.letterCode] + '!important' }
    },

    ['rowColor' + [FUNERAL_HOME.WOLLONGONG_CITY.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] },
        '&:hover': {
            outlineColor: theme.palette.contentForeground[FUNERAL_HOME.WOLLONGONG_CITY.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.EASY_FUNERALS.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode] + '!important',
        '& svg': { color: theme.palette.contentBackground[FUNERAL_HOME.EASY_FUNERALS.letterCode] },
        '&:hover': {
            outlineColor: theme.palette.contentForeground[FUNERAL_HOME.EASY_FUNERALS.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.PARSONS_LADIES.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.PARSONS_LADIES.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] },
        '&:hover': {
            outlineColor: theme.palette.contentForeground[FUNERAL_HOME.PARSONS_LADIES.letterCode] + '!important'
        }
    },

    ['rowColor' + [FUNERAL_HOME.H_PARSONS.letterCode]]: {
        color: theme.palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode],
        backgroundColor: theme.palette.contentBackground[FUNERAL_HOME.H_PARSONS.letterCode] + '!important',
        '& svg': { color: theme.palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] },
        '&:hover': { outlineColor: theme.palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode] + '!important' }
    }
});

export default compose(withSnackbarMessage, withTheme(), withStyles(styles))(RefereeDoctorList);
