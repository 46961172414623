import Drawer from '@material-ui/core/Drawer';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import Button from './extras/Button';
import TaskBucket from './TaskBucket';
import CloseIcon from './extras/CloseIcon';

export default function TemporaryDrawer({ tasks, labels, filters, setFilters, reloadBucket }) {
    const [state, setState] = React.useState({
        left: false
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return undefined;
        }
        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            role="presentation"
            className="workqueue-page"
            //onClick={toggleDrawer(side, false)}
            //onKeyDown={toggleDrawer(side, false)}
            style={{ maxWidth: '90vw' }}
        >
            <Button
                variant="primary icon-button white shadow"
                size="tiny"
                color="inherit"
                aria-label="close drawer"
                onClick={toggleDrawer('left', false)}
                style={{ position: 'absolute', right: 6, top: 6 }}
            >
                <CloseIcon />
            </Button>
            <TaskBucket
                className="sidebar-bucket"
                tasks={tasks}
                labels={labels}
                filters={filters}
                setFilters={setFilters}
                reloadBucket={reloadBucket}
            />
        </div>
    );

    return (
        <div className="view-header">
            <span>
                <Button
                    title="View Task Categories"
                    onClick={toggleDrawer('left', true)}
                    variant="quaternary icon-button shadow"
                >
                    <MoreHorizIcon />
                </Button>
                <h1
                    className="title"
                    style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 6, fontSize: '1.333em' }}
                >
                    Work Queue
                </h1>
            </span>
            <Drawer open={state.left} onClose={toggleDrawer('left', false)} variant="temporary">
                {state.left && sideList('left')}
            </Drawer>
        </div>
    );
}
