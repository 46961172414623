import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '../../component/form/Grid';
import { OutlineButton } from '../../component/form/PrimaryButton';
import TableData from '../../component/form/TableData';
import Spinner from '../../component/Spinner';
import Query from 'react-apollo/Query';
import QuotesSearch from './QuotesSearch';
import TickCircleIcon from '../../component/icon/TickCircleIcon';
import ExclamationCircleIcon from '../../component/icon/ExclamationCircleIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import cx from 'classnames';
import { createColumns, createTableData, getPats, searchEnquiriesQuery, searchEnquiryTotals } from './QuotesConstants';
import {
    getUserNotificationQuotesQuery,
    getUserNotificationsTabSegmentUrlQuery
} from '../workQueue/notifications/GetSaveNotification';
import { getUser } from '../../util/sessions';
import { dayDelta } from '../../util/date';
import { joinDefined } from '../../util/strings';
import { DATE_OF_DEATH_OPTION } from '../funeral/deceased/DeceasedConstants';
import { compose } from 'react-apollo';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import moment from 'moment';
import { getEnquiryClient, getMyClient, GQL_POLL_INTERVAL } from '../../apollo';
import { getFuneralHomeByKey } from '../../util/funerals';
import Hidden from '@material-ui/core/Hidden';
import BellIcon from '../../component/icon/BellIcon';

class Quotes extends Component {
    state = {
        searchKeyword: '',
        searchType: null,
        preneedType: null,
        searchOffice: null,
        searchByDate: null,
        searchBegin: null,
        searchUntil: null,
        sortField: 'Created',
        sortDirection: 'DESC',
        offset: 0,
        limit: 10,
        Released: null,
        Accepted: null,
        loadingTable: false
    };

    static getDerivedStateFromProps(props, { searchOffice }) {
        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get('office');
        if (searchOffice !== key) return { searchOffice: key };
        return null;
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span>Enquiries</span>
                    </InlineFieldRightAlignChildren>
                </Typography>
                <br />
                {this.renderColumn()}
            </Fragment>
        );
    }

    renderSummaries = ({ loading, error, data }, type) => {
        if (error) return <div>Error</div>;
        if (loading) return <div>Loading...</div>;

        const { myReleasedCount, myAcceptedCount, allCount } = data && data.searchEnquiries;
        const { classes } = this.props;
        if (type) {
            if (type === 'MyReleased') {
                return (
                    <Paper className={classes.paper} elevation={0} style={{ textAlign: 'center', height: '100%' }}>
                        <Typography>You're waiting on</Typography>
                        <div className={classes.countAmount}>
                            <span>{myReleasedCount}</span>
                        </div>
                        <div className={classes.countSubtitle}>Released</div>
                        <Typography>
                            quote{myReleasedCount === 1 ? '' : 's'}. {getPats(myReleasedCount)}
                        </Typography>
                    </Paper>
                );
            } else {
                return (
                    <Paper className={classes.paper} elevation={0} style={{ textAlign: 'center', height: '100%' }}>
                        <Typography>You have got</Typography>
                        <div className={classes.countAmount}>
                            <span>{myAcceptedCount}</span>
                        </div>
                        <div className={classes.countSubtitle}>Accepted</div>
                        <Typography>
                            quote{myAcceptedCount === 1 ? '' : 's'}. {getPats(myAcceptedCount)}
                        </Typography>
                    </Paper>
                );
            }
        }

        return (
            <Fragment>
                <span>{allCount}</span>
            </Fragment>
        );
    };

    renderEnquiryCounts = ({ loading, error, data }, classes, variables) => {
        if (error) return <Typography className={classes.message}>{error.message}</Typography>;
        if (!data) return;

        const { draftCount, releasedCount, acceptedCount, allCount } = (data && data.searchEnquiries) || {};

        return (
            <Fragment>
                <div
                    className={cx(
                        classes.panels,
                        (variables.released === null && variables.accepted === null && classes.panelsActive) || ''
                    )}
                >
                    <div onClick={e => this.onFilterSubmit()}>
                        Enquired
                        <span>{allCount || (!!loading ? <Spinner /> : '0')}</span>
                    </div>
                </div>
                <div className={classes.gap} />
                <div className={cx(classes.panels, (variables.released === false && classes.panelsActive) || '')}>
                    <div onClick={e => this.onFilterSubmit('Released', false)}>
                        Draft
                        <span>{draftCount || (!!loading ? <Spinner /> : '0')}</span>
                    </div>
                </div>
                <div className={classes.gap} />
                <div className={cx(classes.panels, (variables.released === true && classes.panelsActive) || '')}>
                    <div onClick={e => this.onFilterSubmit('Released', true)}>
                        Released
                        <span>{releasedCount || (!!loading ? <Spinner /> : '0')}</span>
                    </div>
                </div>
                <div className={classes.gap} />
                <div className={cx(classes.panels, (variables.accepted === true && classes.panelsActive) || '')}>
                    <div onClick={e => this.onFilterSubmit('Accepted', true)}>
                        Accepted
                        <span>{acceptedCount || (!!loading ? <Spinner /> : '0')}</span>
                    </div>
                </div>
            </Fragment>
        );
    };

    renderTable = ({ loading, error, data, networkStatus }) => {
        if (error) return <div>Error loading data</div>;

        if (networkStatus === 1) {
            return (
                <div>
                    Loading data, please wait a moment...
                    <LinearProgress />
                </div>
            );
        }

        const edges = data && data.searchEnquiries && data.searchEnquiries.edges;
        const total = data && data.searchEnquiries && data.searchEnquiries.pageInfo;

        if (!loading && (!edges || edges.length < 1))
            return (
                <div style={{ textAlign: 'center' }}>
                    No enquiries matched your search. Please try different keywords or filters.
                </div>
            );

        const { classes } = this.props;

        return (
            <Fragment>
                <div style={{ position: 'relative' }}>
                    {!!loading && (
                        <div style={{ position: 'absolute', background: '#ffffff99', width: '100%', height: '100%' }} />
                    )}
                    <div className={classes.tableContainer}>
                        <TableData columns={createColumns(edges, this)} data={createTableData(edges)} />
                    </div>
                    {((total.hasPreviousPage || total.hasNextPage) && (
                        <div style={{ textAlign: 'center', padding: 12 }}>
                            <OutlineButton
                                disabled={!total.hasPreviousPage}
                                onClick={e => this.setState({ offset: this.state.offset - this.state.limit })}
                            >
                                {'<'}
                                <Hidden xsDown> Prev</Hidden>
                            </OutlineButton>
                            <span style={{ margin: 'auto 16px', whiteSpace: 'pre', display: 'inline-block' }}>
                                {this.state.offset + 1}
                                {' - '}
                                {Math.min(this.state.offset + this.state.limit, total.totalCount)}
                            </span>
                            <OutlineButton
                                disabled={!total.hasNextPage}
                                onClick={e => this.setState({ offset: this.state.offset + this.state.limit })}
                            >
                                <Hidden xsDown>Next </Hidden>
                                {'>'}
                            </OutlineButton>
                        </div>
                    )) || <div style={{ textAlign: 'center', padding: 12, color: '#6669' }}>- End of results. -</div>}
                </div>
                {!!loading && <LinearProgress />}
            </Fragment>
        );
    };

    renderColumn() {
        const { classes } = this.props;
        const office = (this.state.searchOffice && getFuneralHomeByKey(this.state.searchOffice)) || null;

        const variables = {};
        variables.released = this.state.Released !== null ? this.state.Released : null;
        variables.accepted = this.state.Accepted !== null ? this.state.Accepted : null;
        variables.contains = this.state.searchKeyword;
        variables.enqType = this.state.searchType || null;
        variables.preneedType = this.state.preneedType || null;
        variables.officeID = (office && office.ID) || null;
        variables.limit = this.state.limit;
        variables.offset = this.state.offset;
        variables.sortBy = [{ field: this.state.sortField, direction: this.state.sortDirection }];
        variables.createdByUserID = getUser().ID;
        variables.filters = [];
        if (this.state.searchByDate) {
            if (this.state.searchUntil)
                variables.filters.push({
                    field: this.state.searchByDate + ':LessThanOrEqual',
                    value: this.state.searchUntil
                });
            if (this.state.searchBegin)
                variables.filters.push({
                    field: this.state.searchByDate + ':GreaterThan',
                    value: this.state.searchBegin
                });
        }

        return (
            <Grid container spacing={24}>
                <Grid item md={12} lg={8} xl={8} style={{ width: '100%' }}>
                    <Paper className={cx(classes.paper)} elevation={0} style={{ height: '100%', paddingTop: 0 }}>
                        <Query
                            query={searchEnquiryTotals}
                            variables={{
                                contains: this.state.searchKeyword,
                                enqType: variables.enqType,
                                preneedType: variables.preneedType,
                                officeID: variables.officeID,
                                filters: variables.filters
                            }}
                            pollInterval={1000 * 60}
                            client={getEnquiryClient()}
                        >
                            {results => this.renderEnquiryCounts(results, classes, variables)}
                        </Query>

                        <div style={{ clear: 'both' }} />

                        <QuotesSearch onSearchSubmit={this.onSearchSubmit} />

                        <div style={{ clear: 'both' }} />

                        <Query
                            pollInterval={1000 * 60}
                            fetchPolicy="cache-and-network"
                            query={searchEnquiriesQuery}
                            variables={variables}
                            client={getEnquiryClient()}
                        >
                            {results => this.renderTable(results)}
                        </Query>
                    </Paper>
                </Grid>
                <Grid item md={12} lg={4} xl={4} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {this.renderNotifications()}
                    <Grid container spacing={24} style={{ marginTop: '1rem' }}>
                        <Grid item xs={6}>
                            <Query
                                query={searchEnquiryTotals}
                                pollInterval={GQL_POLL_INTERVAL}
                                client={getEnquiryClient()}
                            >
                                {results => this.renderSummaries(results, 'MyReleased')}
                            </Query>
                        </Grid>
                        <Grid item xs={6}>
                            <Query
                                query={searchEnquiryTotals}
                                pollInterval={GQL_POLL_INTERVAL}
                                client={getEnquiryClient()}
                            >
                                {results => this.renderSummaries(results, 'MyAccepted')}
                            </Query>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderNotifications() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Grid container spacing={24} style={{ flexGrow: 1 }}>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <div
                            style={{
                                position: 'absolute',
                                top: 12,
                                bottom: 12,
                                left: 12,
                                right: 12,
                                overflow: 'hidden',
                                pointerEvents: 'none'
                            }}
                        >
                            <BellIcon
                                style={{
                                    opacity: 0.025,
                                    width: 300,
                                    height: 300,
                                    position: 'absolute',
                                    right: -24,
                                    bottom: 24,
                                    transform: 'rotate(20deg)',
                                    zIndex: 0
                                }}
                            />
                        </div>
                        <Paper
                            className={classes.paper}
                            elevation={0}
                            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                        >
                            <Typography variant="headline">Quote Notifications</Typography>
                            <div style={{ minHeight: 435, overflow: 'auto', flex: '1 1 0' }}>
                                <Query
                                    client={getMyClient()}
                                    pollInterval={30 * 1000}
                                    query={getUserNotificationsTabSegmentUrlQuery}
                                    variables={{
                                        filters: [
                                            { field: 'TabURLSegment:PartialMatch', value: 'quotes' },
                                            {
                                                field: 'Created:GreaterThan',
                                                value: moment()
                                                    .subtract(1, 'month')
                                                    .startOf('day')
                                            }
                                        ]
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading && !data.readNotifications) return <Spinner />;

                                        if (error) {
                                            console.error('failed to load notifications for quotes dashboard', error);
                                            return 'Sorry, unable to load notifications for you right now.';
                                        }

                                        if (!data.readNotifications || data.readNotifications.edges.length === 0)
                                            return 'There are currently no notifications.';

                                        const quotesFilter = data.readNotifications.edges.map(e => {
                                            return { field: 'ID', value: e.node.Quote.ID };
                                        });

                                        return this.renderQuotes(data.readNotifications.edges, quotesFilter);
                                    }}
                                </Query>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderQuotes(notifications, quotesFilter) {
        return (
            <Query
                client={getEnquiryClient()}
                query={getUserNotificationQuotesQuery}
                variables={{ filters: quotesFilter }}
            >
                {({ loading, error, data }) => {
                    if (loading && !data.readQuotes) return <Spinner />;
                    if (error) {
                        console.error('failed to load quotes for quotes dashboard notifications', error);
                        return 'Sorry, unable to load notifications for you right now.';
                    }
                    const readQuotes = (data && data.readQuotes) || null;
                    return notifications.map((notification, index) => {
                        const { Quote } = notification.node || null;
                        const quoteFull = Quote && !!readQuotes && readQuotes.edges.find(e => Quote.ID === e.node.ID);
                        return (
                            <Fragment key={index}>
                                {this.renderQuoteNotification(notification, (quoteFull && quoteFull.node) || null)}
                            </Fragment>
                        );
                    });
                }}
            </Query>
        );
    }

    renderQuoteNotification(notification, Quote) {
        const { classes } = this.props;
        const { Enquiry } = Quote || {};
        if (!Enquiry) return null;
        const me = getUser();
        const client = joinDefined([Enquiry.GivenName, Enquiry.MiddleName, Enquiry.Surname], ' ');
        const enquirer = joinDefined(
            [Enquiry.EnquirerGivenName, Enquiry.EnquirerMiddleName, Enquiry.EnquirerSurname],
            ' '
        );

        let deathDescription = '';
        if (
            Enquiry.DateOfDeathType === DATE_OF_DEATH_OPTION.APPROX.value ||
            Enquiry.DateOfDeathType === DATE_OF_DEATH_OPTION.ON.value ||
            Enquiry.DateOfDeathType === DATE_OF_DEATH_OPTION.ABOUT.value
        ) {
            const deathDaysDelta = dayDelta(Enquiry.DateOfDeath1, new Date());
            deathDescription = ` who died ${deathDaysDelta} day${deathDaysDelta === 1 ? '' : 's'} ago`;
        } else if (Enquiry.DateOfDeathType === DATE_OF_DEATH_OPTION.AFTER.value) {
            deathDescription = ` who died some time after ${Enquiry.DateOfDeath1}`;
        } else if (Enquiry.DateOfDeathType === DATE_OF_DEATH_OPTION.BETWEEN.value) {
            deathDescription = ` who died some time between ${Enquiry.DateOfDeath1} and ${Enquiry.DateOfDeath2}`;
        } else if (Enquiry.DateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value) {
            deathDescription = ` who died on an unknown date`;
        }

        let description;

        if (Quote.QuoteType === 'ATNEED') {
            description = `The quote is for a funeral for ${client}${deathDescription}.`;
        } else if (Quote.QuoteType === 'PHONE') {
            description = `This is a phone quote for ${enquirer}, regarding a funeral for ${client}${deathDescription}.`;
        } else if (Quote.QuoteType === 'PRENEED') {
            description = `The quote is for a funeral prearrangement for ${client}.`;
        } else if (Quote.QuoteType === 'PLAQUE') {
            description = `The quote is for a plaque for ${client}.`;
        } else {
            description = `It's not known what type of quote this is.`;
        }

        const your = !!(
            notification &&
            notification.node &&
            notification.node.AssignedMembers.find(e => e.ID === me.ID)
        )
            ? 'Your'
            : '';

        return (
            <Grid pc={1} style={{ margin: '1rem 0' }}>
                {Quote.Accepted ? (
                    <Fragment>
                        <Typography variant="caption">
                            <span className={classes.greatSuccess}>
                                <TickCircleIcon className={classes.littleIcon} /> Quote Accepted
                            </span>{' '}
                            &bull; {moment(Quote.RespondedDate).format('Do MMM YYYY [at] h:mma')}
                        </Typography>
                        <Typography variant="subheading" className={your ? classes.myBaby : null}>
                            {your} Quote #{Quote.ID} to {enquirer} was accepted!
                        </Typography>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Typography variant="caption">
                            <span className={classes.greatFailure}>
                                <ExclamationCircleIcon className={classes.littleIcon} /> Quote Rejected
                            </span>
                            &nbsp; &bull; {moment(Quote.RespondedDate).format('Do MMM YYYY [at] h:mma')}
                        </Typography>
                        <Typography variant="subheading" className={your ? classes.myBaby : null}>
                            {your} Quote #{Quote.ID} to {enquirer} was not accepted.
                        </Typography>
                    </Fragment>
                )}

                <Typography variant="caption">{description}</Typography>

                <OutlineButton
                    onClick={e =>
                        this.props.history.push(`/quotes/enquiry/${Quote.Enquiry.LegacyKey}/${Quote.Enquiry.ID}`)
                    }
                >
                    View enquiry
                </OutlineButton>
            </Grid>
        );
    }

    onSearchSubmit = ({
        searchKeyword,
        searchType,
        preneedType,
        searchOffice,
        searchByDate,
        searchBegin,
        searchUntil
    }) => {
        this.setState({
            searchKeyword,
            searchType,
            preneedType,
            searchOffice,
            searchByDate,
            searchBegin,
            searchUntil,
            offset: 0
        });
    };

    onFilterSubmit = (name, value) => {
        const newState = { Accepted: null, Released: null, offset: 0 };
        if (name) newState[name] = value;
        this.setState(newState);
    };
}

const styles = ({ breakpoints, palette, funeralHome }) => ({
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    paper: {
        //maxWidth: 1680,
        padding: 42,
        borderBottom: '3px solid ' + palette.contentForeground[funeralHome],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [breakpoints.down('md')]: {
            padding: 16
        }
    },
    countAmount: {
        '& span': { fontSize: '4rem' }
    },
    countSubtitle: {
        fontSize: '1.5rem',
        margin: '-1rem 0 1rem'
    },
    panels: {
        color: '#6669',
        textAlign: 'center',
        margin: '2rem 0 2rem',
        padding: '1rem 0',
        width: 'calc(25% - 2px)',
        float: 'left',
        '& span': {
            fontSize: '2.75rem',
            display: 'block'
        },
        '& div': {
            cursor: 'pointer'
        }
    },
    panelsActive: {
        color: palette.contentForeground[funeralHome],
        borderBottom: '3px solid ' + palette.contentForeground[funeralHome]
    },
    gap: {
        borderLeft: '1px solid #CCC',
        height: '3rem',
        float: 'left',
        marginTop: '3.5rem',
        width: 2
    },
    littleIcon: {
        fontSize: '18px',
        verticalAlign: 'text-bottom'
    },
    tableContainer: {
        [breakpoints.down('sm')]: {
            '& table tr > :nth-child(2n), & table tr > :nth-child(7)': {
                display: 'none'
            }
        }
    },
    greatSuccess: {
        color: palette.highlight.green
    },
    greatFailure: {
        color: palette.highlight.red
    },
    myBaby: {
        fontWeight: 'bolder'
    },
    icon: {
        color: palette.contentForeground[funeralHome]
    }
});

export default compose(withStyles(styles))(Quotes);
