import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import moment from 'moment';
import { cloneDeep } from 'apollo-utilities';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Hidden, Icon } from '@material-ui/core';
import { InlineField } from '../../../component/form/Inline';
import TextField from '../../../component/form/TextField';
import LinkButton from '../../../component/form/LinkButton';
import CheckableStar from '../CheckableStar';
import UserCircle, { GetInitials } from '../../../component/form/UserCircle';
import Grid from '../../../component/form/Grid';
import { isNullOrUndefined } from '../../../util/objects';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { joinDefined } from '../../../util/strings';
import GatedComponent from '../../../component/GatedComponent';
import { getUser } from '../../../util/sessions';
import FileUpload, { getFileName } from '../../../component/form/FileUpload';
import SaveIcon from '../../../component/icon/SaveIcon';
import Select from '../../../component/form/Select';
import Label from '../../../component/form/Label';
import FlexGrid from '../extras/FlexGrid';
import FileUploadCard from '../extras/FileUploadCard';
import { getTaskStatus, getTaskStatusClass, updateTaskFunc } from '../TaskConstants';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';
import TaskChecklist from '../TaskChecklist';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import PrimaryButton, { OutlineButton, SaveButton } from '../../../component/form/PrimaryButton';
import EditIcon from '../../../component/icon/EditIcon';
import DataFormView from '../../../component/form/v2/DataFormView';
import { taskTabs } from './TaskModal';
import { getMyClient } from '../../../apollo';

class ViewTaskModalDetail extends Component {
    state = {
        hideNotes: true,
        hideInfo: true,
        editMode: false,
        mutate: {
            ID: null
        }
    };

    static getDerivedStateFromProps({ task }, { mutate }) {
        if (task.node.ID !== mutate.ID) {
            const { node } = cloneDeep(task);
            return { mutate: node };
        }
        return null;
    }

    render() {
        const { classes, task, dragHandle } = this.props;
        if (!(task && !!task.node)) return null;
        const { mutate, hideInfo, editMode } = this.state;

        const convertDateToCheck = date => !isNullOrUndefined(date);
        const me = getUser();
        const muTask = mutate && mutate.AssignedMembers.find(e => !!e && e.ID === me.ID);
        const muStar = muTask && muTask.Join.Starred;
        const myTask = task && task.node.AssignedMembers.find(e => !!e && e.ID === me.ID);
        const myStar = myTask && myTask.Join.Starred;

        const array1 = [].concat(task.node.Attachments.map(t => Number(t.ID)));
        const array2 = [].concat(mutate.Attachments.map(m => Number(m.ID)));
        const dirtyFiles = !(
            array1.length === array2.length &&
            array1.sort().every(function(value, index) {
                return value === array2.sort()[index];
            })
        );
        const isDirty =
            !!mutate &&
            !!task &&
            !(
                !mutate.NewComment &&
                !!mutate.Completed === !!task.node.Completed &&
                !!mutate.InProgress === !!task.node.InProgress &&
                !!mutate.Archived === !!task.node.Archived &&
                !!muStar === !!myStar &&
                !dirtyFiles
            );

        return (
            <Fragment>
                <DialogTitle id={dragHandle} className={classes.diagHeader}>
                    <div className={classes.dragMe}>
                        <Icon>drag_indicator</Icon>
                    </div>
                    <div>
                        <div className={classes.diagHeaderTitle}>Task #{task.node.ID}</div>
                        <div className={classes.diagHeaderSubtitle}>{task.node.Title}</div>
                    </div>
                    <GatedComponent showComponentCode="FM_ACCESS_Tasks_View">
                        {() => {
                            return (
                                <CheckableStar
                                    className={classes.starButton}
                                    value={mutate && convertDateToCheck(muStar)}
                                    onChange={checked => {
                                        const AssignedMembers = mutate.AssignedMembers.map(e => {
                                            e.Join = {
                                                Starred:
                                                    e.ID === me.ID
                                                        ? checked
                                                            ? new Date()
                                                            : null
                                                        : e.Join && e.Join.Starred
                                            };
                                            return e;
                                        });
                                        const taskUpdate = { ...task.node, AssignedMembers };
                                        this.updateTask(taskUpdate);
                                    }}
                                />
                            );
                        }}
                    </GatedComponent>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <div className={classes.detailsEditRow}>
                        <LinkButton
                            variant="link"
                            text={hideInfo ? 'Show all Details ⮟' : 'Hide Details ⮝'}
                            onClick={() => this.setState({ hideInfo: !hideInfo })}
                        />
                        {(editMode && (
                            <PrimaryButton
                                size="small"
                                onClick={() => this.setState({ editMode: false })}
                                color="primary"
                            >
                                <EditIcon />
                                <Hidden>Edit Mode</Hidden>
                            </PrimaryButton>
                        )) || (
                            <OutlineButton size="small" onClick={() => this.onEdit()} color="primary">
                                <EditIcon />
                                <Hidden>Edit Task</Hidden>
                            </OutlineButton>
                        )}
                    </div>
                    {(editMode && (
                        <DataFormView
                            tabs={taskTabs}
                            objectType="Task"
                            name="Task"
                            context={this}
                            createNew={false}
                            itemId={task.node.ID}
                            client={getMyClient()}
                            classes={{
                                footerButtonsContainer: classes.saveButtonContainer,
                                saveButton: classes.saveButton
                            }}
                        />
                    )) || (
                        <Fragment>
                            {this.renderInfo(task)}
                            {task.node.ChecklistItems && task.node.ChecklistItems.length > 0 && (
                                <Grid container spacing={16} style={{ padding: 8, paddingTop: 0 }}>
                                    <Grid item pc>
                                        <InlineField>
                                            <Label>Checklist:</Label>
                                            <TaskChecklist task={task.node} canCreate={false} />
                                        </InlineField>
                                    </Grid>
                                </Grid>
                            )}
                            {this.renderActions(mutate)}
                        </Fragment>
                    )}
                </DialogContent>

                {!!mutate && !!task && !editMode && (
                    <DialogActions className={classes.diagFooter}>
                        <Button className={classes.cancelButton} size="small" onClick={this.onCancel}>
                            {!!isDirty ? 'Cancel' : 'Close'}
                        </Button>
                        <GatedComponent showComponentCode="FM_ACCESS_Tasks_Edit">
                            {() => {
                                return (
                                    <OutlineButton
                                        size="small"
                                        onClick={() => this.onShowWorkQueue()}
                                        color="primary"
                                        className="targetNewPage"
                                    >
                                        <Hidden xsDown>Show in </Hidden>Work Queue
                                    </OutlineButton>
                                );
                            }}
                        </GatedComponent>
                        {!!isDirty ? (
                            <SaveButton onClick={this.handleSubmit} className={classes.buttonSave}>
                                <SaveIcon />
                                <Hidden smDown>&nbsp;Save Changes</Hidden>
                            </SaveButton>
                        ) : (
                            <Button disabled size="small" className={classes.buttonSaved}>
                                <TickCircleIcon />
                                <Hidden smDown>&nbsp;Saved</Hidden>
                            </Button>
                        )}
                    </DialogActions>
                )}
            </Fragment>
        );
    }

    renderInfo(task) {
        const { classes } = this.props;
        const { hideInfo } = this.state;
        const overdue = task && moment() > moment(task.node.Due) && !task.node.Completed;
        const status = getTaskStatus(task.node);
        const statusClass = getTaskStatusClass(task.node);
        return (
            <Fragment>
                <Grid container spacing={16} style={{ padding: 8 }}>
                    <Grid item xs={12}>
                        <Label>Task Description: </Label>
                        <div className={classes.regular}>{task.node.Description}</div>
                    </Grid>
                    {task.node.Due && (
                        <InlineField>
                            <Label>Due: </Label>
                            <div className={overdue ? classes.redtext : classes.text}>
                                <div className={classes.regular}>{moment(task.node.Due).format('dddd ll [at] LT')}</div>
                            </div>
                        </InlineField>
                    )}
                    <InlineField>
                        <Label>Assigned Staff: </Label>
                        <div className={classes.regular}>
                            {joinDefined(
                                task.node.AssignedMembers.sort((a, b) => (a.Surname < b.Surname ? -1 : 1)).map(
                                    user => joinDefined([user.FirstName, user.Surname], ' ') || user.Email
                                ),
                                ', '
                            )}
                        </div>
                    </InlineField>
                    {!hideInfo && (
                        <Fragment>
                            <Grid item xs={12}>
                                <div className={classes.regular}>{task.node.Description}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <InlineField>
                                    <Label>Task ID: </Label>
                                    <div className={classes.regular}>#{task.node.ID}</div>
                                </InlineField>
                                {task.node.Due && (
                                    <InlineField>
                                        <Label>Due: </Label>
                                        <div className={overdue ? classes.redtext : classes.text}>
                                            <div className={classes.regular}>
                                                {moment(task.node.Due).format('dddd ll [at] LT')}
                                            </div>
                                        </div>
                                    </InlineField>
                                )}

                                {isRelatedObjectDefined(task.node.Funeral) && (
                                    <InlineField>
                                        <Label>Refers to: </Label>
                                        <div className={classes.regular}>
                                            <LinkButton
                                                variant="link"
                                                href={`/funeral/${task.node.Funeral.LegacyKey}/${task.node.Funeral.ID}`}
                                                text={'Funeral ' + task.node.Funeral.LegacyKey}
                                            />
                                            {' | '}
                                            {task.node.Funeral.NameOfDeceased}
                                        </div>
                                    </InlineField>
                                )}

                                {task.node.RequiredAction && (
                                    <InlineField>
                                        <Label>Message: </Label>
                                        <div className={classes.regular}>{task.node.RequiredAction}</div>
                                    </InlineField>
                                )}
                                <InlineField>
                                    <Label>Progress: </Label>
                                    <div className="wq-page" style={{ padding: 0 }}>
                                        <strong className={`task-status ${statusClass}`}> {status}</strong>
                                    </div>
                                </InlineField>
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderActions(mutate) {
        const { classes, task } = this.props;
        const { hideNotes } = this.state;
        const attachments = mutate.Attachments;
        let audits = [];
        if (task && task.node.Audits.edges.length > 0) {
            audits = task.node.Audits.edges.filter(e => e.node.Type === 0);
        }
        return (
            <Fragment>
                <Grid bucket>
                    <Grid item xs={12}>
                        <InlineField>
                            <Label>Set Progress:</Label>
                            <Select
                                required
                                value={
                                    !!mutate.Archived
                                        ? 'Archived'
                                        : !!mutate.Completed
                                        ? 'Completed'
                                        : !!mutate.InProgress
                                        ? 'InProgress'
                                        : ''
                                }
                                placeholder="To Do"
                                options={[
                                    { label: 'In Progress', value: 'InProgress' },
                                    { label: 'Completed', value: 'Completed' },
                                    { label: 'Archived', value: 'Archived' }
                                ]}
                                onChange={this.onTaskProgressChange}
                            />
                        </InlineField>
                    </Grid>
                    <Grid item xs={12}>
                        <Label>Upload an Attachment:</Label>
                        <FileUpload
                            buttonOnly
                            folderPath={`/documents/task/${task.node.ID}`}
                            onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile)}
                        />
                    </Grid>
                    {!!attachments && attachments.length > 0 && (
                        <Grid item xs={12}>
                            <Label>Attached Files:</Label>
                            <FlexGrid>
                                {attachments.map(attachment => (
                                    <FileUploadCard
                                        variant="mini"
                                        key={'attachment' + attachment.ID}
                                        fileName={attachment.Name}
                                        fileLink={attachment.AbsoluteLink}
                                    />
                                ))}
                            </FlexGrid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Label>Leave a Note:</Label>
                        <TextField
                            multiline
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter your notes here..."
                            onChange={e =>
                                this.setState({
                                    mutate: { ...mutate, NewComment: e.target.value }
                                })
                            }
                            value={mutate ? mutate.NewComment : null}
                        />
                        {audits.length > 0 && (
                            <span className={classes.toggleActivity}>
                                <LinkButton
                                    variant="link"
                                    text={hideNotes ? 'Show Previous Notes' : 'Hide Previous Notes'}
                                    onClick={() => this.setState({ hideNotes: !hideNotes })}
                                />
                            </span>
                        )}
                    </Grid>

                    {!hideNotes && (
                        <Grid item xs={12} className={classes.audit}>
                            {audits.filter(e => e.node.Type === 0).map(audit => this.renderAudit(audit))}
                        </Grid>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderAudit = audit => {
        const { classes } = this.props;
        return (
            <InlineField className={classes.auditItem} key={audit.node.ID}>
                <span>
                    <UserCircle
                        abbreviation={GetInitials(audit.node.AddedBy)}
                        user={joinDefined([audit.node.AddedBy.FirstName, audit.node.AddedBy.Surname], ' ')}
                    />
                </span>
                <span>
                    <span className={classes.auditDetails}>
                        {`Added notes on ${moment(audit.node.Created).format('ll [at] LT')}`}
                    </span>
                    <br />
                    <span className={classes.auditType}>{audit.node.Comment}</span>
                </span>
            </InlineField>
        );
    };

    updateTask(task) {
        const { setSnackbarMessage } = this.props;

        return updateTaskFunc(task).then(
            result => {
                const { updateTask } = cloneDeep(result.data);
                if (updateTask) {
                    setSnackbarMessage('Success, your changes have been saved.', true);
                    this.setState({ mutate: updateTask });
                } else {
                    setSnackbarMessage('Oops! Failed to reload the task.');
                }
            },
            e => setSnackbarMessage('Oops! Failed to update the task.', false, null, new Error(e))
        );
    }

    handleUploadAttachment(uploadFile) {
        const { mutate } = this.state;
        const { Attachments } = mutate;
        Attachments.push({
            ID: uploadFile.id,
            AbsoluteLink: uploadFile.url,
            Name: getFileName(uploadFile.filename)
        });
        this.setState({
            mutate
        });
    }

    handleSubmit = () => {
        const { mutate } = this.state;
        this.updateTask(mutate);
    };

    onTaskProgressChange = e => {
        const value = e.target.value;
        const { mutate } = this.state;
        mutate.Archived = value === 'Archived' ? new Date() : null;
        mutate.Completed = value === 'Completed' ? new Date() : null;
        mutate.InProgress = value === 'InProgress';
        this.setState({ mutate });
    };

    onShowWorkQueue() {
        const { task } = this.props;
        const win = window.open('/work-queue/task/' + task.node.ID);
        win.focus();
    }

    onEdit() {
        const { onEdit, task } = this.props;
        if (onEdit) onEdit(task);
        else {
            this.setState({ editMode: true, hideInfo: false });
        }
    }

    onCancel = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.clearState();
    };

    clearState() {
        const clearedState = Object.keys(this.state).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});
        this.setState(clearedState);
    }
}

const styles = ({ palette, breakpoints, spacing }) => ({
    text: {},
    redtext: { color: 'red' },
    buttonSave: {
        padding: '0.65em 1.5em'
    },
    buttonSaved: { color: palette.action.save },
    buttonClose: {},
    dragMe: {
        color: 'white',
        width: 32,
        marginLeft: -32,
        opacity: 0.5,
        flex: '0 0 auto!important'
    },
    diagHeader: {
        background: palette.primary.main,
        // borderRadius: '10px 10px 0 0',
        padding: '30px 44px',
        cursor: 'move',
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            '& div': {
                flex: '1 1 auto'
            }
        },
        position: 'sticky',
        zIndex: 1,
        top: 0
    },
    diagFooter: {
        position: 'sticky',
        zIndex: 1,
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 20px 6px'
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal',
        [breakpoints.down('xs')]: {
            fontSize: '24px'
        }
    },
    detailsEditRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    content: {
        maxHeight: 'calc(100vh - 260px)',
        overflow: 'auto',
        width: '100%',
        padding: '16px 36px 0',
        '& label': {
            minWidth: 112,
            marginRight: 16
        },
        [breakpoints.down('xs')]: {
            padding: spacing.unit
        }
    },
    starButton: {
        width: 'unset',
        height: 'unset',
        '& svg': {
            width: '2.5em',
            height: '2.5em'
        }
    },
    title: {
        fontSize: '36px',
        [breakpoints.down('xs')]: {
            fontSize: '24px'
        }
    },
    regular: {
        fontSize: '16px',
        fontWeight: 'lighter',
        whiteSpace: 'pre-wrap'
    },
    toggleActivity: {
        fontSize: '12px',
        float: 'right',
        marginTop: spacing.unit
    },
    userCircleCell: {
        width: '50px'
    },
    userCircle: {
        height: '35px',
        width: '35px',
        background: '#7BDBFF',
        borderRadius: '20px',
        fontSize: '15px',
        textAlign: 'center',
        color: '#FFFFFF',
        display: 'inline-block',
        '& > *': {
            lineHeight: '34px'
        }
    },
    auditType: {
        fontSize: '14px',
        whiteSpace: 'pre-wrap'
    },
    auditDetails: {
        fontSize: '13px',
        fontWeight: 'lighter'
    },
    auditItem: {
        // alignItems: 'baseline',
        margin: '8px 0',
        padding: 8,
        borderRadius: 6,
        '&:hover': { background: 'rgba(0, 0, 0, 0.05)' },
        '&:first-of-type': { marginTop: 0 },
        '& > span': { lineHeight: '16px!important' }
    },
    audit: {
        maxHeight: '250px',
        overflowX: 'hidden',
        overflowY: 'auto'
        // padding: '0 8px !important',
        // borderBottom: '1px solid ' + palette.contentForeground.none + '33',
        // marginBottom: 16
    },
    cancelButton: {
        color: palette.action.destroy,
        textDecoration: 'underline'
    }
});

export default compose(withStyles(styles), withSnackbarMessage)(ViewTaskModalDetail);
