import React, { Fragment } from 'react';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles/index';
import { compose } from 'react-apollo/index';
import { withRouter } from 'react-router';
import Hidden from '@material-ui/core/Hidden';
import CalendarFilters from './CalendarFilters';
import { withEditAppointment } from '../../context/EditAppointment';
import NextIcon from '../../component/icon/NextIcon';
import BackIcon from '../../component/icon/BackIcon';
import TextField from '../../component/form/TextField';
import { InlineFlex } from '../../component/form/Inline';

class DayToolbar extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.padToolbar}>
                <Hidden xsDown>
                    <div className="rbc-toolbar print-hide">
                        <span className="rbc-btn-group">{this.renderTypeSwitcher()}</span>
                        <span className="rbc-btn-group">{this.renderCalendarNavigationNative()}</span>
                        {this.renderAppointment()}
                        {this.renderFilters()}
                    </div>
                </Hidden>
                <Hidden smUp>
                    <div className="rbc-toolbar toolbar-2 print-hide">
                        <span className="rbc-btn-group calWidth" style={{ display: 'flex' }}>
                            {this.renderTypeSwitcher()}
                            {this.renderCalendarNavigationNative()}
                            {this.renderFilters()}
                        </span>
                    </div>
                </Hidden>
                <div className="print-show">
                    <h2>{this.showHeading()}</h2>
                </div>
            </div>
        );
    }

    renderTypeSwitcher() {
        const { props } = this;
        const { classes } = props;
        let viewNames = props.views;
        let view = props.view;
        let result = false;
        if (viewNames.length > 1) {
            result = viewNames.map(name => {
                return (
                    <button key={name} className={view === name ? 'rbc-active' : ''} onClick={e => this.view(name)}>
                        {name !== 'day' ? (
                            <span>
                                <Icon className={classes.icons}>place</Icon>

                                <Hidden smDown> Locations</Hidden>
                            </span>
                        ) : (
                            <span>
                                <Icon className={classes.icons}>person</Icon>

                                <Hidden smDown> Staff</Hidden>
                            </span>
                        )}
                    </button>
                );
            });
        }
        return result;
    }

    showHeading() {
        const { props } = this;
        return props.view !== 'day' ? props.label : moment(props.date).format('ddd, D MMM YYYY');
    }

    renderCalendarNavigationNative() {
        const { classes, date } = this.props;
        return (
            <Fragment>
                <Hidden xsDown>
                    <button type="button" onClick={this.navigate('PREV')} className="arrowBtn">
                        <BackIcon />
                    </button>
                </Hidden>
                <button
                    type="button"
                    onClick={this.navigate('TODAY')}
                    className={moment(date).isSame(new Date(), 'day') ? 'rbc-active today' : 'today'}
                >
                    Today
                </button>
                <button type="button" className="day" style={{ padding: 0, flex: 2 }}>
                    <InlineFlex nowrap>
                        <Hidden mdDown>
                            <span style={{ paddingLeft: 24, marginRight: -24 }}>{moment(date).format('dddd')},</span>
                        </Hidden>
                        <TextField
                            style={{ marginBottom: 0 }}
                            InputProps={{ classes: { root: classes.noBorder } }}
                            type={'date'}
                            value={moment(date).format('YYYY-MM-DD')}
                            onChange={e => this.handleDateChange(e.target.value)}
                        />
                    </InlineFlex>
                </button>
                <Hidden xsDown>
                    <button type="button" onClick={this.navigate('NEXT')} className="arrowBtn">
                        <NextIcon />
                    </button>
                </Hidden>
            </Fragment>
        );
    }

    renderFilters() {
        const { props } = this;
        return (
            <CalendarFilters
                setFilters={props.setFilters}
                filters={props.filters}
                view={props.view}
                resources={props.resources}
                groups={props.groups}
            />
        );
    }

    renderAppointment() {
        if (1 < 2) return null;
        return (
            <span className="rbc-btn-group">
                <button type="button" onClick={e => this.createNewAppointment()} className="ApptBtn padRight">
                    New Appointment
                </button>
            </span>
        );
    }

    handleDateChange = date => {
        const { props } = this;
        if (!date) date = moment();
        props.onNavigate('DATE', moment(date).toDate());
    };

    navigate = action => e => {
        e.preventDefault();
        const { props } = this;
        props.onNavigate(action);
    };

    view = view => {
        const { props } = this;
        props.onView(view);
    };

    createNewAppointment() {
        const { props } = this;
        if (props.createNewAppointment) props.createNewAppointment();
    }
}

const styles = ({ spacing, breakpoints, palette, funeralHome }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    padToolbar: {
        marginBottom: spacing.unit * 3,
        [breakpoints.down('xs')]: {
            marginBottom: spacing.unit
        }
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome],
        display: 'flex',
        alignItems: 'center'
    },
    popover: {
        padding: '0.1rem 1.5rem 1.5rem',
        width: '400px',
        maxWidth: '100%',
        fontSize: '90%',
        backgroundColor: '#fafafa'
    },
    bulkbuttons: {
        border: '1px solid #35327c',
        borderRadius: '5rem',
        fontSize: '75%',
        backgroundColor: 'white',
        marginRight: '0.5rem',
        color: '#35327c',
        textTransform: 'none'
    },
    icons: {
        fontSize: '18px',
        margin: '-4px 0'
    },
    padRight: {
        marginRight: '10px'
    },
    noBorder: {
        border: 0,
        padding: '0 0.88rem'
    }
});

export default compose(withRouter, withEditAppointment, withStyles(styles))(DayToolbar);
