import Optionals from './Optionals';
import OptionalsFragment from './OptionalsFragment';
import {
    cancelAllBookings,
    flattenBookableObjects,
    isRelatedObjectDefined,
    prepareBookingsForSave,
    prepareRefreshmentsForSave,
    prepareServiceForSave
} from '../../../util/bookable';
import { flattenCateringStaffAllocations, flattenStaffAllocations } from '../../../util/functions';
import { joinValidationResults, messages, validationHelper } from '../../../util/validation';
import { stringIsNullOrEmpty } from '../../../util/strings';
import { isNullOrUndefined } from '../../../util/objects';
import { isRelatedObjectUndefined } from '../../../util/graphql';

export default {
    label: 'Optionals',
    component: Optionals,
    fragment: OptionalsFragment,
    onLoad: data => {
        flattenBookableObjects(
            ['Florists', 'PersonalisedTouches', 'MemorialStationary', 'NewspaperNotices', 'Caterers'],
            data
        );
        if (data.Florists && data.Florists.length > 0 && data.Florists.filter(e => !e.Cancelled).length > 0)
            data.FlowersRequired = true;
        if (data.RefreshmentsVenue) {
            flattenStaffAllocations(data.RefreshmentsVenue);
            flattenCateringStaffAllocations(data.RefreshmentsVenue);
            if (!data.RefreshmentsVenue.Type)
                data.RefreshmentsVenue.Type = isRelatedObjectDefined(data.RefreshmentsVenue, 'Location')
                    ? 'Venue'
                    : (!!data.RefreshmentsVenue.OffsiteAddressLine1 && 'Offsite') || 'Not Required';
        }

        if (data.PlaceOfService) {
            flattenStaffAllocations(data.PlaceOfService);
        }
    },
    formatSaveData: (saveData, state) => {
        // Clear the bookable items when item set to not required
        if (state.FlowersRequired === false && state.Florists && state.Florists.length)
            saveData.Florists = cancelAllBookings(state.Florists);
        if (
            state.RefreshmentsVenue &&
            (state.RefreshmentsVenue.Type === 'Not Required' || state.RefreshmentsVenue.CateringRequired === false) &&
            state.Caterers &&
            state.Caterers.length
        )
            saveData.Caterers = cancelAllBookings(state.Caterers);
        if (state.NewspaperNoticeRequired === false && state.NewspaperNotices && state.NewspaperNotices.length)
            saveData.NewspaperNotices = cancelAllBookings(state.NewspaperNotices);
        if (state.MemorialStationaryBy !== 'Supplier' && state.MemorialStationary && state.MemorialStationary.length)
            saveData.MemorialStationary = cancelAllBookings(state.MemorialStationary);

        const items = ['Florists', 'NewspaperNotices', 'MemorialStationary', 'Caterers', 'PersonalisedTouches'];

        items.forEach(item => {
            if (saveData[item] && saveData[item].length) {
                saveData[item] = prepareBookingsForSave(saveData[item]);
            }
        });

        if (saveData.RefreshmentsVenue) {
            prepareRefreshmentsForSave(saveData, state);
        }
        if (saveData.PlaceOfService) {
            prepareServiceForSave(saveData, state);
        }
    },

    validation: {
        required: [],
        suggested: [],
        onValidate: {
            'RefreshmentsVenue.Location': (newValue, persistedValue, hasValue, getField) => {
                if (getField('RefreshmentsVenue.Type') !== 'Venue' || isRelatedObjectDefined(persistedValue))
                    return validationHelper.ok();

                return validationHelper.required();
            },

            'RefreshmentsVenue.OffsiteAddressLine1': (newValue, persistedValue, hasValue, getField) => {
                if (getField('RefreshmentsVenue.Type') !== 'Offsite' || !stringIsNullOrEmpty(persistedValue))
                    return validationHelper.ok();

                return validationHelper.required();
            },

            'RefreshmentsVenue.Time': (newValue, persistedValue, hasValue, getField) => {
                if (getField('RefreshmentsVenue.Type') === 'Not Required' || !isNullOrUndefined(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'RefreshmentsVenue.Duration': (newValue, persistedValue, hasValue, getField) => {
                if (getField('RefreshmentsVenue.Type') === 'Not Required' || !isNullOrUndefined(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'RefreshmentsVenue.NumberOfPeople': (newValue, persistedValue, hasValue, getField) => {
                if (getField('RefreshmentsVenue.Type') === 'Not Required' || !isNullOrUndefined(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'RefreshmentsVenue.Date': (newValue, persistedValue, hasValue, getField) => {
                if (getField('RefreshmentsVenue.Type') === 'Not Required' || !isNullOrUndefined(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            NewspaperNoticeRequired: (newValue, persistedValue, hasValue, getField) => {
                const notices = getField('NewspaperNotices') || [];
                if (!persistedValue || notices.length > 0) return validationHelper.ok();

                return validationHelper.required(messages.atLeastOne('Newspaper Notice'));
            },

            FlowersRequired: (newValue, persistedValue, hasValue, getField) => {
                const florists = getField('Florists') || [];
                if (!persistedValue || florists.length > 0) return validationHelper.ok();

                return validationHelper.required(messages.atLeastOne('Florist and Flowers'));
            },

            'RefreshmentsVenue.CateringRequired': (newValue, persistedValue, hasValue, getField) => {
                const florists = getField('Caterers') || [];
                const venue = getField('RefreshmentsVenue.Type');
                if (!persistedValue || florists.length > 0) return validationHelper.ok();
                if (!venue || venue === 'Not Required') return validationHelper.ok();

                return validationHelper.required(messages.atLeastOne('Catering Menu'));
            },

            MemorialStationaryBy: (newValue, persistedValue, hasValue, getField) => {
                const stationary = getField('MemorialStationary') || [];
                if (persistedValue !== 'Supplier' || stationary.length > 0) return validationHelper.ok();

                return validationHelper.required(messages.atLeastOne('Memorial Stationery'));
            },

            Florists: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('FlowersRequired')) return validationHelper.ok();

                const validationResults = [];
                (persistedValue || []).forEach((florist, index) => {
                    const invalidFields = [];

                    if (isRelatedObjectUndefined(florist.Contact)) {
                        invalidFields.push(`Florists[${index}].Contact`);
                    }

                    if (invalidFields.length > 0)
                        validationResults.push(validationHelper.required(messages.required, invalidFields));
                });

                return joinValidationResults(validationResults);
            }
        }
    }
};
