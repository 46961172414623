import Collapse from '@material-ui/core/Collapse';
import { IconButton } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from './extras/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { getUser } from '../../util/sessions';
import { joinDefined } from '../../util/strings';
import Spinner from '../../component/Spinner';
import AddLabel from './AddLabel';
import { TASK_CATEGORY_OPTIONS } from './TaskConstants';

export default withStyles({})(({ className, tasks, labels, filters, setFilters, reloadBucket }) => {
    const [state, setState] = React.useState({ [filters.type]: true });
    const me = getUser();

    const handleClick = (e, id) => {
        e.stopPropagation();
        const was = !!state[id] || false;
        setState({ [id]: !was });
    };

    const teamTasks = tasks && tasks.filter(e => e.AssignedTeams.length);

    const renderTeamTasks = allTeams => {
        if (!teamTasks) return null;
        const myTeams = allTeams || [];
        myTeams.forEach(team => {
            team.Count = teamTasks.filter(task => {
                return !!task.AssignedTeams.find(group => group.ID === team.ID);
            }).length;
            team.Members.forEach(member => {
                member.Count = tasks.filter(task => {
                    return !!task.AssignedMembers.find(person => person.ID === member.ID);
                }).length;
            });
        });

        return (
            <List component="nav" aria-labelledby="nested-list-subheader">
                {myTeams.map(team => {
                    return (
                        <Fragment key={'team' + team.ID}>
                            <ListItem
                                selected={filters.type === 'team' && filters.value === team.ID}
                                button
                                onClick={() => setFilters('team', team.ID, `All Tasks for ${team.Title}`)}
                            >
                                <ListItemText primary={team.Title} />
                                <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                    {team.Count}
                                </p>
                                {team.Members.length > 0 && (
                                    <IconButton onClick={e => handleClick(e, 'team' + team.ID)} className="nestedButt">
                                        {state['team' + team.ID] ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                )}
                            </ListItem>
                            <Collapse
                                in={state['team' + team.ID]}
                                timeout="auto"
                                unmountOnExit
                                className="task-bucket-list-nested-container"
                            >
                                <List component="div" disablePadding>
                                    {team.Members.map(member => (
                                        <ListItem
                                            selected={filters.type === 'member' && filters.value === member.ID}
                                            button
                                            key={'member' + member.ID}
                                            onClick={() =>
                                                setFilters(
                                                    'member',
                                                    member.ID,
                                                    `All Tasks for ${joinDefined(
                                                        [member.FirstName, member.Surname],
                                                        ' '
                                                    )}`
                                                )
                                            }
                                        >
                                            <ListItemText
                                                primary={joinDefined([member.FirstName, member.Surname], ' ')}
                                            />
                                            <p
                                                className="tasknumber"
                                                title="This is the number of tasks assigned to you."
                                            >
                                                {member.Count}
                                            </p>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </Fragment>
                    );
                })}
            </List>
        );
    };

    const starredTasks =
        tasks &&
        tasks.filter(e => e.AssignedMembers.find(person => person.ID === me.ID && !!person.Join.Starred)).length;
    const startedTasks = tasks && tasks.filter(e => !!e.InProgress).length;
    const todoTasks = tasks && tasks.filter(e => !e.InProgress).length;
    const recentTasks = tasks && tasks.filter(e => moment().diff(e.Created, 'days') < 7).length;

    const categoryTasks = TASK_CATEGORY_OPTIONS.filter(e => ['Funeral', 'EnquiryFM'].indexOf(e.value) > -1).map(e => {
        e.items = [];
        return e;
    });

    tasks &&
        tasks.forEach(task => {
            categoryTasks.forEach(type => {
                if ((!!task[type.value] && Number(task[type.value].ID) > 0) || type.value === task.Category) {
                    const hasMe = type.items.find(obj => obj.key === task[type.value].LegacyKey);
                    if (hasMe) {
                        hasMe.count++;
                    } else {
                        type.items.push({
                            key: task[type.value].LegacyKey || 'unknown',
                            ID: task[type.value].ID,
                            count: 1
                        });
                    }
                }
            });
        });
    const goalTasks = tasks && tasks.filter(e => e.Category === 'Goal').length;
    const customTasks =
        tasks.length - goalTasks - categoryTasks.reduce((c, i) => c + i.items.reduce((t, e) => t + e.count, 0), 0);

    const myLabels =
        (labels &&
            labels.map(e => {
                return { ID: e.ID, title: e.Title, count: 0 };
            })) ||
        [];
    tasks.forEach(task => {
        const labels = task.AssignedLabels.filter(label => label.Member.ID === me.ID);
        if (labels) {
            labels.forEach(label => {
                const hasMe = myLabels.find(obj => obj.ID === label.ID);
                if (hasMe) hasMe.count++;
                //else myLabels.push({ ID: label.ID, title: label.Title, count: 1 });
            });
        }
    });

    const overdueTasks = tasks && tasks.filter(e => moment().diff(e.Due, 'days') > 0).length;

    return (
        <div className={`task-bucket clearfix ${className}`}>
            <div className="flex-me-babies" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className="flex-me-scroll" style={{ overflow: 'auto', margin: '-1.5em', padding: '1.5em' }}>
                    <div className="teams">
                        <h3>Your Tasks</h3>
                        <List component="nav" aria-labelledby="nested-list-subheader">
                            <ListItem
                                button
                                onClick={() => setFilters('all', null, 'All Your Tasks')}
                                selected={filters.type === 'all' && !filters.value}
                            >
                                <ListItemText primary="All Your Tasks" />
                                <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                    {tasks && tasks.length}
                                    {tasks && tasks.length > 99 ? '+' : ''}
                                </p>
                                <IconButton onClick={e => handleClick(e, 'all')} className="nestedButt">
                                    {state['all'] ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </ListItem>
                            <Collapse
                                in={state['all']}
                                timeout="auto"
                                unmountOnExit
                                className="task-bucket-list-nested-container"
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        onClick={() => setFilters('inprogress', false, 'Your To Do Tasks')}
                                        selected={filters.type === 'inprogress' && !filters.value}
                                    >
                                        <ListItemText primary="To Do" />
                                        <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                            {todoTasks}
                                        </p>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() => setFilters('inprogress', true, 'Your In Progress Tasks')}
                                        selected={filters.type === 'inprogress' && !!filters.value}
                                    >
                                        <ListItemText primary="In Progress" />
                                        <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                            {startedTasks}
                                        </p>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() => setFilters('all', 'done', 'Your Completed Tasks')}
                                        selected={filters.type === 'all' && filters.value === 'done'}
                                    >
                                        <ListItemText primary="Completed" />
                                        <p className="tasknumber">&nbsp;</p>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() => setFilters('all', 'gone', 'Your Archived Tasks')}
                                        selected={filters.type === 'all' && filters.value === 'gone'}
                                    >
                                        <ListItemText primary="Archived" />
                                        <p className="tasknumber">&nbsp;</p>
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem
                                button
                                onClick={() => setFilters('overdue', null, 'Your Overdue Tasks')}
                                selected={filters.type === 'overdue'}
                            >
                                <ListItemText primary="Overdue" />
                                <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                    {overdueTasks}
                                </p>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => setFilters('new', null, 'Your New Tasks')}
                                selected={filters.type === 'new'}
                            >
                                <ListItemText primary="New" />
                                <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                    {recentTasks}
                                </p>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => setFilters('starred', null, 'Your Starred Tasks')}
                                selected={filters.type === 'starred'}
                            >
                                <ListItemText primary="Starred" />
                                <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                    {starredTasks}
                                </p>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => setFilters('custom', null, 'Your Custom Tasks')}
                                selected={filters.type === 'custom'}
                            >
                                <ListItemText primary="Custom Tasks" />
                                <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                    {customTasks}
                                </p>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => setFilters('goal', null, 'Your Goals')}
                                selected={filters.type === 'goal'}
                            >
                                <ListItemText primary="Goals" />
                                <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                    {goalTasks}
                                </p>
                            </ListItem>
                        </List>
                    </div>
                    <div className="teams">
                        <h3>Shared Tasks</h3>
                        <List component="nav" aria-labelledby="nested-list-subheader">
                            {categoryTasks.map(task => (
                                <Fragment key={task.value}>
                                    <ListItem
                                        button
                                        onClick={() => setFilters(task.value, null, `All Tasks for ${task.label}`)}
                                        selected={filters.type === task.value && !filters.value}
                                    >
                                        <ListItemText primary={task.label} />
                                        <p className="tasknumber" title="This is the number of tasks assigned to you.">
                                            {task.items && task.items.reduce((t, e) => t + e.count, 0)}
                                        </p>
                                        {task.items.length > 0 && (
                                            <IconButton
                                                onClick={e => handleClick(e, task.value)}
                                                className="nestedButt"
                                            >
                                                {state[task.value] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        )}
                                    </ListItem>
                                    <Collapse
                                        in={state[task.value]}
                                        timeout="auto"
                                        unmountOnExit
                                        className="task-bucket-list-nested-container"
                                    >
                                        <List component="div" disablePadding>
                                            {task.items.map(item => (
                                                <ListItem
                                                    selected={filters.type === task.value && filters.value === item.ID}
                                                    button
                                                    key={task.value + item.ID}
                                                    onClick={() =>
                                                        setFilters(
                                                            task.value,
                                                            item.ID,
                                                            `All Tasks for ${task.labelOne} ${item.key}`
                                                        )
                                                    }
                                                >
                                                    <ListItemText primary={`${task.labelOne} ${item.key}`} />
                                                    <p
                                                        className="tasknumber"
                                                        title="This is the number of tasks assigned to you."
                                                    >
                                                        {item.count}
                                                    </p>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </Fragment>
                            ))}
                        </List>
                    </div>
                    <div className="teams">
                        <h3>Team Tasks</h3>
                        <Query
                            query={gql`
                                query ReadAllTeams {
                                    readGroups(parentGroupCode: "fm-user") {
                                        ID
                                        Title
                                        Code
                                        Members {
                                            ID
                                            FirstName
                                            Surname
                                        }
                                    }
                                }
                            `}
                        >
                            {({ data, loading }) => {
                                if (loading) return <Spinner />;
                                const allGroups = data && data.readGroups;
                                return renderTeamTasks(allGroups.filter(e => e.Code !== 'fm-witness'));
                            }}
                        </Query>
                    </div>
                </div>

                <div
                    className="flex-me-fixed"
                    style={{
                        maxHeight: '33vh',
                        overflow: 'auto',
                        flexShrink: 0,
                        margin: '-1.5em',
                        padding: '1.5em',
                        marginTop: '1.5em'
                    }}
                >
                    <div className="labels" style={{ marginBottom: '-0.5em' }}>
                        <AddLabel
                            LabelList={myLabels || []}
                            filters={filters}
                            setFilters={setFilters}
                            reloadBucket={reloadBucket}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
