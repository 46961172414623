import React, { Component } from 'react';
import { IconButton, InputAdornment, Typography, withStyles } from '@material-ui/core';
import ModalStepTitle from '../../../component/form/ModalStepTitle';
import TableData from '../../../component/form/TableData';
import Checkbox from '../../../component/form/Checkbox';
import TextField from '../../../component/form/TextField';
import { prettyPrice } from '../../../util/strings';
import LineItemAutoComplete from '../../../component/form/LineItemAutoComplete';
import { InlineField } from '../../../component/form/Inline';
import TickIcon from '../../../component/icon/TickIcon';
import CloseIcon from '../../../component/icon/CloseIcon';
import { GST, price_amendment_range } from '../../../util/products';
import { isNullOrUndefined, round } from '../../../util/objects';
import GatedComponent from '../../../component/GatedComponent';
import Grid from '../../../component/form/Grid';
import { isRelatedObjectUndefined } from '../../../util/graphql';
import { lookupItemSource } from './QuotesModal';

class QuotesModalAdjustTab extends Component {
    state = {
        gstPrices: []
    };

    static getDerivedStateFromProps = ({ form }, { gstPrices }) => {
        // populate gst prices, if not already present
        if (!!form && !!form.fields) {
            const quote = form.getField('quote');
            if (!!quote && gstPrices.length === 0) return { gstPrices: quote.QuoteItems.map(x => getPriceIncGst(x)) };
        }
        return null;
    };

    render() {
        const { classes, form } = this.props;
        const quote = form.getField('quote');

        return (
            <div className={classes.root}>
                <div className={classes.modalHeader}>
                    <ModalStepTitle number="Two" title="Please review the quote details" />
                    <Typography variant="title">
                        {!!quote && !!quote.QuoteItems.length ? (
                            <InlineField>
                                <Grid item xs={12} sm={7}>
                                    <TextField
                                        label={'Quote title'}
                                        onChange={e => {
                                            const newState = { quote };
                                            newState.quote.Title = e.target.value;
                                            form.setField(newState);
                                        }}
                                        value={quote.Title}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <LineItemAutoComplete
                                        variations
                                        onSelect={item => {
                                            item.Complimentary = false;
                                            item.Optional = false;
                                            item.Prepaid = false;
                                            const source = lookupItemSource(item);
                                            item.Price = source.Cost;
                                            const items = { quote };
                                            items.quote.QuoteItems.push(item);
                                            form.setField(items);
                                            this.setState({
                                                gstPrices: items.quote.QuoteItems.map(x => getPriceIncGst(x))
                                            });
                                        }}
                                    />
                                </Grid>
                            </InlineField>
                        ) : (
                            'Please select a quote first.'
                        )}
                    </Typography>
                </div>
                {!isNullOrUndefined(quote) && quote.QuoteItems.length > 0 && (
                    <GatedComponent isEnabledCode={'ADMIN'}>
                        {canSetAnyPrice => this.renderAdjustmentList(canSetAnyPrice)}
                    </GatedComponent>
                )}
            </div>
        );
    }

    reformatQuoteItem = (quoteItem, gstPrice) => {
        const item = {};
        item.ID = '' + quoteItem.ID;
        item.Qty = Number(quoteItem.Qty).toFixed(0);
        item.Complimentary = !!quoteItem.Complimentary;
        item.Optional = !!quoteItem.Optional;
        item.Prepaid = !!quoteItem.Prepaid;
        item.Price = Number(gstPrice);
        item.Code = 0;
        const source = lookupItemSource(quoteItem);
        item.Cost = source.Cost;
        item.Code = source.Code;
        item.Title = source.Title;
        item.NeedGST = source.GST;
        if (!!quoteItem.Title) item.Title = quoteItem.Title;
        return { ...item };
    };

    convertGST = (rawPrice, needGST = false) => {
        // convert prices to GST
        if (!needGST) return rawPrice;
        const costExcGST = Number(rawPrice || 0);
        return costExcGST * (GST + 1);
    };

    renderAdjustmentList(canSetAnyPrice) {
        const { classes, form } = this.props;
        const quote = form.getField('quote');
        const { gstPrices } = this.state;
        const data = quote.QuoteItems.map((item, index) => {
            const e = this.reformatQuoteItem(item, gstPrices[index]);
            const displayData = {
                data: {
                    Code: e.Code,
                    Description: e.Title,
                    Cost: Number(e.Cost || 0),
                    Price: Number(e.Price || 0),
                    Qty: Number(e.Qty),
                    GST: !!e.NeedGST ? Number(e.Qty) * (Number(e.Price || 0) / (GST * 100 + 1)) : false,
                    NeedGST: !!e.NeedGST,
                    Optional: !!e.Optional,
                    Prepaid: !!e.Prepaid,
                    Complimentary: !!e.Complimentary
                }
            };

            displayData.data = QuotesModalAdjustTab.reCalcItem(displayData.data);

            return displayData.data;
        });

        const columns = [
            {
                id: 'Code',
                label: 'Product Code',
                render: (val, row, rowIndex) => {
                    return (
                        <span>
                            <span>{val}</span>
                            {this.props.canEdit && (
                                <IconButton
                                    className={classes.iconButton}
                                    title={'Delete'}
                                    onClick={() => this.onDeleteLineItem(rowIndex)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>
                            )}
                        </span>
                    );
                }
            },
            { id: 'Description', label: 'Description of service' },
            {
                id: 'Cost',
                label: 'Cost',
                render: (val, row) => {
                    return prettyPrice(this.convertGST(val, row.NeedGST));
                },
                styles: { textAlign: 'right' }
            },
            {
                id: 'Price',
                label: 'Price',
                render: (val, row, rowIndex) => {
                    const cost = round(this.convertGST(data[rowIndex].Cost, row.NeedGST), 2);
                    const minPrice = round(
                        canSetAnyPrice ? -99999 : Math.max(cost - price_amendment_range, cost < 0 ? -99999 : 0),
                        2
                    );
                    const maxPrice = round(canSetAnyPrice ? +99999 : cost < 0 ? 0 : cost + price_amendment_range, 2);
                    const value = gstPrices[rowIndex];

                    const onUpdate = e => {
                        const newValue = { gstPrices: gstPrices };
                        if (isNaN(Number(e.target.value))) {
                            newValue.gstPrices[rowIndex] = 0;
                        } else {
                            newValue.gstPrices[rowIndex] = Number(e.target.value);
                        }
                        if (document.activeElement === e.target) {
                            // still typing new price, don't interfere with quote
                            newValue.gstPrices[rowIndex] = e.target.value;
                            this.setState(newValue);
                        } else {
                            // not typing, so massage new price and update quote
                            const newState = { quote: { ...quote } };
                            if (newValue.gstPrices[rowIndex] > maxPrice) {
                                newValue.gstPrices[rowIndex] = maxPrice;
                            } else if (newValue.gstPrices[rowIndex] < minPrice) {
                                newValue.gstPrices[rowIndex] = minPrice;
                            }
                            newState.quote.QuoteItems[rowIndex].Price =
                                '' +
                                (!!row.NeedGST
                                    ? newValue.gstPrices[rowIndex] / (GST + 1)
                                    : newValue.gstPrices[rowIndex]
                                ).toFixed(4); // base price is 4 decimals

                            form.setField(newState);
                            this.setState(newValue);
                        }
                    };

                    return (
                        <TextField
                            InputProps={{
                                startAdornment: <InputAdornment position={'start'}>$</InputAdornment>
                            }}
                            onChange={onUpdate}
                            onBlur={onUpdate}
                            style={{ width: 128, margin: '-8px 0' }}
                            type="number"
                            max={maxPrice}
                            min={minPrice}
                            value={value}
                        />
                    );
                },
                styles: { width: 1 }
            },
            {
                id: 'Qty',
                label: 'Quantity',
                render: (val, row, rowIndex) => {
                    return (
                        (this.props.canEdit && (
                            <TextField
                                onChange={e => {
                                    const newState = { quote: { ...quote } };
                                    newState.quote.QuoteItems[rowIndex].Qty = Number((1 * e.target.value).toFixed(2));
                                    form.setField(newState);
                                }}
                                type="number"
                                style={{ width: 55, margin: '-8px 0' }}
                                InputProps={{
                                    inputProps: { min: 0, max: 100 }
                                }}
                                value={data[rowIndex] ? data[rowIndex].Qty : null}
                            />
                        )) ||
                        val
                    );
                },
                styles: { textAlign: 'right', width: 1 }
            },
            {
                id: 'SubTotal',
                label: 'Subtotal (exGST)',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5.5rem' }
            },
            {
                id: 'GST',
                label: 'GST',
                render: (val, row, rowIndex) =>
                    row.NeedGST
                        ? prettyPrice(!!row.Complimentary || !!row.Optional || !!row.Prepaid || !val ? 0 : val)
                        : 'N/A',
                styles: { textAlign: 'right', width: '5rem' }
            },
            {
                id: 'Total',
                label: 'Total',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5.5rem' }
            },
            {
                id: 'Prepaid',
                label: 'Prepaid',
                render: (val, row, rowIndex) => {
                    if (this.props.canEdit)
                        return (
                            <Checkbox
                                value={'' + val}
                                checked={data[rowIndex] ? data[rowIndex].Prepaid : false}
                                onChange={e => {
                                    const newState = { quote: { ...quote } };
                                    const newVal = !!e.target.checked;
                                    newState.quote.QuoteItems[rowIndex].Prepaid = newVal;
                                    form.setField(newState);
                                }}
                            />
                        );
                    return val ? <TickIcon color={'primary'} /> : <CloseIcon color={'disabled'} />;
                },

                styles: { textAlign: 'center', width: 1 }
            },
            {
                id: 'Complimentary',
                label: 'Complimentary',
                render: (val, row, rowIndex) => {
                    if (this.props.canEdit)
                        return (
                            <Checkbox
                                value={'' + val}
                                checked={data[rowIndex] ? data[rowIndex].Complimentary : false}
                                onChange={e => {
                                    const newValue = { gstPrices: gstPrices };
                                    const newState = { quote: { ...quote } };
                                    const newVal = !!e.target.checked;
                                    newState.quote.QuoteItems[rowIndex].Complimentary = newVal;
                                    if (newVal) {
                                        newState.quote.QuoteItems[rowIndex].Price = '0.00';
                                        newValue.gstPrices[rowIndex] = 0;
                                    } else {
                                        const basePrice = Number(row.Cost || 0);
                                        newState.quote.QuoteItems[rowIndex].Price = '' + basePrice.toFixed(4);
                                        newValue.gstPrices[rowIndex] = Number(
                                            this.convertGST(row.Cost || 0, !!row.NeedGST).toFixed(2)
                                        );
                                    }
                                    form.setField(newState);
                                    this.setState(newValue);
                                }}
                            />
                        );
                    return val ? <TickIcon color={'primary'} /> : <CloseIcon color={'disabled'} />;
                },
                styles: { textAlign: 'center', width: 1 }
            },
            {
                id: 'Optional',
                label: 'Optional',
                render: (val, row, rowIndex) => {
                    if (this.props.canEdit)
                        return (
                            <Checkbox
                                value={'' + val}
                                checked={data[rowIndex] ? data[rowIndex].Optional : false}
                                onChange={e => {
                                    const newState = { quote: { ...quote } };
                                    const newVal = !!e.target.checked;
                                    newState.quote.QuoteItems[rowIndex].Optional = newVal;
                                    if (newVal) {
                                        newState.quote.QuoteItems[rowIndex].Qty = 0;
                                    } else {
                                        newState.quote.QuoteItems[rowIndex].Qty = 1;
                                    }
                                    form.setField(newState);
                                }}
                            />
                        );
                    return val ? <TickIcon color={'primary'} /> : <CloseIcon color={'disabled'} />;
                },
                styles: { textAlign: 'center', width: 1 }
            }
        ];

        if (!this.props.canEdit)
            columns.forEach((e, i, a) => {
                if (e.id === 'Price') delete a[i];
            });

        return (
            <div>
                <div className={classes.dataList}>
                    <TableData
                        columns={columns}
                        data={data}
                        footer={[
                            { id: 'Qty', label: 'TOTAL' },
                            {
                                id: 'SubTotal',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['SubTotal'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                }
                            },
                            {
                                id: 'GST',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return !!obj.Complimentary || !!obj.Optional || !!obj.Prepaid || !obj.Qty
                                                ? 0
                                                : obj.Qty * obj['GST'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                }
                            },
                            {
                                id: 'Total',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['Total'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                }
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }

    static reCalcItem(data) {
        data.Total =
            data.Complimentary || data.Optional || data.Prepaid
                ? 0
                : (Number(data.Qty) * Number(data.Price)).toFixed(2);
        data.SubTotal = data.Total - (data.Complimentary || data.Optional || data.Prepaid ? 0 : data.GST);
        return data;
    }

    onDeleteLineItem(index) {
        const { form } = this.props;
        const quote = form.getField('quote');
        quote.QuoteItems.splice(index, 1);
        const newState = { quote: { ...quote } };
        form.setField(newState);
        const { gstPrices } = this.state;
        gstPrices.splice(index, 1);
        this.setState({ gstPrices });
    }
}

const hasGst = invoiceLineItem => {
    return !isRelatedObjectUndefined(invoiceLineItem.Variation)
        ? invoiceLineItem.Variation.GST
        : !isRelatedObjectUndefined(invoiceLineItem.Product) && invoiceLineItem.Product.GST;
};

const getPriceIncGst = invoiceLineItem => {
    const price = parseFloat(invoiceLineItem.Price);
    return round(hasGst(invoiceLineItem) ? price * (GST + 1) : price, 2);
};

const styles = ({ palette, typography, breakpoints }) => ({
    root: {
        maxHeight: 'calc(100vh - 375px)',
        minHeight: '33vh',
        overflow: 'auto',
        padding: 42,
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 175px)',
            padding: 12
        }
    },
    iconButton: {
        width: '17px;',
        height: '17px;',
        float: 'right'
    },
    icon: {
        width: '14px;',
        height: '14px;'
    },
    dataList: {
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 30
    }
});

export default withStyles(styles)(QuotesModalAdjustTab);
