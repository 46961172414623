import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TextField from '../component/form/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import AnonPage from './layout/AnonPage';
import { setToken } from '../util/sessions';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import { joinDefined } from '../util/strings';
import { getAuthClient, getClient, getServiceURLHostname, isLocalClient, isLocalServer } from '../apollo';
import { FUNERAL_HOMES } from '../util/funerals';
import Spinner from '../component/Spinner';
import NextIcon from '../component/icon/NextIcon';
import CircularProgress from '@material-ui/core/CircularProgress';

class SignIn extends Component {
    state = {
        email: '',
        password: '',
        lastofficeid: '',
        busy: false
    };

    onChangeText = name => e => {
        this.setState({
            [name]: e.target.value,
            error: false
        });
    };

    onToggleRemember = e => {
        this.setState({ remember: e.target.checked });
    };

    onCompleted = ({ data }, remember) => {
        const { Token, Member } = (data && (data.createTokenFromHash || data.createToken)) || {};
        const { lastofficeid } = this.state;
        if (!Token) {
            this.setState({ error: 'Incorrect email or password.', busy: false });
            return null;
        }
        const { ID, FirstName, Surname } = Member;
        setToken({ userid: ID, value: Token, name: joinDefined([FirstName, Surname], ' ') }, parseInt(remember));

        if (!!ID && !!lastofficeid) {
            const that = this;
            getClient()
                .mutate({
                    mutation: updateMemberLastOfficeIDMutation,
                    variables: {
                        memberid: ID,
                        lastofficeid: lastofficeid
                    }
                })
                .then(() => that.redirection());
        } else {
            this.redirection();
        }
    };

    componentWillMount() {
        if (!(isLocalClient() && !isLocalServer())) {
            const { match } = this.props;
            const { id, hash, remember } = match.params;

            getAuthClient()
                .mutate({
                    mutation: createTokenFromHash,
                    variables: {
                        id: id,
                        hash: hash
                    }
                })
                .then(data => {
                    this.onCompleted(data, remember);
                });
        }
    }

    redirection = () => {
        const { location, history } = this.props;
        const redirectPath = (location.state && location.state.referrer) || '/';
        history.replace(redirectPath);
    };

    render() {
        const { remember } = this.state;
        return (
            <AnonPage>
                {(isLocalClient() && !isLocalServer() && (
                    <Mutation
                        client={getAuthClient()}
                        mutation={loginMutation}
                        onCompleted={m => this.onCompleted({ data: m }, remember)}
                    >
                        {this.renderForm}
                    </Mutation>
                )) || <CircularProgress />}
            </AnonPage>
        );
    }

    renderForm = (mutate, results = {}) => {
        const { classes } = this.props;
        const { loading } = results;
        const { email, password, lastofficeid, remember, error, busy } = this.state;
        const onSubmit = e => {
            if (e) {
                e.preventDefault();
            }

            if (loading) {
                return;
            }

            if (!email) {
                this.setState({ error: 'Please enter an email address.' });
                return;
            }

            if (!password) {
                this.setState({ error: 'Please enter a password.' });
                return;
            }

            if (!lastofficeid) {
                this.setState({ error: 'Please select an office.' });
                return;
            }
            this.setState({ busy: true }, () => {
                window.localStorage.removeItem('notificationSeenDate');
                mutate({
                    variables: { email, password }
                });
            });
        };

        // const resetPassword = "/forgot-password";
        const resetPassword = '//' + getServiceURLHostname() + '/Security/lostpassword';

        return (
            <form className={classes.form} onSubmit={onSubmit}>
                <Typography variant="title" gutterBottom>
                    Sign In
                </Typography>
                <div className={classes.dividerLine} />
                <Typography component="p" variant="body1">
                    If you have a Funeral Manager account please sign in below.
                </Typography>
                {error && (
                    <Paper className={classes.error}>
                        <Typography component="p" variant="body1" className={classes.errorText}>
                            {error}
                        </Typography>
                    </Paper>
                )}
                <div style={{ marginTop: 16, marginBottom: 0 }}>
                    <TextField
                        disabled={busy}
                        onChange={this.onChangeText('email')}
                        placeholder="Email"
                        value={email}
                        inputMode="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="primary">person</Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>

                <div style={{ marginTop: 16, marginBottom: 0 }}>
                    <TextField
                        disabled={busy}
                        onChange={this.onChangeText('password')}
                        placeholder="Password"
                        value={password}
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="primary">lock</Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                    <TextField
                        disabled={busy}
                        onChange={this.onChangeText('lastofficeid')}
                        label="Office"
                        value={lastofficeid}
                        select
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="primary">business</Icon>
                                </InputAdornment>
                            )
                        }}
                    >
                        {FUNERAL_HOMES.map(option => (
                            <MenuItem key={option.ID} value={option.ID}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <div className={classes.rememberLinks}>
                    {(1 !== 2 && (
                        <FormControlLabel
                            control={<Checkbox checked={remember} onChange={this.onToggleRemember} color="primary" />}
                            label="Stay signed in"
                        />
                    )) || <span />}
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={busy}
                        color="primary"
                        className={classes.button}
                    >
                        Sign In {busy ? <Spinner /> : <NextIcon />}
                    </Button>
                </div>
                <div>
                    <Link to={resetPassword} className={classes.link} target={'_blank'}>
                        Lost/reset password
                    </Link>
                </div>
            </form>
        );
    };
}

const updateMemberLastOfficeIDMutation = gql`
    mutation UpdateMemberLastOfficeID($memberid: Int!, $lastofficeid: Int!) {
        updateMemberLastOfficeID(ID: $memberid, LastOfficeID: $lastofficeid) {
            ID
        }
    }
`;

const loginMutation = gql`
    mutation Login($email: String!, $password: String!) {
        createToken(Email: $email, Password: $password) {
            Token
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

const createTokenFromHash = gql`
    mutation createTokenFromHash($id: ID!, $hash: String!) {
        createTokenFromHash(id: $id, hash: $hash) {
            Token
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

const styles = ({ palette, typography, spacing, breakpoints }) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 320 + spacing.unit * 4,
        padding: spacing.unit * 2,
        margin: 'auto',
        [breakpoints.down('xs')]: {
            marginBottom: spacing.unit * 4 // extra space for silly mobile footers
        }
    },
    dividerLine: {
        width: 50,
        height: 4,
        backgroundColor: palette.action.active,
        margin: '16px 0'
    },
    link: {
        fontSize: typography.body1.fontSize
    },
    rememberLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        margin: '12px 0',
        borderRadius: 25,
        width: 120,
        alignSelf: 'center',
        textTransform: 'none'
    },
    error: {
        backgroundColor: palette.error.light,
        padding: spacing.unit,
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit
    },
    errorText: {
        color: palette.error.contrastText,
        fontWeight: typography.fontWeightMedium
    }
});

export default withStyles(styles)(SignIn);
