import React from 'react';
import FileIcon from '../../../component/icon/FileIcon';
import '../../workQueue2/css/fileThumbnailCard.scss';
import { IconButton } from '@material-ui/core';
import CloseIcon from './CloseIcon';

export default ({ file, fileName, fileLink, fileSize, variant, onClickRemoveAction }) => {
    if (file) {
        fileName = fileName || file.Name;
        fileLink = fileLink || file.AbsoluteLink;
        fileSize = fileSize || file.Size;
    }
    return (
        <a
            className={`file-upload-card ${variant}`}
            href={fileLink}
            target="_blank"
            rel="noopener noreferrer"
            title={fileSize}
        >
            {!!onClickRemoveAction && (
                <IconButton
                    title={'Remove file'}
                    className="remove-upload"
                    onClick={e => {
                        e.preventDefault();
                        onClickRemoveAction();
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <FileIcon className="file-documents" />
            <p className="filename-description">{fileName}</p>
        </a>
    );
};
