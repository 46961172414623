import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Grid from '../../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import Spinner from '../../../component/form/v2/Spinner';
import Typography from '@material-ui/core/Typography/Typography';
import SimpleValuableDetailsModal from '../Common/SimpleValuableDetailsModal';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import PrimaryButton, { DestroyButton, OutlineButton, SaveButton } from '../../../component/form/PrimaryButton';
import DataFormView from '../../../component/form/v2/DataFormView';
import { CheckoutItemFragment, sanitizeFuneralAdminCheck, saveFuneralAdminCheck } from './CheckoutItemConstants';
import { dateTimeToString, dateToString, niceDateTimeFromString } from '../../../util/date';
import ColumnLayout from '../../../component/ColumnLayout';
import Checkbox from '../../../component/form/Checkbox';
import cx from 'classnames';
import { joinDefined } from '../../../util/strings';
import LinkButton from '../../../component/form/LinkButton';
import { flattenBookingItems, PreloadProducts, ProductConfig } from '../../../util/products';
import { ImageList, ImageListItem } from '../../../component/form/ImageList';
import history from '../../../History';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../../component/form/Table';
import ValuablesForm from '../Common/ValuablesForm';
import CheckoutForm from '../Common/CheckoutForm';
import PhotoModal from '../../../component/form/PhotoModal';
import { isContactDefined, isRelatedObjectDefined } from '../../../util/bookable';
import StaffAutoComplete from '../../../component/form/StaffAutoComplete';
import { isRelatedObjectUndefined } from '../../../util/graphql';
import { getUser } from '../../../util/sessions';
import { cloneDeep } from 'apollo-utilities';
import moment from 'moment';
import { InlineField, InlineFieldRightAlignChildren } from '../../../component/form/Inline';
import Icon from '@material-ui/core/Icon';
import { getDressingStatus, releaseFromCheckout, unreleaseFromCheckout } from '../MortuaryItemConstants';
import { indexOf } from '../../../util/arrays';
import {
    filterAdminChecks,
    loadAdminSummary,
    saveCheckoutChecklistFunc
} from '../../funeral/adminSummary/GetSaveAdminSummary';
import { isNullOrUndefined } from '../../../util/objects';
import GatedComponent from '../../../component/GatedComponent';
import AlertModal from '../../../component/form/AlertModal';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';
import BackIcon from '../../../component/icon/BackIcon';
import TickIcon from '../../../component/icon/TickIcon';
import CloseIcon from '../../../component/icon/CloseIcon';
import TextField from '../../../component/form/TextField';
import Label from '../../../component/form/Label';
import InputAdornment from '@material-ui/core/InputAdornment';
import RecordDrawer from '../../workQueue2/RecordDrawer';

const emptyUser = { ID: null };

class CheckoutItem extends Component {
    state = {
        loadingProducts: true,
        checklistData: null,
        openValuableModal: false,
        valuableItemId: null,
        staff1: emptyUser,
        staff2: emptyUser,
        selectedPhoto: null,
        loadingChecks: {},
        loadingAllChecks: false,
        alertModalState: {
            open: false,
            title: null,
            message: null
        }
    };

    componentWillMount() {
        const { form } = this.props;
        const funeralId = form.getState('ID');
        const me = this;

        if (funeralId) {
            this.setState({ loadingAllChecks: true });
            loadAdminSummary(funeralId).then(adminData => {
                saveCheckoutChecklistFunc(funeralId, adminData.adminSummary, adminData.original).then(saveResult => {
                    form.setState(
                        {
                            AdminChecks: saveResult
                                ? saveResult.updateFuneral.AdminChecks
                                : adminData.adminSummary.AdminChecks
                        },
                        true
                    );
                    me.setState({ loadingAllChecks: false });
                });
            });

            const products = form.getState('Disposal.DisposalBookingItems');

            if (products && products.length > 0) {
                PreloadProducts(this, products).then(() => this.setState({ loadingProducts: false }));
            } else {
                this.setState({ loadingProducts: false });
            }
        }
    }

    render() {
        const { classes, form } = this.props;
        const { selectedPhoto, openValuableModal, valuableItemId, alertModalState, loading } = this.state;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <InlineFieldRightAlignChildren lineHeight={'inherit'}>
                        {form.getField('Cancelled') && (
                            <BiggerMessageBar messageType="error">This record is cancelled.</BiggerMessageBar>
                        )}
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Mortuary</span>
                            <span className={classes.header}>
                                Checkout:{' '}
                                {`${form.getState('LegacyKey')} ${joinDefined(
                                    [form.getState('FirstName'), form.getState('MiddleName'), form.getState('Surname')],
                                    ' '
                                )}`}
                            </span>
                        </Typography>

                        <PrimaryButton onClick={this.backButtonFunction}>
                            <BackIcon /> Back
                        </PrimaryButton>

                        <OutlineButton
                            target="_blank"
                            onClick={() => this.viewFuneralDetails(form.getState('ID'), form.getState('LegacyKey'))}
                        >
                            View Funeral Record
                        </OutlineButton>
                        <RecordDrawer funeralId={form.getField('ID')} legacyKey={form.getField('LegacyKey')} />
                    </InlineFieldRightAlignChildren>
                    <br />
                </Grid>

                <Paper
                    className={cx(classes.paper, classes.bodyPadding)}
                    elevation={0}
                    style={{ position: 'relative' }}
                >
                    {!!loading && this.renderLoading()}
                    <ColumnLayout>
                        {this.renderLeftColumn()}
                        {this.renderRightColumn()}
                    </ColumnLayout>
                </Paper>

                <SimpleValuableDetailsModal
                    open={openValuableModal}
                    onClose={() => this.setState({ openValuableModal: false, valuableItemId: null })}
                    context={this}
                    itemId={valuableItemId}
                    parentId={form.getState('ID')}
                    onCreateNew={this.onCreateNewValuable}
                    onSaved={this.onSaveValuable}
                />

                <PhotoModal photo={selectedPhoto} onClose={() => this.setState({ selectedPhoto: null })} />
                <AlertModal {...alertModalState} onCancel={() => this.closeAlertModal()} />
            </Grid>
        );
    }

    renderLeftColumn() {
        const { classes, form } = this.props;
        const { staff1, staff2 } = this.state;

        const isNotReleased =
            !form.getField('DatetimeReadyForMortuaryCheckout') && !form.getField('DatetimeOfMortuaryCheckout');
        const isComplete = !!form.getField('DatetimeOfMortuaryCheckout');

        return (
            <Grid container spacing={24} className={classes.column} style={{ position: 'relative' }}>
                {isNotReleased && (
                    <div
                        style={{
                            zIndex: 99,
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: '#FFFFFF99'
                        }}
                    >
                        <BiggerMessageBar messageType="error">
                            Deceased has not been released to checkout.
                        </BiggerMessageBar>
                    </div>
                )}
                {isComplete && (
                    <div style={{ marginBottom: -8, width: '100%' }}>
                        <BiggerMessageBar>This item has already completed Mortuary Checkout.</BiggerMessageBar>
                    </div>
                )}

                <Grid bucket>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            Checkout Staff
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineField style={{ lineHeight: 0 }}>
                            <Typography
                                variant="subheading"
                                gutterBottom
                                style={{ lineHeight: '2rem', marginBottom: 0 }}
                            >
                                <span>Who is performing the checkout? </span>
                            </Typography>

                            <Checkbox
                                label="I am one of the checkout staff"
                                checked={staff1.ID === getUser().ID}
                                onChange={e => this.setState({ staff1: e.target.checked ? getUser() : emptyUser })}
                                className={classes.floatRight}
                            />
                        </InlineField>
                    </Grid>

                    <Grid item xs={6}>
                        <StaffAutoComplete
                            label={'Checkout Staff 1'}
                            placeholder="Select Staff 1..."
                            selectProps={{ multiple: false }}
                            onSelect={(_, user) => this.onSelectStaff('staff1', user)}
                            value={staff1}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <StaffAutoComplete
                            label={'Checkout Staff 2'}
                            placeholder="Select Staff 2..."
                            selectProps={{ multiple: false }}
                            onSelect={(_, user) => this.onSelectStaff('staff2', user)}
                            value={staff2}
                        />
                    </Grid>
                </Grid>

                {this.renderCheckList()}

                <Grid bucket>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            Funeral Staff
                        </Typography>
                        <Typography variant="subheading">
                            <span>Arrangers: </span>
                            {form.getField('Arrangers').map((e, i, a) => {
                                return (
                                    <span key={i}>
                                        <span className={classes.purpleText}>
                                            {joinDefined([e.Member.FirstName, e.Member.Surname], ' ')}
                                        </span>
                                        {1 + i === a.length ? '' : ', '}
                                    </span>
                                );
                            })}
                        </Typography>
                        <Typography variant="subheading">
                            <span>Coordinators: </span>
                            {form.getField('Coordinators').map((e, i, a) => {
                                return (
                                    <span key={i}>
                                        <span className={classes.purpleText}>
                                            {joinDefined([e.Member.FirstName, e.Member.Surname], ' ')}
                                        </span>
                                        {1 + i === a.length ? '' : ', '}
                                    </span>
                                );
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderRightColumn() {
        const { classes, form } = this.props;
        const { loading, loadingProducts } = this.state;
        const isComplete = form.getField('DatetimeOfMortuaryCheckout');
        const isReleased = form.getField('DatetimeReadyForMortuaryCheckout');
        const isReturned = form.getField('MortuaryActionReturnedReason');
        const estimatedDeceasedWeight = Number(form.getField('EstimatedDeceasedWeight') || 0);
        const tabURLSegment = 'mortuary';
        const adminChecks =
            !!form.getState('AdminChecks') &&
            filterAdminChecks(form, tabURLSegment, form.getState('AdminChecks')).filter(item => item.include);

        return (
            <Grid container spacing={24} className={classes.column} style={{ position: 'relative' }}>
                {this.renderTopTextFields()}

                {this.renderCoffinDetails()}

                {this.renderPhotos()}

                {this.renderValuablesTable()}

                <Grid bucket>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Complete Mortuary Checkout</span>
                        </Typography>

                        {(!!isReleased && (
                            <Typography variant="subheading">
                                Released to Mortuary Checkout on {niceDateTimeFromString(isReleased)}
                                {!!isComplete && <div>Checkout completed on {niceDateTimeFromString(isComplete)}</div>}
                            </Typography>
                        )) ||
                            (!!isReturned && <BiggerMessageBar messageType="error">{isReturned}</BiggerMessageBar>) || (
                                <Typography variant="subheading">Waiting for Mortuary staff to release.</Typography>
                            )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Label text="Weight of Deceased" />
                        <TextField
                            placeholder={'Enter the estimated weight...'}
                            type="number"
                            form={form}
                            name="EstimatedDeceasedWeight"
                            disabled={isComplete || !isReleased}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">KG</InputAdornment>
                            }}
                        />
                    </Grid>

                    <InlineField>
                        {!loadingProducts && (
                            <CheckoutForm
                                form={form}
                                mortuaryListData={!!adminChecks && adminChecks.map(e => e.adminCheck)}
                            />
                        )}
                        {!!isReleased && !isComplete && (
                            <GatedComponent showComponentCode={'FM_ACCESS_Mortuary_Process'}>
                                {() => (
                                    <SaveButton
                                        disabled={loading || !estimatedDeceasedWeight}
                                        onClick={() => this.handleReleaseFromCheckout()}
                                    >
                                        <TickCircleIcon />
                                        Complete Checkout
                                    </SaveButton>
                                )}
                            </GatedComponent>
                        )}

                        {!isReleased && (
                            <OutlineButton onClick={e => this.goCheckoutFunction()}>
                                <BackIcon /> View Mortuary Action
                            </OutlineButton>
                        )}
                        {!!isComplete && (
                            <SaveButton disabled>
                                <TickCircleIcon />
                                Checkout Completed
                            </SaveButton>
                        )}
                    </InlineField>
                </Grid>
            </Grid>
        );
    }

    renderLoading() {
        return (
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: '#FFFFFF99',
                    margin: -24,
                    zIndex: 9
                }}
            >
                {this.renderLoadingWidget()}
            </div>
        );
    }

    renderLoadingWidget() {
        return (
            <div>
                <Spinner />
                &nbsp;<span>Loading...</span>
            </div>
        );
    }

    renderPhotos() {
        const { form, classes } = this.props;
        const photos = form.getField('DeceasedPhotos') || [];

        return (
            <Grid bucket={true} container spacing={24}>
                <Grid item xs={12}>
                    <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                        <span>Photos</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {photos.length > 0 ? (
                        <ImageList>
                            {photos.map((photo, i) => (
                                <ImageListItem
                                    key={i}
                                    value={{ image: photo.AbsoluteLink, label: photo.Name }}
                                    onClick={() => this.setState({ selectedPhoto: photo })}
                                />
                            ))}
                        </ImageList>
                    ) : (
                        <Typography variant="subheading">No photos supplied.</Typography>
                    )}
                </Grid>
            </Grid>
        );
    }

    renderValuablesTable() {
        const { classes, form } = this.props;
        const valuables = form.getState('ValuableItems') || [];

        return (
            <GatedComponent
                showComponentCode={'FM_ACCESS_Mortuary_Valuables_View'}
                isEnabledCode={'FM_ACCESS_Mortuary_Valuables_Edit'}
            >
                {isEnabled => {
                    return (
                        <Grid bucket={true} xs={12}>
                            <Grid item xs={12}>
                                <Typography variant={'headline'} className={cx(classes.pageTitle)}>
                                    Valuables
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.tableData}>
                                    {valuables.length > 0 ? (
                                        <Table>
                                            <TableHeaderRow>
                                                <TableHeaderCell>Description</TableHeaderCell>
                                                <TableHeaderCell>QTY</TableHeaderCell>
                                                <TableHeaderCell>Placement</TableHeaderCell>
                                                <TableHeaderCell>Status</TableHeaderCell>
                                                <TableHeaderCell>Location</TableHeaderCell>
                                                <TableHeaderCell>Return</TableHeaderCell>
                                                <TableHeaderCell>Done</TableHeaderCell>
                                            </TableHeaderRow>
                                            {valuables.map((valuable, index) => (
                                                <TableRow
                                                    key={index}
                                                    pad={true}
                                                    onClick={() => this.viewValuableItem(valuable.ID)}
                                                    className={classes.clickThis}
                                                >
                                                    <TableCell>{valuable.Description}</TableCell>
                                                    <TableCell>{valuable.Quantity}</TableCell>
                                                    <TableCell>{valuable.ToBePlaced}</TableCell>
                                                    <TableCell>{valuable.CurrentLocation}</TableCell>
                                                    <TableCell>{valuable.CurrentLocationDetail}</TableCell>
                                                    <TableCell>{valuable.ToBeReturned ? 'Yes' : 'No'}</TableCell>
                                                    <TableCell centred>
                                                        {valuable.ToBeReturned ? (
                                                            valuable.CurrentLocation === 'Returned' ? (
                                                                <TickIcon color={'disabled'} />
                                                            ) : (
                                                                <CloseIcon color={'error'} />
                                                            )
                                                        ) : valuable.CurrentLocation !== 'Awaiting Delivery' ? (
                                                            <TickIcon color={'disabled'} />
                                                        ) : (
                                                            <CloseIcon color={'error'} />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </Table>
                                    ) : (
                                        <Typography variant="subheading">
                                            Deceased has no record of valuables.
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                            <InlineField>
                                <PrimaryButton onClick={() => this.viewValuableItem(null)}>
                                    Add Valuables...
                                </PrimaryButton>
                                <ValuablesForm form={form} />
                            </InlineField>
                        </Grid>
                    );
                }}
            </GatedComponent>
        );
    }

    renderCoffinDetails() {
        const { loadingProducts } = this.state;
        const { form, classes } = this.props;
        const coffinProducts = (form.getField('Disposal.DisposalBookingItems') || []).map(b => {
            const obj = { ...b };
            const product = ProductConfig.productMap[obj.ProductID];
            if (!!product) {
                if (obj.Quantity && product.urlSegment === 'coffins') product.tileWidth = 2;
                obj.titleObj = product.variations.find(e => e.id === obj.VariationID) || product;
            }
            return { ...obj, product };
        });

        return (
            <Grid container bucket={true}>
                <Grid item xs={12}>
                    <Typography
                        variant="headline"
                        gutterBottom
                        className={cx(classes.pageTitle, classes.coffinDetailsHeader)}
                    >
                        <span>Coffin Details</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {loadingProducts ? (
                        this.renderLoadingWidget()
                    ) : coffinProducts.length > 0 ? (
                        <Fragment>
                            <ImageList>
                                {coffinProducts.map((obj, i) => {
                                    return <ImageListItem key={i} value={obj.product} quantity={obj.Quantity} />;
                                })}
                            </ImageList>
                            <ul>
                                {coffinProducts.map((obj, i) => {
                                    return (
                                        <li key={i} className={Number(obj.Quantity) === 0 ? classes.strikeout : ''}>
                                            {Number(obj.Quantity) === 0 ? '' : Number(obj.Quantity) + 'x'}{' '}
                                            <strong>{obj.titleObj.internalId}</strong>: {obj.titleObj.title}
                                            {1 === 2 && !!obj.Returns ? ' | To be returned' : ''}
                                            {!!obj.Comment ? ' | Comment: ' + obj.Comment : ''}
                                        </li>
                                    );
                                })}
                            </ul>
                        </Fragment>
                    ) : (
                        <Typography variant="subheading">No coffins have been selected.</Typography>
                    )}
                </Grid>
            </Grid>
        );
    }

    renderTopTextFields() {
        const { form, classes } = this.props;
        const disposalTitle = () => {
            const title = form.getState('Disposal.CrematedOrBuried');
            switch (title) {
                case 'Buried':
                    return 'Burial';
                case 'Cremated':
                    return 'Cremation';
                case 'Repatriated':
                    return 'Repatriation';
                case 'Body Not Recovered':
                    return 'No Body';
                default:
                    return 'Disposal Unknown';
            }
        };

        let latestDate = moment().add(100, 'years');
        let viewing = null;
        const items = form.getState('PlaceOfViewingItems') || [];
        items.forEach(e => {
            const theDate = moment(e.Start || e.Date);
            if (moment(theDate).isValid() && theDate < latestDate) {
                latestDate = theDate;
                viewing = e;
            }
        });
        const isGraveside = form.getField('PlaceOfService.Type') === 'Graveside';
        const service = !!isGraveside ? form.getState('Disposal') : form.getState('PlaceOfService');
        const getRelease = () => {
            const startV = !!viewing && (viewing.Start || viewing.Date);
            const startS = !!service && (service.Start || service.Date);
            if (startV && !startS) return viewing;
            if (startS && !startV) return service;
            return moment(startV).isBefore(startS) ? viewing : service;
        };
        const release = getRelease();
        const isCremation = form.getState('Disposal.CrematedOrBuried') === 'Cremated';
        const hasPacemaker = !!isCremation && !!form.getState('RemovePacemaker');
        const needsReferee = !!isCremation && !(form.getState('Certification.TypeOfBdmDeathCertificate') === 'Coroner');
        const isCrypt =
            (form.getState('Disposal.CrematedOrBuried') === 'Buried' ||
                form.getState('Disposal.CrematedOrBuried') === 'Repatriated') &&
            (form.getState('Grave.GroundDescription') === 'Crypt' || form.getState('Grave.DepthOption') === 'Crypt');

        return (
            <Fragment>
                <Grid container bucket={true}>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>{disposalTitle()}</span>
                            {!!isCremation && (
                                <span className={classes.lightGreyHeader}>
                                    {!!needsReferee ? (
                                        !!isContactDefined(form.getState('Certification.Referee')) ? (
                                            !!form.getState('RefereeCremationCheck') ? (
                                                <Fragment>
                                                    Referee Completed{' '}
                                                    <TickIcon className={classes.icon} title={'Completed'} />
                                                </Fragment>
                                            ) : (
                                                <Fragment>Referee Assigned</Fragment>
                                            )
                                        ) : (
                                            <span className={classes.redText}>Referee Not Assigned</span>
                                        )
                                    ) : (
                                        <Fragment>Referee Not Required</Fragment>
                                    )}
                                </span>
                            )}
                            {!!isCrypt && <span className={classes.lightGreyHeader}>for Crypt</span>}
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="subheading">
                            Viewing:{' '}
                            <span className={classes.purpleText}>
                                {!!form.getField('PlaceOfViewingRequired') && !isNullOrUndefined(viewing)
                                    ? !!viewing.Date && !!viewing.Time
                                        ? niceDateTimeFromString(viewing.Date + ' ' + viewing.Time)
                                        : 'Date/time not provided.'
                                    : 'No viewings.'}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="subheading">
                            Service:{' '}
                            <span className={classes.purpleText}>
                                {!isNullOrUndefined(service) && service.Type !== 'No Service No Attendance'
                                    ? !!service.Date && !!service.Time
                                        ? niceDateTimeFromString(service.Date + ' ' + service.Time)
                                        : 'Date/time not provided.'
                                    : 'No service.'}
                            </span>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subheading">
                            Release to:{' '}
                            {!isNullOrUndefined(release) && !!isContactDefined(release.Location) ? (
                                <span className={classes.purpleText}>{release.LocationFlattened}</span>
                            ) : (
                                'No release specified.'
                            )}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Dressing</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subheading">{getDressingStatus(form)}</Typography>
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Mortuary Preparation Notes</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subheading" className={classes.notesPre}>
                            {form.getState('CoffinComment') || 'No notes.'}
                        </Typography>

                        {!!isCrypt && (
                            <BiggerMessageBar messageType="warning">Embalming is required for Crypt.</BiggerMessageBar>
                        )}

                        {!!hasPacemaker && (
                            <BiggerMessageBar messageType="warning">Pacemaker must be removed.</BiggerMessageBar>
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderCheckList() {
        const { classes, form } = this.props;
        const { loadingAllChecks } = this.state;

        const disabled = isRelatedObjectUndefined(this.state.staff1) || isRelatedObjectUndefined(this.state.staff2);

        const tabURLSegment = 'mortuary';

        return (
            <GatedComponent showComponentCode={'FM_ACCESS_Mortuary_Checklist'}>
                {() => {
                    return (
                        <Grid container spacing={24} style={{ margin: 0, padding: 9, width: '100%' }}>
                            <Grid item xs={12}>
                                <DestroyButton style={{ float: 'right' }} onClick={e => this.onUnrelease()}>
                                    <Icon style={{ marginRight: 6 }}>report</Icon> Cancel Checkout...
                                </DestroyButton>
                                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                                    <span>Checklist </span>
                                </Typography>
                                {!!disabled && (
                                    <small className={classes.redText}>
                                        Please nominate staff to proceed with the checkout.
                                    </small>
                                )}
                            </Grid>

                            {loadingAllChecks
                                ? this.renderLoadingWidget()
                                : !!form.getState('AdminChecks') &&
                                  filterAdminChecks(form, tabURLSegment, form.getState('AdminChecks'))
                                      .filter(item => item.include)
                                      .map(item => this.renderCheckListItem(item.adminCheck, item.index))}
                        </Grid>
                    );
                }}
            </GatedComponent>
        );
    }

    renderCheckListItem(item, index) {
        const { classes } = this.props;
        const loading = this.state.loadingChecks[item.ID] === true;
        const disabled =
            isRelatedObjectUndefined(this.state.staff1) || isRelatedObjectUndefined(this.state.staff2) || loading;
        const assignToDisabled = isRelatedObjectDefined(item.Join.Task);

        let assignedTo = '';

        if (isRelatedObjectDefined(item.Join.Member)) {
            assignedTo += joinDefined([item.Join.Member.FirstName, item.Join.Member.Surname], ' ');
        }

        if (isRelatedObjectDefined(item.Join.MortuaryMember)) {
            assignedTo +=
                ' and ' + joinDefined([item.Join.MortuaryMember.FirstName, item.Join.MortuaryMember.Surname], ' ');
        }

        return (
            <Grid
                key={index}
                item
                xs={12}
                bucket={true}
                className={classes.checkRow}
                style={{ pointerEvents: !!disabled ? 'none' : 'auto' }}
            >
                <Grid container spacing={24} className={classes.hideSecondChild}>
                    <Grid item xs={12}>
                        <Checkbox
                            label={item.Title}
                            checked={!isNullOrUndefined(item.Join.Checked)}
                            onChange={e => this.onAdminItemChecked(e.target.checked, item.ID, index)}
                            disabled={disabled}
                        />
                        <div style={{ float: 'right' }}>
                            {isRelatedObjectDefined(item.Join.Task) && item.Join.Task.AssignedMembers.length === 0 ? (
                                <LinkButton
                                    className={classes.assignText}
                                    text={'Reassign To Funeral Staff'}
                                    onClick={() => this.reassignToFuneralStaff(item.ID, index)}
                                    disabled={!!loading}
                                />
                            ) : (
                                !!isNullOrUndefined(item.Join.Checked) && (
                                    <LinkButton
                                        className={!!assignToDisabled ? classes.assignTextDisabled : classes.assignText}
                                        text={
                                            assignToDisabled
                                                ? 'Assigned To Funeral Staff'
                                                : 'Assign to Funeral Staff...'
                                        }
                                        onClick={() => this.assignToFuneralStaff(item.ID, index)}
                                        disabled={assignToDisabled}
                                    />
                                )
                            )}
                        </div>
                        {loading && <Spinner />}

                        <Typography variant="subheading" className={classes.checklistStaff}>
                            {assignedTo}
                        </Typography>
                    </Grid>
                    {isRelatedObjectDefined(item.Join.Task) && (
                        <div className={classes.noteBox}>
                            <BiggerMessageBar
                                messageType={
                                    !!item.Join.Task.Completed
                                        ? 'good'
                                        : item.Join.Task.AssignedMembers.length > 0
                                        ? 'info'
                                        : 'error'
                                }
                            >
                                <div>
                                    {item.Join.Task.AssignedMembers.length > 0
                                        ? `This item ${
                                              !!item.Join.Task.Completed ? 'was' : 'is'
                                          } assigned to ${joinDefined(
                                              item.Join.Task.AssignedMembers.map(
                                                  assignedMember =>
                                                      `${assignedMember.FirstName} ${assignedMember.Surname}`
                                              ),
                                              ' and '
                                          )}.`
                                        : 'THIS TASK HAS NO FUNERAL STAFF ASSIGNED TO IT!'}
                                </div>
                                <div>
                                    <i>{item.Join.Task.Comment}</i>
                                </div>
                            </BiggerMessageBar>
                        </div>
                    )}
                </Grid>
            </Grid>
        );
    }

    onSelectStaff(propertyName, staff) {
        this.setState({ [propertyName]: staff });
    }

    onUnrelease = () => {
        const { form } = this.props;
        const reason = window.prompt(
            'Are you sure you want to return this item to the Mortuary Action List?\n\nPlease enter a reason...',
            ''
        );
        if (reason === null) return null;
        unreleaseFromCheckout(form.getState('ID'), reason).then(() => history.push('/mortuary'));
    };

    onAdminItemChecked(checked, id, index) {
        const { form } = this.props;
        const AdminChecks = form.getState('AdminChecks');
        const funeralAdminCheck = cloneDeep(AdminChecks[index].Join);

        //set or remove the timestamp and assigned members
        if (checked) {
            const { staff1, staff2 } = this.state;
            funeralAdminCheck.Member = staff1;
            funeralAdminCheck.MortuaryMember = staff2;
            funeralAdminCheck.Checked = dateTimeToString(new Date());
        } else {
            funeralAdminCheck.Member = emptyUser;
            funeralAdminCheck.MortuaryMember = emptyUser;
            funeralAdminCheck.Checked = null;
        }

        this.sanitizeAndSaveFuneralAdminCheck(id, index, funeralAdminCheck);
    }

    assignToFuneralStaff(id, index) {
        const { form } = this.props;
        const funeralStaff = (form.getState('Coordinators') || [])
            .concat(form.getState('Arrangers') || [])
            .map(x => x.Member);

        if (funeralStaff.length === 0) {
            this.openAlertModal('Error', 'Sorry, there are currently no Funeral Staff allocated to this funeral.');
            return;
        }
        const AdminChecks = form.getState('AdminChecks');
        const adminCheck = AdminChecks[index];

        this.openAlertModalWithText(
            'Assign To Funeral Staff: ' + adminCheck.Title,
            'Please enter a comment for staff to review...',
            Comment => {
                const funeralAdminCheck = cloneDeep(adminCheck.Join);

                const { staff1, staff2 } = this.state;

                const mortUser1 = !!staff1.ID && joinDefined([staff1.FirstName, staff1.Surname.substring(0, 1)], ' ');
                const mortUser2 = !!staff2.ID && joinDefined([staff2.FirstName, staff2.Surname.substring(0, 1)], ' ');
                const mortUsers = joinDefined([mortUser1, mortUser2], ' and ');

                funeralAdminCheck.Task = {
                    Title: `Urgent Mortuary Issue`,
                    Description: `${adminCheck.Title}`,
                    RequiredAction: `Resolve & contact ${!!mortUsers ? mortUsers : 'Mortuary Staff'} immediately`,
                    TabURLSegment: '',
                    Due: `${dateToString(new Date())} 18:30:00`,
                    FuneralID: form.getState('ID'),
                    Comment,
                    AssignedMembers: funeralStaff.map(user => ({
                        ID: user.ID
                    }))
                };

                this.sanitizeAndSaveFuneralAdminCheck(id, index, funeralAdminCheck);
            }
        );
    }

    reassignToFuneralStaff(id, index) {
        const { form } = this.props;
        const funeralStaff = (form.getState('Coordinators') || [])
            .concat(form.getState('Arrangers') || [])
            .map(x => x.Member);

        if (funeralStaff.length === 0) {
            console.error('no coordinators or arranges allocated to this funeral!', form.getState('ID'));
            return;
        }

        const funeralAdminCheck = cloneDeep(form.getState(`AdminChecks[${index}].Join`));

        funeralAdminCheck.Task.AssignedMembers = funeralStaff.map(user => ({
            ID: user.ID
        }));

        this.sanitizeAndSaveFuneralAdminCheck(id, index, funeralAdminCheck);
    }

    sanitizeAndSaveFuneralAdminCheck(id, index, funeralAdminCheck) {
        this.setCheckLoadingState(id, true);

        const { form } = this.props;
        const AdminChecks = form.getState('AdminChecks');

        //sanitize and commit to the database
        const sanitized = sanitizeFuneralAdminCheck(cloneDeep(funeralAdminCheck));
        saveFuneralAdminCheck(sanitized).then(result => {
            if (result) {
                AdminChecks[index].Join = result;
                form.setState({ AdminChecks });
            }
            this.setCheckLoadingState(id, false);
        });
    }

    handleReleaseFromCheckout = e => {
        this.setState({ loading: true });

        const { form } = this.props;
        const me = this;
        releaseFromCheckout(form.getState('ID'), form.getState('EstimatedDeceasedWeight')).then(data => {
            me.props.form.setField({ DatetimeOfMortuaryCheckout: data.DatetimeOfMortuaryCheckout }, 1);
            me.setState({ loading: false });
        });
    };

    setCheckLoadingState(index, loading) {
        //set to loading, to block other changes
        const { loadingChecks } = this.state;
        loadingChecks[index] = loading;
        this.setState({ loadingChecks });
    }

    backButtonFunction() {
        history.push('/mortuary/checkout');
    }

    goCheckoutFunction() {
        const { form } = this.props;
        const ID = form.getField('ID');
        const LegacyKey = form.getField('LegacyKey');
        history.push(`/mortuary/item/${LegacyKey}/${ID}`);
    }

    viewFuneralDetails = (id, legacyId) => {
        const path = `/funeral/${legacyId}/${id}`;
        const win = window.open(path);
        win.focus();
    };

    viewValuableItem = valuableItemId => {
        this.setState({ valuableItemId, openValuableModal: true });
    };

    onCreateNewValuable = item => {
        const { form } = this.props;
        const ValuableItems = form.getState('ValuableItems') || [];
        ValuableItems.push(item);
        form.setState({ ValuableItems });
    };

    onSaveValuable = item => {
        const { form } = this.props;
        const ValuableItems = form.getState('ValuableItems') || [];
        const index = indexOf(ValuableItems, x => x.ID === item.ID);
        if (index < 0) return;

        Object.assign(ValuableItems[index], item);
        form.setState({ ValuableItems });
    };

    openAlertModal(title, message) {
        this.setState({
            alertModalState: {
                open: true,
                title,
                message,
                acceptInput: false,
                onAccept: null
            }
        });
    }

    openAlertModalWithText(title, message, onAccept) {
        this.setState({
            alertModalState: {
                open: true,
                title,
                message,
                acceptInput: true,
                onAccept: inputValue => {
                    this.closeAlertModal();
                    onAccept(inputValue);
                }
            }
        });
    }

    closeAlertModal() {
        const { alertModalState } = this.state;
        alertModalState.open = false;
        this.setState({ alertModalState });
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3,
        width: '100%'
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    coffinDetailsHeader: {
        '@media (max-width: 1280px)': {
            marginTop: 15
        }
    },
    floatRight: {
        float: 'right'
    },
    bodyPadding: {
        padding: '1.5rem',
        '& p': {
            fontSize: '0.875rem'
        }
    },
    redText: {
        color: 'red'
    },
    column: {
        // padding: '1rem',
        '& > *': {
            padding: '15px',
            marginBottom: 20
        }
    },
    print: {
        '@media print': {
            margin: 100
        }
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    secondaryHeader: {
        color: palette.action.active
    },
    lightGreyHeader: {
        fontWeight: typography.fontWeightLight,
        color: palette.custom.lightishGrey,
        borderLeft: '1px solid ' + palette.custom.lightishGrey,
        paddingLeft: 16,
        marginLeft: 16
    },
    purpleText: {
        // color: palette.action.active
        fontWeight: typography.fontWeightMedium
    },
    tableData: {
        // paddingTop: 20
    },
    smallerButton: {
        marginRight: 15,
        '& > span': {
            fontSize: 11
        }
    },
    addButtonDesktop: {
        float: 'right',
        top: -45,
        boxShadow: 'none',
        backgroundColor: palette.button.alt.backgroundColor,
        borderRadius: 18
    },
    backButtonDesktop: {
        float: 'right',
        top: -45,
        boxShadow: 'none',
        border: '1px solid',
        color: palette.button.alt.backgroundColor,
        backgroundColor: 'white',
        borderRadius: 18,
        marginRight: 15,
        '&:hover': {
            color: 'white'
        }
    },
    notesPre: {
        whiteSpace: 'pre-wrap'
    },
    assignText: {
        float: 'right',
        fontSize: 14,
        paddingTop: 10,
        pointerEvents: 'auto'
    },
    assignTextDisabled: {
        float: 'right',
        fontSize: 14,
        paddingTop: 10
    },
    checklistStaff: {
        fontSize: 14
    },
    checkRow: {
        padding: '10px 15px'
    },
    clickThis: {
        cursor: 'pointer'
    },
    noteBox: {
        margin: '-16px 12px 6px',
        width: '100%'
    },
    hideSecondChild: {
        //todo: enable this later
        '&:hover > :last-child': {
            //display:'inline-block'
        },
        '& > :last-child': {
            //display:'none'
        }
    }
});

const Tabs = [
    {
        id: 'CheckoutItem',
        label: 'Checkout Item',
        component: compose(withRouter, withSnackbarMessage, withStyles(styles))(CheckoutItem),
        fragment: CheckoutItemFragment,
        onLoad: data => {
            flattenBookingItems(data.Disposal, 'DisposalBookingItems');
            // data.Florists.forEach(florist => flattenBookingItems(florist, 'BookingItems'));
        },
        formatSaveData: (saveData, state) => {}
    }
];

class CheckoutItemView extends Component {
    render() {
        return (
            <DataFormView
                tabs={Tabs}
                createNew={false}
                createNewFunc={null}
                objectType="Funeral"
                itemId={this.props.match.params.id}
                name="CheckoutItem"
                readOnly={true}
            />
        );
    }
}

export default compose(withRouter, withStyles(styles))(CheckoutItemView);
