module.exports = {
    AdminChecks: require('../fragments/AdminChecks').default,
    Arrangement: require('../fragments/Arrangement').default,
    AshesDetail: require('../fragments/AshesDetail').default,
    AuthorisedContacts: require('../fragments/AuthorisedContacts').default,
    BdmResponse: require('../fragments/BdmResponse').default,
    BillTo: require('../fragments/BillTo').default,
    Cars: require('../fragments/Cars').default,
    Caterers: require('../fragments/Caterers').default,
    CateringStaffs: require('../fragments/CateringStaffs').default,
    CemeteryCremetorium: require('../fragments/CemeteryCremetorium').default,
    Certification: require('../fragments/Certification').default,
    ChildrenDetails: require('../fragments/ChildrenDetails').default,
    Clergy: require('../fragments/Clergy').default,
    CoffinBearings: require('../fragments/CoffinBearings').default,
    DefaultMusicians: require('../fragments/DefaultMusicians').default,
    Disposal: require('../fragments/Disposal').default,
    EnterMusicians: require('../fragments/EnterMusicians').default,
    EnterMusicSong: require('../fragments/EnterMusicSong').default,
    ExitMusicians: require('../fragments/ExitMusicians').default,
    ExitMusicSongs: require('../fragments/ExitMusicSongs').default,
    Florists: require('../fragments/Florist').default,
    FuneralDressing: require('../fragments/FuneralDressing').default,
    Grave: require('../fragments/Grave').default,
    GraveLedger: require('../fragments/GraveLedger').default,
    GravesideMusicians: require('../fragments/GravesideMusicians').default,
    GravesideMusicSongs: require('../fragments/GravesideMusicSongs').default,
    Hearse: require('../fragments/Hearse').default,
    Informant: require('../fragments/Informant').default,
    Invoices: require('../fragments/Invoices').default,
    LegacyDocuments: require('../fragments/LegacyDocuments').default,
    Lifestory: require('../fragments/Lifestory').default,
    LimoBookings: require('../fragments/Limos').default,
    Marriages: require('../fragments/Marriages').default,
    MemorialStationary: require('../fragments/MemorialStationary').default,
    NewspaperNotices: require('../fragments/NewspaperNotice').default,
    Parent1: require('../fragments/Parent1').default,
    Parent2: require('../fragments/Parent2').default,
    PersonalisedTouches: require('../fragments/PersonalisedTouches').default,
    PurchaseOrders: require('../fragments/PurchaseOrders').default,
    PlaceOfDeath: require('../fragments/PlaceOfDeath').default,
    TransferFrom: require('../fragments/TransferFrom').default,
    PlaceOfDeathResidentialAddress: require('../fragments/PlaceOfDeathResidentialAddress').default,
    TransferFromResidentialAddress: require('../fragments/TransferFromResidentialAddress').default,
    PlaceOfService: require('../fragments/PlaceOfService').default,
    PlaceOfViewingItems: require('../fragments/PlaceOfViewingItems').default,
    ReflectionMusicians: require('../fragments/ReflectionMusicians').default,
    ReflectionMusicSongs: require('../fragments/ReflectionMusicSongs').default,
    ReflectionRoom: require('../fragments/ReflectionRoom').default,
    RefreshmentsVenue: require('../fragments/RefreshmentsVenue').default,
    RslMasonic: require('../fragments/RslMasonic').default,
    SpecialInstructions: require('../fragments/SpecialInstructions').default,
    ValuableItems: require('../fragments/ValuableItems').default,
    Arrangers: require('../fragments/Arrangers').default,
    Conductors: require('../fragments/Conductors').default,
    Coordinators: require('../fragments/Coordinators').default,
    DeceasedPhotos: require('../fragments/DeceasedPhotos').default,
    Application: require('../fragments/Application').default,
    WebServiceLogs: require('../fragments/WebServiceLogs').default,
    ReferencePhoto: require('../fragments/ReferencePhoto').default,
    RunsheetPDFs: require('../fragments/RunsheetPDFs').default
};
