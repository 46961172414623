import React, { Component, Fragment } from 'react';
import Grid from './Grid';
import TextField from './TextField';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from './Table';
import { joinDefined, pad } from '../../util/strings';
import { dateToString, stringToDate } from '../../util/date';
import EditIcon from '../icon/EditIcon';
import CloseIcon from '../icon/CloseIcon';
import DeleteIcon from '../icon/DeleteIcon';
import { IconButton } from '@material-ui/core';
import { InlineField } from './Inline';
import StaffAutoComplete from './StaffAutoComplete';
import PrimaryButton, { OutlineButton } from './PrimaryButton';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import TickCircleIcon from '../icon/TickCircleIcon';
import { getClient } from '../../apollo';
import { gql } from 'apollo-boost';
import Spinner from '../Spinner';
import { deleteTypeName } from '../../util/objects';
import { Query } from 'react-apollo';

class StaffAllocationTable extends Component {
    state = {
        loading: false,
        editField: null,
        editIndex: null
    };

    render() {
        const { classes, staffAllocations, showCalendarLink, date, disabled } = this.props;
        const { editIndex, editClone, loading } = this.state;
        return (
            <Fragment>
                {staffAllocations.length > 0 && (
                    <Fragment>
                        <Grid item xs={12}>
                            <Table>
                                <TableHeaderRow>
                                    <TableHeaderCell>Allocated Staff</TableHeaderCell>
                                    <TableHeaderCell>Arrive</TableHeaderCell>
                                    <TableHeaderCell>Leave</TableHeaderCell>
                                    <TableHeaderCell className={classes.tinyCell} />
                                </TableHeaderRow>
                                {staffAllocations.map((obj, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {editIndex === i ? (
                                                <div className={classes.editCell}>
                                                    <StaffAutoComplete
                                                        disabled={disabled}
                                                        placeholder={'Allocate a staff member...'}
                                                        value={editClone.Member}
                                                        onSelect={(_, result) => this.onEditField('Member', result)}
                                                    />
                                                    <TextField
                                                        placeholder={'Additional notes...'}
                                                        value={editClone.Allocation}
                                                        onChange={e => this.onEditField('Allocation', e.target.value)}
                                                    />
                                                </div>
                                            ) : (
                                                <div className={classes.readonlyCell}>
                                                    {(obj.Member && this.showMember(obj.Member)) || (
                                                        <span className={classes.notSpecified}>
                                                            Staff Not Allocated
                                                        </span>
                                                    )}
                                                    <small>
                                                        <br />
                                                        {obj.Allocation}
                                                    </small>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editIndex === i ? (
                                                <TextField
                                                    disabled={disabled}
                                                    type="time"
                                                    value={editClone.TimeFrom}
                                                    onChange={e => this.onEditField('TimeFrom', e.target.value)}
                                                />
                                            ) : (
                                                <div className={classes.readonlyCell}>
                                                    {(!!obj.TimeFrom &&
                                                        moment('2001-01-01 ' + obj.TimeFrom).format('h:mma')) ||
                                                        ''}
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editIndex === i ? (
                                                <TextField
                                                    disabled={disabled}
                                                    type="time"
                                                    value={editClone.TimeTo}
                                                    onChange={e => this.onEditField('TimeTo', e.target.value)}
                                                />
                                            ) : (
                                                <div className={classes.readonlyCell}>
                                                    {(!!obj.TimeTo &&
                                                        moment('2001-01-01 ' + obj.TimeTo).format('h:mma')) ||
                                                        ''}
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.editCell}>
                                            {editIndex === i ? (
                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                    <IconButton
                                                        className={classes.iconButton}
                                                        title={'Confirm'}
                                                        onClick={() => this.onSaveRow()}
                                                    >
                                                        <TickCircleIcon className={classes.icon} />
                                                    </IconButton>

                                                    <IconButton
                                                        className={classes.iconButton}
                                                        title={'Cancel'}
                                                        onClick={() => this.onCancelEditRow()}
                                                    >
                                                        <CloseIcon className={classes.icon} />
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                    <IconButton
                                                        className={classes.iconButton}
                                                        title={'Edit'}
                                                        onClick={() => this.onEditRow(i)}
                                                    >
                                                        <EditIcon className={classes.icon} />
                                                    </IconButton>

                                                    <IconButton
                                                        disabled={disabled}
                                                        className={classes.iconButton}
                                                        title={'Delete'}
                                                        onClick={() => this.onDeleteRow(i)}
                                                    >
                                                        <DeleteIcon className={classes.icon} />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </Grid>
                    </Fragment>
                )}
                <Grid item xs={12}>
                    <InlineField lineHeight={'normal'}>
                        <PrimaryButton disabled={!date || loading || (disabled && (staffAllocations || []).length > 0)} onClick={() => this.onAddRow()}>
                            {loading ? (
                                <Fragment>
                                    <Spinner />
                                    &nbsp;Loading Staff...
                                </Fragment>
                            ) : (
                                '+ Add Staff Allocation'
                            )}
                        </PrimaryButton>
                        {showCalendarLink && (
                            <OutlineButton target="_blank" onClick={() => this.openCalendar()}>
                                Open Calendar
                            </OutlineButton>
                        )}
                    </InlineField>
                </Grid>
            </Fragment>
        );
    }

    openCalendar() {
        const date = this.props.date ? '/' + this.props.date.replace(/-/g, '').trim() : '';
        const url = `/calendar${date}`;
        const win = window.open(url, '_blank');
        win.focus();
    }

    onEditField(fieldName, value) {
        const { editClone } = this.state;
        editClone[fieldName] = value;
        this.setState({ editClone });
    }

    onSaveRow() {
        const { onChange } = this.props;
        const { editClone, editIndex } = this.state;

        if (onChange) onChange(editIndex, editClone);

        this.onCancelEditRow();
    }

    onCancelEditRow() {
        this.setState({ editIndex: null, editClone: null });
    }

    onEditRow(index) {
        if (this.state.editIndex) this.onSaveRow();

        const { staffAllocations } = this.props;
        this.setState({ editIndex: index, editClone: { ...staffAllocations[index] } });
    }

    onDeleteRow(index) {
        const { onDelete } = this.props;

        if (!onDelete) return;

        onDelete(index);
    }

    onAddRow() {
        const { onAdd, date, disabled } = this.props;
        if (!onAdd) return;
        if (!(!!date && moment(date).isValid())) return;
        const theDate = dateToString(date);

        const minuteBuffer = this.props.buffer || 30;

        let startTimeString = this.props.startTime;
        if (!startTimeString) startTimeString = '09:30:00';
        else if (startTimeString.length === 5) startTimeString += ':00';
        else if (startTimeString.length !== 8)
            throw new Error('unexpected start time format ' + startTimeString + '. Expected=HH:mm:ss');

        const startTime = stringToDate(`${theDate} ${startTimeString}`);
        const adjustedStartTime = new Date(startTime.getTime() - minuteBuffer * 60000);

        let adjustedEndTime;
        if (this.props.endTime) {
            let endTimeString = this.props.endTime;
            if (endTimeString.length === 5) endTimeString += ':00';
            else if (endTimeString.length !== 8)
                throw new Error('unexpected end time format ' + endTimeString + '. Expected=HH:mm:ss');

            const endTime = stringToDate(`${theDate} ${endTimeString}`);
            adjustedEndTime = new Date(endTime.getTime() + minuteBuffer * 60000);
        } else {
            const duration = this.props.duration || 4;
            adjustedEndTime = new Date(startTime.getTime() + minuteBuffer * 60000 + duration * 60 * 60000);
        }

        const TimeFrom = `${pad(adjustedStartTime.getHours(), 2)}:${pad(adjustedStartTime.getMinutes(), 2)}:00`;
        const TimeTo = `${pad(adjustedEndTime.getHours(), 2)}:${pad(adjustedEndTime.getMinutes(), 2)}:00`;
        const DateFrom = dateToString(adjustedStartTime);
        const DateTo = dateToString(adjustedEndTime);

        const { staffAllocations } = this.props || [];
        if (staffAllocations.length === 0) {
            if (disabled) {
                onAdd({});
            }
            else {
                const that = this;
                that.setState({ loading: true });
                this.getDefaultStaffMembers().then(data => {
                    const Members = that.getFuneralStaff(data);
                    if (Members && Members.length) {
                        Members.forEach(m => onAdd({ Member: m, TimeFrom, TimeTo, DateFrom, DateTo }));
                    } else onAdd({ Member: null, TimeFrom, TimeTo, DateFrom, DateTo });
                    that.setState({
                        loading: false,
                        editIndex: Members && Members.length ? null : 0,
                        editClone: { TimeFrom, TimeTo }
                    });
                });
            }
        } else {
            onAdd({ Member: null, TimeFrom, TimeTo, DateFrom, DateTo });
            this.setState({ editIndex: staffAllocations.length - 1, editClone: { TimeFrom, TimeTo } });
        }
    }

    getFuneralStaff = data => {
        const conductors = data && data.Conductors;
        const arrangers = data && data.Arrangers;
        if (!(conductors || arrangers)) return null;
        if (!((conductors && conductors.length > 0) || (arrangers && arrangers.length > 0))) return null;

        const staff = [];
        const staffIDs = []; // checks for dupes
        for (let x = 0; x < conductors.length; x++) {
            if (!staffIDs[conductors[x].Member.ID]) {
                staff.push(conductors[x].Member);
                staffIDs[conductors[x].Member.ID] = true;
            }
        }
        for (let x = 0; x < arrangers.length; x++) {
            if (!staffIDs[arrangers[x].Member.ID]) {
                staffIDs[arrangers[x].Member.ID] = true;
                staff.push(arrangers[x].Member);
            }
        }

        return deleteTypeName(staff);
    };

    getDefaultStaffMembers = async () => {
        const { funeralId } = this.props;
        if (!funeralId) return null;
        const funeralStaff = await getClient().query({
            query: ReadStaffAllocateQuery,
            variables: { funeralId }
        });
        return funeralStaff.data && funeralStaff.data.readOneFuneral;
    };

    showMember = Member => {
        const { funeralId } = this.props;
        return (
            <Query query={ReadStaffAllocateQuery} variables={{ funeralId }}>
                {({ data, loading, error }) => {
                    const conductor =
                        data.readOneFuneral && data.readOneFuneral.Conductors.find(o => Member.ID === o.Member.ID);
                    const arranger =
                        data.readOneFuneral && data.readOneFuneral.Arrangers.find(o => Member.ID === o.Member.ID);
                    return (
                        <Fragment>
                            {joinDefined([Member.FirstName, Member.Surname], ' ')}
                            {(conductor || arranger) && ' ('}
                            {(conductor &&
                                ((conductor.Allocation && conductor.Allocation + ' ') || '') + 'Conductor') ||
                                (arranger && ((arranger.Allocation && arranger.Allocation + ' ') || '') + 'Arranger')}
                            {(conductor || arranger) && ')'}
                        </Fragment>
                    );
                }}
            </Query>
        );
    };
}

const ReadStaffAllocateQuery = gql`
    query ReadFuneralStaff($funeralId: ID!) {
        readOneFuneral(ID: $funeralId) {
            ID
            Arrangers {
                ID
                Allocation
                Member {
                    ID
                    FirstName
                    Surname
                }
            }
            Conductors {
                ID
                Allocation
                Member {
                    ID
                    FirstName
                    Surname
                }
            }
        }
    }
`;

const styles = () => ({
    iconButton: {
        height: '24px',
        width: '24px'
        //marginTop: '12px'
    },
    icon: {
        height: '18px',
        width: '18px',
        verticalAlign: 'middle'
    },
    readonlyCell: {
        margin: '10px 0'
    },
    editCell: {
        margin: '4px 0'
    },
    tinyCell: {
        width: 1
    },
    notSpecified: {
        fontStyle: 'italic',
        color: '#777'
    },
    viewCalendarButton: {
        float: 'right'
    }
});

export default withStyles(styles)(StaffAllocationTable);
