import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from './TextField';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { ADDRESS_BOOK_CATEGORIES } from '../../page/funeral/funeralConstants';
import Select from './Select';
import AddressAutocomplete from './AddressAutocomplete';
import { CONTACT_AUS_STATES } from '../../page/contacts/contactConstants';

// TODO: Add select for the address book category, with default to the category provided in props

/**
 * Modal which allows creation of new address book entries.
 *
 * Once a new address entry has been created the newly created entry
 * can be saved to a field on the funeral by providing the `form`
 * state and the `name` of the field on the Funeral type which has
 * the address book entry to be selected.
 *
 * For custom handling behavior you can use the `onSelect`
 * callback handler passed through props.
 */
export default class AddressBookModal extends Component {
    static defaultProps = {
        title: "Enter new contact's details",
        onClose: () => {}
    };

    state = {};

    static getDerivedStateFromProps({ category }, { AddressBookCategory }) {
        if (category && category.key !== AddressBookCategory) return { AddressBookCategory: category.key };
        return null;
    }

    getId() {
        return `AddressBookModal-${this.props.title}`;
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onBlur = e => {
        // sure it seems redundant, but actually we're gonna strip away naughty stray spaces.
        const val = (e.target.value || '').trim();
        this.setState({ [e.target.name]: val });
    };

    onSelect = (str, arr) => {
        this.setState({ Address: str, ...arr });
    };

    onCancel = () => {
        this.props.onClose();
    };

    onSubmit = (mutate, loading) => e => {
        e.preventDefault();
        if (loading) return;
        const state = { ...this.state };
        delete state['Address'];
        mutate({
            variables: {
                input: {
                    ...state
                    //                    AddressBookCategory: category.key
                }
            }
        });
    };

    onCompleted = ({ createAddressBook: address }) => {
        const { form, name, onClose } = this.props;
        onClose(address);

        if (form) {
            form.setField({
                [`${name}.ID`]: address.ID,
                [`${name}.Name`]: address.Name,
                [`${name}.AddressLine1`]: address.AddressLine1,
                [`${name}.AddressLine2`]: address.AddressLine2,
                [`${name}.Postcode`]: address.PostCode,
                [`${name}.State`]: address.State,
                [`${name}.Suburb`]: address.Suburb
            });
        }

        const clearedState = Object.keys(this.state).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});

        this.setState(clearedState);
    };

    render() {
        const { open, onClose } = this.props;
        return (
            <Dialog open={open} onClose={onClose} aria-labelledby={this.getId()}>
                <Mutation mutation={createAddressBook} onCompleted={this.onCompleted}>
                    {this.renderForm}
                </Mutation>
            </Dialog>
        );
    }

    renderForm = (mutate, { loading }) => {
        const { title } = this.props;
        return (
            <form onSubmit={this.onSubmit(mutate, loading)}>
                <DialogTitle id={this.getId()}>{title}</DialogTitle>

                <DialogContent>
                    <Select
                        label={'Category'}
                        placeholder={'Select a category'}
                        name={'AddressBookCategory'}
                        options={ADDRESS_BOOK_CATEGORIES.map(e => {
                            return { label: e.label, value: e.key };
                        })}
                        onChange={this.onChange}
                        value={this.state.AddressBookCategory || ''}
                    />
                    {this.renderField('Name', 'Business Name', true, true)}
                    <AddressAutocomplete
                        name={'Address'}
                        value={this.state.Address || ''}
                        onSelect={this.onSelect}
                        label={'Address'}
                        placeholder="Search for an address"
                        allowCustomAddress={true}
                        componentFields={{
                            line1: 'AddressLine1',
                            line2: 'AddressLine2',
                            city: 'Suburb',
                            state: 'State',
                            code: 'Postcode'
                        }}
                    />
                    {this.renderField('Email', 'Email')}
                    {this.renderField('Phone', 'Primary Phone')}
                    {this.renderField('Contact', 'Contact Person')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={loading ? undefined : this.onCancel} disabled={loading}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={loading}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        );
    };

    renderField(name, label, autoFocus, required) {
        if (name === 'state') {
            return (
                <Select
                    label={label}
                    name={name}
                    required={required}
                    value={this.state[name] || ''}
                    onChange={this.onChange}
                    options={CONTACT_AUS_STATES}
                />
            );
        }
        return (
            <TextField
                label={label}
                name={name}
                autoFocus={autoFocus}
                margin="dense"
                required={required}
                value={this.state[name] || ''}
                onChange={this.onChange}
                onBlur={this.onBlur}
            />
        );
    }
}

const createAddressBook = gql`
    mutation CreateAddressBook($input: AddressBookCreateInputType!) {
        createAddressBook(Input: $input) {
            ID
            Name
            Contact
            Email
            Phone
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
    }
`;
