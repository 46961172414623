import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from './Grid';
import Label from './Label';
import cx from 'classnames';

const styles = ({ spacing, palette, transitions, breakpoints, typography }) => ({
    inlineHeaderRightAlignChildren: {
        display: 'flex',
        // marginBottom: -14,
        lineHeight: 2.25,
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        '& > :first-child': {
            flex: '1 1 auto',
            alignSelf: 'center'
        },
        '& > :not(:first-child)': {
            marginLeft: '5px'
        }
    },

    inlineFieldRightAlignChildren: {
        display: 'flex',
        lineHeight: 2.25,
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        '& > :first-child': {
            flex: '1 1 auto',
            lineHeight: 'inherit'
        },
        '& > :not(:first-child)': {
            marginLeft: '5px',
            lineHeight: 'inherit'
        }
    },

    inlineHeader: {
        display: 'inline-block',
        lineHeight: 2.25,
        '& > :not(:first-child)': {
            marginLeft: 20
        },
        [`& > label ~ div.inlineField`]: {
            marginLeft: 0
        }
    },

    inlineField: {
        display: 'flex',
        flexWrap: 'nowrap',
        lineHeight: 2.25,
        '& > :first-child': {
            lineHeight: 'inherit'
        },
        '& > :not(:first-child)': {
            marginLeft: 15,
            lineHeight: 'inherit'
        }
    },

    inlineFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'stretch',
        lineHeight: 2.25,
        '& > :not(:first-child)': {
            marginLeft: '15px',
            lineHeight: 'inherit'
        }
    }
});

//inline grid that contains elements in a left-to-right line
const InlineField = withStyles(styles)(({ classes, children, className, lineHeight, ...props }) => {
    const style = { lineHeight: lineHeight || 2.25 };
    return (
        <Grid pc={1} className={cx(classes.inlineField, className, 'inlineField')} style={style}>
            {children}
        </Grid>
    );
});

//inline grid that contains elements in a left-to-right line
const InlineFieldRightAlignChildren = withStyles(styles)(({ classes, children, className, lineHeight, ...props }) => {
    const style = { lineHeight: lineHeight || 2.25 };
    return (
        <Grid pc={1} className={cx(classes.inlineFieldRightAlignChildren, className)} style={style}>
            {children}
        </Grid>
    );
});

//inline grid with a label on the left. subsequent children appear padded with a left margin
const InlineHeader = withStyles(styles)(({ header, classes, children, className, ...props }) => (
    <Grid pc={1} className={cx(classes.inlineHeader, className)}>
        <Label text={header} />
        {children}
    </Grid>
));

//inline grid with a label on the left. subsequent children appear right-aligned, padded with a left margin
const InlineHeaderRightAlignChildren = withStyles(styles)(
    ({ header, classes, children, className, disabled, ...props }) => (
        <Grid pc={1} className={cx(classes.inlineHeaderRightAlignChildren, className)}>
            <Label text={header} disabled={disabled} />
            {children}
        </Grid>
    )
);

const InlineFlex = withStyles(styles)(({ classes, children, style }) => (
    <div className={classes.inlineFlex} style={style}>
        {children}
    </div>
));

export { InlineField, InlineHeader, InlineHeaderRightAlignChildren, InlineFieldRightAlignChildren, InlineFlex };
